import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import './index.scss';
import EventStatusBadge from '../../../Shared/EventStatusBadge';
import { getSvgIcon } from '../../../../util/utilityFunctions';

interface Status {
  eventStatus: string;
  visualIndicator?: string;
  thresholdQuantityIndicator?: boolean;
  status?: string;
  type?: string;
}
export interface Props {
  children?: FC;
  item?: Status;
}

const InventoryRequestStatusCell: FC<Props> = ({
  item = {
    eventStatus: '',
    visualIndicator: '',
    thresholdQuantityIndicator: '',
    status: '',
    type: '',
  },
}) => {
  const { eventStatus, thresholdQuantityIndicator, visualIndicator, type } = item;
  const iconThreshold = getSvgIcon('threshold');
  return (
    <DataTableCell title={type === 'Inventory Request' ? eventStatus : visualIndicator || 'NEW'}>
      <div style={{ display: 'flex' }}>
        <EventStatusBadge
          status={type === 'Inventory Request' ? eventStatus : visualIndicator || 'NEW'}
        />
        {thresholdQuantityIndicator && (
          <div style={{ marginLeft: '5px' }}>
            <img
              className="slds-tooltip-trigger"
              title="Threshold Exceed"
              src={iconThreshold}
              alt="threshold"
              style={{ width: '20px', height: '15px', marginLeft: '5px' }}
            />
          </div>
        )}
      </div>
    </DataTableCell>
  );
};

export default InventoryRequestStatusCell;
