/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import EventStatusBadge from '../../../Shared/EventStatusBadge';
import './index.scss';

interface Action {
  workflowAction: string;
  userworkflowAction?: [];
  footerRow?: boolean;
  lineVisualIndicator?: string;
  status: string;
}
export interface Props {
  children?: FC;
  item?: Action;
  viewOnly: boolean;
  type?: any;
}

const InventoryReturnTypeDataCell: FC<Props> = ({
  item = {
    workflowAction: null,
    userworkflowAction: [],
    lineVisualIndicator: '',
    status: '',
  },
  type = null,
}) => {
  const { footerRow, lineVisualIndicator, status } = item;
  const lineItemStatus = type === 'Processed' || type === 'Failed' ? status : lineVisualIndicator;
  return footerRow ? (
    <DataTableCell key="-1" title="footer" />
  ) : (
    <DataTableCell key={item} title="Status">
      {
        <p>
          <EventStatusBadge status={lineItemStatus || 'NEW'} />
        </p>
      }
    </DataTableCell>
  );
};

export default InventoryReturnTypeDataCell;
