/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars*/
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import Button from '@salesforce/design-system-react/components/button';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import NotificationHeader from './NotificationHeader';
import FilterNotifications from './FilterNotifications';
import { GET_NOTIFICATION_LISTS } from '../../graphql/getUserNotificationList';
import { LIMIT, NEXT_DATA, PREV_DATA } from '../../util/constants';
import FilterTags from '../Shared/FilterTags';
import debounced from '../../util/debounced';
import { getUserId } from '../../store/ducks/userId';
import { getSvgIcon } from '../../util/utilityFunctions';
import { getUserInfo } from '../../store/ducks/userInfo';
import { UPDATE_USER_NOTIFICATION } from '../../graphql/mutations/updateUserNotification';
import CancelModal from './deleteNotificationsPopup';
import {
  setNotificationFilters,
  getNotificationFilters,
} from '../../store/ducks/notificationFilters';

interface Props {
  type: string;
}

interface SelectedData {
  id: string;
  label: string;
  __typename?: string;
}
interface Filters {
  status: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  usage: string;
  eventType: string[];
  filterExternalId?: string;
  filterName?: string;
  search?: string;
  searchText?: string;
}

interface DynamicValue {
  [key: string]: any;
}

// eslint-disable-next-line no-empty-pattern
const NotificationCenterPage: FC<RouteComponentProps> = ({ history, location }) => {
  const userInfo = useSelector(getUserInfo);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<DynamicValue | null>(null);
  const [selectedFilterApiValues, setSelectedFilterApiValues] = useState<DynamicValue | null>(null);
  const [hasMore, setItems] = useState(true);
  const userId = useSelector(getUserId);
  const [updateNotification] = useMutation(UPDATE_USER_NOTIFICATION);
  const [checkedItems, setChecked] = useState<any[]>([]);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [deleteLineId, setDeleteLineId] = useState('');
  const [sortedFlag, setSortFlag] = useState(true);
  const [sortedId, setSortId] = useState(true);
  const [sortedType, setSortType] = useState(true);
  const [sortedDate, setSortDate] = useState(true);
  const [sortedSubject, setSortSubject] = useState(true);
  const [sortedSales, setSortSalesRep] = useState(true);
  const [sortedColumn, setColumn] = useState('');
  const [searchTerm, setSearchText] = useState('');
  const starMarkedIcon = getSvgIcon('starMarked');
  const starNotMarkedIcon = getSvgIcon('starNotMarked');
  const iconup = getSvgIcon('upArrow');
  const iconDown = getSvgIcon('downPointedArrow');
  const dispatch = useDispatch();
  const saleRepColCheck =
    (userInfo && userInfo.personas === 'Branch Op') ||
    (userInfo && userInfo.personas === 'Sales Associate') ||
    (userInfo && userInfo.personas === 'Sales Manager');

  const tableClass = saleRepColCheck
    ? 'parts-table-header ir-cols-with-sales-rep'
    : 'parts-table-header ir-cols-with-sales-rep';
  const tableRow = saleRepColCheck ? 'ir-cols-with-sales-rep' : 'ir-cols-with-sales-rep';

  const fetchMoreData = () => {
    setNotifications(notifications);
    setItems(false);
  };

  const [
    getNotificationsList,
    { data: notificationsList, refetch: refetchNotifications },
  ] = useLazyQuery(GET_NOTIFICATION_LISTS, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    getNotificationsList({
      variables: {
        userId,
        filters: selectedFilterApiValues,
      },
    });
  }, [getNotificationsList, selectedFilterApiValues, userId]);

  useEffect(() => {
    if (history.action === 'REPLACE') {
      window.location.reload();
    }
  }, []);

  const refetchNotificationsList = (): void => {
    const appliedFilters = selectedFilterApiValues || {};
    appliedFilters.searchText = searchTerm;

    getNotificationsList({
      variables: {
        userId,
        filters: appliedFilters,
      },
    });
    setChecked([]);
  };

  const getNotificationsListFormatted = useCallback((items: any[]): any[] => {
    if (items && items.length > 0) {
      const list = items.map((item: any) => {
        return {
          ...item,
          date: item.notificationCreatedDate
            ? moment(item.notificationCreatedDate).format('MM/DD/YYYY')
            : '--',
        };
      });
      return list;
    }
    return [];
  }, []);

  useEffect(() => {
    if (notificationsList?.getUserNotificationList) {
      const list = getNotificationsListFormatted(notificationsList.getUserNotificationList);
      setNotifications(list);
    }
  }, [getNotificationsList, getNotificationsListFormatted, notificationsList]);

  const refetchNotificationsItems = useCallback(
    (filterData: Filters | DynamicValue | null): void => {
      const appliedFilters = filterData || {};
      appliedFilters.searchText = searchTerm;

      getNotificationsList({
        variables: {
          userId,
          filters: appliedFilters,
        },
      });
    },
    [getNotificationsList, searchTerm, userId]
  );

  const handleToggleNotificationFilter = (): void => {
    setFilterModalVisible(!filterModalVisible);
  };
  const resetFilters = useCallback((): void => {
    setSelectedFilter(null);
    setSelectedFilterApiValues(null);
    getNotificationsList({
      variables: {
        userId,
      },
    });
  }, [getNotificationsList, userId]);

  const getFilterApiValue = (filterValues: DynamicValue): DynamicValue => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          let values;
          if (key === 'inventoryLocation') {
            values = filterValues[key].map((item: SelectedData) => item.label);
          } else {
            values = filterValues[key].map((item: SelectedData) => item.id);
          }
          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  const filterStore = useSelector(getNotificationFilters);

  useEffect(() => {
    const setFilters = JSON.parse(filterStore || '{}');

    if (setFilters && setFilters.filterValues) {
      setSelectedFilter(setFilters);
      const filters = getFilterApiValue(setFilters.filterValues);
      setSelectedFilterApiValues(filters);
      refetchNotificationsItems(filters);
    }
  }, [filterStore, refetchNotificationsItems]);

  const handleFilterNotification = (
    filterValues: DynamicValue | null,
    filterName?: string
  ): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
      dispatch(setNotificationFilters(JSON.stringify(filter)));
      refetchNotificationsItems(filters);
    } else {
      resetFilters();
    }
  };

  const setRequestSearch = (searchedItem: string): void => {
    setSearchText(searchedItem);
    const appliedFilters = selectedFilterApiValues || {};
    appliedFilters.searchText = searchedItem || '';

    getNotificationsList({
      variables: {
        userId,
        filters: appliedFilters,
      },
    });
  };

  const debouncedFetchRequestedItems = useCallback(debounced(500, setRequestSearch), [
    debounced,
    setRequestSearch,
  ]);

  const handleNotificationSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      if (value && value.length >= 3) {
        debouncedFetchRequestedItems(value);
      } else {
        debouncedFetchRequestedItems(value);
      }
    },
    [debouncedFetchRequestedItems]
  );

  const handleUpdateNotifications = (
    updateDetails: any,
    reloadFlag: boolean,
    type: string
  ): void => {
    updateNotification({
      variables: updateDetails,
    }).then(response => {
      if (
        response &&
        response?.data &&
        response?.data?.updateUserNotification &&
        response?.data?.updateUserNotification?.message &&
        response?.data?.updateUserNotification?.message === 'success'
      ) {
        setTimeout(() => {
          refetchNotificationsList();
        }, 500);
        if (type === 'detail') {
          history.push('/notificationDetails', { id: updateDetails.id[0] });
          window.location.reload();
        }
        if (reloadFlag && type !== 'detail') window.location.reload();
      } else {
        console.log('error while update');
      }
    });
  };

  const toggleFlag = (id: string, starFlag: boolean): void => {
    const updateDetails = {
      id: [id],
      type: 'Update',
      isStar: !starFlag,
    };
    handleUpdateNotifications(updateDetails, false, 'update');
  };

  const toggleSort = (sort: any, sortProperty: string): void => {
    switch (sortProperty) {
      case 'isStar':
        setSortFlag(!sort);
        setSortId(true);
        setSortType(true);
        setSortDate(true);
        setSortSubject(true);
        setSortSalesRep(true);
        break;
      case 'id':
        setSortFlag(true);
        setSortId(!sort);
        setSortType(true);
        setSortDate(true);
        setSortSubject(true);
        setSortSalesRep(true);
        break;
      case 'salesRepName':
        setSortFlag(true);
        setSortId(true);
        setSortType(true);
        setSortDate(true);
        setSortSubject(true);
        setSortSalesRep(!sort);
        break;
      case 'transactionType':
        setSortFlag(true);
        setSortId(true);
        setSortType(!sort);
        setSortDate(true);
        setSortSubject(true);
        setSortSalesRep(true);
        break;
      case 'notificationCreatedDate':
        setSortFlag(true);
        setSortId(true);
        setSortType(true);
        setSortDate(!sort);
        setSortSubject(true);
        setSortSalesRep(true);
        break;
      case 'subject':
        setSortFlag(true);
        setSortId(true);
        setSortType(true);
        setSortDate(true);
        setSortSubject(!sort);
        setSortSalesRep(true);
        break;
      default:
        setSortFlag(true);
        setSortId(true);
        setSortType(true);
        setSortDate(true);
        setSortSubject(true);
        setSortSalesRep(true);
    }

    getNotificationsList({
      variables: {
        userId,
        orderBy: sortProperty,
        orderSortType: sort ? 'asc' : 'desc',
        filters: selectedFilterApiValues,
      },
    });
  };

  const handleDelete = (id: string): void => {
    setShowCancelPopup(true);
    setDeleteLineId(id);
  };

  const handleCheckboxChange = (id: string, event: any): void => {
    if (event.target.checked) {
      if (checkedItems.length || !checkedItems.includes(id)) checkedItems.push(id);
    } else if (checkedItems.indexOf(id) !== -1) {
      checkedItems.splice(checkedItems.indexOf(id), 1);
    }
    const finalLineItems = notifications.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          isChecked: event.target.checked,
        };
      }
      return {
        ...item,
        isChecked: item.isChecked,
      };
    });
    setNotifications(finalLineItems);
    setChecked(checkedItems);
  };

  const handleCheckboxChangeForAll = (event: any): void => {
    const finalLineItems = notifications.map((item: any) => {
      if (event.target.checked) {
        checkedItems.push(item.id);
        setChecked(checkedItems);
        return {
          ...item,
          isChecked: true,
        };
      }
      setChecked([]);
      return {
        ...item,
        isChecked: false,
      };
    });
    setNotifications(finalLineItems);
  };

  const goToNotificationsDetail = (id: string): void => {
    const updateDetails = {
      id: [id],
      type: 'Update',
      isRead: true,
    };
    handleUpdateNotifications(updateDetails, false, 'detail');
  };

  const handleActionDropdownSelect = (option: any): void => {
    let updateActionDetails = {};
    if (option.value === 'mark_as_unread') {
      updateActionDetails = {
        id: checkedItems,
        type: 'Update',
        isRead: false,
      };
    } else if (option.value === 'delete') {
      setShowCancelPopup(true);
    } else if (option.value === 'mark_as_read') {
      updateActionDetails = {
        id: checkedItems,
        type: 'Update',
        isRead: true,
      };
    } else if (option.value === 'mark_important') {
      updateActionDetails = {
        id: checkedItems,
        type: 'Update',
        isStar: true,
      };
    }
    if (option.value !== 'delete') {
      handleUpdateNotifications(updateActionDetails, true, 'actions');
      setChecked([]);
    }
  };

  const handleCancelModalConfirmPress = (): void => {
    const updateDetails = {
      id: deleteLineId ? [deleteLineId] : checkedItems,
      type: 'Delete',
    };
    handleUpdateNotifications(updateDetails, true, 'delete');
    setShowCancelPopup(!showCancelPopup);
  };

  const handleCancelModal = (): void => {
    setShowCancelPopup(!showCancelPopup);
    setDeleteLineId('');
    setChecked([]);
  };

  return (
    <div className="slds-p-horizontal_large slds-p-vertical_x-large events_list">
      <IconSettings iconPath="/icons">
        {filterModalVisible && (
          <FilterNotifications
            handleToggleNotificationFilter={handleToggleNotificationFilter}
            filterModalVisible={filterModalVisible}
            selectedFilter={selectedFilter}
            handleFilterNotification={handleFilterNotification}
            resetFilters={resetFilters}
          />
        )}
        <NotificationHeader
          handleNotificationSearch={handleNotificationSearch}
          handleToggleNotificationFilter={handleToggleNotificationFilter}
          handleActionDropdownSelect={handleActionDropdownSelect}
        />
        {selectedFilter && selectedFilter.filterValues && (
          <FilterTags selectedFilter={selectedFilter} handleFilter={handleFilterNotification} />
        )}
        <div className="order-list-container" />
        <InfiniteScroll
          dataLength={notifications.length}
          next={fetchMoreData}
          hasMore={hasMore}
          height="100vh"
          loader={notifications.length > 50 ? <h4>Loading...</h4> : ''}
          endMessage=""
        >
          <div className="notification-table-container">
            <div className={tableClass}>
              <p style={{ paddingLeft: '3px' }}>
                <Checkbox
                  assistiveText={{
                    label: 'Select/Unselect All',
                  }}
                  key="mark_all"
                  id="mark_all"
                  onChange={(_event: any): void => {
                    handleCheckboxChangeForAll(_event);
                  }}
                />
              </p>
              <p
                onMouseEnter={() => setColumn('isStar')}
                onMouseLeave={() => setColumn('false')}
                onClick={() => toggleSort(sortedFlag, 'isStar')}
              >
                Flag
                {sortedColumn === 'isStar' && (
                  <span style={{ padding: '5px' }}>
                    {sortedFlag ? (
                      <img src={iconup} className="logo-image" alt="ascending" />
                    ) : (
                      <img src={iconDown} className="logo-image" alt="descending" />
                    )}
                  </span>
                )}
              </p>
              <p
                onMouseEnter={() => setColumn('id')}
                onMouseLeave={() => setColumn('false')}
                onClick={() => toggleSort(sortedId, 'id')}
              >
                Notification ID
                {sortedColumn === 'id' && (
                  <span style={{ padding: '5px' }}>
                    {sortedId ? (
                      <img src={iconup} className="logo-image" alt="ascending" />
                    ) : (
                      <img src={iconDown} className="logo-image" alt="descending" />
                    )}
                  </span>
                )}
              </p>
              {/* {saleRepColCheck && ( */}
              <p
                onMouseEnter={() => setColumn('salesRepName')}
                onMouseLeave={() => setColumn('false')}
                onClick={() => toggleSort(sortedSales, 'salesRepName')}
              >
                Sales Rep
                {sortedColumn === 'salesRepName' && (
                  <span style={{ padding: '5px' }}>
                    {sortedSales ? (
                      <img src={iconup} className="logo-image" alt="ascending" />
                    ) : (
                      <img src={iconDown} className="logo-image" alt="descending" />
                    )}
                  </span>
                )}
              </p>
              {/* )} */}
              <p
                onMouseEnter={() => setColumn('transactionType')}
                onMouseLeave={() => setColumn('false')}
                onClick={() => toggleSort(sortedType, 'transactionType')}
              >
                Type
                {sortedColumn === 'transactionType' && (
                  <span style={{ padding: '5px' }}>
                    {sortedType ? (
                      <img src={iconup} className="logo-image" alt="ascending" />
                    ) : (
                      <img src={iconDown} className="logo-image" alt="descending" />
                    )}
                  </span>
                )}
              </p>
              <p
                onMouseEnter={() => setColumn('notificationCreatedDate')}
                onMouseLeave={() => setColumn('false')}
                onClick={() => toggleSort(sortedDate, 'notificationCreatedDate')}
              >
                Date
                {sortedColumn === 'notificationCreatedDate' && (
                  <span style={{ padding: '5px' }}>
                    {sortedDate ? (
                      <img src={iconup} className="logo-image" alt="ascending" />
                    ) : (
                      <img src={iconDown} className="logo-image" alt="descending" />
                    )}
                  </span>
                )}
              </p>
              <p
                onMouseEnter={() => setColumn('subject')}
                onMouseLeave={() => setColumn('false')}
                onClick={() => toggleSort(sortedSubject, 'subject')}
              >
                Subject
                {sortedColumn === 'subject' && (
                  <span style={{ padding: '5px' }}>
                    {sortedSubject ? (
                      <img src={iconup} className="logo-image" alt="ascending" />
                    ) : (
                      <img src={iconDown} className="logo-image" alt="descending" />
                    )}
                  </span>
                )}
              </p>
              <p>{}</p>
            </div>
            {notifications &&
              notifications.map((lineItem: any, index) => {
                return (
                  <div
                    key={lineItem.id}
                    className={
                      lineItem.isRead
                        ? `parts-table-row ${tableRow} ir-cols unread-notification`
                        : `parts-table-row ${tableRow} ir-cols read-notification`
                    }
                  >
                    <p className="vertical-align-middle">
                      <Checkbox
                        assistiveText={{
                          label: 'Surgery',
                        }}
                        key={lineItem.id}
                        id={lineItem.id}
                        checked={lineItem.isChecked}
                        onChange={(_event: any): void => {
                          handleCheckboxChange(lineItem.id, _event);
                        }}
                      />
                    </p>
                    <p
                      className="vertical-align-middle cursor"
                      onClick={() => toggleFlag(lineItem.id, lineItem.isStar)}
                    >
                      {lineItem.isStar ? (
                        <img src={starMarkedIcon} className="logo-image" alt="flagged" />
                      ) : (
                        <img src={starNotMarkedIcon} className="logo-image" alt="not flagged" />
                      )}
                    </p>
                    <p style={{ marginTop: '5px' }}>
                      <a onClick={() => goToNotificationsDetail(lineItem.id)}>{lineItem.id}</a>
                    </p>
                    {/* {saleRepColCheck && ( */}
                    <p className="vertical-align-middle">
                      {' '}
                      {(lineItem?.subject === 'You have now been removed from Trial Hold' &&
                        lineItem?.transactionSfid === '') ||
                      (lineItem?.subject === 'You have now been placed on Trial Hold' &&
                        lineItem?.transactionSfid === '')
                        ? lineItem?.createdBy || ''
                        : lineItem.salesRepName}
                    </p>
                    {/* )} */}
                    <p className="vertical-align-middle">{lineItem.transactionType}</p>
                    <p className="vertical-align-middle">{lineItem.date}</p>
                    <p className="vertical-align-middle">{lineItem.subject}</p>
                    <p className="vertical-align-middle">
                      <Button
                        iconCategory="utility"
                        iconName="delete"
                        iconSize="large"
                        iconVariant="bare"
                        variant="icon"
                        onClick={(): void => {
                          handleDelete(lineItem.id);
                        }}
                      />
                    </p>
                  </div>
                );
              })}
          </div>
        </InfiniteScroll>
        {showCancelPopup && (
          <CancelModal
            canceModalVisible={showCancelPopup}
            handleCancelModal={handleCancelModal}
            handleCancelModalConfirmPress={handleCancelModalConfirmPress}
          />
        )}
      </IconSettings>
    </div>
  );
};

export default withRouter(NotificationCenterPage);
