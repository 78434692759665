/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_PARTS_WITH_LOT_DETAILS = gql`
  query getPartWithLotDetails(
    $limit: Int
    $offset: Int
    $subInventoryName: String!
    $salesRepId: String
    $accountNumber: String
    $productNumber: [ProductWithSource]!
  ) {
    getPartWithLotDetails(
      limit: $limit
      offset: $offset
      subInventoryName: $subInventoryName
      salesRepId: $salesRepId
      accountNumber: $accountNumber
      productNumber: $productNumber
    ) {
      subInventoryName
      productNumber
      productDescription
      lotNumber
      expirationDate
      availableqty
      onhandqty
      sourceLocation
      subInventoryCode
    }
  }
`;
