import { Form } from '../../components/Shared/FormEngine';

const inventoryRequest: Form = [
  {
    kind: 'field',
    name: 'topInfo',
    fieldLabel: '*Required fields',
    fieldType: 'text',
    required: true,
    optionsAutoSelected: false,
    permissionSets: [],
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'salesRep',
        fieldLabel: 'Sales Rep',
        isShow: true,
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: 'Sales Rep',
        optionsDynamic: 'getSalesReps',
        optionsAutoSelected: false,
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'primaryAccount',
        fieldLabel: 'Account',
        isShow: true,
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: 'Pick an Account',
        optionsDynamic: 'getAccounts',
        optionsAutoSelected: true,
        optionsDependentField: ['salesRep'],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'shipTo',
        fieldLabel: 'Ship To',
        isShow: true,
        fieldType: 'filterSingleDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: 'Pick a Value',
        optionsDynamic: 'getShipToAddresses',
        optionsDependentField: ['primaryAccount'],
        optionsAutoSelected: true,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'requestedReceivalDate',
        fieldLabel: 'Ship by Date',
        isShow: true,
        fieldType: 'dateTimePicker',
        required: true,
        optionsAutoSelected: false,
        date: true,
        time: false,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'serviceLevel',
        fieldLabel: 'Service Level',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: 'Service Level',
        isShow: true,
        optionsDynamic: 'getServiceLevel',
        optionsAutoSelected: false,
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'Attachments',
        fieldLabel: 'Attachments',
        isShow: true,
        fieldType: 'Attachments',
        required: false,
        optionsAutoSelected: false,
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'comments',
        fieldLabel: 'Comments',
        fieldType: 'comments',
        required: false,
        optionsAutoSelected: false,
        isShow: true,
        characterLimit: 32000,
        optionsPlaceHolder:
          'Do not use to enter patient information, complaints, or set extension request.',
        showOnWeb: true,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'specificShipingDetails',
        fieldLabel: 'Specific Shipping Details',
        fieldType: 'radio',
        optionsStatic: [
          { value: 'no', label: 'No', id: 'No' },
          { value: 'yes', label: 'Yes', id: 'Yes' },
        ],
        required: false,
        isShow: true,
        optionsPlaceHolder: 'Specific Shipping Details',
        optionsDynamic: '',
        optionsDependentField: [],
        optionsAutoSelected: false,
        permissionSets: [],
      },
    ],
  },
];

export default inventoryRequest;
