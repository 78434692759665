import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Button from '@salesforce/design-system-react/components/button';
import { BrowserRouter as Router, RouteComponentProps, withRouter } from 'react-router-dom';

interface EventId {
  externalId: string;
  recordID: string;
  caseNumber: string;
  orderId: string;
  status: string;
  recordType: string;
  cycleCountExternalId: string;
}

interface Persona {
  personas: string;
}
// interface for props that the data table cell is getting default from data table.
export interface Props extends RouteComponentProps {
  children?: FC;
  item?: EventId;
  type?: string;
  userInfo?: Persona;
}

const OrderRequestIdCell: FC<Props> = ({
  item = {
    externalId: null,
    recordID: null,
    caseNumber: null,
    orderId: null,
    status: null,
    recordType: null,
    cycleCountExternalId: null,
  },
  history,
}) => {
  const goToDetailsPage = (): void => {
    const {
      status,
      recordID,
      caseNumber,
      externalId,
      orderId,
      recordType,
      cycleCountExternalId,
    } = item;
    const type = status;
    if (recordType === 'Surgery' || recordType === 'Demo' || recordType === 'Trial') {
      // If data comes for event
      history.push('/surgicalDetails', {
        eventType: recordType,
        externalId,
        id: recordID,
      });
    } else if (recordType === 'Inventory Request') {
      // If IR
      if (type === 'Favorites') {
        history.push('/inventoryFavoriteDetails', {
          inventoryRequestFav: {
            eventId: orderId,
            caseNumber,
            caseExternalId: externalId,
            orderId,
          },
        });
      } else if (
        type === 'New' ||
        type === 'Requested' ||
        (type === 'Approved' && !orderId && externalId)
      ) {
        history.push('/inventoryRequestDetails', {
          isEventInventoryFlow: false,
          externalId,
          type: 'Open',
        });
      } else if (type === 'Failed') {
        history.push('/inventoryRequestDetails', {
          isEventInventoryFlow: false,
          externalId: orderId,
          type,
        });
      } else {
        history.push('/inventoryRequestDetails', {
          isEventInventoryFlow: false,
          externalId: orderId,
          type: 'Processed',
        });
      }
    } else if (
      recordType === 'Transfer' ||
      recordType === 'Customer Transfer' ||
      recordType === 'Rep to Customer' ||
      recordType === 'Customer to Rep' ||
      recordType === 'Rep to Rep' ||
      recordType === 'Event to Event'
    ) {
      // If Inventory transfer
      let newType;
      if (status === 'New') {
        newType = 'Sent';
      } else if (status === 'Requested') {
        newType = 'Received';
      } else if (status === 'Failed') {
        newType = 'Failed';
      } else {
        newType = 'Completed';
      }
      history.push('/inventoryTransferDetails', {
        externalId,
        transferType: newType,
        visualIndicator: status,
        status,
      });
    } else if (recordType === 'Inventory Return' || recordType === 'Pickup Return') {
      // If Inventory Return
      let newType;
      if (status === 'New') {
        newType = 'Sent';
      } else if (status === 'Requested') {
        newType = 'Received';
      } else if (status === 'Failed') {
        newType = 'Failed';
      } else {
        newType = 'Completed';
      }
      history.push('/inventoryReturnDetails', {
        externalId,
        type: newType,
        orderId,
      });
    } else if (recordType === 'Cycle Count') {
      // For Cycle Count
      if (cycleCountExternalId) {
        history.push('/cycleCountDetails', {
          cycleCountExternalId,
        });
      }
    } else if (recordType === 'Bill Only' || recordType === 'Ship & Bill') {
      // for Orders
      let newType;
      if (status === 'New' || status === 'Requested') {
        newType = 'Open';
      } else if (status === 'Failed') {
        newType = 'Failed';
      } else if (
        status === 'Submitted' ||
        status === 'Entered' ||
        status === 'Processed' ||
        status === 'Partial' ||
        status === 'Booked' ||
        status === 'Closed' ||
        status === 'Completed'
      ) {
        newType = 'Processed';
      } else {
        newType = 'Completed';
      }
      if (newType && orderId && newType !== 'Review') {
        history.push('/OrdersUsageDetails', { orderId, type: newType });
      } else {
        history.push('/OrdersUsageDetails', { externalId, type: newType });
      }
    }
  };

  return (
    <Router>
      <DataTableCell title={item?.recordID || ''}>
        <Button
          label={item?.recordID || item?.caseNumber || 'TBD'}
          variant="base"
          onClick={goToDetailsPage}
        />
      </DataTableCell>
    </Router>
  );
};

export default withRouter(OrderRequestIdCell);
