/* eslint-disable @typescript-eslint/no-unused-vars*/
import React, { FC, useEffect, useState } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Button from '@salesforce/design-system-react/components/button';
import { BrowserRouter as Router, RouteComponentProps, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { OPS_ACTION_TYPE } from '../../../util/constants';
import { getUserInfo } from '../../../store/ducks/userInfo';

interface EventId {
  caseId: string;
  cycleCountExternalId: string;
  assignedUserId: string;
}

// interface for props that the data table cell is getting default from data table.
export interface Props extends RouteComponentProps {
  children?: FC;
  item?: EventId;
  actionButton?: boolean;
  type?: string;
}

const CycleCountIdCell: FC<Props> = ({
  item = {
    eventId: null,
    cycleCountExternalId: null,
    caseId: null,
    assignedUserId: null,
  },
  actionButton,
  history,
  type,
}) => {
  const { cycleCountExternalId, caseId, assignedUserId } = item;
  const userInfo = useSelector(getUserInfo);
  const [currentOps, setAction] = useState('');
  useEffect(() => {
    if (type === 'REVIEW') {
      if (assignedUserId) {
        if (assignedUserId === userInfo?.id) {
          setAction(OPS_ACTION_TYPE.REVIEW);
        } else {
          setAction('Assign');
        }
      } else {
        setAction('Assign');
      }
    }
  }, [assignedUserId, type, userInfo]);
  const goToCycleCountDetails = (): void => {
    history.push('/cycleCountDetails', { cycleCountExternalId, opsAction: currentOps });
  };

  return (
    <Router>
      <DataTableCell title={caseId || ''}>
        {actionButton ? (
          <Button
            style={{ background: '#FFB500', color: 'white' }}
            iconVariant="border-filled"
            onClick={goToCycleCountDetails}
            iconCategory="utility"
            iconName="edit"
            iconSize="small"
          />
        ) : (
          <Button label={caseId || 'TBD'} variant="base" onClick={goToCycleCountDetails} />
        )}
      </DataTableCell>
    </Router>
  );
};

export default withRouter(CycleCountIdCell);
