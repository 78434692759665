import React from 'react';
import './index.scss';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../store/ducks/userInfo';
import GlobalSearchResults from './GlobalSearchResults';
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
const GlobalSearch = () => {
  const userInfo = useSelector(getUserInfo);
  const location = useLocation();
  return (
    <GlobalSearchResults userInfo={userInfo} globalSearchStr={location.state.searchText || ''} />
  );
};

export default GlobalSearch;
