import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_CYCLE_COUNT_LINE_ITEM_DETAILS = gql`
  query getCycleCountLineItemDetails(
    $id: String
    $externalId: String
    $filters: CycleCountDetailFilters
  ) {
    getCycleCountLineItemDetails(id: $id, externalId: $externalId, filters: $filters) {
      caseNumber
      cycleCountId
      cycleCountExternalId
      sfid
      externalId
      productId
      lotNumber
      status
      costVariance
      productdescription
      productNumber
      productFamily
      productCost
      quantity
      availablequantity
      quantitydifference
      pendingeimtransaction
      needToDisplay
      plant
      storageLocation
      soldTo
      unitOfMeasure
      serialNumber
      movementType
      stockType
      specialStockIndicator
      deltaQty
      DOM
      SLED
      isLotNumber
      isManual
      createdByDetails {
        sfid
        externalId
        quantity
        productId
        lotNumber
        createdByPersona
        createdByName
        createdById
        updatedById
        updatedByName
        comments {
          id
          externalId
          parentExternalId
          comments
          type
          user
          postTime
        }
      }
    }
  }
`;
