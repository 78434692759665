/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';
import Card from '@salesforce/design-system-react/components/card';
import Button from '@salesforce/design-system-react/components/button';
// import Combobox from '@salesforce/design-system-react/components/combobox';
// import { useQuery } from '@apollo/react-hooks';
// import { GET_SALES_REPS } from '../../../../../../graphql/getSalesReps';
import InputField from '../../../../../Shared/Input';
import { UsageSheetDetails } from '../../../UsageDetailsHeader';
import '../../../index.scss';
import SignatureCanvas from './SignatureCanvas';

interface Props {
  usageDetails?: UsageSheetDetails;
  viewOnly: boolean;
  customerPrintedName: string;
  repPrintedName: string;
  repImgDataUrl: string;
  custImgDataUrl: string;
  repNameSign?: boolean;
  customerNameSign?: boolean;
  handleSaveSign: (url: string, signType: string) => void;
  onChangeCustName: (val: string) => void;
  onChangeRepName: (val: string) => void;
  handleSave?: any;
}

interface SalesRep {
  hospital?: string;
  id?: string;
  owned?: string;
  value?: string;
  label?: string;
}

const Signature: FC<Props> = ({
  viewOnly,
  customerPrintedName,
  repPrintedName,
  repImgDataUrl,
  custImgDataUrl,
  repNameSign,
  customerNameSign,
  handleSaveSign,
  onChangeCustName,
  onChangeRepName,
  handleSave,
}) => {
  const [signPadOpen, setSignPadOpen] = useState(false);
  const [signType, setSignType] = useState('');
  const handleToggleModal = (): void => {
    setSignPadOpen(!signPadOpen);
  };
  const handleSignature = (type: string): void => {
    setSignType(type);
    setSignPadOpen(true);
  };
  const handleSaveSignature = (dataUrl: string): void => {
    handleSaveSign(dataUrl, signType);
    handleSave(true);
  };
  // const [selectedRep, setSelectedRep] = useState<any[]>([]);
  // const { data: salesRepData } = useQuery(GET_SALES_REPS);
  // useEffect(() => {
  //   if (usageDetails?.salesRepId) {
  //     const rep = [
  //       {
  //         id: usageDetails.salesRepId,
  //         label: usageDetails.salesRep,
  //       },
  //     ];
  //     setSelectedRep(rep);
  //   }
  // }, [usageDetails]);
  // const getOptionsWithLabel = (options: SalesRep[]): SalesRep[] => {
  //   return options.map((item: any) => {
  //     return {
  //       ...item,
  //       label: item.value,
  //     };
  //   });
  // };
  // const handleSelect = (e: any, data: any): void => {
  //   setSelectedRep(data?.selection || []);
  // };
  return (
    <Card id="Signature" heading="Signature">
      <hr className="card-divider" />
      <div className="signature-container">
        {customerNameSign && (
          <div>
            <InputField
              disabled={viewOnly}
              label="Contact Printed Name"
              name="CustomerName"
              placeholder="Contact Printed Name"
              type="input"
              handleChangeText={onChangeCustName}
              value={customerPrintedName}
            />
            <div className="rep-column">
              <div className="signature-image-container">
                {custImgDataUrl && (
                  <img src={custImgDataUrl} alt="customer signature" className="signature-image" />
                )}
              </div>
              <Button
                label="Customer Signature"
                onClick={(): void => handleSignature('hosPital_Sig')}
                disabled={viewOnly}
                className="yellow-btn sig-btn"
                iconCategory="utility"
                iconName="add"
                iconPosition="left"
              />
            </div>
          </div>
        )}
        {repNameSign && (
          <div>
            <InputField
              disabled={viewOnly}
              label="Rep Printed Name"
              name="RepPrintedName"
              placeholder="Rep Printed Name"
              type="input"
              handleChangeText={onChangeRepName}
              value={repPrintedName}
            />
            <div className="rep-column">
              <div className="signature-image-container">
                {repImgDataUrl && (
                  <img src={repImgDataUrl} alt="rep signature" className="signature-image" />
                )}
              </div>
              <Button
                label="Rep Signature"
                onClick={(): void => handleSignature('rep_Sig')}
                disabled={viewOnly}
                className="yellow-btn sig-btn"
                iconCategory="utility"
                iconName="add"
                iconPosition="left"
              />
            </div>
          </div>
        )}
        <SignatureCanvas
          open={signPadOpen}
          handleToggleModal={handleToggleModal}
          handleSaveSignature={handleSaveSignature}
        />
      </div>
    </Card>
  );
};

export default Signature;
