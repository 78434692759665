/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Button from '@salesforce/design-system-react/components/button';
import OrdersHeader from '../OrdersHeader';
import { LIMIT, NEXT_DATA, ORDER_TYPE, PREV_DATA } from '../../../util/constants';
import debounced from '../../../util/debounced';
import '../index.scss';
import FilterTags from '../../Shared/FilterTags';
import FilterOrders from '../FilterOrders';
import OrdersDataTable from '../OrderDataTable';
import { GET_ORDER_LIST } from '../../../graphql/getOrderList';
import {
  GET_WORKFLOW_PERMISSION,
  GET_WORKFLOW_PERMISSIONS,
} from '../../../graphql/workflowPermissions';
import { setOrderFilters, getOrderFilter } from '../../../store/ducks/orderFilters';

interface Props {
  type: string;
}

interface SelectedData {
  id: string;
  label: string;
  __typename?: string;
}
interface Filters {
  status: SelectedData[];
  salesReps: SelectedData[];
  coveringReps: SelectedData[];
  account: SelectedData[];
  branch: SelectedData[];
  franchise: SelectedData[];
  surgeon: SelectedData[];
  procedureDetail: SelectedData[];
  procedureType?: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  usage: string;
  eventType: string[];
  filterExternalId?: string;
  filterName?: string;
  search?: string;
}
interface FilterPreset {
  filterValues: Filters;
  filterExternalId?: string;
  filterName?: string;
}

interface DynamicValue {
  [key: string]: any;
}

const ErpOrderList: FC<Props> = ({ type }) => {
  const [offsetOrderFailed, setOffsetOrderFailed] = useState(0);
  const [sortColumn, setSortColumn] = useState<any>();
  const [offsetOrderProcessed, setOffsetOrderProcessed] = useState(0);
  const [orders, setOrders] = useState<any[]>([]);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<DynamicValue | null>(null);
  const [selectedFilterApiValues, setSelectedFilterApiValues] = useState<DynamicValue | null>(null);
  useQuery(GET_WORKFLOW_PERMISSIONS);
  const { data: orderResubmitPermission } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'enable_order_resubmission',
    },
  });

  const { data: isCsrUser } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'is_csr_user',
    },
  });

  const [getOrdersList, { data: ordersList, loading: loadingOrderList }] = useLazyQuery(
    GET_ORDER_LIST,
    {
      fetchPolicy: 'network-only',
    }
  );
  useEffect(() => {
    getOrdersList({
      variables: {
        limit: LIMIT,
        offset: 0,
        orderType: 'BILL_ONLY',
        screenType: type.toUpperCase(),
        filters: selectedFilterApiValues,
      },
    });
  }, [getOrdersList, selectedFilterApiValues, type]);
  const getOrdersFromList = useCallback((items: any[]): any[] => {
    if (items && items.length > 0) {
      const list = items.map((item: any) => {
        return {
          ...item,
          usageType: item.type === ORDER_TYPE.SJC_SM_BILL_ONLY ? 'Bill Only' : 'Ship & Bill',
          createdDate: item.createdDate ? moment(item.createdDate).format('L') : '--',
          eventDateTime: item.eventDateTime ? moment.utc(item.eventDateTime).format('L') : null,
          account: `${item.accountNumber} - ${item.accountName}`,
          PO: item.poNumber || 'Missing PO',
          procedureName: item.procedureName || '--',
          surgeon: item.surgeonFirstName
            ? `${item.surgeonFirstName} ${item.surgeonLastName}`
            : '--',
          totalAmount: item.totalAmount ? parseFloat(item.totalAmount).toFixed(2) : '0.00',
        };
      });
      return list;
    }
    return [];
  }, []);
  useEffect(() => {
    if (ordersList?.getOrderList) {
      const list = getOrdersFromList(ordersList.getOrderList);
      setOrders(list);
    }
  }, [ordersList, getOrdersFromList]);

  const sortOrderList = (items: any[]): void => {
    setOrders(items);
  };
  const handleToggleFilterOrder = (): void => {
    // handleEditFilter(false);
    setFilterModalVisible(!filterModalVisible);
  };
  const resetFilters = useCallback((): void => {
    setSelectedFilter(null);
    // setIsEditFilter(false);
    setSelectedFilterApiValues(null);
    setOffsetOrderFailed(0);
    setOffsetOrderProcessed(0);
    getOrdersList({
      variables: {
        offset: 0,
        limit: LIMIT,
        orderType: 'BILL_ONLY',
        screenType: type.toUpperCase(),
      },
    });
  }, [getOrdersList, type]);
  const getFilterApiValue = (filterValues: DynamicValue): DynamicValue => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          let values;
          if (key === 'orderType') {
            values = filterValues[key].map((item: SelectedData) => item.label);
          } else {
            values = filterValues[key].map((item: SelectedData) => item.id);
          }
          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (key === 'accountType' && filterValues[key]) {
          const accountTypeSelected =
            filterValues[key] === 'My Accounts' ? 'MyAccounts' : 'TBAAccounts';
          reducedFilterValues = { ...reducedFilterValues, [key]: accountTypeSelected };
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  // function to refetch items on filter selection
  const handleRefetchItems = useCallback(
    (filterData: Filters | DynamicValue | null): void => {
      setOffsetOrderFailed(0);
      setOffsetOrderProcessed(0);
      getOrdersList({
        variables: {
          offset: 0,
          limit: LIMIT,
          type: type.toUpperCase(),
          filters: filterData,
        },
      });
    },
    [getOrdersList, type]
  );

  const dispatch = useDispatch();
  const filterStore = useSelector(getOrderFilter);

  useEffect(() => {
    const setFilters = JSON.parse(filterStore || '{}');

    if (setFilters && setFilters.filterValues) {
      setSelectedFilter(setFilters);
      const filters = getFilterApiValue(setFilters.filterValues);
      setSelectedFilterApiValues(filters);
      handleRefetchItems(filters);
    }
  }, [filterStore, handleRefetchItems]);

  const handleFilterOrders = (filterValues: DynamicValue | null, filterName?: string): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
      dispatch(setOrderFilters(JSON.stringify(filter)));
      handleRefetchItems(filters);
    } else {
      resetFilters();
    }
  };

  const sortedcolumn = (property: any): void => {
    setSortColumn(property);
  };

  const setRequestSearch = (searchedItem: string): void => {
    getOrdersList({
      variables: {
        offset: 0,
        limit: LIMIT,
        orderType: 'BILL_ONLY',
        screenType: type.toUpperCase(),
        filters: {
          searchText: searchedItem,
        },
      },
    });
  };

  const debouncedFetchRequestedItems = useCallback(debounced(500, setRequestSearch), [
    debounced,
    setRequestSearch,
  ]);

  const handleOrderSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      if (value && value.length >= 3) {
        debouncedFetchRequestedItems(value);
      } else {
        debouncedFetchRequestedItems(value);
      }
    },
    [debouncedFetchRequestedItems]
  );

  // Pagination for Erp orders- processed and failed tabs items
  const handlePaginationButtonClicked = (nav: string): void => {
    let finalOffset;
    if (type.toUpperCase() === 'FAILED') {
      if (nav === NEXT_DATA) {
        finalOffset = offsetOrderFailed + LIMIT;
      } else {
        finalOffset = offsetOrderFailed - LIMIT;
      }
      if (sortColumn) {
        const sortProperty = sortColumn.property;
        const { sortDirection } = sortColumn;
        getOrdersList({
          variables: {
            offset: finalOffset,
            limit: LIMIT,
            orderType: 'BILL_ONLY',
            orderBy: sortProperty,
            orderSortType: sortDirection,
            screenType: type.toUpperCase(),
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      } else {
        getOrdersList({
          variables: {
            offset: finalOffset,
            limit: LIMIT,
            orderType: 'BILL_ONLY',
            screenType: type.toUpperCase(),
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      }
      setOffsetOrderFailed(finalOffset);
    } else if (type.toUpperCase() === 'PROCESSED') {
      if (nav === NEXT_DATA) {
        finalOffset = offsetOrderProcessed + LIMIT;
      } else {
        finalOffset = offsetOrderProcessed - LIMIT;
      }
      if (sortColumn) {
        const sortProperty = sortColumn.property;
        const { sortDirection } = sortColumn;
        getOrdersList({
          variables: {
            offset: finalOffset,
            limit: LIMIT,
            orderType: 'BILL_ONLY',
            orderBy: sortProperty,
            orderSortType: sortDirection,
            screenType: type.toUpperCase(),
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      } else {
        getOrdersList({
          variables: {
            offset: finalOffset,
            limit: LIMIT,
            orderType: 'BILL_ONLY',
            screenType: type.toUpperCase(),
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      }
      setOffsetOrderProcessed(finalOffset);
    }
  };
  return (
    <IconSettings iconPath="/icons">
      {filterModalVisible && (
        <FilterOrders
          handleToggleFilterOrder={handleToggleFilterOrder}
          filterModalVisible={filterModalVisible}
          selectedFilter={selectedFilter}
          // refetchFilterPresets={refetchFilterPresets}
          handleFilterOrders={handleFilterOrders}
          resetFilters={resetFilters}
          // handleEditFilter={handleEditFilter}
          // isEditFilter={isEditFilter}
        />
      )}
      <OrdersHeader
        label={type}
        handleToggleFilterOrder={handleToggleFilterOrder}
        handleOrderSearch={handleOrderSearch}
      />
      {selectedFilter && selectedFilter.filterValues && (
        <FilterTags selectedFilter={selectedFilter} handleFilter={handleFilterOrders} />
      )}
      <div className="order-list-container">
        {loadingOrderList && (
          <Spinner
            size="large"
            variant="base"
            assistiveText={{ label: 'Loading...' }}
            key="spinner"
          />
        )}
        <OrdersDataTable
          orders={orders || []}
          sortedcolumn={sortedcolumn}
          offset={type.toUpperCase() === 'FAILED' ? offsetOrderFailed : offsetOrderProcessed}
          getOrdersList={getOrdersList}
          type={type}
          sortOrderList={sortOrderList}
          isCsrUser={isCsrUser}
          selectedFilterApiValues={selectedFilterApiValues}
          orderResubmitPermission={orderResubmitPermission}
        />
      </div>
      {type.toUpperCase() === 'FAILED' && (
        <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronleft"
              iconSize="small"
              disabled={!(offsetOrderFailed > 0)}
              iconVariant="bare"
              onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
              variant="icon"
            />
          </div>
          <div className="slds-col slds-p-left_medium slds-p-right_medium">
            <span>{`${offsetOrderFailed + 1} - ${offsetOrderFailed + LIMIT}`}</span>
          </div>
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronright"
              iconSize="small"
              iconVariant="bare"
              disabled={ordersList?.getOrderList.length < 50}
              onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
              variant="icon"
            />
          </div>
        </div>
      )}
      {type.toUpperCase() === 'PROCESSED' && (
        <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronleft"
              iconSize="small"
              disabled={!(offsetOrderProcessed > 0)}
              iconVariant="bare"
              onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
              variant="icon"
            />
          </div>
          <div className="slds-col slds-p-left_medium slds-p-right_medium">
            <span>{`${offsetOrderProcessed + 1} - ${offsetOrderProcessed + LIMIT}`}</span>
          </div>
          <div className="slds-col">
            <Button
              assistiveText={{ icon: 'Icon Bare Small' }}
              iconCategory="utility"
              iconName="chevronright"
              iconSize="small"
              iconVariant="bare"
              disabled={ordersList?.getOrderList.length < 50}
              onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
              variant="icon"
            />
          </div>
        </div>
      )}
    </IconSettings>
  );
};

export default ErpOrderList;
