/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_ORDER_DETAILS = gql`
  query getOrderDetails($id: String!, $searchText: String, $orderType: String) {
    getOrderDetails(id: $id, searchText: $searchText, orderType: $orderType) {
      salesRepId
      receivingRepId
      status
      orderType
      createdDate
      createdBy
      updatedBy
      updateDate
      submittedDate
      orderNumber
      erpOrderNumber
      erpOrderType
      effectiveDate
      fromSubInventoryCode
      toSubInventoryCode
      recordTypeId
      priceBook2Id
      salesOrganization
      branchId
      id
      externalId
      shipMethod
      serviceLevel
      serviceLevelJustification
      needByDate
      accountId
      accountNumber
      accountName
      salesRep
      isTBAAccount
      receivingRepName
      shippingAddessLine1
      shippingAddessLine2
      shippingPostalcode
      shippingCity
      shippingState
      shippingId
      shipToId
      shippingAddressNumber
      billingAddessLine1
      billingAddessLine2
      billingPostalcode
      billingCity
      billingState
      billingId
      billingAddressNumber
      caseId
      caseExternalId
      caseNumber
      poNumber
      patientId
      surgeonId
      surgeonFirstName
      surgeonLastName
      surgeonErpCode
      procedures
      procedureId
      procedureName
      eventDateTime
      repPrintedName
      hospitalPrintedName
      failureReason
      createrName
      createrPhone
      salesRepPhone
      salesRepEmail
      visualIndicator
      diplayOrderType
      territoryNumber
      parentEventId
      parentEventType
      parentEventStatus
      parentCaseExternalId
      parentCaseId
      parentOrderid
      parentEventStartDate
      parentEventDescription
      parentEventSubType
      eventId
      eventType
      showBackorderdWarning
      showRedWarningIcon
      isshippingLabelRequired
      liftGateNeeded
      dockAvailable
      shortTruckOnly
      onsiteContractName
      onsiteContractPhone
      onsiteContractEmail
      onsiteContractInstructions
      isPickupDetailsAdded
      isShippingDetails
      deliveryContractName
      deliveryContractPhone
      deliveryContractEmail
      deliveryContractInstructions
      shipDate
      shipmentDate
      salesRepNameIns
      salesRepEmailIns
      salesRepPhoneIns
      shippingInstructions
      assignedUserId
      thresholdQuantityIndicator
      isCycleCountInitiated
      comments {
        id
        externalId
        value: comments
        type
        user
        postTime
      }
      lineItems {
        sfid
        externalId
        productId
        lotNumber
        quantity
        actualQuantity
        priceBookEntryId
        unitPrice
        salesUnitOfMeasure
        expirationDate
        productNo
        productNumber
        status
        approvalStatus
        productdescription
        isLineSubmitted
        deliveredDate
        availableQuantity
        onhandQuantity
        caseUsageId
        caseUsageExternalId
        packContent
        listPrice
        productFamily
        baseorCapital
        serialNumber
        caseUsageSubInventoryCode
        usgPrice
        usgTotalAmount
        priceListHeader
        priceListContract
        overridePrice
        priceOverride
        priceOverrideType
        wasted
        failureReason
        trackingNumber
        majorCode
        lotValidation
        priceValidation
        floorPrice
        productOnHold
        partStatus
        backorderedQuantity
        deliveryQuantity
        shippedQuantity
        returnReason
        returnType
        sourceLocation
        lineVisualIndicator
        iscatalog
        isSelected
        ircaseExternalId
        ireventID
        irCaseID
        inventorySfid
        needByDate
        serviceLevel
        shipToId
        isLotNumber
        thresholdQuantity
        lineThresholdQuantityIndicator
        isAlreadyRequested
        orderCasaesUsageSfid
        isValidLotSerialCombination
        shippingLineItems {
          trackingNumber
          erpOrderNumber
          deliveredDate
          deliveryQuantity
          quantity
          actualQuantity
          shipmentSource
        }
      }
      receivedLineItems {
        id
        externalId
        productId
        lotNumber
        quantity
        expirationDate
        productNumber
        status
        workflowAction
        type
        productDescription
        productdescription
        availableQuantity
        onhandQuantity
        lineVisualIndicator
        productOnHold
        returnReason
        trackingNumber
        receivedQuantity
        erpOrderNumber
        receivedDate
      }
      attachments {
        id
        caseId
        caseExternalId
        recordType
        attachmentType
        showOnUsageSheet
        name
        createdBy
        createdAt
        imageurl
        user
      }
      shippingLabelAttachments {
        id
        caseId
        caseExternalId
        recordType
        attachmentType
        name
        createdBy
        createdAt
        imageurl
        user
      }
    }
  }
`;
