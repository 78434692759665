/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState, useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Button from '@salesforce/design-system-react/components/button';
import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Spinner from '@salesforce/design-system-react/components/spinner';
import InventoryRequestIdCell from '../DataTableCells/InventoryRequestIdCell';
import ResetCell from '../DataTableCells/ResetCell';
import InventoryRequestStatusCell from '../DataTableCells/InventoryStatusCell';
import InventoryRequestDateTImeCell from '../DataTableCells/InventoryDateTImeCell';
import Inventory3PL from '../DataTableCells/Inventory3PL';
import '../index.scss';
import debounced from '../../../../util/debounced';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import SnackBar from '../../../Shared/SnackBar';
import InventoryRequestsAssignDataCell from '../DataTableCells/InventoryAssignDataCell';
import AccountCell from '../DataTableCells/AccountCell';
import { GET_ORDER_LIST } from '../../../../graphql/getOrderList';
import ASSIGN_INVENTORY_REQUEST from '../../../../graphql/mutations/assignInventoryRequest';
import { NEXT_DATA, PREV_DATA, NOTIFY_TYPE } from '../../../../util/constants';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import FilterTagReviewRequest from '../../../Shared/FilterTagReviewRequest';
import ReprocessQueueHeader from './ReprocessQueueHeader';
import ReprocessTypeCell from './ReprocessTypeCell';
import {
  setInventoryReprocessFilters,
  getInventoryReprocessFilters,
} from '../../../../store/ducks/inventoryReprocessFilters';

interface Props {
  action: string;
}

interface InventoryTotalCostDataCellProps {
  item?: {
    totalcost?: string;
  };
}

interface Sort {
  sortColumn: string;
  sortColumnDirection: {
    [key: string]: string;
  };
}

const InventoryTotalCostDataCell: FC<InventoryTotalCostDataCellProps> = ({
  item = {
    totalcost: null,
  },
}) => {
  const { totalcost } = item;
  return (
    <DataTableCell title={totalcost || ''}>
      <p>{totalcost ? parseFloat(totalcost).toFixed(2) : '0.00'}</p>
    </DataTableCell>
  );
};

// CustomDataTableCell must have the same displayName as DataTableCell or it will be ignored.
InventoryRequestIdCell.displayName = DataTableCell.displayName;
InventoryRequestStatusCell.displayName = DataTableCell.displayName;
ReprocessTypeCell.displayName = DataTableCell.displayName;
InventoryRequestsAssignDataCell.displayName = DataTableCell.displayName;
AccountCell.displayName = DataTableCell.displayName;
InventoryRequestDateTImeCell.displayName = DataTableCell.displayName;
InventoryTotalCostDataCell.displayName = DataTableCell.displayName;
Inventory3PL.displayName = DataTableCell.displayName;
ResetCell.displayName = DataTableCell.displayName;

const ReprocessQueue: FC<Props> = ({ action }) => {
  const [getReprocessQueueList, { data: inventoryQueueData, refetch, loading }] = useLazyQuery(
    GET_ORDER_LIST,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [assignToOps, { data: assignRespone, loading: isLoading }] = useMutation(
    ASSIGN_INVENTORY_REQUEST
  );
  const [inventoryQueueList, setState] = useState([]);
  const { open, notification, openSnackBar } = useSnackBar();
  const [offsetRequestReview, setOffsetRequestReview] = useState(0);
  const [offsetRequestAssign, setOffsetRequestAssign] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState<object | null>(null);
  const userInfo = useSelector(getUserInfo);
  const [sortObj, setSortObj] = useState<Sort>();
  const dispatch = useDispatch();
  const LIMIT = 100;
  useEffect(() => {
    getReprocessQueueList({
      variables: {
        limit: LIMIT,
        offset: 0,
        orderType: 'REPROCESS_QUEUE',
        screenType: 'FAILED',
        filters: appliedFilters,
      },
    });
    //  setAppliedFilters(null);
  }, [action, getReprocessQueueList, appliedFilters]);

  useEffect(() => {
    if (assignRespone && assignRespone.assignInventoryRequest.message === NOTIFY_TYPE.SUCCESS) {
      openSnackBar(NOTIFY_TYPE.SUCCESS, 'Reset Assignment Successfully');
      refetch();
    }
  }, [assignRespone, refetch, openSnackBar]);

  useEffect(() => {
    if (inventoryQueueData && inventoryQueueData.getOrderList) {
      setState(inventoryQueueData.getOrderList);
    }
  }, [inventoryQueueData]);

  // This function fetches the next and previous records of the events list
  const handlePaginationButtonClicked = (type: string): void => {
    let finalOffset;
    if (action.toUpperCase() === 'ASSIGN') {
      if (type === NEXT_DATA) {
        finalOffset = offsetRequestReview + LIMIT;
      } else {
        finalOffset = offsetRequestReview - LIMIT;
      }
      const filters = appliedFilters || undefined;
      getReprocessQueueList({
        variables: {
          limit: LIMIT,
          offset: finalOffset,
          orderType: 'REPROCESS_QUEUE',
          screenType: 'FAILED',
          filters,
        },
      });
      setOffsetRequestAssign(finalOffset);
    }
  };

  const applyFilters = (appliedFilter: object | null): void => {
    setAppliedFilters(appliedFilter);
    setOffsetRequestAssign(0);
    setOffsetRequestReview(0);
    const filters = appliedFilter || undefined;
    getReprocessQueueList({
      variables: {
        limit: LIMIT,
        offset: 0,
        orderType: 'REPROCESS_QUEUE',
        screenType: 'FAILED',
        filters,
      },
    });
    dispatch(setInventoryReprocessFilters(JSON.stringify(appliedFilter)));
  };

  const setSearch = (searchedItem: string): void => {
    if (searchedItem.length >= 3) {
      getReprocessQueueList({
        variables: {
          limit: LIMIT,
          offset: 0,
          orderType: 'REPROCESS_QUEUE',
          screenType: 'FAILED',
          filters: {
            searchText: searchedItem,
          },
        },
      });
    } else {
      getReprocessQueueList({
        variables: {
          limit: LIMIT,
          offset: 0,
          orderType: 'REPROCESS_QUEUE',
          screenType: 'FAILED',
        },
      });
    }
  };

  const debouncedFetchItems = useCallback(debounced(500, setSearch), [debounced, setSearch]);

  const handleSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      if (value && value.length >= 3) {
        debouncedFetchItems(value);
      } else {
        debouncedFetchItems(value);
      }
    },
    [debouncedFetchItems]
  );

  const handleSort = (sortColumn: any): void => {
    const sortProperty =
      sortColumn.property === 'accountName' ? 'accountNumber' : sortColumn.property;
    const { sortDirection } = sortColumn;
    const sort = {
      sortColumn: sortProperty,
      sortColumnDirection: {
        [sortProperty]: sortDirection,
      },
    };
    if (sortProperty === 'diplayOrderType') {
      const withoutStatndardlist = inventoryQueueList.filter(
        (type: any) => type.diplayOrderType !== 'Inventory Return'
      );
      const withStandardlist = inventoryQueueList.filter(
        (type: any) => type.diplayOrderType === 'Inventory Return'
      );
      withoutStatndardlist.sort((a: any, b: any) => {
        // String Compare
        let val = 0;
        if (a[sortProperty] > b[sortProperty]) {
          val = 1;
        }
        if (a[sortProperty] < b[sortProperty]) {
          val = -1;
        }
        if (sortDirection === 'desc') {
          val *= -1;
        }
        return val;
      });
      let finalData: any = '';
      if (sortDirection === 'asc') {
        finalData = withoutStatndardlist.concat(withStandardlist);
      }
      if (sortDirection === 'desc') {
        finalData = withStandardlist.concat(withoutStatndardlist);
      }
      setState(finalData);
    } else {
      const list =
        inventoryQueueList &&
        inventoryQueueList.sort((a: any, b: any) => {
          // String Compare
          let val = 0;
          if (a[sortProperty] === null) {
            if (b[sortProperty] < '') {
              val = -1;
            }
            if (b[sortProperty] > '') {
              val = 1;
            }
            if (sortDirection === 'desc') {
              val *= -1;
            }
          } else if (b[sortProperty] === null) {
            if (a[sortProperty] > '') {
              val = -1;
            }
            if (a[sortProperty] < '') {
              val = 1;
            }
            if (sortDirection === 'desc') {
              val *= -1;
            }
          } else {
            if (a[sortProperty] > b[sortProperty]) {
              val = 1;
            }
            if (a[sortProperty] < b[sortProperty]) {
              val = -1;
            }
            if (sortDirection === 'desc') {
              val *= -1;
            }
          }
          return val;
        });
    }
    setSortObj(sort);
    // sortedcolumn(sortColumn);
  };

  const handleReset = (externalId: any): void => {
    assignToOps({
      variables: {
        externalId,
        assignedUser: '',
      },
    });
  };
  const filterStore = useSelector(getInventoryReprocessFilters);
  useEffect(() => {
    const setFilters = JSON.parse(filterStore || '{}');
    setAppliedFilters(setFilters);
  }, [filterStore]);

  return (
    <IconSettings iconPath="/icons">
      <ReprocessQueueHeader
        label={action}
        applyFilters={applyFilters}
        handleSearch={handleSearch}
        selectedFilter={appliedFilters}
      />
      <SnackBar open={open} notification={notification} />
      {appliedFilters && (
        <FilterTagReviewRequest selectedFilter={appliedFilters} handleFilter={applyFilters} />
      )}
      <div style={{ overflow: 'auto' }}>
        {!inventoryQueueData || loading || isLoading ? (
          <Spinner
            size="small"
            variant="base"
            assistiveText={{ label: 'Loading...' }}
            // key="spinner"
          />
        ) : (
          <DataTable
            items={inventoryQueueList || []}
            id="DataTableExample-1-default"
            className="font-12"
            // fixedHeader
            joined
            assistiveText={{
              columnSort: 'sort this column',
              columnSortedAscending: 'asc',
              columnSortedDescending: 'desc',
            }}
            fixedLayout
            keyboardNavigation
            onSort={handleSort}
          >
            {[
              <DataTableColumn
                key="createdDate"
                label="Submit Date"
                property="createdDate"
                // width="120px"
                isSorted={sortObj?.sortColumn === 'createdDate'}
                sortable
              >
                <InventoryRequestDateTImeCell dateFor="created" />
              </DataTableColumn>,
              <DataTableColumn
                key="eventId"
                label="Record"
                isSorted={sortObj?.sortColumn === 'eventId'}
                sortable
                property="eventId"
              >
                <InventoryRequestIdCell action={action} />
              </DataTableColumn>,
              <DataTableColumn
                key="diplayOrderType"
                label="Type"
                property="diplayOrderType"
                isSorted={sortObj?.sortColumn === 'diplayOrderType'}
                sortable
                // width="240px"
              >
                <ReprocessTypeCell />
              </DataTableColumn>,
              <DataTableColumn
                key="status"
                label="Status"
                property="status"
                isSorted={sortObj?.sortColumn === 'status'}
                sortable
                // width="112px"
              >
                <InventoryRequestStatusCell />
              </DataTableColumn>,
              <DataTableColumn
                key="failureReason"
                label="Failure Reason"
                property="failureReason"
                isSorted={sortObj?.sortColumn === 'failureReason'}
                sortable
                // width="240px"
              />,
              <DataTableColumn
                key="accountName"
                label="Account"
                property="accountName"
                isSorted={sortObj?.sortColumn === 'accountName'}
                sortable
                // width="240px"
              >
                <AccountCell />
              </DataTableColumn>,
              <DataTableColumn
                key="salesRepName"
                label="Sales Rep"
                property="salesRepName"
                isSorted={sortObj?.sortColumn === 'salesRep'}
                sortable
                // width="208px"
              />,
              <DataTableColumn
                key="serviceLevel"
                label="Service Level"
                property="serviceLevel"
                isSorted={sortObj?.sortColumn === 'serviceLevel'}
                sortable
                // width="192px"
              />,
              <DataTableColumn
                key="needByDate"
                label="Need by Date"
                property="needByDate"
                isSorted={sortObj?.sortColumn === 'needByDate'}
                sortable
                // width="120px"
              >
                <InventoryRequestDateTImeCell />
              </DataTableColumn>,
              <DataTableColumn
                key="assignedUserName"
                label="Assign To"
                width="160px"
                property="assignedUserName"
                isSorted={sortObj?.sortColumn === 'assignedUserName'}
                sortable
              >
                <InventoryRequestsAssignDataCell
                  // action={action}
                  openSnackBar={openSnackBar}
                  refetch={refetch}
                />
              </DataTableColumn>,
              <DataTableColumn key="assigned" label="" width="50px">
                <ResetCell handleReset={handleReset} />
              </DataTableColumn>,
            ]}
          </DataTable>
        )}
        {inventoryQueueList && inventoryQueueList.length > 0 && action.toUpperCase() === 'ASSIGN' && (
          <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronleft"
                iconSize="small"
                disabled={!(offsetRequestAssign > 0)}
                iconVariant="bare"
                // will fetch the previous events data
                onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
                variant="icon"
              />
            </div>
            <div className="slds-col slds-p-left_medium slds-p-right_medium">
              <span>{`${offsetRequestAssign + 1} - ${offsetRequestAssign + LIMIT}`}</span>
            </div>
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronright"
                iconSize="small"
                iconVariant="bare"
                disabled={inventoryQueueList && inventoryQueueList.length < 100}
                // will fetch the next events data
                onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
                variant="icon"
              />
            </div>
          </div>
        )}
      </div>
    </IconSettings>
  );
};

export default ReprocessQueue;
