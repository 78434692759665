/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-lonely-if */
/* eslint-disable  @typescript-eslint/no-use-before-define */

import React, { FC, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Button from '@salesforce/design-system-react/components/button';
import OrdersHeader from '../OrdersHeader';
import { GET_CASE_USAGE_SHEET_LIST } from '../../../graphql/getCaseUsageSheetList';
import { GET_CASE_USAGE_SHEET_REVIEW_LIST } from '../../../graphql/getCaseUsageSheetReviewList';
import { LIMIT, NEXT_DATA, ORDER_TYPE, PREV_DATA } from '../../../util/constants';
import debounced from '../../../util/debounced';
import '../index.scss';
import FilterTags from '../../Shared/FilterTags';
import FilterOrders from '../FilterOrders';
import OrdersDataTable from '../OrderDataTable';
import { setOrderFilters, getOrderFilter } from '../../../store/ducks/orderFilters';
import {
  GET_WORKFLOW_PERMISSION,
  GET_WORKFLOW_PERMISSIONS,
} from '../../../graphql/workflowPermissions';

interface Props {
  type: string;
}

interface SelectedData {
  id: string;
  label: string;
  __typename?: string;
}
interface Filters {
  status: SelectedData[];
  salesReps: SelectedData[];
  coveringReps: SelectedData[];
  account: SelectedData[];
  branch: SelectedData[];
  franchise: SelectedData[];
  surgeon: SelectedData[];
  procedureDetail: SelectedData[];
  procedureType?: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  usage: string;
  eventType: string[];
  filterExternalId?: string;
  filterName?: string;
  search?: string;
  parentCaseExternalId?: string;
  parentCaseId?: string;
  parentEventStartDate?: string;
  parentEventId?: string;
  parentEventType?: string;
  parentEventStatus?: string;
}
interface FilterPreset {
  filterValues: Filters;
  filterExternalId?: string;
  filterName?: string;
}

interface DynamicValue {
  [key: string]: any;
}

const OrderRequests: FC<Props> = ({ type }) => {
  const [offset, setOffset] = useState(0);
  const [sortColumn, setSortColumn] = useState<any>();
  const [orders, setOrders] = useState<any[]>([]);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<DynamicValue | null>(null);
  const [selectedFilterApiValues, setSelectedFilterApiValues] = useState<DynamicValue | null>(null);
  const [getOrdersList, { data: ordersList, loading: loadingOrderList }] = useLazyQuery(
    GET_CASE_USAGE_SHEET_LIST,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [
    getOrdersReviewList,
    { data: ordersReviewList, loading: loadingOrderReviewList, refetch },
  ] = useLazyQuery(GET_CASE_USAGE_SHEET_REVIEW_LIST, {
    fetchPolicy: 'no-cache',
  });
  useQuery(GET_WORKFLOW_PERMISSIONS);
  // const { data: isCsrUser } = useQuery(GET_WORKFLOW_PERMISSION, {
  //   fetchPolicy: 'no-cache',
  //   variables: {
  //     division: 'Sports Med',
  //     action: 'is_csr_user',
  //   },
  // });
  const dispatch = useDispatch();
  useEffect(() => {
    if (type === 'Review') {
      getOrdersReviewList({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: type.toUpperCase(),
          filters: selectedFilterApiValues,
        },
      });
    } else {
      getOrdersList({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: type.toUpperCase(),
          filters: selectedFilterApiValues,
        },
      });
    }
  }, [getOrdersList, getOrdersReviewList, selectedFilterApiValues, type]);

  const getOrdersFromList = useCallback((items: any[]): any[] => {
    if (items && items.length > 0) {
      const list = items.map((item: any) => {
        return {
          ...item,
          usageType: item.orderType === ORDER_TYPE.SJC_SM_BILL_ONLY ? 'Bill Only' : 'Ship & Bill',
          // createdDate: item.createdDate ? moment(item.createdDate).format('L') : '--',
          eventDateTime: item.eventDateTime ? moment(item.eventDateTime).format('L') : null,
          account: `${item.accountNumber} - ${item.accountName}`,
          PO: item.poNumber || 'Missing PO',
          procedureName: item.procedureName || '--',
          surgeon: item.surgeonFirstName
            ? `${item.surgeonFirstName} ${item.surgeonLastName}`
            : '--',
          totalAmount: item.totalAmount ? parseFloat(item.totalAmount).toFixed(2) : '0.00',
        };
      });
      return list;
    }
    return [];
  }, []);
  useEffect(() => {
    if (ordersList?.getCaseUsageSheetList && type === 'Open') {
      const list = getOrdersFromList(ordersList.getCaseUsageSheetList);
      setOrders(list);
    }
    if (ordersReviewList?.getCaseUsageSheetListOps && type === 'Review') {
      const list = getOrdersFromList(ordersReviewList.getCaseUsageSheetListOps);
      setOrders(list);
    }
  }, [ordersList, getOrdersFromList, ordersReviewList, type, refetch]);

  const handleRefetchItems = useCallback(
    (filterData: Filters | DynamicValue | null): void => {
      setOffset(0);
      if (type === 'Review') {
        getOrdersReviewList({
          variables: {
            offset: 0,
            limit: LIMIT,
            type: type.toUpperCase(),
            filters: filterData,
          },
        });
      } else {
        getOrdersList({
          variables: {
            offset: 0,
            limit: LIMIT,
            type: type.toUpperCase(),
            filters: filterData,
          },
        });
      }
    },
    [getOrdersList, type, getOrdersReviewList]
  );

  const filterStore = useSelector(getOrderFilter);

  useEffect(() => {
    const setFilters = JSON.parse(filterStore || '{}');

    if (setFilters && setFilters.filterValues) {
      setSelectedFilter(setFilters);
      const filters = getFilterApiValue(setFilters.filterValues);
      setSelectedFilterApiValues(filters);
      handleRefetchItems(filters);
    }
  }, [filterStore, handleRefetchItems]);
  const handlePaginationButtonClicked = (nav: string): void => {
    let finalOffset;
    if (nav === NEXT_DATA) {
      finalOffset = offset + LIMIT;
    } else {
      finalOffset = offset - LIMIT;
    }
    if (type === 'Review') {
      getOrdersReviewList({
        variables: {
          offset: finalOffset,
          limit: LIMIT,
          type: type.toUpperCase(),
          ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
        },
      });
    } else {
      getOrdersList({
        variables: {
          offset: finalOffset,
          limit: LIMIT,
          type: type.toUpperCase(),
          ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
        },
      });
    }

    if (sortColumn) {
      const sortProperty = sortColumn.property;
      const { sortDirection } = sortColumn;

      if (type === 'Review') {
        getOrdersReviewList({
          variables: {
            offset: finalOffset,
            limit: LIMIT,
            type: type.toUpperCase(),
            orderBy: sortProperty,
            orderSortType: sortDirection,
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      } else {
        getOrdersList({
          variables: {
            offset: finalOffset,
            limit: LIMIT,
            type: type.toUpperCase(),
            orderBy: sortProperty,
            orderSortType: sortDirection,
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      }
    } else {
      if (type === 'Review') {
        getOrdersReviewList({
          variables: {
            offset: finalOffset,
            limit: LIMIT,
            type: type.toUpperCase(),
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      } else {
        getOrdersList({
          variables: {
            offset: finalOffset,
            limit: LIMIT,
            type: type.toUpperCase(),
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      }
    }
    setOffset(finalOffset);
  };
  const sortOrderList = (items: any[]): void => {
    setOrders(items);
  };
  const handleToggleFilterOrder = (): void => {
    // handleEditFilter(false);
    setFilterModalVisible(!filterModalVisible);
  };
  const resetFilters = useCallback((): void => {
    setSelectedFilter(null);
    // setIsEditFilter(false);
    setSelectedFilterApiValues(null);
    setOffset(0);
    if (type === 'Review') {
      getOrdersReviewList({
        variables: {
          offset: 0,
          limit: LIMIT,
          type: type.toUpperCase(),
        },
      });
    } else {
      getOrdersList({
        variables: {
          offset: 0,
          limit: LIMIT,
          type: type.toUpperCase(),
        },
      });
    }
  }, [getOrdersList, type, getOrdersReviewList]);

  const getFilterApiValue = (filterValues: DynamicValue): DynamicValue => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          let values;
          if (key === 'orderType') {
            values = filterValues[key].map((item: SelectedData) => item.label);
          } else if (key === 'part') {
            values = filterValues[key]?.[0]?.id;
          } else {
            values = filterValues[key].map((item: SelectedData) => item.id);
          }
          if (values && values.length > 0) {
            reducedFilterValues = {
              ...reducedFilterValues,
              [key]: key === 'part' ? values : [...values],
            };
          }
        } else if (key === 'accountType' && filterValues[key]) {
          const accountTypeSelected =
            filterValues[key] === 'My Accounts' ? 'MyAccounts' : 'TBAAccounts';
          reducedFilterValues = { ...reducedFilterValues, [key]: accountTypeSelected };
        } else if (key === 'lotOrSerial') {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key]?.label };
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };
  const handleFilterOrders = (filterValues: DynamicValue | null, filterName?: string): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
      dispatch(setOrderFilters(JSON.stringify(filter)));
      handleRefetchItems(filters);
    } else {
      resetFilters();
    }
  };

  const sortedcolumn = (property: any): void => {
    setSortColumn(property);
  };

  const setRequestSearch = (searchedItem: string): void => {
    if (type === 'Review') {
      getOrdersReviewList({
        variables: {
          offset: 0,
          limit: LIMIT,
          type: type.toUpperCase(),
          filters: {
            searchText: searchedItem,
            ...selectedFilterApiValues,
          },
        },
      });
    } else {
      getOrdersList({
        variables: {
          offset: 0,
          limit: LIMIT,
          type: type.toUpperCase(),
          filters: {
            searchText: searchedItem,
            ...selectedFilterApiValues,
          },
        },
      });
    }
  };

  const debouncedFetchRequestedItems = useCallback(debounced(500, setRequestSearch), [
    debounced,
    setRequestSearch,
  ]);

  const handleOrderSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      if (value && value.length >= 3) {
        debouncedFetchRequestedItems(value);
      } else {
        debouncedFetchRequestedItems(value);
      }
    },
    [debouncedFetchRequestedItems]
  );
  // const handleOrderSearch = (name: string): void => {
  //   let filteredOrders = [...ordersList.getCaseUsageSheetList];
  //   if (name) {
  //     filteredOrders = filteredOrders.filter((row: any) => {
  //       return row.eventId && row.eventId.toLowerCase().includes(name.toLowerCase());
  //     });
  //   }
  //   const list = getOrdersFromList(filteredOrders);
  //   setOrders(list);
  // };

  return (
    <IconSettings iconPath="/icons">
      {filterModalVisible && (
        <FilterOrders
          handleToggleFilterOrder={handleToggleFilterOrder}
          filterModalVisible={filterModalVisible}
          selectedFilter={selectedFilter}
          // refetchFilterPresets={refetchFilterPresets}
          handleFilterOrders={handleFilterOrders}
          resetFilters={resetFilters}
          type={type}
          // handleEditFilter={handleEditFilter}
          // isEditFilter={isEditFilter}
        />
      )}
      <OrdersHeader
        label={type}
        handleToggleFilterOrder={handleToggleFilterOrder}
        handleOrderSearch={handleOrderSearch}
      />
      {selectedFilter && selectedFilter.filterValues && (
        <FilterTags selectedFilter={selectedFilter} handleFilter={handleFilterOrders} />
      )}
      <div className="order-list-container">
        {(loadingOrderList || loadingOrderReviewList) && (
          <Spinner
            size="large"
            variant="base"
            assistiveText={{ label: 'Loading...' }}
            key="spinner"
          />
        )}
        <OrdersDataTable
          isCsrUser
          sortedcolumn={sortedcolumn}
          getOrdersList={type === 'Review' ? getOrdersReviewList : getOrdersList}
          orders={orders || []}
          offset={offset}
          type={type}
          sortOrderList={sortOrderList}
          refetch={refetch}
          selectedFilterApiValues={selectedFilterApiValues}
        />
      </div>
      <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
        <div className="slds-col">
          <Button
            assistiveText={{ icon: 'Icon Bare Small' }}
            iconCategory="utility"
            iconName="chevronleft"
            iconSize="small"
            disabled={!(offset > 0)}
            iconVariant="bare"
            onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
            variant="icon"
          />
        </div>
        <div className="slds-col slds-p-left_medium slds-p-right_medium">
          <span>{`${offset + 1} - ${offset + LIMIT}`}</span>
        </div>
        <div className="slds-col">
          <Button
            assistiveText={{ icon: 'Icon Bare Small' }}
            iconCategory="utility"
            iconName="chevronright"
            iconSize="small"
            iconVariant="bare"
            disabled={
              type === 'Review'
                ? ordersReviewList?.getCaseUsageSheetListOps?.length < 50
                : ordersList?.getCaseUsageSheetList?.length < 50
            }
            onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
            variant="icon"
          />
        </div>
      </div>
    </IconSettings>
  );
};

export default OrderRequests;
