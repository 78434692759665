/*
 * Get Applicable Service Levels for the business unit
 * @module src/graphql/getApplicableServiceLevels
 */

/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_SERVICE_LEVELS = gql`
  query getServiceLevels($businessUnit: String, $onlyApplicable: Boolean) {
    getServiceLevels(businessUnit: $businessUnit, onlyApplicable: $onlyApplicable) {
      mappingId
      code
      name
      isapplicable
    }
  }
`;
