/*
 * Event Details GraphQL specification
 * @module src/graphql/getEmailCommunication
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Event inventory GraphQL API query */
export const GET_EMAIL_COMMUNICATION = gql`
  query getEmailCommunication($caseID: String) {
    getEmailCommunication(caseID: $caseID) {
      case_id
      sender
      receiver
      subject
      content
      attachment
      created_at
      timezone
      sendername
      recipientname
    }
  }
`;
