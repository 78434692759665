/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, ReactNode, useState } from 'react';
import Icon from '@salesforce/design-system-react/components/icon';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import ReprocessQueueFilter from './ReprocessQueueFilter';
import '../index.scss';

interface DynamicType {
  [key: string]: any;
}
interface Props {
  label: string;
  applyFilters: (filters: object | null) => void;
  handleSearch: (event: any, searchItem: any) => void;
  selectedFilter?: FilterPreset | DynamicType | null;
}

interface SelectedData {
  id: string;
  label: string;
  __typename?: string;
}
interface Filter {
  status: SelectedData[];
  salesReps: SelectedData[];
  coveringReps: SelectedData[];
  account: SelectedData[];
  branch: SelectedData[];
  franchise: SelectedData[];
  surgeon: SelectedData[];
  procedureDetail: SelectedData[];
  procedureType?: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  usage: string;
  eventType: string[];
  filterExternalId?: string;
  filterName?: string;
  search?: string;
}
interface FilterPreset {
  filterValues: Filter;
  filterExternalId?: string;
  nameOfFilter?: string;
}

const ReprocessQueueHeader: FC<Props> = ({ applyFilters, handleSearch, selectedFilter }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const handleToggleFilter = (): void => {
    setOpenFilter(!openFilter);
  };

  //   const handleFilterEventItems = (filter: any): void => {
  //     let reducedFilterValues = {};
  //     Object.keys(filter).forEach((key: string) => {
  //       if (key === 'serviceLevel' && filter[key]?.length > 0) {
  //         reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
  //       } else if (key === 'status' && filter[key]?.length > 0) {
  //         reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
  //       } else if (key === 'submittedDate' && filter[key]) {
  //         reducedFilterValues = {
  //           ...reducedFilterValues,
  //           submitFromDate: filter[key].from,
  //           submitToDate: filter[key].to,
  //         };
  //       } else if (key === 'needByDate' && filter[key]) {
  //         reducedFilterValues = {
  //           ...reducedFilterValues,
  //           needByDateFromDate: filter[key].from,
  //           needByDateToDate: filter[key].to,
  //         };
  //       } else if (key === 'territory' && filter[key]) {
  //         reducedFilterValues = { ...reducedFilterValues, [key]: filter[key].name };
  //       } else if (key === 'salesRep' && filter[key]?.length > 0) {
  //         reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
  //       } else if (key === 'recordType' && filter[key]?.length > 0) {
  //         reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
  //       } else if (key === 'productGroup' && filter[key]?.length > 0) {
  //         reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
  //       } else if (key === 'threePL' && filter[key]?.length > 0) {
  //         reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
  //       } else if (key === 'account' && filter[key]?.length > 0) {
  //         reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
  //       } else if (key === 'assignedTo' && filter[key]?.length > 0) {
  //         reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
  //       }
  //     });
  //     applyFilters(reducedFilterValues);
  //   };

  const headerActions = (): ReactNode => (
    <>
      <PageHeaderControl>
        <div className="slds-align-middle">
          <div className="slds-col_padded">
            <Input
              iconRight={
                <InputIcon
                  assistiveText={{
                    icon: 'Search',
                  }}
                  name="search"
                  category="utility"
                />
              }
              id="search-case-id"
              placeholder="Search"
              onChange={(_event: any, searchItem: string): void => handleSearch(_event, searchItem)}
            />
          </div>
        </div>
      </PageHeaderControl>
      <PageHeaderControl className="slds-align-middle">
        <ButtonGroup variant="list" id="button-group-page-header-controls">
          <Button
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="filterList"
            label="Filter"
            iconPosition="left"
            onClick={handleToggleFilter}
          />
        </ButtonGroup>
      </PageHeaderControl>
    </>
  );

  return (
    <IconSettings iconPath="/icons">
      <PageHeader
        className="reprocessQueue_header"
        icon={
          <Icon
            assistiveText={{ label: 'Opportunity' }}
            category="standard"
            name="assignment"
            style={{ backgroundColor: '#FFB500' }}
          />
        }
        label=""
        onRenderActions={headerActions}
        // onRenderControls={headerControls}
        title="Reprocess Queue"
        truncate
        variant="object-home"
      />
      {openFilter && (
        <ReprocessQueueFilter
          openFilter={openFilter}
          handleToggleFilter={handleToggleFilter}
          applyFilters={applyFilters}
          selectedFilter={selectedFilter}
        />
      )}
    </IconSettings>
  );
};

export default ReprocessQueueHeader;
