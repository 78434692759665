import moment from 'moment';
import { BlobServiceClient } from '@azure/storage-blob';
import { useCallback } from 'react';

interface FileService {
  uploadFileToBlobStorage: (file: File) => Promise<BlobFile>;
  getBlobUrlWithSas: (fileName: string) => string;
}

export interface BlobFile {
  blobName: string;
  imageurl: string;
}

interface Props {
  sasUriData: SasUriData;
}

interface SasUriData {
  account: string;
  sasUri: string;
  containerName: string;
}

const useBlobFileService = (sasUriData: SasUriData): FileService => {
  const uploadFileToBlobStorage = useCallback(
    async (file: File): Promise<BlobFile> => {
      const { account, sasUri, containerName } = sasUriData;
      const fileType = file.type;
      const tempArr = file.name.split('.');
      const blobName = `${tempArr[0]}_${moment().unix()}.${tempArr[tempArr.length - 1]}`;
      const blobServiceClient = new BlobServiceClient(
        `https://${account}.blob.core.windows.net/?${sasUri}`
      );
      const containerClient = blobServiceClient.getContainerClient(containerName);
      containerClient.getBlockBlobClient(blobName);
      const blobClient = containerClient.getBlockBlobClient(blobName);
      // set mimetype as determined from browser with file upload control
      const options = { blobHTTPHeaders: { blobContentType: fileType } };
      // upload file
      await blobClient.uploadData(file, options);
      return { blobName, imageurl: blobClient.url };
    },
    [sasUriData]
  );

  const getBlobUrlWithSas = useCallback(
    (fileName: string): string => {
      const { account, sasUri, containerName } = sasUriData;
      const blobUrl = `https://${account}.blob.core.windows.net/${containerName}/${fileName}?${sasUri}`;
      return blobUrl;
    },
    [sasUriData]
  );

  return { uploadFileToBlobStorage, getBlobUrlWithSas };
};

export default useBlobFileService;
