/**
 * Graphql mutation to create Notification settings
 * @module src/graphql/createUpdateInventoryReturnLineItems
 */
import gql from 'graphql-tag';

const CREATE_UPDATE_INVENTORY_FAVORITE = gql`
  mutation createUpdateInventoryFavorite(
    $comments: [Comment]
    $nameOfFavorite: String
    $serviceLevel: String
    $externalId: String
    $caseId: String
    $salesRep: SalesRepDefinition
    $account: AccountDefinition
    $shipTo: ShipToDefinition
    $lineItems: [LineItemsDefinition]
    $recordType: String!
  ) {
    createUpdateInventoryFavorite(
      nameOfFavorite: $nameOfFavorite
      serviceLevel: $serviceLevel
      salesRep: $salesRep
      externalId: $externalId
      caseId: $caseId
      account: $account
      shipTo: $shipTo
      lineItems: $lineItems
      comments: $comments
      recordType: $recordType
    ) {
      nameOfFavorite
      user
      serviceLevel
      externalId
      caseId
      userDetails {
        sfId
        name
      }
      comments {
        comment
        postTime
        commentType
        externalId
      }
      salesRep {
        id
        name
      }
      account {
        id
        name
      }
      shipTo {
        id
        name
      }
      lineItems {
        part {
          id
          name
        }
        lotNumber
        quantity
      }
    }
  }
`;

export default CREATE_UPDATE_INVENTORY_FAVORITE;
