/**
 * Utility module for environment variables.
 * @module src/util/env
 */
import Ajv from 'ajv';

// Need to add USE_LOCAL_FORM_JSON

interface Env {
  REACT_APP_AUTH_URL: string;
  REACT_APP_HELLO: string;
  REACT_APP_GRAPHQL_URL: string;
  REACT_APP_USE_LOCAL_FORM_JSON: string;
}

const ENV_KEYS = [
  'REACT_APP_AUTH_URL',
  'REACT_APP_HELLO',
  'REACT_APP_GRAPHQL_URL',
  'REACT_APP_USE_LOCAL_FORM_JSON',
];

const ENV_SCHEMA = {
  type: 'object',
  required: ENV_KEYS,
};

const ajv = new Ajv({ allErrors: true });

/** Flag indicating validity of environment variables  */
export const valid = ajv.validate(ENV_SCHEMA, process.env);

// casting environment
const envUnknown = process.env as unknown; // have to first cast to an unknown
// const loadedEnv = envUnknown as Env;
// loadedEnv.REACT_APP_GRAPHQL_URL = 'https://syk-eim-qa-2.herokuapp.com/graphql';
// loadedEnv.REACT_APP_AUTH_URL = 'https://syk-eim-qa-2.herokuapp.com/auth2';
// export default loadedEnv;
/**  processed environment variables. */
export default envUnknown as Env; // can safely cast due to Avj validation
