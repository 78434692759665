/**
 * Redux file for getting/setting auth token
 * @module src/store/ducks/accessToken
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_USER_INFO = 'SET_USER_INFO';
const CLEAR_USER_INFO = 'CLEAR_USER_INFO';

interface UserInfo {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assignedScreens: any;
  onholdReason: string;
  id: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  userType: string;
  division: string;
  divisions: string;
  personas: string;
  isinventoryhold: boolean;
  isSubInventoryOnhold: boolean;
  allowRepTransfer: boolean;
  allowCustomerTransfer: boolean;
  allowBillOnly: boolean;
  allowPickupReturn: boolean;
  allowStandardReturn: boolean;
  subInventoryName: string;
  subInventoryCode: string;
  assignedBusinessunits: string[];
  businessRules: string[];
  businessunit: string;
}

interface SetUserInfoActionType {
  payload: UserInfo | null;
  type: typeof SET_USER_INFO;
}

interface ClearUserInfoActionType {
  type: typeof CLEAR_USER_INFO;
}

/** accessToken action type */

export type UserInfoActionType = SetUserInfoActionType | ClearUserInfoActionType;

/** set accessToken action creator */

export const setUserInfo = (state: UserInfo | null): UserInfoActionType => {
  return {
    payload: state,
    type: SET_USER_INFO,
  };
};

/** clear accessToken action creator */

export const clearUserInfo = (): ClearUserInfoActionType => {
  return {
    type: CLEAR_USER_INFO,
  };
};

/** accessToken state type */

export type UserInfoState = UserInfo | null;

/** accessToken reducer */

export default (state: UserInfoState = null, action: ActionType): UserInfoState => {
  switch (action.type) {
    case SET_USER_INFO: {
      return action.payload;
    }
    case CLEAR_USER_INFO: {
      return null;
    }
    default:
      return state;
  }
};

/** selector to get accessToken */

export const getUserInfo = (state: State): UserInfoState => {
  return state.userInfo;
};
