/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

/**
 * React component for Input.
 * @module src/components/shared/Checkbox
 */
import React, { FC, useCallback } from 'react';
import { USAGE_TYPE, tabNavigatorOption } from '../../../util/constants';

interface Props {
  key: any;
  items: any;
  selectedTab: string;
  handleTabChange: (result: string) => void;
  partsListCheck: any;
  formType?: string;
}

const TabNavigator: FC<Props> = props => {
  const { handleTabChange, selectedTab, items, partsListCheck, formType } = props;
  const itemsList =
    formType === USAGE_TYPE.BILL_ONLY &&
    partsListCheck &&
    partsListCheck.filter((e: any) => Number(e?.actualQuantity) > Number(e?.dispositionQuantity))
      ?.length === 0
      ? tabNavigatorOption
      : items;
  return (
    <div
      style={{
        borderBottom: '1px solid silver',
        marginLeft: '-16px',
        marginRight: '-16px',
        marginBottom: '10px',
      }}
    >
      <div style={{ marginLeft: '20px', width: 'fit-content', justifyContent: 'space-between' }}>
        {itemsList.map((data: any) => {
          return (
            <span
              style={{
                cursor: 'pointer',
                fontSize: '20px',
                paddingRight: '50px',
                paddingLeft: '50px',
                borderBottom: data.label === selectedTab ? '2px solid #FFB500' : '0px',
                fontWeight: data.label === selectedTab ? 'bold' : 'normal',
              }}
              onClick={() => handleTabChange(data)}
            >
              {data.label.toUpperCase()}
            </span>
          );
        })}
      </div>
    </div>
  );
};

/** React component for Checkbox. */
export default TabNavigator;
