/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-interface */

import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DataTableView from './DataTableView';

interface Filters {
  status?: string[];
  salesReps?: string[];
  coveringReps?: string[];
  account?: string;
  branch?: string[];
  franchise?: string[];
  surgeon?: string;
  procedureDetail?: string[];
  startDate?: Date | string;
  endDate?: Date | string;
  usage?: string;
  eventType?: string[];
  search?: string;
}

interface OracleWorkflowPermission {
  workflowPermission: {
    id: string;
  } | null;
}
interface Props extends RouteComponentProps {
  inventoriesView: any;
}

const EventsInventoryExposure: FC<Props> = ({ inventoriesView, history }) => {
  return (
    <div>
      <div className="card-outer_div" />
      <DataTableView userView="By Account" gridData={inventoriesView} history={history} />
    </div>
  );
};

export default withRouter(EventsInventoryExposure);
