/**
 * Creating our combined reducer.
 * @module src/store/ducks
 */

/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';
import onlineStatusReducer, { OnlineActionType, OnlineState } from './onlineStatus';
import HoldBannerStatusReducer, { HoldBannerActionType, HoldBannerState } from './holdBanner';
import accessTokenReducer, { AccessTokenActionType, AccessTokenState } from './accessToken';
import refreshTokenReducer, { RefreshTokenActionType, RefreshTokenState } from './refreshToken';
import userIdReducer, { UserIdActionType, UserIdState } from './userId';
import userInfoReducer, { UserInfoActionType, UserInfoState } from './userInfo';
import eventFiltersReducer, { EventFiltersActionType, EventFiltersState } from './eventFilters';
import inventoryFilterReducer, {
  InventoryFilterActionType,
  InventoryFilterState,
} from './inventoryFilters';
import inventoryRequestFilterReducer, {
  InventoryRequestFilterActionType,
  InventoryRequestFilterState,
} from './inventoryRequestFilters';
import inventoryReturnsFilterReducer, {
  InventoryReturnsFilterActionType,
  InventoryReturnsFilterState,
} from './inventoryReturnsFilters';
import inventoryTransferFilterReducer, {
  InventoryTransferFilterActionType,
  InventoryTransferFilterState,
} from './inventoryTransferFilters';
import inventoryReviewFilterReducer, {
  InventoryReviewFilterActionType,
  InventoryReviewFilterState,
} from './inventoryReviewFilters';
import inventoryReprocessFilterReducer, {
  InventoryReprocessFilterActionType,
  InventoryReprocessFilterState,
} from './inventoryReprocessFilters';
import OrderFilterReducer, { OrderFilterActionType, OrderFilterState } from './orderFilters';
import NotificationFilterReducer, {
  NotificationFilterActionType,
  NotificationFilterState,
} from './notificationFilters';
import inventoryHoldFilterReducer, {
  InventoryHoldFilterActionType,
  InventoryHoldFilterState,
} from './inventroyHoldFilters';
import allCycleCountFilterReducer, {
  reviewCycleCountFilterReducer,
  CycleCountFiltersActionType,
  CycleCountFiltersState,
} from './cycleCountFilter';
import snackBarReducer, {
  CloseSnackBarActionType,
  OpenSnackBarActionType,
  SnackBarState,
} from './snackBar';
import spinnerReducer, {
  SpinnerState,
  StartLoadingActionType,
  StopLoadingActionType,
} from './spinner';

/** State interface used in ducks/onlineStatus */
export interface State {
  onlineStatus: OnlineState;
  accessToken: AccessTokenState;
  refreshToken: RefreshTokenState;
  userId: UserIdState;
  userInfo: UserInfoState;
  eventFilters: EventFiltersState;
  inventoryFilters: InventoryFilterState;
  inventoryRequestFilters: InventoryRequestFilterState;
  inventoryReturnsFilters: InventoryReturnsFilterState;
  inventoryTransferFilters: InventoryTransferFilterState;
  inventoryReviewFilters: InventoryReviewFilterState;
  inventoryReprocessFilters: InventoryReprocessFilterState;
  inventroyHoldFilters: InventoryHoldFilterState;
  orderFilters: OrderFilterState;
  notificationFilters: NotificationFilterState;
  holdBannerStatus: HoldBannerState;
  allCycleCountFilters: CycleCountFiltersState;
  reviewCycleCountFilters: CycleCountFiltersState;
  snackBarState: SnackBarState;
  spinnerState: SpinnerState;
}

/** Action type used in ducks/onlineStatus */
export type ActionType =
  | OnlineActionType
  | AccessTokenActionType
  | RefreshTokenActionType
  | UserIdActionType
  | UserInfoActionType
  | EventFiltersActionType
  | InventoryFilterActionType
  | InventoryRequestFilterActionType
  | InventoryReturnsFilterActionType
  | InventoryTransferFilterActionType
  | InventoryReviewFilterActionType
  | InventoryReprocessFilterActionType
  | InventoryHoldFilterActionType
  | OrderFilterActionType
  | NotificationFilterActionType
  | HoldBannerActionType
  | CycleCountFiltersActionType
  | OpenSnackBarActionType
  | CloseSnackBarActionType
  | StartLoadingActionType
  | StopLoadingActionType;

/** combined reducer */
export default combineReducers({
  onlineStatus: onlineStatusReducer,
  accessToken: accessTokenReducer,
  refreshToken: refreshTokenReducer,
  userId: userIdReducer,
  userInfo: userInfoReducer,
  eventFilters: eventFiltersReducer,
  inventoryFilters: inventoryFilterReducer,
  inventoryRequestFilters: inventoryRequestFilterReducer,
  inventoryReturnsFilters: inventoryReturnsFilterReducer,
  inventoryTransferFilters: inventoryTransferFilterReducer,
  inventoryReviewFilters: inventoryReviewFilterReducer,
  inventoryReprocessFilters: inventoryReprocessFilterReducer,
  inventroyHoldFilters: inventoryHoldFilterReducer,
  orderFilters: OrderFilterReducer,
  notificationFilters: NotificationFilterReducer,
  holdBannerStatus: HoldBannerStatusReducer,
  allCycleCountFilters: allCycleCountFilterReducer,
  reviewCycleCountFilters: reviewCycleCountFilterReducer,
  snackBarState: snackBarReducer,
  spinnerState: spinnerReducer,
});
