/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line react/jsx-wrap-multilines
import React, { FC, useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import Modal from '@salesforce/design-system-react/components/modal';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Button from '@salesforce/design-system-react/components/button';
import moment from 'moment';
import { getUTCDateTime } from '../../../../util/utilityFunctions';
import './index.scss';
import CREATE_UPDATE_CYCLE_COUNT_COMMENT from '../../../../graphql/mutations/createUpdateCycleCountComments';

interface Comments {
  id?: any;
  type?: any;
  postTime: any;
  user?: any;
}

interface Props {
  children?: FC;
  isOpen: boolean;
  toggleOpen: () => void;
  toggleOpenOnAdd: () => void;
  handleCancelButton?: () => void;
  createCommentLoading: boolean;
  comments: any;
  disabled: boolean;
}

const AddCommentsModal: FC<Props> = ({
  createCommentLoading,
  toggleOpen,
  toggleOpenOnAdd,
  isOpen,
  comments,
  disabled,
}) => {
  const [commentText, setCommentText] = useState('');
  const [createComment] = useMutation(CREATE_UPDATE_CYCLE_COUNT_COMMENT);
  const commentsFlag = comments?.filter((i: any) => i.comments !== null);

  const changeCommentData = (event: any): void => {
    const { value } = event.target;
    setCommentText(value);
  };

  const [commentsList, setCommentList] = useState<Comments[]>();
  useEffect(() => {
    if (comments) {
      setCommentList(comments);
    }
  }, [comments]);
  const handleAdd = (list: any): void => {
    createComment({
      variables: {
        cycleCountLineExternalId: list[0].externalId,
        cycleCountLineId: list[0].sfid,
        comment: commentText,
        postTime: moment()
          .utc()
          .toString(),
        commentType: 'Internal',
        type: 'New',
      },
    }).then(() => toggleOpenOnAdd());
  };

  const formatDate = (value: any): string => {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];
    const date = new Date(value);
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month}, ${year}`;
  };

  const getTimezoneName = (formattedDate: any) => {
    const currentDate = new Date(formattedDate);
    const short = currentDate.toLocaleDateString(undefined);
    const full = currentDate.toLocaleDateString(undefined, { timeZoneName: 'long' });
    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
    }
    return full;
  };

  const getTimeStamp = (formattedDate: any) => {
    const currentDateTime = new Date(formattedDate);
    const hour =
      currentDateTime.getHours() === 0
        ? 12
        : currentDateTime.getHours() > 12
        ? currentDateTime.getHours() - 12
        : currentDateTime.getHours();
    const min =
      currentDateTime.getMinutes() < 10
        ? `0${currentDateTime.getMinutes()}`
        : currentDateTime.getMinutes();
    const ampm = currentDateTime.getHours() < 12 ? 'AM' : 'PM';
    const time = `${hour}:${min} ${ampm}`;

    return time;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleOpen}
      dismissOnClickOutside={false}
      id="internal_comment_modal"
      className="internal_comment_modal"
      ariaHideApp={false}
      footer={[
        createCommentLoading && (
          <div key="loader">
            <Spinner size="small" variant="base" assistiveText={{ label: 'Loading...' }} />
          </div>
        ),
        <Button
          label="Add"
          key="internal_save"
          variant="brand"
          className="slds-button_brand"
          disabled={commentText === '' || disabled}
          onClick={(): void => handleAdd(commentsList)}
        />,
      ]}
      heading={<div className="slds-float_left slds-text-title_bold filter_heading">Comments</div>}
    >
      <div className="comments-container-background">
        <section className="slds-p-around_medium slds-theme_shade">
          {commentsFlag?.length > 0 && (
            <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
              <div className="comments-container-cycle-count">
                {commentsList &&
                  commentsList.map((item: any) =>
                    item.comments
                      ? item.comments &&
                        item.comments.map((line: any) => (
                          <div className="comment-body">
                            <p className="comment-user-name">
                              {line.user || ''}
                              {line.postTime ? (
                                <span style={{ fontWeight: 500, marginLeft: '25px' }}>
                                  {moment(line.postTime).format('MM/DD/YYYY')}
                                  {`${' at '}`}
                                  {getTimeStamp(line.postTime)}
                                  {`${' '}`}
                                  {getTimezoneName(new Date())
                                    .match(/\b([A-Z])/g)
                                    ?.join('')}
                                </span>
                              ) : (
                                ''
                              )}
                            </p>
                            {/* <h4 className="comment-timestamp">
                              {`${formatDate(line.postTime)} at  ${moment(line.postTime).format(
                                'LT'
                              )}`}
                            </h4> */}
                            <p className="comments_text">{line.comments}</p>
                          </div>
                        ))
                      : null
                  )}
              </div>
            </div>
          )}
          <h1 className="slds-text-title_bold">Add Comment</h1>
          <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
            <textarea
              id="unique-id-1"
              rows={10}
              className="slds-textarea"
              value={commentText || ''}
              disabled={disabled}
              onChange={
                (event: any): void => changeCommentData(event)
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          </div>
        </section>
      </div>
    </Modal>
  );
};

export default AddCommentsModal;
