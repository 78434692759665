/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * React component for Input.
 * @module src/components/shared/Input
 */
import React, { FC, useCallback } from 'react';
import Input from '@salesforce/design-system-react/components/input';
import { AUTO_SELECT_FIELDS } from '../../../util/constants';

interface Props {
  label: string;
  placeholder?: string;
  type: string;
  minValue?: string;
  maxLength?: string;
  handleChangeText: (result: string, inputType: any) => void;
  key?: string;
  required?: boolean;
  value: string;
  disabled: boolean;
  name: string;
  showCount?: boolean;
  errorText?: string;
  onHnadleKeyPress?: (result: any) => void;
}
interface ValueInterface {
  value: string;
}

const InputField: FC<Props> = props => {
  const {
    label,
    placeholder,
    type,
    minValue,
    maxLength,
    handleChangeText,
    required,
    value,
    disabled,
    name,
    showCount,
    errorText,
    onHnadleKeyPress,
  } = props;
  const handleChange = useCallback(
    (_event, selectedValue: ValueInterface) => {
      const { value: inputValue } = selectedValue;
      _event.persist();
      const allowed = AUTO_SELECT_FIELDS.includes(name) ? inputValue !== '0' : true;
      if (allowed) {
        if (label === 'Delivery Contact Phone' || label === 'Onsite Contact Phone') {
          handleChangeText(inputValue, _event.nativeEvent.inputType);
        } else {
          handleChangeText(inputValue, undefined);
        }
      }
    },
    [handleChangeText, label, name]
  );

  const optionalText = required ? '' : '';

  return (
    <div className="slds-form-element slds-m-bottom_small" key={label}>
      <div className="slds-form-element__control">
        <Input
          label={`${label + optionalText}`}
          id={label}
          placeholder={placeholder}
          type={type}
          minValue={minValue}
          onChange={handleChange}
          maxLength={maxLength}
          value={value || ''}
          disabled={disabled}
          errorText={errorText}
          required={required}
          onKeyPress={onHnadleKeyPress}
        />
        {value && showCount && (
          <div style={{ color: '#3e3e3c', fontSize: '0.75rem', textAlign: 'end' }}>
            Count:
            {`${value.length}/${maxLength}`}
          </div>
        )}
      </div>
    </div>
  );
};

/** React component for Input. */
export default InputField;
