/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable array-callback-return */
/**
 * Save Filter Modal.
 * @module src/components/FilterEvent/SaveFilter
 */
import React, { ChangeEvent, FC } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import './index.scss';
import CustomCombobox from '../../../../Shared/CustomCombobox/index';

interface Option {
  id: string;
  name: string;
  email: string;
  label?: string;
  isfavourite?: boolean;
  __typename: string;
  contactRole: string;
}
interface Props {
  loading: boolean;
  allContacts: Option[];
  handleContactModalVisible: () => void;
  contactModalVisible: boolean;
  handleAddContacts: (name: any) => void;
  selectedContact: any;
  contactsDataOption: Option[];
  favourites: Option[];
  selectedOptions: Option[];
  toggleFavorite: (arg: Option) => void;
  toggleOption: (arg: Option) => void;
  removeSelectedOption: (arg: Option) => void;
  handleSearch: (arg: ChangeEvent<HTMLInputElement>) => void;
}
const SaveFilter: FC<Props> = ({
  loading,
  allContacts,
  contactModalVisible,
  handleContactModalVisible,
  handleAddContacts,
  contactsDataOption,
  favourites,
  toggleFavorite,
  selectedOptions,
  toggleOption,
  removeSelectedOption,
  handleSearch,
}) => {
  const optionsWithLabel = (value: Option[]): Option[] =>
    value?.map((element: Option) => {
      // eslint-disable-next-line no-underscore-dangle
      if (element.__typename === 'UserContactsList') {
        return {
          ...element,
          label: `${element.name} <${element.email || 'No Email Exists'}> ${element.contactRole ||
            '<No Role Exists>'}`,
        };
      }
      return element;
    });

  const contactData = optionsWithLabel(contactsDataOption);

  const handleCreatePreset = (): void => {
    handleAddContacts(selectedOptions);
  };

  return (
    <Modal
      className="save-filter-modal default-modal_header default-modal_footer"
      ariaHideApp={false}
      isOpen={contactModalVisible}
      footer={[
        <Button label="Cancel" onClick={handleContactModalVisible} key="save_filter_cancel" />,
        loading ? (
          <Button label="Adding..." variant="brand" key="saving" disabled />
        ) : (
          <Button
            label="Add"
            disabled={selectedOptions?.length === 0 && favourites?.length === 0}
            variant="brand"
            onClick={handleCreatePreset}
            key="save_filter"
          />
        ),
      ]}
      onRequestClose={handleContactModalVisible}
      heading="Add From Contact"
    >
      <div className="slds-text-align--left">
        <div style={{ padding: 20, paddingBottom: 40 }}>
          <CustomCombobox
            allContacts={optionsWithLabel(allContacts)}
            options={contactData || []}
            favourites={favourites}
            toggleFavorite={(result: Option): void => {
              toggleFavorite(result);
            }}
            selectedOptions={selectedOptions}
            toggleOption={(result: Option): void => {
              toggleOption(result);
            }}
            removeSelectedOption={(result: Option): void => {
              removeSelectedOption(result);
            }}
            handleSearch={(result: ChangeEvent<HTMLInputElement>): void => {
              handleSearch(result);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

/** Save Filter event modal component */
export default SaveFilter;
