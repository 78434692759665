/*
 * @module src/graphql/getFavouriteContacts
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Filtered Accounts GraphQL API query */
export const GET_FAVOURITE_CONTACTS = gql`
  query getFavourites($salesRepId: String, $accountId: String) {
    getFavourites(salesRepId: $salesRepId, accountId: $accountId) {
      email
      name
    }
  }
`;
