/**
 * Redux file for getting/setting event filters
 * @module src/store/ducks/inventoryRequestFilters
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_IN_REQUEST_FILTERS = 'SET_IN_REQUEST_FILTERS';
const CLEAR_IN_REQUEST_FILTERS = 'CLEAR_IN_REQUEST_FILTERS';

interface SetInventoryRequestFiltersActionType {
  payload: string | null;
  type: typeof SET_IN_REQUEST_FILTERS;
}

interface ClearInventoryRequestFiltersActionType {
  type: typeof CLEAR_IN_REQUEST_FILTERS;
}

/** eventFilters action type */

export type InventoryRequestFilterActionType =
  | SetInventoryRequestFiltersActionType
  | ClearInventoryRequestFiltersActionType;

/** set eventFilters action creator */

export const setInventoryRequestFilters = (
  state: string | null
): InventoryRequestFilterActionType => {
  return {
    payload: state,
    type: SET_IN_REQUEST_FILTERS,
  };
};

/** clear eventFilters action creator */

export const clearInventoryRequestFilters = (): ClearInventoryRequestFiltersActionType => {
  return {
    type: CLEAR_IN_REQUEST_FILTERS,
  };
};

/** eventFilters state type */

export type InventoryRequestFilterState = string | null;

/** eventFilters reducer */

export default (
  state: InventoryRequestFilterState = null,
  action: ActionType
): InventoryRequestFilterState => {
  switch (action.type) {
    case SET_IN_REQUEST_FILTERS: {
      return action.payload;
    }
    case CLEAR_IN_REQUEST_FILTERS: {
      return null;
    }
    default:
      return state;
  }
};

/** selector to get accessToken */

export const getInventoryRequestFilters = (state: State): InventoryRequestFilterState => {
  return state.inventoryRequestFilters;
};
