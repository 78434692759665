/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * React component for Date Picker.
 * @module src/components/shared/DatePicker
 */
import React, { FC, useCallback } from 'react';
import Datepicker from '@salesforce/design-system-react/components/date-picker';
import { format, isValid } from 'date-fns';

interface Props {
  label: string;
  placeholder?: string;
  key?: string;
  handleChangeDate: (date: Date | null) => void;
  value: any;
  disabled: boolean;
  required?: boolean;
  isFilterDate?: boolean;
}

const DatePicker: FC<Props> = props => {
  const { label, placeholder, handleChangeDate, value, disabled, required, isFilterDate } = props;

  const validateDate = (data: any): boolean => {
    const letters = /^[A-Za-z]+$/;
    if (
      data.formattedDate &&
      !data.formattedDate.match(letters) &&
      !data.formattedDate.match(/^ *$/)
    ) {
      return true;
    }
    return false;
  };

  const handleChange = useCallback(
    (_event, data: any) => {
      _event.persist();

      const isValidDate = isValid(new Date(data.date));
      const isValidFormattedDate = isValid(new Date(data.formattedDate));
      const date = new Date(data.formattedDate);
      const validDate = validateDate(data);

      if (data && isValidDate && validDate) {
        handleChangeDate(data.date);
      } else if (isValidFormattedDate && validDate) {
        handleChangeDate(date);
      } else {
        handleChangeDate(null);
      }
    },
    [handleChangeDate]
  );

  const dateDisabled = (input: any): boolean => {
    if (!isFilterDate) {
      const d = new Date();
      return input.date < d.setDate(d.getDate() - 1);
    }
    return false;
  };

  const parserFormatter = 'MM-dd-yyyy';
  const formatter = 'M/d/yyyy';
  const dateValue = value && format(new Date(value), formatter);

  return (
    <div className="slds-form-element slds-m-bottom_small" key={label}>
      <Datepicker
        required={required}
        labels={{
          label,
        }}
        dateDisabled={dateDisabled}
        placeholder={placeholder}
        onChange={handleChange}
        formatter={(date: Date): string => {
          return date ? format(date, formatter) : '';
        }}
        parser={(dateString: string): Date | null => {
          try {
            const date = new Date(dateString);
            return new Date(format(date, parserFormatter));
          } catch {
            return new Date(format(new Date(), parserFormatter));
          }
        }}
        formattedValue={dateValue}
        disabled={disabled}
      />
    </div>
  );
};

/** React component for Date Picker. */
export default DatePicker;
