import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Button from '@salesforce/design-system-react/components/button';
import { BrowserRouter as Router, RouteComponentProps, withRouter } from 'react-router-dom';

interface EventId {
  caseExternalId: string;
  eventId: string;
  caseNumber: string;
  orderId: string;
}

// interface for props that the data table cell is getting default from data table.
export interface Props extends RouteComponentProps {
  children?: FC;
  item?: EventId;
  type?: string;
}

const OrderRequestIdCell: FC<Props> = ({
  item = {
    caseExternalId: null,
    orderId: null,
    eventId: null,
    caseNumber: null,
  },
  history,
  type = null,
}) => {
  const { eventId, caseNumber, caseExternalId, orderId } = item;
  const goToOrderDetails = (): void => {
    if (type === 'Favorites') {
      history.push('/inventoryFavoriteDetails', {
        inventoryRequestFav: item,
      });
    } else if (type === 'Processed' || type === 'Failed') {
      history.push('/inventoryRequestDetails', {
        isEventInventoryFlow: false,
        externalId: orderId || caseExternalId,
        type: orderId ? type : 'Open',
      });
    } else if (type === 'Open') {
      history.push('/inventoryRequestDetails', {
        isEventInventoryFlow: false,
        externalId: caseExternalId,
        type,
      });
    }
  };

  return (
    <Router>
      <DataTableCell title={eventId || ''}>
        <Button label={eventId || caseNumber || 'TBD'} variant="base" onClick={goToOrderDetails} />
      </DataTableCell>
    </Router>
  );
};

export default withRouter(OrderRequestIdCell);
