/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable import/no-cycle */
import React, { FC, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import SnackBar from '../../../Shared/SnackBar';
import { UsageSheetDetails } from '../UsageDetailsHeader';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import UsageParts from './Parts';

import UsageSurgerySheet from './SurgerySheet';
import '../index.scss';
import { GET_UPDATED_RESOLVED_PRICE } from '../../../../graphql/getUpdatedResolvedPrice';
import CREATE_UPDATE_USAGE_LINE_ITEMS from '../../../../graphql/mutations/createUpdateUsageSheetLineItems';
import { NOTIFY_TYPE, USAGE_TYPE } from '../../../../util/constants';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import UsageReplenishment from './Replenishment';
import UsagePricing from './Pricing';
import ShippingTab from './ShippingTab';

import { getSvgIcon } from '../../../../util/utilityFunctions';

interface Props {
  usageDetails?: UsageSheetDetails;
  refetch: () => void;
  eventType?: string;
  viewOnly: boolean;
  isAssignUser: boolean;
  isERPOrder: boolean;
  isERPOrderFailed: boolean;
  orderResubmitPermission: boolean;
  orderDetails?: any;
  detailsConfig?: any;
  type: string;
  screenLoading: boolean;
  triggerSubmit: any;
  selectedUsageTab: any;
}

const UsageTabs: FC<Props> = ({
  usageDetails,
  refetch,
  eventType,
  viewOnly,
  isAssignUser,
  isERPOrder,
  isERPOrderFailed,
  orderResubmitPermission,
  orderDetails,
  detailsConfig,
  type,
  screenLoading,
  triggerSubmit,
  selectedUsageTab,
}) => {
  const [count, setCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isPartChecked, setIsPartChecked] = useState(false);
  const [isPricingAccessed, setIsPricingAccessed] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isPriceUpdate, setPriceUpdate] = useState(false);
  const { open, notification, openSnackBar } = useSnackBar();
  const catalogInd = getSvgIcon('catalog');
  const [getResolvedPrice, { data: updatedPrice, loading: pricingLoader }] = useLazyQuery(
    GET_UPDATED_RESOLVED_PRICE,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [createUpdateUsageLineItems] = useMutation(CREATE_UPDATE_USAGE_LINE_ITEMS);
  useEffect(() => {
    if (usageDetails?.lineItems) {
      const totalCount = usageDetails.lineItems.reduce((acc: any, curr: any) => {
        return acc + Number(curr.quantity);
      }, 0);
      if (totalCount !== count) {
        setIsPricingAccessed(false);
      }
      setCount(totalCount || 0);

      if (
        usageDetails.lineItems.length > 0
        // eventType !== EVENT_TYPE.DEMO &&
        // eventType !== EVENT_TYPE.TRIAL
      ) {
        getResolvedPrice({
          variables: {
            productNumbers: usageDetails.lineItems.map((i: any) => i.productNumber),
            caseExternalId: usageDetails?.caseExternalId,
            accountNumber: usageDetails.accountNumber,
          },
        });
      }
    }
    // Set Active Tab
    const refetchedOntab = localStorage.getItem('refetchedOnTab');
    if (refetchedOntab) {
      setSelectedTabIndex(Number(refetchedOntab));
      // Clear Refetch Tab
      localStorage.setItem('refetchedOnTab', '');
    }

    // On Unmount - clear refetched tab if not refetched on tab
    return (): void => {
      if (!localStorage.getItem('refetchedOnTab')) {
        localStorage.setItem('refetchedOnTab', '');
      }
    };
  }, [eventType, getResolvedPrice, usageDetails, count]);

  useEffect(() => {
    if (usageDetails && usageDetails?.lineItems.length > 0) {
      const result = usageDetails?.lineItems.find(
        (lineItem: any) =>
          lineItem.isSelected === true &&
          (lineItem.shipToId === null ||
            lineItem.shipToId === '' ||
            lineItem.serviceLevel === null ||
            lineItem.serviceLevel === '' ||
            lineItem.needByDate === null ||
            lineItem.needByDate === '')
      );
      if (result) {
        setIsPartChecked(true);
      } else {
        setIsPartChecked(false);
      }
    }
  }, [usageDetails]);

  const refetchUsage = (): void => {
    localStorage.setItem('refetchedOnTab', selectedTabIndex.toString() || '');
    refetch();
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getEnabledTabs = (key: string) => {
    let enabledTabs: any[] = [];
    if (detailsConfig && detailsConfig[key]) {
      detailsConfig[key].forEach((item: any) => {
        if (item.isShow === true) {
          enabledTabs = [...enabledTabs, item.mappingKey];
        }
      });

      if (key === 'shipping_tab') {
        detailsConfig?.parts_tab.forEach((item: any) => {
          if (item.isShow === true && item.mappingKey === 'actual_qty') {
            enabledTabs = [...enabledTabs, item.mappingKey];
          }
        });

        detailsConfig?.pricing_tab.forEach((item: any) => {
          if (item.isShow === true && item.mappingKey === 'actual_uom') {
            enabledTabs = [...enabledTabs, item.mappingKey];
          }
        });
      }
    }
    return enabledTabs;
  };

  useEffect(() => {
    let updateSelectedTabIndex = 1;
    if (getEnabledTabs('replenishment_tab').includes('replenishment_tab')) {
      updateSelectedTabIndex = 2;
    }
    if (usageDetails?.lineItems && usageDetails.lineItems.length > 0 && !isPriceUpdate) {
      const isShipAndBillCondition =
        usageDetails?.diplayOrderType === USAGE_TYPE.SHIP_AND_BILL &&
        (selectedTabIndex === 1 || selectedTabIndex === 2);
      const isBillOnlyCondition =
        usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY &&
        (selectedTabIndex === updateSelectedTabIndex ||
          selectedTabIndex === updateSelectedTabIndex + 1);
      if (isShipAndBillCondition || isBillOnlyCondition) {
        setPriceUpdate(true);
        setLoader(true);
        const payload: Array<any> = [];
        usageDetails.lineItems.forEach((product: any, index: number): void => {
          payload.push({
            sfid: product.sfid,
            externalId: product.externalId,
            productId: product.productId,
            quantity: product.quantity.toString(),
            actualQuantity: product?.actualQuantity
              ? parseFloat(product?.actualQuantity.toString()).toFixed(2)
              : parseFloat(product?.quantity.toString()).toFixed(2),
            lotNumber:
              (product.selectedLot && product.selectedLot[0]?.lotNumber) || product.lotNumber,
            isLotNumber: product?.isLotNumber,
            productNumber: product.productNumber,
            serialNumber: (index + 1).toString(),
            usgPrice: product.usgPrice,
            priceOverride: product.priceOverride || null,
            overridePrice: !!product.priceOverride,
            priceOverrideType: product.priceOverrideType,
            isValidLotSerialCombination: product?.isValidLotSerialCombination || false,
            isLotValueUpdated: false,
            sourceLocation: product?.sourceLocation || '',
            iscatalog: product?.iscatalog,
            wasted: product?.wasted || false,
            subInventoryCode: product?.caseUsageSubInventoryCode,
            usgTotalAmount: parseFloat(
              (product.actualQuantity * product.usgPrice).toString()
            ).toFixed(2),
          });
        });
        const mutation = {
          caseId: usageDetails?.caseId,
          caseExternalId: usageDetails?.caseExternalId,
          salesRepId: usageDetails?.salesRepId,
          formType: 'Update',
          tabName: 'Pricing',
          lineItems: payload,
          system: 'Web',
          version: process.env.REACT_APP_VERSION || '0.0.1',
        };
        createUpdateUsageLineItems({
          variables: mutation,
        })
          .then(() => {
            refetch();
            setTimeout(() => {
              setLoader(false);
            }, 3000);
          })
          .catch(res => {
            if (res && res.message) {
              openSnackBar(NOTIFY_TYPE.ERROR, res.message);
              setLoader(false);
            } else {
              openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
            }
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabIndex]);

  const handleTabSelect = (value: number): void => {
    const checkReplenishment = getEnabledTabs('replenishment_tab')?.includes('replenishment_tab');
    selectedUsageTab(checkReplenishment ? value : value + 1);
    if (value === 1 && usageDetails?.lineItems && usageDetails.lineItems.length > 0) {
      setIsPricingAccessed(true);
      if (type !== 'Processed') {
        setLoader(true);
        const payload: Array<any> = [];
        usageDetails.lineItems.forEach((product: any, index: number): void => {
          payload.push({
            sfid: product.caseUsageId ? product.caseUsageId : product.sfid,
            externalId: product.caseUsageExternalId
              ? product.caseUsageExternalId
              : product.externalId,
            productId: product.productId,
            quantity: product.quantity.toString(),
            actualQuantity: parseFloat(product.actualQuantity.toString()).toFixed(2),
            lotNumber:
              (product.selectedLot && product.selectedLot[0]?.lotNumber) || product.lotNumber,
            isLotNumber: product?.isLotNumber,
            productNumber: product.productNumber,
            serialNumber: (index + 1).toString(),
            usgPrice: product.usgPrice,
            priceOverride: product.priceOverride || null,
            overridePrice: !!product.priceOverride,
            priceOverrideType: product.priceOverrideType,
            subInventoryCode: product?.caseUsageSubInventoryCode,
            isValidLotSerialCombination: product?.isValidLotSerialCombination || false,
            isLotValueUpdated: false,
            sourceLocation: product?.sourceLocation || '',
            iscatalog: product?.iscatalog,
            usgTotalAmount: parseFloat(
              (product.actualQuantity * product.usgPrice).toString()
            ).toFixed(2),
          });
        });
        const mutation = {
          caseId: usageDetails?.caseId,
          caseExternalId: usageDetails?.caseExternalId,
          salesRepId: usageDetails?.salesRepId,
          formType: 'Update',
          tabName: 'Pricing',
          lineItems: payload,
        };
        createUpdateUsageLineItems({
          variables: mutation,
        })
          .then(() => {
            refetch();
            setLoader(false);
          })
          .catch(res => {
            if (res && res.message) {
              openSnackBar(NOTIFY_TYPE.ERROR, res.message);
              setLoader(false);
            } else {
              openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
            }
          });
      }
    } else if (
      value === 2 &&
      !isPricingAccessed &&
      usageDetails?.lineItems &&
      usageDetails.lineItems.length > 0
    ) {
      if (type !== 'Processed') {
        setLoader(true);
        const payload: Array<any> = [];
        usageDetails.lineItems.forEach((product: any, index: number): void => {
          payload.push({
            sfid: product.caseUsageId ? product.caseUsageId : product.sfid,
            externalId: product.caseUsageExternalId
              ? product.caseUsageExternalId
              : product.externalId,
            productId: product.productId,
            quantity: product.quantity.toString(),
            actualQuantity: parseFloat(product.actualQuantity.toString()).toFixed(2),
            lotNumber:
              (product.selectedLot && product.selectedLot[0]?.lotNumber) || product.lotNumber,
            isLotNumber: product?.isLotNumber,
            productNumber: product.productNumber,
            serialNumber: (index + 1).toString(),
            usgPrice: product.usgPrice,
            priceOverride: product.priceOverride || null,
            overridePrice: !!product.priceOverride,
            priceOverrideType: product.priceOverrideType,
            isValidLotSerialCombination: product?.isValidLotSerialCombination || false,
            isLotValueUpdated: false,
            sourceLocation: product?.sourceLocation || '',
            iscatalog: product?.iscatalog,
            subInventoryCode: product?.caseUsageSubInventoryCode,
            usgTotalAmount: parseFloat(
              (product.actualQuantity * product.usgPrice).toString()
            ).toFixed(2),
          });
        });
        const mutation = {
          caseId: usageDetails?.caseId,
          caseExternalId: usageDetails?.caseExternalId,
          salesRepId: usageDetails?.salesRepId,
          formType: 'Update',
          tabName: 'Pricing',
          lineItems: payload,
        };
        createUpdateUsageLineItems({
          variables: mutation,
        })
          .then(() => {
            refetch();
            setLoader(false);
          })
          .catch(res => {
            if (res && res.message) {
              openSnackBar(NOTIFY_TYPE.ERROR, res.message);
              setLoader(false);
            } else {
              openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
            }
          });
      }
    } else if (
      value === 2 &&
      !isPricingAccessed &&
      usageDetails?.lineItems &&
      usageDetails.lineItems.length > 0
    ) {
      if (type !== 'Processed') {
        setLoader(true);
        const payload: Array<any> = [];
        usageDetails.lineItems.forEach((product: any, index: number): void => {
          payload.push({
            sfid: product.caseUsageId ? product.caseUsageId : product.sfid,
            externalId: product.caseUsageExternalId
              ? product.caseUsageExternalId
              : product.externalId,
            productId: product.productId,
            quantity: product.quantity.toString(),
            actualQuantity: parseFloat(product.actualQuantity.toString()).toFixed(2),
            lotNumber:
              (product.selectedLot && product.selectedLot[0]?.lotNumber) || product.lotNumber,
            isLotNumber: product?.isLotNumber,
            productNumber: product.productNumber,
            serialNumber: (index + 1).toString(),
            usgPrice: product.usgPrice,
            priceOverride: product.priceOverride || null,
            overridePrice: !!product.priceOverride,
            priceOverrideType: product.priceOverrideType,
            subInventoryCode: product?.caseUsageSubInventoryCode,
            isValidLotSerialCombination: product?.isValidLotSerialCombination || false,
            isLotValueUpdated: false,
            sourceLocation: product?.sourceLocation || '',
            iscatalog: product?.iscatalog,
            usgTotalAmount: parseFloat(
              (product.actualQuantity * product.usgPrice).toString()
            ).toFixed(2),
          });
        });
        const mutation = {
          caseId: usageDetails?.caseId,
          caseExternalId: usageDetails?.caseExternalId,
          salesRepId: usageDetails?.salesRepId,
          formType: 'Update',
          tabName: 'Pricing',
          lineItems: payload,
        };
        createUpdateUsageLineItems({
          variables: mutation,
        })
          .then(() => {
            refetch();
            setLoader(false);
          })
          .catch((res: any) => {
            if (res && res.message) {
              openSnackBar(NOTIFY_TYPE.ERROR, res.message);
              setLoader(false);
            } else {
              openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
            }
          });
      }
    }
    setSelectedTabIndex(value);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getInheritedColumns = (column: string, tabs: []) => {
    const inherited: any = tabs.find((tab: any) => {
      return tab.mappingKey === column;
    });
    return inherited ? inherited.isShow : true;
  };

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      {(loader || pricingLoader) && <Spinner />}
      <Tabs
        variant="scoped"
        id={
          usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY
            ? 'usage-tabs-scoped-returns'
            : 'usage-tabs-scoped'
        }
        onSelect={handleTabSelect}
        defaultSelectedIndex={0}
        selectedIndex={selectedTabIndex}
      >
        <TabsPanel label="Parts">
          {detailsConfig && (
            <UsageParts
              usageDetails={usageDetails}
              refetch={refetchUsage}
              count={count}
              type={type}
              viewOnly={viewOnly}
              isAssignUser={isAssignUser}
              isERPOrder={isERPOrder}
              isERPOrderFailed={isERPOrderFailed}
              updatedPrice={updatedPrice}
              orderDetails={orderDetails}
              partsDetailsConfig={detailsConfig?.parts_tab}
              screenLoading={screenLoading || pricingLoader || loader}
            />
          )}
        </TabsPanel>
        {detailsConfig && getEnabledTabs('replenishment_tab').includes('replenishment_tab') && (
          <TabsPanel label="Replenishment">
            {detailsConfig && (
              <UsageReplenishment
                usageDetails={usageDetails}
                refetch={refetchUsage}
                count={count}
                isAssignUser={isAssignUser}
                type={type}
                viewOnly={viewOnly}
                replenishmentConfig={detailsConfig?.replenishment_tab}
                selectedIndex={selectedTabIndex}
              />
            )}
          </TabsPanel>
        )}
        <TabsPanel label="Pricing">
          {detailsConfig && (
            <UsagePricing
              usageDetails={usageDetails}
              refetch={refetchUsage}
              eventType={eventType}
              isAssignUser={isAssignUser}
              count={count}
              viewOnly={viewOnly}
              type={type}
              wasted={getInheritedColumns('wasted', detailsConfig?.parts_tab)}
              actualQty={getInheritedColumns('actual_qty', detailsConfig?.parts_tab)}
              updatedPrice={updatedPrice}
              isERPOrder={isERPOrder}
              pricingDetailsConfig={detailsConfig?.pricing_tab}
              detailsConfig={detailsConfig}
              screenLoading={screenLoading || pricingLoader || loader}
            />
          )}
        </TabsPanel>
        <TabsPanel label="Usage Sheet">
          <UsageSurgerySheet
            usageDetails={usageDetails}
            refetch={refetchUsage}
            eventType={eventType}
            updatedPrice={updatedPrice}
            viewOnly={viewOnly}
            type={type}
            isAssignUser={isAssignUser}
            isPartChecked={isPartChecked}
            isERPOrderFailed={isERPOrderFailed}
            orderResubmitPermission={orderResubmitPermission}
            detailsConfig={detailsConfig}
            usageSheetDetailsConfig={getEnabledTabs('usageSheet_tab')}
            pricingModalConfig={getEnabledTabs('priceDetail_tab')}
            screenLoading={screenLoading || pricingLoader || loader}
            triggerSubmit={triggerSubmit}
          />
        </TabsPanel>
        {usageDetails?.diplayOrderType === USAGE_TYPE.SHIP_AND_BILL && (
          <TabsPanel label="Shipping">
            <ShippingTab
              orderDetails={orderDetails}
              // isAssignUser={isAssignUser}
              tabConfig={getEnabledTabs('shipping_tab')}
            />
          </TabsPanel>
        )}
        {usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY && (
          <TabsPanel
            disabled
            onClick={(e: any) => {
              e.preventDefault();
            }}
            style={{
              cursor: 'default',
            }}
            label={
              <div
                style={{
                  display: 'inline-block',
                  cursor: 'default',
                  color: '#000000',
                }}
              >
                <img
                  src={catalogInd}
                  className="logo-image"
                  alt="company"
                  style={{ marginLeft: '8px', marginRight: '8px' }}
                />
                Catalog
              </div>
            }
          />
        )}
      </Tabs>
    </IconSettings>
  );
};

export default UsageTabs;
