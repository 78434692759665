import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
//  Interface for Data table data type for all the columns
export interface Props {
  children?: FC;
  item?: AccountEventName;
}

interface AccountEventName {
  eventDateTime?: string;
}
const OrderSurgeryDateCell: FC<Props> = ({
  item = {
    eventDateTime: '',
  },
}) => {
  const { eventDateTime } = item;
  return (
    <DataTableCell title={eventDateTime || ''}>
      <p style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{eventDateTime}</p>
    </DataTableCell>
  );
};

/** Custom Account/Event Name cell */
export default OrderSurgeryDateCell;
