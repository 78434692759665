/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Module for Edit Message Event
 * @module src/components/SurgicalDetail/SurgicalDetailEditMessage/index
 */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Button from '@salesforce/design-system-react/components/button';
import './index.scss';

interface Props {
  showEditMessageModal: boolean;
  handleModalToggle: () => void;
  salesRep: string;
}

const EventEditMessage: FC<Props> = ({ handleModalToggle, showEditMessageModal, salesRep }) => {
  return (
    <IconSettings iconPath="/icons">
      <Modal
        isOpen={showEditMessageModal}
        onRequestClose={handleModalToggle}
        dismissOnClickOutside={false}
        ariaHideApp={false}
        className="message_modal"
      >
        <section>
          <div key="editMessage">
            <div className="edit_message_text">
              {` Please contact your [ sales rep - ${salesRep} ] or customer service
              representative to make changes.`}
            </div>
            <div className="slds-align_absolute-center event_edit_button">
              <Button label="OK" onClick={handleModalToggle} />
            </div>
          </div>
        </section>
      </Modal>
    </IconSettings>
  );
};

/** Edit Message modal **/
export default EventEditMessage;
