import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_UPDATED_RESOLVED_PRICE = gql`
  query getUpdatedResolvedPrice(
    $productNumbers: [String]!
    $caseExternalId: String
    $accountNumber: String
  ) {
    getUpdatedResolvedPrice(
      productNumbers: $productNumbers
      caseExternalId: $caseExternalId
      accountNumber: $accountNumber
    ) {
      productNumber
      resolvedPrice
      listPrice
      contractId
      contractName
      sellingUOM
    }
  }
`;
