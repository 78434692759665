/**
 * Module for Custom Event Type Cell.
 * @module src/Events/EventsList
 */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import { getEventIconInner } from '../../../util/utilityFunctions';

interface Status {
  eventSubType: string;
  eventType: string;
}
export interface Props {
  children?: FC;
  item?: Status;
}

const EventsListEventTypeCell: FC<Props> = ({
  item = {
    eventSubType: '',
    eventType: '',
  },
}) => {
  const { eventSubType, eventType } = item;
  const iconPath = getEventIconInner(eventType);
  return (
    <DataTableCell title={eventType}>
      <img src={iconPath} className="logo-image" alt="company" />
      {eventSubType ? (
        <span className="slds-p-left_x-small">
          {eventSubType}
          <br />
          {eventType}
        </span>
      ) : (
        <span className="slds-p-left_x-small">{eventType}</span>
      )}
    </DataTableCell>
  );
};
/** Custom Event Type cell */
export default EventsListEventTypeCell;
