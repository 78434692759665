/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */

import React, { FC, useEffect, useState, useCallback } from 'react';
import Card from '@salesforce/design-system-react/components/card';
import Input from '@salesforce/design-system-react/components/input';
import Button from '@salesforce/design-system-react/components/button';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
// import InventoryApproveRejectDataCell from '../DataTableCells/InventoryApproveRejectDataCell';
import InventoryApproveRejectDataCell from './DataTableCells/InventoryApproveRejectDataCell';
import InventoryCounterDataCell from './DataTableCells/InventoryCounterDataCell';
import InventoryReturnTypeDataCell from './DataTableCells/InventoryReturnTypeDataCell';
import { MAX_QUANTITY } from '../../../util/constants';
import './index.scss';

interface Props {
  inventoryReturnDetails: InventoryReturnDetails;
  handleLineItemActions: (parts: any) => void;
  addLineItems: (parts: any) => void;
  saveLineItems: (parts: any) => void;
  viewOnly: boolean;
}
interface LineItems {
  availableQuantity: string;
  expirationDate: string;
  externalId: string;
  id: string;
  lotNumber: string;
  onhandQuantity: string;
  productDescription: string;
  productId: string;
  productNumber: string;
  quantity: string;
  status: string;
  type: string;
  workflowAction: string;
}

interface InventoryReturnDetails {
  lineItems: LineItems[];
  visualIndicator: string;
  status: string;
}

// CustomDataTableCell must have the same displayName as DataTableCell or it will be ignored.
InventoryApproveRejectDataCell.displayName = DataTableCell.displayName;
InventoryReturnTypeDataCell.displayName = DataTableCell.displayName;
InventoryCounterDataCell.displayName = DataTableCell.displayName;

const PartsInfo: FC<Props> = ({
  inventoryReturnDetails,
  handleLineItemActions,
  addLineItems,
  saveLineItems,
  viewOnly,
}) => {
  const { lineItems } = inventoryReturnDetails;
  const [lineItemsData, setLineItemsData] = useState<any>(lineItems);
  useEffect(() => {
    if (lineItemsData) {
      const finalLineItems = lineItems.map((k: any) => {
        const result = lineItemsData.filter(
          (e: any) => e.externalId === k.externalId && e.productId === k.productId
        );
        const type =
          result && result.length > 0
            ? Array.isArray(result[0].type)
              ? result[0].type[0].value
              : result[0].type
            : null;
        return {
          ...k,
          type,
          userworkflowAction: null,
        };
      });
      setLineItemsData(finalLineItems);
      handleLineItemActions(finalLineItems);
    } else {
      const finalLineItems = lineItems.map((item: LineItems) => {
        return {
          ...item,
          userworkflowAction: null,
        };
      });
      setLineItemsData(finalLineItems);
      handleLineItemActions(finalLineItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItems, inventoryReturnDetails]);

  const handleCount = useCallback(
    (event: any, data: { value: string; number: number }, product: any): void => {
      const updatedList = [...lineItemsData];
      updatedList.forEach((element: any) => {
        if (
          product.productId === element.productId &&
          product.lotNumber === element.lotNumber &&
          product.id === element.id
        ) {
          /* eslint-disable no-param-reassign */
          if (!element.lotNumber) {
            element.maxQuantity = MAX_QUANTITY;
          } else {
            element.maxQuantity =
              element.availableQuantity > 0
                ? Number(element.quantity) + Number(element.availableQuantity)
                : element.quantity;
          }
          element.counterValue =
            data.number > element.maxQuantity
              ? element.maxQuantity.toString()
              : data.number.toString();
          element.changed = true;
        }
      });
      setLineItemsData(updatedList);
      handleLineItemActions(updatedList);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lineItemsData]
  );

  // const handleUserWorkflowAction = (changeItem: any): void => {
  //   const finalLineItems = lineItemsData.map((item: any) => {
  //     const { externalId, userworkflowAction } = item;
  //     return {
  //       ...item,
  //       userworkflowAction:
  //         changeItem.externalId === externalId ? changeItem.userworkflowAction : userworkflowAction,
  //     };
  //   });
  //   setLineItemsData(finalLineItems);
  //   handleLineItemActions(finalLineItems);
  // };

  // const handleReturnType = (changeItem: any): void => {
  //   const finalLineItems = lineItemsData.map((item: any) => {
  //     const { externalId, type } = item;
  //     return {
  //       ...item,
  //       type: changeItem.externalId === externalId ? changeItem.type : type,
  //     };
  //   });
  //   setLineItemsData(finalLineItems);
  //   handleLineItemActions(finalLineItems);
  // };

  const onSaveClick = () => {
    saveLineItems(lineItemsData);
  };

  // const handleSelectDropdownItem = (data: any, item: any): void => {
  //   const obj = {
  //     ...item,
  //     type: data.selection,
  //   };
  //   handleReturnType(obj);
  // };

  return (
    <div className="slds-m-around_small slds-m-bottom_none">
      <Card
        style={{ backgroundColor: '#545857', color: 'white', fontWeight: 600 }}
        id="PartsInfo"
        heading="Inventory"
        headerActions={
          <>
            <Button
              style={{ color: 'black', fontWeight: 600 }}
              iconCategory="utility"
              className="slds-text-color_default"
              iconName="add"
              label="Add"
              iconPosition="left"
              disabled={
                viewOnly ||
                inventoryReturnDetails.status === 'Completed' ||
                inventoryReturnDetails.visualIndicator === 'Completed'
              }
              onClick={addLineItems}
            />
            <Button
              style={{ color: 'black', fontWeight: 600 }}
              className="yellow-btn"
              label="Save"
              iconPosition="left"
              disabled={
                viewOnly ||
                inventoryReturnDetails.status === 'Completed' ||
                inventoryReturnDetails.visualIndicator === 'Completed'
              }
              onClick={onSaveClick}
            />
          </>
        }
      />
      <div style={{ overflow: 'auto' }}>
        <>
          <div className="parts-table-container-IR">
            <div>
              <div className="parts-table-header ir-cols">
                <p>Part #</p>
                <p>Lot</p>
                <p>Part Name</p>
                <p className="pad-left-60">Qty</p>
                {/* <p>Return Type</p> */}
              </div>
              {lineItemsData &&
                lineItemsData.map((lineItem: any) => {
                  const {
                    quantity,
                    availableQuantity,
                    counterValue,
                    workflowAction,
                    lotNumber,
                    productDescription,
                    productNumber,
                  } = lineItem;
                  const count = counterValue ? counterValue : quantity;
                  return (
                    <div>
                      <div>
                        <div className="parts-table-row ir-cols">
                          <p className="vertical-align-middle">{productNumber}</p>
                          <p className="vertical-align-middle">{lotNumber}</p>
                          <p className="vertical-align-middle">{productDescription}</p>
                          <div className="row-control">
                            {lotNumber ? (
                              <Input
                                id="counter-input-3"
                                minValue={0}
                                maxValue={Number(availableQuantity) + Number(quantity)}
                                disabled={
                                  viewOnly ||
                                  !!(
                                    workflowAction &&
                                    (workflowAction.toLowerCase() === 'accept' ||
                                      workflowAction.toLowerCase() === 'cancel')
                                  )
                                }
                                onChange={(
                                  event: any,
                                  data: { value: string; number: number }
                                ): void => {
                                  handleCount(event, data, lineItem);
                                }}
                                value={count}
                                variant="counter"
                              />
                            ) : (
                              <Input
                                id="counter-input-3"
                                minValue={0}
                                maxValue={MAX_QUANTITY}
                                disabled={
                                  viewOnly ||
                                  !!(
                                    workflowAction &&
                                    (workflowAction.toLowerCase() === 'accept' ||
                                      workflowAction.toLowerCase() === 'cancel')
                                  )
                                }
                                onChange={(
                                  event: any,
                                  data: { value: string; number: number }
                                ): void => {
                                  handleCount(event, data, lineItem);
                                }}
                                value={count}
                                variant="counter"
                              />
                            )}
                          </div>
                          {/* <div className="vertical-align-middle" style={{ paddingLeft: '10px' }}>
                            {type &&
                            workflowAction &&
                            workflowAction !== WORKFLOW_ACTION.NO_ACTION &&
                            workflowAction !== 'NoAction' ? (
                              <p>
                                <EventStatusBadge status={type} />
                              </p>
                            ) : (
                              <Combobox
                                id="combobox-readonly-single"
                                menuPosition="overflowBoundaryElement"
                                disabled={viewOnly}
                                events={{
                                  onSelect: (_event: any, data: any): void =>
                                    handleSelectDropdownItem(data, lineItem),
                                }}
                                labels={{
                                  placeholder: 'Select',
                                }}
                                options={RETURNS_TYPE_OPTION || []}
                                selection={type || []}
                                variant="readonly"
                                // defaultValue={userAction}
                              />
                            )}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default PartsInfo;
