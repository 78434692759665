/**
 * Custom Header component on detail popover.
 * @module src/components/Events/EventsCalendar/EventsCalendarDay/EventDetailPanel
 */
import React, { FC } from 'react';
import './index.scss';
import EventStatusBadge from '../../../../Shared/EventStatusBadge';
import { getEventIconInner } from '../../../../../util/utilityFunctions';

interface Props {
  eventData: {
    event: {
      eventType: string;
      externalId: string;
      eventId: string;
      id: string;
      eventStatus: string;
      procedures: string;
      eventDate: string;
      eventStartDate: Date;
      eventEndDate: Date;
      eventTime: string;
      accountName: string;
      eventName: string;
      surgeonFirstName: string;
      surgeonLastName: string;
      surgeonName?: string;
      eventSubType: string;
      salesRep: string;
      coveringReps: string;
    };
  };
}
const EventDetailPanelHeading: FC<Props> = ({ eventData }) => {
  const { event } = eventData;
  const { eventStatus, eventType, eventSubType } = event;
  const iconPath = getEventIconInner(eventType);

  return (
    <div className="slds-grid slds-grid_align-spread">
      <div className="slds-col">
        <span />
        <span className="slds-page-header__title slds-truncate popover_calendar_header">
          <span className="header_image">
            <img src={iconPath} className="logo-image" alt="company" />
          </span>
          {eventSubType || eventType}
        </span>
      </div>
      <div className="slds-col ">
        <EventStatusBadge status={eventStatus || 'NEW'} />
      </div>
    </div>
  );
};
/** Custom header component on Event detail popover panel */
export default EventDetailPanelHeading;
