/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/**
 * Module for header on InventoryRequestDetailCatalog Page
 * @module src/SurgicalDetail/InventoryRequestDetailCatalog
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
// import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import { Paper } from '@material-ui/core';
import moment from 'moment';
import './index.scss';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useMutation } from '@apollo/react-hooks';
import { getUserInfo } from '../../../../../store/ducks/userInfo';
import InventoryRequestAddItems from '../InventoryRequestAddItems';
import TransferEditLineItems from '../TransferEditLineItems';
import { TRANSFER_ACTIONS, NOTIFY_TYPE } from '../../../../../util/constants';
import SnackBar from '../../../../Shared/SnackBar';
import EventStatusBadge from '../../../../Shared/EventStatusBadge';
import useSnackBar from '../../../../../util/customHooks/useSnackBar';
import InventoryCart from './inventoryCart';
import { UPDATE_TRANSFER_INVENTORY } from '../../../../../graphql/mutations/updateTransferInventory';

interface Props {
  inventoryDetail?: any;
  transferType: string;
  selectedInventoryItems?: any;
  salesRepValue?: any;
  recivingSalesRepValue?: any;
  handleSelectedRow: (item: any) => void;
  refetchTransferDetails: () => void;
}

interface ItemPrivate {
  salesUnitOfMeasure: string;
  approvalStatus?: string;
  priceBookEntryId?: string;
  unitPrice?: string;
  externalId?: string;
  id?: string;
  quantity: string;
  availableqty: number | null | undefined;
  expirationDate: string;
  lotNumber: string;
  onhandqty: number | null;
  productDescription: string | null;
  productId: string;
  productNumber: string | null;
  subInventoryName: string | null;
}

interface LineItem {
  id: string;
  externalId: string;
  partNumber?: string;
  lotNumber: string;
  productId: string;
  quantity: string;
  priceBookEntryId: string;
  unitPrice: string;
  status: string;
  approvalStatus: string;
  salesUnitOfMeasure: string;
  productNumber: string;
  isLotNumber: boolean;
}

interface UserInfo {
  contactId?: string;
  division: string | null;
  divisions: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  personas: string;
  userName: string | null;
  userType: string;
}

const InventoryRequestHeader: FC<Props> = ({
  inventoryDetail,
  transferType,
  handleSelectedRow,
  refetchTransferDetails,
  // recivingSalesRepValue,
}) => {
  const userInfo: UserInfo | null = useSelector(getUserInfo);
  const userId = userInfo?.id;
  const { open, notification, openSnackBar } = useSnackBar();
  const [showSearchCatalogModal, setShowSearchCatalogModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [radiobutton, setRadioButton] = useState<any>([]);
  const [updateTransfer] = useMutation(UPDATE_TRANSFER_INVENTORY);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedLineItemInventoryList, setSelectedLineItemInventoryList] = useState<any>();
  const [count, setCount] = useState(
    inventoryDetail && inventoryDetail.lineItems && inventoryDetail.lineItems.length
      ? inventoryDetail.lineItems.length
      : 0
  );

  useEffect(() => {
    let tcount = 0;
    // eslint-disable-next-line no-unused-expressions
    if (
      inventoryDetail &&
      (inventoryDetail.status === 'Rejected' || 'New' || 'Recevied') &&
      // transferType &&
      // transferType === 'Sent' &&
      inventoryDetail &&
      inventoryDetail.lineItems &&
      inventoryDetail.lineItems.length > 0
    ) {
      // eslint-disable-next-line no-unused-expressions
      inventoryDetail &&
        inventoryDetail.lineItems &&
        inventoryDetail.lineItems.forEach((element: any) => {
          // eslint-disable-next-line radix
          tcount += parseInt(element.quantity);
        });
      setCount(tcount);
    }
  }, [count, inventoryDetail, transferType]);

  const onHandleCloseSearchCatalogModal = (): void => {
    setShowSearchCatalogModal(false);
  };

  const onHandleCloseEditModal = (): void => {
    setShowEditModal(false);
  };
  const handleSelectedLineItems = (item: any): void => {
    setSelectedLineItemInventoryList(item);
    setCount(item.length);
    handleSelectedRow(item);
  };

  // const [updateTransfer] = useMutation(UPDATE_TRANSFER_INVENTORY);
  const onEditButtonClick = (): void => {
    setShowEditModal(true);
  };

  const onSaveButtonTransfer = (): void => {
    const isAttempted = radiobutton.filter((item: any) => item.action === '');
    if (isAttempted.length > 0) {
      openSnackBar(NOTIFY_TYPE.ERROR, 'Please attempt all the inventory');
    } else {
      const updatedPickedItems = radiobutton.map(
        (element: any): LineItem => {
          return {
            id: element?.id || '',
            externalId: element.item.externalId || '',
            lotNumber: element.item.lotNumber || '',
            productId: element.item.productId || '',
            quantity: element.item.quantity || '',
            priceBookEntryId: element.item.priceBookEntryId || '',
            unitPrice: element.item.unitPrice || '',
            status: inventoryDetail.status || 'New',
            approvalStatus: element.action || '',
            salesUnitOfMeasure: element.item.salesUnitOfMeasure || '',
            productNumber: element.item.productNumber,
            isLotNumber: element.item.isLotNumber,
          };
        }
      );
      const updatedDetails = {
        orderId: inventoryDetail.id || '',
        orderExternalId: inventoryDetail.externalId || '',
        lineItems: updatedPickedItems || [],
        system: 'Web',
        version: process.env.REACT_APP_VERSION || '0.0.1',
      };
      updateTransfer({
        variables: updatedDetails,
      })
        .then(response => {
          if (
            response &&
            response?.data &&
            response?.data?.updateTransferInventory &&
            response?.data?.updateTransferInventory?.message &&
            response?.data?.updateTransferInventory?.message === 'success'
          ) {
            openSnackBar(NOTIFY_TYPE.SUCCESS, 'Inventory transfer saved successfully');
            setTimeout(() => {
              refetchTransferDetails();
            }, 500);
          } else {
            console.log('We had a trouble saving data to salesforce. Please try again later');
          }
        })
        .catch(error => {
          setTimeout(() => {
            if (error && error.message) {
              //    setLoader(false);
              openSnackBar(NOTIFY_TYPE.ERROR, `${error.message}`);
            } else {
              openSnackBar(
                NOTIFY_TYPE.ERROR,
                'Error: We had a trouble saving data to salesforce. Please try again later'
              );
            }
          }, 1000);
        });
    }
  };

  useEffect(() => {
    const radiosButtonarr =
      inventoryDetail &&
      inventoryDetail.lineItems.map((item: any) => {
        const { id } = item;
        return {
          id,
          action: item.approvalStatus
            ? item.approvalStatus === 'Sales Forecast'
              ? 'New'
              : item.approvalStatus
            : 'New',
          item,
        };
      });
    if (inventoryDetail && inventoryDetail.status === 'Requested') {
      setRadioButton(radiosButtonarr);
      handleSelectedRow(radiosButtonarr);
    }
  }, [inventoryDetail]); // eslint-disable-line react-hooks/exhaustive-deps

  function createDyanmicRow(item: any): any {
    const expDate = moment.utc(new Date(item.expirationDate)).format('MM/DD/YYYY');
    const row = (
      <div
        style={{ cursor: 'pointer' }}
        // onClick={(): void => handleSelect(item)}
        aria-hidden="true"
      >
        <ul className="slds-page-header__detail-row slds-grid slds-m-around_small">
          <li className="slds-col slds-size_11-of-12">
            <div className="slds-text-title_bold" style={{ borderBottom: '1px solid lightgray' }}>
              {item.productNumber}
              {'-'}
              {item.productDescription}
            </div>
            <div>
              <ul>
                <li className="slds-page-header__detail-block slds-col slds-size_12-of-12 slds-text-title">{`Lot# - ${item.lotNumber}`}</li>
              </ul>
              <ul>
                <li className="slds-text-title">{`Expiration Date : ${expDate}`}</li>
              </ul>
              <ul>
                <li
                  className="slds-text-title_bold"
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  {item.quantity}
                </li>
              </ul>
            </div>

            <div style={{ padding: '10px', backgroundColor: '#f4f4f4' }}>
              <RadioGroup
                row
                aria-label={item.productId}
                name="customized-radios"
                defaultValue={item.approvalStatus ? item.approvalStatus : 'New'}
                onChange={(event: any): void => {
                  const arr = radiobutton.map((i: any) => {
                    const obj = {
                      ...i,
                      action: i.id === item.id ? event.target.value : i.action,
                      item: i.item,
                    };
                    return obj;
                  });
                  handleSelectedRow(arr);
                  setRadioButton(arr);
                }}
              >
                {TRANSFER_ACTIONS.map(value => (
                  <FormControlLabel
                    value={value.value}
                    control={
                      <Radio
                        disabled={
                          item.approvalStatus === 'Approved' ||
                          inventoryDetail.visualIndicator === 'Completed' ||
                          inventoryDetail?.receivingRepId !== userId
                        }
                        color="primary"
                      />
                    }
                    label={value.name}
                  />
                ))}
              </RadioGroup>
            </div>
          </li>
        </ul>
      </div>
    );
    return row;
  }

  const actions = (): ReactNode => (
    <PageHeaderControl>
      <ButtonGroup className="slds-align-middle" id="button-group-page-header-actions">
        <div style={{ marginRight: '10px' }}>
          <InventoryCart count={count} color="white" textColor="black" />
        </div>
        {inventoryDetail &&
        inventoryDetail.status === 'New' &&
        transferType &&
        transferType === 'Sent' ? (
          <Button
            style={{ marginLeft: '10px', borderRadius: '0.25rem' }}
            iconCategory="utility"
            iconSize="small"
            align="right"
            iconPosition="right"
            label="Add"
            onClick={(): void => {
              setShowSearchCatalogModal(true);
            }}
          />
        ) : null}
        <div>
          {' '}
          {inventoryDetail &&
          (inventoryDetail.status === 'Requested' || inventoryDetail.status === 'New') &&
          transferType &&
          transferType === 'Sent' ? (
            <Button
              style={{ marginLeft: '10px', borderRadius: '0.25rem' }}
              iconCategory="utility"
              iconSize="small"
              align="right"
              iconPosition="right"
              label="Edit"
              onClick={onEditButtonClick}
              disabled={
                (inventoryDetail.visualIndicator &&
                  inventoryDetail.visualIndicator.toLowerCase() === 'requested') ||
                (inventoryDetail.visualIndicator &&
                  inventoryDetail.visualIndicator.toLowerCase() === 'cancelled') ||
                (inventoryDetail.visualIndicator &&
                  inventoryDetail.visualIndicator.toLowerCase() === 'approved') ||
                (inventoryDetail.visualIndicator &&
                  inventoryDetail.visualIndicator.toLowerCase() === 'completed')
              }
            />
          ) : null}
        </div>
        <div>
          {transferType &&
          transferType === 'Received' &&
          inventoryDetail?.receivingRepId === userId ? (
            <Button
              style={{ marginLeft: '10px', borderRadius: '0.25rem' }}
              iconCategory="utility"
              iconSize="small"
              align="right"
              iconPosition="right"
              label="Save"
              onClick={onSaveButtonTransfer}
            />
          ) : null}
        </div>
      </ButtonGroup>
    </PageHeaderControl>
  );
  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      {/* <PageHeader
        className="slds-page-header-inventory  slds-page-header__name-title-inventory slds-m-around_small slds-m-bottom_none"
        // onRenderActions={actions}
        title=""
        variant="record-home"
        id="header"
      /> */}
      {inventoryDetail &&
      transferType &&
      transferType === 'Sent' &&
      (inventoryDetail.status === 'New' || inventoryDetail.status === 'Requested') ? (
        <div
          className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none transferDetails"
          style={{ justifyContent: 'center', backgroundColor: '#f3f2f2' }}
        >
          <>
            <Paper style={{ width: '50%' }}>
              {inventoryDetail &&
                inventoryDetail.lineItems.map((lineItem: any) => (
                  <div
                    style={{
                      display: 'flex',
                      borderBottom: '1px solid lightgray',
                    }}
                  >
                    <div style={{ padding: '10px', width: '50%' }}>
                      {lineItem.productNumber}
                      {'-'}
                      {lineItem.productDescription}
                      <br />
                      {`Lot#-${lineItem.lotNumber}`}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '45%' }}>
                      <div
                        style={{
                          padding: '10px',
                        }}
                      >
                        <EventStatusBadge status={lineItem.approvalStatus || 'New'} />
                      </div>
                      <div
                        style={{
                          padding: '10px',
                          textAlign: 'end',
                        }}
                      >
                        {lineItem.quantity}
                      </div>
                    </div>
                  </div>
                ))}
            </Paper>
          </>
        </div>
      ) : null}
      {transferType !== 'Received' &&
      inventoryDetail &&
      (inventoryDetail.status === 'Complete' ||
        inventoryDetail.status === 'Cancelled' ||
        inventoryDetail.status === 'Closed') ? (
        // eslint-disable-next-line react/jsx-indent
        <div
          className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none transferDetails"
          style={{ justifyContent: 'center', backgroundColor: '#f3f2f2' }}
        >
          <>
            <Paper style={{ width: '50%' }}>
              {inventoryDetail &&
                inventoryDetail.lineItems.map((lineItem: any) => (
                  <div
                    style={{
                      display: 'flex',
                      borderBottom: '1px solid lightgray',
                    }}
                  >
                    <div style={{ padding: '10px', width: '50%' }}>
                      {lineItem.productNumber}
                      {'-'}
                      {lineItem.productDescription}
                      <br />
                      {`Lot#-${lineItem.lotNumber}`}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '45%' }}>
                      <div style={{ padding: '10px' }}>
                        <EventStatusBadge status={lineItem.approvalStatus || 'New'} />
                      </div>
                      <div
                        style={{
                          padding: '10px',
                          textAlign: 'end',
                        }}
                      >
                        {lineItem.quantity}
                      </div>
                    </div>
                  </div>
                ))}
            </Paper>
          </>
        </div>
      ) : null}
      {/* {inventoryDetail &&
      // (inventoryDetail.status === 'New' || inventoryDetail.status === 'Requested' || inventoryDetail.status === 'Rejected') &&
      transferType &&
      transferType === 'Received' ? (
        <div
          className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none transferDetails"
          style={{ justifyContent: 'center', backgroundColor: '#f3f2f2' }}
        >
          <>
            <Paper style={{ width: '50%' }}>
              {inventoryDetail &&
                inventoryDetail.lineItems.map((lineItem: any) => createDyanmicRow(lineItem))}
            </Paper>
          </>
        </div>
      ) : null} */}
      {showSearchCatalogModal && (
        <InventoryRequestAddItems
          isOpen={showSearchCatalogModal}
          refetchTransferDetails={refetchTransferDetails}
          handleClose={onHandleCloseSearchCatalogModal}
          inventoryReturnDetail={inventoryDetail}
          handleSelectedLineItems={handleSelectedLineItems}
          returnType={transferType}
          //  selectedFilter={handleSelectedLineItems}
          //  handleFilter={handleFilter}
        />
        // <InventoryRequestAddItems
        //   selectedLineItemInventoryList={selectedLineItemInventoryList}
        //   isOpen={showSearchCatalogModal}
        //   refetchTransferDetails={refetchTransferDetails}
        //   handleClose={onHandleCloseSearchCatalogModal}
        //   inventoryDetail={inventoryDetail}
        //   handleSelectedLineItems={handleSelectedLineItems}
        //   transferType={transferType}
        // />
      )}

      {showEditModal ? (
        <TransferEditLineItems
          isOpen={showEditModal}
          handleClose={onHandleCloseEditModal}
          refetchTransferDetails={refetchTransferDetails}
          inventoryDetail={inventoryDetail}
          InventoryTotalcount={count}
          handleSelectedLineItems={handleSelectedLineItems}
        />
      ) : null}
    </IconSettings>
  );
};

/** Custom Header on InventoryRequestDetailCatalog Page **/
export default InventoryRequestHeader;
