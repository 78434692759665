/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React, { FC, ReactNode, useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from '@salesforce/design-system-react/components/icon';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import CartComponent from '../../../../Shared/CartComponent';
import EventStatusBadge from '../../../../Shared/EventStatusBadge';
import { getSvgIcon } from '../../../../../util/utilityFunctions';
import userInfo from '../../../../../store/ducks/userInfo';

interface Props {
  orderDetails: any;
  tabConfig?: any;
}

const StyledTableRow = withStyles(() => ({
  root: {
    height: 35,
    backgroundColor: '#f4f4f4',
    color: '#000000',
    fontFamily: 'Salesforce Sans", Arial, sans-serif',
  },
}))(TableRow);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: '0px 12px',
    lineHeight: 'normal',
    marginLeft: '45px',
    textAlign: 'center',
  },
}))(TableCell);

const StyledTableHeaderCell = withStyles(() => ({
  root: {
    lineHeight: 'normal',
    marginLeft: '45px',
    fontWeight: 'bold',
    padding: '0px 12px',
    background: '#fafafa',
    textAlign: 'center',
  },
}))(TableCell);

const StyledDataTableRow = withStyles(() => ({
  root: {
    height: 35,
  },
}))(TableRow);

const StyledCollapsibleTableCell = withStyles(() => ({
  root: {
    padding: '0px 0px',
    fontWeight: 'bold',
  },
}))(TableCell);

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

// const StyledTableLotCell = withStyles(() => ({
//   root: {
//     padding: '0px 16px',
//     fontWeight: 'bold',
//     textAlign: 'center',
//   },
// }))(TableCell);

const Row = (props: any) => {
  const { row, expandAll, tabConfig } = props;
  const [open, setOpen] = useState(false);

  const classes = useRowStyles();

  useEffect(() => {
    setOpen(expandAll);
  }, [expandAll]);

  const iconBackorder = getSvgIcon('backorder');

  return (
    <>
      <StyledDataTableRow className={classes.root}>
        <StyledTableCell style={{ width: '6.5%' }}>
          {row?.shippingLineItems?.length > 0 && (
            <span>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {!open ? (
                  <Icon
                    assistiveText={{ label: 'Announcement' }}
                    category="utility"
                    name="chevronright"
                    size="x-small"
                    style={{
                      fill: '#000',
                      display: 'inline-block',
                      marginLeft: '21px',
                    }}
                  />
                ) : (
                  <Icon
                    assistiveText={{ label: 'Announcement' }}
                    category="utility"
                    name="chevrondown"
                    size="x-small"
                    style={{
                      fill: '#000',
                      display: 'inline-block',
                      marginLeft: '21px',
                    }}
                  />
                )}
              </IconButton>
            </span>
          )}
        </StyledTableCell>
        <StyledTableCell style={{ width: '16.5%', textAlign: 'left' }}>
          {`${row?.productNumber} - ${row?.productdescription}`}
        </StyledTableCell>
        <StyledTableCell
          style={{
            width: '10%',
            fontWeight: 'bold',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
            <EventStatusBadge status={row?.partStatus} />
            {row?.backorderedQuantity && Number(row?.backorderedQuantity) > 0 && (
              <img
                src={iconBackorder}
                alt="backorder"
                style={{ width: '20px', height: '15px', marginLeft: '5px' }}
              />
            )}
          </div>
        </StyledTableCell>
        <StyledTableCell style={{ width: '8%', fontWeight: 'bold' }}>
          {row?.quantity}
        </StyledTableCell>

        {tabConfig.includes('actual_uom') && (
          <StyledTableCell style={{ width: '6%' }}>
            <div style={{ paddingLeft: '5px', textAlign: 'center', fontWeight: 'bold' }}>
              {row.packContent && row.salesUnitOfMeasure === 'PK' ? `Pack/${row.packContent}` : ''}
              {row.packContent && row.salesUnitOfMeasure === 'BX' ? `Box/${row.packContent}` : ''}
              {row.salesUnitOfMeasure === 'EA' ? 'Each' : ''}
            </div>
          </StyledTableCell>
        )}
        {tabConfig.includes('actual_qty') && (
          <StyledTableCell style={{ width: '6%', fontWeight: 'bold' }}>
            {row.actualQuantity}
          </StyledTableCell>
        )}

        {tabConfig?.includes('backorder_qty') && (
          <StyledTableCell style={{ width: '9%', fontWeight: 'bold' }}>
            {row?.backorderedQuantity === '0' ? '' : row?.backorderedQuantity}
          </StyledTableCell>
        )}
        {tabConfig?.includes('shipped_qty') && (
          <StyledTableCell
            style={{
              width: '8%',
              fontWeight: 'bold',
            }}
          >
            {row?.deliveryQuantity === '0' ? '' : row?.deliveryQuantity}
          </StyledTableCell>
        )}
        <StyledTableCell style={{ width: '10%' }}>{}</StyledTableCell>
        {tabConfig?.includes('tracking#') && (
          <StyledTableCell style={{ width: '10%' }}>{}</StyledTableCell>
        )}
        {tabConfig?.includes('delivery') && (
          <StyledTableCell style={{ width: '10%' }}>{}</StyledTableCell>
        )}
      </StyledDataTableRow>
      <TableRow>
        <StyledCollapsibleTableCell colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                {row?.shippingLineItems?.map((shipping: any) => (
                  <StyledDataTableRow className={classes.root}>
                    <StyledTableCell style={{ width: '6.5%' }}>{}</StyledTableCell>
                    <StyledTableCell style={{ width: '16.5%' }}>{}</StyledTableCell>
                    <StyledTableCell style={{ width: '10%' }}>{}</StyledTableCell>
                    <StyledTableCell style={{ width: '8%' }}>{}</StyledTableCell>
                    {tabConfig.includes('actual_uom') && (
                      <StyledTableCell style={{ width: '6%' }}>{}</StyledTableCell>
                    )}
                    {tabConfig.includes('actual_qty') && (
                      <StyledTableCell style={{ width: '6%' }}>{}</StyledTableCell>
                    )}
                    {/* <StyledTableHeaderCell style={{ width: '6%' }}>{}</StyledTableHeaderCell>
                    <StyledTableHeaderCell style={{ width: '6%' }}>{}</StyledTableHeaderCell> */}
                    {tabConfig?.includes('backorder_qty') && (
                      <StyledTableCell style={{ width: '9%' }}>{}</StyledTableCell>
                    )}
                    {tabConfig?.includes('shipped_qty') && (
                      <StyledTableCell style={{ width: '8%' }}>
                        {shipping.deliveryQuantity ? Number(shipping.deliveryQuantity) : ''}
                      </StyledTableCell>
                    )}
                    {tabConfig?.includes('erp_order') && (
                      <StyledTableCell style={{ width: '10%' }}>
                        {shipping.erpOrderNumber}
                      </StyledTableCell>
                    )}
                    {tabConfig?.includes('tracking#') && (
                      <StyledTableCell style={{ width: '12%' }}>
                        {shipping?.trackingNumber && shipping?.trackingNumber !== 'N/A' ? (
                          <>
                            {shipping?.shipmentSource === 'FEDEX' && (
                              <a
                                rel="noreferrer noopener"
                                target="_blank"
                                href={`https://www.fedex.com/fedextrack/?trknbr=${shipping?.trackingNumber}`}
                              >
                                {shipping?.trackingNumber}
                              </a>
                            )}
                            {shipping?.shipmentSource === 'UPS' && (
                              <a
                                rel="noreferrer noopener"
                                target="_blank"
                                href={`https://www.ups.com/track?loc=en_US&tracknum=${shipping?.trackingNumber}`}
                              >
                                {shipping?.trackingNumber}
                              </a>
                            )}
                            {shipping?.shipmentSource !== 'FEDEX' &&
                              shipping?.shipmentSource !== 'UPS' &&
                              shipping?.trackingNumber}
                          </>
                        ) : (
                          ''
                        )}
                      </StyledTableCell>
                    )}
                    {tabConfig?.includes('delivery') && (
                      <StyledTableCell style={{ width: '12%' }}>
                        {shipping?.deliveredDate}
                      </StyledTableCell>
                    )}
                  </StyledDataTableRow>
                ))}
              </Table>
            </Box>
          </Collapse>
        </StyledCollapsibleTableCell>
      </TableRow>
    </>
  );
};

const ShippingTab: React.FC<Props> = ({ orderDetails, tabConfig }) => {
  const [open, setOpen] = useState(false);

  const lineItems = orderDetails?.lineItems || [];
  const shippedQuantity = orderDetails?.lineItems?.reduce(
    (accumulator: any, current: any) => accumulator + Number(current.deliveryQuantity),
    0
  );

  const headerActions = (): ReactNode => <PageHeaderControl />;

  return (
    <IconSettings iconPath="/icons">
      <PageHeader
        className="slds-page-header-usage slds-page-header__name-title-inventory slds-m-around_small slds-m-bottom_none marginOff"
        onRenderActions={headerActions}
        title={
          <>
            <span style={{ marginRight: '10px' }}>
              <CartComponent count={shippedQuantity} color="white" textColor="black" />
            </span>
            <span style={{ fontSize: '18px' }}>Shipping</span>
          </>
        }
        variant="record-home"
        id="header"
      />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <StyledTableRow>
            {lineItems?.length > 0 && (
              <StyledTableHeaderCell style={{ width: '6.5%' }}>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                  {open ? (
                    <Icon
                      category="utility"
                      name="normal_on-all_u593"
                      size="small"
                      style={{
                        display: 'inline-block',
                        marginLeft: '25px',
                        marginTop: '10px',
                        fill: 'white',
                      }}
                    />
                  ) : (
                    <Icon
                      category="utility"
                      name="double_arrow_collapse"
                      size="small"
                      style={{
                        display: 'inline-block',
                        marginLeft: '25px',
                        marginTop: '10px',
                        fill: 'white',
                      }}
                    />
                  )}
                </IconButton>
              </StyledTableHeaderCell>
            )}
            <StyledTableHeaderCell style={{ width: '16.5%', textAlign: 'left' }}>
              Part
            </StyledTableHeaderCell>
            <StyledTableHeaderCell style={{ width: '10%', textAlign: 'left', paddingLeft: '32px' }}>
              Status
            </StyledTableHeaderCell>
            <StyledTableHeaderCell style={{ width: '8%' }}>Ordered Qty</StyledTableHeaderCell>
            {tabConfig.includes('actual_uom') && (
              <StyledTableHeaderCell style={{ width: '6%' }}>Actual UoM</StyledTableHeaderCell>
            )}
            {tabConfig.includes('actual_qty') && (
              <StyledTableHeaderCell style={{ width: '6%' }}>Actual Qty</StyledTableHeaderCell>
            )}
            {tabConfig?.includes('backorder_qty') && (
              <StyledTableHeaderCell style={{ width: '9%' }}>Backorder Qty</StyledTableHeaderCell>
            )}

            {tabConfig?.includes('shipped_qty') && (
              <StyledTableHeaderCell style={{ width: '8%' }}>Shipped Qty</StyledTableHeaderCell>
            )}

            {tabConfig?.includes('erp_order') && (
              <StyledTableHeaderCell style={{ width: '10%' }}>ERP Order</StyledTableHeaderCell>
            )}
            {tabConfig?.includes('tracking#') && (
              <StyledTableHeaderCell style={{ width: '12%' }}>Tracking #</StyledTableHeaderCell>
            )}
            {tabConfig?.includes('delivery') && (
              <StyledTableHeaderCell style={{ width: '12%' }}>Delivery</StyledTableHeaderCell>
            )}
          </StyledTableRow>
          <TableBody>
            {lineItems?.map((row: any) => (
              <Row
                key={row?.productNumber}
                row={row}
                userInfo={userInfo}
                expandAll={open}
                tabConfig={tabConfig}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </IconSettings>
  );
};

export default ShippingTab;
