/**
 * Redux file for getting/setting event filters
 * @module src/store/ducks/inventoryTransferFilters
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_IN_TRANSFER_FILTERS = 'SET_IN_TRANSFER_FILTERS';
const CLEAR_IN_TRANSFER_FILTERS = 'CLEAR_IN_TRANSFER_FILTERS';

interface SetInventoryTransferFiltersActionType {
  payload: string | null;
  type: typeof SET_IN_TRANSFER_FILTERS;
}

interface ClearInventoryTransferFiltersActionType {
  type: typeof CLEAR_IN_TRANSFER_FILTERS;
}

/** eventFilters action type */

export type InventoryTransferFilterActionType =
  | SetInventoryTransferFiltersActionType
  | ClearInventoryTransferFiltersActionType;

/** set eventFilters action creator */

export const setInventoryTransferFilters = (
  state: string | null
): InventoryTransferFilterActionType => {
  return {
    payload: state,
    type: SET_IN_TRANSFER_FILTERS,
  };
};

/** clear eventFilters action creator */

export const clearInventoryTransferFilters = (): ClearInventoryTransferFiltersActionType => {
  return {
    type: CLEAR_IN_TRANSFER_FILTERS,
  };
};

/** eventFilters state type */

export type InventoryTransferFilterState = string | null;

/** eventFilters reducer */

export default (
  state: InventoryTransferFilterState = null,
  action: ActionType
): InventoryTransferFilterState => {
  switch (action.type) {
    case SET_IN_TRANSFER_FILTERS: {
      return action.payload;
    }
    case CLEAR_IN_TRANSFER_FILTERS: {
      return null;
    }
    default:
      return state;
  }
};

/** selector to get accessToken */

export const getInventoryTransferFilters = (state: State): InventoryTransferFilterState => {
  return state.inventoryTransferFilters;
};
