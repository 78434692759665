/* eslint-disable @typescript-eslint/explicit-function-return-type */
/**
 * Module for header on Surgical Detail Page
 * @module src/SurgicalDetail/SurgicalDetailHeader
 */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FC, ReactNode, useState, useCallback, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import moment from 'moment';
// import { format } from 'date-fns';
import EventStatusBadge from '../../Shared/EventStatusBadge';
import { Props } from '../SurgicalDetailContent';
import './index.scss';
import CreateEditSurgeryEvent from '../../CreateEditSurgeryEvent';
import EventEditConfirmationModal from '../SurgicalDetailEditConfirmationModal';
import {
  EVENT_STATUS,
  ORDER_TYPE,
  TRIAL_EVENT_TYPE,
  USAGE_TYPE,
  PERSONAS,
  MOCK_OR_EVENT_TYPE,
  SURGERY_EVENT_TYPE,
} from '../../../util/constants';
import { GET_WORKFLOW_PERMISSION } from '../../../graphql/workflowPermissions';
import EventEditMessage from '../SurgicalDetailEditMessage';
import { getEventIconInner } from '../../../util/utilityFunctions';
import InventoryRequest from '../../Inventory/InventoryRequest';
import { GET_FORM_CONFIGURATIONS } from '../../../graphql/getFormConfigurations';
import { getUserInfo } from '../../../store/ducks/userInfo';
import UsageSheetForm from '../../Usage/UsageSheetForm';
import { GET_DETAILS_VIEW_CONFIGURATIONS } from '../../../graphql/getDetailsViewConfigurations';
import DisplayRecordIndicator from '../../Shared/DisplayRecordIndicator';

// import ReadMore from '../../Shared/ReadMore';

interface UserInfo {
  contactId?: string;
  division: string | null;
  divisions: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  personas: string;
  userName: string | null;
  userType: string;
  businessunit: string;
  assignedScreens: any;
  businessRules: any;
}

const SurgicalDetailHeader: FC<Props> = ({
  eventDetail,
  showCancelEventModal,
  showCloneEventModal,
  refetchEventDetails,
  // hasEventInventory,
  calendarView,
  calendarDate,
  // eventCancelReason,
  // eventCancelComment,
}) => {
  const {
    surgeonFirstName,
    surgeonLastName,
    procedures,
    salesRep,
    coveringReps,
    comments,
    eventStartDate,
    eventEndDate,
    accountName,
    accountNumber,
    eventStatus,
    eventType,
    eventId,
    eventSubType,
    proceduresList,
    caseExternalId,
    eventAddress,
    poNumber,
    patientId,
    shippingAddessLine1,
    shippingAddessLine2,
    shippingPostalcode,
    shippingCity,
    shippingState,
    billingAddessLine1,
    billingAddessLine2,
    billingPostalcode,
    billingCity,
    billingState,
    orderType,
    eventDuration,
    locationDescription,
    eventDescription,
    isShipToAddress,
    isOtherAddress,
    opportunity,
  } = eventDetail;
  // const endDate = new Date(moment.utc(eventEndDate).format('YYYY-MM-DD HH:mm:ss'));
  // const formatter = 'MM/dd/yyyy';
  const userInfo: UserInfo | null = useSelector(getUserInfo);
  const [showEditEventModal, setShowEditEventModal] = useState(false);
  const [showEditMessageModal, setShowEditMessageModal] = useState(false);
  const [showEditConfirmationModal, setShowEditConfirmationModal] = useState(false);
  const [showInventoryModal, setShowInventoryModal] = useState(false);
  const [showUsageModal, setShowUsageModal] = useState(false);
  const [getFormConfigurations, { data }] = useLazyQuery(GET_FORM_CONFIGURATIONS);
  const [usageCreated, setUsageCreated] = useState(false);
  const { data: getDetailsViewConfigurations } = useQuery(GET_DETAILS_VIEW_CONFIGURATIONS);

  const filterRule: any =
    // eslint-disable-next-line no-unused-expressions
    userInfo?.businessRules?.filter((br: any) => {
      if (br.rules === 'Clone Event with Records') {
        return br.isRequired;
      }
    });
  const mockConfig = getDetailsViewConfigurations?.getDetailsViewConfigurations?.mockor?.headers;
  let hasAssociatedRecords = false;
  if (
    (eventDetail && eventDetail.eventTransfer.length > 0) ||
    eventDetail.eventReturn.length > 0 ||
    eventDetail.eventOrder.length > 0 ||
    eventDetail.eventInventory.length > 0
  ) {
    hasAssociatedRecords = true;
  }
  const isClone = filterRule && filterRule.length > 0 && filterRule[0].isRequired;

  useEffect(() => {
    const usageFlag = orderType === ORDER_TYPE.SJC_SM_BILL_ONLY;
    setUsageCreated(usageFlag);
  }, [orderType]);
  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);
  const { data: enableEditModalMessage } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      state: eventStatus,
      action: 'enable_edit_message',
    },
  });

  const { data: showSurgeonData } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      action: 'view_surgeon_cases',
    },
  });

  const cancellationOptions = [
    { label: 'Cancel Event', value: 'cancelEvent' },
    { label: 'Clone Event', value: 'clone', disabled: !isClone },
  ];

  const cloneOption = [{ label: 'Clone', value: 'clone', disabled: !isClone }];

  const handleModalToggle = useCallback((): void => {
    if (enableEditModalMessage) {
      setShowEditMessageModal(!showEditMessageModal);
    } else {
      // If modal should be open for edit scenarios
      /* eslint-disable no-lonely-if */
      /* if (hasEventInventory) {
        // setShowEditConfirmationModal(true);
      } else {
        setShowEditEventModal(!showEditEventModal);  
      }*/
      setShowEditEventModal(!showEditEventModal);
    }
  }, [enableEditModalMessage, showEditEventModal, showEditMessageModal]);

  const handleModalToggleEdit = (): void => {
    setShowEditConfirmationModal(false);
    setShowEditEventModal(false);
  };
  const getconfig = (key: string) => {
    if (mockConfig?.length) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < mockConfig.length; i++) {
        if (mockConfig[i].mappingKey === key && mockConfig[i].isShow) {
          return true;
        }
      }
    }
    return false;
  };

  const getFormconfig = (key: string) => {
    let eventFormData = [];
    if (eventSubType === 'Show-n-Go') {
      eventFormData = data?.getFormConfigurations?.showGo;
    } else if (eventType === TRIAL_EVENT_TYPE) {
      eventFormData = data?.getFormConfigurations?.newTrial;
    } else if (eventType === SURGERY_EVENT_TYPE) {
      eventFormData = data?.getFormConfigurations?.surgery;
    } else if (eventSubType === 'Standard Demo') {
      eventFormData = data?.getFormConfigurations?.demo;
    }
    if (eventFormData?.length) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < eventFormData?.length; i++) {
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < eventFormData[i]?.fields?.length; j++) {
          if (eventFormData[i]?.fields[j]?.name === key && eventFormData[i]?.fields[j]?.isShow) {
            return true;
          }
        }
      }
    }
    return false;
  };
  const handleUsageModalToggle = useCallback((): void => {
    setShowUsageModal(!showUsageModal);
  }, [showUsageModal]);

  const handleEventInventoryModalToggle = (): void => {
    setShowInventoryModal(false);
  };

  const handleEventEditConfirmationModal = (): void => {
    setShowEditConfirmationModal(!showEditConfirmationModal);
  };

  const handlelEditEventModalConfirmPress = (): void => {
    setShowEditConfirmationModal(false);
    setShowEditEventModal(true);
  };
  const handleDropdownSelect = useCallback(
    (selectedOption: any): void => {
      if (selectedOption.value === 'clone' && !selectedOption.disabled) {
        if (showCloneEventModal) {
          showCloneEventModal();
        }
      } else if (selectedOption.value === 'cancelEvent') {
        if (showCancelEventModal) {
          showCancelEventModal();
        }
      }
    },
    [showCancelEventModal, showCloneEventModal]
  );

  const handleNavigateToEventList = (): void => {
    localStorage.setItem('calendarView', JSON.stringify(calendarView) || '');
    localStorage.setItem('calendarDate', JSON.stringify(calendarDate) || '');
    window.history.back();
  };

  const timedata = new Date();
  const newtime = timedata
    .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
    .substring(4)
    .match(/\b(\w)/g);
  const TimeZoneData = newtime?.join('');

  const actions = (): ReactNode => (
    <PageHeaderControl>
      {/* {!usageCreated && (
        <Button
          label="Create Usage"
          className="yellow-btn"
          onClick={handleUsageModalToggle}
          disabled={
            eventStatus === EVENT_STATUS.CANCELLED ||
            eventStatus === EVENT_STATUS.CLOSED ||
            eventStatus === EVENT_STATUS.COMPLETED
          }
        />
      )} */}
      {userInfo && userInfo.personas !== PERSONAS.KENCO ? (
        <ButtonGroup
          className="slds-align-middle"
          variant="list"
          id="button-group-page-header-actions"
        >
          {/* {
          <Button
            label="Inventory Request"
            onClick={handleInventoryModalToggle}
            disabled={
              eventStatus === EVENT_STATUS.CANCELLED ||
              eventStatus === EVENT_STATUS.CLOSED ||
              eventStatus === EVENT_STATUS.COMPLETED
            }
          />
        } */}
          {
            <Button
              label="Edit"
              onClick={handleModalToggle}
              disabled={
                eventStatus === EVENT_STATUS.CANCELLED ||
                eventStatus === EVENT_STATUS.CLOSED ||
                eventStatus === EVENT_STATUS.COMPLETED ||
                eventStatus === EVENT_STATUS.COMPLETE
              }
            />
          }
          <Dropdown
            align="right"
            assistiveText={{ icon: 'More Options' }}
            iconCategory="utility"
            iconName="down"
            iconVariant="border-filled"
            id="page-header-setup-dropdown-actions"
            options={
              eventStatus === EVENT_STATUS.CANCELLED ||
              eventStatus === EVENT_STATUS.COMPLETED ||
              eventStatus === EVENT_STATUS.CLOSED ||
              eventStatus === EVENT_STATUS.COMPLETE
                ? cloneOption
                : cancellationOptions
            }
            // options={cancellationOptions}
            width="xx-small"
            onSelect={(value: any): void => handleDropdownSelect(value)}
          />
          <DisplayRecordIndicator recordInfo={eventDetail} />
        </ButtonGroup>
      ) : (
        <ButtonGroup
          className="slds-align-middle"
          variant="list"
          id="button-group-page-header-actions"
        >
          {/* {
          <Button
            label="Inventory Request"
            onClick={handleInventoryModalToggle}
            disabled={
              eventStatus === EVENT_STATUS.CANCELLED ||
              eventStatus === EVENT_STATUS.CLOSED ||
              eventStatus === EVENT_STATUS.COMPLETED
            }
          />
        } */}
          {
            <Button
              label="Edit"
              onClick={handleModalToggle}
              disabled={
                eventStatus === EVENT_STATUS.CANCELLED ||
                eventStatus === EVENT_STATUS.CLOSED ||
                eventStatus === EVENT_STATUS.COMPLETED ||
                eventStatus === EVENT_STATUS.COMPLETE
              }
            />
          }
          <DisplayRecordIndicator recordInfo={eventDetail} />
        </ButtonGroup>
      )}
    </PageHeaderControl>
  );
  const proceduresValue =
    procedures ||
    (proceduresList && proceduresList.length
      ? proceduresList.map((procedure: any) => procedure.value)
      : '');
  // ? procedures.replace(/[{()}]/g, '').replace(/['"]+/g, '') : '';
  const iconPath = getEventIconInner(eventType);
  const locationDescriptionTemp =
    locationDescription && locationDescription.trim().length > 0
      ? locationDescription.trim()
      : '--';
  return (
    <IconSettings iconPath="/icons">
      <div className="slds-x-small-buttons_horizontal" style={{ padding: '10px' }}>
        <Button
          style={{ color: 'black' }}
          iconCategory="utility"
          iconName="back"
          iconPosition="left"
          label="Back"
          onClick={handleNavigateToEventList}
        />
      </div>
      <PageHeader
        className="slds-m-around_small slds-m-bottom_none surgical_header"
        icon={
          <img
            src={iconPath}
            className="logo-image"
            alt="company"
            style={{ width: '2.75rem', height: '2.75rem' }}
          />
        }
        label={eventSubType || eventType}
        onRenderActions={actions}
        title={
          <div className="slds-grid slds-grid_vertical-align-center slds-p-top_xx-small">
            <span className="slds-col event_id">{eventId || 'TBD'}</span>
            {eventStatus ? (
              <span className="slds-col slds-m-left_large">
                <EventStatusBadge status={eventStatus || ''} />
              </span>
            ) : (
              ''
            )}
            {/* {eventStatus === EVENT_STATUS.CANCELLED && (
              <span className="slds-col slds-m-left_large">
                <span className="slds-text-title slds-text-color_default">
                  {`Cancellation Reason: `}
                </span>
                <span className="slds-text-title cancel_reason slds-text-body_small">
                  {eventCancelReason || eventCancelComment}
                </span>
              </span>
            )} */}
          </div>
        }
        variant="record-home"
      />
      {mockConfig?.length && eventSubType === MOCK_OR_EVENT_TYPE ? (
        <>
          <ul
            className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
            style={{ borderBottom: 'none' }}
          >
            {getconfig('account') ? (
              <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
                <div className="slds-text-title" title="Account" style={{ fontWeight: 'bold' }}>
                  Account
                </div>
                <div className="slds-tooltip-trigger">
                  <div title={accountName}>{`${accountNumber}-${accountName}`}</div>
                </div>
              </li>
            ) : (
              ''
            )}

            {getconfig('EventDescription') && (
              <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                <div
                  className="slds-text-title slds-truncate"
                  title="Event Description"
                  style={{ fontWeight: 'bold' }}
                >
                  Event Description
                </div>
                <div title={eventDescription}>{eventDescription || '--'}</div>
              </li>
            )}

            {getconfig('salesRep') ? (
              <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                <div
                  className="slds-text-title slds-truncate"
                  title="Sales Rep"
                  style={{ fontWeight: 'bold' }}
                >
                  Sales Rep
                </div>
                <div title={salesRep}>{salesRep}</div>
              </li>
            ) : (
              ''
            )}
            {getconfig('coveringRep') ? (
              <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
                <div
                  className="slds-text-title slds-truncate"
                  title="Covering Rep"
                  style={{ fontWeight: 'bold' }}
                >
                  Covering Rep
                </div>
                <div title={coveringReps}>{coveringReps || '--'}</div>
              </li>
            ) : (
              ''
            )}
          </ul>
          <ul
            className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
            style={{ borderBottom: usageCreated ? 'none' : '' }}
          >
            {getconfig('startDate') ? (
              <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
                <div
                  className="slds-text-title slds-truncate"
                  title="Date"
                  style={{ fontWeight: 'bold' }}
                >
                  {eventType !== TRIAL_EVENT_TYPE && eventSubType !== MOCK_OR_EVENT_TYPE
                    ? `Date/Time`
                    : 'Start Date/Time'}
                </div>
                {eventType !== TRIAL_EVENT_TYPE && eventSubType !== MOCK_OR_EVENT_TYPE ? (
                  <div style={{ display: 'flex' }}>
                    <p>{moment(eventStartDate).format('L')}</p>
                    <p style={{ paddingLeft: '7.5px' }}>
                      {moment(eventStartDate).format('LT')}
                      {`  ${TimeZoneData}`}
                    </p>
                  </div>
                ) : (
                  <>
                    <p>{moment(eventStartDate).format('L')}</p>
                    <p>
                      {moment(eventStartDate).format('LT')}
                      {`  ${TimeZoneData}`}
                    </p>
                  </>
                )}
              </li>
            ) : (
              ''
            )}
            {getconfig('endDate') ? (
              <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                <div
                  className="slds-text-title slds-truncate"
                  title="Date"
                  style={{ fontWeight: 'bold' }}
                >
                  End Date/Time
                </div>

                <>
                  <p>{moment(eventEndDate).format('L')}</p>
                  <p>
                    {moment(eventEndDate).format('LT')}
                    {`  ${TimeZoneData}`}
                  </p>
                </>
              </li>
            ) : (
              ''
            )}
            {getconfig('EventLocationSection') ||
              (getconfig('eventLocation') && (
                <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                  <div
                    className="slds-text-title slds-truncate"
                    title="Event Location"
                    style={{ fontWeight: 'bold' }}
                  >
                    Event Location
                  </div>
                  <div title={locationDescription}>
                    {isShipToAddress && 'Ship To Address'}
                    {isOtherAddress && locationDescriptionTemp}
                  </div>
                </li>
              ))}
            {eventType !== SURGERY_EVENT_TYPE && getconfig('opportunity') && (
              <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                <div
                  className="slds-text-title slds-truncate"
                  title="opportunity"
                  style={{ fontWeight: 'bold' }}
                >
                  Opportunity
                </div>
                <div>{opportunity || '--'}</div>
              </li>
            )}
            {getconfig('comments') ? (
              <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                <div
                  className="slds-text-title slds-truncate"
                  title="Comment"
                  style={{ fontWeight: 'bold' }}
                >
                  Comments
                </div>
                {comments && comments.length
                  ? comments.map((comment: any) => (
                      <div>{comment.value || '--'}</div>
                      // <><ReadMore comments={comment.value || '--'} /><br /></>
                    ))
                  : '--'}
              </li>
            ) : (
              ''
            )}
            {usageCreated && (
              <>
                <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                  <div
                    className="slds-text-title slds-truncate"
                    title="PO"
                    style={{ fontWeight: 'bold' }}
                  >
                    PO
                  </div>
                  <div className="slds-truncate">{poNumber}</div>
                </li>
                <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                  <div
                    className="slds-text-title slds-truncate"
                    title="Patient ID"
                    style={{ fontWeight: 'bold' }}
                  >
                    Patient ID
                  </div>
                  <div className="slds-truncate">{patientId}</div>
                </li>
              </>
            )}
          </ul>
        </>
      ) : (
        <>
          <ul
            className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
            style={{ borderBottom: 'none' }}
          >
            {getFormconfig('account') && (
              <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
                <div className="slds-text-title" title="Account" style={{ fontWeight: 'bold' }}>
                  Account
                </div>
                <div className="slds-tooltip-trigger">
                  <div title={accountName}>{`${accountNumber}-${accountName}`}</div>
                </div>
              </li>
            )}
            {(eventSubType === 'Show-n-Go' || eventSubType === MOCK_OR_EVENT_TYPE) &&
              getFormconfig('eventLocation') && (
                <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                  <div
                    className="slds-text-title slds-truncate"
                    title="Event Description"
                    style={{ fontWeight: 'bold' }}
                  >
                    Event Description
                  </div>
                  <div title={eventDescription}>{eventDescription || '--'}</div>
                </li>
              )}
            {(getFormconfig('surgeon') || getFormconfig('contact')) && (
              <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                <div
                  className="slds-text-title slds-truncate"
                  title="Surgeon"
                  style={{ fontWeight: 'bold' }}
                >
                  {eventSubType === 'Show-n-Go' ? 'Contact' : 'Surgeon'}
                </div>
                {surgeonFirstName && surgeonLastName && (
                  <div className="slds-truncate" title={`${surgeonFirstName} ${surgeonLastName}`}>
                    {surgeonFirstName && surgeonLastName
                      ? `${surgeonFirstName} ${surgeonLastName}`
                      : '--'}
                  </div>
                )}
              </li>
            )}

            {eventSubType !== 'Show-n-Go' &&
              eventSubType !== MOCK_OR_EVENT_TYPE &&
              getFormconfig('procedureType') && (
                <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                  <div
                    className="slds-text-title slds-truncate"
                    title="Procedure"
                    style={{ fontWeight: 'bold' }}
                  >
                    Procedure
                  </div>
                  <p className="slds-line-clamp">{proceduresValue}</p>
                </li>
              )}

            <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
              <div
                className="slds-text-title slds-truncate"
                title="Sales Rep"
                style={{ fontWeight: 'bold' }}
              >
                Sales Rep
              </div>
              <div title={salesRep}>{salesRep}</div>
            </li>
            <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
              <div
                className="slds-text-title slds-truncate"
                title="Covering Rep"
                style={{ fontWeight: 'bold' }}
              >
                Covering Rep
              </div>
              <div title={coveringReps}>{coveringReps || '--'}</div>
            </li>
            {/* */}
          </ul>
          <ul
            className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
            style={{ borderBottom: usageCreated ? 'none' : '' }}
          >
            <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
              <div
                className="slds-text-title slds-truncate"
                title="Date"
                style={{ fontWeight: 'bold' }}
              >
                {eventType !== TRIAL_EVENT_TYPE && eventSubType !== MOCK_OR_EVENT_TYPE
                  ? `Date/Time`
                  : 'Start Date/Time'}
              </div>
              {eventType !== TRIAL_EVENT_TYPE && eventSubType !== MOCK_OR_EVENT_TYPE ? (
                <div style={{ display: 'flex' }}>
                  <p>{moment(eventStartDate).format('L')}</p>
                  <p style={{ paddingLeft: '7.5px' }}>
                    {moment(eventStartDate).format('LT')}
                    {`  ${TimeZoneData}`}
                  </p>
                </div>
              ) : (
                <>
                  <p>{moment(eventStartDate).format('L')}</p>
                  <p>
                    {moment(eventStartDate).format('LT')}
                    {`  ${TimeZoneData}`}
                  </p>
                </>
              )}
            </li>
            <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
              <div
                className="slds-text-title slds-truncate"
                title="Date"
                style={{ fontWeight: 'bold' }}
              >
                {eventType !== TRIAL_EVENT_TYPE && eventSubType !== MOCK_OR_EVENT_TYPE
                  ? `Duration (Hrs)`
                  : 'End Date/Time'}
              </div>
              {eventType !== TRIAL_EVENT_TYPE && eventSubType !== MOCK_OR_EVENT_TYPE ? (
                <div style={{ display: 'flex' }}>
                  <p>{eventDuration}</p>
                </div>
              ) : (
                <>
                  <p>{moment(eventEndDate).format('L')}</p>
                  <p>
                    {moment(eventEndDate).format('LT')}
                    {`  ${TimeZoneData}`}
                  </p>
                </>
              )}
            </li>
            {(eventSubType === 'Show-n-Go' || eventSubType === MOCK_OR_EVENT_TYPE) &&
              getFormconfig('eventLocation') && (
                <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                  <div
                    className="slds-text-title slds-truncate"
                    title="Event Location"
                    style={{ fontWeight: 'bold' }}
                  >
                    Event Location
                  </div>
                  <div title={locationDescription}>
                    {isShipToAddress && 'Ship To Address'}
                    {isOtherAddress && locationDescriptionTemp}
                  </div>
                </li>
              )}

            {eventType !== SURGERY_EVENT_TYPE && getFormconfig('opportunity') && (
              <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                <div
                  className="slds-text-title slds-truncate"
                  title="opportunity"
                  style={{ fontWeight: 'bold' }}
                >
                  Opportunity
                </div>
                <div>{opportunity || '--'}</div>
              </li>
            )}
            {getFormconfig('comments') && (
              <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                <div
                  className="slds-text-title slds-truncate"
                  title="Comment"
                  style={{ fontWeight: 'bold' }}
                >
                  Comments
                </div>
                {comments && comments.length
                  ? comments.map((comment: any) => (
                      <div>{comment.value || '--'}</div>
                      // <><ReadMore comments={comment.value || '--'} /><br /></>
                    ))
                  : '--'}
              </li>
            )}

            {eventType === TRIAL_EVENT_TYPE &&
            eventSubType !== MOCK_OR_EVENT_TYPE &&
            getFormconfig('trialLocation') ? (
              <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                <div
                  className="slds-text-title slds-truncate"
                  title="Trial Location"
                  style={{ fontWeight: 'bold' }}
                >
                  Trial Location
                </div>
                {eventAddress || '--'}
              </li>
            ) : null}
            {usageCreated && (
              <>
                <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                  <div
                    className="slds-text-title slds-truncate"
                    title="PO"
                    style={{ fontWeight: 'bold' }}
                  >
                    PO
                  </div>
                  <div className="slds-truncate">{poNumber}</div>
                </li>
                <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                  <div
                    className="slds-text-title slds-truncate"
                    title="Patient ID"
                    style={{ fontWeight: 'bold' }}
                  >
                    Patient ID
                  </div>
                  <div className="slds-truncate">{patientId}</div>
                </li>
              </>
            )}
          </ul>
        </>
      )}

      {usageCreated && (
        <ul className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none">
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Ship To"
              style={{ fontWeight: 'bold' }}
            >
              Ship To
            </div>
            <div>
              {`${shippingAddessLine1 || ''},${shippingAddessLine2 || ''},${shippingCity ||
                ''},${shippingState || ''},${shippingPostalcode || ''}`}
            </div>
          </li>
          <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Bill To"
              style={{ fontWeight: 'bold' }}
            >
              Bill To
            </div>
            <div>
              {' '}
              {`${billingAddessLine1 || ''},${billingAddessLine2 || ''},${billingCity ||
                ''},${billingState || ''},${billingPostalcode || ''}`}
            </div>
          </li>
        </ul>
      )}
      {showEditEventModal && !showEditConfirmationModal && (
        <CreateEditSurgeryEvent
          toggleOpen={handleModalToggleEdit}
          isOpen={showEditEventModal}
          eventType={eventType}
          eventSubType={eventSubType}
          eventLabel={eventType}
          eventDetail={eventDetail}
          refetchEventDetails={refetchEventDetails}
          showSurgeonData={showSurgeonData}
          hasAssociatedRecords={hasAssociatedRecords}
        />
      )}
      {showEditMessageModal && (
        <EventEditMessage
          showEditMessageModal={showEditMessageModal}
          handleModalToggle={handleModalToggle}
          salesRep={salesRep}
        />
      )}
      <EventEditConfirmationModal
        showEditConfirmationModal={showEditConfirmationModal}
        handleModalToggle={handleEventEditConfirmationModal}
        handlelEditEventModalConfirmPress={handlelEditEventModalConfirmPress}
      />
      {showInventoryModal && (
        <InventoryRequest
          flowType
          isOpen={showInventoryModal}
          toggleOpen={handleEventInventoryModalToggle}
          formData={data}
          eventType="Request Inventory"
          eventExternalId={caseExternalId}
          refetchEventDetails={refetchEventDetails}
          editDetails={eventDetail}
        />
      )}
      <UsageSheetForm
        eventUsage
        isOpen={showUsageModal}
        toggleOpen={handleUsageModalToggle}
        formData={data}
        editDetails={eventDetail}
        orderType={USAGE_TYPE.BILL_ONLY}
        refetchDetails={refetchEventDetails}
      />
    </IconSettings>
  );
};

/** Custom Header on Events Page **/
export default SurgicalDetailHeader;
