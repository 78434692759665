import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import moment from 'moment';

interface DateTime {
  shipmentDate: Date;
  submittedDate: string;
}
export interface Props {
  item?: DateTime;
  dateFor?: string;
}

const InventoryRequestDateTImeCell: FC<Props> = ({
  item = {
    shipmentDate: null,
    submittedDate: null,
  },
  dateFor,
}) => {
  const { submittedDate, shipmentDate } = item;
  const dt = dateFor === 'submittedDate' ? submittedDate : shipmentDate;
  const date = dt ? moment(dt).format('MM/DD/YYYY') : '--';
  return (
    <DataTableCell title={date}>
      <div>
        <p className="wrap-text font-12 font-600">{date}</p>
      </div>
    </DataTableCell>
  );
};

/** Custom Date/Time cell */
export default InventoryRequestDateTImeCell;
