/*
 * Filtered Sales Reps GraphQL specification
 * @module src/graphql/getFilteredSalesReps
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Filtered Sales Reps GraphQL API query */
export const GET_FILTERED_SALES_REPS = gql`
  query getSalesReps($filters: SalesRepFilters) {
    getSalesReps(filters: $filters) {
      id
      value
      hospital
      owned
    }
  }
`;
