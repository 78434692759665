/**
 * Apollo client
 * @module src/graphql/client
 */
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import {
  InMemoryCache,
  defaultDataIdFromObject,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import customReAuthorizationFetch from './customReauthFetch';
import store from '../store';
import { getAccessToken } from '../store/ducks/accessToken';
import introspectionQueryResultData from './fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export interface IdGetterObj extends Object {
  __typename?: string;
  id?: string;
  externalId?: string;
}

const httpLink = createHttpLink({
  uri: '/graphql',
  fetch: customReAuthorizationFetch,
});

const authLink = setContext(async (_, { headers }) => {
  const state = store.getState();
  const accessToken = getAccessToken(state);
  if (accessToken === null) {
    return { headers };
  }
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };
});

/** Apollo client */
export default new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject: (object: IdGetterObj): string | null => {
      // eslint-disable-next-line no-underscore-dangle
      switch (object.__typename) {
        case 'Event':
          return object.id || `${object.externalId}`;

        default:
          return defaultDataIdFromObject(object); // fall back to default handling
      }
    },
    // specialized pattern here
    // https://www.apollographql.com/docs/react/caching/cache-interaction/#cache-redirects-with-cacheredirects
    cacheRedirects: {
      Query: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        workflowPermission: (_, args, { getCacheKey }): any => {
          const { division, workflow, state, action } = args;
          const nd = division === undefined ? null : division;
          const nw = workflow === undefined ? null : workflow;
          const ns = state === undefined ? null : state;
          const na = action === undefined ? null : action;
          const id = `${nd}|${nw}|${ns}|${na}`;
          return getCacheKey({ __typename: 'WorkflowPermission', id });
        },
      },
    },
  }),
});
