/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-unused-vars*/
/* eslint-disable no-lonely-if*/

import React, { FC, useCallback, ReactNode, useState, useEffect } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
// import Popover from '@salesforce/design-system-react/components/popover';
import Button from '@salesforce/design-system-react/components/button';
import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Spinner from '@salesforce/design-system-react/components/spinner';
import { useSelector } from 'react-redux';
import moment from 'moment';
import FilterCycleCount from './FilterListItems';
import FilterTags from '../../../Shared/FilterTags';
import CycleCountAddItems from '../CycleCountAddItems';
import { CYCLE_COUNT, SPORTS_MED } from '../../../../util/constants';
import debounced from '../../../../util/debounced';
import { getEventIcon } from '../../../../util/utilityFunctions';
import CCSalesReps from './CCSalesReps';
import CCOpsComments from './CCOpsComments';
import PartNumberCell from './PartNumberCell';
import PartDescriptionCell from './PartDescriptionCell';
import PartDescriptionCellForRep from './PartDescriptionCellForRep';
import ExpirationDate from './ExpirationDate';
import CostDeltaCell from './CostDeltaCell';
import QuantityDeltaCell from './QuantityDeltaCell';
import TableRowLotNumCell from './TableRowLotNumCell';
import TableRowEIMCell from './TableRowEIMCell';
import TableRowExpectedCell from './TableRowExpectedCell';
import TableRowQTYCell from './TableRowQTYCell';
import FilterTagsForModal from '../../../Shared/FilterTagsForModal';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import CycleCountDelete from './CycleCountDelete';

interface Panels {
  [key: string]: boolean;
}

interface Props {
  inventoryCountTableDetails: any[];
  cycleCountDetails: any;
  refetch?: () => void;
  refetchCycleCountDetails: any;
  getCycleCountLineItemsDetails?: (variables: any) => void;
  getCycleCountLineItemsDetailsForExcel?: (variables: any) => void;
}

interface SelectedData {
  id: string;
  label: string;
  __typename?: string;
}

interface Sort {
  sortColumn: string;
  sortColumnDirection: {
    [key: string]: string;
  };
}
interface DynamicValue {
  [key: string]: any;
}

const InventoryCountTable: FC<Props> = ({
  inventoryCountTableDetails,
  refetch,
  refetchCycleCountDetails,
  cycleCountDetails,
  getCycleCountLineItemsDetails,
  getCycleCountLineItemsDetailsForExcel,
}) => {
  const [showSearchCatalogModal, setShowSearchCatalogModal] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<DynamicValue | null>(null);
  const [selectedFilterApiValues, setSelectedFilterApiValues] = useState<DynamicValue | null>(null);
  const [selectedFilterPart, setSelectedFilterPart] = useState<DynamicValue | null>(null);
  const [
    selectedFilterApiValuesPart,
    setSelectedFilterApiValuesPart,
  ] = useState<DynamicValue | null>(null);
  const [lineItemsData, setLineItemsData] = useState<any>();
  const [sortObj, setSortObj] = useState<Sort>();
  const [updateAction, setUpdateAction] = useState(false);
  const [loading, setLoader] = useState(false);
  const userInfo = useSelector(getUserInfo);
  const isOps = userInfo?.personas?.includes('Branch Op') || false;
  const countType: any = cycleCountDetails?.countType;
  const inProgress = cycleCountDetails && cycleCountDetails.status.toLowerCase() === 'in progress';

  const onHandleCloseSearchCatalogModal = (): void => {
    setShowSearchCatalogModal(false);
    setUpdateAction(false);

    if (!isOps && inProgress) {
      refetchCycleCountDetails();
    } else {
      if (refetch) {
        refetch();
        if (getCycleCountLineItemsDetailsForExcel && cycleCountDetails) {
          getCycleCountLineItemsDetailsForExcel({
            variables: {
              externalId: cycleCountDetails.cycleCountExternalId,
            },
          });
        }
        // setLoader(true);
      }
    }
  };

  const handleToggleFilterCycleCount = (): void => {
    // handleEditFilter(false);
    setFilterModalVisible(!filterModalVisible);
  };
  const getFilterApiValue = (filterValues: DynamicValue): DynamicValue => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          let values;
          if (key === 'salesRepId') {
            values = filterValues[key].map((item: any) => item.salesRepId);
          } else {
            values = filterValues[key].map((item: SelectedData) => item.id);
          }
          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  const handleFilter = (filterValues: DynamicValue | null, filterName?: string): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setSelectedFilterPart(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValuesPart(filters);
    }
  };

  const handleRefetchItems = useCallback(
    (filterData: any): void => {
      if (getCycleCountLineItemsDetails && cycleCountDetails) {
        getCycleCountLineItemsDetails({
          variables: {
            externalId: cycleCountDetails.cycleCountExternalId,
            filters: filterData,
          },
        });
      }
      setUpdateAction(false);
      if (refetchCycleCountDetails) {
        refetchCycleCountDetails();
      }
    },
    [getCycleCountLineItemsDetails, cycleCountDetails, refetchCycleCountDetails]
  );

  const handleRefetchLineItems = useCallback(
    (action: any): void => {
      if (getCycleCountLineItemsDetails && cycleCountDetails) {
        getCycleCountLineItemsDetails({
          variables: {
            externalId: cycleCountDetails.cycleCountExternalId,
            filters: selectedFilterApiValues,
          },
        });
      }
      if (getCycleCountLineItemsDetailsForExcel && cycleCountDetails) {
        getCycleCountLineItemsDetailsForExcel({
          variables: {
            externalId: cycleCountDetails.cycleCountExternalId,
            filters: selectedFilterApiValues,
          },
        });
      }
      if (action === 'update') {
        setUpdateAction(true);
      } else {
        setUpdateAction(false);
      }
      setLoader(true);
      if (refetchCycleCountDetails) {
        refetchCycleCountDetails();
      }
    },
    [
      getCycleCountLineItemsDetails,
      cycleCountDetails,
      getCycleCountLineItemsDetailsForExcel,
      refetchCycleCountDetails,
      selectedFilterApiValues,
    ]
  );

  const handleRefetchDetailLineItems = useCallback(
    (action: any): void => {
      if (refetchCycleCountDetails && cycleCountDetails) {
        refetchCycleCountDetails();
      }
      if (action === 'update') {
        setUpdateAction(true);
      } else {
        setUpdateAction(false);
      }
      // setLoader(true);
    },
    [cycleCountDetails, refetchCycleCountDetails]
  );

  const resetFilters = useCallback((): void => {
    setSelectedFilter(null);
    setSelectedFilterApiValues(null);
    localStorage.setItem('CCFilters', 'false');
    // setOffset(0);
    if (getCycleCountLineItemsDetails && cycleCountDetails) {
      getCycleCountLineItemsDetails({
        variables: {
          externalId: cycleCountDetails.cycleCountExternalId,
        },
      });
    }
  }, [getCycleCountLineItemsDetails, cycleCountDetails]);

  const handleFilterCycleCount = (filterValues: DynamicValue | null, filterName?: string): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setSelectedFilter(filter);
    if (filterValues) {
      if (Object.keys(filterValues).length === 0 && filterValues.constructor === Object) {
        localStorage.setItem('CCFilters', 'false');
      } else {
        localStorage.setItem('CCFilters', 'true');
      }
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
      handleRefetchItems(filters);
    } else {
      resetFilters();
      localStorage.setItem('CCFilters', 'false');
    }
  };

  const handleDeleteItem = () => {
    handleRefetchLineItems('update');
  };

  const handleLoading = () => {
    setLoader(true);
  };

  CCSalesReps.displayName = DataTableCell.displayName;
  CCOpsComments.displayName = DataTableCell.displayName;
  PartNumberCell.displayName = DataTableCell.displayName;
  PartDescriptionCell.displayName = DataTableCell.displayName;
  CostDeltaCell.displayName = DataTableCell.displayName;
  QuantityDeltaCell.displayName = DataTableCell.displayName;
  TableRowLotNumCell.displayName = DataTableCell.displayName;
  TableRowEIMCell.displayName = DataTableCell.displayName;
  TableRowExpectedCell.displayName = DataTableCell.displayName;
  TableRowQTYCell.displayName = DataTableCell.displayName;
  CycleCountDelete.displayName = DataTableCell.displayName;

  if (!isOps) {
    PartDescriptionCellForRep.displayName = DataTableCell.displayName;
    ExpirationDate.displayName = DataTableCell.displayName;
  }

  const handleSort = (sortColumn: any): void => {
    const sortProperty = sortColumn.property;
    const { sortDirection } = sortColumn;
    const sort = {
      sortColumn: sortProperty,
      sortColumnDirection: {
        [sortProperty]: sortDirection,
      },
    };

    const list =
      lineItemsData &&
      lineItemsData.sort((a: any, b: any) => {
        if (sortProperty === 'costVariance') {
          // Date Compare
          if (sortDirection === 'desc') {
            return Number(b[sortProperty]) - Number(a[sortProperty]);
          }
          return Number(a[sortProperty]) - Number(b[sortProperty]);
        }
        // String Compare
        let val = 0;
        if (a[sortProperty] > b[sortProperty]) {
          val = 1;
        }
        if (a[sortProperty] < b[sortProperty]) {
          val = -1;
        }
        if (sortDirection === 'desc') {
          val *= -1;
        }
        return val;
      });
    setSortObj(sort);
    setLineItemsData(list);
  };

  const iconPath = getEventIcon(CYCLE_COUNT);

  const handleAddParts = (): void => {
    setShowSearchCatalogModal(true);
  };
  const setSearch = (searchedItem: string): void => {
    if (searchedItem.length >= 3) {
      if (getCycleCountLineItemsDetails && cycleCountDetails) {
        getCycleCountLineItemsDetails({
          variables: {
            externalId: cycleCountDetails.cycleCountExternalId,
            filters: {
              searchText: searchedItem,
            },
          },
        });
      }
    } else {
      if (getCycleCountLineItemsDetails && cycleCountDetails) {
        getCycleCountLineItemsDetails({
          variables: {
            externalId: cycleCountDetails.cycleCountExternalId,
          },
        });
      }
    }
    setUpdateAction(false);
  };

  const debouncedFetchItems = useCallback(debounced(500, setSearch), [debounced, setSearch]);

  const handleSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      if (value && value.length >= 3) {
        debouncedFetchItems(value);
      } else {
        debouncedFetchItems(value);
      }
    },
    [debouncedFetchItems]
  );

  useEffect(() => {
    if (inventoryCountTableDetails && isOps) {
      const lineItems = inventoryCountTableDetails.map((i: any, index) => {
        if (updateAction) {
          return {
            ...i,
            id: index,
            isOpen:
              lineItemsData && lineItemsData[index]?.isOpen
                ? lineItemsData[index]?.isOpen || false
                : false,
          };
        }
        return {
          ...i,
          id: index,
          isOpen: i.isOpen ? i.isOpen : false,
        };
      });
      setLineItemsData(lineItems);
    } else {
      if (!isOps) {
        setLineItemsData(cycleCountDetails?.lineItems || []);
      }
    }

    setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryCountTableDetails, cycleCountDetails, isOps]);

  // changes for handling mutli-line lineItems expansion and collapse
  const handleLineToggle = useCallback(
    (id: any, isOpenTab: any): void => {
      if (lineItemsData) {
        const optionList = lineItemsData.map((i: any) => {
          if (i.id === id) {
            return {
              ...i,
              isOpen: !isOpenTab,
            };
          }

          return {
            ...i,
            isOpen: i.isOpen,
          };
        });
        setLineItemsData(optionList);
      }
    },
    [lineItemsData]
  );

  let disableAction = true;
  // let disableCountedQty = true;

  if (isOps) {
    if (cycleCountDetails && cycleCountDetails.status.toLowerCase() === 'submitted') {
      disableAction = false;
    }
  } else {
    if (inProgress) {
      disableAction = false;
    }

    if (cycleCountDetails) {
      const startDate = moment(cycleCountDetails.startDate)
        .utc()
        .format('MM/DD/YYYY');
      // new Date(cycleCountDetails.startDate);
      const endDate = moment(cycleCountDetails.endDate)
        .utc()
        .format('MM/DD/YYYY');
      const currentDate = moment()
        .utc()
        .format('MM/DD/YYYY');

      if (inProgress && currentDate > endDate) {
        disableAction = true;
      }
    }
  }

  const data = JSON.parse(JSON.stringify(lineItemsData || []));

  if (!isOps) {
    data?.forEach((item: any) => {
      item.needToDisplay = true;
    });
  }

  const headerActions = (): ReactNode => (
    <PageHeaderControl>
      <ButtonGroup variant="list" id="button-group-page-header-actions">
        {/* <div className="slds-col_padded">
          <Input
            iconRight={
              <InputIcon
                assistiveText={{
                  icon: 'Search',
                }}
                name="search"
                category="utility"
              />
            }
            id="search-case-id"
            placeholder="Search by Part # or Lot #"
            onChange={(_event: any, searchItem: string): void => handleSearch(_event, searchItem)}
          />
        </div>
        <div className="slds-col_padded">
          <Button
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="filterList"
            label="Filter"
            iconPosition="left"
            onClick={handleToggleFilterCycleCount}
          />
        </div> */}
        <Button
          iconCategory="utility"
          iconSize="small"
          className="slds-text-color_default"
          label="Add"
          iconName="add"
          iconPosition="left"
          disabled={disableAction}
          onClick={handleAddParts}
        />
      </ButtonGroup>
    </PageHeaderControl>
  );

  return (
    <IconSettings iconPath="/icons">
      {selectedFilter && selectedFilter.filterValues && (
        <FilterTags selectedFilter={selectedFilter} handleFilter={handleFilterCycleCount} />
      )}
      <PageHeader
        className="events_header"
        icon={
          <img
            src={iconPath}
            className="logo-image"
            alt="company"
            style={{ width: '2.75rem', height: '2.75rem' }}
          />
        }
        onRenderActions={headerActions}
        variant="record-home"
        title="Inventory Count"
      />
      {loading && (
        <Spinner
          size="large"
          variant="base"
          assistiveText={{ label: 'Loading...' }}
          key="spinner"
        />
      )}
      {!loading && countType && (
        <DataTable
          assistiveText={{
            columnSort: 'sort this column',
            columnSortedAscending: 'asc',
            columnSortedDescending: 'desc',
          }}
          items={lineItemsData || []}
          id="inventory-count"
          className="font-12"
          fixedLayout
          keyboardNavigation
          joined
          onSort={handleSort}
        >
          {isOps && [
            <DataTableColumn width="7%">
              <CCSalesReps toggleOpen={handleLineToggle} />
            </DataTableColumn>,
            <DataTableColumn key="productNumber" label="Part Number" property="productNumber">
              <PartNumberCell />
            </DataTableColumn>,
            <DataTableColumn
              key="productdescription"
              label="Part Description"
              property="productdescription"
              width="12%"
            >
              <PartDescriptionCell />
            </DataTableColumn>,
            <DataTableColumn
              key="lotNumber"
              label={userInfo?.businessunit === SPORTS_MED ? 'Lot Number' : 'Lot/Serial'}
              property="lotNumber"
            >
              <TableRowLotNumCell
                handleRefetchLineItems={handleRefetchLineItems}
                cycleCountDetails={cycleCountDetails}
                isOps={isOps}
                disabled={disableAction}
              />
            </DataTableColumn>,
            <DataTableColumn
              key="pendingeimtransaction"
              label="EIM Reserved"
              property="pendingeimtransaction"
            >
              <TableRowEIMCell />
            </DataTableColumn>,
            <DataTableColumn
              key="quantity"
              label="Counted Qty"
              property="quantity"
              style={{ textAlign: 'center' }}
            >
              <QuantityDeltaCell
                handleRefetchLineItems={handleRefetchLineItems}
                disabled={
                  !!(
                    cycleCountDetails &&
                    (cycleCountDetails.status.toLowerCase() === 'cancelled' ||
                      cycleCountDetails.status.toLowerCase() === 'completed')
                  ) || disableAction
                }
                isOps={isOps}
                inProgress={inProgress}
                countType={countType}
              />
            </DataTableColumn>,
            <DataTableColumn
              key="availablequantity"
              label="Expected Qty"
              property="availablequantity"
            >
              <TableRowExpectedCell />
            </DataTableColumn>,
            <DataTableColumn
              key="quantitydifference"
              label="Qty Delta"
              property="quantitydifference"
            >
              <TableRowQTYCell />
            </DataTableColumn>,
            <DataTableColumn
              key="costVariance"
              label="Cost Delta"
              property="costVariance"
              isSorted={sortObj?.sortColumn === 'costVariance'}
              sortable
              sortDirection={sortObj?.sortColumnDirection.costVariance}
            >
              <CostDeltaCell />
            </DataTableColumn>,
            <DataTableColumn label="Comments">
              <CCOpsComments
                disabled={
                  !!(
                    (cycleCountDetails &&
                      (cycleCountDetails.status.toLowerCase() === 'cancelled' ||
                        cycleCountDetails.status.toLowerCase() === 'completed')) ||
                    userInfo?.personas === 'sales rep'
                  )
                }
              />
            </DataTableColumn>,
            <DataTableColumn style={{ textAlign: 'center' }} label="Action">
              <CycleCountDelete
                countType={cycleCountDetails?.countType}
                status={cycleCountDetails?.status}
                lineItemsData={lineItemsData}
                handleDeleteItem={handleDeleteItem}
                handleLoading={handleLoading}
                isOps={isOps}
                disabled={disableAction}
              />
            </DataTableColumn>,
          ]}
        </DataTable>
      )}

      {!loading && !isOps && countType && (
        <DataTable
          assistiveText={{
            columnSort: 'sort this column',
            columnSortedAscending: 'asc',
            columnSortedDescending: 'desc',
          }}
          items={data || []}
          id="inventory-count"
          className="font-12"
          fixedLayout
          keyboardNavigation
          joined
          onSort={handleSort}
        >
          {!isOps && [
            <DataTableColumn
              key={`${inProgress ? 'productDescription' : 'productdescription'}`}
              label="Part"
              property={`${inProgress ? 'productDescription' : 'productdescription'}`}
              width={countType === 'Blind' ? '30%' : '25%'}
            >
              <PartDescriptionCellForRep />
            </DataTableColumn>,
            <DataTableColumn
              key="lotNumber"
              label={userInfo?.businessunit === SPORTS_MED ? 'Lot Number' : 'Lot/Serial'}
              property="lotNumber"
              width={countType === 'Blind' ? '20%' : '15%'}
            >
              <TableRowLotNumCell
                handleRefetchLineItems={handleRefetchDetailLineItems}
                disabled={disableAction}
                cycleCountDetails={cycleCountDetails}
                isOps={isOps}
                inProgress={inProgress}
              />
            </DataTableColumn>,
            <DataTableColumn
              key="expirationDate"
              label="Expiration Date"
              property="expirationDate"
              width={countType === 'Blind' ? '20%' : '15%'}
            >
              <ExpirationDate />
            </DataTableColumn>,
            countType !== 'Blind' && (
              <DataTableColumn
                key={`${inProgress ? 'pendingEimQty' : 'pendingeimtransaction'}`}
                label="EIM Reserved"
                property={`${inProgress ? 'pendingEimQty' : 'pendingeimtransaction'}`}
              >
                <TableRowEIMCell />
              </DataTableColumn>
            ),
            <DataTableColumn
              key="quantity"
              label="Counted Qty"
              property="quantity"
              style={{ textAlign: 'center' }}
              width="15%"
            >
              <QuantityDeltaCell
                handleRefetchLineItems={handleRefetchDetailLineItems}
                disabled={
                  !!(
                    cycleCountDetails &&
                    (cycleCountDetails.status.toLowerCase() === 'cancelled' ||
                      cycleCountDetails.status.toLowerCase() === 'completed')
                  ) || disableAction
                }
                isOps={isOps}
                inProgress={inProgress}
                countType={countType}
              />
            </DataTableColumn>,
            countType !== 'Blind' && (
              <DataTableColumn
                key={`${inProgress ? 'availableQuantity' : 'availablequantity'}`}
                label="Expected Qty"
                property={`${inProgress ? 'availableQuantity' : 'availablequantity'}`}
              >
                <TableRowExpectedCell />
              </DataTableColumn>
            ),
            <DataTableColumn label="Comments">
              <CCOpsComments disabled={!inProgress} />
            </DataTableColumn>,
            <DataTableColumn style={{ textAlign: 'center' }} label="Action">
              <CycleCountDelete
                countType={cycleCountDetails?.countType}
                status={cycleCountDetails?.status}
                lineItemsData={lineItemsData}
                handleDeleteItem={handleDeleteItem}
                handleLoading={handleLoading}
                isOps={isOps}
                disabled={disableAction}
              />
            </DataTableColumn>,
          ]}
        </DataTable>
      )}

      {showSearchCatalogModal && (
        <CycleCountAddItems
          refetch={refetch}
          isOpen={showSearchCatalogModal}
          handleClose={onHandleCloseSearchCatalogModal}
          inventoryDetail={cycleCountDetails}
          selectedFilterPart={selectedFilterPart}
          handleFilter={handleFilter}
        />
      )}
      {filterModalVisible && (
        <FilterCycleCount
          handleToggleFilterCycleCount={handleToggleFilterCycleCount}
          filterModalVisible={filterModalVisible}
          selectedFilter={selectedFilter}
          handleFilterCycleCount={handleFilterCycleCount}
          resetFilters={resetFilters}
          type="listItems"
          cycleCountDetails={cycleCountDetails}
        />
      )}
      {selectedFilterPart && selectedFilterPart.filterValues && (
        <FilterTagsForModal selectedFilter={selectedFilterPart} handleFilter={handleFilter} />
      )}
    </IconSettings>
  );
};

export default InventoryCountTable;
