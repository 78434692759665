/**
 * Custom fetch for Apollo Client to handle re-authorization
 * @module src/graphql/customReauthFetch
 */
import env from '../util/env';
import store from '../store';
import { clearRefreshToken, getRefreshToken } from '../store/ducks/refreshToken';
import { setAccessToken, clearAccessToken, getAccessToken } from '../store/ducks/accessToken';
import { clearUserId } from '../store/ducks/userId';
import { clearUserInfo } from '../store/ducks/userInfo';

const UNAUTHENTICATED = 'UNAUTHENTICATED';

// Detecting ApolloServer standard AuthenticationError message - fix
// https://www.apollographql.com/docs/apollo-server/data/errors/
/* eslint-disable-next-line */
const hasUnauthenticatedError = (element: any): boolean => {
  if (
    typeof element !== 'object' ||
    element === null ||
    element.extensions === undefined ||
    element.extensions.code !== UNAUTHENTICATED
  ) {
    return false;
  }
  return true;
};

/** customFetch function for handling re-authorization */
/* eslint-disable-next-line */
export default async (uri: string, options: any): Promise<Response> => {
  const state = store.getState();
  const refreshToken = getRefreshToken(state);
  try {
    // first attempt to send query
    const initialResponse = await fetch(env.REACT_APP_GRAPHQL_URL, options);
    const clonedInitialResponse = initialResponse.clone(); // can only parse json once so have to clone
    const json = await clonedInitialResponse.json();

    // guard clause for anything but an unauthorized response
    if (
      refreshToken === null ||
      !(Array.isArray(json.errors) && json.errors.find(hasUnauthenticatedError) !== undefined)
    ) {
      return Promise.resolve(initialResponse);
    }

    let updatedAccessToken;
    const updatedState = store.getState();
    const currentAccessToken = getAccessToken(updatedState);
    let usedAccessToken;

    if (
      options.headers &&
      options.headers.authorization &&
      options.headers.authorization.startsWith('Bearer ')
    ) {
      [, usedAccessToken] = options.headers.authorization.split(' ');
    }

    try {
      if (usedAccessToken && usedAccessToken !== currentAccessToken) {
        updatedAccessToken = currentAccessToken;
      } else {
        // obtain new access token
        const refreshResponse = await fetch(`${env.REACT_APP_AUTH_URL}/refresh`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            encryptedRefreshToken: refreshToken,
          }),
        });
        const { accessToken } = await refreshResponse.json();
        store.dispatch(setAccessToken(accessToken));
        updatedAccessToken = accessToken;
      }

      // second attempt to send query
      const updatedHeaders = {
        ...options.headers,
        Authorization: `Bearer ${updatedAccessToken}`,
      };
      const updatedOptions = {
        ...options,
        headers: updatedHeaders,
      };
      return fetch(env.REACT_APP_GRAPHQL_URL, updatedOptions);
    } catch (err2) {
      // logout
      store.dispatch(clearAccessToken());
      store.dispatch(clearRefreshToken());
      store.dispatch(clearUserId());
      store.dispatch(clearUserInfo());
      return Promise.resolve(initialResponse);
    }
  } catch (err) {
    return Promise.reject(err);
  }
};
