import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import EventStatusBadge from '../../Shared/EventStatusBadge';
import { getSvgIcon } from '../../../util/utilityFunctions';

interface Status {
  status: string;
  showRedWarningIcon?: boolean;
  showBackorderdWarning?: boolean;
}
export interface Props {
  children?: FC;
  item?: Status;
}

const OrderStatusCell: FC<Props> = ({
  item = {
    status: '',
    showRedWarningIcon: false,
    showBackorderdWarning: false,
  },
}) => {
  const { status, showRedWarningIcon, showBackorderdWarning } = item;

  const iconBackorder = getSvgIcon('backorder');

  return (
    <IconSettings iconPath="/icons">
      <DataTableCell title={status || 'NEW'}>
        <div style={{ display: 'flex' }}>
          <EventStatusBadge status={status || 'NEW'} />
          {showRedWarningIcon && (
            <div style={{ marginLeft: '5px' }}>
              <Icon
                title="Rejected"
                category="utility"
                name="warning"
                size="x-small"
                style={{ color: '#b83c27' }}
                className="slds-icon-text-error"
              />
            </div>
          )}
          {showBackorderdWarning && (
            <img
              src={iconBackorder}
              alt="backorder"
              style={{ width: '20px', height: '15px', marginLeft: '5px' }}
            />
          )}
        </div>
      </DataTableCell>
    </IconSettings>
  );
};

export default OrderStatusCell;
