/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC } from 'react';
import Card from '@salesforce/design-system-react/components/card';
import DataTable from '@salesforce/design-system-react/components/data-table';
import Spinner from '@salesforce/design-system-react/components/spinner';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import CustomeDataCell from './DataTableCells/CustomDataCell';
import './index.scss';

interface Props {
  showProgress: boolean;
  emailHistory: any;
}

const EmailHistory: FC<Props> = ({ showProgress, emailHistory }) => {
  const columns = [
    <DataTableColumn key="created_at" label="Date & Time" property="created_at" width="3.5em">
      <CustomeDataCell columnName="created_at" />
    </DataTableColumn>,
    <DataTableColumn key="sendername" label="Sender" property="sendername" width="3em" />,
    <DataTableColumn key="recipientname" label="Recipients" property="recipientname" width="6em" />,
    <DataTableColumn key="subject" label="Content" property="subject" width="7em">
      <CustomeDataCell columnName="subject" />
    </DataTableColumn>,
    <DataTableColumn key="attachment" label="Attachments" property="attachment" width="6em">
      <CustomeDataCell columnName="attachment" />
    </DataTableColumn>,
  ];

  return (
    <Card id="EmailHistory" heading="Email History">
      <div style={{ overflow: 'auto' }}>
        <DataTable items={emailHistory} id="DataTableExample-1-default" fixedLayout>
          {columns}
        </DataTable>
      </div>
      {showProgress && <Spinner size="small" />}
    </Card>
  );
};

export default EmailHistory;
