/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import React, { FC, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Icon from '@salesforce/design-system-react/components/icon';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import {
  getDateDifferenceInDays,
  getHoldIcon,
  getWhiteBGIcon,
} from '../../../util/utilityFunctions';
import './index.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Props extends RouteComponentProps {
  children?: FC;
  items?: any;
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const StyledTableRow = withStyles(theme => ({
  root: {
    height: 35,
    backgroundColor: '#f4f4f4',
  },
}))(TableRow);

const StyledDataTableRow = withStyles(theme => ({
  root: {
    height: 35,
  },
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
  root: {
    padding: '0px 16px',
    fontWeight: 'bold',
  },
}))(TableCell);

const StyledCollapsibleTableCell = withStyles(theme => ({
  root: {
    padding: '0px 0px',
    fontWeight: 'bold',
  },
}))(TableCell);
const holdIconPath: any = getHoldIcon();
const holdwhitePath: any = getWhiteBGIcon();
const Row = (props: any) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  return (
    <>
      <StyledDataTableRow>
        <StyledTableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {row.productOnHoldType === 'ITEM' ? (
              <img
                src={holdIconPath}
                className="logo-image"
                alt="company"
                style={{ marginLeft: '8px', marginRight: '8px' }}
              />
            ) : (
              ''
            )}
            {(!open && row.productOnHoldType === 'LOT') ||
            (!open && row.productOnHoldType === 'SERIAL') ? (
              <img
                src={holdIconPath}
                className="logo-image"
                alt="company"
                style={{ marginLeft: '8px', marginRight: '8px' }}
              />
            ) : (
              ''
            )}
            {row.productOnHoldType === 'ITEM' ||
            (!open && row.productOnHoldType === 'LOT') ||
            (!open && row.productOnHoldType === 'SERIAL') ? (
              ''
            ) : (
              <img
                src={holdwhitePath}
                className="logo-image"
                style={{ marginLeft: '8px', marginRight: '8px' }}
                alt="company"
              />
            )}
            {!open ? (
              <Icon
                assistiveText={{ label: 'Announcement' }}
                category="utility"
                name="chevronright"
                size="x-small"
                style={{
                  fill: 'grey',
                  display: 'inline-block',
                }}
              />
            ) : (
              <Icon
                assistiveText={{ label: 'Announcement' }}
                category="utility"
                name="chevrondown"
                size="x-small"
                style={{
                  fill: 'grey',
                  display: 'inline-block',
                }}
              />
            )}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell style={{ width: '34.5%' }}>
          {`${row.productNumber ? row.productNumber : '--'} - ${
            row.productdescription ? row.productdescription : '--'
          }`}
        </StyledTableCell>
        <StyledTableCell>{}</StyledTableCell>
        <StyledTableCell>{}</StyledTableCell>
        <StyledTableCell style={{ width: '12.5%' }}>
          {row.totalActaulQty ? row.totalActaulQty : 0}
        </StyledTableCell>
        <StyledTableCell style={{ width: '18.5%' }}>
          {row.totalDispositionQty ? row.totalDispositionQty : 0}
        </StyledTableCell>
      </StyledDataTableRow>
      <TableRow>
        <StyledCollapsibleTableCell colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                {row?.lotDetails?.map((lot: any) => {
                  const diffDays = getDateDifferenceInDays(
                    new Date(lot.expirationDate).toString(),
                    new Date().toString()
                  );
                  let icon: any;
                  let color: any;
                  if (diffDays < 90) {
                    icon = 'notification';
                    color = '#000084';
                  }
                  if (diffDays < 30) {
                    icon = 'clock';
                    color = '#FFAE42';
                  }
                  if (diffDays < 0) {
                    icon = 'warning';
                    color = '#D2042D';
                  }
                  return (
                    <StyledDataTableRow>
                      <StyledTableCell style={{ width: '14%' }} />
                      <StyledTableCell style={{ width: '27%' }} />
                      <StyledTableCell style={{ width: '12%', paddingLeft: '4%' }}>
                        {lot?.lotSerialNumber}
                        {(row.serialRecalled &&
                          row.serialRecalled.includes(lot?.lotSerialNumber)) ||
                        row.productOnHoldType === 'ITEM' ? (
                          // eslint-disable-next-line react/jsx-indent
                          <>
                            <img
                              src={holdIconPath}
                              className="logo-image"
                              alt="company"
                              style={{ marginLeft: '4%' }}
                            />
                          </>
                        ) : (
                          ''
                        )}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '16%', position: 'relative', left: '5%' }}>
                        {lot?.expirationDate
                          ? moment(lot.expirationDate).format('MM/DD/YYYY')
                          : '--'}
                        <span>
                          {lot?.expirationDate && icon && (
                            <Icon
                              category="utility"
                              name={icon}
                              size="x-small"
                              style={{ fill: color, marginLeft: '5px', marginBottom: '3px' }}
                            />
                          )}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '12.5%' }}>
                        {lot.lotQuantity ? lot.lotQuantity : 0}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '18.5%' }}>
                        {lot.dispQuantity ? lot.dispQuantity : 0}
                      </StyledTableCell>
                    </StyledDataTableRow>
                  );
                })}
              </Table>
            </Box>
          </Collapse>
        </StyledCollapsibleTableCell>
      </TableRow>
    </>
  );
};

const PartsList: FC<Props> = ({ items }) => {
  const classes = useRowStyles();

  return (
    <>
      <div style={{ padding: '10px', float: 'right', marginRight: '5px' }}>
        {/* <div className="inventory-list" style={{ display: 'inline-block' }}>
          Expiration Date Legend: 
        </div> */}
        <div style={{ display: 'inline-block' }}>
          <span style={{ marginLeft: '30px' }}>
            <Icon
              category="utility"
              name="notification"
              size="x-small"
              style={{ fill: '#000084', marginRight: '5px' }}
            />
            Expiring in 90 days
          </span>
          <span style={{ marginLeft: '10px' }}>
            <Icon
              category="utility"
              name="clock"
              size="x-small"
              style={{ fill: '#FFAE42', marginRight: '5px' }}
            />
            30 days
          </span>
          <span style={{ marginLeft: '10px' }}>
            <Icon
              category="utility"
              name="warning"
              size="x-small"
              style={{ fill: '#D2042D', marginRight: '5px' }}
            />
            Expired
          </span>
          <span style={{ marginLeft: '10px', marginRight: '5px' }}>
            <img
              src={holdIconPath}
              className="logo-image"
              alt="company"
              style={{ marginLeft: '8px', marginRight: '8px' }}
            />
            On Hold
          </span>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <StyledTableRow>
            <StyledTableCell />
            <StyledTableCell>Part</StyledTableCell>
            <StyledTableCell>Lot/Serial</StyledTableCell>
            <StyledTableCell>Expiration Date</StyledTableCell>
            <StyledTableCell>Actual Qty</StyledTableCell>
            <StyledTableCell>Dispositioned Qty</StyledTableCell>
          </StyledTableRow>
          <TableBody>
            {items && items?.map((row: any) => <Row key={row?.productNumber} row={row} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withRouter(PartsList);
