import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Button from '@salesforce/design-system-react/components/button';
import { BrowserRouter as Router, RouteComponentProps, withRouter } from 'react-router-dom';

interface EventId {
  externalId: string;
  orderNumber: string;
  eventId: string;
  orderId: string;
  caseExternalId?: string;
}

// interface for props that the data table cell is getting default from data table.
export interface Props extends RouteComponentProps {
  children?: FC;
  item?: EventId;
  type: string;
}

const ReturnIdCell: FC<Props> = ({
  item = {
    externalId: null,
    orderNumber: null,
    eventId: '',
    orderId: null,
    caseExternalId: '',
  },
  type = '',
  history,
}) => {
  const { orderNumber, externalId, eventId, orderId, caseExternalId } = item;
  const goToReturnDetails = (): void => {
    history.push('/inventoryReturnDetails', {
      externalId,
      type,
      orderId,
      caseExternalId,
    });
  };

  return (
    <Router>
      <DataTableCell title={type === 'Returns' ? orderNumber : eventId}>
        <Button
          label={type === 'Returns' ? orderNumber || 'TBD' : eventId || 'TBD'}
          variant="base"
          onClick={goToReturnDetails}
        />
      </DataTableCell>
    </Router>
  );
};

export default withRouter(ReturnIdCell);
