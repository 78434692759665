/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable dot-notation */
/* eslint-disable no-console */

import React, { FC, ReactNode, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import Spinner from '@salesforce/design-system-react/components/spinner';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import Button from '@salesforce/design-system-react/components/button';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import { Tab, Tabs, Paper } from '@material-ui/core';
// import Collapsible from '../Collapsible';
import { NOTIFY_TYPE } from '../../util/constants';
import { GET_NOTIFICATION_CONFIGURATION } from '../../graphql/getNotificationConfiguration';
import CREATE_UPDATE_NOTIFICATION_CONFIG from '../../graphql/mutations/createUpdateNotificationConfig';
import useSnackBar from '../../util/customHooks/useSnackBar';
import SnackBar from '../Shared/SnackBar';
import EventConfig from './EventsConfig';
import OrderConfig from './OrdersConfig';
import InventoryConfig from './InventoryConfig';
// import NotificationConfig from './NotificationConfig';
import SystemConfig from './SystemConfig';
import { getUserInfo } from '../../store/ducks/userInfo';
import NotificationConfigurations from '../NotificationCenter/NotificationConfiguration';

interface Props {
  type: string;
}

// eslint-disable-next-line no-empty-pattern
const NotificationConfiguration: FC<RouteComponentProps> = ({ history, location }) => {
  const userInfo = useSelector(getUserInfo);
  const [
    getNotificationConfigurations,
    {
      data: getNotificationConfigurationData,
      refetch: refetchNotificationConfigurations,
      loading: getNotificationLoader,
    },
  ] = useLazyQuery(GET_NOTIFICATION_CONFIGURATION, {
    fetchPolicy: 'no-cache',
    variables: { businessunit: userInfo?.businessunit || 'Sports Med' },
  });
  const { open, notification, openSnackBar } = useSnackBar();
  const [showInventory, setShowInventory] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState<any>('Events');
  const [tabPanel, setTabPanel] = useState<ReactNode>();
  const [showEvents, setShowEvents] = useState(true);
  const [showOrders, setShowOrders] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showSystem, setShowSystem] = useState(false);
  const [Showcyclecount, setShowcyclecount] = useState(false);

  const [notificationConfigurations, setNotificationconfigurations] = useState<any>(null);
  const [
    createUpdateNotificationConfig,
    { data: notificationConfigurationData, loading },
  ] = useMutation(CREATE_UPDATE_NOTIFICATION_CONFIG);

  useEffect(() => {
    getNotificationConfigurations({
      variables: { businessunit: userInfo?.businessunit || 'Sports Med' },
    });
  }, [getNotificationConfigurations]);

  useEffect(() => {
    if (
      notificationConfigurationData &&
      notificationConfigurationData.createUpdateNotificationConfig
    ) {
      if (notificationConfigurationData.createUpdateNotificationConfig.message === 'success') {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Notification Settings Saved Successfully');
        refetchNotificationConfigurations();
      }
    }
  }, [notificationConfigurationData]);

  useEffect(() => {
    if (
      getNotificationConfigurationData &&
      getNotificationConfigurationData.getNotificationConfiguration
    ) {
      const data = getNotificationConfigurationData.getNotificationConfiguration;
      setNotificationconfigurations(data);
    }
  }, [getNotificationConfigurationData, refetchNotificationConfigurations]);

  const handleCancel = (): void => {
    getNotificationConfigurations({
      variables: { businessunit: userInfo?.businessunit || 'Sports Med' },
    });
  };

  const handleSave = (): void => {
    let mutation: any = {};
    const payload: Array<any> = [];
    notificationConfigurations.forEach((e: any): void => {
      payload.push({
        id: e.id,
        emailCategoryId: e.emailCategoryId,
        businessunitId: e.businessunitId,
        enabled: e.enabled,
        required: e.required,
        description: e.description,
        businessunitName: e.businessunitName,
        subCategory: e.subCategory,
        category: e.category,
      });
    });
    mutation = {
      notificationConfiguration: payload,
    };
    createUpdateNotificationConfig({
      variables: mutation,
    }).catch(error => {
      const errMsg = error.message.split(':')[1] || 'Something went wrong. Please try again.';
      openSnackBar(NOTIFY_TYPE.ERROR, errMsg);
    });
  };

  const handleChange = (event: any, item: any, toggledProperty: string): void => {
    const updatedNotificationSettings = notificationConfigurations.map((element: any) => {
      if (item.id) {
        if (item.id === element.id && item.subCategory === element.subCategory) {
          if (toggledProperty === 'required') {
            return { ...element, required: event.target.checked ? 'true' : 'false' };
          }
          if (toggledProperty === 'enabled') {
            return { ...element, enabled: event.target.checked ? 'true' : 'false' };
          }
        }
      }
      return element;
    });
    setNotificationconfigurations(updatedNotificationSettings);
  };

  const actions = (): ReactNode => (
    <PageHeaderControl>
      <Button
        style={{ color: 'black', marginRight: '10px', fontWeight: 600 }}
        align="right"
        label="Cancel"
        onClick={handleCancel}
      />
      <Button className="yellow-btn" align="right" label="Save" onClick={handleSave} />
    </PageHeaderControl>
  );

  const handleTabSelect = (value: number): void => {
    setSelectedTabIndex(value);
  };

  useEffect(() => {
    const { pathname } = location;
    setShowInventory(false);
    setShowEvents(false);
    setShowNotification(false);
    setShowOrders(false);
    setShowcyclecount(false);
    setShowSystem(false);

    if (pathname.includes('/configurations/event')) {
      setShowEvents(true);
      setTabPanel(<EventConfig selectedTab={selectedTabIndex} />);
    } else if (pathname.includes('/configurations/order')) {
      setShowOrders(true);
      setTabPanel(<EventConfig selectedTab={selectedTabIndex} />);
    } else if (pathname.includes('/configurations/inventories')) {
      setShowInventory(true);
      setTabPanel(<EventConfig selectedTab={selectedTabIndex} />);
    } else if (pathname.includes('/configurations/global')) {
      setShowSystem(true);
      setTabPanel(<SystemConfig />);
    } else if (pathname.includes('/configurations/cyclecount')) {
      setShowcyclecount(true);
      setTabPanel(<EventConfig selectedTab={selectedTabIndex} />);
    } else if (pathname.includes('/configurations/notifications')) {
      setShowNotification(true);
      setTabPanel(<NotificationConfigurations />);
    } else {
      setShowEvents(true);
      setTabPanel(<EventConfig selectedTab={selectedTabIndex} />);
    }
  }, [location]);

  const handleTabChange = (event: any): void => {
    const tabName = event.target.innerText;
    handleTabSelect(tabName);
    switch (tabName) {
      case 'Inventory':
        history.push('/configurations/inventories');
        return;
      case 'Events':
        history.push('/configurations/event');
        return;
      case 'Orders':
        history.push('/configurations/order');
        return;
      case 'Cycle Count':
        history.push('/configurations/cyclecount');
        return;
      case 'Notifications':
        history.push('/configurations/notifications');
        return;
      case 'Global':
        history.push('/configurations/global');
        return;
      default:
        history.push('/configurations/events');
    }
  };

  return (
    <div>
      <Paper className="paper" square>
        <Tabs
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          // value={value}
          onChange={handleTabChange}
          aria-label="notifications_selection"
        >
          <Tab label="Events" className={showEvents ? 'active' : 'inactive'} />
          <Tab label="Inventory" className={showInventory ? 'active' : 'inactive'} />
          <Tab label="Orders" className={showOrders ? 'active' : 'inactive'} />
          <Tab label="Cycle Count" className={Showcyclecount ? 'active' : 'inactive'} />
          <Tab label="Notifications" className={showNotification ? 'active' : 'inactive'} />
          <Tab label="Global" className={showSystem ? 'active' : 'inactive'} />
        </Tabs>
      </Paper>
      <div className="slds-p-horizontal_large slds-p-vertical_x-large events_list">{tabPanel}</div>
    </div>
  );
};

export default withRouter(NotificationConfiguration);
