/*
 * Get Branches GraphQL specification
 * @module src/graphql/getBranches
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_ELIGIBLE_ACCOUNTS = gql`
  query getEligibleCycleCountAccountsForUsers(
    $startDate: Date
    $endDate: Date
    $salesRepId: String
  ) {
    getEligibleCycleCountAccountsForUsers(
      startDate: $startDate
      endDate: $endDate
      salesRepId: $salesRepId
    ) {
      id
      number
      name
      branchId
      territory
      ithasinventory
    }
  }
`;
