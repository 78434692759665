import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import EventStatusBadge from '../../../Shared/EventStatusBadge';

interface Status {
  status: string;
  visualIndicator: string;
}
export interface Props {
  children?: FC;
  item?: Status;
}

const ReturnStatusCell: FC<Props> = ({
  item = {
    status: '',
    visualIndicator: '',
  },
}) => {
  const { status, visualIndicator } = item;
  // let statusToShow =
  //   visualIndicator === 'Rejected' || visualIndicator === 'Approved' ? status : visualIndicator;

  // if (visualIndicator === 'Failed' && status === 'Failed') {
  //   statusToShow = 'Approved';
  // }

  let statusToShow = visualIndicator;

  if (visualIndicator === 'Rejected' || visualIndicator === 'Approved') {
    statusToShow = status;
  } else if (
    status === 'Failed' &&
    (visualIndicator === 'Failed' || visualIndicator === 'Closed')
  ) {
    statusToShow = 'Approved';
  }
  return (
    <IconSettings iconPath="/icons">
      <DataTableCell title={statusToShow || 'NEW'}>
        <div style={{ display: 'flex' }}>
          <EventStatusBadge status={statusToShow || 'NEW'} />
          {status === 'Requested' && visualIndicator === 'Rejected' && (
            <div style={{ marginLeft: '5px' }}>
              <Icon
                title="Rejected"
                category="utility"
                name="warning"
                size="x-small"
                style={{ color: '#b83c27' }}
                className="slds-icon-text-error"
              />
            </div>
          )}
        </div>
      </DataTableCell>
    </IconSettings>
  );
};

export default ReturnStatusCell;
