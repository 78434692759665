/*
 * Get Branches GraphQL specification
 * @module src/graphql/getBranches
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_USER_HOLD = gql`
  query getUserHoldInfo($id: String) {
    getUserHoldInfo(id: $id) {
      id
      onHold
      onHoldReason
    }
  }
`;
