/**
 * Redux file for getting/setting event filters
 * @module src/store/ducks/inventoryReprocessFilters
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_IN_REPROCESS_FILTERS = 'SET_IN_REPROCESS_FILTERS';
const CLEAR_IN_REPROCESS_FILTERS = 'CLEAR_IN_REPROCESS_FILTERS';

interface SetInventoryReprocessFiltersActionType {
  payload: string | null;
  type: typeof SET_IN_REPROCESS_FILTERS;
}

interface ClearInventoryReprocessFiltersActionType {
  type: typeof CLEAR_IN_REPROCESS_FILTERS;
}

/** eventFilters action type */

export type InventoryReprocessFilterActionType =
  | SetInventoryReprocessFiltersActionType
  | ClearInventoryReprocessFiltersActionType;

/** set eventFilters action creator */

export const setInventoryReprocessFilters = (
  state: string | null
): InventoryReprocessFilterActionType => {
  return {
    payload: state,
    type: SET_IN_REPROCESS_FILTERS,
  };
};

/** clear eventFilters action creator */

export const clearInventoryReprocessFilters = (): ClearInventoryReprocessFiltersActionType => {
  return {
    type: CLEAR_IN_REPROCESS_FILTERS,
  };
};

/** eventFilters state type */

export type InventoryReprocessFilterState = string | null;

/** eventFilters reducer */

export default (
  state: InventoryReprocessFilterState = null,
  action: ActionType
): InventoryReprocessFilterState => {
  switch (action.type) {
    case SET_IN_REPROCESS_FILTERS: {
      return action.payload;
    }
    case CLEAR_IN_REPROCESS_FILTERS: {
      return null;
    }
    default:
      return state;
  }
};

/** selector to get accessToken */

export const getInventoryReprocessFilters = (state: State): InventoryReprocessFilterState => {
  return state.inventoryReprocessFilters;
};
