/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import moment from 'moment';

interface Item {
  attachmentType: string;
}

interface Props {
  children?: FC;
  item?: Item;
  columnName: string;
}

const CustomeDataCell: FC<Props> = (props: any) => {
  const [modelIsOpen, setModelIsOpen] = useState(false);
  const [modelContent, setModelContent] = useState('');

  const getTimezoneName = (formattedDate: any) => {
    const currentDate = new Date(formattedDate);
    const full = currentDate.toLocaleDateString(undefined, { timeZoneName: 'long' });
    return full;
  };

  const getFileName = (fileurl: string) => {
    const parsedUrl = new URL(fileurl);
    const { pathname } = parsedUrl;
    const parts = pathname.split('/');
    const filename = parts[parts.length - 1];
    return filename;
  };
  return (
    <>
      <DataTableCell {...props}>
        {props.columnName === 'created_at' ? (
          <span
            title={`${moment(props?.item?.[props.columnName]).format('MM-DD-YY, hh:mm A ')}
             ${getTimezoneName(props?.item?.[props.columnName])
               .match(/\b([A-Z])/g)
               ?.join('')}`}
          >
            {moment(props?.item?.[props.columnName]).format('MM-DD-YY, hh:mm A ')}
            {getTimezoneName(props?.item?.[props.columnName])
              .match(/\b([A-Z])/g)
              ?.join('')}
          </span>
        ) : (
          <></>
        )}
        {props.columnName === 'subject' ? (
          <Button
            label={props?.item?.[props.columnName]}
            onClick={(event: any) => {
              event.preventDefault();
              setModelIsOpen(true);
              setModelContent(props.item.content);
            }}
            title={props?.item?.[props.columnName]}
            variant="base"
            className="slds-truncate"
          />
        ) : (
          <></>
        )}
        {props.columnName === 'attachment' ? (
          <Button
            label={getFileName(props?.item?.[props.columnName])}
            onClick={(event: any) => {
              event.preventDefault();
              setModelIsOpen(true);
              setModelContent(
                `<iframe scrolling='no' width='100%' height='800px' src='${
                  props?.item?.[props.columnName]
                }#toolbar=1&navpanes=0&scrollbar=0' ></iframe>`
              );
            }}
            title={getFileName(props?.item?.[props.columnName])}
            variant="base"
            className="slds-truncate"
          />
        ) : (
          <></>
        )}
      </DataTableCell>
      {modelIsOpen ? (
        <Modal
          assistiveText={{ dialogLabel: 'Email Content' }}
          isOpen={modelIsOpen}
          size="medium"
          onRequestClose={() => setModelIsOpen(false)}
        >
          <section className="slds-p-around_medium">
            <div dangerouslySetInnerHTML={{ __html: modelContent }} />
          </section>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

CustomeDataCell.displayName = DataTableCell.displayName;

export default CustomeDataCell;
