import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { getSvgIcon } from '../../../util/utilityFunctions';
import './index.scss';

interface Option {
  id: string;
  name: string;
  email: string;
  label?: string;
  isfavourite?: boolean;
  __typename: string;
  contactRole: string;
}

interface Props {
  allContacts: Option[];
  options: Option[];
  favourites: Option[];
  selectedOptions: Option[];
  toggleFavorite: (arg: Option) => void;
  toggleOption: (arg: Option) => void;
  removeSelectedOption: (arg: Option) => void;
  handleSearch: (arg: ChangeEvent<HTMLInputElement>) => void;
}

const CustomMultiSelectDropdown: FC<Props> = ({
  allContacts,
  options,
  favourites,
  toggleFavorite,
  selectedOptions,
  toggleOption,
  removeSelectedOption,
  handleSearch,
}) => {
  const starMarkedIcon = getSvgIcon('starMarked');
  const starNotMarkedIcon = getSvgIcon('starNotMarked');

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(true);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleDropdownFocus = (): void => {
    setIsDropdownOpen(true);
  };

  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: MouseEvent): void => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    // Add event listener when the dropdown is open
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      // Remove event listener when the dropdown is closed
      document.removeEventListener('mousedown', handleClickOutside);
    }
    // Clean up the event listener on component unmount
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const getSelectedLabel = (option: Option): string | undefined => {
    return allContacts?.find(element => element.email === option.email)?.label;
  };

  return (
    <div className="custom-multiselect-dropdown">
      <div className={`multiselect-search-button ${isDropdownOpen ? 'active' : ''}`}>
        <input
          type="search"
          className="multiselect-search-grp"
          onFocus={handleDropdownFocus}
          placeholder="Please select/search contact"
          onChange={(event): void => handleSearch(event)}
        />
      </div>
      {isDropdownOpen && (
        <div ref={dropdownRef} className="dropdown">
          {options?.map((option: Option, index: number) => (
            <div key={index} className="dropdown-option ">
              <div
                className="vertical-align-middle cursor"
                onClick={(): void => {
                  if (option.email) toggleFavorite(option);
                }}
                onKeyPress={(): void => {
                  if (option.email) toggleFavorite(option);
                }}
                aria-hidden="true"
              >
                {favourites?.filter(
                  element => element.name === option.name && element.email === option.email
                ).length ? (
                  <img src={starMarkedIcon} className="logo-image" alt="flagged" />
                ) : (
                  <img
                    src={starNotMarkedIcon}
                    className={!option.email ? 'logo-image slds-hidden' : 'logo-image'}
                    alt="not flagged"
                  />
                )}
              </div>
              <div
                onClick={(): void => {
                  if (option.email) toggleOption(option);
                }}
                onKeyPress={(): void => {
                  if (option.email) toggleOption(option);
                }}
                aria-hidden="true"
                role="button"
                className="cursor drop-detail"
              >
                <input
                  type="checkbox"
                  defaultChecked={option.isfavourite}
                  className={!option.email ? 'slds-hidden' : ''}
                  checked={
                    selectedOptions.filter(
                      element => element.name === option.name && element.email === option.email
                    ).length > 0
                  }
                  onChange={(): void => {
                    if (option.email) toggleOption(option);
                  }}
                />
                {option?.label}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="selected-options">
        {selectedOptions?.map((option: Option, index: number) => (
          <div key={index} className="selected-option">
            {getSelectedLabel(option)}
            <button
              type="button"
              className="removeButton"
              onClick={(): void => removeSelectedOption(option)}
            >
              X
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomMultiSelectDropdown;
