/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars*/
/* eslint-disable react/jsx-curly-newline*/

/**
 * Module for header on Cycle Count page
 * @module src/CycleCountHeader
 */
import React, { FC, ReactNode, useState, useCallback } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import { useLazyQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import Popover from '@salesforce/design-system-react/components/popover';
import { GET_FORM_CONFIGURATIONS } from '../../../graphql/getFormConfigurations';
import { getEventIcon } from '../../../util/utilityFunctions';
import NewCycleCount from '../NewCycleCount';
import { ORDERS, CYCLE_COUNT } from '../../../util/constants';
import { getUserInfo } from '../../../store/ducks/userInfo';
import CancelEventModal from '../../CreateEditSurgeryEvent/CancelEventCreationModal';

interface FilterPreset {
  filterValues: any;
  filterExternalId: string;
  filterName: string;
}
interface Props {
  label: string;
  handleCycleCountSearch: (event: any, val: string) => void;
  handleToggleFilterCycleCount: () => void;
  refetchCycleCounts: () => void;
  type: string;
  filterPresets?: FilterPreset[];
  onEditPress: (filter: FilterPreset, isEditPress: boolean) => void;
  handleFilterEvents: (filterValues: any, filterName: string) => void;
  resetFilters: () => void;
}

const CycleCountHeader: FC<Props> = ({
  handleCycleCountSearch,
  handleToggleFilterCycleCount,
  refetchCycleCounts,
  type,
  onEditPress,
  handleFilterEvents,
  resetFilters,
  filterPresets,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filterPresetOpen, setFilterPresetOpen] = useState(false);
  const [getFormConfigurations, { data }] = useLazyQuery(GET_FORM_CONFIGURATIONS);
  const userInfo = useSelector(getUserInfo);
  const [canceModalVisible, setCanceModalVisible] = useState(false);

  const handleNewCycleCount = (): void => {
    setIsOpen(true);
  };

  const handleCancelButton = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleCancelModal = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleCancelModalConfirmPress = (): void => {
    setCanceModalVisible(!canceModalVisible);
    setIsOpen(!isOpen);
  };

  const handleModalToggle = useCallback((): void => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleEditPress = (filter: FilterPreset) => (): void => {
    setFilterPresetOpen(false);
    onEditPress(filter, true);
  };

  const handleFilterEventItems = (filterValues: any, filterName: string) => (): void => {
    setFilterPresetOpen(false);
    handleFilterEvents(filterValues, filterName);
  };

  const handleFilterPresetPopover = useCallback((): void => {
    setFilterPresetOpen(!filterPresetOpen);
  }, [filterPresetOpen]);

  const handleFilterPresetPopoverClose = useCallback((): void => {
    setFilterPresetOpen(false);
  }, []);

  const headerActions = (): ReactNode => (
    <PageHeaderControl>
      {type !== 'REVIEW' && userInfo && userInfo?.personas?.toLowerCase()?.includes('branch op') && (
        <ButtonGroup variant="list" id="button-group-page-header-actions">
          <Button
            iconCategory="utility"
            iconSize="small"
            className="slds-text-color_default"
            label="New"
            iconName="add"
            iconPosition="left"
            onClick={handleNewCycleCount}
          />
        </ButtonGroup>
      )}
    </PageHeaderControl>
  );

  const headerControls = (): ReactNode => (
    <>
      <PageHeaderControl className="slds-m-left_large">
        <div className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
          <div className="slds-col_padded">
            <Input
              iconRight={
                <InputIcon
                  assistiveText={{
                    icon: 'Search',
                  }}
                  name="search"
                  category="utility"
                />
              }
              id="search-case-id"
              placeholder="Search by"
              onChange={(_event: any, searchItem: string): void =>
                handleCycleCountSearch(_event, searchItem)
              }
            />
          </div>
        </div>
      </PageHeaderControl>
      <PageHeaderControl className="slds-m-left_large">
        {type === 'REVIEW' ? (
          <ButtonGroup variant="list" id="button-group-page-header-controls">
            <Button
              iconCategory="utility"
              className="slds-text-color_default"
              iconName="filterList"
              label="Filter"
              iconPosition="left"
              onClick={handleToggleFilterCycleCount}
            />
            <div className="slds-dropdown-trigger slds-dropdown-trigger_click filter-preset-popover">
              <Popover
                align="bottom"
                isOpen={filterPresetOpen}
                onClose={handleFilterPresetPopoverClose}
                onRequestClose={handleFilterPresetPopoverClose}
                ariaLabelledby=""
                body={
                  <div className="filter-preset_dropdown">
                    <ul className="slds-dropdown__list" role="menu" aria-label="Show More">
                      <li className="slds-dropdown__item" role="presentation" key="reset">
                        <Button
                          className="slds-button slds-button_neutral filter-preset-reset-button"
                          onClick={resetFilters}
                        >
                          Reset
                        </Button>
                      </li>
                      {filterPresets &&
                        filterPresets.map((filter: FilterPreset) => {
                          return (
                            <li
                              className="slds-dropdown__item"
                              role="presentation"
                              key={filter.filterExternalId}
                            >
                              <Button
                                className="slds-button slds-button_neutral filter-preset-heading-button"
                                onClick={handleFilterEventItems(
                                  filter.filterValues,
                                  filter.filterName
                                )}
                              >
                                {filter.filterName}
                              </Button>
                              <Button
                                className="slds-button slds-button_neutral filter-preset-edit-button"
                                onClick={handleEditPress(filter)}
                              >
                                Edit
                              </Button>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                }
                id="popover-walkthrough"
              >
                <Button
                  className="filter-drop-trigger"
                  iconCategory="utility"
                  iconName="filter"
                  iconPosition="left"
                  label="Filter Presets"
                  onClick={handleFilterPresetPopover}
                />
              </Popover>
            </div>
          </ButtonGroup>
        ) : (
          <ButtonGroup variant="list" id="button-group-page-header-controls">
            <Button
              iconCategory="utility"
              className="slds-text-color_default"
              iconName="filterList"
              label="Filter"
              iconPosition="left"
              onClick={handleToggleFilterCycleCount}
            />
          </ButtonGroup>
        )}
      </PageHeaderControl>
    </>
  );

  const iconPath = getEventIcon(CYCLE_COUNT);

  return (
    <div>
      <IconSettings iconPath="/icons">
        <PageHeader
          className="events_header"
          icon={
            type === 'REVIEW' ? (
              <img src={iconPath} className="logo-image" alt="Review Counts" />
            ) : (
              <img src={iconPath} className="logo-image" alt="Cycle Counts" />
            )
          }
          onRenderActions={headerActions}
          onRenderControls={headerControls}
          title={type === 'REVIEW' ? 'Review Counts' : 'Cycle Counts'}
          label=""
          truncate
          variant="object-home"
        />
      </IconSettings>
      {isOpen && (
        <NewCycleCount
          isOpen={isOpen}
          toggleOpen={handleModalToggle}
          refetch={refetchCycleCounts}
          formData={data}
          eventType="New"
          handleCancelButton={handleCancelButton}
        />
      )}
      {canceModalVisible && (
        <CancelEventModal
          isNew
          canceModalVisible={canceModalVisible}
          handleCancelModal={handleCancelModal}
          handleCancelModalConfirmPress={handleCancelModalConfirmPress}
        />
      )}
    </div>
  );
};

export default CycleCountHeader;
