/**
 * Redux file for getting/setting event filters
 * @module src/store/ducks/orderFilters
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_ORDER_FILTERS = 'SET_ORDER_FILTERS';
const CLEAR_ORDER_FILTERS = 'CLEAR_ORDER_FILTERS';

interface SetOrderFiltersActionType {
  payload: string | null;
  type: typeof SET_ORDER_FILTERS;
}

interface ClearOrderFiltersActionType {
  type: typeof CLEAR_ORDER_FILTERS;
}

/** eventFilters action type */

export type OrderFilterActionType = SetOrderFiltersActionType | ClearOrderFiltersActionType;

/** set eventFilters action creator */

export const setOrderFilters = (state: string | null): OrderFilterActionType => {
  return {
    payload: state,
    type: SET_ORDER_FILTERS,
  };
};

/** clear eventFilters action creator */

export const clearOrderFilters = (): OrderFilterActionType => {
  return {
    type: CLEAR_ORDER_FILTERS,
  };
};

/** eventFilters state type */

export type OrderFilterState = string | null;

/** eventFilters reducer */

export default (state: OrderFilterState = null, action: ActionType): OrderFilterState => {
  switch (action.type) {
    case SET_ORDER_FILTERS: {
      return action.payload;
    }
    case CLEAR_ORDER_FILTERS: {
      return null;
    }
    default:
      return state;
  }
};

/** selector to get accessToken */

export const getOrderFilter = (state: State): OrderFilterState => {
  return state.orderFilters;
};
