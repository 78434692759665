/**
 * Graphql mutation to delete filter preset
 * @module src/graphql/deleteFilterPreset
 */
import gql from 'graphql-tag';

/** delete filter preset */
const DELETE_INVENTORY_FILTER_PRESET = gql`
  mutation deleteInventoryFilterPreset($nameOfFilter: String) {
    deleteInventoryFilterPreset(nameOfFilter: $nameOfFilter) {
      _id
      nameOfFilter
      user
      serviceLevel
      salesRep
      recordType
      productGroup
      userDetails {
        sfId
        name
      }
      territory {
        id
        name
      }
      status
      assignedTo
      cost {
        value
      }
      submittedDate {
        from
        to
      }
      needByDate {
        from
        to
      }
    }
  }
`;

export default DELETE_INVENTORY_FILTER_PRESET;
