/*
 * Graphql Query to get notfication settings
 * @module src/graphql/getNotificationSettings
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/** Get Parts GraphQL API query */
export const GET_NOTIFICATION_SETTINGS = gql`
  query getNotificationSettings {
    getNotificationSettings {
      eventInvite
      eventUpdate
      eventCancellation
      eventInitiated
      inventoryShipment
      customerComment
      atpNotification
      urgentReplenish
      pickupReminder
    }
  }
`;
