import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import './index.scss';
import EventStatusBadge from '../../../Shared/EventStatusBadge';

interface Status {
  eventStatus: string;
  visualIndicator?: string;
}
export interface Props {
  children?: FC;
  item?: Status;
}

const InventoryRequestStatusCell: FC<Props> = ({
  item = {
    eventStatus: '',
    visualIndicator: '',
  },
}) => {
  const { eventStatus, visualIndicator } = item;
  return (
    <DataTableCell title={visualIndicator || eventStatus || 'NEW'}>
      <EventStatusBadge status={visualIndicator || eventStatus || 'NEW'} />
    </DataTableCell>
  );
};

export default InventoryRequestStatusCell;
