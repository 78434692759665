/**
 * Graphql mutation to update Notification settings
 * @module src/graphql/updateNotificationSettings
 */
import gql from 'graphql-tag';

/** updating Notification settings  */
const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation updateNotificationSettings(
    $eventInvite: Boolean
    $eventUpdate: Boolean
    $eventCancellation: Boolean
    $eventInitiated: Boolean
    $inventoryShipment: Boolean
    $customerComment: Boolean
    $atpNotification: Boolean
    $urgentReplenish: Boolean
  ) {
    updateNotificationSettings(
      eventInvite: $eventInvite
      eventUpdate: $eventUpdate
      eventCancellation: $eventCancellation
      eventInitiated: $eventInitiated
      inventoryShipment: $inventoryShipment
      customerComment: $customerComment
      atpNotification: $atpNotification
      urgentReplenish: $urgentReplenish
    ) {
      message
    }
  }
`;

export default UPDATE_NOTIFICATION_SETTINGS;
