/**
 * App component file.
 * @module src/components/App
 */
/* eslint-disable no-console */
import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { ApolloProvider } from '@apollo/react-hooks';
import queryString from 'query-string';
import Routes from '../Routes';
import client from '../../graphql/client';
import { valid } from '../../util/env';
import store, { persistor } from '../../store';
import './index.scss';
import CustomFooter from './CustomFooter';

const { grant_token: grantToken } = queryString.parse(window.location.search);
if (grantToken !== undefined && grantToken !== null && !Array.isArray(grantToken)) {
  window.history.replaceState({}, document.title, '/');
}

const ReduxApolloApp: FC = () => {
  return (
    <IconSettings iconPath="/icons">
      <div />
    </IconSettings>
  );
};

const ReduxApp: FC = () => {
  return <ReduxApolloApp />;
};

const App: FC = () => {
  return valid ? (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
          <ReduxApp />
          <CustomFooter />
        </PersistGate>
      </Provider>
    </ApolloProvider>
  ) : (
    <div>
      <p>Invalid build environment</p>
    </div>
  );
};

/** App component */
export default App;
