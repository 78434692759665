/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Module for Cancel Event
 * @module src/components/Inventory/InventoryRequestCancel/index
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
// import Icon from '@salesforce/design-system-react/components/icon';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Button from '@salesforce/design-system-react/components/button';
import './index.scss';

interface Props {
  cancelInventoryModalVisible: boolean;
  handleCancelInventoryModal: () => void;
  eventId: string;
  cancelInventory: () => void;
  cancelInventoryLoading: boolean;
}

const CancelTransferInventory: FC<Props> = ({
  cancelInventoryModalVisible,
  handleCancelInventoryModal,
  eventId,
  cancelInventory,
  cancelInventoryLoading,
}) => {
  const handleCancelInventory = (): void => {
    if (cancelInventory) {
      cancelInventory();
    }
  };

  // const cancelButtonDisabled = !!(selectedReason === '');

  return (
    <IconSettings iconPath="/icons">
      <Modal
        isOpen={cancelInventoryModalVisible}
        onRequestClose={handleCancelInventoryModal}
        dismissOnClickOutside={false}
        ariaHideApp={false}
        heading={
          <div className="slds-float_left slds-text-title_bold slds-text-heading_medium">{`Inventory Transfer ${eventId}`}</div>
        }
        footer={[
          <div
            className="slds-align_absolute-center event_cancel_button"
            key="cancel_return_inventory"
          >
            {cancelInventoryLoading && (
              <Spinner
                size="small"
                variant="base"
                assistiveText={{ label: 'Event creation Loading...' }}
              />
            )}
            <Button label="No" onClick={handleCancelInventoryModal} />
            <Button
              style={{ backgroundColor: '#ffb501' }}
              label="Yes"
              onClick={handleCancelInventory}
            />
          </div>,
        ]}
      >
        <section style={{ position: 'relative' }}>
          <div style={{ position: 'relative', padding: '1rem' }}>
            <div className="cancel-success" />
            {`Are you sure you want to cancel this Inventory Transfer?`}
          </div>
        </section>
      </Modal>
    </IconSettings>
  );
};

/** Cancel Inventory **/
export default CancelTransferInventory;
