/**
 * Graphql mutation to create event
 * @module src/graphql/submitInventoryRequest
 */
import gql from 'graphql-tag';

/** creating event */

const SUBMIT_INVENTORY_REQUEST = gql`
  mutation submitInventoryRequest(
    $caseId: String
    $caseExternalId: String
    $accountId: String
    $branchId: String
    $salesRepId: String
    $shipToId: String
    $serviceLevel: String
    $needByDate: Date
    $shipMethod: String
    $eventType: String
    $system: String
    $version: String
    $comments: [Comment]
    $lineItems: [SubmitInventoryRequestLineItems]
  ) {
    submitInventoryRequest(
      caseId: $caseId
      caseExternalId: $caseExternalId
      accountId: $accountId
      branchId: $branchId
      salesRepId: $salesRepId
      shipToId: $shipToId
      serviceLevel: $serviceLevel
      needByDate: $needByDate
      shipMethod: $shipMethod
      eventType: $eventType
      system: $system
      version: $version
      lineItems: $lineItems
      comments: $comments
    ) {
      message
    }
  }
`;

export default SUBMIT_INVENTORY_REQUEST;
