/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Module for header on InventoryFavoriteDetailCatalog Page
 * @module src/SurgicalDetail/InventoryFavoriteDetailCatalog
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty-pattern */
import React, { FC, useEffect, useState } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import './index.scss';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import InventoryFavoriteTable from '../InventoryFavoriteTable';

interface Props {
  inventoryRequestFav: any;
  updateFavLineItems: (item: any) => void;
  children?: any;
}

const InventoryFavoriteDetailCatalog: FC<Props> = ({ inventoryRequestFav, updateFavLineItems }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [irFavoriteData, setIrFavoriteData] = useState(inventoryRequestFav);
  const updateLineItems = (items: any): void => {
    updateFavLineItems(items);
    const data = { ...irFavoriteData };
    data.lineItems = items;
    setIrFavoriteData(data);
  };
  const handleTabSelect = (value: number): void => {
    setSelectedTabIndex(value);
  };
  useEffect(() => {
    if (inventoryRequestFav) {
      setIrFavoriteData(inventoryRequestFav);
    }
  }, [inventoryRequestFav]);

  return (
    <div style={{ padding: '16px' }}>
      <IconSettings iconPath="/icons">
        <Tabs
          variant="scoped"
          id="usage-tabs-scoped"
          onSelect={handleTabSelect}
          defaultSelectedIndex={0}
          selectedIndex={selectedTabIndex}
        >
          <TabsPanel label="Inventory">
            <InventoryFavoriteTable
              inventoryRequestFav={irFavoriteData}
              updateFavLineItems={updateLineItems}
            />
          </TabsPanel>
        </Tabs>
      </IconSettings>
    </div>
  );
};

/** Custom Header on InventoryFavoriteDetailCatalog Page **/
export default InventoryFavoriteDetailCatalog;
