/**
 * Redux file for getting/setting auth token
 * @module src/store/ducks/accessToken
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_USER_ID = 'SET_USER_ID';
const CLEAR_USER_ID = 'CLEAR_USER_ID';

interface SetUserIdActionType {
  payload: string | null;
  type: typeof SET_USER_ID;
}

interface ClearUserIdActionType {
  type: typeof CLEAR_USER_ID;
}

/** accessToken action type */

export type UserIdActionType = SetUserIdActionType | ClearUserIdActionType;

/** set accessToken action creator */

export const setUserId = (state: string | null): UserIdActionType => {
  return {
    payload: state,
    type: SET_USER_ID,
  };
};

/** clear accessToken action creator */

export const clearUserId = (): ClearUserIdActionType => {
  return {
    type: CLEAR_USER_ID,
  };
};

/** accessToken state type */

export type UserIdState = string | null;

/** accessToken reducer */

export default (state: UserIdState = null, action: ActionType): UserIdState => {
  switch (action.type) {
    case SET_USER_ID: {
      return action.payload;
    }
    case CLEAR_USER_ID: {
      return null;
    }
    default:
      return state;
  }
};

/** selector to get accessToken */

export const getUserId = (state: State): UserIdState => {
  return state.userId;
};
