/*
 * User Info GraphQL specification
 * @module src/graphql/getUserInfo
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/** User Info GraphQL API query */
export const GET_USER_INFO = gql`
  query getUserInfo($id: String) {
    getUserInfo(id: $id) {
      id
      firstName
      lastName
      userName
      email
      userType
      division
      divisions
      personas
      contactId
      isinventoryhold
      isSubInventoryOnhold
      allowCustomerTransfer
      allowRepTransfer
      allowStandardReturn
      allowPickupReturn
      allowBillOnly
      subInventoryName
      subInventoryCode
      assignedBusinessunits
      businessunit
      onholdReason
      salesRepEmail
      assignedScreens {
        screen
        module
        isRequired
        screenType
      }
      businessRules {
        rules
        values
        isRequired
        description
        screenName
        moduleName
        screenType
        businessunitName
        screenCode
        businessunitId
        formId
        isheader
        value_type
        header
      }
    }
  }
`;
