/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import { ATTACHMENT_TYPE } from '../../../../../../../util/constants';

interface Item {
  attachmentType: string;
  showOnUsageSheet: boolean;
}

interface Props {
  children?: FC;
  item?: Item;
  indicator: string;
}

const CustomeDataCell: FC<Props> = ({
  item = {
    showOnUsageSheet: false,
    attachmentType: '',
  },
  indicator = '',
}) => {
  const { attachmentType, showOnUsageSheet } = item;
  const flag =
    indicator === ATTACHMENT_TYPE.PO ? attachmentType === ATTACHMENT_TYPE.PO : showOnUsageSheet;
  return (
    <DataTableCell>
      <IconSettings iconPath="/icons">
        {flag ? (
          <Icon category="utility" name="check" size="small" />
        ) : (
          <span style={{ color: 'lightgray' }}>NA</span>
        )}
      </IconSettings>
    </DataTableCell>
  );
};

CustomeDataCell.displayName = DataTableCell.displayName;

export default CustomeDataCell;
