/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Icon from '@salesforce/design-system-react/components/icon';
import { classicNameResolver } from 'typescript';
import { getHoldIcon, getEventIconInner, getWhiteBGIcon } from '../../../util/utilityFunctions';

interface Props {
  userView?: string;
  gridData?: any;
  selectedPart?: any;
  selectedAccount?: any;
  history: any;
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const StyledTableRow = withStyles(theme => ({
  root: {
    height: 35,
    backgroundColor: '#f4f4f4',
  },
}))(TableRow);

const StyledDataTableRow = withStyles(theme => ({
  root: {
    height: 35,
  },
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
  root: {
    padding: '0px 16px',
    fontWeight: 'bold',
    // pointerEvents: 'none'
  },
}))(TableCell);
const holdIconPath = getHoldIcon();
const holdwhitePath: any = getWhiteBGIcon();
const StyledCollapsibleTableCell = withStyles(theme => ({
  root: {
    padding: '0px 0px',
    fontWeight: 'bold',
  },
}))(TableCell);

const StyledTableLotCell = withStyles(theme => ({
  root: {
    // padding: '0px 16px',
    fontWeight: 'bold',
    color: '#006DCC',
  },
}))(TableCell);

const Row = (props: any) => {
  const { row, selectedView, history } = props;
  const [open, setOpen] = useState(false);
  const [openAccount, setOpenAccount] = useState<any[]>();
  const [openEvents, setOpenEvents] = useState<any[]>();
  const classes = useRowStyles();

  const handleEventClick = (e: any, elem: any) => {
    e.stopPropagation();
    history.push('/surgicalDetails', {
      eventType: elem.eventType,
      externalId: elem.eventExternalId,
      id: elem.eventNumber,
    });
  };

  const openCloseAccountCollapsible = (accountId: any) => {
    if (openAccount) {
      if (openAccount.includes(accountId)) {
        const array = openAccount.filter((item: any) => {
          return item !== accountId;
        });
        setOpenAccount(array);
      } else {
        setOpenAccount([...openAccount, accountId]);
      }
    } else {
      setOpenAccount([accountId]);
    }
  };
  let arr = 0;
  let arrItem = 0;
  const openCloseEventsCollapsible = (eventId: any) => {
    if (openEvents) {
      if (openEvents.includes(eventId)) {
        const array = openEvents.filter((item: any) => {
          return item !== eventId;
        });
        setOpenEvents(array);
      } else {
        setOpenEvents([...openEvents, eventId]);
      }
    } else {
      setOpenEvents([eventId]);
    }
  };
  return (
    <>
      <StyledDataTableRow className={classes.root}>
        <StyledTableCell>
          <span>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {!open ? (
                <StyledTableCell style={{ borderBottom: 'aliceblue', padding: '0px 10px' }}>
                  <Icon
                    assistiveText={{ label: 'Announcement' }}
                    category="utility"
                    name="chevronright"
                    size="x-small"
                    style={{
                      fill: '#000',
                      display: 'inline-block',
                      // marginLeft: '10px',
                    }}
                  />
                  {`${row.territoryName}`}
                </StyledTableCell>
              ) : (
                <StyledTableCell style={{ borderBottom: 'aliceblue', padding: '0px 10px' }}>
                  <Icon
                    assistiveText={{ label: 'Announcement' }}
                    category="utility"
                    name="chevrondown"
                    size="x-small"
                    style={{
                      fill: '#000',
                      display: 'inline-block',
                      // marginLeft: '10px',
                    }}
                  />
                  {`${row.territoryName}`}
                </StyledTableCell>
              )}
            </IconButton>
          </span>
        </StyledTableCell>
      </StyledDataTableRow>
      <TableRow>
        <StyledCollapsibleTableCell colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                {row.accounts.map((lot: any, index: any) => (
                  <>
                    <StyledDataTableRow key={index}>
                      <StyledTableCell style={{ width: '10%', borderBottom: 'aliceblue' }} />
                      {/* <span> */}
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => openCloseAccountCollapsible(lot.accountId)}
                        style={{ width: '10%' }}
                      >
                        {openAccount && openAccount.includes(lot.accountId) ? (
                          <StyledTableCell style={{ borderBottom: 'aliceblue' }}>
                            <Icon
                              assistiveText={{ label: 'Announcement' }}
                              category="utility"
                              name="chevrondown"
                              size="x-small"
                              style={{
                                fill: '#000',
                                display: 'inline-block',
                                // marginLeft: '10px',
                              }}
                            />
                            {`${lot.accountNumber} - ${lot.accountName}`}
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell style={{ borderBottom: 'aliceblue' }}>
                            <Icon
                              assistiveText={{ label: 'Announcement' }}
                              category="utility"
                              name="chevronright"
                              size="x-small"
                              style={{
                                fill: '#000',
                                display: 'inline-block',
                                // marginLeft: '10px',
                              }}
                            />
                            {`${lot.accountNumber} - ${lot.accountName}`}
                          </StyledTableCell>
                        )}
                      </IconButton>
                      {/* </span> */}
                    </StyledDataTableRow>
                    <TableRow>
                      <StyledCollapsibleTableCell colSpan={9}>
                        <Collapse
                          in={openAccount && openAccount.includes(lot.accountId)}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box>
                            <Table style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                              {lot.events.map((elem: any, index: any) => {
                                arr = 0;
                                arrItem = 0;
                                const iconPath = getEventIconInner(elem.eventType);
                                let icon: any;
                                let color: any;
                                if (elem.daysAtEvent < 60) {
                                  color = '#58b837';
                                }
                                if (elem.daysAtEvent >= 60) {
                                  color = '#1b5687';
                                }
                                if (elem.daysAtEvent >= 75) {
                                  color = '#ffb503';
                                }
                                if (elem.daysAtEvent >= 90) {
                                  icon = 'warning';
                                  color = '#b83c27';
                                }
                                let days = elem.daysAtEvent;
                                if (Number(elem.daysAtEvent) <= 0) {
                                  days = 0;
                                }

                                return (
                                  <>
                                    <StyledDataTableRow key={index}>
                                      <StyledTableCell
                                        style={{ width: '20%', borderBottom: 'aliceblue' }}
                                      />
                                      <StyledTableCell
                                        style={{ width: '20%', borderBottom: 'aliceblue' }}
                                      >
                                        <IconButton
                                          style={{ right: '15%' }}
                                          aria-label="expand row"
                                          size="small"
                                          onClick={() => openCloseEventsCollapsible(elem.eventId)}
                                        >
                                          {openEvents && openEvents.includes(elem.eventId) ? (
                                            <StyledTableLotCell
                                              style={{ borderBottom: 'aliceblue', padding: '0px' }}
                                            >
                                              {elem.lineItems.map((data: any, index: any) => {
                                                if (
                                                  (data.serialRecalled &&
                                                    data.serialRecalled.includes(
                                                      data?.lotNumber
                                                    )) ||
                                                  data.productOnHoldType === 'ITEM'
                                                ) {
                                                  arrItem = 1;
                                                }
                                                return null;
                                              })}
                                              {arrItem === 1 ? (
                                                <img
                                                  src={holdIconPath}
                                                  className="logo-image"
                                                  alt="company"
                                                  style={{
                                                    marginRight: '8px',
                                                  }}
                                                />
                                              ) : (
                                                ''
                                              )}
                                              <Icon
                                                assistiveText={{ label: 'Announcement' }}
                                                category="utility"
                                                name="chevrondown"
                                                size="x-small"
                                                style={{
                                                  fill: '#000',
                                                  display: 'inline-block',
                                                  // marginLeft: '10px',
                                                }}
                                              />

                                              <img
                                                src={iconPath}
                                                className="logo-image"
                                                alt="company"
                                                style={{ marginLeft: '8px', marginRight: '8px' }}
                                              />
                                              <span onClick={e => handleEventClick(e, elem)}>
                                                {elem.eventNumber}
                                              </span>
                                            </StyledTableLotCell>
                                          ) : (
                                            <StyledTableLotCell
                                              style={{ borderBottom: 'aliceblue', padding: '0px' }}
                                            >
                                              {elem.lineItems.map((data: any, index: any) => {
                                                if (
                                                  (data.serialRecalled &&
                                                    data.serialRecalled.includes(
                                                      data?.lotNumber
                                                    )) ||
                                                  data.productOnHoldType === 'ITEM'
                                                ) {
                                                  arr = 1;
                                                }
                                                return null;
                                              })}
                                              {arr === 1 ? (
                                                <img
                                                  src={holdIconPath}
                                                  className="logo-image"
                                                  alt="company"
                                                  style={{
                                                    marginRight: '8px',
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  src={holdwhitePath}
                                                  className="logo-image"
                                                  style={{
                                                    marginRight: '8px',
                                                  }}
                                                  alt="company"
                                                />
                                              )}
                                              <Icon
                                                assistiveText={{ label: 'Announcement' }}
                                                category="utility"
                                                name="chevronright"
                                                size="x-small"
                                                style={{
                                                  fill: '#000',
                                                  display: 'inline-block',
                                                  // marginLeft: '10px',
                                                }}
                                              />
                                              <img
                                                src={iconPath}
                                                className="logo-image"
                                                alt="company"
                                                style={{ marginLeft: '8px', marginRight: '8px' }}
                                              />
                                              <span onClick={e => handleEventClick(e, elem)}>
                                                {elem.eventNumber}
                                              </span>
                                            </StyledTableLotCell>
                                          )}
                                        </IconButton>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{ width: '10%', borderBottom: 'unset' }}
                                      >
                                        {elem.eventName}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{
                                          textAlign: 'left',
                                          position: 'absolute',
                                          right: '50%',
                                          borderBottom: 'unset',
                                        }}
                                      >
                                        {elem.salesRepName}
                                      </StyledTableCell>
                                    </StyledDataTableRow>
                                    <TableRow>
                                      <StyledCollapsibleTableCell colSpan={9}>
                                        <Collapse
                                          in={openEvents && openEvents.includes(elem.eventId)}
                                          timeout="auto"
                                          unmountOnExit
                                        >
                                          <Box>
                                            <Table
                                              style={{
                                                borderTop: '1px solid rgba(224, 224, 224, 1)',
                                              }}
                                            >
                                              {elem.lineItems.map((data: any, index: any) => {
                                                let icon: any;
                                                let color: any;
                                                if (data.daysAtEvent < 60) {
                                                  color = '#58b837';
                                                }
                                                if (data.daysAtEvent >= 60) {
                                                  color = '#1b5687';
                                                }
                                                if (data.daysAtEvent >= 75) {
                                                  color = '#ffb503';
                                                }
                                                if (data.daysAtEvent >= 90) {
                                                  icon = 'warning';
                                                  color = '#b83c27';
                                                }
                                                let days = data.daysAtEvent;
                                                if (Number(data.daysAtEvent) <= 0) {
                                                  days = 0;
                                                }
                                                return (
                                                  <>
                                                    <StyledDataTableRow key={index}>
                                                      <StyledTableCell style={{ width: '50%' }} />
                                                      <StyledTableCell
                                                        style={{
                                                          width: '25%',
                                                          textAlign: 'left',
                                                          paddingLeft: '3%',
                                                        }}
                                                      >
                                                        {`${data.productNumber} ${data.productdescription}`}
                                                        {(data.serialRecalled &&
                                                          data.serialRecalled.includes(
                                                            data?.lotNumber
                                                          )) ||
                                                        data.productOnHoldType === 'ITEM' ? (
                                                          <img
                                                            src={holdIconPath}
                                                            className="logo-image"
                                                            alt="company"
                                                            style={{
                                                              float: 'right',
                                                            }}
                                                          />
                                                        ) : (
                                                          <img
                                                            src={holdwhitePath}
                                                            className="logo-image"
                                                            style={{
                                                              float: 'right',
                                                            }}
                                                            alt="company"
                                                          />
                                                        )}
                                                      </StyledTableCell>

                                                      <StyledTableCell
                                                        style={{
                                                          width: '15%',
                                                          textAlign: 'center',
                                                        }}
                                                      >
                                                        {data.lotNumber}
                                                        {(data.serialRecalled &&
                                                          data.serialRecalled.includes(
                                                            data?.lotNumber
                                                          )) ||
                                                        (data?.lotNumber &&
                                                          data.productOnHoldType === 'ITEM') ? (
                                                          // eslint-disable-next-line react/jsx-indent
                                                          <>
                                                            <img
                                                              src={holdIconPath}
                                                              className="logo-image"
                                                              alt="company"
                                                              style={{
                                                                marginLeft: '8px',
                                                              }}
                                                            />
                                                          </>
                                                        ) : (
                                                          <img
                                                            src={holdwhitePath}
                                                            className="logo-image"
                                                            style={{
                                                              marginRight: '8px',
                                                            }}
                                                            alt="company"
                                                          />
                                                        )}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        style={{
                                                          // width: '10%',
                                                          textAlign: 'center',
                                                        }}
                                                      >
                                                        {data.quantity}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        style={{
                                                          width: '10%',
                                                          textAlign: 'center',
                                                          // padding: '0px',
                                                        }}
                                                      >
                                                        {icon && (
                                                          <Icon
                                                            // assistiveText={{ label: 'Announcement' }}
                                                            category="utility"
                                                            name={icon}
                                                            size="x-small"
                                                            style={{
                                                              fill: '#b83c27',
                                                              marginRight: '5px',
                                                            }}
                                                          />
                                                        )}
                                                        <span
                                                          style={{
                                                            color: 'white',
                                                            backgroundColor: color,
                                                            paddingRight: '10px',
                                                            paddingLeft: '10px',
                                                            paddingTop: '4px',
                                                            paddingBottom: '4px',
                                                            borderRadius: '15% / 50%',
                                                            // border-radius: 5% / 50%;
                                                          }}
                                                        >
                                                          {days}
                                                        </span>
                                                      </StyledTableCell>
                                                    </StyledDataTableRow>
                                                  </>
                                                );
                                              })}
                                            </Table>
                                          </Box>
                                        </Collapse>
                                      </StyledCollapsibleTableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                            </Table>
                          </Box>
                        </Collapse>
                      </StyledCollapsibleTableCell>
                    </TableRow>
                  </>
                ))}
              </Table>
            </Box>
          </Collapse>
        </StyledCollapsibleTableCell>
      </TableRow>
    </>
  );
};

const InventoryListAccountView: React.FC<Props> = ({ userView, gridData, history }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <StyledTableRow>
          <StyledTableCell style={{ width: '10%' }}>Territory</StyledTableCell>
          <StyledTableCell style={{ width: '10%' }}>Account</StyledTableCell>
          <StyledTableCell style={{ width: '10%' }}>Event</StyledTableCell>
          <StyledTableCell style={{ width: '12%' }}>Event Name</StyledTableCell>
          <StyledTableCell style={{ width: '10%', textAlign: 'center' }}>Sales Rep</StyledTableCell>
          <StyledTableCell style={{ width: '25%', textAlign: 'left' }}>Part</StyledTableCell>
          <StyledTableCell style={{ width: '10%', textAlign: 'left' }}>Lot/Serial</StyledTableCell>
          <StyledTableCell style={{ width: '5%', textAlign: 'left' }}>Quantity</StyledTableCell>
          <StyledTableCell style={{ width: '8%' }}>Days At Event</StyledTableCell>
        </StyledTableRow>
        {userView === 'By Account' ? (
          <TableBody>
            {gridData &&
              gridData.map((row: any, index: any) => (
                <Row key={index} row={row} selectedView={userView} history={history} />
              ))}
          </TableBody>
        ) : (
          <TableBody>
            {
              <>
                <StyledTableCell />
                {/* <StyledTableCell style={{ fontWeight: 'bold' }}>
                  Select an Account to view Inventory
                </StyledTableCell> */}
              </>
            }
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default InventoryListAccountView;
