/*
 * Get Cancellation Reasons GraphQL specification
 * @module src/graphql/getCancellationReasons
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_CANCELLATION_REASONS = gql`
  query getCancellationReasons {
    getCancellationReasons {
      reasons
    }
  }
`;
