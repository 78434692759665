import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Button from '@salesforce/design-system-react/components/button';
import { BrowserRouter as Router, RouteComponentProps, withRouter } from 'react-router-dom';
import { getEventIconInner } from '../../../../util/utilityFunctions';

interface EventId {
  caseExternalId: string;
  orderNumber: string;
  eventId: string;
  eventType: string;
  parentEventId: string;
  parentEventType: string;
  parentCaseExternalId: string;
}

// interface for props that the data table cell is getting default from data table.
export interface Props extends RouteComponentProps {
  children?: FC;
  item?: EventId;
  type: string;
}

const EventIdCell: FC<Props> = ({
  item = {
    caseExternalId: null,
    orderNumber: null,
    eventId: '',
    eventType: '',
    parentEventId: '',
    parentEventType: '',
    parentCaseExternalId: '',
  },
  // type = '',
  history,
}) => {
  const { eventType, parentEventId, parentEventType, parentCaseExternalId } = item;
  const goToReturnDetails = (): void => {
    history.push('/surgicalDetails', {
      eventType,
      externalId: parentCaseExternalId,
      id: parentEventId,
    });
  };
  const iconPath = getEventIconInner(parentEventType);

  return (
    <Router>
      <DataTableCell title={parentEventId || ''}>
        {parentEventId ? (
          <>
            <img src={iconPath} className="logo-image" alt="company" />
            <Button
              style={{ margin: '5px 5px 5px 5px' }}
              label={parentEventId || ''}
              variant="base"
              onClick={goToReturnDetails}
            />
          </>
        ) : (
          '--'
        )}
      </DataTableCell>
    </Router>
  );
};

export default withRouter(EventIdCell);
