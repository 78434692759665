/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable-next-line no-underscore-dangle */
/**
 * React component for Single Select Picker.
 * @module src/components/shared/AutocompleteSingleSelectPicker
 */
import React, { FC, useCallback, useState, useEffect } from 'react';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Icon from '@salesforce/design-system-react/components/icon';
import escapeRegExp from 'lodash.escaperegexp';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { GET_PRODUCT_LIST } from '../../../graphql/getProductList';
import { GET_SUB_INVENTORY } from '../../../graphql/getSubInventories';
import debounced from '../../../util/debounced';
import { getUserInfo } from '../../../store/ducks/userInfo';

interface Selection {
  productDescription: any;
  productdescription: any;
  productNumber: any;
  isPrimaryAddress: any;
  address1: any;
  address2: any;
  state: any;
  postalcode: any;
  __typename: string;
  number: any;
  name: string;
  id: string;
  label: string;
  value: string;
  branchId?: string;
  territory?: string;
}

interface SelectedData {
  selection: any;
}

interface ValueInterface {
  value: string;
}

type SelectionType = any;

interface SurgeonWorkflowPermission {
  workflowPermission: {
    id: string;
  } | null;
}

interface Props {
  items: SelectionType;
  selectedItem: SelectionType;
  setSelectedItem: (items: SelectionType | null) => void;
  label: string;
  placeholder?: string;
  addItemLabel?: string;
  withAddMenuButton: boolean;
  fetchItems: (arg0: string) => any;
  filter: boolean;
  disabled: boolean;
  key?: string;
  required?: boolean;
  name: string;
  autoSelectValue?: boolean;
  place?: string;
  hasInputSpinnerCheck?: any;
  multiple?: boolean;
  variant?: string;
}

const AutocompleteSingleSelectPicker: FC<Props> = props => {
  const [inputValue, setInputValue] = useState('');

  const [isShipToChanged, setIsShipToChanged] = useState(false);
  const [productListItems, { data: productItems, loading }] = useLazyQuery(GET_PRODUCT_LIST);
  const userInfo = useSelector(getUserInfo);
  const [productList, setProductList] = useState<any>([]);
  const [partsOptions, setPartsOptions] = useState<any>([]);

  const {
    items,
    setSelectedItem,
    selectedItem,
    label,
    placeholder,
    addItemLabel = '',
    withAddMenuButton = false,
    fetchItems,
    filter = false,
    disabled,
    required,
    autoSelectValue = true,
    name,
    place,
    hasInputSpinnerCheck,
    multiple,
    variant,
  } = props;

  const selectedItemLength = selectedItem ? selectedItem.length : 0;
  const itemLength = items ? items.length : 0;
  const [
    getSubInventoryWithLotDetails,
    { data: subInventoryWithLotDetails, loading: loadingSubInventory },
  ] = useLazyQuery(GET_SUB_INVENTORY);

  useEffect(() => {
    if (itemLength === 1 && selectedItemLength === 0 && inputValue === '' && autoSelectValue) {
      if (!withAddMenuButton) {
        setSelectedItem(items);
      }
    }
    if (
      (name === 'shipTo' || name === 'billTo') &&
      items?.length &&
      !selectedItem.length &&
      !isShipToChanged
    ) {
      const record = (items || []).find((rec: any) => rec.isPrimaryAddress);
      setIsShipToChanged(true);

      if (record) {
        setSelectedItem([record as any]);
      } else {
        setSelectedItem([]);
      }
    }
    // else if (
    //   !autoSelectValue &&
    //   itemLength === 1 &&
    //   selectedItemLength === 0 &&
    //   inputValue === ''
    // ) {
    //   setSelectedItem([]);
    // }
  }, [
    autoSelectValue,
    inputValue,
    itemLength,
    items,
    selectedItemLength,
    setSelectedItem,
    withAddMenuButton,
  ]);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const optionsData = subInventoryWithLotDetails?.getSubInventoryList?.map((elem: any) => {
      if (elem.__typename === 'SubInventoryList') {
        return {
          ...elem,
          label: `${elem.productNumber ? elem.productNumber : ''} ${elem.productDescription}`,
        };
      }
    });
    setPartsOptions(optionsData);
  }, [subInventoryWithLotDetails, getSubInventoryWithLotDetails]);

  const optionsWithLabelDefault =
    items &&
    items.map((elem: any) => {
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === 'ProcedureDetail') {
        return { ...elem, label: elem.name };
      }
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === 'ProcedureType') {
        return { ...elem, label: elem.value };
      }
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === 'ShipToAddress') {
        return {
          ...elem,
          label: `${elem.isPrimaryAddress ? '*' : ''} ${elem.name || ''} - ${elem.address1 ||
            ''} ${elem.address2 || ''}, ${elem.city || ''}, ${elem.state ||
            ''}, ${elem.postalcode || ''}`,
        };
      }
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === 'ProductList') {
        return {
          ...elem,
          label: `${elem.productNumber ? elem.productNumber : ''} ${elem.productdescription}`,
        };
      }
      if (elem.__typename === 'SubInventoryList') {
        return {
          ...elem,
          label: `${elem.productNumber ? elem.productNumber : ''} ${elem.productDescription}`,
        };
      }
      return { ...elem, label: `${elem.number ? elem.number : ''} ${elem.value}` };
    });

  // USeEffect to check only selected item length and update the formik form with default value
  useEffect(() => {
    if (selectedItemLength === 1) {
      setSelectedItem([selectedItem[0]]);
    }
  }, [selectedItemLength]);

  useEffect(() => {
    if (productItems && productItems.getProductList && productItems.getProductList.length) {
      setProductList(productItems.getProductList);
    } else {
      setProductList([]);
    }
  }, [productItems]);

  const showDefaultList = (): void => {
    getSubInventoryWithLotDetails({
      variables: {
        salesRepId: userInfo?.id || '',
        filters: {},
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getDubInventroyDetail = (searchedItem: string) => {
    if (searchedItem.length > 2) {
      getSubInventoryWithLotDetails({
        variables: {
          salesRepId: userInfo?.id || '',
          filters: {
            searchText: searchedItem,
          },
        },
      });
    } else {
      getSubInventoryWithLotDetails({
        variables: {
          salesRepId: userInfo?.id || '',
          filters: {
            searchText: '',
          },
        },
      });
    }
  };

  const setEventPartQuantity = (searchedItem: string): void => {
    getDubInventroyDetail(searchedItem);
  };

  const debouncedFetchSetItems = useCallback(debounced(500, setEventPartQuantity), [
    debounced,
    setEventPartQuantity,
  ]);
  useEffect(() => {
    let delayDebounceFn: NodeJS.Timeout;

    // eslint-disable-next-line prefer-const
    delayDebounceFn = setTimeout(() => {
      if (inputValue && inputValue.length >= 3 && filter) {
        fetchItems(inputValue);
        debouncedFetchSetItems(inputValue);
      } else if (inputValue.length === 0 && filter) {
        fetchItems(inputValue);
        debouncedFetchSetItems(inputValue);
      }
    }, 2000);

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);
  const handleChange = useCallback(
    (_event, selectedValue: ValueInterface) => {
      const { value } = selectedValue;
      _event.persist();
      setInputValue(value);
    },
    [fetchItems, filter, setInputValue]
  );

  const handleRemoveSelection = useCallback(
    (_event, data: SelectedData) => {
      if (!disabled) {
        const { selection } = data;
        setInputValue('');
        setSelectedItem(selection);
        showDefaultList();
      }
    },
    [disabled, setSelectedItem]
  );

  const handleSelectItem = useCallback(
    (_event, data: SelectedData) => {
      const { selection } = data;
      setInputValue('');
      setSelectedItem(selection);
    },
    [setInputValue, setSelectedItem]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const comboboxFilterAndLimit = (parameters: any): SelectionType => {
    const { searchedValue, options, selection } = parameters;
    const inputValueRegExp = new RegExp(escapeRegExp(searchedValue.replace(/-/g, '')), 'ig');
    return options.filter((option: Selection) => {
      const searchTermFound = option.label
        ? option.label.replace(/-/g, '').match(inputValueRegExp)
        : false;
      const isSeparator = false;
      const notAlreadySelected = !selection.some((sel: Selection) => sel.id === option.id);
      return (!searchedValue || isSeparator || searchTermFound) && notAlreadySelected;
    });
  };

  const optionsWithLabelFormatted = (arr: []): any[] =>
    arr &&
    arr.map((elem: any) => {
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === 'ProductList') {
        return {
          ...elem,
          label: `${elem.productNumber ? elem.productNumber : ''} ${elem.productdescription}`,
        };
      }
      if (elem.__typename === 'SubInventoryList') {
        return {
          ...elem,
          label: `${elem.productNumber ? elem.productNumber : ''} ${elem.productDescription}`,
        };
      }
      return { ...elem, label: `${elem.number ? elem.number : ''} ${elem.value}` };
    });

  const optionsWithLabel = optionsWithLabelFormatted(productItems?.getProductList);
  const optionalText = required ? '' : '';
  return (
    <div className="slds-form-element slds-m-bottom_small" key={label}>
      {/* {JSON.stringify(selectedItem)}
      123 */}
      <Combobox
        id={label}
        optionsAddItem={
          withAddMenuButton
            ? [
                {
                  id: 'options-add-id-1',
                  icon: (
                    <Icon
                      assistiveText={{ label: 'Add' }}
                      category="utility"
                      size="x-small"
                      name="add"
                    />
                  ),
                  label: (): [JSX.Element] => [<span key={label}>{addItemLabel}</span>],
                },
              ]
            : []
        }
        events={{
          onChange: handleChange,
          onRequestRemoveSelectedOption: handleRemoveSelection,
          onSelect: handleSelectItem,
        }}
        labels={{
          label: `${label + optionalText}`,
          placeholder,
          noOptionsFound: 'No data found',
        }}
        singleInputDisabled={disabled}
        options={comboboxFilterAndLimit({
          searchedValue: inputValue,
          options: optionsWithLabel || optionsWithLabelDefault || partsOptions || [],
          selection: selectedItem,
        })}
        multiple={multiple || false}
        menuItemVisibleLength={5}
        selection={selectedItem}
        value={inputValue}
        menuPosition="relative"
        variant={variant || 'inline-listbox'}
        required={required}
        hasInputSpinner={loading || hasInputSpinnerCheck || loadingSubInventory}
      />
    </div>
  );
};

/** React component for Autocomplete Single Select Picker. */
export default AutocompleteSingleSelectPicker;
