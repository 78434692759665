/**
 * Graphql mutation to create Inventory Request
 * @module src/graphql/createInventoryRequest
 */
import gql from 'graphql-tag';

/** creating Inventory Request */

interface Comment {
  comment: string;
  postTime: string;
  commentType: string;
}
const CREATE_INVENTORY_REQUEST = gql`
  mutation createInventoryRequest(
    $division: String
    $branchId: String!
    $accountId: String!
    $eventType: String
    $procedureTypeIds: String
    $procedureDetailNames: String
    $fromSubInventoryCode: String
    $salesRepId: String!
    $surgeonId: String
    $coveringRepIds: [String]
    $coveringRepNames: [String]
    $eventDateTime: String
    $needByDate: String
    $serviceLevel: String
    $shipToId: String
    $status: String
    $liftGateNeeded: Boolean
    $dockAvailable: Boolean
    $shortTruckOnly: Boolean
    $deliveryContractName: String
    $deliveryContractPhone: String
    $deliveryContractEmail: String
    $deliveryContractInstructions: String
    $system: String
    $version: String
    $isShippingDetails: Boolean
    $comments: [Comment]
    $lineItems: [InventoryLineItem]
    $attachments: [Attachment]
  ) {
    createInventoryRequest(
      system: $system
      version: $version
      division: $division
      branchId: $branchId
      accountId: $accountId
      surgeonId: $surgeonId
      eventType: $eventType
      procedureTypeIds: $procedureTypeIds
      procedureDetailNames: $procedureDetailNames
      fromSubInventoryCode: $fromSubInventoryCode
      salesRepId: $salesRepId
      coveringRepIds: $coveringRepIds
      coveringRepNames: $coveringRepNames
      eventDateTime: $eventDateTime
      needByDate: $needByDate
      serviceLevel: $serviceLevel
      shipToId: $shipToId
      status: $status
      liftGateNeeded: $liftGateNeeded
      dockAvailable: $dockAvailable
      shortTruckOnly: $shortTruckOnly
      deliveryContractName: $deliveryContractName
      deliveryContractPhone: $deliveryContractPhone
      deliveryContractEmail: $deliveryContractEmail
      deliveryContractInstructions: $deliveryContractInstructions
      isShippingDetails: $isShippingDetails
      comments: $comments
      lineItems: $lineItems
      attachments: $attachments
    ) {
      message
      externalId
      sfid
    }
  }
`;

export default CREATE_INVENTORY_REQUEST;
