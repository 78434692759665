/**
 * Graphql mutation to create Notification settings
 * @module src/graphql/createUpdateUserNotificationConfig
 */
import gql from 'graphql-tag';

const CREATE_UPDATE_ADMIN_FORM_CONFIG = gql`
  mutation updateAdminFormConfigurations(
    $isBUFormRequired: Boolean
    $screen: String
    $configurations: [UpdateConfigurations]
    $businessrules: [UpdateBussinessRules]
    $slMappingsToUpdate: [ServiceLevelMapping]
  ) {
    updateAdminFormConfigurations(
      isBUFormRequired: $isBUFormRequired
      screen: $screen
      configurations: $configurations
      businessrules: $businessrules
      slMappingsToUpdate: $slMappingsToUpdate
    ) {
      message
    }
  }
`;

export default CREATE_UPDATE_ADMIN_FORM_CONFIG;
