/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/aria-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Module for Surgical Detail Tabs
 * @module src/SurgicalDetail/SurgicalDetailTabs
 */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useEffect, useState, useCallback } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import {
  GET_WORKFLOW_PERMISSION,
  GET_WORKFLOW_PERMISSIONS,
} from '../../../graphql/workflowPermissions';
import '../SurgicalDetailContent/index.scss';
import './index.scss';
import UsageSheetForm from '../../Usage/UsageSheetForm';
import PartList from './PartsList';
import InventoryRequest from '../../Inventory/InventoryRequest';
import InventoryRequestList from './InventoryRquestList';
import InventoryReturn from '../../Inventory/InventoryReturns/InventoryReturn';
import OrdersList from './OrdersList';
import ReturnsList from './ReturnsList';
import TransferList from './TransferList';
import { Props } from '../SurgicalDetailContent';
import CartComponent from '../../Inventory/InventoryRequestDetail/InventoryRequestTable/cartComponent';
import { GET_FORM_CONFIGURATIONS } from '../../../graphql/getFormConfigurations';
import { GET_LINE_ITEMS_FOR_EVENTS } from '../../../graphql/getLineItemsForEvents';
import {
  EVENT_FORM_LIST,
  USAGE_TYPE,
  labelMapping,
  PERSONAS,
  MOCK_OR_EVENT_TYPE,
  EVENT_FORM_LIST_MOCK_OR,
} from '../../../util/constants';
import NewRepTransfer from '../../Inventory/Transfer/NewRepTransfer';
import { getUserInfo } from '../../../store/ducks/userInfo';
import CancelEventModal from '../../CreateEditSurgeryEvent/CancelEventCreationModal';

interface Comment {
  id: string;
  comment: string;
  createdDate: string;
  commentType: string;
  commentExternalId: string;
  postTime: string;
  userId: string;
  sfid: string;
}

const SurgicalDetailTabs: FC<Props> = ({
  eventDetail,
  handleTabSelect,
  eventInventoryData = null,
  showEventInventoryTab = null,
  tabSelectedIndex,
  showEventUsageTab,
  refetchEventDetails,
}) => {
  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const [selectedItem, setSelectedItem] = useState('');
  const [canceModalVisible, setCanceModalVisible] = useState(false);
  const [getFormConfigurations, { data }] = useLazyQuery(GET_FORM_CONFIGURATIONS, {
    fetchPolicy: 'network-only',
  });

  useQuery(GET_WORKFLOW_PERMISSIONS);
  const { data: showSurgeonData } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      action: 'show_surgeon_fields',
    },
  });
  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);

  const [getLineItemsForEvents, { data: eventPartsData }] = useLazyQuery(
    GET_LINE_ITEMS_FOR_EVENTS,
    {
      fetchPolicy: 'no-cache',
      variables: { eventExternalId: eventDetail?.caseExternalId },
    }
  );

  const [caseSfid, setCaseSfid] = useState('');
  const [caseExternalId, setCaseExternalId] = useState('');
  const [inventoryItems, setInventoryItems] = useState('');
  const [partsItems, setPartsList] = useState<any>([]);
  const [orderItems, setOrderItems] = useState('');
  const [returnItems, setReturnItems] = useState('');
  const [transferItems, setTransferItems] = useState('');
  const [showInventoryModal, setShowInventoryModal] = useState(false);
  const [showUsageModal, setShowUsageModal] = useState(false);
  const [openNewReturnModal, setShowReturnModal] = useState(false);
  const [openNewTransferModal, setShowTransferModal] = useState(false);
  const [totalActualQty, setTotalActual] = useState(0);
  const [totalDispositionQty, setTotalDisposiitoned] = useState(0);
  const [eventStatusRec, setEventStatus] = useState('');
  const [isShowOrder, setShowOrder] = useState(true);
  // const [isIR, setIR] = useState(false);
  const [eventForms, setEventForms] = useState<any>(EVENT_FORM_LIST);
  const userInfo = useSelector(getUserInfo);
  const requiredForms = userInfo?.assignedScreens;

  useEffect(() => {
    if (!selectedTab) {
      const selectedCommentTab = showSurgeonData ? 'Customer' : 'Internal';
      setSelectedTab(selectedCommentTab);
    }
  }, [selectedTab, showSurgeonData]);

  useEffect(() => {
    const externalId = eventDetail?.caseExternalId;
    const sfid = eventDetail?.caseId;
    const eventInventories = eventDetail?.eventInventory;
    const eventOrders = eventDetail?.eventOrder;
    const eventReturns = eventDetail?.eventReturn;
    const eventTransfers = eventDetail?.eventTransfer;
    const eventStatus = eventDetail?.eventStatus;
    setCaseSfid(sfid);
    setCaseExternalId(externalId);
    setInventoryItems(eventInventories);
    setOrderItems(eventOrders);
    setReturnItems(eventReturns);
    setEventStatus(eventStatus);
    setTransferItems(eventTransfers);
  }, [caseExternalId, caseSfid, eventDetail, getLineItemsForEvents]);

  useEffect(() => {
    getLineItemsForEvents({
      variables: { eventExternalId: eventDetail?.caseExternalId },
    });
  }, [eventDetail, getLineItemsForEvents]);

  useEffect(() => {
    if (eventPartsData && eventPartsData?.getLineItemsForEvents) {
      const list = eventPartsData?.getLineItemsForEvents;
      setPartsList(list);
    }
  }, [eventInventoryData, eventPartsData]);
  let BUconfiguredForm: { label: string; value: string }[] = [];
  const getTabsClassName = (): string => {
    if (!showEventInventoryTab) {
      return 'event_inventory';
    }
    if (!showEventUsageTab) {
      return 'event_usage';
    }
    return '';
  };

  const handleEventInventoryModalToggle = useCallback((): void => {
    setShowInventoryModal(!showInventoryModal);
  }, [showInventoryModal]);

  const handleUsageModalToggle = useCallback((): void => {
    setShowUsageModal(!showUsageModal);
  }, [showUsageModal]);

  const handleReturnModalToggle = useCallback((): void => {
    setShowReturnModal(!openNewReturnModal);
  }, [openNewReturnModal]);

  const handleTransferModalToggle = useCallback((): void => {
    setShowTransferModal(!openNewTransferModal);
  }, [openNewTransferModal]);

  const handleCancelButton = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };
  const handleCancelModal = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };
  const handleCancelModalConfirmPress = (): void => {
    setCanceModalVisible(!canceModalVisible);
    if (selectedItem === 'Inventory Request') {
      handleEventInventoryModalToggle();
    }
    if (selectedItem === 'Bill Only') {
      handleUsageModalToggle();
    }
    if (selectedItem === 'Standard Return' || selectedItem === 'Pickup Return') {
      handleReturnModalToggle();
    }
    if (selectedItem === 'Customer Transfer' || selectedItem === 'Event Transfer') {
      handleTransferModalToggle();
    }
  };

  const handleFormSelection = (selection: any): void => {
    setSelectedItem(selection);
    if (selection === 'Inventory Request') {
      handleEventInventoryModalToggle();
    } else if (selection === 'Bill Only') {
      handleUsageModalToggle();
    } else if (selection === 'Standard Return' || selection === 'Pickup Return') {
      handleReturnModalToggle();
    } else if (selection === 'Customer Transfer' || selection === 'Event Transfer') {
      handleTransferModalToggle();
    }
  };

  useEffect(() => {
    if (partsItems.length > 0) {
      const totalActual = partsItems.reduce((acc: any, curr: any) => {
        return acc + Number(curr.totalActaulQty);
      }, 0);
      const total = partsItems.reduce((acc: any, curr: any) => {
        return acc + Number(curr.totalDispositionQty);
      }, 0);
      setTotalActual(totalActual);
      setTotalDisposiitoned(total);
    }
  }, [partsItems]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleRefetchItems = useCallback((): void => {}, []);

  useEffect(() => {
    const type =
      eventDetail.eventType === 'Demo' ? eventDetail?.eventSubType : eventDetail?.eventType;
    if (userInfo && userInfo?.businessRules && userInfo?.businessRules.length > 0) {
      if (
        eventDetail.eventType === 'Trial' ||
        eventDetail.eventSubType === 'Standard Demo' ||
        eventDetail.eventSubType === 'Show-n-Go'
      ) {
        const filterRule: any = userInfo?.businessRules?.filter((item: any) => {
          if (item.screenName === `${type} - Detail` && item.rules === 'Bill Only Dispositioning') {
            return item.isRequired;
          }
        });
        const isOrder = filterRule && filterRule.length > 0 && filterRule[0].isRequired;
        setShowOrder(isOrder);
      }
    }
  }, [eventDetail, userInfo]);

  // useEffect(() => {
  //   if (userInfo && userInfo?.businessRules && userInfo?.businessRules.length > 0) {
  //     if (eventDetail.eventType === 'Trial') {
  //       const filterRule: any = userInfo?.businessRules?.filter((item: any) => {
  //         if (
  //           item.screenName === `Global` &&
  //           item.rules === 'Trial Hold' &&
  //           (userInfo?.personas === 'Sales Rep' || userInfo?.personas === 'Agency Sales Rep') &&
  //           userInfo?.isinventoryhold
  //         ) {
  //           return item.isRequired;
  //         }
  //       });
  //       const isIr = filterRule && filterRule.length > 0 && filterRule[0].isRequired;
  //       setIR(isIr);
  //     }
  //   }
  // }, [eventDetail, userInfo]);

  useEffect(() => {
    const labels =
      // eslint-disable-next-line consistent-return
      requiredForms?.map((rec: any) => {
        if (Object.keys(labelMapping).includes(rec.screen) && !rec.isRequired) {
          return labelMapping[rec.screen];
        }
      }) || [];

    if (userInfo && userInfo?.personas === PERSONAS.KENCO) {
      EVENT_FORM_LIST.splice(
        EVENT_FORM_LIST.findIndex((item: any) => {
          return item.label === 'Event Transfer';
        }),
        1
      );
      EVENT_FORM_LIST.splice(
        EVENT_FORM_LIST.findIndex((item: any) => {
          return item.label === 'Bill Only';
        }),
        1
      );
      EVENT_FORM_LIST.splice(
        EVENT_FORM_LIST.findIndex((item: any) => {
          return item.label === 'Customer Transfer';
        }),
        1
      );
    }

    if (userInfo && userInfo?.personas === PERSONAS.CSR) {
      EVENT_FORM_LIST.splice(
        EVENT_FORM_LIST.findIndex((item: any) => {
          return item.label === 'Event Transfer';
        }),
        1
      );

      EVENT_FORM_LIST.splice(
        EVENT_FORM_LIST.findIndex((item: any) => {
          return item.label === 'Customer Transfer';
        }),
        1
      );

      EVENT_FORM_LIST.splice(
        EVENT_FORM_LIST.findIndex((item: any) => {
          return item.label === 'Standard Return';
        }),
        1
      );

      EVENT_FORM_LIST.splice(
        EVENT_FORM_LIST.findIndex((item: any) => {
          return item.label === 'Pickup Return';
        }),
        1
      );
    }

    if (userInfo && userInfo?.personas === PERSONAS.BRANCH_OPS) {
      EVENT_FORM_LIST.splice(
        EVENT_FORM_LIST.findIndex((item: any) => {
          return item.label === 'Bill Only';
        }),
        1
      );
    }

    if (labels?.length > 0) {
      if (eventDetail?.eventSubType === MOCK_OR_EVENT_TYPE) {
        BUconfiguredForm = EVENT_FORM_LIST_MOCK_OR.filter((items: any) => {
          return !labels.includes(items.label);
        });
      } else {
        BUconfiguredForm = EVENT_FORM_LIST.filter((items: any) => {
          return !labels.includes(items.label);
        });
      }
      setEventForms(BUconfiguredForm);
    }
  }, [requiredForms]);

  useEffect(() => {
    if (!isShowOrder) {
      eventForms.splice(
        eventForms.findIndex((item: any) => {
          return item.label === 'Bill Only';
        }),
        1
      );
    }
  }, [isShowOrder]);

  // useEffect(() => {
  //   if (isIR) {
  //     eventForms.splice(
  //       eventForms.findIndex((item: any) => {
  //         return item.label === 'Inventory Request';
  //       }),
  //       1
  //     );
  //   }
  // }, [isIR]);

  return (
    <IconSettings iconPath="/icons">
      <div className={getTabsClassName()}>
        <div className="side-section-over-tab">
          <CartComponent count={totalActualQty} color="grey" textColor="white" cartTitle="Actual" />
          <CartComponent
            count={totalDispositionQty}
            color="grey"
            textColor="white"
            cartTitle="Dispositioned"
          />
          <ButtonGroup variant="list" id="button-group-page-header-actions">
            <Dropdown
              iconCategory="utility"
              iconSize="small"
              align="right"
              iconName="add"
              iconPosition="left"
              disabled={eventStatusRec === 'Cancelled' || eventStatusRec === 'Completed'}
              label="New"
              onSelect={(selectedOption: any): void => {
                handleFormSelection(selectedOption.value);
              }}
              options={eventForms}
            />
          </ButtonGroup>
        </div>
        <Tabs
          variant="scoped"
          id="event-tabs-scoped"
          className="slds-p-around_small"
          onSelect={handleTabSelect}
          defaultSelectedIndex={tabSelectedIndex}
          selectedIndex={tabSelectedIndex}
        >
          <TabsPanel label="Parts List">
            <PartList items={partsItems || ''} />
          </TabsPanel>
          <TabsPanel label="Inventory Requests">
            <InventoryRequestList items={inventoryItems} eventid={caseExternalId} />
          </TabsPanel>
          {eventDetail?.eventSubType !== MOCK_OR_EVENT_TYPE && isShowOrder && (
            <TabsPanel label="Orders">
              <OrdersList items={orderItems} />
            </TabsPanel>
          )}
          <TabsPanel label="Returns">
            <ReturnsList items={returnItems} />
          </TabsPanel>
          <TabsPanel label="Transfers">
            <TransferList items={transferItems} />
          </TabsPanel>
        </Tabs>
        {selectedItem === 'Inventory Request' && (
          <InventoryRequest
            flowType
            isOpen={showInventoryModal}
            toggleOpen={handleEventInventoryModalToggle}
            handleCancelButton={handleCancelButton}
            formData={data}
            eventType="Request Inventory"
            eventExternalId={caseExternalId}
            refetchEventDetails={refetchEventDetails}
            editDetails={eventDetail}
          />
        )}
        {selectedItem === 'Bill Only' && (
          <UsageSheetForm
            eventUsage
            isOpen={showUsageModal}
            toggleOpen={handleUsageModalToggle}
            handleCancelButton={handleCancelButton}
            formData={data}
            editDetails={eventDetail}
            orderType={USAGE_TYPE.BILL_ONLY}
            refetchDetails={refetchEventDetails}
          />
        )}
        {(selectedItem === 'Standard Return' || selectedItem === 'Pickup Return') && (
          <InventoryReturn
            eventUsage
            isOpen={openNewReturnModal}
            toggleOpen={handleReturnModalToggle}
            formData={data}
            eventType="Return Inventory"
            returnsType={selectedItem}
            flowType="events"
            editDetails={eventDetail}
            handleCancelButton={handleCancelButton}
          />
        )}
        {(selectedItem === 'Customer Transfer' || selectedItem === 'Event Transfer') && (
          <NewRepTransfer
            eventUsage
            isEdit={false}
            isOpen={openNewTransferModal}
            toggleOpen={handleTransferModalToggle}
            formData={data}
            transferType={
              selectedItem.toLowerCase().includes('customer') ? 'Customer Transfer' : selectedItem
            }
            handleCancelButton={handleCancelButton}
            handleRefetchItems={handleRefetchItems}
            editDetails={eventDetail}
          />
        )}
        {canceModalVisible && (
          <CancelEventModal
            isNew
            canceModalVisible={canceModalVisible}
            handleCancelModal={handleCancelModal}
            handleCancelModalConfirmPress={handleCancelModalConfirmPress}
          />
        )}
      </div>
    </IconSettings>
  );
};

/** Surgical Detail Tabs component **/
export default SurgicalDetailTabs;
