/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * React component for Text Area.
 * @module src/components/shared/TextArea
 */
import React, { FC, useCallback } from 'react';
import Textarea from '@salesforce/design-system-react/components/textarea';
import Tooltip from '@salesforce/design-system-react/components/tooltip';

interface Props {
  label: string;
  maxLength?: string;
  showTooltip: boolean;
  tooltipText: string;
  handleChangeText: (result: string) => void;
  key?: string;
  required?: boolean;
  value: any;
  disabled: boolean;
}

const TextArea: FC<Props> = props => {
  const {
    label,
    maxLength,
    showTooltip = false,
    tooltipText = '',
    handleChangeText,
    required,
    value,
    disabled,
  } = props;

  const handleChange = useCallback(
    event => {
      event.persist();
      const textValue = event.target.value;
      const blankSpaceOnly = textValue ? !textValue.replace(/\s/g, '').length : true;
      if (!blankSpaceOnly) {
        handleChangeText(textValue);
      } else {
        handleChangeText('');
      }
    },
    [handleChangeText]
  );

  const optionalText = required ? '*' : '';

  return (
    <div className="slds-form-element slds-m-bottom_small" key={label}>
      <div className="slds-form-element__label">
        <div style={{ color: '#c23934', display: 'flex' }}>
          {optionalText}
          <span style={{ color: '#3e3e3c' }}>{label}</span>
          {showTooltip && (
            <Tooltip
              id="base"
              align="top left"
              content={tooltipText}
              variant="learnMore"
              dialogClassName="dialog-classname"
              disabled={disabled}
            />
          )}
        </div>
      </div>
      <Textarea
        id={label}
        maxLength={maxLength}
        onChange={handleChange}
        value={value[0]?.label || value || ''}
        disabled={disabled}
      />
      <div style={{ color: '#3e3e3c', fontSize: '0.75rem', textAlign: 'end' }}>
        Count:
        {`${value.length}/${maxLength}`}
      </div>
    </div>
  );
};

/** React component for Text Area. */
export default TextArea;
