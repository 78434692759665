import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_USERA_BY_BU = gql`
  query getAllUsersByBU($filters: SalesRepFilters) {
    getAllUsersByBU(filters: $filters) {
      id
      value
    }
  }
`;
