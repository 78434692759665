/**
 * Graphql mutation to create event
 * @module src/graphql/submitInventoryRequest
 */
import gql from 'graphql-tag';

/** creating event */

const SUBMIT_INVENTORY_RETURN = gql`
  mutation submitInventoryReturn(
    $orderId: String
    $orderExternalId: String!
    $accountId: String!
    $salesRepId: String!
    $fromSubInventoryCode: String!
    $salesOrganization: String!
    $shipDate: Date
    $trackingId: String
    $returnReason: String
    $returnType: String
    $lineItems: [UpdateReturnLineItems]
    $returnStatus: String
    $system: String
    $version: String
    $isShippingLabelRequired: Boolean
  ) {
    submitInventoryReturn(
      orderId: $orderId
      orderExternalId: $orderExternalId
      accountId: $accountId
      salesRepId: $salesRepId
      fromSubInventoryCode: $fromSubInventoryCode
      salesOrganization: $salesOrganization
      shipDate: $shipDate
      trackingId: $trackingId
      returnReason: $returnReason
      system: $system
      version: $version
      returnType: $returnType
      returnStatus: $returnStatus
      isShippingLabelRequired: $isShippingLabelRequired
      lineItems: $lineItems
    ) {
      message
      externalId
    }
  }
`;

export default SUBMIT_INVENTORY_RETURN;
