/**
 * Graphql mutation to create filter preset
 * @module src/graphql/createFilterPreset
 */
import gql from 'graphql-tag';

/** create filter preset */
const CREATE_UPDATE_INVENTORY_FILTER_PRESET = gql`
  mutation createUpdateInventoryFilterPreset(
    $nameOfFilter: String
    $serviceLevel: [String]
    $threePL: [String]
    $account: [String]
    $salesRep: [String]
    $territory: TerritoryDefinition
    $status: [String]
    $assignedTo: [String]
    $cost: CostDefinition
    $submittedDate: SubmittedDateDefinition
    $needByDate: NeedByDateDefinition
    $productGroup: [String]
    $recordType: [String]
  ) {
    createUpdateInventoryFilterPreset(
      nameOfFilter: $nameOfFilter
      serviceLevel: $serviceLevel
      threePL: $threePL
      account: $account
      salesRep: $salesRep
      territory: $territory
      status: $status
      assignedTo: $assignedTo
      cost: $cost
      submittedDate: $submittedDate
      needByDate: $needByDate
      productGroup: $productGroup
      recordType: $recordType
    ) {
      nameOfFilter
      user
      serviceLevel
      threePL
      account
      recordType
      userDetails {
        sfId
        name
      }
      salesRep
      territory {
        id
        name
      }
      status
      assignedTo
      cost {
        value
      }
      submittedDate {
        from
        to
      }
      needByDate {
        from
        to
      }
      productGroup
    }
  }
`;

export default CREATE_UPDATE_INVENTORY_FILTER_PRESET;
