/**
 * SurgeryUsageOnly Form JSON.
 * @module src/util/formJsons/surgeryUsageOnly
 */
import { Form } from '../../components/Shared/FormEngine';

const formJson: Form = [
  {
    kind: 'field',
    name: 'topInfo',
    fieldLabel: 'All fields must be filled unless marked (Optional)', // TODO. Replace after localization
    fieldType: 'text',
    required: true,
    optionsAutoSelected: false,
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'franchise',
        fieldLabel: 'Franchise',
        fieldType: 'selectSingleDynamicPicker',
        required: true,
        optionsPlaceHolder: 'Franchise',
        optionsDynamic: 'getFranchises',
        optionsAutoSelected: true,
        permissionSets: ['show_surgeon_fields', 'show_csr_fields'],
      },
      {
        kind: 'field',
        name: 'country',
        fieldLabel: 'Country',
        fieldType: 'selectSingleDynamicPicker',
        required: true,
        optionsPlaceHolder: 'Country',
        optionsDynamic: 'getCountries',
        optionsDependentField: ['franchise'],
        optionsAutoSelected: true,
        permissionSets: ['show_surgeon_fields', 'show_csr_fields'],
      },
      {
        kind: 'field',
        name: 'account',
        fieldLabel: 'Account',
        fieldType: 'filterSingleDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: 'Account',
        optionsDynamic: 'getAccounts',
        optionsDependentField: ['franchise', 'country'],
        optionsAutoSelected: true,
        permissionSets: ['show_surgeon_fields', 'show_csr_fields'],
      },
      {
        kind: 'field',
        name: 'surgeon',
        fieldLabel: 'Surgeon',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: 'Surgeon',
        optionsDynamic: 'getSurgeons',
        optionsDependentField: ['franchise', 'country', 'account'],
        optionsAutoSelected: true,
        showAddButton: true,
        addButtonText: 'Add Surgeon',
        permissionSets: ['show_surgeon_fields', 'show_csr_fields'],
      },
    ],
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'procedureType',
        fieldLabel: 'Procedure Type',
        fieldType: 'selectMultiDynamicPicker',
        required: true,
        optionsPlaceHolder: 'Procedure Type',
        optionsDynamic: 'getProcedureType',
        optionsDependentField: ['franchise'],
        optionsAutoSelected: true,
        permissionSets: ['show_csr_fields'],
      },
      {
        kind: 'field',
        name: 'procedureDetails',
        fieldLabel: 'Procedure Details',
        fieldType: 'selectMultiDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: 'Procedure Details',
        optionsDynamic: 'getProcedureDetails',
        optionsDependentField: ['franchise'],
        optionsAutoSelected: true,
        permissionSets: ['show_surgeon_fields', 'show_csr_fields'],
      },
      {
        kind: 'field',
        name: 'navigation',
        fieldLabel: 'Navigation',
        fieldType: 'toogleSwitch',
        required: false,
        optionsAutoSelected: false,
        permissionSets: ['show_surgeon_fields', 'show_csr_fields'],
      },
      {
        kind: 'field',
        name: 'robotics',
        fieldLabel: 'Robotics',
        fieldType: 'toogleSwitch',
        required: false,
        optionsAutoSelected: false,
        permissionSets: ['show_surgeon_fields', 'show_csr_fields'],
      },
      {
        kind: 'field',
        name: 'revision',
        fieldLabel: 'Revision',
        fieldType: 'toogleSwitch',
        required: false,
        optionsAutoSelected: false,
        permissionSets: ['show_surgeon_fields', 'show_csr_fields'],
      },
    ],
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'salesRep',
        fieldLabel: 'Sales Rep',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: 'Sales Rep',
        optionsDynamic: 'getSalesReps',
        optionsDependentField: ['franchise', 'country', 'account'],
        optionsAutoSelected: true,
        permissionSets: ['show_surgeon_fields', 'show_csr_fields'],
      },
      {
        kind: 'field',
        name: 'coveringRep',
        fieldLabel: 'Covering Rep',
        fieldType: 'selectMultiDynamicPicker',
        required: false,
        optionsPlaceHolder: 'Covering Rep',
        optionsDynamic: 'getCoveringReps',
        optionsDependentField: ['franchise', 'country', 'account'],
        optionsAutoSelected: false,
        permissionSets: ['show_csr_fields'],
      },
      {
        kind: 'field',
        name: 'eventDate',
        fieldLabel: 'Event Date',
        fieldType: 'datePicker',
        required: true,
        optionsAutoSelected: false,
        permissionSets: ['show_surgeon_fields', 'show_csr_fields'],
      },
      {
        kind: 'field',
        name: 'eventTime',
        fieldLabel: 'Event Time',
        fieldType: 'timePicker',
        required: true,
        optionsAutoSelected: false,
        permissionSets: ['show_surgeon_fields', 'show_csr_fields'],
      },
    ],
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'comments',
        fieldLabel: 'Internal Comments',
        fieldType: 'textArea',
        required: false,
        optionsPlaceHolder:
          'Do not use to enter patient information, complaints, or set extension request.',
        showTooltip: true,
        optionsAutoSelected: false,
        characterLimit: 32000,
        permissionSets: ['show_csr_fields'],
      },
      {
        kind: 'field',
        name: 'comments',
        fieldLabel: 'Customer Comments',
        fieldType: 'textArea',
        required: false,
        optionsPlaceHolder:
          'Do not use to enter patient information, complaints, or set extension request.',
        showTooltip: true,
        optionsAutoSelected: false,
        characterLimit: 32000,
        permissionSets: ['show_surgeon_fields'],
      },
    ],
  },
];

/** Exporting surgeryUsageOnly form json */
export default formJson;
