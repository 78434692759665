/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from '@salesforce/design-system-react/components/icon';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import { TableSortLabel } from '@material-ui/core';
import moment from 'moment';
import CustomPagination from './CustomPagination';

interface Props {
  gridData?: any;
  handlePermissionOnHold?: (
    itemId: string,
    id: string,
    isOnHold: boolean,
    reason: string,
    reasonCodeForHold: string
  ) => void;
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  colSpacing: {
    width: '13%',
    textAlign: 'right',
  },
});

const StyledTableRow = withStyles(theme => ({
  root: {
    height: 35,
    backgroundColor: '#f4f4f4',
  },
}))(TableRow);

const StyledDataTableRow = withStyles(theme => ({
  root: {
    height: 35,
  },
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
  root: {
    padding: '0px 16px',
  },
}))(TableCell);

const StyledCollapsibleTableCell = withStyles(theme => ({
  root: {
    padding: '0px 0px',
    fontWeight: 'bold',
  },
}))(TableCell);

const StyledTableLotCell = withStyles(theme => ({
  root: {
    padding: '0px 16px',
    fontWeight: 'bold',
    color: '#006DCC',
  },
}))(TableCell);

const Row = (props: any) => {
  const { row, handlePermissionOnHold } = props;
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(false);
  const classes = useRowStyles();
  const history = useHistory();
  const onChangePermissionOnHold = (
    itemId: string,
    id: string,
    holdState: boolean,
    reason: string,
    reasonCodeForHold: string
  ): void => {
    setState(!holdState);
    handlePermissionOnHold(itemId, id, !holdState, reason, reasonCodeForHold);
  };
  const handleLink = (params: any) => {
    if (params) {
      history.push('/ReservedTransactions', {
        productId: params?.productId,
        lotNumber: params?.lotNumber,
        productDescription: params?.productDescription,
        productNumber: params?.productNumber,
      });
    }
  };
  const handleLinkClick = (params: any) => {
    handleLink(params);
  };

  const reasons = row.reasons || [];
  const uniqueReasonCodes = Array.from(
    new Set(reasons.map((reason: any) => reason.reasonCodeForHold))
  );
  const formattedReasonCodes = uniqueReasonCodes.join(', ');
  return (
    <>
      <StyledDataTableRow className={classes.root}>
        <StyledTableCell style={{ width: '20%' }}>
          <span>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {!open ? (
                <Icon
                  assistiveText={{ label: 'Announcement' }}
                  category="utility"
                  name="chevronright"
                  size="x-small"
                  style={{
                    fill: '#000',
                    display: 'inline-block',
                    marginLeft: '0px',
                  }}
                />
              ) : (
                <Icon
                  assistiveText={{ label: 'Announcement' }}
                  category="utility"
                  name="chevrondown"
                  size="x-small"
                  style={{
                    fill: '#000',
                    display: 'inline-block',
                    marginLeft: '0px',
                  }}
                />
              )}
            </IconButton>
          </span>
          {row.value}
        </StyledTableCell>
        {!open ? (
          <StyledTableCell style={{ width: '25%', textAlign: 'left', paddingLeft: '6%' }}>
            {formattedReasonCodes}
          </StyledTableCell>
        ) : (
          <StyledTableCell />
        )}

        <StyledTableCell />
        <StyledTableCell />
        <StyledTableCell />
        <StyledTableCell />
      </StyledDataTableRow>
      <TableRow>
        <StyledCollapsibleTableCell colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                {row.reasons.map((lot: any) => (
                  <StyledDataTableRow>
                    <StyledTableCell style={{ width: '15%' }} />
                    <StyledTableCell
                      style={{
                        width: '25%',
                        textAlign: 'left',
                        paddingLeft: '10%',
                      }}
                    >
                      {lot.reasonCodeForHold}
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '25%', textAlign: 'left', paddingLeft: '1%' }}>
                      {lot?.holdReason}
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '20%', textAlign: 'left', paddingLeft: '1%' }}>
                      {lot.dateInitiated ? moment(lot.dateInitiated).format('MM/DD/YYYY') : ''}
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '10%', textAlign: 'left', paddingLeft: '3%' }}>
                      <IconSettings iconPath="/assets/icons">
                        <Checkbox
                          id={lot.id}
                          labels={{
                            toggleEnabled: 'On Hold',
                            toggleDisabled: '',
                          }}
                          onChange={() => {
                            onChangePermissionOnHold(
                              row.id,
                              lot.permissionExternalId,
                              lot.isinventoryhold,
                              lot.holdReason,
                              lot.reasonCodeForHold
                            );
                          }}
                          variant="toggle"
                          disabled={lot.reasonCodeForHold === 'Cycle Count Hold'}
                          checked={lot.isinventoryhold}
                        />
                      </IconSettings>
                    </StyledTableCell>
                  </StyledDataTableRow>
                ))}
              </Table>
            </Box>
          </Collapse>
        </StyledCollapsibleTableCell>
      </TableRow>
    </>
  );
};

const Accordian: React.FC<Props> = ({ gridData, handlePermissionOnHold }) => {
  const [sortedData, setSortedData] = useState<any>(gridData);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' }>({
    key: '',
    direction: 'asc',
  });
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(50);

  function handleChangePage(event: any, newpage: number) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event: any) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }
  const handleSort = (event: React.MouseEvent<unknown>, key: string) => {
    event.preventDefault();
    let direction: 'asc' | 'desc' = 'asc';

    direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';

    const sorted = [...sortedData].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setSortedData(sorted);
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    setSortedData(gridData);
  }, [gridData]);

  const classes = useRowStyles();
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <StyledTableRow style={{ width: '15%', textAlign: 'left' }}>
            <StyledTableCell style={{ width: '20%', fontWeight: 'bold' }}>
              <TableSortLabel
                active={sortConfig.key === 'value'}
                direction={sortConfig.key === 'value' ? sortConfig.direction : 'asc'}
                onClick={event => handleSort(event, 'value')}
              />
              User
            </StyledTableCell>
            <StyledTableCell
              style={{ width: '25%', textAlign: 'left', paddingLeft: '8%', fontWeight: 'bold' }}
            >
              Type
            </StyledTableCell>
            <StyledTableCell
              style={{ width: '25%', textAlign: 'left', padding: '0%', fontWeight: 'bold' }}
            >
              Reason
            </StyledTableCell>
            <StyledTableCell
              className={classes.colSpacing}
              style={{ width: '20%', padding: '0%', textAlign: 'left', fontWeight: 'bold' }}
            >
              Date Initiated
            </StyledTableCell>
            <StyledTableCell
              className={classes.colSpacing}
              style={{ width: '10%', fontWeight: 'bold' }}
            >
              Off/On
            </StyledTableCell>
          </StyledTableRow>
          <TableBody>
            {sortedData &&
              sortedData
                .slice(pg * rpg, pg * rpg + rpg)
                .map((row: any) => (
                  <Row key={row.id} row={row} handlePermissionOnHold={handlePermissionOnHold} />
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        count={sortedData.length}
        rowsPerPage={rpg}
        page={pg}
        oncPageChange={handleChangePage}
        oncRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* <TablePagination
        rowsPerPageOptions={[2]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rpg}
        page={pg}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </>
  );
};

export default Accordian;
