/* eslint-disable consistent-return */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-plusplus */
/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */

/**
 * React component for Create Edit Surgery.
 * @module src/components/CreateEditSurgeryEvent/index
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import Modal from '@salesforce/design-system-react/components/modal';
import './index.scss';
import { format } from 'date-fns';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getUserId } from '../../store/ducks/userId';
import { getSchemeJson } from '../../util/FormJson';
import FormEngine, { Form } from '../Shared/FormEngine';
import { GET_FRANCHISES } from '../../graphql/getFranchises';
import { GET_BILL_TO_ADDRESSES } from '../../graphql/getBillToAddresses';
import { GET_SHIP_TO_ADDRESSES } from '../../graphql/getShipToAddresses';
import { GET_COVERING_REPS } from '../../graphql/getCoveringReps';
import { GET_USER_BRANCHES } from '../../graphql/getUserBranches';
import { GET_PROCEDURE_TYPES } from '../../graphql/getProcedureTypes';
import { GET_OPPORTUNITIES } from '../../graphql/getOpportunities';
import { GET_PROCEDURE_DETAILS } from '../../graphql/getProcedureDetails';
import { GET_SURGEONS } from '../../graphql/getSurgeons';
import { GET_ACCOUNTS } from '../../graphql/getAccounts';
import { GET_SALES_REPS } from '../../graphql/getSalesReps';
import { GET_STATES } from '../../graphql/getStates';
import { GET_USER_TBA_ACCOUNTS } from '../../graphql/getUserTBAAccounts';
import { GET_FORM_CONFIGURATIONS } from '../../graphql/getFormConfigurations';
import CREATE_EVENT from '../../graphql/mutations/createEvent';
import { GET_WORKFLOW_PERMISSION } from '../../graphql/workflowPermissions';
import {
  EVENT_STATUS,
  DATE_VIEW_FORMATTER,
  NOTIFY_TYPE,
  MOCK_OR_EVENT_TYPE,
} from '../../util/constants';
import UPDATE_EVENT from '../../graphql/mutations/updateEvent';
import GET_LINE_ITEMS from '../../graphql/getLineItemsForInventoryRequests';
import CancelEventModal from './CancelEventCreationModal';
import env from '../../util/env';
import { getUTCDateTime } from '../../util/utilityFunctions';
import { getUserInfo } from '../../store/ducks/userInfo';
import SnackBar from '../Shared/SnackBar';
import useSnackBar from '../../util/customHooks/useSnackBar';
import EventEditConfirmationModal from '../SurgicalDetail/SurgicalDetailEditConfirmationModal';
import { GET_TNVENTORY_REQUEST_DETAILS } from '../../graphql/getInventoryRequestDetails';
import CREATE_EVENT_AFTER_SFID from '../../graphql/mutations/createEventAfterSfid';

interface SurgeonWorkflowPermission {
  workflowPermission: {
    id: string;
  } | null;
}

interface EventDate {
  date: Date;
  formattedDate: string;
}

interface FormikValueItem {
  id: string;
  label: string;
}

interface VariableType {
  [key: string]: string;
}

interface ProcedureType {
  id: string;
  label: string;
  type: string;
}

interface SalesTeam {
  id: string;
  label: string;
}
interface ItemPrivate {
  salesUnitOfMeasure: string;
  approvalStatus?: string;
  priceBookEntryId?: string;
  unitPrice?: string;
  externalId?: string;
  id?: string;
  quantity: string;
  availableqty: number | null | undefined;
  expirationDate: string;
  lotNumber: string;
  onhandqty: number | null;
  productdescription: string | null;
  productId: string;
  productNumber: string | null;
  subInventoryName: string | null;
  lineVisualIndicator?: string | null | undefined;
  productNo?: string | null;
  usageId?: string | null | undefined;
  usageExternalId?: string | null | undefined;
  workflowAction?: string | null | undefined;
}
interface EventData {
  opportunityId?: string;
  eventInventory?: any;
  caseId?: string;
  caseExternalId?: string;
  eventType?: string;
  eventId?: string;
  division?: string;
  eventStatus?: string;
  eventDescription?: string;
  isOtherAddress?: boolean;
  isShipToAddress?: boolean;
  locationDescription?: string;
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  eventSubType?: string;
  isTBAAccount?: boolean;
  eventName?: string;
  eventAddress?: string;
  branchId?: string;
  branch?: string;
  accountId?: string;
  accountNumber?: string;
  accountName?: string;
  surgeonId?: string;
  surgeonFirstName?: string;
  surgeonLastName?: string;
  surgeonErpCode?: string;
  procedures?: string;
  salesRep?: string;
  salesRepId?: string;
  coveringReps?: string;
  navigation?: boolean;
  mako?: boolean;
  revisions?: boolean;
  eventStartDate?: Date;
  eventEndDate?: Date;
  shippingName?: string;
  shippingId?: string;
  billingName?: string;
  billingId?: string;
  deliveryService?: string;
  specialInstruction?: string;
  customerPoNo?: string;
  organizedBy?: string;
  numberOfParticipants?: string;
  trailContractSigned?: boolean;
  eventShippingLocation?: string;
  proceduresList?: ProcedureType[];
  salesTeams?: SalesTeam[];
  comments?: any;
  eventDuration?: string;
  opportunity?: string;
}

interface EventInventory {
  getInventoryRequestDetails?: EventInventory | null | undefined;
  getEventInventory: any;
}

interface InternalComments {
  id: string;
  comment: string;
  createdDate: string;
  commentType: string;
  commentExternalId: string;
  postTime: string;
  userId: string;
  sfid: string;
  userName: string;
}

interface CustomerComments {
  id: string;
  comment: string;
  createdDate: string;
  commentType: string;
  commentExternalId: string;
  postTime: string;
  userId: string;
  sfid: string;
  userName: string;
}

const EVENTS_KEY: VariableType = {
  franchise: 'division',
  country: 'branchId',
  account: 'accountId',
  surgeon: 'surgeonId',
  eventAddress: 'eventAddress',
  eventName: 'eventName',
  procedureType: 'procedureTypeIds',
  procedureDetails: 'procedureDetailIds',
  salesRep: 'salesRepId',
  coveringRep: 'coveringRepIds',
  navigation: 'navigation',
  robotics: 'robotics',
  revision: 'revision',
  deliveryService: 'deliveryService',
  specialInstruction: 'specialInstruction',
  eventStartDateTime: 'eventStartDateTime',
  eventEndDateTime: 'eventEndDateTime',
  shipTo: 'shipToId',
  billTo: 'billToId',
  customerPo: 'customerPo',
  organizedBy: 'organizedBy',
  numberOfParticipants: 'numberOfParticipants',
  trailContractSigned: 'trailContractSigned',
  eventShippingLocation: 'eventShippingLocation',
  comments: 'comments',
  trialLocation: 'eventAddress',
  eventDuration: 'eventDuration',
};

interface SurgeonWorkflowPermission {
  workflowPermission: {
    id: string;
  } | null;
}

const getUTCDate = (date: string): string => {
  const utcTimeString = new Date(date.replace(' ', 'T'));

  const utcDate = new Date(
    utcTimeString.getFullYear(),
    utcTimeString.getMonth(),
    utcTimeString.getDate(),
    utcTimeString.getHours(),
    utcTimeString.getMinutes(),
    utcTimeString.getSeconds()
  ).toISOString();
  return `${utcDate.substr(0, 10)} ${utcDate.substr(11, 8)}`;
};

const getCommentDate = (): string => {
  const finalDate = format(new Date(), DATE_VIEW_FORMATTER);
  const utcDate = getUTCDate(finalDate);
  return utcDate;
};
export interface Props extends RouteComponentProps {
  children?: FC;
  isOpen: boolean;
  toggleOpen: (isSave?: boolean) => void;
  eventType: string;
  eventSubType: string;
  eventLabel: string;
  eventDetail?: EventData;
  refetchEventItems?: () => void;
  showSurgeonData?: SurgeonWorkflowPermission | null;
  refetchEventDetails?: () => void;
  eventInventoryData?: EventInventory | null;
  isInvCloneTrue?: any;
  internalComments?: InternalComments[] | null;
  customerComments?: CustomerComments[] | null;
  callEventDetail?: (
    externalId: string,
    flowType: string,
    eventAcc: any,
    otherAdd: any,
    locDesc: any,
    selectedSalesRep: any
  ) => void;
  callEventDetailWithInv?: (externalId: string, flowType: string, externalIdInv?: any) => void;
  clonedLineItems?: (data: any) => void;
  hasAssociatedRecords?: boolean;
}

const CreateEditSurgeryEvent: FC<Props> = ({
  isOpen,
  toggleOpen,
  eventType,
  eventSubType,
  eventLabel,
  history,
  refetchEventItems,
  showSurgeonData,
  eventDetail,
  refetchEventDetails,
  eventInventoryData,
  internalComments,
  customerComments,
  callEventDetail,
  clonedLineItems,
  hasAssociatedRecords,
  isInvCloneTrue,
}) => {
  const { open, notification, openSnackBar } = useSnackBar();
  const [cloneInventory, setCloneInventory] = useState(false);
  const [locationChecked, setLocationChecked] = useState('Ship To Address');
  const [selectedSalesRep, setSelectedSalesRep] = useState<any>();
  const [accountSelected, setaccountSelected] = useState<any>();
  const [showEditConfirmationModal, setEditConfirmationModal] = useState(false);
  const [accountChecked, setAccountChecked] = useState('My Accounts');
  const [clickedButtonType, setClickedButton] = useState('');
  const [canceModalVisible, setCanceModalVisible] = useState(false);
  const [allLineItemsClone, setAllLineItemsClone] = useState<any>([]);
  const [invDetails, setInvData] = useState<any>([]);
  const [resultData, setResult] = useState<any>({});
  const [eventAccount, setEventAccount] = useState<any>([]);
  const [otherAddress, setOtherAddress] = useState<any>(null);
  const [locationDesc, setLocationDesc] = useState<any>(null);
  const [opportunityListData, setOpportunityList] = useState([]);
  const [isIR, setIR] = useState(false);
  const userId = useSelector(getUserId);
  const userInfo = useSelector(getUserInfo);
  const [getInventoryDetails, { data: invData }] = useLazyQuery(GET_TNVENTORY_REQUEST_DETAILS, {
    fetchPolicy: 'no-cache',
  });
  let apiDataObj: any = null;
  const isEditEvent = !!(eventDetail && (eventDetail.caseId || eventDetail.caseExternalId));
  const USE_LOCAL_FORM_JSON = env.REACT_APP_USE_LOCAL_FORM_JSON || 'true';
  const { data: formData } = useQuery(GET_FORM_CONFIGURATIONS);
  let showConfirmationModal = false;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const disabledEditFeilds = (payloadObject: any): any => {
    const clonePayloadObject = JSON.parse(JSON.stringify(payloadObject));

    if (isEditEvent) {
      const type = eventType === 'Demo' ? eventSubType : eventType;

      // eslint-disable-next-line consistent-return
      const buisnessRules = userInfo?.businessRules?.filter((item: any) => {
        if (item.screenName === `${type} - Detail`) {
          return item.rules;
        }
      });

      if (buisnessRules && buisnessRules.length > 0) {
        buisnessRules.forEach((item: any) => {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < clonePayloadObject.length; i++) {
            if (clonePayloadObject[i].kind === 'section') {
              const data: any = clonePayloadObject[i] || {};
              const fieldItem = data?.fields?.find((fI: any) => fI.fieldLabel === item.rules);
              if (fieldItem) {
                fieldItem.disabled = !item.isRequired;
                if (item.isRequired) {
                  showConfirmationModal = true;
                }
                break;
              }
            }
          }
        });
      }
    }
    return clonePayloadObject;
  };

  const formJson = useCallback(() => {
    if (USE_LOCAL_FORM_JSON === 'true') {
      return getSchemeJson(eventType);
    }

    try {
      const dataObject = formData && formData.getFormConfigurations;
      const formIdentifierToFormName: Record<string, string> = {
        Trial: 'newTrial',
        Surgery: 'surgery',
        'Surgery-Usage Only': 'surgeryUsageOnly',
        'Workshop Events': 'workshop',
        Demo: 'demo',
        shownGo: 'showGo',
        shownGoLoc: 'showGoWithLocation',
        mockOR: 'mockOr',
        mockORLoc: 'mockOrWithLocation',
      };
      let payloadObject: Form = dataObject[formIdentifierToFormName[eventType]];
      if (eventType === 'Show-n-Go' || eventDetail?.eventSubType === 'Show-n-Go') {
        if (locationChecked === 'Ship To Address') {
          payloadObject = dataObject[formIdentifierToFormName.shownGo];
        } else {
          payloadObject = dataObject[formIdentifierToFormName.shownGoLoc];
        }
      }
      if (eventType === MOCK_OR_EVENT_TYPE || eventDetail?.eventSubType === MOCK_OR_EVENT_TYPE) {
        if (locationChecked === 'Ship To Address') {
          payloadObject = dataObject[formIdentifierToFormName.mockOR]; // from form api
        } else {
          payloadObject = dataObject[formIdentifierToFormName.mockORLoc]; // from form api
        }
      }

      if (isEditEvent && hasAssociatedRecords) {
        payloadObject = disabledEditFeilds(payloadObject);
      }
      return payloadObject;
    } catch (error) {
      // If the query errors out, need to return something
      console.log(`Form Json Error - Error Caught: ${error}`);
      return getSchemeJson(eventType);
    }

    // Get rid of USE_LOCAL_FORM_JSON here and replace the above one from the env file
  }, [
    formData,
    eventType,
    USE_LOCAL_FORM_JSON,
    locationChecked,
    eventDetail,
    isEditEvent,
    disabledEditFeilds,
    hasAssociatedRecords,
  ]);

  const { data: showCSRFields } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      action: 'show_csr_fields',
    },
  });

  const { data: showSurgeonFields } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      action: 'show_surgeon_fields',
    },
  });

  const { data: showCreateInventoryButton } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      state: eventType,
      action: 'enable_create_inventory_button',
    },
  });

  const { data: showCreateOpenButton } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      state: eventType,
      action: 'enable_create_open_button',
    },
  });

  const [showInventoryWorkflowPermissions, { data: showInventoryTab }] = useLazyQuery(
    GET_WORKFLOW_PERMISSION,
    {
      fetchPolicy: 'cache-only',
    }
  );

  const [enableInventoryWorkflowPermissions, { data: enableInventoryTab }] = useLazyQuery(
    GET_WORKFLOW_PERMISSION,
    {
      fetchPolicy: 'cache-only',
    }
  );

  const [getLineItems, { data: lineItemsData }] = useLazyQuery(GET_LINE_ITEMS); // USELAZYQUERY

  useEffect(() => {
    if (
      !(eventDetail && eventDetail.eventInventory) ||
      (eventDetail && eventDetail.eventInventory.length <= 0)
    ) {
      return;
    }
    const getAllLineItems = [...eventDetail.eventInventory];

    const externalIDs = getAllLineItems.map(
      e => e?.externalId && e?.externalId !== '' && e?.externalId
    );
    if (externalIDs && externalIDs.length > 0) {
      setCloneInventory(true);
      getLineItems({
        variables: {
          externalIds: externalIDs,
        },
      });
    }
    getInventoryDetails({
      variables: {
        externalId: externalIDs[0],
      },
    });
  }, [eventDetail, getInventoryDetails, getLineItems]);

  useEffect(() => {
    if (eventDetail) {
      const value = eventDetail.isOtherAddress ? 'Other Address' : 'Ship To Address';
      setLocationChecked(value);
      const data = !eventDetail?.isTBAAccount ? 'My Accounts' : 'TBA Accounts';
      setAccountChecked(data);
    }
  }, [eventDetail, isEditEvent]);

  useEffect(() => {
    if (
      eventInventoryData &&
      eventInventoryData?.getInventoryRequestDetails &&
      lineItemsData &&
      lineItemsData.getLineItemsForInventoryRequests &&
      lineItemsData.getLineItemsForInventoryRequests.length > 0
    ) {
      setAllLineItemsClone(lineItemsData.getLineItemsForInventoryRequests);
    }
  }, [lineItemsData, eventInventoryData]);

  useEffect(() => {
    if (invData && invData?.getInventoryRequestDetails) {
      setInvData(invData && invData?.getInventoryRequestDetails);
    }
  }, [invData]);

  const handleClickedButton = (buttonType: string): void => {
    setClickedButton(buttonType);
  };

  const [createEvent, { data: createEventData, loading: createEventLoading }] = useMutation(
    CREATE_EVENT
  );

  const [
    createEventAfterSfid,
    { data: createEventDataAfterSfid, loading: createEventAfterSfidLoading },
  ] = useMutation(CREATE_EVENT_AFTER_SFID);

  const [updateEvent, { data: updateEventData, loading: updateEventLoading }] = useMutation(
    UPDATE_EVENT
  );

  // eslint-disable-next-line consistent-return
  const getFormFieldValues = (coveringRepsData: any): any => {
    if (eventDetail) {
      const procedureTypeList =
        eventDetail.proceduresList &&
        eventDetail.proceduresList.filter((procedure: ProcedureType) => procedure.type === 'PT');
      const procedureDetailList =
        eventDetail.proceduresList &&
        eventDetail.proceduresList.filter((procedure: ProcedureType) => procedure.type === 'PD');

      return {
        franchise: [
          {
            id: eventDetail.division,
            label: eventDetail.division,
          },
        ],
        country: [
          {
            id: eventDetail.branchId,
            label: eventDetail.branch,
          },
        ],
        opportunity: [
          {
            id: eventDetail.opportunityId,
            label: eventDetail.opportunity,
          },
        ],
        procedureDetails: procedureDetailList,
        procedureType: procedureTypeList || [],
        navigation: eventDetail.navigation,
        robotics: eventDetail.mako,
        revision: eventDetail.revisions,
        eventDate: eventDetail.eventStartDate,
        startDate: eventDetail.eventStartDate,
        endDate: eventDetail.eventEndDate,
        eventTime: eventDetail.eventStartDate,
        startTime: eventDetail.eventStartDate,
        endTime: eventDetail.eventEndDate,
        eventDescription: eventDetail.eventDescription,
        locationDescription: eventDetail.locationDescription,
        street1: eventDetail.street1,
        street2: eventDetail.street2,
        city: eventDetail.city,
        countryAddress: 'US',
        state: eventDetail.state
          ? [
              {
                label: eventDetail.state ? `${eventDetail.state}` : ``,
                name: eventDetail.state ? `${eventDetail.state}` : ``,
              },
            ]
          : [],
        zipCode: eventDetail.zipCode,
        account: [
          {
            id: eventDetail.accountId,
            label: eventDetail.accountNumber
              ? `${eventDetail.accountNumber} ${eventDetail.accountName}`
              : eventDetail.accountName,
          },
        ],
        eventName: eventDetail.eventName,
        eventAddress: eventDetail.eventAddress,
        ...(eventDetail.surgeonId && {
          contact: [
            {
              id: eventDetail.surgeonId,
              label: `${eventDetail.surgeonFirstName} ${eventDetail.surgeonLastName}`,
            },
          ],
        }),
        eventSubType: eventDetail.eventSubType,
        salesRep: [
          {
            id: eventDetail.salesRepId,
            label: eventDetail.salesRep,
          },
        ],
        coveringRep: eventDetail.salesTeams,
        shipTo: [
          {
            id: eventDetail.shippingId,
            label: eventDetail.shippingName,
          },
        ],
        billTo: [
          {
            id: eventDetail.billingId,
            label: eventDetail.billingName,
          },
        ],
        specialInstruction: eventDetail.specialInstruction,
        customerPo: eventDetail.customerPoNo,
        trailContractSigned: eventDetail.trailContractSigned,
        organizedBy: eventDetail.organizedBy,
        comments: eventDetail.comments && eventDetail.comments[0] && eventDetail.comments[0].value,
        trialLocation: eventDetail.eventAddress,
        numberOfParticipants: eventDetail.numberOfParticipants,
        eventShippingLocation: eventDetail.eventShippingLocation,
        eventDuration: eventDetail.eventDuration,
        deliveryService: [
          {
            id: eventDetail.deliveryService,
            label: eventDetail.deliveryService,
          },
        ],
      };
    }
    const data: any = {
      franchise: [],
      countryAddress: 'US',
      coveringRep: coveringRepsData,
    };
    return data;
  };
  const handleAccountSelection = useCallback(
    accountSelectedValue => {
      setaccountSelected(accountSelectedValue);
    },
    [setaccountSelected]
  );

  useEffect(() => {
    if (
      updateEventData &&
      updateEventData.updateEvent &&
      updateEventData.updateEvent.message === 'success'
    ) {
      openSnackBar(NOTIFY_TYPE.SUCCESS, `Event has been updated successfully`);
      setTimeout(() => {
        toggleOpen();
        if (refetchEventDetails) {
          refetchEventDetails();
        }
        if (refetchEventItems) {
          refetchEventItems();
        }
      }, 1000);
    }
  }, [openSnackBar, refetchEventDetails, refetchEventItems, toggleOpen, updateEventData]);

  useEffect(() => {
    if (
      createEventDataAfterSfid &&
      createEventDataAfterSfid.createEventAfterSfid &&
      createEventDataAfterSfid.createEventAfterSfid.message === 'success'
    ) {
      openSnackBar(NOTIFY_TYPE.SUCCESS, `Event has been created successfully`);
      if (cloneInventory && callEventDetail) {
        // eslint-disable-next-line no-lone-blocks
        {
          callEventDetail(
            createEventDataAfterSfid.createEventAfterSfid.externalId,
            'clone',
            invDetails,
            '',
            '',
            selectedSalesRep
          );
        }
      }
      if (!isInvCloneTrue) {
        setTimeout(() => {
          toggleOpen(clickedButtonType !== 'createInventory');
          if (refetchEventItems) {
            refetchEventItems();
          }

          if (clickedButtonType === 'createInventory') {
            const { externalId } = createEventDataAfterSfid.createEventAfterSfid;
            const eventAcc = eventAccount || '';
            const otherAdd = otherAddress || '';
            const locDesc = locationDesc || '';
            if (callEventDetail) {
              /** This to set the flow type to clone once we select clone (Clone Event component) */
              const flowType = cloneInventory ? 'clone' : '';
              if (clickedButtonType === 'createInventory') {
                callEventDetail(
                  externalId,
                  flowType,
                  eventAcc,
                  otherAdd,
                  locDesc,
                  selectedSalesRep
                );
              }
            }
          } else if (refetchEventItems) {
            refetchEventItems();
          }
        }, 1000);
      }
    }
  }, [
    clickedButtonType,
    otherAddress,
    createEventData,
    enableInventoryTab,
    locationDesc,
    enableInventoryWorkflowPermissions,
    eventType,
    showInventoryTab,
    showInventoryWorkflowPermissions,
    history,
    refetchEventItems,
    toggleOpen,
    callEventDetail,
    cloneInventory,
    openSnackBar,
    isEditEvent,
    eventAccount,
    invDetails,
    createEventDataAfterSfid,
    isInvCloneTrue,
    selectedSalesRep,
  ]);

  const handleSave = (result: any): void => {
    if (showConfirmationModal && Object.keys(resultData)?.length === 0 && hasAssociatedRecords) {
      setEditConfirmationModal(true);
      setResult(result);
      return;
    }

    let intComments: InternalComments[] = [];
    let custComments: CustomerComments[] = [];

    /** clonedLineItems function to pass the allLineItemsClone to Clone Event component */
    if (clonedLineItems) {
      clonedLineItems(allLineItemsClone);
    }

    if (result.city && result.state && result.street1 && result.zipCode) {
      const completeAddress = {
        address1: result?.street1,
        address2: result?.street2 || null,
        locationDescription: result?.locationDescription,
        city: result.city,
        id: '',
        isPrimaryAddress: null,
        name: result.zipCode,
        postalcode: 'US',
        state: result.state[0].name,
        isOtherAddress: true,
        __typename: 'ShipToAddress',
      };
      setOtherAddress(completeAddress);
    }

    if (internalComments) {
      intComments = internalComments.map(comment => ({
        id: comment.id,
        comment: comment.comment,
        createdDate: getCommentDate(),
        commentType: comment.commentType,
        commentExternalId: comment.commentExternalId,
        postTime: getCommentDate(),
        userId: comment.userId,
        sfid: comment.sfid,
        userName: comment.userName,
      }));
    }

    if (customerComments) {
      custComments = customerComments.map(comment => ({
        id: comment.id,
        comment: comment.comment,
        createdDate: getCommentDate(),
        commentType: comment.commentType,
        commentExternalId: comment.commentExternalId,
        postTime: getCommentDate(),
        userId: comment.userId,
        sfid: comment.sfid,
        userName: comment.userName,
      }));
    }

    let mutation = {};
    mutation = {
      ...mutation,
      opportunity:
        result?.opportunity && result?.opportunity[0]?.id ? result?.opportunity[0]?.id : '',
      eventType: eventType === 'Show-n-Go' || eventType === MOCK_OR_EVENT_TYPE ? 'Demo' : eventType,
      ...(isEditEvent &&
        eventDetail?.eventSubType === MOCK_OR_EVENT_TYPE && {
          eventSubType: eventDetail?.eventSubType,
        }),
      ...(!isEditEvent &&
        eventDetail?.eventSubType !== MOCK_OR_EVENT_TYPE && {
          eventSubType:
            // eslint-disable-next-line no-nested-ternary
            eventType === 'Show-n-Go' || eventType === MOCK_OR_EVENT_TYPE
              ? eventType
              : eventType === 'Demo'
              ? eventType
              : eventSubType,
        }),
      status: showSurgeonData ? EVENT_STATUS.PORTAL : EVENT_STATUS.NEW,
    }; // TODO Added static value for status as discuss with SFDC team
    if (isEditEvent && eventDetail) {
      const status =
        eventDetail.eventStatus === EVENT_STATUS.PORTAL && !showSurgeonData
          ? EVENT_STATUS.NEW
          : eventDetail.eventStatus;
      mutation = {
        ...mutation,
        externalId: eventDetail.caseExternalId,
        id: eventDetail.caseId,
        branchId: eventDetail.branchId,
        status,
      };
    }
    if (!isEditEvent && eventDetail) {
      mutation = {
        ...mutation,
        eventSubType:
          eventDetail?.eventSubType === '' || eventDetail?.eventSubType === null
            ? ''
            : eventDetail?.eventSubType,
      };
    }
    Object.keys(EVENTS_KEY).forEach(key => {
      const serverKey = EVENTS_KEY[key];
      if (key === 'comments') {
        const commentDate = getCommentDate();
        const commentType = showSurgeonData ? 'Customer' : 'Internal';
        const commentExternlId =
          eventDetail?.comments && eventDetail.comments[0] && eventDetail.comments[0].externalId;
        if (commentExternlId) {
          // Edit
          mutation = {
            ...mutation,
            [serverKey]: [
              {
                comment: result.comments || '',
                postTime: commentDate,
                commentType,
                externalId: commentExternlId,
              },
            ],
          };
        } else if (result.comments) {
          // New
          mutation = {
            ...mutation,
            [serverKey]: [
              {
                comment: result.comments || '',
                postTime: commentDate,
                commentType,
              },
            ],
          };
        }
      } else if (result[key]) {
        if (
          Array.isArray(result[key]) &&
          key === 'procedureType' &&
          eventDetail?.eventSubType !== 'Show-n-Go'
        ) {
          mutation = { ...mutation, [serverKey]: result?.procedureType[0]?.id };
        } else if (Array.isArray(result[key]) && key === 'procedureDetails') {
          const value = result[key].map((item: FormikValueItem) => item.id);
          const name = result[key].map((item: FormikValueItem) => item.label);
          mutation = {
            ...mutation,
            [serverKey]: [...value],
            procedureDetailNames: [...name].join(', '),
          };
        } else if (Array.isArray(result[key]) && key === 'coveringRep') {
          const value = result[key].map((item: FormikValueItem) => item.id);
          const name = result[key].map((item: FormikValueItem) => item.label);
          mutation = {
            ...mutation,
            [serverKey]: [...value],
            coveringRepNames: [...name],
          };
        } else if (typeof result[key] === 'string' || typeof result[key] === 'boolean') {
          mutation = { ...mutation, [serverKey]: result[key] };
        } else if (Array.isArray(result[key]) && result[key].length > 0) {
          mutation = { ...mutation, [serverKey]: result[key][0].id };
        }
      }
    });
    if (result.country) {
      mutation = { ...mutation, branchId: result.country[0].id };
    }

    if (result.locationDescription) {
      setLocationDesc(result.locationDescription);
      mutation = { ...mutation, locationDescription: result.locationDescription };
    }

    if (result.eventDescription) {
      mutation = { ...mutation, eventDescription: result.eventDescription };
    }

    if (result.street1) {
      mutation = { ...mutation, street1: result.street1 };
    }

    if (result.street2) {
      mutation = { ...mutation, street2: result.street2 };
    }

    if (result.city) {
      mutation = { ...mutation, city: result.city };
    }
    if (result.state) {
      mutation = { ...mutation, state: result.state.length > 0 ? result.state[0]?.name : '' };
    }

    if (result.zipCode) {
      mutation = { ...mutation, zipCode: result.zipCode };
    }

    if (result.account) {
      setEventAccount(result.account);
      if (result.account[0].branchId) {
        mutation = {
          ...mutation,
          branchId: result.account[0].branchId,
        };
      } else {
        mutation = { ...mutation };
      }
    }
    if (result.contact && result.contact.length) {
      mutation = { ...mutation, surgeonId: result.contact[0].id };
    }

    if (result.eventDate) {
      mutation = {
        ...mutation,
        eventDateTime: getUTCDateTime(result.eventDate), // `${moment(result.eventDate).format('YYYY-MM-DD HH:mm:ss')}`,
      };
    }

    if (result.startDate) {
      mutation = {
        ...mutation,
        eventStartDateTime: getUTCDateTime(result.startDate), // `${moment(result.startDate).format('YYYY-MM-DD HH:mm:ss')}`,
      };
    }

    if (result.endDate) {
      mutation = {
        ...mutation,
        eventEndDateTime: getUTCDateTime(result.endDate), // `${moment(result.startDate).format('YYYY-MM-DD HH:mm:ss')}`,
      };
    }
    if (result.duration) {
      mutation = {
        ...mutation,
        eventDuration: result.eventDuration.toString(), // `${moment(result.endDate).format('YYYY-MM-DD HH:mm:ss')}`,
      };
    }
    if (!result.navigation) {
      mutation = { ...mutation, navigation: false };
    }
    if (!result.robotics) {
      mutation = { ...mutation, robotics: false };
    }
    if (!result.revision) {
      mutation = { ...mutation, revision: false };
    }
    if (!result.trailContractSigned) {
      mutation = { ...mutation, trailContractSigned: false };
    }
    // if (eventInventoryData) {
    //   mutation = { ...mutation, kits, parts };
    // }
    if (internalComments) {
      mutation = { ...mutation, internalComments: intComments };
    }
    if (customerComments) {
      mutation = { ...mutation, customerComments: custComments };
    }
    /** checking the if its clone Event and Clone Inventory  */
    if (mutation && eventDetail && !isEditEvent) {
      mutation = {
        ...mutation,
        branchId: eventDetail?.branchId,
        system: 'Web',
        version: process.env.REACT_APP_VERSION || '0.0.1',
      };
      /** checking if the allLineItemsClone length to call only the createEvent and route to /surgicalDetails */
      if (allLineItemsClone.length === 0) {
        createEvent({
          variables: mutation,
        })
          .then(response => {
            mutation = {
              ...mutation,
              caseSFID: response?.data?.createEvent?.sfid,
            };
            const recursiveCallApi = () => {
              createEventAfterSfid({
                variables: mutation,
              })
                .then(res => {
                  if (res.data !== null && !cloneInventory) {
                    history.push('/surgicalDetails', {
                      eventType,
                      externalId:
                        res.data &&
                        res.data.createEventAfterSfid &&
                        res.data.createEventAfterSfid.externalId,
                    });
                  } else if (!isInvCloneTrue) {
                    history.push('/surgicalDetails', {
                      eventType,
                      externalId:
                        res.data &&
                        res.data.createEventAfterSfid &&
                        res.data.createEventAfterSfid.externalId,
                    });
                  }
                })
                .catch(error => {
                  if (error.message.toLowerCase().includes('salesforce and heroku sync issue')) {
                    recursiveCallApi();
                  } else {
                    openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
                  }
                });
            };

            recursiveCallApi();
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
            console.log(error); // TODO change when some operation needs to be run on error.
          });
      } else {
        createEvent({
          variables: mutation,
        })
          .then(response => {
            mutation = {
              ...mutation,
              caseSFID: response?.data?.createEvent?.sfid,
            };
            const recursiveCallApi = () => {
              createEventAfterSfid({
                variables: mutation,
              }).catch(error => {
                if (error.message.toLowerCase().includes('salesforce and heroku sync issue')) {
                  recursiveCallApi();
                } else {
                  openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
                }
              });
            };

            recursiveCallApi();
          })
          .catch(error => {
            openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
            // eslint-disable-next-line no-console
            console.log(error); // TODO change when some operation needs to be run on error.
          });
      }
    } else if (mutation && !isEditEvent) {
      mutation = {
        ...mutation,
        system: 'Web',
        version: process.env.REACT_APP_VERSION || '0.0.1',
      };
      createEvent({
        variables: mutation,
      })
        .then(response => {
          mutation = {
            ...mutation,
            caseSFID: response?.data?.createEvent?.sfid,
          };
          const recursiveCallApi = () => {
            createEventAfterSfid({
              variables: mutation,
            }).catch(error => {
              if (error.message.toLowerCase().includes('salesforce and heroku sync issue')) {
                recursiveCallApi();
              } else {
                openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
              }
            });
          };

          recursiveCallApi();
        })
        .catch(error => {
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
          console.log(error); // TODO change when some operation needs to be run on error.
        });
    } else if (mutation && isEditEvent && eventDetail) {
      mutation = {
        ...mutation,
        branchId: eventDetail.branchId,
        system: 'Web',
        version: process.env.REACT_APP_VERSION || '0.0.1',
      };
      updateEvent({
        variables: mutation,
      }).catch(error => {
        // eslint-disable-next-line no-console
        openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
        console.log(error); // TODO change when some operation needs to be run on error.
      });
    }
  };

  const { data: franchiseItems } = useQuery(GET_FRANCHISES, {
    variables: {
      userId,
    },
  });
  const franchises = franchiseItems?.getFranchises;

  const [getBillToAddressItems, { data: billToAddressesItems }] = useLazyQuery(
    GET_BILL_TO_ADDRESSES
  ); // USELAZYQUERY
  const billToAddresses = billToAddressesItems?.getBillToAddresses;

  const [getShipToAddressItems, { data: shipToAddressesItems }] = useLazyQuery(
    GET_SHIP_TO_ADDRESSES
  ); // USELAZYQUERY
  const shipToAddresses = shipToAddressesItems?.getShipToAddresses;

  const [getSurgeonItems, { data: surgeonItems }] = useLazyQuery(GET_SURGEONS); // USELAZYQUERY
  const surgeonsData = surgeonItems?.getSurgeons;

  const [getCountryItems, { data: userBranchesItems }] = useLazyQuery(GET_USER_BRANCHES); // USELAZYQUERY
  const userBranchesData = userBranchesItems?.getUserBranches;

  const [getAccountItems, { data: accountItems }] = useLazyQuery(GET_ACCOUNTS, {
    fetchPolicy: 'no-cache',
  }); // USELAZYQUERY
  const accountsData = accountItems?.getAccounts;
  const [getTBAAccounts, { data: tbaAccountsItems }] = useLazyQuery(GET_USER_TBA_ACCOUNTS, {
    fetchPolicy: 'no-cache',
  });
  const tbaAccountsData = tbaAccountsItems?.getUserTBAAccounts;

  const [getProcedureDetailItems, { data: procedureDetailItems }] = useLazyQuery(
    GET_PROCEDURE_DETAILS
  ); // USELAZYQUERY
  const procedureDetailsData = procedureDetailItems?.getProcedureDetails;

  const [getProcedureTypesItems, { data: procedureTypeItems }] = useLazyQuery(GET_PROCEDURE_TYPES); // USELAZYQUERY
  const procedureTypesData = procedureTypeItems?.getProcedureTypes;
  const [getOpportunitiesItem, { data: getOpportunities }] = useLazyQuery(GET_OPPORTUNITIES); // USELAZYQUERY
  const opportunitiesData = getOpportunities?.getOpportunities;

  const [getCoveringRepsItems, { data: coveringRepsItems }] = useLazyQuery(GET_COVERING_REPS); // USELAZYQUERY
  const coveringRepsData = coveringRepsItems?.getCoveringReps;

  const [getSalesRepsItems, { data: salesRepsItems }] = useLazyQuery(GET_SALES_REPS); // USELAZYQUERY
  const salesRepsData = salesRepsItems?.getSalesReps;
  const [getStatesData, { data: statesData }] = useLazyQuery(GET_STATES); // USELAZYQUERY

  const fetchItems = useCallback(() => {
    if (selectedSalesRep && selectedSalesRep.length > 0) {
      getAccountItems({
        variables: {
          salesRepId: selectedSalesRep[0].id || userInfo?.id,
        },
      });
    }
    getOpportunitiesItem({
      variables: {
        accountId: accountSelected?.length > 0 ? accountSelected[0]?.id : eventDetail?.accountId,
      },
    });
    getProcedureTypesItems();
    getSalesRepsItems();
    getStatesData();
    // getCoveringRepsItems({
    //   variables: {
    //     salesRepId: selectedSalesRep || userInfo?.id,
    //   },
    // });
    // getSurgeonItems();
    const getAccountId =
      accountSelected?.length > 0 ? accountSelected[0]?.id : eventDetail?.accountId;
    if (getAccountId) {
      getSurgeonItems({
        variables: {
          accountId: getAccountId,
        },
      });
    }
  }, [
    getAccountItems,
    selectedSalesRep,
    userInfo,
    getOpportunitiesItem,
    accountSelected,
    eventDetail,
    getProcedureTypesItems,
    getSalesRepsItems,
    getStatesData,
    getSurgeonItems,
  ]);
  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const handleSalesRepSelection = useCallback(
    salesRepValue => {
      if (salesRepValue && salesRepValue.length > 0) {
        setSelectedSalesRep(salesRepValue);
        getCoveringRepsItems({
          variables: {
            salesRepId: salesRepValue[0].id || userInfo?.id,
          },
        });
      }
    },
    [setSelectedSalesRep, getCoveringRepsItems, userInfo]
  );

  useEffect(() => {
    if (opportunitiesData && eventDetail) {
      const dataArray = opportunitiesData;
      const data1 = opportunitiesData?.filter(
        (item: any) => item?.id === eventDetail?.opportunityId
      );
      if (data1?.length === 0 && eventDetail?.opportunityId !== null) {
        const ClosedOpportunityEdit = {
          id: eventDetail?.opportunityId,
          value: eventDetail?.opportunity,
          label: eventDetail?.opportunity,
        };
        dataArray.unshift(ClosedOpportunityEdit);
        setOpportunityList(dataArray);
      } else {
        setOpportunityList(opportunitiesData);
      }
    }
    if (accountSelected?.length === 0) {
      setOpportunityList([]);
    }
  }, [accountSelected, eventDetail, opportunitiesData]);

  const getOptionsWithLabel = (accntData: any): [] => {
    if (accntData) {
      // eslint-disable-next-line array-callback-return
      return accntData.map((item: any) => {
        // eslint-disable-next-line no-underscore-dangle
        if (item.__typename === 'Account') {
          return {
            ...item,
            label: item.isRepStockAccount
              ? `* ${item.number} ${item.value}`
              : `${item.number} ${item.value}`,
          };
        }
        return {
          ...item,
          label: item.number ? `${item.number} ${item.value}` : item.value,
        };
      });
    }
    return [];
  };

  apiDataObj = {
    getFranchises: franchises,
    getCoveringReps: coveringRepsData,
    getBillToAddress: billToAddresses,
    getShipToAddress: shipToAddresses,
    getCountries: userBranchesData,
    getProcedureDetails: procedureDetailsData,
    getProcedureType: procedureTypesData,
    getSurgeons: surgeonsData,
    getAccounts:
      accountChecked === 'My Accounts'
        ? getOptionsWithLabel(accountsData)
        : getOptionsWithLabel(tbaAccountsData),
    getOpportunity: opportunitiesData,
    getSalesReps: salesRepsData,
    getStates: statesData?.getStates,
  };

  const lazyQueryObject = {
    getSurgeons: getSurgeonItems,
    getCountries: getCountryItems,
    getAccounts: getAccountItems,
    getOpportunities: getOpportunitiesItem,
    getTbaAccounts: getTBAAccounts,
    getProcedureDetails: getProcedureDetailItems,
    getProcedureType: getProcedureTypesItems,
    getCoveringReps: getCoveringRepsItems,
    getShipToAddress: getShipToAddressItems,
    getBillToAddress: getBillToAddressItems,
    getSalesReps: getSalesRepsItems,
    getStates: getStatesData,
  };

  const eventStatus = eventDetail ? eventDetail.eventStatus || '' : '';
  const eventId = eventDetail ? eventDetail.eventId || '' : '';

  let modalHeading;

  if (isEditEvent) {
    modalHeading = `Edit ${eventSubType || eventType} (${eventId})`;
  } else if (eventDetail) {
    modalHeading = `Clone ${eventSubType || eventType} (${eventId})`;
  } else {
    modalHeading = `New ${eventLabel}`;
  }

  const handleCancelButton = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleCancelModal = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleCancelModalConfirmPress = (): void => {
    setCanceModalVisible(!canceModalVisible);
    toggleOpen();
  };

  const handleRadioButtonChange = (event: any, name: any): void => {
    if (name === 'tbaAccount') setAccountChecked(event.target.value);
    else setLocationChecked(event.target.value);
  };

  const handlelEditEventModalConfirmPress = (): void => {
    handleSave(resultData);
    setEditConfirmationModal(false);
  };

  const handleModalToggle = (): void => {
    setResult({});
    setEditConfirmationModal(!showEditConfirmationModal);
  };

  useEffect(() => {
    if (userInfo && userInfo?.businessRules && userInfo?.businessRules.length > 0) {
      if (eventType === 'Trial') {
        const filterRule: any = userInfo?.businessRules?.filter((item: any) => {
          if (
            item.screenName === `Global` &&
            item.rules === 'Trial Hold' &&
            (userInfo?.personas === 'Sales Rep' || userInfo?.personas === 'Agency Sales Rep') &&
            userInfo?.isinventoryhold
          ) {
            return item.isRequired;
          }
        });
        const isIr = filterRule && filterRule.length > 0 && filterRule[0].isRequired;
        setIR(isIr);
      }
    }
  }, [eventType, userInfo]);

  return (
    <div>
      <SnackBar open={open} notification={notification} />
      <Modal
        className="form-engine-modal"
        isOpen={isOpen}
        onRequestClose={createEventAfterSfidLoading ? '' : handleCancelButton}
        ariaHideApp={false}
        heading={<div className="slds-float_left slds-text-title_bold">{modalHeading}</div>}
        dismissOnClickOutside={false}
      >
        <section className="slds-p-around_medium slds-theme_shade" key="form">
          <FormEngine
            division="EU"
            formItems={formJson()}
            apiData={apiDataObj}
            useLazyQueryObject={lazyQueryObject}
            handleSave={handleSave}
            toggleOpen={toggleOpen}
            isOpen={isOpen}
            createEventLoading={createEventLoading || updateEventLoading}
            createRequest={createEventAfterSfidLoading}
            showCSRFields={showCSRFields}
            showSurgeonFields={showSurgeonFields}
            handleClickedButton={handleClickedButton}
            formValues={getFormFieldValues(coveringRepsData)}
            eventStatus={eventStatus}
            showCreateInventoryButton={showCreateInventoryButton}
            handleAccountIdSelection={handleAccountSelection}
            showCreateOpenButton={showCreateOpenButton}
            handleCancelButton={handleCancelButton}
            isEventInventoryButton
            modalHeading={modalHeading}
            handleRadioButtonChange={handleRadioButtonChange}
            accountChecked={accountChecked}
            locationChecked={locationChecked}
            isEdit={isEditEvent}
            isIR={isIR}
            handleSalesRepSelection={handleSalesRepSelection}
          />
        </section>
      </Modal>

      {canceModalVisible && (
        <CancelEventModal
          isNew
          canceModalVisible={canceModalVisible}
          handleCancelModal={handleCancelModal}
          handleCancelModalConfirmPress={handleCancelModalConfirmPress}
          edit={isEditEvent}
        />
      )}

      <EventEditConfirmationModal
        showEditConfirmationModal={showEditConfirmationModal}
        handleModalToggle={handleModalToggle}
        handlelEditEventModalConfirmPress={handlelEditEventModalConfirmPress}
      />
    </div>
  );
};

/** React component for Create Edit Surgery. */
export default withRouter(CreateEditSurgeryEvent);
