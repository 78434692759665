/**
 * Module for Custom Rep/Covering Cell.
 * @module src/Events/EventsList
 */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import './index.scss';

interface Reps {
  salesRep: string;
  coveringReps: string;
  eventType: string;
}
interface Props {
  item?: Reps;
  showSalesRep?: boolean;
  showCoveringRep?: boolean;
  eventType?: string;
}

const EventsListRepCoveringCell: FC<Props> = ({
  showSalesRep,
  showCoveringRep,
  item = {
    salesRep: '',
    coveringReps: '',
    eventType: '',
  },
}) => {
  const { salesRep, coveringReps, eventType } = item;
  return (
    <DataTableCell title={salesRep || coveringReps || ''}>
      {showSalesRep ? (
        <p className="wrap-text font-12">
          {showSalesRep && eventType !== 'Cycle Count'
            ? salesRep
            : coveringReps?.replace(',', ', ')}
        </p>
      ) : showCoveringRep && eventType !== 'Cycle Count' ? (
        <p className="wrap-text font-12">{showCoveringRep ? coveringReps : '--'}</p>
      ) : null}
    </DataTableCell>
  );
};

/** Custom Rep/Covering cell */
export default EventsListRepCoveringCell;
