import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
//  Interface for Data table data type for all the columns
export interface Props {
  children?: FC;
  item?: AccountEventName;
}

interface AccountEventName {
  failureReason?: string;
}
const OrderFailureRason: FC<Props> = ({
  item = {
    failureReason: '',
  },
}) => {
  const { failureReason } = item;
  return (
    <DataTableCell title={failureReason || ''}>
      <p style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{failureReason}</p>
    </DataTableCell>
  );
};

/** Custom Account/Event Name cell */
export default OrderFailureRason;
