/**
 * Graphql mutation to edit comment
 * @module src/graphql/editComment
 */
import gql from 'graphql-tag';

/** editing comment */
const CREATE_UPDATE_CYCLE_COUNT_COMMENT = gql`
  mutation createUpdateCycleCountComment(
    $externalId: String
    $sfid: String
    $cycleCountLineExternalId: String
    $cycleCountLineId: String
    $caseExternalId: String
    $caseId: String
    $comment: String
    $postTime: String
    $commentType: String
    $objectType: String
    $screen: String
    $type: String
  ) {
    createUpdateCycleCountComment(
      externalId: $externalId
      sfid: $sfid
      cycleCountLineExternalId: $cycleCountLineExternalId
      cycleCountLineId: $cycleCountLineId
      caseExternalId: $caseExternalId
      caseId: $caseId
      comment: $comment
      postTime: $postTime
      commentType: $commentType
      objectType: $objectType
      screen: $screen
      type: $type
    ) {
      message
    }
  }
`;

export default CREATE_UPDATE_CYCLE_COUNT_COMMENT;
