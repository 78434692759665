/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable */
/*  eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Button from '@salesforce/design-system-react/components/button';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import moment from 'moment';
import EventStatusBadge from '../../Shared/EventStatusBadge';
import { LIMIT, NEXT_DATA, PREV_DATA, holdTypes } from '../../../util/constants';
import './index.scss';
import InventoryReturnFilter from './InventoryReturnFilter';
import InventoryReturn from './InventoryReturn';
import { GET_FORM_CONFIGURATIONS } from '../../../graphql/getFormConfigurations';
import { GET_ORDER_LIST } from '../../../graphql/getOrderList';
import { GET_INVENTORY_RETURN_LIST } from '../../../graphql/getInventoryReturnsList';
import CancelEventModal from '../../CreateEditSurgeryEvent/CancelEventCreationModal';
import debounced from '../../../util/debounced';
import FilterTags from '../../Shared/FilterTags';
import ReturnDataTable from './ReturnDataTable';
import ReturnHeader from './ReturnHeader';
import {
  setInventoryReturnsFilters,
  getInventoryReturnsFilters,
} from '../../../store/ducks/inventoryReturnsFilters';
import { getInventoryFilters } from '../../../store/ducks/inventoryFilters';
import { setHoldBannerStatus } from '../../../store/ducks/holdBanner';
import { GET_USER_HOLD } from '../../../graphql/getUserHold';
import { getUserInfo } from '../../../store/ducks/userInfo';

export interface Item {
  orderId: string;
  externalId: string;
  orderExternalId: string;
  salesRepId: string;
  assignedOPSId: string;
  status: string;
  type: string;
  createdDate: string;
  orderNumber: string;
  effectiveDate: string;
  fromSubInventoryCode: string;
  toSubInventoryCode: string;
  recordTypeId: string;
  priceBook2Id: string;
  salesOrganization: string;
  accountId: string;
  accountNumber: string;
  accountName: string;
  salesRepName: string;
  assignedOPSName: string;
  visualIndicator: string;
  shipmentDate: string;
  shippingMethod: string;
  trackingNumber: string;
  returnReason: string;
  returnExpiryDate: string;
  comments: [];
  lineItems: any;
}
interface DynamicValue {
  [key: string]: any;
}
interface SelectedData {
  id: string;
  label: string;
  __typename?: string;
}
interface FilterPreset {
  filterValues: Filter;
  filterExternalId?: string;
  filterName?: string;
}
interface Filter {
  search?: string;
  status: SelectedData[];
  submitToDate: Date | string;
  submitFromDate: Date | string;
}
interface Filters {
  search?: string;
  status?: string[];
  submitToDate?: Date | string;
  submitFromDate?: Date | string;
}
interface Props extends RouteComponentProps {
  history: any;
}

const InventoryReturns: FC<Props> = ({ history }) => {
  const { pathname } = history.location;
  const dispatch = useDispatch();
  const [sortColumn, setSortColumn] = useState<any>();
  const userInfo = useSelector(getUserInfo);

  const statusUrl = pathname.substr(pathname.lastIndexOf('/') + 1, pathname.length - 1);
  // const [status, setStatus] = useState('RETURNS')
  const [status, setStatus] = useState(
    ['RETURNS', 'COMPLETED', 'FAILED'].indexOf(statusUrl.toUpperCase()) >= 0
      ? statusUrl.toUpperCase()
      : 'RETURNS'
  );
  const [openReturnCompletedFilter, setOpenReturnCompletedFilter] = useState(false);
  const [canceModalVisible, setCanceModalVisible] = useState(false);
  const [openReturnFilter, setOpenReturnFilter] = useState(false);
  const [openNewReturnModal, setOpenNewReturnModal] = useState(false);
  const [openNewPickupReturnModal, setOpenNewPickupReturnModal] = useState(false);
  const [returnsType, setReturnsType] = useState('');
  const [updatedSelectedFilter, setUpdatedSelectedFilter] = useState<
    FilterPreset | DynamicValue | null
  >(null);
  const [selectedFilter, setSelectedFilter] = useState<FilterPreset | DynamicValue | null>(null);
  const [returnItems, setReturnItems] = useState<any[] | null>();
  const [returnCompletedItems, setReturnCompletedItems] = useState<any[] | null>();
  const [returnFailedItems, setReturnFailedItems] = useState<any[] | null>();
  const [
    getInventoryReturnListItems,
    { data: inventoryReturnItems, loading: loadingInventoryReturnItems },
  ] = useLazyQuery(GET_INVENTORY_RETURN_LIST, { fetchPolicy: 'no-cache' });

  const [
    getInventoryReturnListCompletedItems,
    { data: inventoryReturnCompletedItems, loading: loadingInventoryReturnCompletedItems },
  ] = useLazyQuery(GET_INVENTORY_RETURN_LIST, { fetchPolicy: 'network-only' });

  const [getFormConfigurations, { data }] = useLazyQuery(GET_FORM_CONFIGURATIONS, {
    fetchPolicy: 'network-only',
  });

  const inventoryReturnListData = inventoryReturnItems?.getInventoryReturnList;
  const [offsetFailed, setOffsetFailed] = useState(0);
  const [offsetReturns, setOffsetReturns] = useState(0);
  const [offsetCompleted, setOffsetCompleted] = useState(0);

  const { data: holddata } = useQuery(GET_USER_HOLD, {
    fetchPolicy: 'no-cache',
    variables: {
      id: userInfo?.id,
    },
  });
  useEffect(() => {
    dispatch(setHoldBannerStatus(false));
  }, [dispatch]);
  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);

  const [
    getInventoryRequestListFailedItems,
    { data: inventoryRequestFailedItems, loading: loadingInventoryRequestFailedItems },
  ] = useLazyQuery(GET_ORDER_LIST, { fetchPolicy: 'no-cache' });
  const inventoryReturnFailedListData = inventoryRequestFailedItems?.getOrderList;

  const [
    getInventoryRequestListProgressItems,
    { data: inventoryRequestProgressItems, loading: loadingInventoryRequestProgressItems },
  ] = useLazyQuery(GET_ORDER_LIST, { fetchPolicy: 'no-cache' });
  const inventoryReturnCompletedListData = inventoryRequestProgressItems?.getOrderList;

  useEffect(() => {
    setOffsetReturns(0);
    setOffsetCompleted(0);
    if (status === 'FAILED') {
      getInventoryRequestListFailedItems({
        variables: {
          offset: 0,
          limit: LIMIT,
          orderType: 'INVENTORY_RETURN',
          screenType: status,
          ...(selectedFilter && { filters: selectedFilter }),
        },
      });
    } else if (status === 'COMPLETED') {
      getInventoryRequestListProgressItems({
        variables: {
          offset: 0,
          limit: LIMIT,
          orderType: 'INVENTORY_RETURN',
          screenType: 'PROCESSED',
          ...(selectedFilter && { filters: selectedFilter }),
        },
      });
    } else {
      getInventoryReturnListItems({
        variables: {
          offset: 0,
          limit: LIMIT,
          type: status,
        },
      });
    }
  }, [
    getInventoryReturnListItems,
    getInventoryRequestListFailedItems,
    getInventoryRequestListProgressItems,
    status,
  ]);

  useEffect(() => {
    if (inventoryReturnItems?.getInventoryReturnList) {
      const list = getFormattedList(inventoryReturnItems.getInventoryReturnList);
      setReturnItems(list);
    }
  }, [inventoryReturnItems]);
  useEffect(() => {
    if (inventoryRequestProgressItems?.getOrderList) {
      const list = getFormattedList(inventoryRequestProgressItems?.getOrderList);
      setReturnCompletedItems(list);
    }
  }, [inventoryRequestProgressItems]);

  useEffect(() => {
    if (inventoryRequestFailedItems?.getOrderList) {
      const list = getFormattedList(inventoryRequestFailedItems?.getOrderList);
      setReturnFailedItems(list);
    }
  }, [inventoryRequestFailedItems]);

  const filterStore = useSelector(getInventoryReturnsFilters);

  useEffect(() => {
    let setFilters = JSON.parse(filterStore || '{}');

    if (setFilters && setFilters.filterValues) {
      setUpdatedSelectedFilter(setFilters);
      setSelectedFilter(setFilters);
      const filters = getFilterApiValue(setFilters.filterValues);
      delete filters.search;
      handleRefetchItems(filters);
    }
  }, [status]);

  const routeToInventoryReturnDetail = (externalId: string): void => {
    history.push('/inventoryReturnDetails', {
      externalId,
    });
  };

  const handleCompletedTab = (e: any): void => {
    setSelectedFilter(null);
    setUpdatedSelectedFilter(null);
    if (e === 0) {
      setStatus('RETURNS');
      history.push('/inventory/Returns/return');
    } else if (e === 1) {
      setStatus('FAILED');
      history.push('/inventory/Returns/failed');
    } else if (e === 2) {
      setStatus('COMPLETED');
      history.push('/inventory/Returns/completed');
    }
  };

  const sortedcolumn = (property: any): void => {
    setSortColumn(property);
  };

  const handlePaginationButtonClicked = (type: string): void => {
    let finalOffset;
    if (status === 'RETURNS') {
      if (type === NEXT_DATA) {
        finalOffset = offsetReturns + LIMIT;
      } else {
        finalOffset = offsetReturns - LIMIT;
      }
      if (sortColumn) {
        const sortProperty = sortColumn.property;
        const { sortDirection } = sortColumn;
        getInventoryReturnListItems({
          variables: {
            limit: LIMIT,
            orderBy: sortProperty,
            orderSortType: sortDirection,
            offset: finalOffset,
            type: status,
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      } else {
        getInventoryReturnListItems({
          variables: {
            offset: finalOffset,
            limit: LIMIT,
            type: status,
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      }
      setOffsetReturns(finalOffset);
    } else if (status === 'COMPLETED') {
      if (type === NEXT_DATA) {
        finalOffset = offsetCompleted + LIMIT;
      } else {
        finalOffset = offsetCompleted - LIMIT;
      }

      if (sortColumn) {
        const sortProperty = sortColumn.property;
        const { sortDirection } = sortColumn;
        getInventoryRequestListProgressItems({
          variables: {
            limit: LIMIT,
            orderBy: sortProperty,
            orderSortType: sortDirection,
            offset: finalOffset,
            orderType: 'INVENTORY_RETURN',
            screenType: 'PROCESSED',
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      } else {
        getInventoryRequestListProgressItems({
          variables: {
            offset: finalOffset,
            limit: LIMIT,
            orderType: 'INVENTORY_RETURN',
            screenType: 'PROCESSED',
            // ...(selectedFilter && { filters: selectedFilter }),
          },
        });
      }
      setOffsetCompleted(finalOffset);
    } else if (status === 'FAILED') {
      if (type === NEXT_DATA) {
        finalOffset = offsetFailed + LIMIT;
      } else {
        finalOffset = offsetFailed - LIMIT;
      }

      if (sortColumn) {
        const sortProperty = sortColumn.property;
        const { sortDirection } = sortColumn;
        getInventoryRequestListFailedItems({
          variables: {
            limit: LIMIT,
            orderBy: sortProperty,
            orderSortType: sortDirection,
            offset: finalOffset,
            orderType: 'INVENTORY_RETURN',
            screenType: status,
            ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
          },
        });
      } else {
        getInventoryRequestListFailedItems({
          variables: {
            offset: finalOffset,
            limit: LIMIT,
            orderType: 'INVENTORY_RETURN',
            screenType: status,
            // ...(selectedFilter && { filters: selectedFilter }),
          },
        });
      }
      setOffsetCompleted(finalOffset);
    }
  };

  const handleReturnsFilter = (): void => {
    setSelectedFilter(updatedSelectedFilter);
    setOpenReturnFilter(!openReturnFilter);
  };

  const handleReturnCompletedFilter = (): void => {
    setOpenReturnCompletedFilter(!openReturnCompletedFilter);
  };

  const handleRequestCompletedFilterModal = (): void => {
    setOpenReturnCompletedFilter(!openReturnCompletedFilter);
  };

  const handleFilterModal = (): void => {
    setOpenReturnFilter(!openReturnFilter);
  };

  const handleRefetchItems = (filterData: Filters | null): void => {
    setOffsetReturns(0);
    setOffsetCompleted(0);
    if (status === 'FAILED') {
      getInventoryRequestListFailedItems({
        variables: {
          offset: 0,
          limit: LIMIT,
          orderType: 'INVENTORY_RETURN',
          screenType: status,
          ...(filterData && { filters: filterData }),
        },
      });
    } else if (status === 'COMPLETED') {
      getInventoryRequestListProgressItems({
        variables: {
          offset: 0,
          limit: LIMIT,
          orderType: 'INVENTORY_RETURN',
          screenType: 'PROCESSED',
          ...(filterData && { filters: filterData }),
        },
      });
    } else {
      getInventoryReturnListItems({
        variables: {
          offset: 0,
          limit: LIMIT,
          type: status,
          ...(filterData && { filters: filterData }),
        },
      });
    }
  };

  const getFilterApiValue = (filterValues: DynamicValue): Filters => {
    let reducedFilterValues = {};

    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          const values = filterValues[key].map((item: SelectedData) => item.id);

          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (Array.isArray(filterValues[key]) && filterValues[key]) {
          if (filterValues[key].length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  const handleFilterRequest = (
    filterValues: Filter | DynamicValue | null,
    filterName?: string
  ): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setUpdatedSelectedFilter(filter);
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      // setSelectedFilterApiValues(filters);
      delete filters.search;
      handleRefetchItems(filters);
      dispatch(setInventoryReturnsFilters(JSON.stringify(filter)));
    } else {
      // setSelectedFilterApiValues(null);
      handleRefetchItems(null);
    }
  };

  const handleNewReturn = (): void => {
    setOpenNewReturnModal(!openNewReturnModal);
  };

  const handleNewReturnsActionDropdownSelect = (value: any): void => {
    setReturnsType(value.label);
    setOpenNewReturnModal(!openNewReturnModal);
  };

  const handleCancelButton = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleCancelModal = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleCancelModalConfirmPress = (): void => {
    setCanceModalVisible(!canceModalVisible);
    handleNewReturn();
  };

  const setReturnSearch = (searchedItem: string): void => {
    setOffsetReturns(0);
    setOffsetCompleted(0);
    if (searchedItem.length >= 3) {
      if (status === 'FAILED') {
        getInventoryRequestListFailedItems({
          variables: {
            offset: 0,
            limit: LIMIT,
            orderType: 'INVENTORY_RETURN',
            screenType: status,
            filters: {
              productSearch: searchedItem,
            },
          },
        });
      } else if (status === 'COMPLETED') {
        getInventoryRequestListProgressItems({
          variables: {
            offset: 0,
            limit: LIMIT,
            orderType: 'INVENTORY_RETURN',
            screenType: 'PROCESSED',
            filters: {
              productSearch: searchedItem,
            },
          },
        });
      } else {
        getInventoryReturnListItems({
          variables: {
            offset: 0,
            limit: LIMIT,
            type: status,
            filters: {
              productSearch: searchedItem,
            },
          },
        });
      }
    } else {
      if (status === 'FAILED') {
        getInventoryRequestListFailedItems({
          variables: {
            offset: 0,
            limit: LIMIT,
            orderType: 'INVENTORY_RETURN',
            screenType: status,
          },
        });
      } else if (status === 'COMPLETED') {
        getInventoryRequestListProgressItems({
          variables: {
            offset: 0,
            limit: LIMIT,
            orderType: 'INVENTORY_RETURN',
            screenType: 'PROCESSED',
          },
        });
      } else {
        getInventoryReturnListItems({
          variables: {
            offset: 0,
            limit: LIMIT,
            type: status,
          },
        });
      }
    }
  };

  const debouncedFetchReturnItems = useCallback(debounced(500, setReturnSearch), [
    debounced,
    setReturnSearch,
  ]);

  const handleSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      if (value && value.length >= 3) {
        debouncedFetchReturnItems(value);
      } else {
        debouncedFetchReturnItems(value);
      }
    },
    [debouncedFetchReturnItems]
  );
  const getFormattedList = useCallback((items: any[]): any[] => {
    if (items && items.length > 0) {
      const list = items.map((item: any) => {
        return {
          ...item,
          createdDate: item.createdDate ? moment(item.createdDate).format('L') : null,
          returnExpiryDate: item.returnExpiryDate
            ? moment(item.returnExpiryDate).format('L')
            : null,
          account: `${item.accountNumber} - ${item.accountName}`,
        };
      });
      return list;
    }
    return [];
  }, []);
  let offsetData = offsetReturns;
  if (status === 'RETURNS') {
    offsetData = offsetReturns;
  } else if (status === 'COMPLETED') {
    offsetData = offsetCompleted;
  } else if (status === 'FAILED') {
    offsetData = offsetFailed;
  }
  return (
    <IconSettings iconPath="/icons">
      <ReturnHeader
        handleSearch={handleSearch}
        handleReturnsFilter={handleReturnsFilter}
        handleNewReturnsActionDropdownSelect={handleNewReturnsActionDropdownSelect}
      />
      {selectedFilter && selectedFilter.filterValues && (
        <FilterTags selectedFilter={selectedFilter} handleFilter={handleFilterRequest} />
      )}
      <Tabs
        variant="scoped"
        id="inventory-tabs-scoped"
        selectedIndex={status === 'COMPLETED' ? 2 : status === 'FAILED' ? 1 : 0}
        onSelect={(e: any): void => handleCompletedTab(e)}
      >
        <TabsPanel className={true ? 'active' : 'inactive'} label="Open">
          {loadingInventoryReturnItems && (
            <Spinner
              size="large"
              variant="base"
              assistiveText={{ label: 'Loading...' }}
              key="spinner"
            />
          )}{' '}
          {returnItems && returnItems?.length > 0 ? (
            <ReturnDataTable
              data={returnItems || []}
              offsetData={offsetData}
              type="Returns"
              sortOrderList={getInventoryReturnListItems}
              sortedcolumn={sortedcolumn}
              filters={getFilterApiValue(selectedFilter?.filterValues)}
            />
          ) : (
            <div className="no-data-found-container">
              <h1 className="no-data-found-text">No Records Found</h1>
            </div>
          )}
        </TabsPanel>
        <TabsPanel label="Failed">
          {loadingInventoryRequestFailedItems && (
            <Spinner
              size="large"
              variant="base"
              assistiveText={{ label: 'Loading...' }}
              key="spinner"
            />
          )}{' '}
          {returnFailedItems && returnFailedItems.length > 0 ? (
            <ReturnDataTable
              data={returnFailedItems || []}
              offsetData={offsetData}
              type="Failed"
              sortOrderList={getInventoryRequestListFailedItems}
              sortedcolumn={sortedcolumn}
              filters={getFilterApiValue(selectedFilter?.filterValues)}
            />
          ) : (
            <div className="no-data-found-container">
              <h1 className="no-data-found-text">No Records Found</h1>
            </div>
          )}
        </TabsPanel>
        <TabsPanel label="Processed">
          {loadingInventoryRequestProgressItems && (
            <Spinner
              size="large"
              variant="base"
              assistiveText={{ label: 'Loading...' }}
              key="spinner"
            />
          )}{' '}
          {returnCompletedItems && returnCompletedItems.length > 0 ? (
            <ReturnDataTable
              data={returnCompletedItems || []}
              offsetData={offsetData}
              type="Completed"
              sortOrderList={getInventoryRequestListProgressItems}
              sortedcolumn={sortedcolumn}
              filters={getFilterApiValue(selectedFilter?.filterValues)}
            />
          ) : (
            <div className="no-data-found-container">
              <h1 className="no-data-found-text">No Records Found</h1>
            </div>
          )}
        </TabsPanel>
      </Tabs>
      <div>
        {status === 'FAILED' && (
          <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronleft"
                iconSize="small"
                disabled={!(offsetFailed > 0)}
                iconVariant="bare"
                onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
                variant="icon"
              />
            </div>
            <div className="slds-col slds-p-left_medium slds-p-right_medium">
              <span>{`${offsetFailed + 1} - ${offsetFailed + LIMIT}`}</span>
            </div>
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronright"
                iconSize="small"
                iconVariant="bare"
                disabled={returnFailedItems && returnFailedItems?.length < 50}
                onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
                variant="icon"
              />
            </div>
          </div>
        )}
        {status === 'RETURNS' && (
          <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronleft"
                iconSize="small"
                disabled={!(offsetReturns > 0)}
                iconVariant="bare"
                onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
                variant="icon"
              />
            </div>
            <div className="slds-col slds-p-left_medium slds-p-right_medium">
              <span>{`${offsetReturns + 1} - ${offsetReturns + LIMIT}`}</span>
            </div>
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronright"
                iconSize="small"
                iconVariant="bare"
                disabled={returnItems && returnItems?.length < 50}
                onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
                variant="icon"
              />
            </div>
          </div>
        )}
        {status === 'COMPLETED' && (
          <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronleft"
                iconSize="small"
                disabled={!(offsetCompleted > 0)}
                iconVariant="bare"
                onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
                variant="icon"
              />
            </div>
            <div className="slds-col slds-p-left_medium slds-p-right_medium">
              <span>{`${offsetCompleted + 1} - ${offsetCompleted + LIMIT}`}</span>
            </div>
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronright"
                iconSize="small"
                iconVariant="bare"
                disabled={returnCompletedItems && returnCompletedItems?.length < 50}
                onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
                variant="icon"
              />
            </div>
          </div>
        )}
        {openReturnFilter && (
          <InventoryReturnFilter
            type={status}
            selectedFilter={selectedFilter}
            handleFilterRequest={handleFilterRequest}
            isOpen={openReturnFilter}
            toggleOpen={handleFilterModal}
            isRequest={false}
          />
        )}
        {openReturnCompletedFilter && (
          <InventoryReturnFilter
            type={status}
            selectedFilter={selectedFilter}
            handleFilterRequest={handleFilterRequest}
            isOpen={openReturnCompletedFilter}
            toggleOpen={handleRequestCompletedFilterModal}
            isRequest={false}
          />
        )}
        {openNewReturnModal && (
          <InventoryReturn
            isOpen={openNewReturnModal}
            toggleOpen={handleNewReturn}
            formData={data}
            eventType="Return Inventory"
            returnsType={returnsType}
            handleCancelButton={handleCancelButton}
          />
        )}
        {canceModalVisible && (
          <CancelEventModal
            isReturn
            canceModalVisible={canceModalVisible}
            handleCancelModal={handleCancelModal}
            handleCancelModalConfirmPress={handleCancelModalConfirmPress}
          />
        )}
      </div>
    </IconSettings>
  );
};

export default withRouter(InventoryReturns);
