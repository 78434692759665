/*
 * Get Surgeons GraphQL specification
 * @module src/graphql/getSurgeons
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_SURGEONS = gql`
  query getSurgeons($accountId: String, $filters: SurgeonFilters) {
    getSurgeons(accountId: $accountId, filters: $filters) {
      id
      value
      hospitalId
      surgeonERPCode
    }
  }
`;
