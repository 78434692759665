import gql from 'graphql-tag';

const GET_LINE_ITEMS = gql`
  query getLineItemsForInventoryRequests($externalIds: [String]!) {
    getLineItemsForInventoryRequests(externalIds: $externalIds) {
      caseId
      caseExternalId
      usageId
      usageExternalId
      productId
      productNumber
      quantity
      status
      workflowAction
      productdescription
      cost
      linevisualindicator
    }
  }
`;
export default GET_LINE_ITEMS;
