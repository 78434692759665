/*
 * Get SubInventory GraphQL specification
 * @module src/graphql/getSubInventory
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export interface SubInventory {
  params: { subInventoryName: string; productNumber: string };
  subInventoryName: string;
  productNumber: string;
  productDescription: string;
  availableqty: string;
  onhandqty: string;
  isonhold: boolean;
  recalled: boolean;
  expired: boolean;
  data: string;
  expirationDate: string;
  isexpirewithin30days: boolean;
}

/** SubInventory Items GraphQL data interface */
export interface SubInventoryData {
  subInventoryName: SubInventoryData;
  subInventories: SubInventory[];
}

export interface Filters {
  ownership?: string;
  status?: string[];
  searchText?: string;
  subInventoryName?: string;
  productNumber?: string;
  expirationFromDate?: Date | string;
  expirationToDate?: Date | string;
  productGroup?: string;
}
export const GET_SUB_INVENTORY = gql`
  query getSubInventoryList(
    $limit: Int
    $offset: Int
    $salesRepId: String
    $businessUnit: [String]
    $filters: SubInventoryListFilters
  ) {
    getSubInventoryList(
      limit: $limit
      offset: $offset
      salesRepId: $salesRepId
      businessUnit: $businessUnit
      filters: $filters
    ) {
      subInventoryName
      productNumber
      productDescription
      availableqty
      onhandqty
      isonhold
      isexpirewithin30days
    }
  }
`;
