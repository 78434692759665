/*
 * Event Details GraphQL specification
 * @module src/graphql/workflowPermissions
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  List View Configurations Query */
export const GET_DETAILS_VIEW_CONFIGURATIONS = gql`
  query getDetailsViewConfigurations {
    getDetailsViewConfigurations {
      billOnly {
        headers {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
        parts_tab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
        replenishment_tab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
          __typename
        }
        pricing_tab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
        usageSheet_tab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
        priceDetail_tab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
      }
      shipAndBill {
        headers {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
        parts_tab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
        pricing_tab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
        usageSheet_tab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
        shipping_tab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
        priceDetail_tab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
      }
      inventoryRequest {
        headers {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
        inventory_tab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
        shipping_tab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
      }
      StandardReturnDetail {
        headers {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
        ReceivedTab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
        ReturningTab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
      }
      mockor {
        headers {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
      }
      inventoryRequest {
        headers {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
        inventory_tab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
        shipping_tab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
      }
      pickupReturnDetail {
        headers {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
        ReceivedTab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
        ReturningTab {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
      }
      customerTransfer {
        headers {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
      }
      repToRepTransfer {
        headers {
          Id
          screenName
          screenCode
          moduleName
          moduleCode
          label
          mappingKey
          property
          isShow
          isRequired
          isEditable
          description
          businessunitName
          businessunitCode
          header
        }
      }
    }
  }
`;
