/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useEffect, useState, useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Button from '@salesforce/design-system-react/components/button';
import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Spinner from '@salesforce/design-system-react/components/spinner';
import InventoryRequestIdCell from './DataTableCells/InventoryRequestIdCell';
import InventoryRequestStatusCell from './DataTableCells/InventoryStatusCell';
import InventoryRequestDateTImeCell from './DataTableCells/InventoryDateTImeCell';
import ResetCell from './DataTableCells/ResetCell';
import './index.scss';
import InventoryQueueHeader from './InventoryQueueHeader';
import FilterTagReviewRequest from '../../Shared/FilterTagReviewRequest';
import { LIMIT, NEXT_DATA, PREV_DATA, NOTIFY_TYPE } from '../../../util/constants';
import debounced from '../../../util/debounced';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import SnackBar from '../../Shared/SnackBar';
import InventoryAssignReturnListRepCoveringCell from './DataTableCells/InventoryAssignReturnListRepCoveringCell';
import InventoryReturnTypeCell from './DataTableCells/InventoryReturnTypeCell';
import { GET_INVENTORY_RETURN_QUEUE_LIST } from '../../../graphql/getInventoryReturnQueueList';
import ASSIGN_INVENTORY_REQUEST from '../../../graphql/mutations/assignInventoryRequest';
import InventoryReturnAssignDataCell from './DataTableCells/InventoryAssignDataCell';

interface Props {
  action: string;
}
interface Sort {
  sortColumn: string;
  sortColumnDirection: {
    [key: string]: string;
  };
}

// CustomDataTableCell must have the same displayName as DataTableCell or it will be ignored.
InventoryRequestIdCell.displayName = DataTableCell.displayName;
InventoryReturnTypeCell.displayName = DataTableCell.displayName;
InventoryRequestStatusCell.displayName = DataTableCell.displayName;
InventoryReturnAssignDataCell.displayName = DataTableCell.displayName;
InventoryRequestDateTImeCell.displayName = DataTableCell.displayName;
ResetCell.displayName = DataTableCell.displayName;

const InventoryReturnQueue: FC<Props> = ({ action }) => {
  const [getQueueList, { data: inventoryQueueData, refetch }] = useLazyQuery(
    GET_INVENTORY_RETURN_QUEUE_LIST,
    {
      fetchPolicy: 'no-cache',
    }
  );
  // const [refetch1, setRefetch] = useState(false);
  const [inventoryQueueList, setState] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [offsetReturnReview, setOffsetReturnReview] = useState(0);
  const [offsetReturnAssign, setOffsetReturnAssign] = useState(0);
  const { open, notification, openSnackBar } = useSnackBar();
  const [assignToOps, { data: assignRespone }] = useMutation(ASSIGN_INVENTORY_REQUEST);

  const [appliedFilters, setAppliedFilters] = useState<object | null>(null);
  const [sortObj, setSortObj] = useState<Sort>();

  useEffect(() => {
    if (assignRespone && assignRespone.assignInventoryRequest.message === NOTIFY_TYPE.SUCCESS) {
      openSnackBar(
        NOTIFY_TYPE.SUCCESS,
        isReset ? 'Reset Assignment Successfully' : 'Inventory Request Assigned Successfully'
      );
      refetch();
    }
  }, [assignRespone, openSnackBar, refetch, isReset]);

  const handleSort = (sortColumn: any): void => {
    const sortProperty = sortColumn.property;
    const { sortDirection } = sortColumn;
    const sort = {
      sortColumn: sortProperty,
      sortColumnDirection: {
        [sortProperty]: sortDirection,
      },
    };
    // needs to work in both directions
    const list =
      inventoryQueueList &&
      inventoryQueueList.sort((a: any, b: any) => {
        // if (sortProperty === 'totalAmount') {
        //   // Number Compare
        //   if (sortDirection === 'desc') {
        //     return Number(b[sortProperty]) - Number(a[sortProperty]);
        //   }
        //   return Number(a[sortProperty]) - Number(b[sortProperty]);
        // }
        // if (sortProperty === 'createdDate' || sortProperty === 'returnExpiryDate') {
        //   // Date Compare
        //   if (sortDirection === 'desc') {
        //     return Number(new Date(b[sortProperty])) - Number(new Date(a[sortProperty]));
        //   }
        //   return Number(new Date(a[sortProperty])) - Number(new Date(b[sortProperty]));
        // }
        // String Compare
        let val = 0;
        if (a[sortProperty] === null) {
          if (b[sortProperty] < '') {
            val = -1;
          }
          if (b[sortProperty] > '') {
            val = 1;
          }
          if (sortDirection === 'desc') {
            val *= -1;
          }
        } else if (b[sortProperty] === null) {
          if (a[sortProperty] > '') {
            val = -1;
          }
          if (a[sortProperty] < '') {
            val = 1;
          }
          if (sortDirection === 'desc') {
            val *= -1;
          }
        } else {
          if (a[sortProperty] > b[sortProperty]) {
            val = 1;
          }
          if (a[sortProperty] < b[sortProperty]) {
            val = -1;
          }
          if (sortDirection === 'desc') {
            val *= -1;
          }
        }
        return val;
      });
    setSortObj(sort);
    // sortOrderList(list);
  };

  useEffect(() => {
    // console.log('clicked')
    getQueueList({
      variables: {
        limit: LIMIT,
        offset: 0,
        type: action.toUpperCase(),
        // filters: appliedFilters || undefined,
      },
    });
    setAppliedFilters(null);
  }, [action, getQueueList, setAppliedFilters]);

  useEffect(() => {
    if (inventoryQueueData && inventoryQueueData.getReviewInventoryReturnList) {
      setState(inventoryQueueData.getReviewInventoryReturnList);
    }
  }, [inventoryQueueData]);

  // const refetch = (): void => {
  //   setRefetch(!refetch1)
  // };

  // This function fetches the next and previous records of the events list
  const handlePaginationButtonClicked = (type: string): void => {
    let finalOffset;
    if (action.toUpperCase() === 'REVIEW') {
      if (type === NEXT_DATA) {
        finalOffset = offsetReturnReview + LIMIT;
      } else {
        finalOffset = offsetReturnReview - LIMIT;
      }
      const filters = appliedFilters || undefined;
      getQueueList({
        variables: {
          limit: LIMIT,
          offset: finalOffset,
          type: action.toUpperCase(),
          filters,
        },
      });
      setOffsetReturnReview(finalOffset);
    } else if (action.toUpperCase() === 'ASSIGN') {
      if (type === NEXT_DATA) {
        finalOffset = offsetReturnReview + LIMIT;
      } else {
        finalOffset = offsetReturnReview - LIMIT;
      }
      const filters = appliedFilters || undefined;
      getQueueList({
        variables: {
          limit: LIMIT,
          offset: finalOffset,
          type: action.toUpperCase(),
          filters,
        },
      });
      setOffsetReturnAssign(finalOffset);
    }
  };

  const getFilterApiValue = (filterValues: any): any => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          const values = filterValues[key].map((item: any) => {
            if (item.id) {
              return item.id;
            }
            return item;
          });
          if (values && values.length > 0) {
            if (key === 'salesRep' || key === 'assignedTo' || key === 'territory') {
              reducedFilterValues = { ...reducedFilterValues, [key]: values[0] };
            } else {
              reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
            }
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  const applyFilters = (appliedFilter: object | null): void => {
    const filters = getFilterApiValue(appliedFilter);
    setAppliedFilters(filters);
    setOffsetReturnAssign(0);
    setOffsetReturnReview(0);
    getQueueList({
      variables: {
        limit: LIMIT,
        offset: 0,
        type: action.toUpperCase(),
        filters,
      },
    });
  };

  // const handleFilterOrders = (filterValues: DynamicValue | null, filterName?: string): void => {
  //   const updatedFilterName = filterName || '';
  //   const filter = {
  //     filterValues,
  //     filterName: updatedFilterName,
  //   };
  //   setSelectedFilter(filter);
  //   if (filterValues) {
  //     const filters = getFilterApiValue(filterValues);
  //     handleRefetchItems(filters);
  //   } else {
  //     resetFilters();
  //   }
  // };

  const setSearch = (searchedItem: string): void => {
    if (searchedItem.length >= 3) {
      getQueueList({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: action.toUpperCase(),
          filters: {
            searchText: searchedItem,
          },
        },
      });
    } else {
      getQueueList({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: action.toUpperCase(),
        },
      });
    }
  };

  const debouncedFetchItems = useCallback(debounced(500, setSearch), [debounced, setSearch]);

  const handleSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      if (value && value.length >= 3) {
        debouncedFetchItems(value);
      } else {
        debouncedFetchItems(value);
      }
    },
    [debouncedFetchItems]
  );

  const handleDropdownSelection = (selection: any, externalId: any): void => {
    if (selection) {
      assignToOps({
        variables: {
          externalId,
          assignedUser: selection[0].id,
        },
      });
      setIsReset(false);
    }
  };

  const handleReset = (externalId: any): void => {
    assignToOps({
      variables: {
        externalId,
        assignedUser: '',
      },
    });
    setIsReset(true);
  };

  return (
    <IconSettings iconPath="/icons">
      <InventoryQueueHeader
        label={action}
        applyFilters={applyFilters}
        handleSearch={handleSearch}
        selectedFilter={appliedFilters}
      />
      {appliedFilters && (
        <FilterTagReviewRequest selectedFilter={appliedFilters} handleFilter={applyFilters} />
      )}
      <SnackBar open={open} notification={notification} />
      <div style={{ overflow: 'auto' }}>
        {!inventoryQueueData ? (
          <Spinner
            size="large"
            variant="base"
            assistiveText={{ label: 'Loading...' }}
            key="spinner"
          />
        ) : (
          <DataTable
            items={inventoryQueueList || []}
            id="DataTableExample-2"
            className="font-12"
            // fixedHeader
            joined
            onSort={handleSort}
            assistiveText={{
              columnSort: 'sort this column',
              columnSortedAscending: 'asc',
              columnSortedDescending: 'desc',
            }}
            fixedLayout
            keyboardNavigation
          >
            {[
              <DataTableColumn
                key="submittedDate"
                label="Submit Date"
                property="submittedDate"
                isSorted={sortObj?.sortColumn === 'submittedDate'}
                sortable
              >
                <InventoryRequestDateTImeCell dateFor="submittedDate" />
              </DataTableColumn>,
              <DataTableColumn
                key="shipmentDate"
                label="Ship Date"
                property="shipmentDate"
                isSorted={sortObj?.sortColumn === 'shipmentDate'}
                sortable
              >
                <InventoryRequestDateTImeCell dateFor="shipmentDate" />
              </DataTableColumn>,
              <DataTableColumn
                key="orderNumber"
                label="Return"
                property="orderNumber"
                isSorted={sortObj?.sortColumn === 'orderNumber'}
                sortable
                // width="112px"
              >
                <InventoryRequestIdCell action={action} />
              </DataTableColumn>,
              <DataTableColumn
                key="type"
                label="Type"
                property="type"
                isSorted={sortObj?.sortColumn === 'type'}
                sortable
                // width="180px"
              >
                <InventoryReturnTypeCell />
              </DataTableColumn>,
              <DataTableColumn
                key="visualIndicator"
                label="Status"
                property="visualIndicator"
                isSorted={sortObj?.sortColumn === 'visualIndicator'}
                sortable
              >
                <InventoryRequestStatusCell />
              </DataTableColumn>,
              <DataTableColumn
                key="territory"
                label="Territory"
                property="territory"
                isSorted={sortObj?.sortColumn === 'territory'}
                sortable
                // width="240px"
              />,
              // <DataTableColumn
              //   key="trackingNumber"
              //   label="Tracking"
              //   property="trackingNumber"
              //   width="208px"
              // />,

              <DataTableColumn
                key="salesRepName"
                label="Sales Rep"
                property="salesRepName"
                isSorted={sortObj?.sortColumn === 'salesRepName'}
                sortable
                // width="208px"
              >
                <InventoryAssignReturnListRepCoveringCell showSalesRep />
              </DataTableColumn>,
              // <DataTableColumn
              //   key="shipmentDate"
              //   label="Ship Date"
              //   property="shipmentDate"
              //   width="192px"
              // >
              //   <InventoryRequestDateTImeCell dateFor="shipmentDate" />
              // </DataTableColumn>,
              <DataTableColumn
                key="assignedOPSName"
                label={action === 'Review' ? 'Assigned' : 'Assign To'}
                width="192px"
                property="assignedOPSName"
                isSorted={sortObj?.sortColumn === 'assignedOPSName'}
                sortable
              >
                <InventoryReturnAssignDataCell
                  action={action}
                  openSnackBar={openSnackBar}
                  refetch={refetch}
                />
              </DataTableColumn>,
              action.toUpperCase() === 'ASSIGN' && (
                <DataTableColumn key="assigned" label="" width="80px">
                  <ResetCell handleReset={handleReset} />
                </DataTableColumn>
              ),
            ]}
          </DataTable>
        )}
        {inventoryQueueList && inventoryQueueList.length > 0 && action.toUpperCase() === 'ASSIGN' && (
          <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronleft"
                iconSize="small"
                disabled={!(offsetReturnAssign > 0)}
                iconVariant="bare"
                // will fetch the previous events data
                onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
                variant="icon"
              />
            </div>
            <div className="slds-col slds-p-left_medium slds-p-right_medium">
              <span>{`${offsetReturnAssign + 1} - ${offsetReturnAssign + LIMIT}`}</span>
            </div>
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronright"
                iconSize="small"
                iconVariant="bare"
                disabled={inventoryQueueList && inventoryQueueList.length < 50}
                // will fetch the next events data
                onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
                variant="icon"
              />
            </div>
          </div>
        )}
        {inventoryQueueList && inventoryQueueList.length > 0 && action.toUpperCase() === 'REVIEW' && (
          <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronleft"
                iconSize="small"
                disabled={!(offsetReturnReview > 0)}
                iconVariant="bare"
                // will fetch the previous events data
                onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
                variant="icon"
              />
            </div>
            <div className="slds-col slds-p-left_medium slds-p-right_medium">
              <span>{`${offsetReturnReview + 1} - ${offsetReturnReview + LIMIT}`}</span>
            </div>
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronright"
                iconSize="small"
                iconVariant="bare"
                disabled={inventoryQueueList && inventoryQueueList.length < 50}
                // will fetch the next events data
                onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
                variant="icon"
              />
            </div>
          </div>
        )}
      </div>
    </IconSettings>
  );
};

export default InventoryReturnQueue;
