/**
 * Graphql mutation to cancel event
 * @module src/graphql/cancelCycleCount
 */
import gql from 'graphql-tag';

/** cancel event */
const CANCEL_CYCLE_COUNT = gql`
  mutation cancelCycleCount($externalId: String!) {
    cancelCycleCount(externalId: $externalId) {
      message
    }
  }
`;

export default CANCEL_CYCLE_COUNT;
