import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_INVENTORY_REVIEW_LIST = gql`
  query getInventoryReviewList(
    $limit: Int
    $offset: Int
    $filters: ReviewInventoryRequestListFilters
  ) {
    getInventoryReviewList(limit: $limit, offset: $offset, filters: $filters) {
      caseId
      caseExternalId
      salesRepId
      assignedUserId
      assignedUserName
      serviceLevel
      shippingPostalcode
      eventId
      isApproved
      salesRep
      needByDate
      division
      eventStatus
      visualIndicator
      parentEventId
      parentEventType
      parentEventStatus
      branchId
      createdDate
      accountId
      accountNumber
      accountName
      shippingAddessLine1
      shippingAddessLine2
      shippingPostalcode
      shippingCity
      shippingState
      shippingId
      shippingAddressNumber
      type
      submittedDate
      territorynumber
      fromSubInventoryCode
      toSubInventoryCode
      thresholdQuantityIndicator
      __typename
    }
  }
`;
