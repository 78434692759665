/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/button-has-type */
import React, { FC, useState, useRef, useEffect } from 'react';
import './index.scss';
import Input from '@salesforce/design-system-react/components/input';

interface Props {
  lineItem: any;
  handleSuggestionSelect: any;
  disabled?: boolean;
}

const InputWithAutoSuggestion: FC<Props> = ({ lineItem, disabled, handleSuggestionSelect }) => {
  const [inputValue, setInputValue] = useState(lineItem.lotNumber || '');
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  // const [isDivFocused, setIsDivFocused] = useState(false);
  const divRef: any = useRef(null);
  const timeoutRef: any = useRef(null);

  useEffect(() => {
    if (lineItem.lotNumber === '') {
      setInputValue('');
    }
  }, [lineItem.lotNumber]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
    if (value.trim() === '') {
      setFilteredSuggestions([]);
    } else {
      const suggestionDetails = lineItem?.lotOptions?.filter((item: any) => {
        return item?.label?.includes(value);
      });
      setFilteredSuggestions(suggestionDetails);
    }
  };

  const handleSelect = (suggestion: any) => {
    if (suggestion !== lineItem.lotNumber) {
      handleSuggestionSelect(suggestion, lineItem);
    }
    setInputValue(suggestion?.label);
    setFilteredSuggestions([]);
  };

  const handleDivBlur = () => {
    timeoutRef.current = setTimeout(() => {
      if (inputValue !== lineItem.lotNumber) {
        const suggestionDetails = lineItem?.lotOptions?.filter((item: any) => {
          return item?.label?.includes(inputValue) && inputValue !== '';
        });
        const data: any = { label: inputValue };
        if (suggestionDetails?.length > 0 && Number(suggestionDetails[0]?.availableqty) > 0) {
          data.subInventoryCode = suggestionDetails[0]?.subInventoryCode;
          data.availableqty = suggestionDetails[0]?.availableqty;
        } else if (Number(lineItem?.availableqty)) {
          data.subInventoryCode = lineItem?.caseUsageSubInventoryCode;
          data.availableqty = lineItem?.availableqty;
        }
        handleSuggestionSelect(data, lineItem);
      }
      setFilteredSuggestions([]);
    }, 0);
  };

  /* useEffect(() => {
    const handleWindowClick = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        clearTimeout(timeoutRef.current);
        setIsDivFocused(false);
      }
    };

    window.addEventListener('click', handleWindowClick);

    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, []);*/

  const divOnFocus = () => {
    if (inputValue.trim() !== '') {
      const suggestionDetails = lineItem?.lotOptions?.filter((item: any) => {
        return item?.label?.includes(inputValue);
      });
      setFilteredSuggestions(suggestionDetails);
    } else if (lineItem?.lotOptions?.length > 0) {
      setFilteredSuggestions(lineItem?.lotOptions);
    }
  };

  const handleChildFocus = (suggestion: string) => {
    clearTimeout(timeoutRef.current);
    handleSelect(suggestion);
    setTimeout(() => {
      setFilteredSuggestions([]);
    }, 0);
  };

  return (
    <div
      className="input-container"
      ref={divRef}
      tabIndex={0}
      onBlur={handleDivBlur}
      onFocus={() => {
        divOnFocus();
      }}
    >
      <Input value={inputValue} onChange={handleInputChange} id="input-box" disabled={disabled} />
      {filteredSuggestions?.length > 0 && (
        <div>
          <ul className="suggestion-list" id="suggestion-list">
            {filteredSuggestions?.map((suggestion: any) => {
              const value = suggestion?.label;
              return (
                <>
                  <li className="slds-listbox__item" key={value}>
                    <button
                      className="slds-listbox-button"
                      onFocus={() => handleChildFocus(suggestion)}
                    >
                      <span className="slds-truncate">{value}</span>
                    </button>
                  </li>
                </>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default InputWithAutoSuggestion;
