import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Button from '@salesforce/design-system-react/components/button';
import { BrowserRouter as Router, RouteComponentProps, withRouter } from 'react-router-dom';

interface EventId {
  caseExternalId: string;
  orderId: string;
  erpOrderNumber: string;
}

// interface for props that the data table cell is getting default from data table.
export interface Props extends RouteComponentProps {
  children?: FC;
  item?: EventId;
  actionButton?: boolean;
  type: string;
}

const OrderIdCell: FC<Props> = ({
  item = {
    caseExternalId: null,
    orderId: null,
    erpOrderNumber: null,
  },
  actionButton,
  type = '',
  history,
}) => {
  const { erpOrderNumber, orderId } = item;
  const goToOrderDetails = (): void => {
    history.push('/inventoryRequestDetails', {
      isEventInventoryFlow: false,
      externalId: orderId,
      type,
    });
  };

  return (
    <Router>
      <DataTableCell title={erpOrderNumber || ''}>
        {actionButton ? (
          <Button
            style={{ background: '#FFB500', color: 'white' }}
            iconVariant="border-filled"
            onClick={goToOrderDetails}
            iconCategory="utility"
            iconName="edit"
            iconSize="small"
          />
        ) : (
          <Button label={erpOrderNumber || '--'} variant="base" onClick={goToOrderDetails} />
        )}
      </DataTableCell>
    </Router>
  );
};

export default withRouter(OrderIdCell);
