/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * React component for Single Picker.
 * @module src/components/shared/SinglePicker
 */
import React, { FC, useCallback, useEffect } from 'react';
import Combobox from '@salesforce/design-system-react/components/combobox';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { AUTO_SELECT_FIELDS } from '../../../util/constants';

//  Interface for Selected data type
export interface Selection {
  id: string;
  label: string;
  value?: string;
  type?: string;
  isPrimaryAddress?: boolean;
  __typename?: string;
}

interface SelectedData {
  selection: Selection[];
}

type SelectionType = Selection[];

interface Props {
  items: SelectionType;
  selectedItem: SelectionType;
  setSelectedItem: (items: SelectionType) => void;
  label: string;
  placeholder?: string;
  optionsAutoSelected: boolean;
  disabled: boolean;
  key?: string;
  required?: boolean;
  name: string;
}

const SinglePicker: FC<Props> = props => {
  const {
    items,
    selectedItem,
    setSelectedItem,
    label,
    placeholder,
    disabled,
    required,
    name,
    key,
    optionsAutoSelected = true,
  } = props;

  const selectedItemLength = selectedItem ? selectedItem.length : 0;

  const itemLength = items ? items.length : 0;

  const primaryAddressItem = items && items.filter((item: Selection) => item.isPrimaryAddress);

  // FOR BILLTO & SHIPTO Autoselect Primary address
  useEffect(() => {
    if (
      AUTO_SELECT_FIELDS.includes(name) &&
      selectedItemLength === 0 &&
      itemLength > 1 &&
      primaryAddressItem &&
      primaryAddressItem.length === 1
    ) {
      setSelectedItem(primaryAddressItem);
    }
  }, [itemLength, name, primaryAddressItem, selectedItemLength, setSelectedItem]);

  if (name === 'deliveryService' && selectedItemLength === 0) {
    setSelectedItem([
      { id: 'Next Day', label: 'Next Day', value: 'Next Day', __typename: 'FormFieldOption' },
    ]);
  }

  useEffect(() => {
    if (itemLength === 1 && selectedItemLength === 0 && optionsAutoSelected) {
      setSelectedItem(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = useCallback(
    (_event, data: SelectedData) => {
      const { selection } = data;
      setSelectedItem(selection);
    },
    [setSelectedItem]
  );

  const handleRemoveSelection = useCallback(
    (_event, data: SelectedData) => {
      if (!disabled) {
        const { selection } = data;
        setSelectedItem(selection);
      }
    },
    [disabled, setSelectedItem]
  );

  const optionalText = required ? '' : '';

  return (
    <IconSettings iconPath="/icons">
      <div className="slds-m-bottom_small" key={key || label}>
        <Combobox
          id={label}
          events={{
            onSelect: handleSelect,
            onRequestRemoveSelectedOption: handleRemoveSelection,
          }}
          labels={{
            label: `${label + optionalText}`,
            placeholder,
            noOptionsFound: 'No data found',
          }}
          hasInputSpinner={!items}
          options={items || []}
          singleInputDisabled={disabled}
          selection={selectedItem}
          variant="readonly"
        />
      </div>
    </IconSettings>
  );
};

/** React component for Single Picker. */
export default SinglePicker;
