/**
 * Redux file for getting/setting auth token
 * @module src/store/ducks/accessToken
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
const CLEAR_ACCESS_TOKEN = 'CLEAR_ACCESS_TOKEN';

interface SetAccessTokenActionType {
  payload: string | null;
  type: typeof SET_ACCESS_TOKEN;
}

interface ClearAccessTokenActionType {
  type: typeof CLEAR_ACCESS_TOKEN;
}

/** accessToken action type */

export type AccessTokenActionType = SetAccessTokenActionType | ClearAccessTokenActionType;

/** set accessToken action creator */

export const setAccessToken = (state: string | null): AccessTokenActionType => {
  return {
    payload: state,
    type: SET_ACCESS_TOKEN,
  };
};

/** clear accessToken action creator */

export const clearAccessToken = (): ClearAccessTokenActionType => {
  return {
    type: CLEAR_ACCESS_TOKEN,
  };
};

/** accessToken state type */

export type AccessTokenState = string | null;

/** accessToken reducer */

export default (state: AccessTokenState = null, action: ActionType): AccessTokenState => {
  switch (action.type) {
    case SET_ACCESS_TOKEN: {
      return action.payload;
    }
    case CLEAR_ACCESS_TOKEN: {
      return null;
    }
    default:
      return state;
  }
};

/** selector to get accessToken */

export const getAccessToken = (state: State): AccessTokenState => {
  return state.accessToken;
};
