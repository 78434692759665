/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Combobox from '@salesforce/design-system-react/components/combobox';
import './index.scss';
import { WORKFLOW_ACTION, WORKFLOW_ACTION_OPTIONS } from '../../../../util/constants';

interface Action {
  workflowAction: string;
  userworkflowAction?: string[];
  footerRow?: boolean;
  lineVisualIndicator?: string;
}
export interface Props {
  children?: FC;
  item?: Action;
  viewOnly: boolean;
  handleUserWorkflowAction: (item: any) => void;
  typeVal?: string;
}

const InventoryApproveRejectDataCell: FC<Props> = ({
  item = {
    workflowAction: '',
    userworkflowAction: [],
    lineVisualIndicator: '',
  },
  viewOnly,
  handleUserWorkflowAction,
  typeVal,
}) => {
  const { workflowAction, footerRow, userworkflowAction } = item;
  const [userAction, setUserAction] = useState<any>(userworkflowAction || []);
  const [options, setOptions] = useState<any>([]);
  useEffect(() => {
    const newKey = 'disabled';
    // Use map() to create a new array with the added key-value pair
    const newArray = WORKFLOW_ACTION_OPTIONS.map(obj => ({
      ...obj,
      [newKey]: viewOnly,
    }));
    setOptions(newArray);
  }, [typeVal, viewOnly]);

  useEffect(() => {
    let flowActionLabel = workflowAction;
    let flowActionValue = workflowAction;
    if (workflowAction === WORKFLOW_ACTION.ACCEPT) {
      flowActionLabel = WORKFLOW_ACTION.APPROVE;
      flowActionValue = WORKFLOW_ACTION.APPROVE;
    } else if (workflowAction === WORKFLOW_ACTION.MANUAL) {
      flowActionLabel = WORKFLOW_ACTION_OPTIONS[2].label;
      flowActionValue = WORKFLOW_ACTION_OPTIONS[2].value;
    } else if (workflowAction === WORKFLOW_ACTION.NO_ACTION) {
      flowActionLabel = '';
      flowActionValue = '';
    }
    if (userworkflowAction === null || userworkflowAction === undefined) {
      setUserAction([
        {
          label: flowActionLabel,
          value: flowActionValue,
        },
      ]);
    }
  }, [workflowAction, userworkflowAction]);

  const handleSelectDropdownItem = (data: any): void => {
    setUserAction(data.selection);
    const obj = {
      ...item,
      userworkflowAction: data.selection,
    };
    handleUserWorkflowAction(obj);
  };

  return footerRow ? (
    <DataTableCell key="-1" title="footer" />
  ) : (
    <DataTableCell key={item} title={workflowAction || ''}>
      {
        <div>
          <Combobox
            id="combobox-readonly-single"
            menuPosition="overflowBoundaryElement"
            disabled={viewOnly}
            events={{
              onSelect: (_event: any, data: any): void => handleSelectDropdownItem(data),
            }}
            labels={{
              placeholder: 'Select',
            }}
            options={options || []}
            selection={userAction}
            variant="readonly"
          />
        </div>
      }
    </DataTableCell>
  );
};

export default InventoryApproveRejectDataCell;
