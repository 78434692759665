/*
 * Get Primary Addresses GraphQL specification
 * @module src/graphql/getPrimaryAddresses
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_PRIMARY_ACCOUNT = gql`
  query getPrimaryAccount(
    $filters: AccountFilters
    $salesRepId: String
    $getAll: Boolean
    $limit: Int
  ) {
    getPrimaryAccount(filters: $filters, salesRepId: $salesRepId, getAll: $getAll, limit: $limit) {
      territory
      id
      value: name
      number
      branchId
      isRepStockAccount
      isITorIHAccount
      itHasInventory
    }
  }
`;
