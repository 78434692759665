/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React, { FC, ReactNode, useCallback, useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from '@salesforce/design-system-react/components/icon';
import { useSelector } from 'react-redux';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { useQuery } from '@apollo/react-hooks';
import CartComponent from '../InventoryRequestTable/cartComponent';
import { CYCLE_COUNT } from '../../../../util/constants';
import { getEventIcon, getSvgIcon } from '../../../../util/utilityFunctions';
import debounced from '../../../../util/debounced';
import { GET_ORDER_DETAILS } from '../../../../graphql/getOrderDetails';
import EventStatusBadge from '../../../Shared/EventStatusBadge';
import { getUserInfo } from '../../../../store/ducks/userInfo';

interface Props {
  externalId: string;
  tabConfig?: any;
}

const StyledTableRow = withStyles(() => ({
  root: {
    height: 35,
    backgroundColor: '#f4f4f4',
    color: '#000000',
    fontFamily: '"SF Pro Text", sans-serif',
  },
}))(TableRow);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: '0px 5px',
    lineHeight: 'normal',
    textAlign: 'center',
    background: 'white',
    fontFamily: '"SF Pro Text", sans-serif',
  },
}))(TableCell);

const StyledTableHeaderCell = withStyles(() => ({
  root: {
    fontWeight: 'bold',
    padding: '0px 5px',
    background: '#fafafa',
    textAlign: 'center',
    fontFamily: '"SF Pro Text", sans-serif',
  },
}))(TableCell);

const StyledDataTableRow = withStyles(() => ({
  root: {
    height: 35,
    background: 'white',
  },
}))(TableRow);

const StyledCollapsibleTableCell = withStyles(() => ({
  root: {
    padding: '0px 0px',
    fontWeight: 'bold',
  },
}))(TableCell);

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Row = (props: any) => {
  const { row, expandAll, tabConfig } = props;
  const [open, setOpen] = useState(false);

  const classes = useRowStyles();

  useEffect(() => {
    setOpen(expandAll);
  }, [expandAll]);

  const iconBackorder = getSvgIcon('backorder');

  const getUoM = (name: string, packContent: string): string => {
    let actualUOM = name;
    if (packContent && Number(packContent) > 1) {
      actualUOM += `/ ${packContent}`;
    }
    return actualUOM;
  };

  return (
    <>
      <StyledDataTableRow className={classes.root} style={{ backgroundColor: 'white' }}>
        <StyledTableCell style={{ width: '6.5%' }}>
          {row?.shippingLineItems?.length > 0 && (
            <span>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {!open ? (
                  <Icon
                    assistiveText={{ label: 'Announcement' }}
                    category="utility"
                    name="chevronright"
                    size="x-small"
                    style={{
                      fill: '#000',
                      display: 'inline-block',
                    }}
                  />
                ) : (
                  <Icon
                    assistiveText={{ label: 'Announcement' }}
                    category="utility"
                    name="chevrondown"
                    size="x-small"
                    style={{
                      fill: '#000',
                      display: 'inline-block',
                    }}
                  />
                )}
              </IconButton>
            </span>
          )}
        </StyledTableCell>
        <StyledTableCell style={{ width: '16.5%', textAlign: 'left' }}>
          {`${row?.productNumber} - ${row?.productdescription}`}
        </StyledTableCell>
        <StyledTableCell
          style={{
            width: '10%',
            fontWeight: 'bold',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
            <EventStatusBadge status={row?.partStatus} />
            {row?.backorderedQuantity && Number(row?.backorderedQuantity) > 0 && (
              <img
                src={iconBackorder}
                alt="backorder"
                style={{ width: '20px', height: '15px', marginLeft: '5px' }}
              />
            )}
          </div>
        </StyledTableCell>
        <StyledTableCell style={{ width: '8%', fontWeight: 'bold' }}>
          {row?.quantity}
        </StyledTableCell>
        {tabConfig.includes('actualUom') && (
          <StyledTableCell style={{ width: '6%' }}>
            <div style={{ paddingLeft: '5px', textAlign: 'center', fontWeight: 'bold' }}>
              {getUoM(row.salesUnitOfMeasure, row.packContent)}
            </div>
          </StyledTableCell>
        )}
        {tabConfig.includes('actualQty') && (
          <StyledTableCell style={{ width: '6%', fontWeight: 'bold' }}>
            {row.actualQuantity}
          </StyledTableCell>
        )}
        {tabConfig?.includes('backorderQty') && (
          <StyledTableCell style={{ width: '9%', fontWeight: 'bold' }}>
            {row?.backorderedQuantity === '0' ? '' : row?.backorderedQuantity}
          </StyledTableCell>
        )}
        {tabConfig?.includes('shippedQty') && (
          <StyledTableCell
            style={{
              width: '8%',
              fontWeight: 'bold',
            }}
          >
            {row?.deliveryQuantity === '0' ? '' : row?.deliveryQuantity}
          </StyledTableCell>
        )}
        {tabConfig?.includes('erpOrder') && (
          <StyledTableCell style={{ width: '10%' }}>{}</StyledTableCell>
        )}
        {tabConfig?.includes('tracking#') && (
          <StyledTableCell style={{ width: '10%' }}>{}</StyledTableCell>
        )}
        {tabConfig?.includes('delivery') && (
          <StyledTableCell style={{ width: '10%' }}>{}</StyledTableCell>
        )}
      </StyledDataTableRow>
      <TableRow>
        <StyledCollapsibleTableCell colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                {row?.shippingLineItems?.map((shipping: any) => (
                  <StyledDataTableRow>
                    <StyledTableCell style={{ width: '6.5%' }}>{}</StyledTableCell>
                    <StyledTableCell style={{ width: '16.5%' }}>{}</StyledTableCell>
                    <StyledTableCell style={{ width: '10%' }}>{}</StyledTableCell>
                    <StyledTableCell style={{ width: '8%' }}>{}</StyledTableCell>
                    {tabConfig.includes('actualUom') && (
                      <StyledTableCell style={{ width: '6%' }}>{}</StyledTableCell>
                    )}
                    {tabConfig.includes('actualQty') && (
                      <StyledTableCell style={{ width: '6%' }}>{}</StyledTableCell>
                    )}
                    {/* <StyledTableCell style={{ width: '6%' }}>{}</StyledTableCell>
                    <StyledTableCell style={{ width: '6%' }}>{}</StyledTableCell> */}
                    {tabConfig?.includes('backorderQty') && (
                      <StyledTableCell style={{ width: '9%' }}>{}</StyledTableCell>
                    )}
                    {tabConfig?.includes('shippedQty') && (
                      <StyledTableCell
                        style={{
                          width: '8%',
                        }}
                      >
                        {shipping.deliveryQuantity ? Number(shipping.deliveryQuantity) : ''}
                      </StyledTableCell>
                    )}
                    {tabConfig?.includes('erpOrder') && (
                      <StyledTableCell style={{ width: '10%' }}>
                        {shipping.erpOrderNumber}
                      </StyledTableCell>
                    )}
                    {tabConfig?.includes('tracking#') && (
                      <StyledTableCell style={{ width: '12%', whiteSpace: 'nowrap' }}>
                        {shipping?.trackingNumber && shipping?.trackingNumber !== 'N/A' ? (
                          <>
                            {shipping?.shipmentSource === 'FEDEX' && (
                              <a
                                rel="noreferrer noopener"
                                target="_blank"
                                href={`https://www.fedex.com/fedextrack/?trknbr=${shipping?.trackingNumber}`}
                              >
                                {shipping?.trackingNumber}
                              </a>
                            )}
                            {shipping?.shipmentSource === 'UPS' && (
                              <a
                                rel="noreferrer noopener"
                                target="_blank"
                                href={`https://www.ups.com/track?loc=en_US&tracknum=${shipping?.trackingNumber}`}
                              >
                                {shipping?.trackingNumber}
                              </a>
                            )}
                            {shipping?.shipmentSource !== 'FEDEX' &&
                              shipping?.shipmentSource !== 'UPS' &&
                              shipping?.trackingNumber}
                          </>
                        ) : (
                          ''
                        )}
                      </StyledTableCell>
                    )}
                    {tabConfig?.includes('delivery') && (
                      <StyledTableCell style={{ width: '12%' }}>
                        {shipping?.deliveredDate}
                      </StyledTableCell>
                    )}
                  </StyledDataTableRow>
                ))}
              </Table>
            </Box>
          </Collapse>
        </StyledCollapsibleTableCell>
      </TableRow>
    </>
  );
};

const InventoryRequestShippimgTab: React.FC<Props> = ({ externalId, tabConfig }) => {
  const userInfo = useSelector(getUserInfo);
  const [open, setOpen] = useState(true);
  const iconPath = getEventIcon(CYCLE_COUNT);
  const [searchValue, setSearchText] = useState('');

  const {
    data: getOrderDetails,
    refetch: refetchProcessedEventDetails,
    loading: processedLoading,
  } = useQuery(GET_ORDER_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: {
      id: externalId,
      orderType: 'INVENTORY_REQUEST',
      searchText: searchValue,
    },
  });

  useEffect(() => {
    if (processedLoading !== true && searchValue?.length > 0) {
      setOpen(!!searchValue);
    }
  }, [searchValue, processedLoading]);

  const lineItems = getOrderDetails?.getOrderDetails?.lineItems || [];

  const setSearch = (value: string): void => {
    setSearchText(value.trim());
    refetchProcessedEventDetails();
  };

  const debouncedFetchRequestedItems = useCallback(debounced(500, setSearch), [
    debounced,
    setSearch,
  ]);

  const handleSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      debouncedFetchRequestedItems(value);
    },
    [debouncedFetchRequestedItems]
  );

  const orderedQuantity = lineItems?.reduce(
    (accumulator: any, current: { quantity: any }) => accumulator + Number(current.quantity),
    0
  );

  const shippedQuantity = lineItems?.reduce(
    (accumulator: any, current: any) => accumulator + Number(current.deliveryQuantity),
    0
  );

  const headerActions = (): ReactNode => (
    <PageHeaderControl>
      <ButtonGroup variant="list" id="button-group-page-header-actions">
        <div className="slds-col_padded" style={{ width: '105%' }}>
          <Input
            iconRight={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <InputIcon
                assistiveText={{
                  icon: 'Search',
                }}
                name="search"
                category="utility"
              />
            }
            id="search-case-id"
            placeholder="Search by Part & ERP Order"
            onChange={(_event: any, searchItem: string): void => handleSearch(_event, searchItem)}
            // value={searchText}
          />
        </div>

        <div style={{ marginRight: '10px' }}>
          <CartComponent
            count={orderedQuantity}
            color="grey"
            textColor="white"
            cartTitle="Requested"
          />
        </div>
        <div style={{ marginRight: '10px' }}>
          <CartComponent
            count={shippedQuantity}
            color="grey"
            textColor="white"
            cartTitle="Shipped"
          />
        </div>
      </ButtonGroup>
    </PageHeaderControl>
  );

  return (
    <div className="inventory-table-container">
      <IconSettings iconPath="/icons">
        <PageHeader
          className="events_header"
          icon={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <img
              src={iconPath}
              className="logo-image"
              alt="company"
              style={{ width: '2.75rem', height: '2.75rem' }}
            />
          }
          onRenderActions={headerActions}
          variant="record-home"
          title="Inventory"
        />
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <StyledTableRow>
              {lineItems?.length > 0 && (
                <StyledTableHeaderCell style={{ width: '6.5%' }}>
                  <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? (
                      <Icon
                        category="utility"
                        name="normal_on-all_u593"
                        size="small"
                        style={{
                          display: 'inline-block',
                          marginLeft: '10px',
                          marginTop: '10px',
                          fill: 'white',
                        }}
                      />
                    ) : (
                      <Icon
                        category="utility"
                        name="double_arrow_collapse"
                        size="small"
                        style={{
                          display: 'inline-block',
                          marginLeft: '10px',
                          marginTop: '10px',
                          fill: 'white',
                        }}
                      />
                    )}
                  </IconButton>
                </StyledTableHeaderCell>
              )}
              <StyledTableHeaderCell style={{ width: '16.5%', textAlign: 'left' }}>
                Part
              </StyledTableHeaderCell>
              <StyledTableHeaderCell
                style={{ width: '10%', textAlign: 'left', paddingLeft: '32px' }}
              >
                Status
              </StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ width: '8%' }}>Ordered Qty</StyledTableHeaderCell>
              {tabConfig.includes('actualUom') && (
                <StyledTableHeaderCell style={{ width: '6%' }}>Actual UoM</StyledTableHeaderCell>
              )}
              {tabConfig.includes('actualQty') && (
                <StyledTableHeaderCell style={{ width: '6%' }}>Actual Qty</StyledTableHeaderCell>
              )}
              {tabConfig?.includes('backorderQty') && (
                <StyledTableHeaderCell style={{ width: '9%' }}>
                  Backordered Qty
                </StyledTableHeaderCell>
              )}
              {tabConfig?.includes('shippedQty') && (
                <StyledTableHeaderCell style={{ width: '8%' }}>Shipped Qty</StyledTableHeaderCell>
              )}

              {tabConfig?.includes('erpOrder') && (
                <StyledTableHeaderCell style={{ width: '10%' }}>ERP Order</StyledTableHeaderCell>
              )}
              {tabConfig?.includes('tracking#') && (
                <StyledTableHeaderCell style={{ width: '12%' }}>Tracking #</StyledTableHeaderCell>
              )}
              {tabConfig?.includes('delivery') && (
                <StyledTableHeaderCell style={{ width: '12%' }}>Delivery</StyledTableHeaderCell>
              )}
            </StyledTableRow>
            <TableBody>
              {lineItems?.map((row: any) =>
                row?.approvalStatus !== 'Manual' ? (
                  <Row
                    key={row?.productNumber}
                    row={row}
                    userInfo={userInfo}
                    expandAll={open}
                    tabConfig={tabConfig}
                  />
                ) : (
                  ''
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </IconSettings>
    </div>
  );
};

export default InventoryRequestShippimgTab;
