import gql from 'graphql-tag';
// eslint-disable-next-line import/prefer-default-export
export const UPDATE_USER_NOTIFICATION = gql`
  mutation updateUserNotification(
    $id: [String]!
    $type: String!
    $isStar: Boolean
    $isRead: Boolean
  ) {
    updateUserNotification(id: $id, type: $type, isStar: $isStar, isRead: $isRead) {
      message
    }
  }
`;
export default UPDATE_USER_NOTIFICATION;
