/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unneeded-ternary */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Input from '@salesforce/design-system-react/components/input';
import './index.scss';

interface Action {
  workflowAction: string;
  userworkflowAction?: [];
  footerRow?: boolean;
  quantity?: string;
  availableQuantity?: string;
  counterValue?: string;
  lotNumber?: string;
}
export interface Props {
  children?: FC;
  item?: Action;
  viewOnly: boolean;
  type?: string;
  handleCount: (event: any, data: { value: string; number: number }, product: any) => void;
}

const InventoryCounterDataCell: FC<Props> = ({
  item = {
    workflowAction: null,
    userworkflowAction: [],
    lineVisualIndicator: '',
    quantity: '',
    availableQuantity: '',
    counterValue: '',
    lotNumber: '',
  },
  viewOnly,
  handleCount,
}) => {
  const { footerRow, quantity, availableQuantity, counterValue, workflowAction, lotNumber } = item;
  const count = counterValue ? counterValue : quantity;
  return footerRow ? (
    <DataTableCell title="footer" />
  ) : (
    <DataTableCell key="-1">
      {lotNumber ? (
        <Input
          id="counter-input-3"
          minValue={0}
          maxValue={Number(availableQuantity) + Number(quantity)}
          disabled={
            viewOnly ||
            !!(
              workflowAction &&
              (workflowAction.toLowerCase() === 'accept' ||
                workflowAction.toLowerCase() === 'cancel')
            )
          }
          onChange={(event: any, data: { value: string; number: number }): void => {
            handleCount(event, data, item);
          }}
          value={count}
          variant="counter"
        />
      ) : (
        <Input
          id="counter-input-3"
          minValue={0}
          maxValue={999}
          disabled={
            viewOnly ||
            !!(
              workflowAction &&
              (workflowAction.toLowerCase() === 'accept' ||
                workflowAction.toLowerCase() === 'cancel')
            )
          }
          onChange={(event: any, data: { value: string; number: number }): void => {
            handleCount(event, data, item);
          }}
          value={count}
          variant="counter"
        />
      )}
    </DataTableCell>
  );
};

export default InventoryCounterDataCell;
