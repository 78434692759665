/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Paper from '@material-ui/core/Paper';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Button from '@salesforce/design-system-react/components/button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
import { addMinutes } from 'date-fns/esm';

import { Event } from '../../../graphql/getEventItems';
import {
  LIMIT,
  MOCK_OR_EVENT_TYPE,
  NEXT_DATA,
  PREV_DATA,
  TRIAL_EVENT_TYPE,
} from '../../../util/constants';
import { getUserId } from '../../../store/ducks/userId';
import EventStatusBadge from '../../Shared/EventStatusBadge';
import './index.scss';
import { getEventIconInner } from '../../../util/utilityFunctions';

interface Filters {
  status?: string[];
  salesReps?: string[];
  coveringReps?: string[];
  account?: string;
  branch?: string[];
  franchise?: string[];
  surgeon?: string;
  procedureDetail?: string[];
  startDate?: Date | string;
  endDate?: Date | string;
  usage?: string;
  eventType?: string[];
  search?: string;
}

interface OracleWorkflowPermission {
  workflowPermission: {
    id: string;
  } | null;
}
interface Props extends RouteComponentProps {
  getEventsData: (variables: any) => void;
  eventItems: Event[];
  setOffset: (item: number) => void;
  offset: number;
  enableOracleStatus: OracleWorkflowPermission;
  activeTab: string;
  selectedFilterApiValues: Filters | null;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    flexGrow: 1,
  },
  card: {
    margin: '7px',
    border: '0.5px solid lightgrey',
    borderRadius: '10px',
    backgroundColor: 'white',
    lineHeight: '1.1em',
    width: '380px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '2px 2px 5px grey',
    },
    '&:focus': {},
  },
}));
const EventsCard: FC<Props> = ({
  getEventsData,
  eventItems,
  setOffset,
  offset,
  activeTab,
  selectedFilterApiValues,
  history,
}) => {
  const classes = useStyles();
  const id = useSelector(getUserId);
  // This function fetches the next and previous records of the events list
  const handlePaginationButtonClicked = (type: string): void => {
    let finalOffset;
    if (type === NEXT_DATA) {
      finalOffset = offset + LIMIT;
    } else {
      finalOffset = offset - LIMIT;
    }
    if (activeTab === 'Requested' && !selectedFilterApiValues) {
      getEventsData({
        variables: {
          offset: finalOffset,
          limit: LIMIT,
          id,
          filters: { status: ['Requested', 'Failed'] },
        },
      });
    } else {
      getEventsData({
        variables: { offset: finalOffset, limit: LIMIT, id, filters: selectedFilterApiValues },
      });
    }

    setOffset(finalOffset);
  };
  const timedata = new Date();
  const newtime = timedata
    .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
    .substring(4)
    .match(/\b(\w)/g);
  const TimeZoneData = newtime?.join('');

  const updatedEventItems =
    eventItems &&
    eventItems.map(element => {
      const event = { ...element };
      event.surgeonName = `${element.surgeonFirstName} ${element.surgeonLastName}`;
      return event;
    });

  const goToSurgicalDetail = (externalId: string, eventType: string, idStr: string): void => {
    if (eventType !== 'Cycle Count') {
      history.push('/surgicalDetails', {
        eventType,
        externalId,
        id: idStr,
      });
    } else if (eventType === 'Cycle Count') {
      history.push('/cycleCountDetails', {
        cycleCountExternalId: externalId,
      });
    } else {
      history.push('/events/card_view');
    }
  };

  return (
    <div>
      <div className="card-outer_div">
        {/* <Grid
          container
          direction="row"
          alignContent="center"
          alignItems="center"
          wrap="wrap"
          spacing={4}
        > */}
        <Grid container justify="center">
          {eventItems &&
            eventItems.map(item => {
              const startDate = new Date(
                moment.utc(item.eventStartDate).format('YYYY-MM-DD HH:mm:ss')
              );
              const endDate = item.eventEndDate
                ? new Date(moment.utc(item.eventEndDate).format('YYYY-MM-DD HH:mm:ss'))
                : addMinutes(
                    new Date(moment.utc(item.eventEndDate).format('YYYY-MM-DD HH:mm:ss')),
                    Number(15)
                  );
              const externalId: any =
                item.eventType === 'Cycle Count' ? item?.cycleCountExternalId : item.externalId;

              return (
                <Grid
                  item
                  className={classes.card}
                  onClick={(): void => goToSurgicalDetail(externalId, item.eventType, item.id)}
                >
                  {/* <Paper style={{ borderRadius: '10px' }}> */}
                  <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr' }}>
                    <div style={{ padding: '15px' }}>
                      <img
                        src={getEventIconInner(item.eventType)}
                        alt="event"
                        style={{ height: '18px', marginTop: '-2px' }}
                      />
                      <span className="card-header">
                        {item.eventSubType || item.eventType || '--'}
                      </span>
                    </div>
                    {/* <div className="card-status">{item.eventStatus || '--'}</div> */}
                    <div style={{ float: 'right', padding: '15px' }}>
                      <EventStatusBadge status={item.eventStatus || 'NEW'} />
                    </div>
                  </div>
                  <GridList cols={1}>
                    <GridListTile style={{ padding: '15px', height: '110px' }}>
                      <div className="card-case">{item.eventId || '--'}</div>
                      {/* {item.eventType === 'Cycle Count' ? (
                        <div className="card-account">
                          {`${item.territory ? `${item.territory}` : '--'}`}
                        </div>
                      ) : ( */}
                      <>
                        <div className="card-account">
                          {`${item.accountName ? `${item.accountName}` : '--'}`}
                        </div>
                        <span className="event-description">{item.eventDescription || ''}</span>
                      </>
                      {/* )} */}
                      {item.eventType !== 'Cycle Count' ? (
                        <div className="card-account-name">
                          {`${item.salesRep ? `${item.salesRep}` : '--'}`}
                        </div>
                      ) : (
                        <div className="card-account-name">
                          {`${
                            item.coveringReps ? `${item.coveringReps?.replace(',', ', ')}` : '--'
                          }`}
                        </div>
                      )}
                    </GridListTile>
                  </GridList>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'auto 1fr',
                      backgroundColor: '#DDDEDA',
                      borderBottomLeftRadius: '10px',
                      borderBottomRightRadius: '10px',
                      minHeight: '90px',
                    }}
                  >
                    <div style={{ display: 'grid', gridTemplateRows: '1fr 1fr', padding: '10px' }}>
                      <div
                        style={{
                          display: 'grid',
                          gridGap: '15px',
                          gridTemplateColumns: 'auto 1fr',
                        }}
                      >
                        <div>
                          <FontAwesomeIcon
                            style={{
                              marginInline: 'auto',
                            }}
                            icon={faUserCircle}
                            size="2x"
                          />
                        </div>
                        <div
                          style={{
                            fontSize: '14px',
                          }}
                        >
                          {`${
                            item.surgeonFirstName && item.surgeonLastName
                              ? `${item.surgeonFirstName}-${item.surgeonLastName}`
                              : '--'
                          }`}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'grid',
                          gridGap: '10px',
                          gridTemplateColumns: 'auto 1fr',
                        }}
                      >
                        <div>
                          <FontAwesomeIcon
                            style={{
                              marginInline: 'auto',
                            }}
                            icon={faUserFriends}
                            size="2x"
                          />
                        </div>
                        <div
                          style={{
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item.eventType !== 'Cycle Count' &&
                            `${item.coveringReps ? `${item.coveringReps}` : '--'}`}
                        </div>
                      </div>
                    </div>
                    <div style={{ display: 'grid', gridTemplateRows: '1fr 1fr', padding: '10px' }}>
                      {item.eventType !== TRIAL_EVENT_TYPE &&
                        item.eventType !== 'Cycle Count' &&
                        item.eventSubType !== MOCK_OR_EVENT_TYPE && (
                          <>
                            <div className="card-date">
                              <div>{moment(item.eventStartDate).format('L')}</div>
                              <div className="card-time">
                                {moment(item.eventStartDate).format('LT')}
                                {`  ${TimeZoneData}`}
                              </div>
                            </div>
                          </>
                        )}
                      {item.eventType === 'Cycle Count' && (
                        <>
                          <div className="card-date">
                            {/* <div>Start Date:</div> */}
                            <div>{moment(startDate).format('L')}</div>
                          </div>
                          <div className="card-date">
                            {/* <div>End Date:</div> */}
                            <div>
                              {moment(endDate).format('L')}
                              {`  ${TimeZoneData}`}
                            </div>
                          </div>
                        </>
                      )}
                      {(item.eventType === TRIAL_EVENT_TYPE ||
                        item.eventSubType === MOCK_OR_EVENT_TYPE) && (
                        <>
                          <div className="card-date">
                            <div>{moment(item.eventStartDate).format('L')}</div>
                            <div className="card-time">
                              {moment(item.eventStartDate).format('LT')}
                              {`  ${TimeZoneData}`}
                            </div>
                          </div>
                          <div className="card-date">
                            <div>{moment(item.eventEndDate).format('L')}</div>
                            <div className="card-time">
                              {moment(item.eventEndDate).format('LT')}
                              {`  ${TimeZoneData}`}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {/* <GridList
                    cols={2}
                    style={{
                      margin: '0px',
                      backgroundColor: '#DDDEDA',
                      borderBottomLeftRadius: '10px',
                      borderBottomRightRadius: '10px',
                    }}
                  >
                    <GridListTile
                      style={{
                        padding: '10px',
                        width: '280px',
                        height: '0%',
                        display: 'grid',
                      }}
                    >
                      <div className="card-user-icon">
                        <div>
                          <FontAwesomeIcon
                            style={{
                              marginInline: 'auto',
                            }}
                            icon={faUserCircle}
                            size="2x"
                          />
                        </div>
                        <div
                          style={{
                            fontSize: '14px',
                          }}
                        >
                          {`${
                            item.surgeonFirstName && item.surgeonLastName
                              ? `${item.surgeonFirstName}-${item.surgeonLastName}`
                              : '--'
                          }`}
                        </div>
                      </div>
                      <div className="card-user-icon">
                        <div>
                          <FontAwesomeIcon
                            style={{
                              marginInline: 'auto',
                            }}
                            icon={faUserFriends}
                            size="2x"
                          />
                        </div>
                        <div style={{ fontSize: '14px' }}>
                          {`${item.coveringReps ? `${item.coveringReps}` : '--'}`}
                        </div>
                      </div>
                    </GridListTile>
                    <div
                      style={{
                        width: '120px',
                        height: '0%',
                        gridTemplateRows: '1fr 1fr',
                        display: 'grid',
                        gridGap: '24px',
                        padding: '10px',
                      }}
                    >
                      <div className="card-date">
                        <div>{moment(item.eventStartDate).format('L')}</div>
                      </div>
                      <div className="card-time">
                        <div>{moment(item.eventStartDate).format('LT')}</div>
                      </div>
                    </div>
                  </GridList> */}
                  {/* </Paper> */}
                </Grid>
              );
            })}
        </Grid>
      </div>
      <div>
        {updatedEventItems && updatedEventItems.length > 0 ? (
          <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronleft"
                iconSize="small"
                disabled={!(offset > 0)}
                iconVariant="bare"
                // will fetch the previous events data
                onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
                variant="icon"
              />
            </div>
            <div className="slds-col slds-p-left_medium slds-p-right_medium">
              <span>{`${offset + 1} - ${offset + LIMIT}`}</span>
            </div>
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronright"
                iconSize="small"
                iconVariant="bare"
                disabled={eventItems && eventItems.length < 50}
                // will fetch the next events data
                onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
                variant="icon"
              />
            </div>
          </div>
        ) : (
          <div className="no-data-found-container">
            <h1 className="no-data-found-text">No Records Found</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(EventsCard);
