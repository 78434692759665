import gql from 'graphql-tag';

const CREATE_USAGE_SHEET = gql`
  mutation createUsageSheet(
    $caseId: String
    $caseExternalId: String
    $eventExternalId: String
    $eventSFID: String
    $division: String
    $branchId: String!
    $accountId: String!
    $procedureTypeIds: String
    $surgeonId: String
    $salesRepId: String!
    $eventType: String
    $shipToId: String
    $billToId: String
    $eventDateTime: Date
    $poNumber: String
    $patientId: String
    $formType: String!
    $shipMethod: String
    $system: String
    $version: String
    $requestedFrom: String
    $comments: [Comment]
    $lineItems: [InventoryLineItem]
    $attachments: [Attachment]
  ) {
    createUsageSheet(
      division: $division
      caseId: $caseId
      caseExternalId: $caseExternalId
      eventSFID: $eventSFID
      eventExternalId: $eventExternalId
      branchId: $branchId
      accountId: $accountId
      system: $system
      version: $version
      procedureTypeIds: $procedureTypeIds
      surgeonId: $surgeonId
      salesRepId: $salesRepId
      eventType: $eventType
      eventDateTime: $eventDateTime
      shipToId: $shipToId
      billToId: $billToId
      poNumber: $poNumber
      patientId: $patientId
      formType: $formType
      shipMethod: $shipMethod
      requestedFrom: $requestedFrom
      comments: $comments
      lineItems: $lineItems
      attachments: $attachments
    ) {
      message
      externalId
      sfid
    }
  }
`;

export default CREATE_USAGE_SHEET;
