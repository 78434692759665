/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useEffect, useState } from 'react';
import Card from '@salesforce/design-system-react/components/card';
import DataTable from '@salesforce/design-system-react/components/data-table';
import Spinner from '@salesforce/design-system-react/components/spinner';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import './index.scss';
// eslint-disable-next-line import/order
import { useQuery } from '@apollo/react-hooks';
// eslint-disable-next-line import/order
import PageHeader from '@salesforce/design-system-react/components/page-header';
import Input from '@salesforce/design-system-react/components/input';
import Button from '@salesforce/design-system-react/components/button';
import { useHistory } from 'react-router-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import SnackBar from '../../Shared/SnackBar';
import { getSvgIcon } from '../../../util/utilityFunctions';
import { GET_GLOBAL_SEARCH_RESULTS } from '../../../graphql/getGlobalSearchResults';
import EventCell from './DataTableCells/EventCell';
import CustomeDataCell from './DataTableCells/CustomDataCell';
import OrderRequestIdCell from './DataTableCells/OrderRequestIdCell';
import { LIMIT, NEXT_DATA, PREV_DATA } from '../../../util/constants';

interface Props {
  userInfo: any;
  globalSearchStr: string;
}

EventCell.displayName = DataTableCell.displayName;
OrderRequestIdCell.displayName = DataTableCell.displayName;

const GlobalSearchResults: FC<Props> = ({ globalSearchStr, userInfo }) => {
  const [filterItems, setFilterItems] = useState([]);
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState(globalSearchStr);
  const [currentPage, setCurrentPage] = useState(0);

  const { data: globalSearch, loading } = useQuery(GET_GLOBAL_SEARCH_RESULTS, {
    variables: {
      searchText,
      limit: LIMIT,
      offset: currentPage,
    },
  });
  const history = useHistory();
  const { open, notification, openSnackBar } = useSnackBar();

  const columns = [
    <DataTableColumn key="recordID" label="Record ID" property="recordID" width="3em">
      <OrderRequestIdCell userInfo={userInfo} />
    </DataTableColumn>,
    <DataTableColumn
      key="erpOrderNumber"
      label="ERP Order Number"
      property="erpOrderNumber"
      width="4em"
    >
      <CustomeDataCell columnName="erpOrderNumber" />
    </DataTableColumn>,
    <DataTableColumn key="recordType" label="Record Type" property="recordType" width="3em" />,
    <DataTableColumn key="orderType" label="Order Type" property="orderType" width="3em">
      <CustomeDataCell columnName="orderType" />
    </DataTableColumn>,
    <DataTableColumn key="parentEventId" label="Event" property="parentEventId" width="6em">
      <EventCell />
    </DataTableColumn>,
    <DataTableColumn key="status" label="Status" property="status" width="6em">
      <CustomeDataCell columnName="status" />
    </DataTableColumn>,
    <DataTableColumn key="salesRep" label="Sales Rep" property="salesRep" width="3em" />,
    <DataTableColumn key="accountName" label="Account" property="accountName" width="6em">
      <CustomeDataCell columnName="accountName" />
    </DataTableColumn>,
  ];

  // eslint-disable-next-line no-redeclare
  const fetchData = () => {
    setFilterItems([]);
    const apiData = globalSearch?.globalSearch;
    setFilterItems(apiData);
  };

  const searchData = (searchValue: string) => {
    const inputTexts = searchValue.split('c-');
    const searchstr = inputTexts?.[inputTexts.length - 1];
    if (searchstr.length > 2) {
      setSearchText(searchValue);
      fetchData();
    } else {
      openSnackBar('error', 'Please enter atleast 3 digit to search');
    }
  };

  const handlePaginationButtonClicked = (nav: string): void => {
    if (nav === 'Prev Data') {
      setCurrentPage(currentPage - LIMIT);
    } else if (nav === 'Next Data') {
      setCurrentPage(currentPage + LIMIT);
    }
  };

  useEffect(() => {
    setInputText(inputText.length > 0 ? inputText : searchText);
    const apiData = globalSearch?.globalSearch;
    setFilterItems(apiData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalSearch, searchText]);

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      <div className="slds-x-small-buttons_horizontal" style={{ padding: '10px 0px 0px 10px' }}>
        <Button
          style={{ color: 'black' }}
          iconCategory="utility"
          iconName="back"
          iconPosition="left"
          label="Back"
          onClick={() => {
            history.push('/', { inputText });
          }}
        />
      </div>
      <PageHeader
        className="slds-usage-deatails slds-m-around_small slds-m-bottom_none"
        title={
          <div
            className="slds-grid slds-p-top_xx-small"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div>
              <img
                src={getSvgIcon('globalSearch')}
                className="logo-image"
                alt="Globle Search Icon"
              />
              <span className="slds-col event_id slds-m-left_small">Search Results</span>
            </div>
            <br />
            <div style={{ display: 'flex' }}>
              <Input
                type="search"
                aria-describedby="error-4"
                id="unique-id-4"
                required
                placeholder="Search..."
                value={inputText}
                onChange={(e: any): void => {
                  setInputText(e.target.value);
                }}
                iconRight={
                  inputText.length > 0 ? (
                    <InputIcon
                      assistiveText={{
                        icon: 'Clear',
                      }}
                      name="clear"
                      category="utility"
                      onClick={() => {
                        setInputText('');
                      }}
                    />
                  ) : (
                    <></>
                  )
                }
              />
              <Button
                style={{
                  marginLeft: '10px',
                  backgroundColor: '#ffb500',
                  border: 'none',
                  color: '#000',
                }}
                label="Search"
                variant="brand"
                onClick={(): void => searchData(inputText)}
              />
            </div>
          </div>
        }
        variant="record-home"
      />
      <Card id="GlobalSearchResults" className="slds-m-horizontal_small" hasNoHeader heading="">
        {loading ? (
          <Spinner
            size="large"
            variant="base"
            assistiveText={{ label: 'Loading...' }}
            key="spinner"
          />
        ) : (
          <div className="slds-m-horizontal_small">
            {filterItems && filterItems.length > 0 ? (
              <>
                <DataTable items={filterItems} id="DataTableExample-1-default" fixedLayout>
                  {columns}
                </DataTable>
                <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
                  <div className="slds-col">
                    <Button
                      assistiveText={{ icon: 'Icon Bare Small' }}
                      iconCategory="utility"
                      iconName="chevronleft"
                      iconSize="small"
                      disabled={!(currentPage > 0)}
                      iconVariant="bare"
                      onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
                      variant="icon"
                    />
                  </div>
                  <div className="slds-col slds-p-left_medium slds-p-right_medium">
                    <span>{`${currentPage + 1} - ${currentPage + LIMIT}`}</span>
                  </div>
                  <div className="slds-col">
                    <Button
                      assistiveText={{ icon: 'Icon Bare Small' }}
                      iconCategory="utility"
                      iconName="chevronright"
                      iconSize="small"
                      iconVariant="bare"
                      disabled={filterItems?.length < LIMIT}
                      onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
                      variant="icon"
                    />
                  </div>
                </div>
              </>
            ) : (
              <p>No Records Found</p>
            )}
          </div>
        )}
      </Card>
    </IconSettings>
  );
};

export default GlobalSearchResults;
