import gql from 'graphql-tag';

const CASE_USAGE_ATTACHMENTS = gql`
  mutation caseUsageAttachments(
    $caseId: String
    $caseExternalId: String!
    $customerPrintedName: String
    $repPrintedName: String!
    $attachments: [Attachment]
  ) {
    caseUsageAttachments(
      caseId: $caseId
      caseExternalId: $caseExternalId
      customerPrintedName: $customerPrintedName
      repPrintedName: $repPrintedName
      attachments: $attachments
    ) {
      message
      externalId
    }
  }
`;

export default CASE_USAGE_ATTACHMENTS;
