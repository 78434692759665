/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Combobox from '@salesforce/design-system-react/components/combobox';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import './index.scss';
import { GET_OPS_LIST } from '../../../../graphql/getOpsList';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import ASSIGN_INVENTORY_REQUEST from '../../../../graphql/mutations/assignInventoryRequest';
import { NOTIFY_TYPE, OPS_ACTION_TYPE } from '../../../../util/constants';

interface Assign {
  assignedOPSName: string;
  assignedOPSId: string;
  externalId: string;
}

interface Props {
  children?: FC;
  item?: Assign;
  action?: string;
  refetch: any;
  openSnackBar?: any;
}

interface OpsList {
  id: string;
  value: string;
}

interface OpsDropdownSelect {
  label?: string;
  value?: string;
  id?: string;
}

const InventoryRequestsAssignDataCell: FC<Props> = ({
  item = {
    assignedOPSName: null,
    externalId: '',
    assignedOPSId: null,
  },
  action,
  refetch,
  openSnackBar,
}) => {
  const { assignedOPSName, externalId, assignedOPSId } = item;
  // const { open, notification, openSnackBar } = useSnackBar();
  const userInfo = useSelector(getUserInfo);
  const [getOpsList, { data }] = useLazyQuery(GET_OPS_LIST);
  const [options, setOptions] = useState<OpsDropdownSelect[]>();
  const [selectedOps, setSelectedOps] = useState<any>();
  const [assignToOps, { data: assignRespone }] = useMutation(ASSIGN_INVENTORY_REQUEST);
  useEffect(() => {
    if (action === OPS_ACTION_TYPE.ASSIGN) {
      getOpsList({
        variables: {
          personaName: userInfo?.personas,
        },
      });
    }
  }, [action, getOpsList, userInfo]);

  useEffect(() => {
    if (data && data.getOPSList) {
      const optionList = data.getOPSList.map((i: OpsList) => {
        return {
          label: i.value,
          value: i.value,
          id: i.id,
        };
      });
      setOptions(optionList);
    }
  }, [data]);
  useEffect(() => {
    setSelectedOps([
      {
        label: assignedOPSName,
        value: assignedOPSName,
        id: assignedOPSId,
      },
    ]);
  }, [assignedOPSName, assignedOPSId]);
  useEffect(() => {
    if (assignRespone && assignRespone.assignInventoryRequest.message === NOTIFY_TYPE.SUCCESS) {
      openSnackBar(NOTIFY_TYPE.SUCCESS, 'Inventory Return Assigned Successfully');
      refetch();
    }
  }, [assignRespone, openSnackBar, refetch]);
  const handleSelectDropdownItem = (option: any): void => {
    const { selection } = option;
    setSelectedOps(selection);
    if (selection) {
      assignToOps({
        variables: {
          externalId,
          assignedUser: selection[0].id,
        },
      });
    }
  };

  // let popover = selectedOps;
  // if (Array.isArray(selectedOps)) {
  //   popover = selectedOps[0].value;
  // }
  return (
    <DataTableCell title={assignedOPSName ? `${assignedOPSName}` : 'Select an Option'}>
      {action === OPS_ACTION_TYPE.REVIEW ? (
        <p>{assignedOPSName}</p>
      ) : (
        <div>
          <Combobox
            id="combobox-readonly-single"
            menuPosition="overflowBoundaryElement"
            events={{
              onSelect: (_event: any, option: any): void => handleSelectDropdownItem(option),
            }}
            labels={{
              placeholder: 'Select',
            }}
            options={options || []}
            selection={selectedOps}
            variant="readonly"
          />
        </div>
      )}
      {/* <IconSettings iconPath="/icons">
        <SnackBar open={open} notification={notification} />
      </IconSettings> */}
    </DataTableCell>
  );
};

export default InventoryRequestsAssignDataCell;
