/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
// import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
// import Button from '@salesforce/design-system-react/components/button';
import CartComponent from '../../Parts/CartComponent';
import '../../../index.scss';

interface Props {
  count: number;
  viewOnly: boolean;
  handleSave: () => void;
}

const PricingHeader: FC<Props> = ({ count }) => {
  // const actions = (): ReactNode => (
  //   <PageHeaderControl>
  //     <Button
  //       className="yellow-btn"
  //       align="right"
  //       label="Save"
  //       onClick={handleSave}
  //       disabled={viewOnly}
  //     />
  //   </PageHeaderControl>
  // );

  return (
    <IconSettings iconPath="/icons">
      <PageHeader
        className="slds-page-header-usage slds-page-header__name-title-inventory slds-m-around_small slds-m-bottom_none"
        // onRenderActions={actions}
        title={
          <>
            <span style={{ marginRight: '10px' }}>
              <CartComponent count={count} color="white" textColor="black" />
            </span>
            <span style={{ fontSize: '18px' }}>Pricing</span>
          </>
        }
        variant="record-home"
        id="header"
      />
    </IconSettings>
  );
};

export default PricingHeader;
