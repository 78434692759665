/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect } from 'react';
import { Grid, Chip, makeStyles } from '@material-ui/core';
import moment from 'moment';
import './index.scss';

interface Props {
  selectedFilter: any;
  handleFilter: (filters: any) => void;
}
interface FilterPreset {
  filterValues: any;
  filterExternalId?: string;
  filterName?: string;
}

interface DynamicValue {
  [key: string]: any;
}
const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
    padding: '0px',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));
const FilterTags: FC<Props> = ({ selectedFilter, handleFilter }) => {
  const classes = useStyles();
  const [filterTags, setFilterTags] = useState<any[] | null>();
  // Filter Tags
  useEffect(() => {
    const tags: any[] = [];
    const filters = selectedFilter && selectedFilter.filterValues;
    Object.keys(filters || {}).forEach((key: any) => {
      const value = filters[key];
      if (value) {
        let tagName;
        if (Array.isArray(value)) {
          if (value.length > 1) {
            if (value[0] && typeof value[0] === 'object') {
              tagName = `${value[0].label} ${value.length > 1 ? `(+${value.length - 1})` : ''}`;
            } else {
              tagName = `${value[0]} ${value.length > 1 ? `(+${value.length - 1})` : ''}`;
            }
          } else if (value[0] && typeof value[0] === 'object') {
            tagName = value[0].label || '';
          } else {
            // eslint-disable-next-line prefer-destructuring
            tagName = value[0];
          }
        } else if (typeof value === 'object') {
          tagName = value.label || '';
        } else if (typeof value === 'string') {
          if (key.toLowerCase().includes('date')) {
            tagName = moment(value).format('L');
          } else if (value !== 'FilterValues') tagName = value;
        } else if (typeof value === 'boolean') {
          tagName = key
            .split(/(?=[A-Z])/)
            .map((s: any) => s.toUpperCase())
            .join(' ');
        } else {
          tagName = value;
        }
        tags.push({ key, value, tagName });
      }
    });
    setFilterTags(tags.filter((i: any) => i.tagName));
  }, [selectedFilter]);
  const handleDeleteTag = (name: any): void => {
    if (selectedFilter && selectedFilter?.filterValues) {
      let filterValues: any;
      // eslint-disable-next-line prefer-const
      filterValues = selectedFilter?.filterValues;
      if (name.includes('expirationFromDate') || name.includes('expirationToDate')) {
        // Inventory List: Expiratation Days
        const { expirationFromDate, expirationToDate, ...newFilterValues } = filterValues;
        handleFilter(newFilterValues);
      } else if (name === 'startDate') {
        // Events: Date Range
        const { startDate, endDate, ...newFilterValues } = filterValues;
        handleFilter(newFilterValues);
      } else {
        const { [name]: remove, ...newFilterValues } = filterValues;
        handleFilter(newFilterValues);
      }
    }
  };
  return (
    <Grid container xs={12} className="filter_tag_container">
      {filterTags &&
        filterTags.length > 0 &&
        filterTags.map((tag: any) => (
          <Chip
            label={tag.tagName}
            size="small"
            className={classes.chip}
            onDelete={(): void => handleDeleteTag(tag.key)}
          />
        ))}
    </Grid>
  );
};

export default FilterTags;
