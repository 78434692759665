import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import EventStatusBadge from '../Shared/EventStatusBadge';

interface Status {
  eventstatus: string;
  status: string;
}
export interface Props {
  children?: FC;
  item?: Status;
}

const RecordStatusCell: FC<Props> = ({
  item = {
    eventstatus: '',
    parentEventStatus: '',
    status: '',
    visualIndicator: '',
    showRedWarningIcon: false,
    showBackorderdWarning: false,
  },
}) => {
  const { eventstatus } = item;

  const statusToShow = eventstatus;

  return (
    <IconSettings iconPath="/icons">
      <DataTableCell title={statusToShow || 'NEW'}>
        <div style={{ display: 'flex' }}>
          <EventStatusBadge status={statusToShow || 'NEW'} />
        </div>
      </DataTableCell>
    </IconSettings>
  );
};

export default RecordStatusCell;
