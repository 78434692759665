import gql from 'graphql-tag';

const UPDATE_ATTACHMENT = gql`
  mutation inventoryReturnAttachments(
    $orderId: String
    $orderExternalId: String!
    $attachments: [Attachment]
  ) {
    inventoryReturnAttachments(
      orderId: $orderId
      orderExternalId: $orderExternalId
      attachments: $attachments
    ) {
      message
      externalId
    }
  }
`;

export default UPDATE_ATTACHMENT;
