import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_TRANSFER_DETAILS = gql`
  query getInventoryTransferDetails($id: String, $requestedFrom: String) {
    getInventoryTransferDetails(id: $id, requestedFrom: $requestedFrom) {
      id
      externalId
      failureReason
      salesRepId
      receivingRepId
      status
      type
      createdDate
      updatedDate
      updatedBy
      createdBy
      orderNumber
      effectiveDate
      fromSubInventoryCode
      toSubInventoryCode
      recordTypeId
      priceBook2Id
      salesOrganization
      accountId
      orderId
      orderExternalId
      accountNumber
      accountName
      salesRepName
      receivingRepName
      visualIndicator
      eventId
      eventType
      eventStatus
      caseExternalId
      caseId
      eventSubType
      eventStartDate
      parentEventId
      parentEventType
      parentEventStatus
      parentCaseExternalId
      parentCaseId
      parentEventSubType
      parentEventStartDate
      isCycleCountInitiated
      comments {
        id
        externalId
        value: comments
        type
      }
      lineItems {
        id
        externalId
        productId
        lotNumber
        quantity
        priceBookEntryId
        orderItemId
        orderItemExternalId
        unitPrice
        salesUnitOfMeasure
        expirationDate
        productNumber
        status
        approvalStatus
        productDescription
        isLineSubmitted
        deliveredDate
        availableQuantity
        onhandQuantity
        productOnHold
        isLotNumber
        caseUsageSubInventoryCode
        sourceLocation
      }
      transferredLineItems {
        id
        externalId
        productId
        productNumber
        lotNumber
        quantity
        expirationDate
        status
        approvalStatus
        type
        productDescription
        availableQuantity
        onhandQuantity
        transferredQuantity
        tequestingQuantity
        erpOrderNumber
        receivedDate
        isLineSubmitted
        requestingQuantity
        orderType
        orderStatus
        transferApprovalStatus
        isLotNumber
      }
      attachments {
        id
        caseId
        caseExternalId
        recordType
        attachmentType
        showOnUsageSheet
        name
        createdBy
        createdAt
        imageurl
        user
      }
    }
  }
`;
