/*
 * Get Procedure Details GraphQL specification
 * @module src/graphql/getProcedureTypes
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_OPPORTUNITIES = gql`
  query getOpportunities($accountId: String) {
    getOpportunities(accountId: $accountId) {
      id
      accountId
      name
      stageName
      recordName
    }
  }
`;
