/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Module for Custom Rep/Covering Cell.
 * @module src/Events/EventsList
 */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import { useHistory } from 'react-router-dom';

interface Quantity {
  quantity: string;
  availablequantity: string;
  productdescription: string;
  productDescription: string;
  productNumber: string;
  createdByDetails: [];
  id: string;
  pendingeimtransaction: string;
  isOpen: boolean;
  needToDisplay: boolean;
  pendingEimQty?: string;
  lotNumber?: string;
  productId?: string;
}
interface Props {
  item?: Quantity;
}

const TableRowCell: FC<Props> = ({
  item = {
    quantity: '',
    availablequantity: '',
    productdescription: '',
    productDescription: '',
    productNumber: '',
    createdByDetails: [],
    id: '',
    pendingeimtransaction: '',
    pendingEimQty: '',
    isOpen: false,
    needToDisplay: false,
    lotNumber: '',
    productId: '',
  },
}) => {
  const {
    createdByDetails,
    pendingeimtransaction,
    productdescription,
    productDescription,
    productNumber,
    isOpen,
    needToDisplay,
    pendingEimQty,
    lotNumber,
    productId,
  } = item;
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleLink = (params: any) => {
    if (params) {
      history.push('/ReservedTransactions', {
        productId: params?.productId,
        lotNumber: params?.lotNumber,
        productDescription: params?.productDescription,
        productNumber: params?.productNumber,
      });
    }
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleLinkClick = (params: any) => {
    if (params) {
      handleLink(params);
    }
  };
  return needToDisplay ? (
    <DataTableCell title={pendingEimQty || pendingeimtransaction} className="center-align">
      <span
        role="button"
        tabIndex={0}
        className="slds-button"
        onClick={
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          () =>
            handleLink({
              productId,
              lotNumber,
              productDescription: productdescription || productDescription,
              productNumber,
            })
          // eslint-disable-next-line react/jsx-curly-newline
        }
        onKeyDown={
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          () =>
            handleLinkClick({
              productId,
              lotNumber,
              productDescription: productdescription || productDescription,
              productNumber,
            })
          // eslint-disable-next-line react/jsx-curly-newline
        }
        style={{ cursor: 'pointer' }}
      >
        {pendingEimQty ?? pendingeimtransaction ?? 0}
      </span>
      {isOpen &&
        createdByDetails?.map((lineItem: any) => (
          <div className="border-line">
            <DataTableCell>{lineItem.createdByName}</DataTableCell>
          </div>
        ))}
    </DataTableCell>
  ) : null;
};

/** Custom Rep/Covering cell */
export default TableRowCell;
