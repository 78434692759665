/*
 * Get SubInventory GraphQL specification
 * @module src/graphql/getLotDetails
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export interface LotDetail {
  subInventoryName: string;
  productNumber: string;
  productDescription: string;
  productGroup: string;
  lotNumber: string;
  availableqty: string;
  onhandqty: string;
  isonhold: boolean;
  recalled: boolean;
  expirationDate: Date;
  dateReceived: Date;
  expired: boolean;
  id: string;
  externalId: string;
  alertstatus: string;
}

/** Lot Items GraphQL data interface */
export interface LotDetailsData {
  lotDetails: LotDetail[];
}

export interface Filters {
  ownership?: string;
  status?: string[];
  searchText?: string;
  subInventoryName?: string;
  productNumber?: string;
  expirationFromDate?: Date | string;
  expirationToDate?: Date | string;
  productGroup?: string;
}
export const GET_LOTDETAILS = gql`
  query getLotDetails($subInventoryName: String!, $productNumber: String!, $lotNumber: String!) {
    getLotDetails(
      subInventoryName: $subInventoryName
      productNumber: $productNumber
      lotNumber: $lotNumber
    ) {
      subInventoryName
      productNumber
      productDescription
      lotNumber
      id
      externalId
      location
      dateReceived
      productGroup
      productId
      expirationDate
      availableqty
      onhandqty
      alertstatus
    }
  }
`;
