/**
 * Custom Component for Day view in calendar.
 * @module src/EventsCalendar/EventsCalendarDay
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-useless-concat */
import React, { FC } from 'react';
import Popover from '@salesforce/design-system-react/components/popover';
import EventDetailPanel from './EventDetailPanel';
import EventDetailPanelHeading from './EventDetailPanel/EventDetailPanelHeading';
import EventDetailPanelFooter from './EventDetailPanel/EventDetailPanelFooter';
import '../index.scss';
import { getEventIconInner } from '../../../../util/utilityFunctions';

interface Props {
  handleNavigateToEventDetail: (eventType: string, externalId: string, id: string) => void;
  currentView: string;
  eventData: {
    event: {
      eventType: string;
      externalId: string;
      eventId: string;
      id: string;
      eventStatus: string;
      procedures: string;
      eventDate: string;
      eventStartDate: Date;
      eventEndDate: Date;
      eventTime: string;
      accountName: string;
      eventName: string;
      surgeonFirstName: string;
      surgeonLastName: string;
      surgeonName?: string;
      eventSubType: string;
      salesRep: string;
      coveringReps: string;
      territory: string;
      eventDuration: string;
      eventDescription: string;
    };
  };
}

const EventsCalendarDay: FC<Props> = ({ eventData, handleNavigateToEventDetail, currentView }) => {
  const { event } = eventData;
  const { eventType, accountName, surgeonFirstName, surgeonLastName, eventId } = event;
  const iconPath = getEventIconInner(eventType);

  return (
    <div>
      <Popover
        ariaLabelledby="ALTERNATIVE-HEADING"
        body={<EventDetailPanel eventData={eventData} />}
        heading={<EventDetailPanelHeading eventData={eventData} />}
        footer={
          <EventDetailPanelFooter
            eventData={eventData}
            handleNavigateToEventDetail={handleNavigateToEventDetail}
          />
        }
        id="popover-heading"
        align="bottom"
        position="overflowBoundaryElement"
      >
        <div className="calendercell-pill">
          {currentView === 'month' ? (
            <div>
              <span className="sub-title-text">
                <span className="header_image">
                  <img src={iconPath} className="logo-image" alt="company" />
                </span>
                <span>
                  {eventType === 'Surgery' &&
                    `${'(' + `${eventId || '--'}` + ')' + ' '}${accountName}`}
                  {eventType === 'Surgery-Usage Only' &&
                    `${'(' + `${eventId || '--'}` + ')' + ' '}${accountName}`}
                  {eventType === 'Cycle Count' &&
                    `${'(' + `${eventId || '--'}` + ')' + ' '}${accountName || ''}`}
                  {eventType !== 'Surgery' &&
                    eventType !== 'Surgery-Usage Only' &&
                    eventType !== 'Cycle Count' &&
                    `${'(' + `${eventId || '--'}` + ')' + ' '}${accountName}`}
                </span>
              </span>
            </div>
          ) : (
            <div>
              {eventType === 'Surgery' ||
              eventType === 'Surgery-Usage Only' ||
              eventType === 'Trial' ||
              eventType === 'Demo' ? (
                <div>
                  <span className="title-text">
                    <span className="header_image">
                      <img src={iconPath} className="logo-image" alt="company" />
                    </span>
                    <span>{eventId}</span>
                  </span>
                  <p className="sub-title-text">{accountName || ''}</p>
                  <p className="foter-text">
                    {surgeonFirstName || ''}
                    {surgeonLastName || ''}
                  </p>
                </div>
              ) : (
                <div>
                  <span className="title-text">
                    <span className="header_image">
                      <img src={iconPath} className="logo-image" alt="company" />
                    </span>
                    <span>{eventId}</span>
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};

/** Custom Component for Day view in calendar. **/
export default EventsCalendarDay;
