/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import './index.scss';
import { getUSDString, priceValidationIndicatorClass } from '../../../../../util/utilityFunctions';
import { USAGE_TYPE } from '../../../../../util/constants';

interface Props {
  usageDetails?: any;
  showPrice?: boolean;
  eventType?: string;
  type?: string;
}

interface LineItemProps {
  lineItem?: any;
  showPrice?: boolean;
  eventType?: string;
  orderType?: string;
  type?: string;
}

const LineItems: FC<Props> = ({ usageDetails, showPrice = false, eventType, type }) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    const { lineItems } = usageDetails;
    setItems(lineItems);
  }, [usageDetails]);
  return (
    <>
      {items &&
        items.map((lineItem: any) => (
          <LineItem
            lineItem={lineItem}
            showPrice={showPrice}
            eventType={eventType}
            type={type}
            orderType={usageDetails?.diplayOrderType}
          />
        ))}
    </>
  );
};

const LineItem: FC<LineItemProps> = ({ lineItem, showPrice, eventType, orderType, type }) => {
  return (
    <div className="line-item-container">
      <div className="line-item-surgery-sheet">
        <div className="line-item-product">
          <div
            className="line-item-product-desc"
            style={{
              color:
                !lineItem?.isValidLotSerialCombination &&
                lineItem.iscatalog &&
                lineItem.lotValidation === 'true' &&
                orderType === USAGE_TYPE.BILL_ONLY &&
                type !== 'Failed' &&
                type !== 'Processed'
                  ? 'red'
                  : '#080707',
            }}
          >
            {`${lineItem.productNumber} 
            ${lineItem.productdescription}`}
          </div>
        </div>
        <div className={priceValidationIndicatorClass(eventType, lineItem)}>
          {showPrice && (
            <span>
              {`
                ${getUSDString(lineItem.usgPrice || '')}
                `}
            </span>
          )}
        </div>
        <div className="line-item-qty">
          <span>{lineItem.actualQuantity}</span>
        </div>
      </div>
    </div>
  );
};

export default LineItems;
