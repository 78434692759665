/*
 * User Info GraphQL specification
 * @module src/graphql/getUserSubInventories
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/** User Info GraphQL API query */
export const GET_USER_SUB_INVENTORIES = gql`
  query getUserSubInventories {
    getUserSubInventories {
      id
      value
    }
  }
`;
