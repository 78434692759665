/*
 * User Info GraphQL specification
 * @module src/graphql/getUserInfo
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/** User Info GraphQL API query */
export const GET_SCREENS = gql`
  query getScreens($module: String) {
    getScreens(module: $module) {
      id
      moduleCode
      moduleName
      screenCode
      screenName
      serialNumber
      screenType
      description
    }
  }
`;
