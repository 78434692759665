/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Button from '@salesforce/design-system-react/components/button';
import './index.scss';

interface CustomTablePaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  oncPageChange: (event: any, newPage: number) => void;
  oncRowsPerPageChange?: (event: any) => void;
}

const CustomTablePagination: React.FC<CustomTablePaginationProps> = props => {
  const { count, page, rowsPerPage, oncPageChange } = props;

  const rangeStart = page * rowsPerPage + 1;
  const rangeEnd = Math.min((page + 1) * rowsPerPage, count);
  return (
    <div
      className="custom-pagination-container"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
        <div className="slds-col">
          <Button
            assistiveText={{ icon: 'Icon Bare Small' }}
            iconCategory="utility"
            iconName="chevronleft"
            iconSize="small"
            iconVariant="bare"
            onClick={(e: any): void => oncPageChange(e, page - 1)}
            disabled={page <= 0}
            variant="icon"
          />
        </div>
        <div className="slds-col slds-p-left_medium slds-p-right_medium">
          <span>{`${rangeStart} - ${rangeEnd}`}</span>
        </div>
        <div className="slds-col">
          <Button
            assistiveText={{ icon: 'Icon Bare Small' }}
            iconCategory="utility"
            iconName="chevronright"
            iconSize="small"
            iconVariant="bare"
            onClick={(e: any): void => oncPageChange(e, page + 1)}
            disabled={(page + 1) * rowsPerPage >= count}
            variant="icon"
          />
        </div>
      </div>
    </div>
  );
};

export default CustomTablePagination;
