/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import Icon from '@salesforce/design-system-react/components/icon';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';

interface Props {
  open?: boolean;
  code?: string;
  title: string;
  handleFilterOpening: (title: any, code: any) => void;
}

const Collapsible: React.FC<Props> = ({ open, children, title, handleFilterOpening, code }) => {
  return (
    <IconSettings iconPath="/icons">
      <div className="card" style={{ marginBottom: '5px' }}>
        {/* <div> */}
        <div
          // className=""
          style={{
            display: 'inline-block',
            width: '100%',
            background: '#f4f4f4',
            padding: '2px',
          }}
        >
          <span
            style={{
              display: 'inline-block',
              marginLeft: '5px',
              fontSize: '16px',
              fontWeight: 'normal',
            }}
          >
            {title}
          </span>
          <span
            aria-hidden="true"
            role="button"
            onClick={(): void => handleFilterOpening(title, code)}
            style={{ cursor: 'pointer', float: 'right' }}
          >
            {!open ? (
              <Icon
                assistiveText={{ label: 'Announcement' }}
                category="utility"
                name="chevrondown"
                size="x-small"
                style={{ fill: '#000', display: 'inline-block' }}
              />
            ) : (
              <Icon
                assistiveText={{ label: 'Announcement' }}
                category="utility"
                name="chevronup"
                size="x-small"
                style={{ fill: '#000', display: 'inline-block' }}
              />
            )}
          </span>
        </div>
        {/* </div> */}

        <div className="border-bottom">
          <div>
            {open && (
              <div className="p-3" style={{ padding: '10px' }}>
                {children}
              </div>
            )}
          </div>
        </div>
      </div>
    </IconSettings>
  );
};

export default Collapsible;
