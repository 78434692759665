import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import EventStatusBadge from '../../Shared/EventStatusBadge';

interface Status {
  eventStatus: string;
  visualIndicator?: string;
  integrationStatus?: string;
}
export interface Props {
  children?: FC;
  item?: Status;
}

const OrderRequestStatusCell: FC<Props> = ({
  item = {
    eventStatus: '',
    integrationStatus: '',
  },
}) => {
  const { eventStatus, integrationStatus } = item;
  return (
    <DataTableCell title={eventStatus || integrationStatus || 'NEW'}>
      <EventStatusBadge status={eventStatus || integrationStatus || 'NEW'} />
    </DataTableCell>
  );
};

export default OrderRequestStatusCell;
