/**
 * Module for Cancelling evnet creation.
 * @module src/components/CreateEditSurgeryEvent/CancelEventCreationModal
 */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import '../index.scss';

interface Props {
  isReturn?: boolean;
  isCycleCount?: boolean;
  isInventory?: boolean;
  isTransfer?: boolean;
  isNew?: boolean;
  canceModalVisible: boolean;
  reloadModal: boolean;
  handleCancelModal: () => void;
  handleCancelModalConfirmPress: () => void;
  handleCrossButtonModal: () => void;
  edit?: boolean;
}

const CancelEventModal: FC<Props> = ({
  canceModalVisible,
  handleCancelModal,
  handleCancelModalConfirmPress,
  handleCrossButtonModal,
  reloadModal,
}) => {
  return (
    <Modal
      className="cancel-creation-modal default-modal_header default-modal_footer"
      isOpen={canceModalVisible}
      onRequestClose={handleCrossButtonModal}
      ariaHideApp={false}
      footer={[
        <Button label="Yes" onClick={handleCancelModalConfirmPress} key="yes" />,
        <Button label="No" variant="brand" onClick={handleCancelModal} key="no" />,
      ]}
      heading="Cancel Confirmation"
    >
      <div className="slds-text-align--center">
        <div>
          {reloadModal ? (
            <h1 className="slds-m-around--large ">
              You have unsaved data. Do you want to save it?
            </h1>
          ) : (
            <h1 className="slds-m-around--large ">
              You are leaving this page. Do you want to save?
            </h1>
          )}
        </div>
      </div>
    </Modal>
  );
};

/** Custom component for Cancelling evnet creation */
export default CancelEventModal;
