import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Button from '@salesforce/design-system-react/components/button';
import { BrowserRouter as Router, RouteComponentProps, withRouter } from 'react-router-dom';

interface EventId {
  caseExternalId: string;
  eventId: string;
  caseNumber: string;
  diplayordertype: string;
}

// interface for props that the data table cell is getting default from data table.
export interface Props extends RouteComponentProps {
  children?: FC;
  item?: EventId;
  type?: string;
}

const RecordCaseIdCell: FC<Props> = ({
  item = {
    caseExternalId: null,
    eventId: null,
    caseNumber: null,
    diplayordertype: null,
  },
  history,
  // type = null,
}) => {
  const { eventId, caseNumber, caseExternalId, diplayordertype } = item;
  const goToOrderDetails = (): void => {
    switch (diplayordertype) {
      case 'Bill Only':
        history.push('/OrdersUsageDetails', {
          externalId: caseExternalId,
        });
        break;
      case 'Pickup Return':
        history.push('/inventoryReturnDetails', {
          externalId: caseExternalId,
        });
        break;
      case 'Standard Return':
        history.push('/inventoryReturnDetails', {
          externalId: caseExternalId,
        });
        break;
      case 'Inventory Request':
        history.push('/inventoryRequestDetails', {
          externalId: caseExternalId,
        });
        break;
      case 'Rep to Customer':
        history.push('/inventoryTransferDetails', {
          externalId: caseExternalId,
        });
        break;
      case 'Rep to Rep':
        history.push('/inventoryTransferDetails', {
          externalId: caseExternalId,
        });
        break;
      default:
    }
  };

  return (
    <Router>
      <DataTableCell title={eventId || ''}>
        <Button label={eventId || caseNumber || 'TBD'} variant="base" onClick={goToOrderDetails} />
      </DataTableCell>
    </Router>
  );
};

export default withRouter(RecordCaseIdCell);
