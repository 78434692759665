/**
 * Module for Custom Procedure Cell.
 * @module src/Events/EventsList
 */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import './index.scss';

interface Procedures {
  procedures: string;
}
interface Props {
  item?: Procedures;
}
const EventsListProcedureCell: FC<Props> = ({
  item = {
    procedures: '',
  },
}) => {
  const { procedures } = item;
  const proceduresValue = procedures ? procedures.replace(/[{()}]/g, '').replace(/['"]+/g, '') : '';
  return (
    <DataTableCell title={proceduresValue || ''}>
      <p className="slds-line-clamp">{proceduresValue}</p>
    </DataTableCell>
  );
};

/** Custom Procedure cell */
export default EventsListProcedureCell;
