/*
 * Get Bill To Addresses GraphQL specification
 * @module src/graphql/getBillToAddresses
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_BILL_TO_ADDRESSES = gql`
  query getBillToAddresses($accountId: String!) {
    getBillToAddresses(accountId: $accountId) {
      id
      name
      label: address
      isPrimaryAddress
    }
  }
`;
