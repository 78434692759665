/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect, ReactNode } from 'react';
// import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Tab, Tabs, Paper } from '@material-ui/core';
import '../index.scss';
import CycleCount from '..';
import ReviewCycleCount from '../ReviewCounts';
import { getUserInfo } from '../../../store/ducks/userInfo';

const CycleCountTabs: FC<RouteComponentProps> = ({ history, location }) => {
  const [showCycleCount, setShowCycleCounts] = useState(false);
  const [showReviewCC, setShowReviewCycleCounts] = useState(false);
  const [tabPanel, setTabPanel] = useState<ReactNode>();
  const userInfo = useSelector(getUserInfo);

  useEffect(() => {
    const { pathname } = location;
    // setShowReviewCycleCounts(false);
    // setShowCycleCounts(false);

    if (pathname.includes('/cycleCount/ReviewCycleCount')) {
      setShowReviewCycleCounts(true);
      setShowCycleCounts(false);
      setTabPanel(<ReviewCycleCount type="REVIEW" />);
    } else {
      setShowCycleCounts(true);
      setShowReviewCycleCounts(false);
      setTabPanel(<CycleCount type="" />);
    }
  }, [location, userInfo]);

  const handleTabChange = (event: any): void => {
    const tabName = event.target.innerText;
    switch (tabName) {
      case 'All Counts':
        history.push('/cycleCount');
        return;
      case 'Review Counts':
        history.push('/cycleCount/ReviewCycleCount');
        return;
      default:
        history.push('/cycleCount');
    }
  };

  return (
    <div>
      <Paper className="paper" square>
        <Tabs
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          onChange={handleTabChange}
        >
          {((userInfo && userInfo.personas === 'Branch Op') ||
            userInfo?.personas === 'CSR;Branch Op' ||
            (userInfo && !!userInfo?.personas?.toLowerCase().includes('branch op'))) && (
            <Tab className={showCycleCount ? 'active' : 'inactive'} label="All Counts" />
          )}
          {((userInfo && userInfo.personas === 'CSR;Branch Op') ||
            (userInfo && userInfo.personas === 'Branch Op') ||
            (userInfo && !!userInfo?.personas?.toLowerCase().includes('branch op'))) && (
            <Tab className={showReviewCC ? 'active' : 'inactive'} label="Review Counts" />
          )}
        </Tabs>
      </Paper>
      <div className="slds-p-horizontal_large slds-p-vertical_x-large events_list">{tabPanel}</div>
    </div>
  );
};
export default withRouter(CycleCountTabs);
