/*
 * User Notifications GraphQL specification
 * @module src/graphql/getReservedRecordLinks
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/** User Info GraphQL API query */
export const GET_REVERVED_RECORD_LINKS = gql`
  query getReservedRecordLinks(
    $limit: Int
    $offset: Int
    $productId: String
    $orderBy: String
    $orderSortType: String
    $productLotNumber: String
    $subInventoyCode: String
  ) {
    getReservedRecordLinks(
      limit: $limit
      offset: $offset
      productId: $productId
      orderBy: $orderBy
      orderSortType: $orderSortType
      productLotNumber: $productLotNumber
      subInventoyCode: $subInventoyCode
    ) {
      caseId
      caseExternalId
      eventId
      orderType
      caseusageproductdescription
      caseUsageProductNumber
      caseUsageProductId
      salesRepId
      salesRep
      division
      eventstatus
      accountId
      accountNumber
      accountName
      diplayordertype
      qty
      createdDate
      totalPrice
      __typename
    }
  }
`;
