/**
 * Redux file for getting/setting event filters
 * @module src/store/ducks/inventoryReturnsFilters
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_IN_RETURNS_FILTERS = 'SET_IN_RETURNS_FILTERS';
const CLEAR_IN_RETURNS_FILTERS = 'CLEAR_IN_RETURNS_FILTERS';

interface SetInventoryReturnsFiltersActionType {
  payload: string | null;
  type: typeof SET_IN_RETURNS_FILTERS;
}

interface ClearInventoryReturnsFiltersActionType {
  type: typeof CLEAR_IN_RETURNS_FILTERS;
}

/** eventFilters action type */

export type InventoryReturnsFilterActionType =
  | SetInventoryReturnsFiltersActionType
  | ClearInventoryReturnsFiltersActionType;

/** set eventFilters action creator */

export const setInventoryReturnsFilters = (
  state: string | null
): InventoryReturnsFilterActionType => {
  return {
    payload: state,
    type: SET_IN_RETURNS_FILTERS,
  };
};

/** clear eventFilters action creator */

export const clearInventoryReturnsFilters = (): ClearInventoryReturnsFiltersActionType => {
  return {
    type: CLEAR_IN_RETURNS_FILTERS,
  };
};

/** eventFilters state type */

export type InventoryReturnsFilterState = string | null;

/** eventFilters reducer */

export default (
  state: InventoryReturnsFilterState = null,
  action: ActionType
): InventoryReturnsFilterState => {
  switch (action.type) {
    case SET_IN_RETURNS_FILTERS: {
      return action.payload;
    }
    case CLEAR_IN_RETURNS_FILTERS: {
      return null;
    }
    default:
      return state;
  }
};

/** selector to get accessToken */

export const getInventoryReturnsFilters = (state: State): InventoryReturnsFilterState => {
  return state.inventoryReturnsFilters;
};
