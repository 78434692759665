/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';

function Inventorycart(props: any) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.092 4H2.908a.903.903 0 00-.644.25.776.776 0 00-.264.58v3.405h1.35v17.451c0 .643.207 1.19.622 1.64.415.449.919.674 1.512.674h20.032c.573 0 1.072-.225 1.497-.675.425-.45.637-.996.637-1.639V8.236H29V4.83a.776.776 0 00-.264-.58.903.903 0 00-.644-.25zM10.1 8.235c-.746 0-1.35.632-1.35 1.412 0 .78.604 1.412 1.35 1.412h10.8c.746 0 1.35-.632 1.35-1.412 0-.78-.604-1.412-1.35-1.412H10.1z"
        fill={props.color}
      />
      {/* <path
        d="M18.662 16.51c0 1.241-.598 2.007-1.793 2.296v.028c.607.075 1.107.303 1.498.686.402.373.602.924.602 1.652 0 .597-.149 1.13-.448 1.596-.298.467-.742.835-1.33 1.106-.578.261-1.278.392-2.1.392-.774 0-1.446-.121-2.015-.364a5.131 5.131 0 01-1.583-1.078l.966-1.078c.43.355.84.611 1.232.77.393.15.86.224 1.4.224.598 0 1.083-.145 1.457-.434.373-.29.56-.7.56-1.232 0-.513-.145-.887-.434-1.12-.28-.233-.705-.35-1.274-.35h-1.597v-1.456h1.358c.533 0 .948-.112 1.246-.336.3-.233.448-.588.448-1.064 0-.467-.149-.821-.448-1.064-.298-.243-.737-.364-1.316-.364-.83 0-1.619.294-2.365.882l-.939-1.05a5.166 5.166 0 011.61-.994 5.081 5.081 0 011.877-.336c.7 0 1.306.121 1.82.364.513.233.9.555 1.162.966.27.401.405.854.405 1.358z"
        fill="#000"
      /> */}
      <text x="16" y="25" textAnchor="middle" fontSize="14" fill={props.textColor}>
        {props.count}
      </text>
    </svg>
  );
}

Inventorycart.propTypes = {
  count: PropTypes.number,
  color: PropTypes.string,
  textColor: PropTypes.string,
};

export default Inventorycart;
