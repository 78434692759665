/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Input from '@salesforce/design-system-react/components/input';
import { MAX_QUANTITY } from '../../../../util/constants';
import './index.scss';

interface Action {
  workflowAction: string;
  userworkflowAction?: [];
  footerRow?: boolean;
  quantity?: string;
}
export interface Props {
  children?: FC;
  item?: Action;
  viewOnly: boolean;
  type?: string;
  handleCount: (event: any, data: { value: string; number: number }, product: any) => void;
}

const InventoryCounterDataCell: FC<Props> = ({
  item = {
    workflowAction: null,
    userworkflowAction: [],
    lineVisualIndicator: '',
    quantity: '',
  },
  handleCount,
}) => {
  const { footerRow, quantity } = item;
  const [qty, setQuantity] = useState(quantity);
  useEffect(() => {
    setQuantity(quantity);
  }, [quantity]);
  return footerRow ? (
    <DataTableCell title="footer" />
  ) : (
    <DataTableCell key="-1">
      <Input
        id="counter-input-3"
        minValue={0}
        maxValue={MAX_QUANTITY}
        // disabled={
        //   !!(
        //     product &&
        //     product.lineVisualIndicator &&
        //     product.lineVisualIndicator.toLowerCase() === 'approved'
        //   )
        // }
        onChange={(event: any, data: { value: string; number: number }): void => {
          handleCount(event, data, item);
        }}
        value={Number(qty)}
        variant="counter"
      />
    </DataTableCell>
  );
};

export default InventoryCounterDataCell;
