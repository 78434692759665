/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line react/jsx-wrap-multilines
import React, { FC, useState } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Button from '@salesforce/design-system-react/components/button';
import './index.scss';

interface Props {
  commentModalVisible: boolean;
  closeModal: () => void;
  handleAdd: (value: string) => void;
  createCommentLoading: boolean;
}

const AddCommentsModal: FC<Props> = ({
  commentModalVisible,
  createCommentLoading,
  closeModal,
  handleAdd,
}) => {
  const [commentText, setCommentText] = useState('');

  const changeCommentData = (event: any): void => {
    const { value } = event.target;
    setCommentText(value);
  };
  return (
    <Modal
      isOpen={commentModalVisible}
      dismissOnClickOutside={false}
      id="internal_comment_modal"
      className="internal_comment_modal"
      ariaHideApp={false}
      footer={[
        createCommentLoading && (
          <div key="loader">
            <Spinner size="small" variant="base" assistiveText={{ label: 'Loading...' }} />
          </div>
        ),
        <Button
          label="Cancel"
          key="internal_cancel"
          className="slds-cancel-button_neutral"
          onClick={closeModal}
        />,

        <Button
          label="Add"
          key="internal_save"
          variant="brand"
          className="slds-button_brand"
          disabled={commentText === ''}
          onClick={(): void => handleAdd(commentText)}
        />,
      ]}
      onRequestClose={closeModal}
      heading={<div className="slds-float_left slds-text-title_bold">Add Comment</div>}
    >
      <section className="slds-p-around_medium slds-theme_shade">
        <div className="slds-m-bottom_medium slds-text-color_weak">
          Do not use to enter in patient information, complaints or set extension request (post
          shipping).
        </div>

        <textarea
          id="unique-id-1"
          rows={10}
          className="slds-textarea"
          value={commentText || ''}
          onChange={changeCommentData}
        />
      </section>
    </Modal>
  );
};

export default AddCommentsModal;
