import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS = gql`
  query getSubInventoryWithLotDetails(
    $limit: Int
    $offset: Int
    $subInventoryName: String
    $subInventoryCode: String
    $recordType: String
    $eventExternalId: String
    $salesRepId: String
    $tabName: String
    $filters: SubInventoryListFilters
  ) {
    getSubInventoryWithLotDetails(
      limit: $limit
      offset: $offset
      subInventoryName: $subInventoryName
      subInventoryCode: $subInventoryCode
      recordType: $recordType
      eventExternalId: $eventExternalId
      salesRepId: $salesRepId
      tabName: $tabName
      filters: $filters
    ) {
      subInventoryName
      subInventoryCode
      isBioTissuePart
      productNumber
      productDescription
      lotNumber
      serialNumber
      expirationDate
      productId
      priceBookEntryId
      unitPrice
      productCost
      salesUnitOfMeasure
      packContent
      deliveredDate
      availableQuantity
      onhandQuantity
      floorPrice
      listPrice
      targetprice
      baseorCapital
      productFamily
      isexpired
      isexpire30days
      isexpire90days
      productOnHold
      productOnHoldType
      serialRecalled
      actualQuantity
      dispositionQuantity
      isDisposition
      isrepstock
      sourceLocation
      isLotNumber
      resolvedPrice {
        contractName
        priceType
        price
        startDate
        endDate
      }
    }
  }
`;
