/*
 * Get Branches GraphQL specification
 * @module src/graphql/getBranches
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_ELIGIBLE_REPS_ACCOUNTS = gql`
  query getEligibleCycleCountAccountDetails($startDate: Date, $endDate: Date) {
    getEligibleCycleCountAccountDetails(startDate: $startDate, endDate: $endDate) {
      id
      name
      accounts {
        id
        number
        name
        branchId
        territory
      }
    }
  }
`;
