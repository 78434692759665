import { Form } from '../../components/Shared/FormEngine';

const pickUpReturn: Form = [
  {
    kind: 'field',
    name: 'topInfo',
    fieldLabel: '*Required fields',
    fieldType: 'text',
    required: true,
    optionsAutoSelected: false,
    permissionSets: [],
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'tabNavigator',
        fieldLabel: 'Tab Navigator',
        fieldType: 'tabNavigator',
        required: false,
        optionsPlaceHolder: '',
        optionsDynamic: '',
        isShow: true,
        optionsAutoSelected: false,
        optionsDependentField: [],
        optionsStatic: [
          { value: 'details', label: 'Details' },
          { value: 'parts', label: 'Parts' },
        ],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'salesRep',
        isShow: true,
        fieldLabel: 'Sales Rep',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: 'Sales Rep',
        optionsDynamic: 'getSalesReps',
        optionsAutoSelected: false,
        optionsDependentField: [],
        permissionSets: [],
        isFailed: true,
      },
      {
        kind: 'field',
        name: 'primaryAccount',
        fieldLabel: 'Account',
        isShow: true,
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: 'Pick an Account',
        optionsDynamic: 'getAccounts',
        optionsAutoSelected: false,
        optionsDependentField: ['salesRep'],
        permissionSets: [],
        isFailed: true,
      },
      {
        kind: 'field',
        name: 'shipTo',
        fieldLabel: 'Ship To',
        isShow: true,
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: 'Pick a Value',
        optionsDynamic: 'getShipToAddresses',
        optionsDependentField: ['primaryAccount'],
        optionsAutoSelected: false,
        permissionSets: [],
        isFailed: true,
      },
      {
        kind: 'field',
        name: 'expectedReturnDate',
        fieldLabel: 'Expected Return Date',
        fieldType: 'dateTimePicker',
        required: true,
        isShow: true,
        optionsAutoSelected: false,
        date: true,
        time: false,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'isshippingLabelRequired',
        fieldLabel: 'Request Shipping Label',
        fieldType: 'toogleSwitch',
        required: false,
        isShow: true,
        optionsAutoSelected: false,
        optionsPlaceHolder: '',
        optionsDynamic: '',
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'Attachments',
        fieldLabel: 'Attachments',
        fieldType: 'Attachments',
        required: false,
        isShow: true,
        optionsAutoSelected: false,
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'comments',
        fieldLabel: 'Comments',
        fieldType: 'comments',
        required: false,
        isShow: true,
        optionsAutoSelected: false,
        characterLimit: 32000,
        optionsPlaceHolder:
          'Do not use to enter patient information, complaints, or set extension request.',
        showOnWeb: true,
        permissionSets: [],
      },
    ],
  },
];

export default pickUpReturn;
