/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, ReactNode, useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import { useLazyQuery } from '@apollo/react-hooks';
import { getEventIcon } from '../../../util/utilityFunctions';
import InventoryRequest from '../InventoryRequest';
import CancelEventModal from '../../CreateEditSurgeryEvent/CancelEventCreationModal';
import { GET_FORM_CONFIGURATIONS } from '../../../graphql/getFormConfigurations';
import { getUserInfo } from '../../../store/ducks/userInfo';

interface Props {
  handleSearch: (event: any, val: string) => void;
  handleFilter: () => void;
  type?: string;
}

interface UserInfo {
  contactId?: string;
  division: string | null;
  divisions: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  personas: string;
  userName: string | null;
  userType: string;
  businessunit: string;
  assignedScreens: any;
}

const RequestHeader: FC<Props> = ({ handleSearch, handleFilter, type }) => {
  const userInfo: UserInfo | null = useSelector(getUserInfo);
  const [eventType, setSelectedEventType] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [canceModalVisible, setCanceModalVisible] = useState(false);
  const [getFormConfigurations, { data }] = useLazyQuery(GET_FORM_CONFIGURATIONS);
  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);

  const handleRequestInventory = (): void => {
    setSelectedEventType('Request Inventory');
    setIsOpen(true);
  };

  const handleModalToggle = useCallback((): void => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleCancelButton = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };
  const handleCancelModal = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };
  const handleCancelModalConfirmPress = (): void => {
    setCanceModalVisible(!canceModalVisible);
    handleModalToggle();
  };
  const requiredForms = userInfo?.assignedScreens;

  const headerActions = (): ReactNode => (
    <>
      <PageHeaderControl className="slds-m-left_large">
        <ButtonGroup variant="list" id="button-group-page-header-actions">
          {requiredForms?.find(
            (rec: any) => rec.screen === 'Inventory Request - Create' && rec.isRequired
          ) && (
            <Button
              iconCategory="utility"
              className="slds-text-color_default"
              label="New"
              iconName="add"
              iconPosition="left"
              onClick={handleRequestInventory}
            />
          )}
        </ButtonGroup>
      </PageHeaderControl>
    </>
  );

  const headerControls = (): ReactNode =>
    type !== 'FAVORITES' && (
      <>
        <PageHeaderControl className="slds-m-left_large">
          <Input
            iconRight={
              <InputIcon
                assistiveText={{
                  icon: 'Search',
                }}
                name="search"
                category="utility"
              />
            }
            id="search-by-product"
            onChange={(_event: any, searchItem: string): void => handleSearch(_event, searchItem)}
            placeholder="Search by Product"
          />
        </PageHeaderControl>
        <PageHeaderControl className="slds-m-left_large">
          <Button
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="filterList"
            label="Filter"
            iconPosition="left"
            onClick={handleFilter}
          />
        </PageHeaderControl>
      </>
    );

  const iconPath = getEventIcon('Inventory');

  return (
    <div>
      <IconSettings iconPath="/icons">
        <PageHeader
          className="events_header"
          icon={<img src={iconPath} className="logo-image" alt="orders" />}
          label="Inventory"
          onRenderActions={headerActions}
          onRenderControls={headerControls}
          title="Requests"
          truncate
          variant="object-home"
        />
      </IconSettings>
      {isOpen && (
        <InventoryRequest
          isOpen={isOpen}
          toggleOpen={handleModalToggle}
          formData={data}
          eventType={eventType}
          handleCancelButton={handleCancelButton}
        />
      )}
      {canceModalVisible && (
        <CancelEventModal
          isInventory
          canceModalVisible={canceModalVisible}
          handleCancelModal={handleCancelModal}
          handleCancelModalConfirmPress={handleCancelModalConfirmPress}
        />
      )}
    </div>
  );
};

export default RequestHeader;
