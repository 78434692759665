import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_CYCLE_COUNT_DETAILS = gql`
  query getCycleCountDetails($id: String, $externalId: String) {
    getCycleCountDetails(id: $id, externalId: $externalId) {
      caseNumber
      caseExternalId
      caseId
      eventType
      cycleCountExternalId
      cycleCountId
      Id
      startDate
      endDate
      status
      salesRep
      inventoryLocation
      territory
      countType
      isInventoryHold
      isInventoryConsigmentHold
      fieldOrg
      createdDate
      updatedDate
      updatedBy
      createdBy
      lineItems {
        sfid
        externalId
        productId
        quantity
        availableQuantity
        quantityDifference
        productCost
        costVariance
        lotNumber
        productNumber
        status
        productFamily
        productDescription
        createdBy
        createdDate
        expirationDate
        expectedQty
        pendingEimQty
        isLotNumber
        isSerialNumber
        isManual
        createdByDetails {
          sfid
          externalId
          quantity
          productId
          lotNumber
          createdByName
          createdById
          createdByPersona
          updatedById
          updatedByName
          comments {
            id
            externalId
            comments
            type
            user
            parentExternalId
            postTime
          }
        }
      }
      comments {
        id
        externalId
        value: comments
        type
      }
      salesRepDetails {
        salesRepId
        salesRepName
        salesRepStatus
        isSubmittedByOPS
      }
      opsDetails {
        opsUserRepId
        opsUserName
      }
      asrOrRsmDetails {
        asrOrRsmUserRepId
        asrOrRsmUserName
        asrOrRsmStatus
        isSubmittedByAsrOrRsm
      }
    }
  }
`;
