/*
 * User Info GraphQL specification
 * @module src/graphql/getUserInfo
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/** User Info GraphQL API query */
export const GET_USER_CONTACTS = gql`
  query getUserContacts($userId: String, $filters: UserContactsListFilters, $accountId: String) {
    getUserContacts(userId: $userId, filters: $filters, accountId: $accountId) {
      id
      name
      email
      isfavourite
      contactRole
    }
  }
`;
