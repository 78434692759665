/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Icon from '@salesforce/design-system-react/components/icon';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import './index.scss';

interface Action {
  workflowAction: string;
  userworkflowAction?: [];
  footerRow?: boolean;
  lineVisualIndicator?: string;
}
export interface Props {
  children?: FC;
  item?: Action;
  viewOnly: boolean;
  type?: any;
  handleUserWorkflowAction: (item: any) => void;
}

const InventoryApproveRejectDataCell: FC<Props> = ({
  item = {
    lineVisualIndicator: '',
    status: '',
  },
  type = null,
  handleUserWorkflowAction,
}) => {
  const { footerRow } = item;
  return footerRow ? (
    <DataTableCell key="-1" title="footer" />
  ) : (
    <IconSettings iconPath="/icons">
      <DataTableCell key={item} title={type === 'Processed' ? '' : 'Delete'}>
        <div>
          <div
            style={{
              margin: '5px',
              pointerEvents: type === 'Processed' ? 'none' : 'fill',
              opacity: type === 'Processed' ? 0.3 : 1,
            }}
            onClick={(): void => handleUserWorkflowAction(item)}
          >
            <Icon category="utility" name="delete" size="x-small" style={{ color: '#b83c27' }} />
          </div>
        </div>
      </DataTableCell>
    </IconSettings>
  );
};

export default InventoryApproveRejectDataCell;
