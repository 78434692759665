/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/**
 * React component for Time Picker.
 * @module src/components/shared/TimePicker
 */
import React, { FC, useCallback, useState, useEffect } from 'react';
import { format } from 'date-fns';
import Timepicker from '@salesforce/design-system-react/components/time-picker';

interface Props {
  label: string;
  placeholder?: string;
  handleChangeTime: (data: Date) => void;
  value: string;
  key?: string;
  disabled: boolean;
  showByDefault?: boolean;
  required?: boolean;
}

const TimePicker: FC<Props> = props => {
  const {
    label,
    placeholder,
    handleChangeTime,
    value,
    disabled,
    required,
    showByDefault = false,
  } = props;
  const [selectedTime, setSelectedTime] = useState('');

  // useEffect(() => {
  //   let date = new Date().setHours(8);
  //   date = new Date(date).setMinutes(0);
  //   if (!value) {
  //     handleChangeTime(new Date(date));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (value && showByDefault) {
      const time = format(new Date(value), 'hh:mm');
      setSelectedTime(time);
    }
  }, [showByDefault, value]);

  const handleChange = useCallback(
    (date: Date, inputStr: string) => {
      let hrs = inputStr.split(':')[0];
      if (inputStr.split(' ')[1] === 'PM' && Number(hrs) >= 1) {
        hrs = (Number(hrs) + 12).toString();
      }
      const mins = inputStr.split(':')[1];
      if (value) {
        let dateValue = new Date(value).setHours(Number(hrs));
        dateValue = new Date(dateValue).setMinutes(Number(mins && mins.split(' ')[0]));
        setSelectedTime(inputStr);
        handleChangeTime(new Date(dateValue));
      }
    },
    [handleChangeTime]
  );

  return (
    <div
      className="slds-form-element slds-m-bottom_small"
      key={label}
      style={{ marginTop: '24px' }}
    >
      <Timepicker
        required={required}
        label={label}
        placeholder={placeholder}
        inheritTargetWidth
        stepInMinutes={15}
        onDateChange={handleChange}
        strValue={selectedTime}
        value={new Date(value)}
        disabled={disabled}
        menuPosition="relative"
      />
    </div>
  );
};

/** React component for Time Picker. */
export default TimePicker;
