import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
//  Interface for Data table data type for all the columns
export interface Props {
  children?: FC;
  item?: AccountEventName;
}

interface AccountEventName {
  type?: string;
}
const OrderType: FC<Props> = ({
  item = {
    type: '',
  },
}) => {
  const { type } = item;
  return (
    <DataTableCell title={type || ''}>
      <p style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{type}</p>
    </DataTableCell>
  );
};

/** Custom Account/Event Name cell */
export default OrderType;
