/**
 * Module for Parts confirmation.
 * @module src/components/Inventory/InventoryRequestPartsConfirm
 */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import './index.scss';

interface Props {
  confirmModalVisible: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
}

const PartsConfirmModal: FC<Props> = ({ confirmModalVisible, handleCancel, handleConfirm }) => {
  return (
    <Modal
      className="parts-confirm-modal default-modal_header default-modal_footer"
      isOpen={confirmModalVisible}
      onRequestClose={handleCancel}
      ariaHideApp={false}
      footer={[
        <Button label="No" onClick={handleCancel} key="no" />,
        <Button label="Proceed" variant="brand" onClick={handleConfirm} key="yes" />,
      ]}
      heading="Parts Confirmation"
    >
      <div className="slds-text-align--center">
        <div>
          <h1 className="slds-m-around--large ">
            {`Some parts may be deleted as they might not be eligible for selected Account. Would you like to proceed?`}
          </h1>
        </div>
      </div>
    </Modal>
  );
};

/** Custom component for Parts confirmation */
export default PartsConfirmModal;
