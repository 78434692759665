import { Form } from '../../components/Shared/FormEngine';

const CustomerTransfer: Form = [
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'salesRep',
        fieldLabel: 'Sales Rep',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: 'Sales Rep',
        isShow: true,
        optionsDynamic: 'getSalesReps',
        optionsAutoSelected: false,
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'sourceAccount',
        fieldLabel: 'Source Account',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: 'Pick an Account',
        isShow: true,
        optionsDynamic: 'getSourceAccounts',
        optionsAutoSelected: true,
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'recievingRep',
        fieldLabel: 'Receiving Rep',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: 'Receiving Rep',
        isShow: true,
        optionsDynamic: 'getReceivingSalesReps',
        optionsAutoSelected: false,
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'Attachments',
        fieldLabel: 'Attachments',
        isShow: true,
        fieldType: 'Attachments',
        required: false,
        optionsAutoSelected: false,
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'comments',
        fieldLabel: 'Comments',
        fieldType: 'comments',
        required: false,
        optionsAutoSelected: false,
        isShow: true,
        characterLimit: 32000,
        optionsPlaceHolder:
          'Do not use to enter patient information, complaints, or set extension request.',
        showOnWeb: true,
        permissionSets: [],
      },
    ],
  },
];

export default CustomerTransfer;
