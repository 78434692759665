/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable radix */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Custom Header component.
 * @module src/components/App/CustomerHeader/index
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Popover from '@salesforce/design-system-react/components/popover';
import Button from '@salesforce/design-system-react/components/button';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { RouteComponentProps, withRouter, NavLink } from 'react-router-dom';
import Icon from '@salesforce/design-system-react/components/icon';
import GlobalNavigationBar from '@salesforce/design-system-react/components/global-navigation-bar';
import GlobalNavigationBarRegion from '@salesforce/design-system-react/components/global-navigation-bar/region';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import SnackBar from '../../Shared/SnackBar';
import { clearAccessToken, getAccessToken } from '../../../store/ducks/accessToken';
import { clearRefreshToken } from '../../../store/ducks/refreshToken';
import { clearUserId, getUserId } from '../../../store/ducks/userId';
import { clearUserInfo, getUserInfo, setUserInfo } from '../../../store/ducks/userInfo';
import { clearEventFilters } from '../../../store/ducks/eventFilters';
import { clearInventoryFilters } from '../../../store/ducks/inventoryFilters';
import { clearInventoryRequestFilters } from '../../../store/ducks/inventoryRequestFilters';
import { clearInventoryReturnsFilters } from '../../../store/ducks/inventoryReturnsFilters';
import { clearInventoryTransferFilters } from '../../../store/ducks/inventoryTransferFilters';
import { clearOrderFilters } from '../../../store/ducks/orderFilters';
import { clearNotificationFilters } from '../../../store/ducks/notificationFilters';
import { getSvgIcon } from '../../../util/utilityFunctions';
import UnreadNotificationsCount from '../../NotificationCenter/unreadNotifications';
import { GET_USER_NOTIFICATIONS_COUNT } from '../../../graphql/getUserNotificationCount';
import UPDATE_USER_BU from '../../../graphql/mutations/updateUserBusinessunit';
import { NOTIFY_TYPE } from '../../../util/constants';
import { GET_USER_INFO } from '../../../graphql/getUserInfoData';
import './index.scss';
import { clearHoldBannerStatus, getHoldBannerStatus } from '../../../store/ducks/holdBanner';

export interface Props extends RouteComponentProps {
  children?: FC;
  handleNotificationModal: () => void;
  refetchNotifications: () => void;
  isOpsUser: boolean;
  isCsrUser: boolean;
}

const CustomHeader: FC<Props> = ({
  handleNotificationModal,
  refetchNotifications,
  // isOpsUser,
  // isCsrUser,
  history,
  location,
}) => {
  const accessToken = useSelector(getAccessToken);
  const userInfo = useSelector(getUserInfo);
  const userId = useSelector(getUserId);
  const userHold = useSelector(getHoldBannerStatus);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [searchDisabled, setSearchDisabled] = useState(true);
  const { data: userInfoData } = useQuery(GET_USER_INFO, {
    fetchPolicy: 'no-cache',
    variables: { id: userId },
  });
  const { data: userNotificationsCount } = useQuery(GET_USER_NOTIFICATIONS_COUNT, {
    variables: {
      userId,
    },
  });
  // const { data: holddata } = useQuery(GET_USER_HOLD, {
  //   fetchPolicy: 'no-cache',
  //   variables: {
  //     id: userId,
  //   },
  // });
  const [updateBU, { data: updateBUdata }] = useMutation(UPDATE_USER_BU);
  const { open, notification, openSnackBar } = useSnackBar();
  const [userOnHold, setUserOnHold] = useState(userHold);

  // const selectedBusinessUnit =
  //   userInfo &&
  //   BUSINESSUNIT.map((element: any) =>
  //     element.key === userInfo.businessunit ? element.label : ''
  //   );

  useEffect(() => {
    if (history?.location?.state) {
      setSearchText(history?.location?.state?.inputText);
    }
    if (history?.location?.state?.inputText === '') {
      setSearchDisabled(true);
    }
  }, [history, history.location.state]);

  const handleLogoutClick = useCallback((): void => {
    dispatch(clearAccessToken());
    dispatch(clearRefreshToken());
    dispatch(clearUserId());
    dispatch(clearUserInfo());
    dispatch(clearEventFilters());
    dispatch(clearInventoryFilters());
    dispatch(clearInventoryRequestFilters());
    dispatch(clearInventoryReturnsFilters());
    dispatch(clearInventoryTransferFilters());
    dispatch(clearOrderFilters());
    dispatch(clearNotificationFilters());
    dispatch(clearHoldBannerStatus());
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout`;
  }, [dispatch]);
  const handleDropdownClick = (value: string) => (): void => {
    switch (value) {
      case 'logout':
        handleLogoutClick();
        break;
      case 'notifications':
        refetchNotifications();
        handleNotificationModal();
        break;

      default:
        break;
    }
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const checkIfActiveDetailPage = () => {
    const { pathname } = window.location;
    // eslint-disable-next-line default-case
    switch (pathname) {
      // use lowercase always
      case '/inventoryRequestDetails':
        history.push('/Inventory/Requests');
        break;
      case '/inventoryReturnDetails':
        history.push('/Inventory/Returns');
        break;
      case '/inventoryTransferDetails':
        history.push('/Inventory/Transfer');
        break;
      case '/OrdersUsageDetails':
        history.push('/Orders');
        break;
      case '/surgicalDetails':
        history.push('/Events');
        break;
      case '/cycleCountDetails':
        history.push('/cycleCount');
        break;
      case '/inventoryReturnApproval':
        history.push('/Inventory/review-return-request');
        break;
      case '/InventoryQueueRequestDetails':
        history.push('/Inventory/Review');
        break;
    }
  };

  useEffect(() => {
    // if (holddata?.getUserHoldInfo && holddata?.getUserHoldInfo?.length > 0) {
    setUserOnHold(userHold);
    // }
  }, [userHold]);

  useEffect(() => {
    if (updateBUdata && updateBUdata.updateUserBusinessunit) {
      if (updateBUdata.updateUserBusinessunit.message === 'success') {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Business unit updated successfully');
        dispatch(clearEventFilters());
        dispatch(clearInventoryFilters());
        dispatch(clearInventoryRequestFilters());
        dispatch(clearInventoryReturnsFilters());
        dispatch(clearInventoryTransferFilters());
        dispatch(clearOrderFilters());
        dispatch(clearNotificationFilters());
        setTimeout(() => {
          checkIfActiveDetailPage();
          window.location.reload();
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSnackBar, updateBUdata, userInfoData]);

  useEffect(() => {
    try {
      dispatch(setUserInfo(userInfoData ? userInfoData.getUserInfo[0] : {}));
    } catch (err) {
      // do nothing
    }
  }, [dispatch, userInfoData]);
  const checkIfActive = (link: string): boolean => {
    const { pathname } = window.location;
    const path = pathname.toLowerCase();
    const linkName = link.toLowerCase();
    switch (linkName) {
      // use lowercase always
      case 'events':
        return path === '/' || path.includes(linkName) || path.includes('surgicaldetails');
      case 'inventory':
        return (
          path.includes(linkName) || path.includes('partdetails') || path.includes('lotdetails')
        );
      // if a Navigation item needs to be active for more than one routes, add case here
      default:
        return path.includes(linkName);
    }
  };
  const handleComplaintManagement = (): void => {
    const url = 'https://www.stryker.com/productexperience/Legal/Agreement';
    const newWindow = window.open(url, '_blank');
    if (newWindow) newWindow.opener = null;
  };

  const handleSettingClick = (): void => {
    // if (userInfo && !userInfo.personas.toLowerCase().includes('business admin'))
    history.push('/notificationSettings');
  };

  const handleBUChange = (selectedBU: string) => (): void => {
    // if (userInfo && userInfo.personas.toLowerCase().includes('3pl ops')) {
    //   return;
    // }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let mutation: any = {};
    mutation = {
      userId,
      currentBusinessunit: selectedBU,
      previousBusinessunit: userInfo?.businessunit,
    };
    updateBU({
      variables: mutation,
    }).catch(error => {
      const errMsg = error.message.split(':')[1] || 'Something went wrong. Please try again.';
      openSnackBar(NOTIFY_TYPE.ERROR, errMsg);
    });
  };

  const getEnvironment = (): string => {
    if (window.location.host) {
      if (window.location.host.includes('test')) {
        return 'DEV';
      }
      if (window.location.host.includes('sit')) {
        return 'SIT';
      }
      if (window.location.host.includes('uat')) {
        return 'UAT';
      }
      if (window.location.host.includes('localhost')) {
        return 'LOCAL';
      }
      return 'PROD';
    }
    return 'LOCAL';
  };
  const iconPath = getSvgIcon('bellIcon');
  const hasNotifications = userNotificationsCount?.getUserNotificationCount?.notificationCount
    ? parseInt(userNotificationsCount?.getUserNotificationCount?.notificationCount)
    : 0;
  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      <div>
        {userOnHold && (
          <div className="holdBanner">
            <div className="holdText">
              <Icon
                category="utility"
                name="info"
                size="x-small"
                style={{
                  fill: 'white',
                  marginRight: '15px',
                }}
              />
              This account is currently on hold. Please contact Ops for further details
            </div>
          </div>
        )}
        <header className="main-header_container">
          <div className="slds-global-header logo-container">
            <div className="slds-global-header__item">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <a className="slds-global-header__logo" href="/">
                  <img src="/icons/stryker_logo.svg" className="logo-image" alt="company" />
                </a>
                {userInfo && (
                  <div style={{ fontSize: '20px', fontWeight: 'normal' }}>
                    {userInfo.businessunit}
                  </div>
                )}
                <div>
                  <GlobalNavigationBar className="main-header main-header-scondary">
                    <GlobalNavigationBarRegion region="secondary" navigation>
                      {userInfo && userId && (
                        <div>
                          {!location.pathname.includes('/globalsearch') && (
                            <div className="global-search-container slds-m-right_small nav-bar slds-grid slds-gutters">
                              <Input
                                onChange={(_event: any, data: any): void => {
                                  setSearchText(data.value);
                                  const searchData = data.value.toLowerCase().split('c-');
                                  const searchstr = searchData[searchData.length - 1];
                                  if (searchstr.length > 2) setSearchDisabled(false);
                                  else setSearchDisabled(true);
                                }}
                                value={searchText}
                                id="topGlobalSearch"
                                placeholder="Search"
                                className={searchDisabled ? 'searchDisable' : 'searchEnable'}
                                iconRight={
                                  searchText?.length > 0 ? (
                                    <InputIcon
                                      assistiveText={{
                                        icon: 'Clear',
                                      }}
                                      style={{
                                        marginTop: '-17px',
                                        marginRight: '-7px',
                                        width: '33px',
                                        paddingRight: '0px',
                                      }}
                                      name="clear"
                                      category="utility"
                                      // clearable={true}
                                      onClick={(): void => {
                                        setSearchDisabled(true);
                                        setSearchText('');
                                      }}
                                    />
                                  ) : (
                                    <></>
                                  )
                                }
                              />
                              <div
                                className={`global-search-button slds-x-small-buttons_horizontal ${
                                  searchDisabled ? ' searchDisable' : ' searchEnable'
                                }`}
                              >
                                <button
                                  type="button"
                                  className="slds-button slds-button_brand"
                                  onClick={(): void => {
                                    if (!searchDisabled) {
                                      history.push('/globalsearch', { searchText });
                                    } else {
                                      openSnackBar(
                                        'error',
                                        'Please enter atleast 3 digit to search'
                                      );
                                    }
                                  }}
                                >
                                  <img
                                    src={getSvgIcon('globalSearch')}
                                    alt="Global Search Icon"
                                    height="20px"
                                    width="20px"
                                  />
                                </button>
                              </div>
                            </div>
                          )}
                          <div className="nav-bar">
                            <NavLink
                              style={{ marginRight: '10px' }}
                              to="/notificationCenter"
                              className="nav-link"
                              activeClassName="active-nav-link"
                            >
                              {hasNotifications === 0 ? (
                                <img src={iconPath} className="logo-image" alt="company" />
                              ) : (
                                <UnreadNotificationsCount count={hasNotifications} />
                              )}
                            </NavLink>
                          </div>
                          <Icon
                            assistiveText={{ label: 'Announcement' }}
                            category="utility"
                            name="user"
                            size="x-small"
                            style={{ fill: '#000' }}
                          />
                          <div className="profile-dropdownmenu">
                            <Popover
                              style={{ width: 'max-content' }}
                              align="bottom"
                              ariaLabelledby=""
                              body={
                                <div className="profile-submenu">
                                  <div className="user-profile_dropdown">
                                    <h1>{`${userInfo.firstName} ${userInfo.lastName}`}</h1>
                                    <h2>{userInfo.personas}</h2>
                                    {/* {accessToken &&
                                      userId &&
                                      userInfo &&
                                      userInfo.personas &&
                                      !userInfo.personas
                                        .toLowerCase()
                                        .includes('business admin') && <p>{userInfo.divisions}</p>} */}
                                  </div>
                                  <div className="user-profile_dropdown_list">
                                    {accessToken && userInfo && userInfo.assignedBusinessunits && (
                                      <ul
                                        className="slds-dropdown__list"
                                        role="menu"
                                        aria-label="Show More"
                                      >
                                        {userInfo.assignedBusinessunits.map((element: string) => (
                                          <li
                                            className="slds-dropdown__header_list slds-truncate"
                                            role="separator"
                                          >
                                            <p
                                              className={
                                                element === userInfo.businessunit ? 'active-BU' : ''
                                              }
                                              aria-hidden="true"
                                              role="button"
                                              onClick={handleBUChange(element)}
                                              style={{ cursor: 'pointer' }}
                                            >
                                              {element}
                                            </p>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </div>
                                  <ul
                                    className="slds-dropdown__list"
                                    role="menu"
                                    aria-label="Show More"
                                  >
                                    <li
                                      className="slds-dropdown__header slds-truncate"
                                      role="separator"
                                    >
                                      <span
                                        aria-hidden="true"
                                        role="button"
                                        onClick={handleComplaintManagement}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        Complaint Management
                                      </span>
                                    </li>
                                    <li
                                      className="slds-dropdown__header slds-truncate"
                                      role="separator"
                                    >
                                      <span
                                        aria-hidden="true"
                                        role="button"
                                        style={{ cursor: 'pointer' }}
                                        onClick={handleSettingClick}
                                      >
                                        Settings
                                      </span>
                                    </li>

                                    <li className="slds-has-divider_top-space" role="separator" />
                                    <li className="slds-popover__item" role="presentation">
                                      <Button onClick={handleDropdownClick('logout')}>
                                        <span className="slds-truncate" title="Menu Item Two">
                                          <Icon
                                            assistiveText={{ label: 'XML' }}
                                            category="utility"
                                            name="logout"
                                            size="x-small"
                                            className="slds-m-right_x-small"
                                            style={{ fill: '#000' }}
                                          />
                                          Log Out
                                        </span>
                                      </Button>
                                    </li>
                                    <li className="slds-has-divider_top-space" role="separator" />
                                    <li className="slds-popover__item" role="presentation">
                                      <span className="profile-dropdownn-footer">
                                        {`EIM Version: ${getEnvironment()} - ${process.env
                                          .REACT_APP_VERSION || '0.0.1'}`}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              }
                              id="popover-walkthrough"
                            >
                              <Button
                                iconCategory="utility"
                                className="slds-text-color_default"
                                iconName="down"
                                label={
                                  Object.keys(userInfo).length > 0
                                    ? `${userInfo.firstName} ${userInfo.lastName}`
                                    : ''
                                }
                                iconPosition="right"
                              />
                            </Popover>
                          </div>
                        </div>
                      )}
                    </GlobalNavigationBarRegion>
                  </GlobalNavigationBar>
                </div>
              </div>
            </div>
          </div>
        </header>
        {accessToken && userId && userInfo && userInfo.personas && (
          <div className="eim-main-nav">
            <div className="nav-bar">
              <NavLink
                className="nav-link"
                activeClassName="active-nav-link"
                to="/Events"
                isActive={(): boolean => checkIfActive('Events')}
              >
                Events
              </NavLink>
              {!userInfo.personas.toLowerCase().includes('csr') && (
                <NavLink
                  className="nav-link"
                  activeClassName="active-nav-link"
                  to="/Inventory"
                  isActive={(): boolean => checkIfActive('Inventory')}
                >
                  Inventory
                </NavLink>
              )}
              {((userInfo && userInfo.personas === 'Agency OPS;Agency CSR') ||
                (userInfo && userInfo.personas === 'CSR;Branch Op') ||
                (userInfo && userInfo.personas === 'CSR;Branch Op;Business Admin')) && (
                <NavLink
                  className="nav-link"
                  activeClassName="active-nav-link"
                  to="/Inventory"
                  isActive={(): boolean => checkIfActive('Inventory')}
                >
                  Inventory
                </NavLink>
              )}
              {userInfo && !userInfo.personas.toLowerCase().includes('3pl ops') && (
                <NavLink
                  className="nav-link"
                  activeClassName="active-nav-link"
                  to="/Orders"
                  isActive={(): boolean => checkIfActive('Orders')}
                >
                  Orders
                </NavLink>
              )}
              {userInfo && userInfo.personas.toLowerCase().includes('branch op') && (
                <NavLink
                  className="nav-link"
                  activeClassName="active-nav-link"
                  to="/cycleCount"
                  isActive={(): boolean => checkIfActive('cycleCount')}
                >
                  Cycle Count
                </NavLink>
              )}

              {userInfo && userInfo.personas.toLowerCase().includes('business admin') && (
                <>
                  {/* <NavLink
                    className="nav-link"
                    activeClassName="active-nav-link"
                    to="/notificationConfiguration"
                    isActive={(): boolean => checkIfActive('notificationConfiguration')}
                  >
                    Admin
                  </NavLink> */}
                  <NavLink
                    className="nav-link"
                    activeClassName="active-nav-link"
                    to="/configurations"
                    isActive={(): boolean => checkIfActive('configurations')}
                  >
                    Configurations
                  </NavLink>
                </>
              )}
              {/* {!(
                (userInfo && userInfo.personas === 'Branch Op') ||
                (userInfo && userInfo.personas === 'Agency OPS')
              ) && (
                <>
                  <NavLink
                    className="nav-link"
                    activeClassName="active-nav-link"
                    to="/configurations"
                    isActive={(): boolean => checkIfActive('configurations')}
                  >
                    Configurations
                  </NavLink>
                </>
              )} */}
            </div>
          </div>
        )}
      </div>
    </IconSettings>
  );
};

/** Custom Header component */
export default withRouter(CustomHeader);
