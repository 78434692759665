/*
 * Filtered Accounts GraphQL specification
 * @module src/graphql/getFilteredAccounts
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Filtered Accounts GraphQL API query */
export const GET_FILTERED_ACCOUNTS = gql`
  query getAccounts($salesRepId: String, $limit: Int, $filters: AccountFilters) {
    getAccounts(salesRepId: $salesRepId, limit: $limit, filters: $filters) {
      territory
      id
      value: name
      number
      branchId
      isRepStockAccount
    }
  }
`;
