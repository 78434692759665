import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Button from '@salesforce/design-system-react/components/button';
import Spinner from '@salesforce/design-system-react/components/spinner';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import { useLazyQuery } from '@apollo/react-hooks';
import RecordStatusCell from './RecordStatusCell';
import RecordCaseIdCell from './RecordCaseIdCell';
import { GET_REVERVED_RECORD_LINKS } from '../../graphql/getReservedRecordLinks';
import './index.scss';

interface Sort {
  sortColumn: string;
  sortColumnDirection: {
    [key: string]: string;
  };
}

RecordCaseIdCell.displayName = DataTableCell.displayName;
RecordStatusCell.displayName = DataTableCell.displayName;

const ReservedTransactions: FC<RouteComponentProps> = ({ history, location }) => {
  const { productId, lotNumber, productDescription, productNumber, accountNumber } = location.state;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [recordsData, setRecordsData] = useState<any>([]);
  const [sortObj, setSortObj] = useState<Sort>();
  const [getReservedRecordLinks, { data: reservedRecordLinksData, loading }] = useLazyQuery(
    GET_REVERVED_RECORD_LINKS,
    {
      fetchPolicy: 'no-cache',
    }
  );
  useEffect(() => {
    if (reservedRecordLinksData && reservedRecordLinksData?.getReservedRecordLinks) {
      setRecordsData(reservedRecordLinksData?.getReservedRecordLinks);
    }
  }, [reservedRecordLinksData, setRecordsData]);

  useEffect(() => {
    if (productId) {
      if (lotNumber) {
        getReservedRecordLinks({
          variables: {
            limit: 50,
            offset: 0,
            productId,
            productLotNumber: lotNumber,
            subInventoyCode: accountNumber,
          },
        });
      } else {
        getReservedRecordLinks({
          variables: {
            limit: 50,
            offset: 0,
            productId,
            subInventoyCode: accountNumber,
          },
        });
      }
    }
  }, [accountNumber, getReservedRecordLinks, lotNumber, productId]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSort = (sortColumn: any): void => {
    const sortProperty = sortColumn.property;
    const { sortDirection } = sortColumn;
    const sort = {
      sortColumn: sortProperty,
      sortColumnDirection: {
        [sortProperty]: sortDirection,
      },
    };
    // needs to work in both directions
    setSortObj(sort);
    // sortedcolumn(sortColumn);
    if (productId) {
      if (lotNumber) {
        getReservedRecordLinks({
          variables: {
            limit: 50,
            offset: 0,
            orderBy: sortProperty,
            orderSortType: sortDirection,
            productId,
            productLotNumber: lotNumber,
            subInventoyCode: accountNumber,
          },
        });
      } else {
        getReservedRecordLinks({
          variables: {
            limit: 50,
            offset: 0,
            orderBy: sortProperty,
            orderSortType: sortDirection,
            productId,
            subInventoyCode: accountNumber,
          },
        });
      }
    }
  };

  return (
    <IconSettings iconPath="/icons">
      {loading && (
        <Spinner
          size="large"
          variant="base"
          assistiveText={{ label: 'Loading...' }}
          key="spinner"
        />
      )}
      <div className="slds-x-small-buttons_horizontal" style={{ padding: '10px 0px 10px 10px' }}>
        <Button
          style={{ color: 'black' }}
          iconCategory="utility"
          iconName="back"
          iconPosition="left"
          label="Back"
          onClick={history.goBack}
        />
      </div>
      {lotNumber ? (
        <PageHeader
          className="events_header lineBreak"
          title="Reserved Transactions"
          truncate
          info={`Part: ${productNumber || ''} - ${productDescription ||
            ''} \n Lot/Serial: ${lotNumber}`}
          variant="object-home"
        />
      ) : (
        <PageHeader
          className="events_header lineBreak"
          title="Reserved Transactions"
          truncate
          info={`Part: ${productNumber} - ${productDescription}`}
          variant="object-home"
        />
      )}
      <DataTable
        assistiveText={{
          columnSort: 'sort this column',
          columnSortedAscending: 'asc',
          columnSortedDescending: 'desc',
        }}
        items={recordsData || []}
        id="DataTableExample-2"
        className="font-12"
        fixedLayout
        keyboardNavigation
        joined
        onSort={handleSort}
      >
        <DataTableColumn
          key="eventId"
          label="Record"
          property="eventId"
          width="15%"
          isSorted={sortObj?.sortColumn === 'eventId'}
          sortable
          sortDirection={sortObj?.sortColumnDirection.eventId}
        >
          <RecordCaseIdCell />
        </DataTableColumn>
        <DataTableColumn
          key="type"
          label="Type"
          property="diplayordertype"
          width="15%"
          isSorted={sortObj?.sortColumn === 'diplayordertype'}
          sortable
          sortDirection={sortObj?.sortColumnDirection.diplayordertype}
        />
        <DataTableColumn
          key="type"
          label="Status"
          property="eventstatus"
          width="20%"
          isSorted={sortObj?.sortColumn === 'eventstatus'}
          sortable
          sortDirection={sortObj?.sortColumnDirection.eventstatus}
        >
          <RecordStatusCell />
        </DataTableColumn>
        <DataTableColumn
          key="type"
          label="Account"
          property="accountName"
          width="30%"
          isSorted={sortObj?.sortColumn === 'accountName'}
          sortable
          sortDirection={sortObj?.sortColumnDirection.accountName}
        />
        <DataTableColumn
          key="type"
          label="Line Total"
          property="totalPrice"
          width="30%"
          isSorted={sortObj?.sortColumn === 'totalPrice'}
          sortable
          sortDirection={sortObj?.sortColumnDirection.totalPrice}
        />
        <DataTableColumn
          key="type"
          label="Sales Rep"
          property="salesRep"
          width="20%"
          isSorted={sortObj?.sortColumn === 'salesRep'}
          sortable
          sortDirection={sortObj?.sortColumnDirection.salesRep}
        />
        <DataTableColumn
          key="type"
          label="Created Date"
          property="createdDate"
          width="30%"
          isSorted={sortObj?.sortColumn === 'createdDate'}
          sortable
          sortDirection={sortObj?.sortColumnDirection.createdDate}
        />
      </DataTable>
    </IconSettings>
  );
};

export default withRouter(ReservedTransactions);
