import { Form } from '../../components/Shared/FormEngine';

const HoldNewRequest: Form = [
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'user',
        fieldLabel: 'user',
        isShow: true,
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: '',
        optionsDynamic: 'getSalesReps',
        optionsAutoSelected: false,
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'holdReason',
        fieldLabel: 'Hold Reason',
        isShow: true,
        fieldType: 'textArea',
        required: true,
        optionsPlaceHolder: '',
        showOnWeb: true,
        optionsAutoSelected: false,
        permissionSets: [],
        showTooltip: false,
        characterLimit: 40,
      },
    ],
  },
];

export default HoldNewRequest;
