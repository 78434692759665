/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { format } from 'date-fns';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import { Grid } from '@material-ui/core';
import GridList from '@material-ui/core/GridList';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import GridListTile from '@material-ui/core/GridListTile';
import Paper from '@material-ui/core/Paper';
import './index.scss';
import { GET_PARTDETAILS, PartDetail } from '../../../graphql/getPartDetails';
import { getDateDifferenceInDays } from '../../../util/utilityFunctions';

interface FetchMoreType {
  variables: {
    offset: number;
    limit: number;
  };
  // TODO: Specify a more specific type
}
interface Props {
  // subInventoryNames: SubInventory[];
  fetchMore: (args0: FetchMoreType) => void;
  refetch: () => void;
  onrefresh: () => void;
  subInventoryName: string;
  productNumber: string;
}
interface Product {
  label: string;
}

const PartDetails: FC<RouteComponentProps> = ({ history, location }) => {
  const [getPartDetails, { data, loading }] = useLazyQuery(GET_PARTDETAILS, {
    fetchPolicy: 'no-cache',
  });
  const { state } = location;
  const { subInventoryName, productNumber } = state.item;
  const [partsList, setPartDetails] = useState<PartDetail[]>([]);
  const formatter = 'MM/dd/yy';

  useEffect(() => {
    // calling getSubInventories only for the first time to get initial data
    if (partsList && partsList.length === 0) {
      getPartDetails({
        variables: {
          subInventoryName,
          productNumber,
        },
      });
    }
  }, [partsList, getPartDetails, data, subInventoryName, productNumber]);

  useEffect(() => {
    // if data is coming from graphql, setting it in state to render
    // Also used for setting data when calling useLazyQuery object
    if (data && data.getPartDetails && data.getPartDetails.lotDetails) {
      setPartDetails(data.getPartDetails.lotDetails);
    }
  }, [partsList, data]);

  const handlePress = (item: PartDetail): void => {
    history.push('/LotDetails', { item, subInventoryName, productNumber });
  };
  return (
    <div>
      <div className="lotbacklink">
        <IconSettings iconPath="/icons">
          <Button
            style={{ color: 'black' }}
            iconCategory="utility"
            iconName="back"
            iconPosition="left"
            label="Back"
            onClick={history.goBack}
          />
        </IconSettings>
      </div>
      <div className="slds-p-horizontal_large slds-p-vertical_x-large events_list topheader">
        <IconSettings iconPath="/icons">
          <PageHeader
            className="inventory_header"
            icon={
              <Icon
                assistiveText={{ label: 'Opportunity' }}
                category="standard"
                name="event"
                style={{ backgroundColor: '#FFB500' }}
              />
            }
            info={[<span key="text" />]}
            label={productNumber}
            title={subInventoryName}
            truncate
            variant="object-home"
          />
          <div className="container">
            {loading ? (
              <Spinner
                size="large"
                variant="base"
                assistiveText={{ label: 'Loading...' }}
                key="spinner"
              />
            ) : (
              <div className="cardContainer">
                <div
                  style={{
                    right: '130px',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                  }}
                >
                  <div style={{ margin: '3px' }}>
                    <span className="availableqty__small" />
                    <span style={{ fontSize: '12px', marginLeft: '5px' }}>EIM Available</span>
                  </div>
                  <div style={{ margin: '3px' }}>
                    <span className="onhandqty__small" />
                    <span style={{ fontSize: '12px', marginLeft: '5px' }}>On Hand</span>
                  </div>
                </div>
                <PageHeader
                  title="Sub-Inventory"
                  className="inventory_sub_header"
                  info={partsList.map(item => (
                    <div
                      className="cardHeaderContainer"
                      onClick={(): void => handlePress(item)}
                      aria-hidden="true"
                    >
                      <Grid item xs={12}>
                        <Paper style={{ borderRadius: '10px' }}>
                          <GridList cols={12}>
                            <GridListTile
                              style={{
                                padding: '15px',
                                width: '80%',
                                height: '0%',
                                color:
                                  item.lotonhold ||
                                  (item.expirationDate &&
                                    getDateDifferenceInDays(
                                      new Date(item.expirationDate).toString(),
                                      new Date().toString()
                                    ) < 30)
                                    ? '#B71010'
                                    : 'black',
                              }}
                            >
                              <div className="card-header">{`Lot#: ${item.lotNumber}`}</div>
                              <div className="card-header">
                                {`Expiration Date: ${
                                  item.expirationDate
                                    ? format(new Date(item.expirationDate), formatter)
                                    : '--'
                                }`}
                              </div>
                            </GridListTile>
                            <GridListTile style={{ padding: '15px', width: '10%', height: '0%' }}>
                              <span className="availableqty">
                                {item.availableqty ? item.availableqty : 0}
                              </span>
                            </GridListTile>
                            <GridListTile style={{ padding: '15px', width: '10%', height: '0%' }}>
                              <span className="onhandqty">{item.onhandqty}</span>
                            </GridListTile>
                          </GridList>
                        </Paper>
                      </Grid>
                    </div>
                  ))}
                />
              </div>
            )}
          </div>
        </IconSettings>
      </div>
    </div>
  );
};
export default withRouter(PartDetails);
