import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Button from '@salesforce/design-system-react/components/button';
import { BrowserRouter as Router, RouteComponentProps, withRouter } from 'react-router-dom';

interface EventId {
  externalId: string;
  orderNumber: string;
  visualIndicator?: string;
  status?: string;
}

// interface for props that the data table cell is getting default from data table.
export interface Props extends RouteComponentProps {
  children?: FC;
  item?: EventId;
  transferType: string;
}

const TransferIdCell: FC<Props> = ({
  item = {
    externalId: null,
    orderNumber: null,
    status: '',
    visualIndicator: '',
  },
  transferType = '',
  history,
}) => {
  const { orderNumber, externalId, visualIndicator, status } = item;
  const goToTransferDetails = (): void => {
    history.push('/inventoryTransferDetails', {
      externalId,
      transferType,
      visualIndicator,
      status,
    });
  };

  return (
    <Router>
      <DataTableCell title={orderNumber || ''}>
        <Button label={orderNumber || 'TBD'} variant="base" onClick={goToTransferDetails} />
      </DataTableCell>
    </Router>
  );
};

export default withRouter(TransferIdCell);
