/**
 * Exchanges grant token for access and refresh tokens
 * @module src/util/exchangeTokens
 */
import env from './env';

/** function to exchange grant token for access and refresh tokens */
export default async (
  grantToken: string
): Promise<{ accessToken: string; refreshToken: string; id: string }> => {
  const payload = {
    grantToken,
  };
  const response = await fetch(`${env.REACT_APP_AUTH_URL}/grant`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  return response.json();
};
