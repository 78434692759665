/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import './index.scss';

interface Props {
  comments?: Comments[];
  type: string;
}

interface Comments {
  id?: string;
  value: string;
  type?: string;
  postTime?: any;
  user?: string;
}

const UsageComments: FC<Props> = ({ comments, type }) => {
  const [commentsList, setCommentList] = useState<Comments[]>();
  useEffect(() => {
    if (comments) {
      const cmnts = comments
        .filter((item: Comments) => item.type === type)
        .sort((a, b) => {
          return a?.postTime < b?.postTime ? -1 : a?.postTime > b?.postTime ? 1 : 0;
        });
      setCommentList(cmnts || []);
    }
  }, [comments, type]);
  return (
    <div className="slds-m-around_small">
      <div className="comments-container">
        {commentsList &&
          commentsList.map((comment: Comments) => (
            <div className="comment-body">
              <p className="comment-user-name">{comment.user || ''}</p>
              <h4 className="comment-timestamp">
                {`${moment(comment.postTime).format('L')} at ${moment(comment.postTime).format(
                  'LT'
                )}`}
              </h4>
              <p className="comments_text">{comment.value}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default UsageComments;
