/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, ReactNode, useState, useEffect, useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import Icon from '@salesforce/design-system-react/components/icon';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Popover from '@salesforce/design-system-react/components/popover';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import InventoryQueueFilter from '../InventoryQueueFilter';
import DeleteFilterPreset from './DeleteFilterPreset';
import { GET_INVENTORY_FILTER_PRESETS } from '../../../../graphql/getInventoryFilterPresets';
import DELETE_INVENTORY_FILTER_PRESET from '../../../../graphql/mutations/deleteInventoryFilterPreset';
// import './index.scss';
interface DynamicType {
  [key: string]: any;
}
interface Props {
  label: string;
  applyFilters: (filters: object | null) => void;
  handleSearch: (event: any, searchItem: any) => void;
  selectedFilter?: FilterPreset | DynamicType | null;
}

interface SelectedData {
  id: string;
  label: string;
  __typename?: string;
}
interface Filter {
  status: SelectedData[];
  salesReps: SelectedData[];
  coveringReps: SelectedData[];
  account: SelectedData[];
  branch: SelectedData[];
  franchise: SelectedData[];
  surgeon: SelectedData[];
  procedureDetail: SelectedData[];
  procedureType?: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  usage: string;
  eventType: string[];
  filterExternalId?: string;
  filterName?: string;
  search?: string;
}
interface FilterPreset {
  filterValues: Filter;
  filterExternalId?: string;
  nameOfFilter?: string;
}

const InventoryQueueHeader: FC<Props> = ({ label, applyFilters, handleSearch, selectedFilter }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [deleteFilterPreset] = useMutation(DELETE_INVENTORY_FILTER_PRESET);
  const [selectForDelete, setSelectForDelete] = useState<any>('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const handleToggleFilter = (): void => {
    setOpenFilter(!openFilter);
  };
  const [
    getFilterPresetsList,
    { data: filterPresetsList },
  ] = useLazyQuery(GET_INVENTORY_FILTER_PRESETS, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    getFilterPresetsList();
  }, [getFilterPresetsList]);

  const handleDeleteModalVisible = useCallback((): void => {
    setDeleteModalVisible(!deleteModalVisible);
  }, [deleteModalVisible]);

  const handleDeletePress = (filter: any): void => {
    handleDeleteModalVisible();
    setSelectForDelete(filter.nameOfFilter);
  };

  const handleDeleteFilterPreset = (): void => {
    deleteFilterPreset({
      variables: {
        nameOfFilter: selectForDelete,
      },
    }).then(response => {
      const deleteUsageResponse = response.data && response.data.deleteInventoryFilterPreset;
      if (deleteUsageResponse) {
        handleDeleteModalVisible();
        getFilterPresetsList();
        applyFilters({});
      }
    });
  };

  const handleFilterEventItems = (filter: any): void => {
    let reducedFilterValues = {};
    Object.keys(filter).forEach((key: string) => {
      if (key === 'serviceLevel' && filter[key]?.length > 0) {
        reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
      } else if (key === 'status' && filter[key]?.length > 0) {
        reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
      } else if (key === 'submittedDate' && filter[key]) {
        reducedFilterValues = {
          ...reducedFilterValues,
          submitFromDate: filter[key].from,
          submitToDate: filter[key].to,
        };
      } else if (key === 'needByDate' && filter[key]) {
        reducedFilterValues = {
          ...reducedFilterValues,
          needByDateFromDate: filter[key].from,
          needByDateToDate: filter[key].to,
        };
      } else if (key === 'territory' && filter[key]) {
        reducedFilterValues = { ...reducedFilterValues, [key]: filter[key].name };
      } else if (key === 'salesRep' && filter[key]?.length > 0) {
        reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
      } else if (key === 'recordType' && filter[key]?.length > 0) {
        reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
      } else if (key === 'productGroup' && filter[key]?.length > 0) {
        reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
      } else if (key === 'threePL' && filter[key]?.length > 0) {
        reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
      } else if (key === 'account' && filter[key]?.length > 0) {
        reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
      } else if (key === 'assignedTo' && filter[key]?.length > 0) {
        reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
      }
    });
    applyFilters(reducedFilterValues);
  };

  const headerActions = (): ReactNode => (
    <>
      <PageHeaderControl>
        <div className="slds-align-middle">
          <div className="slds-col_padded">
            <Input
              iconRight={
                <InputIcon
                  assistiveText={{
                    icon: 'Search',
                  }}
                  name="search"
                  category="utility"
                />
              }
              id="search-case-id"
              placeholder="Search by ID / Zip Code"
              onChange={(_event: any, searchItem: string): void => handleSearch(_event, searchItem)}
            />
          </div>
        </div>
      </PageHeaderControl>
      <PageHeaderControl className="slds-align-middle">
        <ButtonGroup variant="list" id="button-group-page-header-controls">
          <Button
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="filterList"
            label="Filter"
            iconPosition="left"
            onClick={handleToggleFilter}
          />
          <div className="slds-dropdown-trigger slds-dropdown-trigger_click filter-preset-popover">
            <Popover
              align="bottom"
              //   isOpen={filterPresetOpen}
              //   onClose={handleFilterPresetPopoverClose}
              //   onRequestClose={handleFilterPresetPopoverClose}
              ariaLabelledby=""
              body={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <div className="filter-preset_dropdown">
                  <ul className="slds-dropdown__list" role="menu" aria-label="Show More">
                    {filterPresetsList && filterPresetsList.getInventoryFilterPresets.length > 0
                      ? filterPresetsList.getInventoryFilterPresets.map((filter: FilterPreset) => {
                          return (
                            <li
                              className="slds-dropdown__item"
                              role="presentation"
                              key={filter.filterExternalId}
                            >
                              <Button
                                className="slds-button slds-button_neutral filter-preset-heading-button"
                                onClick={(): void => handleFilterEventItems(filter)}
                              >
                                {filter.nameOfFilter}
                              </Button>
                              <Button
                                // className="slds-button slds-button_neutral filter-preset-edit-button delete_button"
                                onClick={(): void => handleDeletePress(filter)}
                                className="delete_button"
                                // label="Delete Preset"
                                key="delete"
                              >
                                Delete
                              </Button>
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
              }
              id="popover-walkthrough"
            >
              <Button
                className="filter-drop-trigger"
                iconCategory="utility"
                iconName="filter"
                iconPosition="left"
                label="Filter Presets"
                // onClick={() => handleFilterPresetPopover}
              />
            </Popover>
          </div>
        </ButtonGroup>
      </PageHeaderControl>
    </>
  );

  return (
    <IconSettings iconPath="/icons">
      <PageHeader
        className="transferdetail_header"
        icon={
          <Icon
            assistiveText={{ label: 'Opportunity' }}
            category="standard"
            name="assignment"
            style={{ backgroundColor: '#FFB500' }}
          />
        }
        label={label}
        onRenderActions={headerActions}
        // onRenderControls={headerControls}
        title="Inventory Requests"
        truncate
        variant="object-home"
      />
      <InventoryQueueFilter
        openFilter={openFilter}
        handleToggleFilter={handleToggleFilter}
        applyFilters={applyFilters}
        getFilterPresetsList={getFilterPresetsList}
        selectedFilter={selectedFilter}
      />
      {deleteModalVisible && (
        <DeleteFilterPreset
          deleteModalVisible={deleteModalVisible}
          handleDeleteModalVisible={handleDeleteModalVisible}
          handleDeleteFilterPreset={handleDeleteFilterPreset}
        />
      )}
    </IconSettings>
  );
};

export default InventoryQueueHeader;
