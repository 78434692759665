/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * Module for Custom Rep/Covering Cell.
 * @module src/Events/EventsList
 */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import { EXCLAMATION } from '../../../../util/constants';
import { getEventIcon, getSvgIcon } from '../../../../util/utilityFunctions';

interface Quantity {
  quantity: string;
  availablequantity: string;
  createdByDetails: any[];
  id: string;
  isOpen: boolean;
  needToDisplay: boolean;
}
interface Props {
  item?: Quantity;
  toggleOpen: (id: any, isOpenTab: any) => void;
}

const PartNumberCell: FC<Props> = ({
  item = {
    quantity: '',
    availablequantity: '',
    createdByDetails: [],
    id: '',
    isOpen: false,
    needToDisplay: false,
  },
  toggleOpen,
}) => {
  const { quantity, availablequantity, id, createdByDetails, isOpen, needToDisplay } = item;
  const iconPath = getEventIcon(EXCLAMATION);
  const iconright = getSvgIcon('rightArrow');
  const iconDown = getSvgIcon('downArrow');

  return needToDisplay ? (
    <DataTableCell title=" ">
      <div>
        {quantity !== availablequantity ? (
          <span style={{ padding: '10px' }}>
            <img src={iconPath} className="logo-image" alt="discrepancy" />
          </span>
        ) : (
          <span style={{ padding: '18px' }} />
        )}
        <span
          style={{ padding: '5px' }}
          title="Expand/Collapse"
          onClick={() => toggleOpen(id, isOpen)}
        >
          {isOpen ? (
            <img src={iconDown} className="logo-image" alt="expanded" />
          ) : (
            <img src={iconright} className="logo-image" alt="closed" />
          )}
        </span>
        {isOpen &&
          createdByDetails?.map((lineItem: any) => (
            <div className="border-line">
              <DataTableCell>{lineItem.createdByName}</DataTableCell>
            </div>
          ))}
      </div>
    </DataTableCell>
  ) : null;
};

/** Custom Rep/Covering cell */
export default PartNumberCell;
