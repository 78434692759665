/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_CASE_USAGE_SHEET_DETAILS = gql`
  query getCaseUsageSheetDetails($id: String, $externalId: String) {
    getCaseUsageSheetDetails(id: $id, externalId: $externalId) {
      caseId
      caseExternalId
      assignedUserId
      salesRepId
      salesRep
      eventType
      eventId
      division
      eventStatus
      serviceLevel
      shipMethod
      needByDate
      branchId
      branch
      accountId
      accountNumber
      accountName
      createdDate
      updatedDate
      updatedBy
      createdBy
      submittedDate
      eventDateTime
      parentEventStartDate
      shippingAddessLine1
      shippingAddessLine2
      shippingPostalcode
      shippingCity
      shippingState
      shippingId
      shippingAddressNumber
      billingAddessLine1
      billingAddessLine2
      billingPostalcode
      billingCity
      billingState
      billingId
      billingAddressNumber
      poNumber
      patientId
      surgeonId
      surgeonFirstName
      surgeonLastName
      surgeonErpCode
      procedures
      procedureId
      procedureName
      territoryNumber
      orderType
      repPrintedName
      hospitalPrintedName
      createrName
      createrPhone
      salesRepPhone
      salesRepEmail
      diplayOrderType
      parentEventId
      parentEventType
      parentEventStatus
      parentCaseExternalId
      parentCaseId
      parentEventSubType
      isTBAAccount
      isPrimaryApproval
      isCycleCountInitiated
      comments {
        id
        value: comments
        type
        user
        postTime
        __typename
      }
      lineItems {
        isFrieghtMisc
        sfid
        externalId
        productId
        lotNumber
        quantity
        actualQuantity
        productdescription
        status
        ircaseExternalId
        ireventID
        irCaseID
        needByDate
        serviceLevel
        shipToId
        workflowAction
        type
        serialNumber
        productNumber
        expirationDate
        availableQuantity
        onhandQuantity
        usgPrice
        usgTotalAmount
        priceListHeader
        priceListContract
        overridePrice
        priceOverride
        priceOverrideType
        floorPrice
        listPrice
        salesUnitOfMeasure
        targetPrice
        packContent
        wasted
        contractStartDate
        contractEndDate
        priceBuyingGroupName
        priceName
        productFamily
        baseorCapital
        majorCode
        lotValidation
        priceValidation
        productOnHold
        iscatalog
        sourceLocation
        isLotNumber
        __typename
        iscatalog
        isValidLotSerialCombination
        isBioTissuePart
        isSelected
        caseUsageSubInventoryCode
      }
      attachments {
        id
        caseId
        caseExternalId
        recordType
        attachmentType
        showOnUsageSheet
        name
        createdBy
        createdAt
        imageurl
        user
        __typename
      }
      __typename
    }
  }
`;
