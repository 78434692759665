import gql from 'graphql-tag';

const ASSIGN_INVENTORY_REQUEST = gql`
  mutation assignInventoryRequest($externalId: String!, $assignedUser: String!) {
    assignInventoryRequest(externalId: $externalId, assignedUser: $assignedUser) {
      message
    }
  }
`;

export default ASSIGN_INVENTORY_REQUEST;
