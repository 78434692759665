/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Combobox from '@salesforce/design-system-react/components/combobox';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
// import Spinner from '@salesforce/design-system-react/components/spinner';
// import './index.scss';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Pill from '@salesforce/design-system-react/components/pill';
import SaveFilter from './SaveFilter';
import DatePicker from '../../../Shared/DatePicker';
import AutocompleteSingleSelectPicker from '../../../Shared/AutocompleteSingleSelectPicker';
import { GET_SALES_REPS } from '../../../../graphql/getSalesReps';
import { GET_USER_TERRITORIES } from '../../../../graphql/getUserTerritories';
import CREATE_UPDATE_INVENTORY_FILTER_PRESET from '../../../../graphql/mutations/createUpdateInventoryFilterPreset';
import {
  INVENTORY_STATUS_OPTIONS,
  INVENTORY_SERVICE_LEVEL_OPTIONS,
  RECORD_TYPE_OPTIONS,
  CATALOG_PARTS_OPTIONS,
  INVENTORY_3PL_OPTIONS,
  FILTER_ORDER_REVIEW_CATALOGED_PARTS_OPTIONS,
} from '../../../../util/constants';
import { GET_PRODUCT_GROUP } from '../../../../graphql/getProductGroups';
import { GET_PRIMARY_ACCOUNT } from '../../../../graphql/getPrimaryAccount';
import { GET_OPS_LIST } from '../../../../graphql/getOpsList';
import { getUserInfo } from '../../../../store/ducks/userInfo';

interface DynamicType {
  [key: string]: any;
}
interface Props {
  openFilter: boolean;
  handleToggleFilter: () => void;
  applyFilters: (filters: object | null) => void;
  getFilterPresetsList: () => void;
  selectedFilter?: DynamicType | null;
}

interface SelectedData {
  id: string;
  label: string;
}
interface DropdownSelect {
  id?: string;
  label?: string;
  value?: string;
}

interface DateFilter {
  submitFromDate?: string;
  submitToDate?: string;
  needByDateFromDate?: string;
  needByDateToDate?: string;
}

interface OpsDropdownSelect {
  label: string;
  value: string;
  id: string;
}

const optionsWithLabel = (arr: []): any[] =>
  arr &&
  arr.map((elem: any) => {
    return { ...elem, label: `${elem.value}` };
  });

const optionsWithLabelAccount = (arr: []): any[] =>
  arr &&
  arr.map((elem: any) => {
    return {
      ...elem,
      label:
        elem?.isRepStockAccount === true
          ? ` * ${elem.number} ${elem.value}`
          : ` ${elem.number} ${elem.value}`,
    };
  });

const InventoryQueueFilter: FC<Props> = ({
  openFilter,
  handleToggleFilter,
  applyFilters,
  getFilterPresetsList,
  selectedFilter,
}) => {
  const filterName = '';
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const { data: salesRepsData } = useQuery(GET_SALES_REPS);
  const location = useLocation();
  const [getTerritories, { data: territoryData }] = useLazyQuery(GET_USER_TERRITORIES);
  // const { data: opsData } = useQuery(GET_OPS_LIST);
  const [getOpsList, { data: opsData }] = useLazyQuery(GET_OPS_LIST);
  const [open, setOpen] = useState(false);
  const [openType, setOpenType] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [salesRepOptions, setSalesRepOptions] = useState<DropdownSelect[]>();
  const [accountsOptions, setAccountsOptions] = useState<DropdownSelect[]>();
  const [selectedSalesRep, setSelectedSalesRep] = useState<DropdownSelect[]>();
  const [selectedAccount, setSelectedAccount] = useState<DropdownSelect[]>();
  const [territoryOptions, setTerritoryOptions] = useState<DropdownSelect[]>();
  const [selectedTerritory, setSelectedTerritory] = useState<DropdownSelect[]>();
  const [selectedStatus, setSelectedStatus] = useState<DropdownSelect[]>();
  const [selectedCatalogedParts, setSelectedCatalogedParts] = useState<DropdownSelect[]>();
  const [selectedRecordType, setSelectedRecordType] = useState<DropdownSelect[]>();
  const [selectedThreePl, setSelectedThreePl] = useState<DropdownSelect[]>();
  const [selectedAssignedTo, setSelectedAssignedTo] = useState<DropdownSelect[]>();
  const [createFilterPreset] = useMutation(CREATE_UPDATE_INVENTORY_FILTER_PRESET);
  const [selectedServiceLevel, setSelectedServiceLevel] = useState<DropdownSelect[]>();
  const [selectedProductGroup, setSelectedProductGroup] = useState<DropdownSelect[]>();
  const [dates, setDates] = useState<DateFilter | null>(null);
  const [filter, setFilter] = useState<DynamicType | null>(selectedFilter || null);
  const [productGroup, { data: productGroupItems }] = useLazyQuery(GET_PRODUCT_GROUP);
  const productGroupList = productGroupItems?.getProductGroups;
  const [options, setOptions] = useState<OpsDropdownSelect[]>();
  const optionsWithLabelProduct =
    productGroupList &&
    productGroupList.map((elem: any) => {
      // eslint-disable-next-line no-underscore-dangle
      return { ...elem, label: `${elem.value}` };
    });
  const userInfo = useSelector(getUserInfo);
  const [getPrimaryAccountItems, { data: primaryAccountItems }] = useLazyQuery(
    GET_PRIMARY_ACCOUNT,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const accountItemsData = primaryAccountItems?.getPrimaryAccount;
  useEffect(() => {
    if (salesRepsData?.getSalesReps) {
      setSalesRepOptions(optionsWithLabel(salesRepsData.getSalesReps));
    }
  }, [salesRepsData]);
  useEffect(() => {
    if (accountItemsData) {
      setAccountsOptions(optionsWithLabelAccount(accountItemsData));
    }
  }, [accountItemsData]);
  useEffect(() => {
    getPrimaryAccountItems({
      variables: {
        salesRepId: userInfo?.id,
      },
    });
  }, [getPrimaryAccountItems, userInfo]);
  useEffect(() => {
    if (userInfo) {
      getOpsList({
        variables: {
          personaName: userInfo?.personas,
        },
      });
    }
  }, [getOpsList, userInfo]);
  useEffect(() => {
    productGroup();
  }, [productGroup]);

  useEffect(() => {
    if (selectedFilter) {
      if (selectedFilter.assignedTo) {
        const filteredAssign = options?.filter(
          (item: any) => item.id === selectedFilter.assignedTo
        );
        setSelectedAssignedTo(filteredAssign);
      } else {
        setSelectedAssignedTo([]);
      }
      if (selectedFilter.status) {
        const output = selectedFilter.status.map((item: any) => ({
          id: item,
          value: item,
          label: item.toUpperCase(),
        }));
        setSelectedStatus(output);
      } else {
        setSelectedStatus([]);
      }
      if (selectedFilter.productGroup) {
        const output = selectedFilter.productGroup.map((item: any) => ({
          id: item,
          value: item,
          label: item.toUpperCase(),
        }));
        setSelectedProductGroup(output);
      } else {
        setSelectedProductGroup([]);
      }
      if (selectedFilter.serviceLevel) {
        const output = selectedFilter.serviceLevel.map((item: any) => ({
          id: item,
          value: item,
          label: item.toUpperCase(),
        }));
        setSelectedServiceLevel(output);
      } else {
        setSelectedServiceLevel([]);
      }
      if (selectedFilter.zipCode) {
        const output = selectedFilter.zipCode.map((item: any) => ({
          id: item,
          value: item,
          label: item.toUpperCase(),
        }));
        setSelectedTerritory(output);
      } else {
        setSelectedTerritory([]);
      }
      if (selectedFilter.iscataloged) {
        const output = selectedFilter.iscataloged.map((item: any) => ({
          id: item,
          value: item,
          label: item,
        }));
        setSelectedCatalogedParts(output);
      } else {
        setSelectedCatalogedParts([]);
      }
      if (selectedFilter.threePL) {
        const output = selectedFilter.threePL.map((item: any) => ({
          id: item,
          value: item,
          label: item.toUpperCase(),
        }));
        setSelectedThreePl(output);
      } else {
        setSelectedThreePl([]);
      }
      if (selectedFilter.recordType) {
        const output = selectedFilter.recordType.map((item: any) => ({
          id: item,
          value: item,
          label: item.toUpperCase(),
        }));
        setSelectedRecordType(output);
      } else {
        setSelectedRecordType([]);
      }

      if (selectedFilter.salesRep) {
        const filteredSalesRep = salesRepOptions?.filter(
          (item: any) => item.id === selectedFilter.salesRep
        );
        setSelectedSalesRep(filteredSalesRep);
      } else {
        setSelectedSalesRep([]);
      }
      if (selectedFilter.account) {
        const filteredSalesRep = accountsOptions?.filter(
          (item: any) => item.id === selectedFilter.account
        );
        setSelectedAccount(filteredSalesRep);
      } else {
        setSelectedAccount([]);
      }
    }
  }, [
    options,
    selectedFilter,
    salesRepOptions,
    accountsOptions,
    setSelectedAssignedTo,
    setSelectedAccount,
    setSelectedStatus,
    setSelectedProductGroup,
    setSelectedServiceLevel,
    setSelectedTerritory,
    setSelectedCatalogedParts,
    setSelectedThreePl,
    setSelectedRecordType,
    setSelectedSalesRep,
  ]);

  useEffect(() => {
    if (opsData && opsData?.getOPSList) {
      const optionList = optionsWithLabel(opsData?.getOPSList);
      optionList.unshift({ id: 'Unassigned', value: 'Unassigned', label: 'UNASSIGNED' });
      setOptions(optionList);
    }
  }, [opsData]);

  // useEffect(() => {
  //   if (productGroupList && selectedProductGroup) {
  //     const record =
  //       productGroupList && productGroupList.filter((rec: any) => rec.id === selectedProductGroup);
  //     const optionsWithLabel1 =
  //       record &&
  //       record.map((elem: any) => {
  //         // eslint-disable-next-line no-underscore-dangle
  //         return { ...elem, label: `${elem.id}` };
  //       });
  //     setSelectedProductGroup(optionsWithLabel1);
  //   }
  // }, [productGroupList, selectedProductGroup]);
  useEffect(() => {
    if (selectedSalesRep && selectedSalesRep[0]?.id) {
      getTerritories({
        variables: {
          userId: selectedSalesRep[0]?.id,
        },
      });
    } else {
      getTerritories();
    }
  }, [getTerritories, selectedSalesRep]);
  useEffect(() => {
    if (selectedFilter) {
      setFilter(selectedFilter);
    }
  }, [selectedFilter]);

  useEffect(() => {
    if (selectedSalesRep) {
      setSelectedTerritory([]);
    }
  }, [selectedSalesRep]);

  useEffect(() => {
    if (selectedSalesRep) {
      setSelectedTerritory([]);
    }
  }, [selectedSalesRep]);

  useEffect(() => {
    if (territoryData?.getUserTerritories) {
      setTerritoryOptions(optionsWithLabel(territoryData.getUserTerritories));
    }
  }, [territoryData]);

  const handleSaveModalVisible = (): void => {
    setSaveModalVisible(!saveModalVisible);
  };

  const handleCreateFilterPreset = (name: string): void => {
    let reducedFilterValues = {};
    if (filter) {
      Object.keys(filter).forEach((key: string) => {
        if (Array.isArray(filter[key]) && key !== 'eventType') {
          const values = filter[key].map((item: SelectedData) => {
            const itemCopy = { id: item.id, label: item.label };
            return { ...itemCopy };
          });
          if (key === 'salesRep' || key === 'assignedTo' || key === 'zipCode') {
            reducedFilterValues = { ...reducedFilterValues, [key]: values[0] };
          } else {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
          if (
            (key === 'serviceLevel' ||
              key === 'recordType' ||
              key === 'productGroup' ||
              key === 'status' ||
              key === 'threePL' ||
              key === 'account' ||
              key === 'assignedTo' ||
              key === 'salesRep') &&
            filter[key]
          ) {
            reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
          }
        } else if (key === 'serviceLevel' && filter[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filter[key] };
        } else if (key === 'status' && filter[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: { name: filter[key] } };
        } else if (key === 'needByDateFromDate' && filter[key]) {
          reducedFilterValues = {
            ...reducedFilterValues,
            needByDate: { from: filter[key], to: filter.needByDateToDate },
          };
        } else if (key === 'submitFromDate' && filter[key]) {
          reducedFilterValues = {
            ...reducedFilterValues,
            submittedDate: { from: filter[key], to: filter.submitToDate },
          };
        } else if (
          (key === 'salesRep' || key === 'assignedTo' || key === 'zipCode') &&
          filter[key]
        ) {
          reducedFilterValues = {
            ...reducedFilterValues,
            [key]: { id: filter[key], name: filter[key] },
          };
        }
      });
    }

    const filterData: any = { ...reducedFilterValues };
    filterData.nameOfFilter = name;
    createFilterPreset({
      variables: filterData,
    })
      .then(res => {
        if (res && res.data && res.data.createUpdateInventoryFilterPreset) {
          setSaveModalVisible(!saveModalVisible);
          applyFilters(filter);
          handleToggleFilter();
          getFilterPresetsList();
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('error', error);
      });
  };

  const handleChangeDate = (selectedDate: any, name: string): void => {
    if (selectedDate && name) {
      const obj = {
        ...filter,
        [name]: moment(selectedDate).format('YYYY-MM-DD'),
      };
      setFilter(obj);
      setDates({ ...dates, [name]: selectedDate });
    }
  };

  const handleSelect = (data: any, name: any): void => {
    if (data.selection) {
      const filterData = data.selection.map((a: any) =>
        name === 'salesRep' || name === 'assignedTo' || name === 'iscataloged' ? a.id : a.value
      );

      switch (name) {
        case 'territory':
          setSelectedTerritory(data.selection);
          break;
        case 'status':
          setSelectedStatus(data.selection);
          break;
        case 'assignedTo':
          setSelectedAssignedTo(data.selection);
          break;
        case 'iscataloged':
          setSelectedCatalogedParts(data.selection);
          break;
        case 'threePL':
          setSelectedThreePl(data.selection);
          break;
        case 'recordType':
          setSelectedRecordType(data.selection);
          break;
        case 'productGroup':
          setSelectedProductGroup(data.selection);
          break;
        case 'serviceLevel':
          setSelectedServiceLevel(data.selection);

          break;
        default:
      }
    } else {
      if (name === 'salesRep') {
        setSelectedSalesRep(data);
      }
      if (name === 'account') {
        setSelectedAccount(data);
      }
      if (name === 'assignedTo') {
        setSelectedAssignedTo(data);
      }
      const filterData = data.map((a: any) => a.id);
      const obj = {
        ...filter,
        [name]: filterData,
      };
      setFilter(obj);
    }
    let obj = {};
    if (name === 'salesRep') {
      setSelectedSalesRep(data);
      obj = {
        ...filter,
        [name]: data[0] ? data[0].id || data[0].value : '',
      };
    }
    setFilter(obj);
  };

  const handleApplyFilter = (): void => {
    applyFilters(filter);
    handleToggleFilter();
  };
  const resetFilters = (): void => {
    setSelectedSalesRep([]);
    setSelectedAccount([]);
    setSelectedTerritory([]);
    setSelectedStatus([]);
    setSelectedAssignedTo([]);
    setSelectedServiceLevel([]);
    setSelectedCatalogedParts([]);
    setSelectedThreePl([]);
    setSelectedRecordType([]);
    setSelectedProductGroup([]);
    setDates(null);
    setFilter(null);
    applyFilters(null);
  };
  const resetWithoutPopUpFilters = (): void => {
    setSelectedSalesRep([]);
    setSelectedTerritory([]);
    setSelectedStatus([]);
    setSelectedAssignedTo([]);
    setSelectedServiceLevel([]);
    setSelectedThreePl([]);
    setSelectedProductGroup([]);
    setDates(null);
    setFilter(null);
    applyFilters(null);
  };

  useEffect(() => {
    if (!selectedFilter?.iscataloged) {
      const catalog = FILTER_ORDER_REVIEW_CATALOGED_PARTS_OPTIONS?.map((item: any) => ({
        id: item?.id,
        label: item?.label,
      }));
      const filterData = FILTER_ORDER_REVIEW_CATALOGED_PARTS_OPTIONS.map((a: any) => a.id);
      const obj = {
        ...filter,
        iscataloged: filterData,
      };
      setFilter(obj);
      setSelectedCatalogedParts(catalog);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   resetWithoutPopUpFilters();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={openFilter}
        onRequestClose={handleToggleFilter}
        footer={[
          <Button
            className="slds-float_left slds-text-color_default"
            label="Save Filters"
            // disabled={
            //     selectedFilters?.endDate &&
            //     selectedFilters?.startDate &&
            //     selectedFilters.endDate < selectedFilters.startDate
            // }
            // key="sa"
            onClick={handleSaveModalVisible}
          />,
          <Button label="Reset" key="Reset" onClick={resetFilters} />,
          <Button
            className="yellow-btn"
            label="Apply Filters"
            onClick={handleApplyFilter}
            // disabled={
            //     selectedFilters?.endDate &&
            //     selectedFilters?.startDate &&
            //     selectedFilters.endDate < selectedFilters.startDate
            // }
            variant="brand"
            key="Apply"
          />,
        ]}
        heading={[
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div className="slds-float_left slds-text-title_bold filter_heading" key="filterName">
            Filters
          </div>,
        ]}
      >
        <section className="slds-p-around_medium slds-theme_shade">
          <div className="slds-card slds-p-around--medium">
            <span className="filtterTabName">Attributes</span>
            <Combobox
              id="recordType"
              labels={{
                label: `Record Type`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              isOpen={openType}
              multiple
              placeholder="Select"
              options={RECORD_TYPE_OPTIONS || []}
              selection={selectedRecordType || []}
              variant="readonly"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpen(false);
                  setOpenType(true);
                },
                onRequestClose: (event: any): void => {
                  setOpenType(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'recordType'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'recordType'),
              }}
            />
            <Combobox
              id="status"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpen(true);
                  setOpenType(false);
                },
                onRequestClose: (event: any): void => {
                  setOpen(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'status'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'status'),
              }}
              labels={{
                label: `Status`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              isOpen={open}
              multiple
              placeholder="Select"
              options={INVENTORY_STATUS_OPTIONS || []}
              selection={selectedStatus || []}
              variant="readonly"
            />
            <Combobox
              id="3PL Delivery"
              labels={{
                label: `3PL Delivery`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'threePL'),
              }}
              placeholder="Select"
              options={INVENTORY_3PL_OPTIONS || []}
              selection={selectedThreePl || []}
              variant="readonly"
            />
            <Combobox
              id="productGroup"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpen(false);
                  setOpenType(false);
                  setOpenProduct(true);
                },
                onRequestClose: (event: any): void => {
                  setOpenProduct(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'productGroup'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'productGroup'),
              }}
              labels={{
                label: `Product Group`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              isOpen={openProduct}
              multiple
              placeholder="Select"
              options={optionsWithLabelProduct || []}
              selection={selectedProductGroup ? selectedProductGroup || [] : []}
              variant="readonly"
            />
            <Combobox
              id="iscataloged"
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'iscataloged'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'iscataloged'),
              }}
              labels={{
                label: `Cataloged Parts`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              placeholder="Select"
              options={FILTER_ORDER_REVIEW_CATALOGED_PARTS_OPTIONS || []}
              selection={selectedCatalogedParts ? selectedCatalogedParts || [] : []}
              multiple
              variant="readonly"
            />
            {/* <Combobox
              id="productGroup"
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'productGroup'),
                // onRequestRemoveSelectedOption: (event: any, data: any): void =>
                //   handleSelect(data, 'productGroup'),
              }}
              labels={{
                label: `Product Group`,
                placeholder: 'Select Product Group',
                noOptionsFound: 'No data found',
              }}
              placeholder="Select Product Group"
              options={optionsWithLabelProduct || []}
              selection={selectedProductGroup ? selectedProductGroup || [] : []}
              variant="readonly"
            /> */}
          </div>
          {/* </section> */}
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--small">Date</h1>
            <div className="date-error">
              {((dates?.needByDateToDate &&
                dates?.needByDateFromDate &&
                dates?.needByDateToDate < dates?.needByDateFromDate) ||
                (dates?.submitToDate &&
                  dates?.submitFromDate &&
                  dates?.submitToDate < dates?.submitFromDate)) && (
                <Pill
                  labels={{
                    label: 'Error: To Date must be greater than or equal to From Date',
                  }}
                  hasError
                  // icon={
                  //   <Icon
                  //     title="Error"
                  //     category="utility"
                  //     name="warning"
                  //     className="slds-icon-text-error"
                  //   />
                  // }
                />
              )}
            </div>
            <div>
              <p className="dateLabel">Submitted Date</p>
              <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="From"
                    handleChangeDate={(data: any): void => handleChangeDate(data, 'submitFromDate')}
                    disabled={false}
                    isFilterDate
                    value={dates?.submitFromDate || null}
                  />
                </div>
                <div className="slds-col slds-align_absolute-center">
                  <span>-</span>
                </div>
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="To"
                    handleChangeDate={(data: any): void => handleChangeDate(data, 'submitToDate')}
                    disabled={false}
                    isFilterDate
                    value={dates?.submitToDate || null}
                  />
                </div>
              </div>
            </div>
            <div>
              <p className="dateLabel">Need by Date</p>
              <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="From"
                    handleChangeDate={(data: any): void => {
                      handleChangeDate(data, 'needByDateFromDate');
                    }}
                    disabled={false}
                    isFilterDate
                    value={dates?.needByDateFromDate || null}
                  />
                </div>
                <div className="slds-col slds-align_absolute-center">
                  <span>-</span>
                </div>
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="To"
                    handleChangeDate={(data: any): void => {
                      handleChangeDate(data, 'needByDateToDate');
                    }}
                    disabled={false}
                    isFilterDate
                    value={dates?.needByDateToDate || null}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <section className="slds-p-around_medium slds-theme_shade"> */}
          <div className="slds-card slds-p-around--medium">
            <span className="filtterTabName">Other</span>
            <p style={{ marginBottom: -12, fontSize: 16 }}>Sales Rep</p>
            <AutocompleteSingleSelectPicker
              items={salesRepOptions || []}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'salesRep');
              }}
              disabled={false}
              selectedItem={selectedSalesRep ? selectedSalesRep || [] : []}
              placeholder="Sales Rep"
              label=""
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="salesRep"
              autoSelectValue={false}
            />
            <AutocompleteSingleSelectPicker
              items={accountsOptions}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'account');
              }}
              disabled={false}
              selectedItem={selectedAccount || []}
              placeholder="Account"
              label="Account"
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="account"
              autoSelectValue={false}
            />
            {/* <Combobox
              id="zipCode"
              labels={{
                label: `Zip Code`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'zipCode'),
              }}
              placeholder="Select"
              options={[]}
              selection={selectedTerritory || []}
              variant="readonly"
            /> */}
            <AutocompleteSingleSelectPicker
              items={options}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'assignedTo');
              }}
              disabled={false}
              selectedItem={selectedAssignedTo || []}
              placeholder="Assigned To"
              label="Assigned To"
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="assignedTo"
              autoSelectValue={false}
            />
            <Combobox
              id="serviceLevel"
              labels={{
                label: `Service Level`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'serviceLevel'),
              }}
              placeholder="Select"
              options={INVENTORY_SERVICE_LEVEL_OPTIONS || []}
              selection={selectedServiceLevel || []}
              variant="readonly"
            />
          </div>
        </section>
        {saveModalVisible && (
          <SaveFilter
            handleSaveModalVisible={handleSaveModalVisible}
            saveModalVisible={saveModalVisible}
            handleCreateFilterPreset={handleCreateFilterPreset}
            selectedFilterName={filterName}
          />
        )}
      </Modal>
    </IconSettings>
  );
};

export default InventoryQueueFilter;
