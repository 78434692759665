/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, ReactNode, useEffect, useState } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
// import Popover from '@salesforce/design-system-react/components/popover';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import { useSelector } from 'react-redux';
import { getEventIcon } from '../../../util/utilityFunctions';
import { getUserInfo } from '../../../store/ducks/userInfo';
import { labelMappingTransfer, TRANSFER_FORM_LIST } from '../../../util/constants';

interface Props {
  handleTransfer: () => void;
  handleSearch: (event: any, val: string) => void;
  handleFilter: () => void;
  isSportMed: boolean;
  handleTransferActionDropdownSelect: (val: any) => void;
}

const TransferHeader: FC<Props> = ({
  handleSearch,
  handleFilter,
  // handleTransfer,
  // isSportMed,
  handleTransferActionDropdownSelect,
}) => {
  const userInfo = useSelector(getUserInfo);
  const requiredForms = userInfo?.assignedScreens;
  const [transferForms, setTransferForms] = useState<any>(TRANSFER_FORM_LIST);
  let BUconfiguredForm: { label: string; value: string }[] = [];

  useEffect(() => {
    const labels =
      // eslint-disable-next-line consistent-return
      requiredForms?.map((rec: any) => {
        if (Object.keys(labelMappingTransfer).includes(rec.screen) && !rec.isRequired) {
          return labelMappingTransfer[rec.screen];
        }
      }) || [];

    if (labels?.length > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      BUconfiguredForm = TRANSFER_FORM_LIST.filter((items: any) => {
        return !labels.includes(items.label);
      });
      setTransferForms(BUconfiguredForm);
    }
  }, [requiredForms]);
  // W.R.T US 294987 AC Sports med transfer new button form will open rep transfer and fucntionality will as we had for sports med
  const headerActions = (): ReactNode => (
    <PageHeaderControl>
      {transferForms?.length > 0 && (
        <ButtonGroup id="button-group-page-header-actions">
          <Dropdown
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="add"
            iconPosition="left"
            label="New"
            options={transferForms}
            onSelect={(value: string): void => handleTransferActionDropdownSelect(value)}
          />
        </ButtonGroup>
      )}
      {/* {transferForms?.length > 0 &&
        transferForms?.find((rec: any) => rec?.label === 'Rep Transfer') &&
        isSportMed && (
          <Button
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="add"
            label="New"
            iconPosition="left"
            onClick={handleTransfer}
          />
        )} */}
    </PageHeaderControl>
  );
  const headerControls = (): ReactNode => (
    <>
      <PageHeaderControl className="slds-m-left_large">
        <Input
          iconRight={
            <InputIcon
              assistiveText={{
                icon: 'Search',
              }}
              name="search"
              category="utility"
            />
          }
          id="search-by-product"
          onChange={(_event: any, searchItem: string): void => handleSearch(_event, searchItem)}
          placeholder="Search by ID"
        />
      </PageHeaderControl>
      <PageHeaderControl className="slds-m-left_large">
        <Button
          iconCategory="utility"
          className="slds-text-color_default"
          iconName="filterList"
          label="Filter"
          iconPosition="left"
          onClick={handleFilter}
        />
      </PageHeaderControl>
    </>
  );

  const iconPath = getEventIcon('Inventory');

  return (
    <div>
      <IconSettings iconPath="/icons">
        <PageHeader
          className="events_header"
          icon={<img src={iconPath} className="logo-image" alt="orders" />}
          label="Inventory"
          onRenderActions={headerActions}
          onRenderControls={headerControls}
          title="Transfer"
          truncate
          variant="object-home"
        />
      </IconSettings>
    </div>
  );
};

export default TransferHeader;
