/*
 * Get Comment history GraphQL specification
 * @module src/graphql/getCommentHistory
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_GLOBAL_SEARCH_RESULTS = gql`
  query globalSearch($searchText: String, $limit: Int, $offset: Int) {
    globalSearch(searchText: $searchText, limit: $limit, offset: $offset) {
      recordID
      sfid
      externalId
      recordType
      salesRepId
      salesRep
      parentEventId
      parentEventSubType
      parentEventType
      parentEventStatus
      parentCaseExternalId
      parentCaseId
      status
      accountId
      orderId
      orderType
      eventSubType
      cycleCountId
      cycleCountExternalId
      eventStartDate
      accountNumber
      accountName
      erpOrderNumber
      __typename
    }
  }
`;
