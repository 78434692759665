/**
 * Graphql mutation to create Notification settings
 * @module src/graphql/createUpdateInventoryReturnLineItems
 */
import gql from 'graphql-tag';

const CREATE_UPDATE_INVENTORY_RETURN_LINE_ITEMS = gql`
  mutation createUpdateInventoryReturnLineItems(
    $orderId: String
    $orderExternalId: String
    $salesRepId: String
    $formType: String!
    $lineItems: [UpdateReturnLineItems]
  ) {
    createUpdateInventoryReturnLineItems(
      orderId: $orderId
      orderExternalId: $orderExternalId
      salesRepId: $salesRepId
      formType: $formType
      lineItems: $lineItems
    ) {
      message
      externalId
    }
  }
`;

export default CREATE_UPDATE_INVENTORY_RETURN_LINE_ITEMS;
