/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import './index.scss';

interface SalesRep {
  salesRep: string;
}
interface Props {
  item?: SalesRep;
}

const CycleCountSalesRepCell: FC<Props> = ({
  item = {
    salesRep: '',
  },
}) => {
  const { salesRep } = item;
  return (
    <DataTableCell title={salesRep || ''}>
      <p className="wrap-text font-12">{salesRep || ''}</p>
    </DataTableCell>
  );
};

/** Custom Rep/Covering cell */
export default CycleCountSalesRepCell;
