/**
 * Module for Custom Rep/Covering Cell.
 * @module src/Events/EventsList
 */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
// import './index.scss';

interface Reps {
  type: string;
  diplayOrderType: string;
}
interface Props {
  item?: Reps;
  state?: string;
}

const InventoryReturnTypeCell: FC<Props> = ({
  item = {
    type: '',
    coveringReps: '',
    diplayOrderType: '',
  },
  state = '',
}) => {
  const { type, diplayOrderType } = item;
  return state === 'Failed' || state === 'Completed' ? (
    <DataTableCell title={diplayOrderType === 'Pickup Return' ? 'Pickup' : 'Standard'}>
      <p className="wrap-text font-12">
        {diplayOrderType === 'Pickup Return' ? 'Pickup' : 'Standard'}
      </p>
    </DataTableCell>
  ) : (
    <DataTableCell title={type === 'Pickup Return' ? 'Pickup' : 'Standard'}>
      <p className="wrap-text font-12">{type === 'Pickup Return' ? 'Pickup' : 'Standard'}</p>
    </DataTableCell>
  );
};

/** Custom Rep/Covering cell */
export default InventoryReturnTypeCell;
