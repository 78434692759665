/* eslint-disable no-console */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable array-callback-return */
/* eslint-disable no-else-return */
/**
 * Module for header on transferdetail pgage
 * @module src/Transfer/TransferDetails
 */
/* eslint-disable react/jsx-wrap-multilines */

import React, { FC, ReactNode, useState, useCallback, useEffect, useRef } from 'react';
import Icon from '@salesforce/design-system-react/components/icon';
import moment from 'moment';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import DropdownTrigger from '@salesforce/design-system-react/components/menu-dropdown/button-trigger';
import { useLocation, useHistory } from 'react-router-dom';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import Button from '@salesforce/design-system-react/components/button';
import { useSelector, useDispatch } from 'react-redux';
import EventStatusBadge from '../../../Shared/EventStatusBadge';
import { GET_TRANSFER_DETAILS } from '../../../../graphql/getInventoryTransferDetails';
import {
  NOTIFY_TYPE,
  EVENT_TRANSFER,
  NonSalesRep,
  OPSCSRUSERS,
  onHoldUsers,
  holdTypes,
  PERSONA_CONDITION_OPS_CSR,
  OPS_ACTION_TYPE,
} from '../../../../util/constants';
import SnackBar from '../../../Shared/SnackBar';
import RequestTransfer from '../EditTransfer';
import NewRepTransfer from '../NewRepTransfer';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import { getEventIcon, getEventIconInner } from '../../../../util/utilityFunctions';
import InventoryRequestHeader from '../InventoryRequest/InventoryRequestHeader';
import InventoryRequestTable from '../InventoryRequestTable';
import CancelTransferInventory from '../TransferCancel';
import { GET_SAS_URI } from '../../../../graphql/getSasUri';
import { GET_PRIMARY_ACCOUNT } from '../../../../graphql/getPrimaryAccount';
import { SUBMIT_TRANFER_REQUEST } from '../../../../graphql/mutations/submitTransferInventory';
import RESUBMIT_ORDER_DETAILS from '../../../../graphql/mutations/resubmitOrderDetails';
import CANCEL_INVENTORY_TRANSFER from '../../../../graphql/mutations/cancelInventoryTransfer';
import { GET_USER_TERRITORIES } from '../../../../graphql/getUserTerritories';
import { getUserInfo, setUserInfo } from '../../../../store/ducks/userInfo';
import { getUserId } from '../../../../store/ducks/userId';
import { GET_FORM_CONFIGURATIONS } from '../../../../graphql/getFormConfigurations';
import { GET_USER_INFO } from '../../../../graphql/getUserInfoData';
import { GET_DETAILS_VIEW_CONFIGURATIONS } from '../../../../graphql/getDetailsViewConfigurations';
import CancelEventModal from '../../../CreateEditSurgeryEvent/CancelEventCreationModal';
import DisplayRecordIndicator from '../../../Shared/DisplayRecordIndicator';
import { GET_USER_HOLD } from '../../../../graphql/getUserHold';
import { GET_USER_CC_HOLD } from '../../../../graphql/getUserCCInfo';
import { setHoldBannerStatus } from '../../../../store/ducks/holdBanner';

interface LineItem {
  id: string;
  externalId: string;
  partNumber?: string;
  lotNumber: string;
  productId: string;
  quantity: string;
  priceBookEntryId: string;
  unitPrice: string;
  status: string;
  approvalStatus: string;
  salesUnitOfMeasure: string;
  productNumber: string;
  isLotNumber: boolean;
}

interface File {
  imageurl: string;
  name: string;
}
interface DropdownSelect {
  label: string;
  value: string;
  subType?: string;
}

const TransferDetails: FC = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfo);
  const userId = useSelector(getUserId);
  const { open, notification, openSnackBar } = useSnackBar();
  const history = useHistory();
  const location = useLocation();
  const isUserOps =
    userInfo?.personas?.toLowerCase().includes('branch op') ||
    userInfo?.personas?.includes('3PL Ops');
  const {
    externalId,
    transferType,
    salesRepValue,
    recivingSalesRepValue,
    creatdType = '',
    opsAction,
  } = location && location.state; // it is equal to yourData
  const [selectedInventoryItems, setselectedInventoryItems] = useState([]);
  const { data: sasUriData } = useQuery(GET_SAS_URI);
  const [showRequestTransferModal, setShowRequestTransferModal] = useState(false);
  const [showNewTransferModal, setShowNewTransferModal] = useState(false);
  const [submitTransfer] = useMutation(SUBMIT_TRANFER_REQUEST);
  const [openTransferCancelModal, setOpenTransferCancelModal] = useState(false);
  const [getOriginTerritory, { data: originTerritiry }] = useLazyQuery(GET_USER_TERRITORIES);
  const [territory, setTerritory] = useState('');
  const [reprocessQueue, setReprocessQueue] = useState(false);
  const [recTerritory, setRecTerritory] = useState('');
  const [sourceAccount, setSourceAccount] = useState<any>();
  const [destAccount, setDestAccount] = useState<any>();
  const [canceModalVisible, setCanceModalVisible] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [cancelModalSM, setCancelModalSM] = useState(false);
  const [cancelModalNonSM, setCancelModalNonSM] = useState(false);
  const [reSubmitOrder] = useMutation(RESUBMIT_ORDER_DETAILS);
  const [getFormConfigurations, { data: formdata }] = useLazyQuery(GET_FORM_CONFIGURATIONS, {
    fetchPolicy: 'no-cache',
  });
  const originTerritoryData = originTerritiry?.getUserTerritories;
  const [userOnHold, setUserOnHold] = useState(false);
  const [getDestTerritory, { data: destTerritiry }] = useLazyQuery(GET_USER_TERRITORIES);
  const { data: getDetailsViewConfigurations } = useQuery(GET_DETAILS_VIEW_CONFIGURATIONS);
  const [getPrimaryAccountItems, { data: primaryAccountItems }] = useLazyQuery(
    GET_PRIMARY_ACCOUNT,
    {
      fetchPolicy: 'no-cache',
    }
  ); // USELAZYQUERY
  const [
    getDestinationPrimaryAccountItems,
    { data: destinationPrimaryAccountItems },
  ] = useLazyQuery(GET_PRIMARY_ACCOUNT, {
    fetchPolicy: 'no-cache',
  }); // USELAZYQUERY
  const { data: holddata } = useQuery(GET_USER_HOLD, {
    fetchPolicy: 'no-cache',
    variables: {
      id: userInfo?.id,
    },
  });
  const isHoldUser = !!onHoldUsers?.find((rec: any) => rec?.value === userInfo?.personas);
  const timedata = new Date();
  const newtime = timedata
    .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
    .substring(4)
    .match(/\b(\w)/g);
  const TimeZoneData = newtime?.join('');
  const primaryAccountData = primaryAccountItems?.getPrimaryAccount;
  const primaryDestinationAccountData = destinationPrimaryAccountItems?.getPrimaryAccount;
  const destTerritoryData = destTerritiry?.getUserTerritories;
  const [
    getTransferDetails,
    { data: TranferDetails, loading, refetch: refetchTransferDetails },
  ] = useLazyQuery(GET_TRANSFER_DETAILS, {
    fetchPolicy: 'no-cache',
  });
  const transferDetailsViewConfig = TranferDetails?.getInventoryTransferDetails?.type
    ?.toLowerCase()
    .includes('customer')
    ? getDetailsViewConfigurations?.getDetailsViewConfigurations?.customerTransfer.headers
    : getDetailsViewConfigurations?.getDetailsViewConfigurations?.repToRepTransfer.headers;
  const commentConfig = transferDetailsViewConfig?.find(
    (config: any) => config.mappingKey === 'comments'
  );
  const attachmentConfig = transferDetailsViewConfig?.find(
    (config: any) => config.mappingKey === 'Attachments'
  );
  const [
    setCancelTransfer,
    { data: cancelInventoryTransfer, loading: cancelTransferLoading },
  ] = useMutation(CANCEL_INVENTORY_TRANSFER);

  const { data: userInfoData } = useQuery(GET_USER_INFO, {
    fetchPolicy: 'no-cache',
    variables: { id: userId },
  });

  const { data: accountHoldFlag } = useQuery(GET_USER_CC_HOLD, {
    fetchPolicy: 'no-cache',
    variables: {
      id: userInfo?.id,
      accountId: TranferDetails?.getInventoryTransferDetails?.fromSubInventoryCode,
      name: TranferDetails?.getInventoryTransferDetails?.salesRepName,
    },
  });

  const { data: accountHoldFlagRec } = useQuery(GET_USER_CC_HOLD, {
    fetchPolicy: 'no-cache',
    variables: {
      id: userInfo?.id,
      accountId: TranferDetails?.getInventoryTransferDetails?.toSubInventoryCode,
      name: TranferDetails?.getInventoryTransferDetails?.receivingRepName,
    },
  });
  const [viewOnly, setViewOnly] = useState(false);
  // const isUserOpsCsr = !!OPSCSRUSERS?.find((rec: any) => rec?.value === userInfo?.personas);
  const isUserOpsCsr =
    userInfo &&
    PERSONA_CONDITION_OPS_CSR?.some(el => userInfo?.personas?.toLowerCase()?.includes(el));
  const isNotSR = !!NonSalesRep?.find((rec: any) => rec?.value === userInfo?.personas);
  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);
  useEffect(() => {
    if (!externalId) {
      console.log('We had a trouble saving data to salesforce. Please try again later');
      return;
    }
    getTransferDetails({
      variables: {
        id: externalId,
        requestedFrom: transferType === 'Received' ? 'RecRep' : 'SalesRep',
      },
    });
  }, [getTransferDetails, externalId, transferType]);

  useEffect(() => {
    try {
      dispatch(setUserInfo(userInfoData ? userInfoData.getUserInfo[0] : {}));
    } catch (err) {
      // do nothing
    }
  }, [dispatch, userInfoData]);

  useEffect(() => {
    if (externalId) {
      if (opsAction) {
        setReprocessQueue(true);
        if (opsAction && opsAction === OPS_ACTION_TYPE.ASSIGN) {
          setViewOnly(true);
        }
      }
    } else {
      setReprocessQueue(false);
    }
  }, [history, opsAction, externalId]);

  useEffect(() => {
    if (
      cancelInventoryTransfer &&
      cancelInventoryTransfer.cancelInventoryReturns &&
      cancelInventoryTransfer.cancelInventoryReturns.message === 'success'
    ) {
      setTimeout(() => {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Success: Inventory return successfully cancelled.');
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelInventoryTransfer]);

  useEffect(() => {
    if (TranferDetails && TranferDetails.getInventoryTransferDetails) {
      setselectedInventoryItems(TranferDetails.getInventoryTransferDetails.lineItems);
    }
    if (TranferDetails && TranferDetails.getInventoryTransferDetails?.salesRepId) {
      getOriginTerritory({
        variables: {
          userId: TranferDetails.getInventoryTransferDetails.salesRepId,
        },
      });
      getPrimaryAccountItems({
        variables: {
          salesRepId: TranferDetails.getInventoryTransferDetails.salesRepId,
        },
      });
    }
    if (TranferDetails && TranferDetails.getInventoryTransferDetails?.receivingRepId) {
      getDestTerritory({
        variables: {
          userId: TranferDetails.getInventoryTransferDetails.receivingRepId,
        },
      });
      getDestinationPrimaryAccountItems({
        variables: {
          salesRepId: TranferDetails.getInventoryTransferDetails.receivingRepId,
        },
      });
    }
  }, [
    getTransferDetails,
    TranferDetails,
    getOriginTerritory,
    getDestTerritory,
    getPrimaryAccountItems,
    getDestinationPrimaryAccountItems,
  ]);

  useEffect(() => {
    if (originTerritoryData && TranferDetails && TranferDetails.getInventoryTransferDetails) {
      const originTerritoryObj = originTerritoryData.filter((e: any) =>
        e?.subInventoryCode?.includes(
          TranferDetails.getInventoryTransferDetails?.fromSubInventoryCode
        )
      );
      setTerritory(originTerritoryObj[0]?.value);
    }
    if (destTerritoryData && TranferDetails && TranferDetails.getInventoryTransferDetails) {
      const destTerritoryObj = destTerritoryData.filter((e: any) =>
        e?.subInventoryCode?.includes(
          TranferDetails.getInventoryTransferDetails?.toSubInventoryCode
        )
      );
      setRecTerritory(destTerritoryObj[0]?.value);
    }
    if (primaryAccountData && TranferDetails && TranferDetails.getInventoryTransferDetails) {
      const sourceAccountObj = primaryAccountData.filter((e: any) =>
        e?.number?.includes(TranferDetails.getInventoryTransferDetails?.fromSubInventoryCode)
      );
      setSourceAccount(sourceAccountObj);
      if (
        TranferDetails &&
        TranferDetails?.getInventoryTransferDetails &&
        TranferDetails?.getInventoryTransferDetails?.type?.toLowerCase().includes('customer')
      ) {
        const destAccountObj = primaryAccountData.filter((e: any) =>
          e?.number?.includes(TranferDetails.getInventoryTransferDetails?.toSubInventoryCode)
        );
        setDestAccount(destAccountObj);
      } else {
        const destAccountObj =
          primaryDestinationAccountData &&
          primaryDestinationAccountData.filter((e: any) =>
            e?.number?.includes(TranferDetails.getInventoryTransferDetails?.toSubInventoryCode)
          );
        setDestAccount(destAccountObj);
      }
    }
  }, [
    getOriginTerritory,
    getDestTerritory,
    originTerritoryData,
    destTerritoryData,
    primaryAccountData,
    primaryDestinationAccountData,
    TranferDetails,
    getPrimaryAccountItems,
    getDestinationPrimaryAccountItems,
  ]);
  const handleAttachmentsView = (file: File): void => {
    const url = `${file.imageurl.split('?')[0]}?${sasUriData?.getSasUri.sasUri}`;
    window.open(url, '_blank');
  };

  const handleTabSelect = (value: number): void => {
    setSelectedTabIndex(value);
    // changeSelectedTab(value);
  };

  const formatLineItemToReSubmit = (lineItems: any): any => {
    const finalLineItems: Array<any> = [];
    lineItems.map((item: any): any => {
      finalLineItems.push({
        caseUsageId: item.id,
        caseUsageExternalId: item.externalId,
        sfid: item.orderItemId,
        externalId: item.orderItemExternalId,
        lotNumber: item.lotNumber,
        productId: item.productId,
        productNumber: item.productNumber,
        quantity: item.quantity,
        sourceLocation: item.sourceLocation,
        isLotNumber: item?.isLotNumber,
      });
    });
    return finalLineItems;
  };

  const handleResubmit = (): void => {
    if (TranferDetails?.getInventoryTransferDetails.isCycleCountInitiated === true) {
      openSnackBar(NOTIFY_TYPE.ERROR, 'One or More part`s Account has cyclecount Initiated');
      return;
    }
    if (
      TranferDetails &&
      TranferDetails?.getInventoryTransferDetails &&
      TranferDetails?.getInventoryTransferDetails.lineItems.length === 0
    ) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'Transfer request should have at least one line item associated with the request'
      );
    } else {
      const filterInventoryOnHold = selectedInventoryItems.filter(
        (item: any) => item.productOnHold
      );
      if (filterInventoryOnHold && filterInventoryOnHold.length > 0) {
        openSnackBar(
          NOTIFY_TYPE.ERROR,
          'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
        );
        return;
      }
      const updatedPickedItems = formatLineItemToReSubmit(selectedInventoryItems);
      const { id, accountId, salesRepId, fromSubInventoryCode, salesOrganization } =
        TranferDetails && TranferDetails.getInventoryTransferDetails;
      const updatedDetails = {
        salesRepId,
        accountId,
        fromSubInventoryCode,
        salesOrganization,
        id:
          (TranferDetails &&
            TranferDetails.getInventoryTransferDetails &&
            TranferDetails.getInventoryTransferDetails.orderId) ||
          '',
        orderExternalId:
          (TranferDetails &&
            TranferDetails.getInventoryTransferDetails &&
            TranferDetails.getInventoryTransferDetails.orderExternalId) ||
          '',
        submitType: 'Resubmit',
        requestedFrom: 'Inventory Transfer',
        lineItems: updatedPickedItems,
      };

      if (updatedPickedItems && updatedPickedItems.length > 0) {
        reSubmitOrder({
          variables: updatedDetails,
        })
          .then((res: any) => {
            if (res && res.data && res.data.resubmitOrderDetails?.message === NOTIFY_TYPE.SUCCESS) {
              openSnackBar(NOTIFY_TYPE.SUCCESS, `Transfer has been resubmitted successfully.`);
              setTimeout(() => {
                // refetch();
                if (reprocessQueue) {
                  history.push(`/Inventory/FailedRequests`);
                } else {
                  history.push(`inventory/Transfer/${transferType.toLowerCase()}`);
                }
              }, 1000);
            }
          })
          .catch(error => {
            if (error?.message?.toLowerCase().includes('permission denied')) {
              openSnackBar(
                NOTIFY_TYPE.ERROR,
                'Submit is not allowed while user is on hold. Please contact Ops.'
              );
            } else openSnackBar(NOTIFY_TYPE.ERROR, 'Error: Inventory Transfer error.');
            console.log('We had a trouble saving data to salesforce. Please try again later');
            // eslint-disable-next-line no-console
            console.log(error); // TODO change when some operation needs to be run on error.
          });
      } else {
        openSnackBar(
          NOTIFY_TYPE.ERROR,
          'Transfer request should have at least one line item associated with the request.'
        );
      }
    }
  };

  const handleSelectDropdownItem = (option: any): void => {
    if (option.value === 'Submit' && !option.disabled) {
      if (option.disabledReason === 'missing_line_items') {
        openSnackBar(
          NOTIFY_TYPE.ERROR,
          'Transfer request should have at least one line item associated with the request'
        );
      } else if (TranferDetails?.getInventoryTransferDetails.isCycleCountInitiated) {
        openSnackBar(NOTIFY_TYPE.ERROR, 'One or More part`s Account has cyclecount Initiated');
      } else {
        let updatedPickedItems;
        if (transferType === 'Sent') {
          updatedPickedItems = selectedInventoryItems.map(
            (element: any): LineItem => {
              return {
                id: element?.id || '',
                externalId: element.externalId || '',
                lotNumber: element.lotNumber || '',
                productId: element.productId || '',
                quantity: element.quantity ? element.quantity : String(element.counterValue),
                priceBookEntryId: element.priceBookEntryId || '',
                unitPrice: element?.unitPrice || '',
                status: element?.status || 'New',
                approvalStatus: element?.approvalStatus || '',
                salesUnitOfMeasure: element.salesUnitOfMeasure || '',
                productNumber: element.productNumber,
                isLotNumber: element.isLotNumber,
              };
            }
          );
        }
        const filterInventoryOnHold = selectedInventoryItems.filter(
          (item: any) => item.productOnHold
        );
        if (filterInventoryOnHold && filterInventoryOnHold.length > 0) {
          openSnackBar(
            NOTIFY_TYPE.ERROR,
            'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
          );
          return;
        }
        if (transferType === 'Received') {
          updatedPickedItems = selectedInventoryItems.map(
            (element: any): LineItem => {
              if (element && element.item) {
                return {
                  id: element.item.id || '',
                  externalId: element.item.externalId || '',
                  lotNumber: element.item.lotNumber || '',
                  productId: element.item.productId || '',
                  quantity: element.item.quantity
                    ? element.item.quantity
                    : String(element.item.counterValue),
                  priceBookEntryId: element.item.priceBookEntryId || '',
                  unitPrice: element.item.unitPrice || '',
                  status: element.status,
                  approvalStatus: element.action || '',
                  salesUnitOfMeasure: element.item.salesUnitOfMeasure || '',
                  productNumber: element.item.productNumber,
                  isLotNumber: element.item.isLotNumber,
                };
              } else {
                return {
                  id: element.id || '',
                  externalId: element.externalId || '',
                  lotNumber: element.lotNumber || '',
                  productId: element.productId || '',
                  quantity: element.quantity ? element.quantity : String(element.counterValue),
                  priceBookEntryId: element.priceBookEntryId || '',
                  unitPrice: element.unitPrice || '',
                  status: element.status,
                  approvalStatus: element.approvalStatus || '',
                  salesUnitOfMeasure: element.salesUnitOfMeasure || '',
                  productNumber: element.productNumber,
                  isLotNumber: element.isLotNumber,
                };
              }
            }
          );
        }
        const updatedDetails = {
          orderId:
            (TranferDetails &&
              TranferDetails.getInventoryTransferDetails &&
              TranferDetails.getInventoryTransferDetails.id) ||
            '',
          orderExternalId:
            (TranferDetails &&
              TranferDetails.getInventoryTransferDetails &&
              TranferDetails.getInventoryTransferDetails.externalId) ||
            '',
          lineItems: updatedPickedItems || [],
          requestedFrom: transferType === 'Sent' ? 'SalesRep' : 'RecRep',
          system: 'Web',
          version: process.env.REACT_APP_VERSION || '0.0.1',
        };
        if (updatedPickedItems && updatedPickedItems.length > 0) {
          submitTransfer({
            variables: updatedDetails,
          })
            .then(response => {
              if (
                response &&
                response?.data &&
                response?.data?.submitInventoryTransfer &&
                response?.data?.submitInventoryTransfer?.message &&
                response?.data?.submitInventoryTransfer?.message === 'success'
              ) {
                openSnackBar(NOTIFY_TYPE.SUCCESS, 'Inventory transfer Submitted successfully');
                setTimeout(() => {
                  // refetchTransferDetails();
                  history.push(`inventory/Transfer/${transferType.toLowerCase()}`);
                }, 1000);
              }
            })
            .catch(error => {
              if (error?.message?.toLowerCase().includes('permission denied')) {
                openSnackBar(
                  NOTIFY_TYPE.ERROR,
                  'Submit is not allowed while user is on hold. Please contact Ops.'
                );
              } else openSnackBar(NOTIFY_TYPE.ERROR, 'Error: Inventory Transfer error.');
              // eslint-disable-next-line no-console
            });
        } else {
          openSnackBar(
            NOTIFY_TYPE.ERROR,
            'Transfer request should have at least one line item associated with the request.'
          );
        }
      }
    } else if (option.value === 'Edit' && !option.disabled) {
      if (
        (TranferDetails &&
          TranferDetails?.getInventoryTransferDetails &&
          TranferDetails?.getInventoryTransferDetails.type.toLowerCase().includes('customer')) ||
        (TranferDetails &&
          TranferDetails?.getInventoryTransferDetails &&
          TranferDetails?.getInventoryTransferDetails.type === 'Rep to Rep')
      ) {
        setShowNewTransferModal(true);
      } else {
        setShowRequestTransferModal(true);
      }
    } else if (option.value === 'Discrepancy' && !option.disabled) {
      const orderNumber =
        TranferDetails &&
        TranferDetails.getInventoryTransferDetails &&
        TranferDetails.getInventoryTransferDetails.orderNumber
          ? TranferDetails.getInventoryTransferDetails.orderNumber
          : '';
      // eslint-disable-next-line no-unused-expressions
      userInfo?.businessRules.forEach((br: any) => {
        if (br.rules === 'Inventory Discrepancy Email Recipient') {
          window.open(`mailto: ${br.values}?subject=Inventory Transfer: ${orderNumber}&body=`);
        }
      });
    } else if (option.value === 'cancel' && !option.disabled) {
      setOpenTransferCancelModal(!openTransferCancelModal);
    } else if (option.value === 'resubmit' && !option.disabled) {
      handleResubmit();
    }
  };
  const cancelTransfer = (): void => {
    const mutation = {
      externalId,
    };
    setCancelTransfer({
      variables: mutation,
    })
      .then(() => {
        setOpenTransferCancelModal(false);
        refetchTransferDetails();
      })
      .catch(error => {
        openSnackBar(NOTIFY_TYPE.ERROR, 'Error: Inventory return cancellation error.');
        // eslint-disable-next-line no-console
        console.log(error); // TODO change when some operation needs to be run on error.
      });
  };

  const handleCancelTransferModal = (): void => {
    setOpenTransferCancelModal(!openTransferCancelModal);
  };

  const handleCloseRequestTranfer = (): void => {
    // setShowRequestTransferModal(!showRequestTransferModal);
    setCancelModalSM(!cancelModalSM);
  };

  const handleNewTranferModalToggle = (): void => {
    // setShowNewTransferModal(!showNewTransferModal);
    setCancelModalNonSM(!cancelModalNonSM);
  };

  const handleCancelModalConfirmPressSM = (): void => {
    setShowRequestTransferModal(!showRequestTransferModal);
    setCancelModalSM(false);
  };

  const handleCancelModalConfirmPressNonSM = (): void => {
    setShowNewTransferModal(!showNewTransferModal);
    setCancelModalNonSM(false);
  };

  const handleRefetchItems = useCallback((): void => {
    if (
      (TranferDetails &&
        TranferDetails?.getInventoryTransferDetails &&
        TranferDetails?.getInventoryTransferDetails.type.toLowerCase().includes('customer')) ||
      (TranferDetails &&
        TranferDetails?.getInventoryTransferDetails &&
        TranferDetails?.getInventoryTransferDetails.type === 'Rep to Rep')
    ) {
      setShowNewTransferModal(!showNewTransferModal);
    } else {
      setShowRequestTransferModal(!showRequestTransferModal);
    }

    setTimeout(() => {
      refetchTransferDetails();
    }, 500);
  }, [showRequestTransferModal, refetchTransferDetails, showNewTransferModal, TranferDetails]);

  const handleSelectedLineItems = (item: any): void => {
    setselectedInventoryItems(item);
  };

  useEffect(() => {
    if (holddata?.getUserHoldInfo && holddata?.getUserHoldInfo?.length > 0) {
      if (
        holddata?.getUserHoldInfo[0]?.onHoldReason.filter(
          (e: any) =>
            e === holdTypes[0]['Custom Hold'] ||
            (e === holdTypes[0]['CC Hold'] &&
              accountHoldFlag?.getCycleCountHoldInfo?.length > 0 &&
              accountHoldFlag?.getCycleCountHoldInfo[0]?.onHold)
        )?.length > 0
      ) {
        setUserOnHold(holddata?.getUserHoldInfo[0]?.onHold);
        dispatch(setHoldBannerStatus(holddata?.getUserHoldInfo[0]?.onHold));
      }
      if (
        holddata?.getUserHoldInfo[0]?.onHoldReason.filter(
          (e: any) =>
            e === holdTypes[0]['Custom Hold'] ||
            (e === holdTypes[0]['CC Hold'] &&
              accountHoldFlagRec?.getCycleCountHoldInfo?.length > 0 &&
              accountHoldFlagRec?.getCycleCountHoldInfo[0]?.onHold)
        )?.length > 0
      ) {
        setUserOnHold(holddata?.getUserHoldInfo[0]?.onHold);
        dispatch(setHoldBannerStatus(holddata?.getUserHoldInfo[0]?.onHold));
      }
      if (
        holddata?.getUserHoldInfo[0]?.onHoldReason.filter(
          (e: any) =>
            e === holdTypes[0]['Regulatory Noncompliance'] ||
            e === holdTypes[0]['Sunshine Act Hold']
        )?.length > 0 &&
        TranferDetails?.getInventoryTransferDetails?.type === 'Rep to Customer'
      ) {
        setUserOnHold(holddata?.getUserHoldInfo[0]?.onHold);
        dispatch(setHoldBannerStatus(holddata?.getUserHoldInfo[0]?.onHold));
      }
      if (
        holddata?.getUserHoldInfo[0]?.onHoldReason.filter(
          (e: any) =>
            e === holdTypes[0]['Regulatory Noncompliance'] ||
            e === holdTypes[0]['Sunshine Act Hold']
        )?.length > 0 &&
        (TranferDetails?.getInventoryTransferDetails?.type === 'Rep to Rep' ||
          TranferDetails?.getInventoryTransferDetails?.type === 'Transfer') &&
        !userInfo?.allowRepTransfer
      ) {
        setUserOnHold(holddata?.getUserHoldInfo[0]?.onHold);
        dispatch(setHoldBannerStatus(holddata?.getUserHoldInfo[0]?.onHold));
      }
      if (
        holddata?.getUserHoldInfo[0]?.onHoldReason.filter(
          (e: any) =>
            e === holdTypes[0]['Regulatory Noncompliance'] ||
            e === holdTypes[0]['Sunshine Act Hold']
        )?.length > 0 &&
        TranferDetails?.getInventoryTransferDetails?.type === 'Customer to Rep' &&
        !userInfo?.allowCustomerTransfer
      ) {
        setUserOnHold(holddata?.getUserHoldInfo[0]?.onHold);
        dispatch(setHoldBannerStatus(holddata?.getUserHoldInfo[0]?.onHold));
      }
      if (
        holddata?.getUserHoldInfo &&
        holddata?.getUserHoldInfo?.length > 0 &&
        accountHoldFlag?.getCycleCountHoldInfo?.length > 0 &&
        accountHoldFlagRec?.getCycleCountHoldInfo?.length > 0
      ) {
        if (
          holddata?.getUserHoldInfo[0]?.onHold === false &&
          accountHoldFlag?.getCycleCountHoldInfo[0]?.onHold === false &&
          accountHoldFlagRec?.getCycleCountHoldInfo[0]?.onHold === false
        ) {
          dispatch(setHoldBannerStatus(false));
        } else if (
          holddata?.getUserHoldInfo[0]?.onHoldReason.length === 1 &&
          holddata?.getUserHoldInfo[0]?.onHoldReason.includes('Cycle Count Hold') &&
          accountHoldFlag?.getCycleCountHoldInfo[0]?.onHold === false &&
          accountHoldFlagRec?.getCycleCountHoldInfo[0]?.onHold === false
        ) {
          dispatch(setHoldBannerStatus(false));
        }
      }
    } else {
      dispatch(setHoldBannerStatus(false));
    }
  }, [TranferDetails, dispatch, accountHoldFlag, holddata, accountHoldFlagRec, userInfo]);

  let transferTypeFromForm = 'customerTransfer';
  if (TranferDetails) {
    if (TranferDetails.getInventoryTransferDetails?.type === 'Event to Event') {
      transferTypeFromForm = 'eventTransfer';
    } else if (
      TranferDetails.getInventoryTransferDetails?.type === 'Rep to Rep' ||
      TranferDetails.getInventoryTransferDetails?.type === 'Rep Transfer' ||
      TranferDetails.getInventoryTransferDetails?.type === 'Transfer'
    ) {
      transferTypeFromForm = 'repTransfer';
    }
  }

  const visualIndicator =
    TranferDetails &&
    TranferDetails.getInventoryTransferDetails &&
    TranferDetails.getInventoryTransferDetails.visualIndicator;
  const status =
    TranferDetails &&
    TranferDetails.getInventoryTransferDetails &&
    TranferDetails.getInventoryTransferDetails.status;

  const isLineItemApproved =
    TranferDetails &&
    TranferDetails.getInventoryTransferDetails &&
    TranferDetails.getInventoryTransferDetails.lineItems.length > 0 &&
    TranferDetails.getInventoryTransferDetails.lineItems.filter(
      (item: any) => item.approvalStatus === 'Approved'
    );

  const parenticonPath = getEventIconInner(TranferDetails?.getInventoryTransferDetails?.eventType);
  const handleChangeRequest = (): void => {
    history.push('/surgicalDetails', {
      eventType: TranferDetails?.getInventoryTransferDetails?.eventType,
      externalId: TranferDetails?.getInventoryTransferDetails?.caseExternalId,
      id: TranferDetails?.getInventoryTransferDetails?.eventId,
    });
  };

  const handleParentEventRequest = (): void => {
    history.push('/surgicalDetails', {
      eventType: TranferDetails?.getInventoryTransferDetails?.parentEventType,
      externalId: TranferDetails?.getInventoryTransferDetails?.parentCaseExternalId,
      id: TranferDetails?.getInventoryTransferDetails?.parentEventId,
    });
  };

  const headerDropdown = [
    {
      label: 'Edit',
      value: 'Edit',
      disabled:
        transferType === 'Failed'
          ? !isUserOpsCsr ||
            !!(
              (visualIndicator && visualIndicator.toLowerCase() === 'requested') ||
              (visualIndicator && visualIndicator.toLowerCase() === 'cancelled') ||
              (visualIndicator && visualIndicator.toLowerCase() === 'completed')
            )
          : status !== 'Approved' &&
            !!(
              (visualIndicator && visualIndicator.toLowerCase() === 'requested') ||
              (visualIndicator && visualIndicator.toLowerCase() === 'cancelled') ||
              (visualIndicator && visualIndicator.toLowerCase() === 'approved') ||
              (visualIndicator && visualIndicator.toLowerCase() === 'completed') ||
              (visualIndicator && visualIndicator.toLowerCase() === 'closed') ||
              (transferType !== 'Sent' && !TranferDetails?.getInventoryTransferDetails.eventId)
            ),
    },
    {
      label: 'Submit',
      value: transferType === 'Failed' ? 'resubmit' : 'Submit',
      disabledReason:
        TranferDetails &&
        TranferDetails?.getInventoryTransferDetails &&
        TranferDetails?.getInventoryTransferDetails.lineItems.length === 0
          ? 'missing_line_items'
          : 'nothing',
      disabled:
        transferType === 'Failed' && isUserOpsCsr && status === 'Approved'
          ? false || viewOnly
          : !!(
              // (TranferDetails &&
              //   TranferDetails.getInventoryTransferDetails &&
              //   TranferDetails.getInventoryTransferDetails.lineItems.length === 0) ||
              (
                (userOnHold && isHoldUser) ||
                (transferType === 'Sent' &&
                  visualIndicator &&
                  visualIndicator.toLowerCase() === 'requested') ||
                (transferType === 'Received' &&
                  visualIndicator &&
                  visualIndicator.toLowerCase() === 'requested' &&
                  TranferDetails?.getInventoryTransferDetails?.receivingRepId !== userInfo?.id) ||
                (visualIndicator && visualIndicator.toLowerCase() === 'cancelled') ||
                (visualIndicator && visualIndicator.toLowerCase() === 'approved') ||
                (visualIndicator && visualIndicator.toLowerCase() === 'completed') ||
                (visualIndicator && visualIndicator.toLowerCase() === 'closed') ||
                (visualIndicator && visualIndicator.toLowerCase() === 'submitted') ||
                (TranferDetails?.getInventoryTransferDetails.type === EVENT_TRANSFER &&
                  status &&
                  status?.toLowerCase() === 'completed')
              )
            ),
    },
    {
      label: 'Cancel',
      value: 'cancel',
      disabled:
        transferType === 'Failed' && isUserOpsCsr && status === 'Approved'
          ? false || viewOnly
          : !!(
              (isLineItemApproved && isLineItemApproved.length > 0) ||
              (visualIndicator && visualIndicator.toLowerCase() === 'requested') ||
              (visualIndicator && visualIndicator.toLowerCase() === 'cancelled') ||
              (visualIndicator && visualIndicator.toLowerCase() === 'approved') ||
              (visualIndicator && visualIndicator.toLowerCase() === 'completed') ||
              (visualIndicator && visualIndicator.toLowerCase() === 'closed') ||
              (transferType !== 'Sent' && !TranferDetails?.getInventoryTransferDetails?.eventId)
            ),
    },
    {
      label: 'Discrepancy',
      disabled: (transferType === 'Failed' && isUserOpsCsr) || viewOnly,
      value: 'Discrepancy',
    },
  ];

  const headerActions = (): ReactNode => (
    <PageHeaderControl>
      <ButtonGroup variant="list" id="button-group-page-header-actions">
        <Dropdown
          align="right"
          assistiveText={{ icon: 'Change view' }}
          iconCategory="utility"
          iconName="settings"
          id="page-header-dropdown-object-home-content-right-2"
          options={headerDropdown}
          width="x-small"
          onSelect={(item: DropdownSelect): void => {
            handleSelectDropdownItem(item);
          }}
          length="10"
        >
          <DropdownTrigger>
            <Button
              iconCategory="utility"
              className="slds-text-color_default new_transferdetail_button"
              iconName="down"
              label="Actions"
              iconPosition="right"
              iconSize="small"
            />
          </DropdownTrigger>
        </Dropdown>
        <DisplayRecordIndicator recordInfo={TranferDetails?.getInventoryTransferDetails} />
      </ButtonGroup>
    </PageHeaderControl>
  );
  const handleBacklink = (): void => {
    window.history.back();
  };

  const iconPath = getEventIcon('Inventory');
  return (
    <IconSettings iconPath="/icons">
      <div>
        <div
          style={{
            padding: '5px 20px',
            fontWeight: 'bold',
            fontSize: '14px',
            cursor: 'pointer',
            color: 'blue',
          }}
        >
          {/* <span aria-hidden="true" onClick={handleBacklink}>
            Back
          </span> */}
          <Button
            style={{ color: 'black' }}
            iconCategory="utility"
            iconName="back"
            iconPosition="left"
            label="Back"
            onClick={handleBacklink}
          />
        </div>
        <>
          <SnackBar open={open} notification={notification} />
          <PageHeader
            className="slds-m-around_small slds-m-bottom_none"
            icon={<img src={iconPath} className="logo-image" alt="orders" />}
            title={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div className="slds-grid slds-grid_vertical-align-center slds-p-top_xx-small">
                <span className="slds-col event_id">
                  {(TranferDetails &&
                    TranferDetails.getInventoryTransferDetails &&
                    TranferDetails.getInventoryTransferDetails.orderNumber) ||
                    'TBD'}
                </span>
                <span className="slds-col slds-m-left_large">
                  <div style={{ display: 'flex' }} title="Event Status">
                    <EventStatusBadge
                      status={
                        visualIndicator === 'Rejected' ||
                        visualIndicator === 'Approved' ||
                        TranferDetails?.getInventoryTransferDetails?.type === EVENT_TRANSFER
                          ? status
                          : visualIndicator
                      }
                    />
                    {status === 'Requested' && visualIndicator === 'Rejected' && (
                      <div style={{ marginLeft: '5px' }}>
                        <Icon
                          title="Rejected"
                          category="utility"
                          name="warning"
                          size="x-small"
                          style={{ color: '#b83c27' }}
                          // className="slds-icon-text-error"
                        />
                      </div>
                    )}
                    {status === 'Requested' && visualIndicator === 'Approved' && (
                      <div style={{ marginLeft: '5px' }}>
                        <Icon
                          title="Approved"
                          category="utility"
                          name="success"
                          size="x-small"
                          style={{ backgrounColor: 'green' }}
                          className="slds-icon-text-success"
                        />
                      </div>
                    )}
                  </div>
                </span>
              </div>
            }
            label={
              TranferDetails &&
              TranferDetails?.getInventoryTransferDetails &&
              TranferDetails?.getInventoryTransferDetails.type
            }
            onRenderActions={headerActions}
            // onRenderControls={headerControls}
            truncate
            variant="object-home"
          />
          <ul
            className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
            style={{ borderBottom: 'none' }}
          >
            <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
              <div className="slds-text-title" style={{ fontWeight: 'bold' }} title="salesRep">
                Sales Rep
              </div>
              <div className="slds-tooltip-trigger">
                <div title="">
                  {TranferDetails &&
                    TranferDetails.getInventoryTransferDetails &&
                    TranferDetails.getInventoryTransferDetails?.salesRepName}
                </div>
                <span />
              </div>
            </li>
            {TranferDetails?.getInventoryTransferDetails &&
              TranferDetails?.getInventoryTransferDetails?.type && (
                <>
                  <li className="slds-page-header__detail-block slds-col slds-size_4-of-12">
                    <div
                      className="slds-text-title"
                      style={{ fontWeight: 'bold' }}
                      title="Source Account"
                    >
                      Source Account
                    </div>
                    <div className="slds-tooltip-trigger">
                      <div title="">
                        {sourceAccount && sourceAccount.length > 0
                          ? `${sourceAccount[0].number} ${sourceAccount[0].value}`
                          : '--'}
                      </div>
                      <span />
                    </div>
                  </li>
                  <li className="slds-page-header__detail-block slds-col slds-size_4-of-12">
                    <div
                      className="slds-text-title slds-truncate"
                      style={{ fontWeight: 'bold' }}
                      title="Destination Account"
                    >
                      Destination Account
                    </div>
                    <div className="slds-tooltip-trigger">
                      <div title="">
                        {destAccount && destAccount.length > 0
                          ? `${destAccount[0].number} ${destAccount[0].value}`
                          : '--'}
                      </div>
                      <span />
                    </div>
                  </li>
                </>
              )}
            {TranferDetails &&
              TranferDetails?.getInventoryTransferDetails &&
              (TranferDetails?.getInventoryTransferDetails.type === 'Rep to Rep' ||
                TranferDetails?.getInventoryTransferDetails.type === 'Transfer') && (
                <>
                  <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                    <div
                      className="slds-text-title slds-truncate"
                      style={{ fontWeight: 'bold' }}
                      title="RecevingRep"
                    >
                      Receiving Rep
                    </div>
                    <div className="slds-truncate" title="">
                      {TranferDetails &&
                        TranferDetails.getInventoryTransferDetails &&
                        TranferDetails.getInventoryTransferDetails.receivingRepName}
                    </div>
                  </li>
                </>
              )}
            {(TranferDetails &&
              TranferDetails?.getInventoryTransferDetails &&
              TranferDetails?.getInventoryTransferDetails.type === 'Event Transfer') ||
              (TranferDetails &&
                TranferDetails?.getInventoryTransferDetails &&
                TranferDetails?.getInventoryTransferDetails.type === EVENT_TRANSFER && (
                  <>
                    <li className="slds-page-header__detail-block slds-col slds-size_4-of-12">
                      <div
                        className="slds-text-title slds-truncate"
                        style={{ fontWeight: 'bold' }}
                        title="Original Event"
                      >
                        Original Event
                      </div>
                      <div className="slds-tooltip-trigger">
                        <span
                          role="button"
                          tabIndex={0}
                          className="slds-button"
                          onClick={handleParentEventRequest}
                          onKeyDown={handleParentEventRequest}
                          style={{ cursor: 'pointer' }}
                        >
                          {TranferDetails?.getInventoryTransferDetails?.parentEventId}
                        </span>
                      </div>
                    </li>
                    <li className="slds-page-header__detail-block slds-col slds-size_4-of-12">
                      <div
                        className="slds-text-title slds-truncate"
                        style={{ fontWeight: 'bold' }}
                        title="New Event"
                      >
                        New Event
                      </div>
                      <div className="slds-tooltip-trigger">
                        <span
                          role="button"
                          tabIndex={0}
                          className="slds-button"
                          onClick={handleChangeRequest}
                          onKeyDown={handleChangeRequest}
                          style={{ cursor: 'pointer' }}
                        >
                          {TranferDetails?.getInventoryTransferDetails?.eventId}
                        </span>
                      </div>
                    </li>
                    <li className="slds-page-header__detail-block slds-col slds-size_4-of-12">
                      <div
                        className="slds-text-title slds-truncate"
                        style={{ fontWeight: 'bold' }}
                        title="Date/Time"
                      >
                        Date/Time
                      </div>
                      <div className="slds-tooltip-trigger">
                        <div style={{ display: 'flex' }}>
                          <p>
                            {moment(
                              TranferDetails?.getInventoryTransferDetails?.parentEventStartDate
                            ).format('L')}
                          </p>
                          <p style={{ paddingLeft: '7.5px' }}>
                            {moment(
                              TranferDetails?.getInventoryTransferDetails?.parentEventStartDate
                            ).format('LT')}
                            {`  ${TimeZoneData}`}
                          </p>
                        </div>
                      </div>
                    </li>
                  </>
                ))}
          </ul>
          <ul
            style={{ borderBottom: 'none' }}
            className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
          >
            {attachmentConfig && attachmentConfig.isShow ? (
              // eslint-disable-next-line react/jsx-indent
              <>
                <li className="slds-page-header__detail-block slds-col slds-size_2-of-12">
                  <div
                    className="slds-text-title slds-truncate"
                    style={{ fontWeight: 'bold' }}
                    title="Attachment"
                  >
                    Attachment
                  </div>
                  {TranferDetails?.getInventoryTransferDetails?.attachments?.length > 0 ? (
                    TranferDetails?.getInventoryTransferDetails?.attachments?.map((item: any) => {
                      return (
                        <div
                          style={{ cursor: 'pointer', wordBreak: 'break-word' }}
                          onClick={(): void => handleAttachmentsView(item)}
                          aria-hidden="true"
                        >
                          {`${item.name}`}
                        </div>
                      );
                    })
                  ) : (
                    <div title="">--</div>
                  )}
                </li>
              </>
            ) : (
              ''
            )}
            {commentConfig && commentConfig.isShow ? (
              // eslint-disable-next-line react/jsx-indent
              <>
                <li className="slds-page-header__detail-block slds-col slds-size_4-of-12">
                  <div
                    className="slds-text-title slds-truncate"
                    style={{ fontWeight: 'bold' }}
                    title="Comments"
                  >
                    Comments
                  </div>
                  {TranferDetails?.getInventoryTransferDetails?.comments?.length > 0 ? (
                    TranferDetails?.getInventoryTransferDetails?.comments?.map((item: any) => {
                      return <div title="">{item.value}</div>;
                    })
                  ) : (
                    <div title="">--</div>
                  )}
                </li>
              </>
            ) : (
              ''
            )}
            {transferType === 'Failed' && (
              <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
                <div
                  className="slds-text-title slds-truncate"
                  style={{ fontWeight: 'bold' }}
                  title="Failure Reason"
                >
                  Failure Reason
                </div>
                <div title="">
                  {(TranferDetails &&
                    TranferDetails.getInventoryTransferDetails &&
                    TranferDetails.getInventoryTransferDetails.failureReason) ||
                    '--'}
                </div>
              </li>
            )}
            {TranferDetails?.getInventoryTransferDetails?.eventId &&
              TranferDetails?.getInventoryTransferDetails?.type
                .toLowerCase()
                .includes('customer') && (
                <li className="slds-page-header__detail-block slds-col slds-size_4-of-12">
                  <div
                    className="slds-text-title slds-truncate"
                    title="Comment"
                    style={{ fontWeight: 'bold' }}
                  >
                    Related Documents
                  </div>
                  {TranferDetails?.getInventoryTransferDetails?.eventId ? (
                    <div title={TranferDetails?.getInventoryTransferDetails?.eventId}>
                      <img src={parenticonPath} alt="company" />
                      <span style={{ margin: '5px 5px 5px 5px' }}>
                        {TranferDetails?.getInventoryTransferDetails?.eventType === 'Demo' &&
                        TranferDetails?.getInventoryTransferDetails?.eventSubType ===
                          'Standard Demo'
                          ? 'Standard Demo'
                          : TranferDetails?.getInventoryTransferDetails?.eventSubType ||
                            TranferDetails?.getInventoryTransferDetails?.eventType}
                      </span>
                      <span
                        role="button"
                        tabIndex={0}
                        className="slds-button"
                        onClick={handleChangeRequest}
                        onKeyDown={handleChangeRequest}
                        style={{ cursor: 'pointer' }}
                      >
                        {TranferDetails?.getInventoryTransferDetails?.eventId}
                      </span>
                    </div>
                  ) : (
                    '--'
                  )}
                </li>
              )}
          </ul>
        </>
        <div>
          {showRequestTransferModal && (
            <RequestTransfer
              formdata={formdata}
              tranferDetails={TranferDetails && TranferDetails.getInventoryTransferDetails}
              requestTransferModalVisible={showRequestTransferModal}
              handleRequestTransferModal={handleCloseRequestTranfer}
              handleRefetchItems={handleRefetchItems}
            />
          )}
          {showNewTransferModal && (
            <NewRepTransfer
              isOpen={showNewTransferModal}
              isEdit={true}
              toggleOpen={handleNewTranferModalToggle}
              transferType={
                TranferDetails &&
                TranferDetails?.getInventoryTransferDetails &&
                TranferDetails?.getInventoryTransferDetails.type.toLowerCase().includes('customer')
                  ? 'Customer Transfer'
                  : TranferDetails?.getInventoryTransferDetails.type
              }
              handleCancelButton={handleNewTranferModalToggle}
              handleRefetchItems={handleRefetchItems}
              tranferDetails={TranferDetails && TranferDetails.getInventoryTransferDetails}
              sourceAccount={sourceAccount}
              destAccount={destAccount}
            />
          )}
        </div>
        {transferType === 'Received' && (
          <InventoryRequestHeader
            inventoryDetail={TranferDetails && TranferDetails.getInventoryTransferDetails}
            transferType={transferType && transferType}
            salesRepValue={salesRepValue}
            recivingSalesRepValue={recivingSalesRepValue}
            selectedInventoryItems={selectedInventoryItems}
            handleSelectedRow={handleSelectedLineItems}
            refetchTransferDetails={refetchTransferDetails}
          />
        )}
        {TranferDetails && transferType !== 'Received' && (
          <div style={{ padding: '16px' }}>
            <IconSettings iconPath="/icons">
              <Tabs
                variant="scoped"
                id="usage-tabs-scoped"
                onSelect={handleTabSelect}
                defaultSelectedIndex={0}
                selectedIndex={selectedTabIndex}
              >
                <TabsPanel label="Requesting">
                  <InventoryRequestTable
                    refetch={refetchTransferDetails}
                    inventoryDetail={TranferDetails && TranferDetails.getInventoryTransferDetails}
                    // isEventInventoryFlow={isEventInventoryFlow}
                    visualIndicator={visualIndicator}
                    type="Requesting"
                    hasEventInventory
                    // eventInventoryExternalId={eventInventoryExternalId}
                    externalId={externalId}
                    transferType={transferType}
                    viewOnly={viewOnly}
                    // isOrder={isOrder}
                  />
                </TabsPanel>
                {TranferDetails &&
                  TranferDetails.getInventoryTransferDetails &&
                  TranferDetails.getInventoryTransferDetails.type !== EVENT_TRANSFER && (
                    <TabsPanel label="Transferred">
                      <InventoryRequestTable
                        refetch={refetchTransferDetails}
                        inventoryDetail={
                          TranferDetails && TranferDetails.getInventoryTransferDetails
                        }
                        // isEventInventoryFlow={isEventInventoryFlow}
                        visualIndicator={visualIndicator}
                        type="Transferred"
                        hasEventInventory
                        // eventInventoryExternalId={eventInventoryExternalId}
                        externalId={externalId}
                        transferType={transferType}
                        viewOnly={viewOnly}
                        // isOrder={isOrder}
                      />
                    </TabsPanel>
                  )}
              </Tabs>
            </IconSettings>
          </div>
        )}
        {TranferDetails && transferType === 'Received' && (
          <div style={{ padding: '16px' }}>
            <IconSettings iconPath="/icons">
              <InventoryRequestTable
                refetch={refetchTransferDetails}
                inventoryDetail={TranferDetails && TranferDetails.getInventoryTransferDetails}
                // isEventInventoryFlow={isEventInventoryFlow}
                visualIndicator={visualIndicator}
                type="Requesting"
                hasEventInventory
                // eventInventoryExternalId={eventInventoryExternalId}
                externalId={externalId}
                transferType={transferType}
                // isOrder={isOrder}
              />
            </IconSettings>
          </div>
        )}
      </div>
      {openTransferCancelModal && (
        <CancelTransferInventory
          cancelInventoryModalVisible={openTransferCancelModal}
          handleCancelInventoryModal={handleCancelTransferModal}
          eventId={
            TranferDetails &&
            TranferDetails.getInventoryTransferDetails &&
            TranferDetails.getInventoryTransferDetails.orderNumber
          }
          cancelInventory={cancelTransfer}
          cancelInventoryLoading={cancelTransferLoading}
        />
      )}
      {showRequestTransferModal && (
        <CancelEventModal
          canceModalVisible={cancelModalSM}
          handleCancelModal={handleCloseRequestTranfer}
          handleCancelModalConfirmPress={handleCancelModalConfirmPressSM}
          edit
        />
      )}
      {showNewTransferModal && (
        <CancelEventModal
          canceModalVisible={cancelModalNonSM}
          handleCancelModal={handleNewTranferModalToggle}
          handleCancelModalConfirmPress={handleCancelModalConfirmPressNonSM}
          edit
        />
      )}
    </IconSettings>
  );
};
/** transferdetail Tab component**/
export default TransferDetails;
