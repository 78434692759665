/*
 * Form GraphQL specification
 * @module src/graphql/getFormConfigurations
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import FRAGMENT_FORM_FIELD from './fragments';
/**  Event Details GraphQL API query */
export const GET_FORM_CONFIGURATIONS = gql`
  query getFormConfigurations {
    getFormConfigurations {
      newTrial {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      surgery {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      surgeryUsageOnly {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      workshop {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      demo {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      requestInventory {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      standardReturn {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      pickupReturn {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      inventoryRequestWithShipping {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      billOnly {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      shipAndBill {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      showGo {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      showGoWithLocation {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      mockOr {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      mockOrWithLocation {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      customerTransfer {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      eventTransfer {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      repTransfer {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      mockOr {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
      mockOrWithLocation {
        ... on FormField {
          ...fragmentFormField
        }
        ... on FormSection {
          kind
          fields {
            ...fragmentFormField
          }
        }
      }
    }
  }
  ${FRAGMENT_FORM_FIELD}
`;
