/**
 * Graphql mutation to create Cycle Count
 * @module src/graphql/createCycleCount
 */
import gql from 'graphql-tag';

/** creating Cycle Count */

const CREATE_CYCLE_COUNT = gql`
  mutation createCycleCount(
    $caseId: String
    $caseExternalId: String
    $cycleCountExternalId: String
    $cycleCountId: String
    $division: String
    $countType: String
    $formType: String
    $submitType: String
    $startDate: Date
    $endDate: Date
    $salesRepId: String
    $subInventoryIds: [String]
    $territoryNames: [String]
    $accountIds: [accountIdsInput]
    $inventoryConsignmentHold: Boolean
    $reasonCodeForUnscannedItems: Boolean
    $comments: [Comment]
  ) {
    createCycleCount(
      caseId: $caseId
      caseExternalId: $caseExternalId
      cycleCountExternalId: $cycleCountExternalId
      cycleCountId: $cycleCountId
      division: $division
      countType: $countType
      formType: $formType
      submitType: $submitType
      startDate: $startDate
      endDate: $endDate
      salesRepId: $salesRepId
      subInventoryIds: $subInventoryIds
      accountIds: $accountIds
      territoryNames: $territoryNames
      inventoryConsignmentHold: $inventoryConsignmentHold
      reasonCodeForUnscannedItems: $reasonCodeForUnscannedItems
      comments: $comments
    ) {
      message
      externalId
    }
  }
`;

export default CREATE_CYCLE_COUNT;
