import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_TRANSFER_LIST = gql`
  query getInventoryTransferList(
    $limit: Int
    $offset: Int
    $type: String
    $filters: InventoryTransferFilters
    $orderBy: String
    $orderSortType: String
  ) {
    getInventoryTransferList(
      limit: $limit
      offset: $offset
      type: $type
      filters: $filters
      orderSortType: $orderSortType
      orderBy: $orderBy
    ) {
      salesRepId
      receivingRepId
      status
      type
      createdDate
      submittedDate
      orderNumber
      eventId
      effectiveDate
      fromSubInventoryCode
      toSubInventoryCode
      recordTypeId
      priceBook2Id
      salesOrganization
      accountId
      accountNumber
      accountName
      id
      externalId
      salesRepName
      receivingRepName
      receivingRepAccountName
      receivingRepAccount
      visualIndicator
      createdByUserId
      createdByName
      failureReason
      eventExternalId
    }
  }
`;
