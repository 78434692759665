/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */
/**
 * Modal for Filter Inventory
 * @module src/components/Inventory/InventoryFilter/index
 */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useState, useCallback, useEffect } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import Button from '@salesforce/design-system-react/components/button';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Slider from '@salesforce/design-system-react/components/slider';
import Spinner from '@salesforce/design-system-react/components/spinner';
import './index.scss';
import { GET_FILTERED_PRODUCT_GROUPS } from '../../../graphql/getFilteredProductGroups';
import { REGION, DATE_VIEW_FORMATTER, FILTER_EVENT_STATUS, NonSalesRep } from '../../../util/constants';
import { GET_USER_SUB_INVENTORIES } from '../../../graphql/getUserSubInventories';
import CREATE_FILTER_PRESET from '../../../graphql/mutations/createFilterPreset';
import SaveFilter from '../FilterInventory/SaveFilter';
import UPDATE_FILTER_PRESET from '../../../graphql/mutations/updateFilterPreset';
import DeleteFilterPreset from './DeleteFilterPreset';
import DELETE_FILTER_PRESET from '../../../graphql/mutations/deleteFilterPreset';
import { getUserId } from '../../../store/ducks/userId';
import { getDateDifferenceInDays } from '../../../util/utilityFunctions';
import { clearInventoryFilters } from '../../../store/ducks/inventoryFilters';
import { GET_USER_TERRITORIES } from '../../../graphql/getUserTerritories';
import escapeRegExp from 'lodash.escaperegexp';
import { GET_FILTERED_SALES_REPS } from '../../../graphql/getFilteredSalesReps';
import { GET_PRIMARY_ACCOUNT } from '../../../graphql/getPrimaryAccount';
import { getUserInfo } from '../../../store/ducks/userInfo';
import { GET_SALES_REPS } from '../../../graphql/getSalesReps';
import AutocompleteSingleSelectPicker from '../../Shared/AutocompleteSingleSelectPicker';


interface Props {
  handleToggleFilterInventory: () => void;
  filterInventoryModalVisible: boolean;
  selectedFilter?: FilterPreset | DynamicType | null;
  refetchFilterPresets: () => void;
  handleFilterInventory: (filter: DynamicType | null) => void;
  handleEditFilter: (editInventory: boolean) => void;
  isEditFilter: boolean;
  handleClearFilter: (externalId: string) => void;
  userView?: string;
  accountItemsData?: any;
  loadingPrimaryAccounts?: boolean;
}

interface UsageData {
  id: string;
  label: string;
}

interface SelectedData {
  id: string;
  label: string;
}

interface DynamicType {
  [key: string]: any;
}
interface ValueInterface {
  value: string;
}
interface FilterPreset {
  filterValues: SelectedFilters;
  filterExternalId?: string;
  filterName?: string;
}

interface SelectedFilters {
  productGroup: SelectedData[];
  salesRepId: SelectedData[];
  isExpiredInventory: SelectedData[];
}

enum DetailTypes {
  ProductGroup = 'ProductGroup',
  Account = "Account",
  Territory = "UserTerritory",
  SalesRep = "SalesRep"

}
interface DropdownSelect {
  id: string;
  label?: string;
  value: string;
}
interface Selection {
  name: string;
  __typename: string;
  id: string;
  label: string;
  value?: string;
}
interface SelectedData {
  selection: Selection[];
}
type SelectionType = Selection[];
const FilterInventory: FC<Props> = ({
  handleToggleFilterInventory,
  filterInventoryModalVisible,
  selectedFilter,
  refetchFilterPresets,
  handleFilterInventory,
  handleEditFilter,
  isEditFilter,
  handleClearFilter,
  userView,
  accountItemsData,
  loadingPrimaryAccounts
}) => {
  const filterValues = selectedFilter?.filterValues;
  const filterName = selectedFilter ? selectedFilter.filterName : '';
  const filterExternalId = selectedFilter ? selectedFilter.filterExternalId : null;
  const usages = filterValues ? filterValues.usage : '';
  const selectedInventoryTypeData = filterValues ? filterValues.InventoryType : [];
  const selectedInventoryData = filterValues ? filterValues.Inventory : [];
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfo);
  const isUserOps = NonSalesRep?.find((rec: any) => rec?.value === userInfo?.personas) ? true : false;
  const [open, setOpen] = useState(false);
  // useState
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [loadingIndicator, setLoading] = useState(true);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [disableSlider, setDisableSlider] = useState(false);
  const [selectedInventoryTypes, setSelectedInventoryTypes] = useState<string[]>(
    selectedInventoryTypeData || []
  );
  const [selectedFilters, setSelectedFilters] = useState<DynamicType | null>(filterValues || null);
  const [sliderSelectedValue, setSliderSelectedValue] = useState(0);
  const [getTerritories, { data: territoryData }] = useLazyQuery(GET_USER_TERRITORIES);
  const [inputValue, setInputValue] = useState('');
  // // Mutation & Queries
  const [createFilterPreset, { data: filterPresets }] = useMutation(CREATE_FILTER_PRESET);
  const [updateFilterPreset, { data: updatedfilterPresets }] = useMutation(UPDATE_FILTER_PRESET);
  const [deleteFilterPreset] = useMutation(DELETE_FILTER_PRESET);
  const [territoryOptions, setTerritoryOptions] = useState<DropdownSelect[]>();
  const { data: filteredproductGroups, loading: loadingProductGroup } = useQuery(
    GET_FILTERED_PRODUCT_GROUPS
  );
  const { data: filteredSubInventory, loading: loadingSubInventory } = useQuery(
    GET_USER_SUB_INVENTORIES
  );
  const optionsWithTerritory = (value: []): any[] =>
    value &&
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      return { ...elem, label: `${elem.value}` };
  });
  useEffect(() => {
    if( !selectedFilters && isExpiredInventory?.length){
      setFilterValue('isExpiredInventory', [isExpiredInventory[0]]);
    }
    if(selectedFilters?.isExpiredInventory[0]?.label ==='Only Show Expired Inventory'){
      setDisableSlider(selectedFilters?.isExpiredInventory[0]?.id)
    }
   }, []);

  const [getSalesRepsItems, { data: salesRepsItems, loading: loadingSalesRep }] = useLazyQuery(
    GET_SALES_REPS
  );

  const optionsWithLabelAccount = (arr: []): any[] =>
    arr &&
    arr.map((elem: any) => {
      return { ...elem, label: `${elem.number} ${elem.value}`, id: `${elem.number}` };
    });

  const userId = useSelector(getUserId);
  const { data: filteredSalesReps, loading: loadingSalesReps } = useQuery(GET_FILTERED_SALES_REPS, {
    variables: {
      userId,
      region: REGION,
    },
  });
  const fetchItems = useCallback(() => {
    getSalesRepsItems();
  }, [getSalesRepsItems]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  useEffect(() => {
    getTerritories({
      variables: {
        userId: userId,
      },
    });
  }, [getTerritories]);

  useEffect(() => {
    if (territoryData?.getUserTerritories && (selectedFilters === null || (selectedFilters && selectedFilters?.subInventoryName === undefined))) {
      if (territoryData.getUserTerritories.length === 1 && userView === 'By Part') {
        handleSelect(optionsWithTerritory(territoryData.getUserTerritories), 'subInventoryName')
      }
    }
    setTerritoryOptions(optionsWithTerritory(territoryData?.getUserTerritories));
  }, [territoryData]);

  useEffect(() => {
    if (accountItemsData && accountItemsData.length > 0 && userView === 'By Part' && (selectedFilters === null || (selectedFilters && selectedFilters?.accountnum === undefined))) {
      const accountData = optionsWithLabelAccount(accountItemsData);
      const repStockAccount = accountData?.filter((rec: any) => rec.isRepStockAccount === true)
      if (repStockAccount?.length === 1) {
        handleSelect(repStockAccount, 'accountnum')
      }
    }
  }, [accountItemsData]);


  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any): void => {
      // For selected filter value
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };
      setSelectedFilters(selectedFilterValues);
    },
    [selectedFilters]
  );
  const resetFilters = (): void => {
    handleToggleFilterInventory();
    setSelectedFilters(null);
    setSelectedInventoryTypes([]);
    handleFilterInventory(null);
    setSliderSelectedValue(0);
    localStorage.setItem('sliderValue', '0');
    dispatch(clearInventoryFilters());
  };

  const handleApplyFilter = (): void => {
    handleToggleFilterInventory();
    handleFilterInventory(selectedFilters);
  };
  const handleChangeOnType = useCallback(
    (_event, selectedValue: ValueInterface) => {
      const { value } = selectedValue;
      _event.persist();
      setInputValue(value);
    },
    [setInputValue]
  );


  // const handleCreateFilterPreset = (name: string): void => {
  //   let reducedFilterValues = {};
  //   if (selectedFilters) {
  //     Object.keys(selectedFilters).forEach((key: string) => {
  //       if (Array.isArray(selectedFilters[key]) && key !== 'InventoryType') {
  //         const values = selectedFilters[key].map((item: SelectedData) => {
  //           const itemCopy = { id: item.id, label: item.label };
  //           return { ...itemCopy };
  //         });
  //         reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
  //       } else if (key !== '__typename' && selectedFilters[key]) {
  //         reducedFilterValues = { ...reducedFilterValues, [key]: selectedFilters[key] };
  //       }
  //     });
  //   }
  //   const filterData = { ...reducedFilterValues };
  //   if (!filterExternalId || filterExternalId === '') {
  //     createFilterPreset({
  //       variables: {
  //         userId,
  //         filterValues: filterData,
  //         name,
  //       },
  //     });
  //   } else if (filterExternalId) {
  //     updateFilterPreset({
  //       variables: {
  //         externalId: filterExternalId,
  //         filterValues: filterData,
  //         name,
  //       },
  //     });
  //   }
  // };

  // const handleSaveModalVisible = (): void => {
  //   if (!filterExternalId || filterExternalId === '') {
  //     setSaveModalVisible(!saveModalVisible);
  //   } else {
  //     handleCreateFilterPreset(filterName);
  //   }
  // };

  const handleDeleteModalVisible = useCallback((): void => {
    setDeleteModalVisible(!deleteModalVisible);
  }, [deleteModalVisible]);
  const handleDeleteFilterPreset = (): void => {
    deleteFilterPreset({
      variables: {
        externalId: filterExternalId,
      },
    }).then(response => {
      // if delete filter preset is successful
      const deleteUsageResponse =
        response.data &&
        response.data.deleteFilterPreset &&
        response.data.deleteFilterPreset.message === 'success';
      if (deleteUsageResponse) {
        handleDeleteModalVisible();
        handleToggleFilterInventory();
        handleClearFilter(filterExternalId);
        if (refetchFilterPresets) {
          handleEditFilter(false);
          refetchFilterPresets();
        }
      }
    });
  };
  useEffect(() => {
    if (selectedFilters) {
      const { expirationFromDate, expirationToDate } = selectedFilters;
      if (expirationFromDate && expirationToDate) {
        const val = getDateDifferenceInDays(expirationToDate, expirationFromDate);
        setSliderSelectedValue(Number(val || 0));
      }
    }
  }, []);
  useEffect(() => {
    if (
      updatedfilterPresets &&
      updatedfilterPresets.updateFilterPreset &&
      updatedfilterPresets.updateFilterPreset.message === 'success'
    ) {
      handleToggleFilterInventory();
      if (refetchFilterPresets) {
        handleEditFilter(false);
        refetchFilterPresets();
        resetFilters();
      }
    }
  }, [
    handleEditFilter,
    handleFilterInventory,
    handleToggleFilterInventory,
    refetchFilterPresets,
    selectedFilters,
    updatedfilterPresets,
  ]);

  useEffect(() => {
    if (
      filterPresets &&
      filterPresets.createFilterPreset &&
      filterPresets.createFilterPreset.message === 'success'
    ) {
      handleToggleFilterInventory();
      if (refetchFilterPresets) {
        handleEditFilter(false);
        refetchFilterPresets();
      }
    }
  }, [
    filterPresets,
    handleEditFilter,
    handleFilterInventory,
    handleToggleFilterInventory,
    refetchFilterPresets,
    selectedFilters,
  ]);
  // eslint-disable-next-line no-underscore-dangle
  const optionsWithLabel = (value: any): object =>
    value &&
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      if (
        [
          DetailTypes.ProductGroup,
          DetailTypes.Territory,
          DetailTypes.SalesRep
        ].indexOf(elem.__typename as any) >= 0
      ) {
        return { ...elem, label: `${elem.value}` };
      } if (
        [
          DetailTypes.Account,
        ].indexOf(elem.__typename as any) >= 0
      ) {
        return { ...elem, label: `${elem.number}  ${elem.value}` };
      }
    });
  const [getPrimaryAccountItems, { data: primaryAccountItems, loading: loadingPrimaryAccountsDetails }] = useLazyQuery(
    GET_PRIMARY_ACCOUNT,
    {
      fetchPolicy: 'no-cache',
    }
  ); // US
  const accountItemsDetails = primaryAccountItems?.getPrimaryAccount;

  useEffect(() => {
    if (userView === 'By Part') {
      getPrimaryAccountItems({
        variables: {
          // salesRepId: selectedFilterApiValues?.salesRepId ? selectedFilterApiValues?.salesRepId : isUserOps ? '': userInfo?.id,
        },
      });
    }
  }, [getPrimaryAccountItems]);

  const handleSelect = useCallback(
    (data: any, name: string) => {
      if (data.selection) {
        const { selection } = data;
        if (name === 'salesRepId') {
          getPrimaryAccountItems({
            variables: {
              salesRepId: selection?.length > 0 ? selection[0]?.id : isUserOps ? '' : userInfo?.id,
            },
          });
        }
        if (name === 'isExpiredInventory') {
          setDisableSlider(selection[0]?.id)
        }
        setFilterValue(name, selection);
      } else {
        setFilterValue(name, data);
      }
    },
    [setFilterValue, userId]
  );
  const handleSelectItem = useCallback(
    (_event, data: SelectedData) => {
      const { selection } = data;
      console.log('datasssss', data);
      setInputValue('');
      const updatedSelection: Selection[] = Object.values(
        selection.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
      );
      setOpen(false);
      handleSelect(updatedSelection, 'productGroup');
    },
    [setInputValue, handleSelect]
  );

  const comboboxFilterAndLimit = (parameters: any): SelectionType => {
    const { searchedValue, options, selection } = parameters;
    const inputValueRegExp = new RegExp(escapeRegExp(searchedValue), 'ig');
    return options.filter((option: Selection) => {
      const searchTermFound = option.label ? option.label.match(inputValueRegExp) : false;
      const isSeparator = false;
      const notAlreadySelected = !selection.some((sel: Selection) => sel.id === option.id);

      return (!searchedValue || isSeparator || searchTermFound) && notAlreadySelected;
    });
  };
  const productGroup = optionsWithLabel(filteredproductGroups?.getProductGroups);
  const productGroupOptions = comboboxFilterAndLimit({
      searchedValue: inputValue,
      options: productGroup || [],
      selection: selectedFilters?.productGroup || [],
    });

  const handleSliderChange = (_event: any, { value }: any) => {
    const formatter = 'yyyy-MM-dd';
    const inventoryTypesStartDate = format(new Date(), formatter);
    var date = new Date();
    const inventoryTypesEndDate = format(date.setDate(date.getDate() + value), formatter);
    if (value) {
      const selectedFilterValues = {
        ...selectedFilters,
        expirationFromDate: inventoryTypesStartDate,
        expirationToDate: inventoryTypesEndDate,
      };
      setSelectedFilters(selectedFilterValues);
      setSliderSelectedValue(value);
    }
  };
  const handleCheckboxChange = (label: string, _event: any, name: string): void => {
    if (_event.checked) {
      const inventoryTypesData = [...selectedInventoryTypes, label];
      setSelectedInventoryTypes(inventoryTypesData);
      setFilterValue(name, inventoryTypesData);
    } else {
      const invTypeData = selectedInventoryTypes.filter((i: string) => i !== label);
      setSelectedInventoryTypes(invTypeData);
      setFilterValue(name, invTypeData);
    }
  };
  const optionsWithSalesRepLabel = (value: []): any[] =>
    value &&
    // eslint-disable-next-line consistent-return
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === DetailTypes.SalesRep) {
        return { ...elem, label: `${elem.value}` };
      } else {
        return { ...elem, label: `${elem.value}` };
      }
    });
  const salesRepId = optionsWithSalesRepLabel(salesRepsItems?.getSalesReps);
  const listedAccounts = optionsWithLabelAccount(accountItemsDetails)
  //const salesRepId = optionsWithLabel(filteredSalesReps?.getSalesReps);
  const isExpiredInventory = [
    {
      "id": false,
      "value": "Include Expired Inventory",
      "__typename": "isExpiredInventory",
      "label": "Include Expired Inventory"
    },
    {
      "id": true,
      "value": "Only Show Expired Inventory",
      "__typename": "isExpiredInventory",
      "label": "Only Show Expired Inventory"
    },
  ];
  // const territoryOptions =  optionsWithTerritory(territoryData.getUserTerritories);
  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={filterInventoryModalVisible}
        onRequestClose={handleToggleFilterInventory}
        footer={[
          <Button
            className="slds-float_left slds-text-color_default"
            label="Cancel"
            disabled={
              selectedFilters?.endDate &&
              selectedFilters?.startDate &&
              selectedFilters.endDate < selectedFilters.startDate
            }
            key="Cancel"
            onClick={handleToggleFilterInventory}
          />,
          <Button label="Reset Filters" key="Reset" onClick={resetFilters} />,
          <Button
            className="yellow-btn"
            label="Apply"
            onClick={handleApplyFilter}
            disabled={!selectedFilters}
            variant="brand"
            key="Apply"
          />,
        ]}
        heading={[
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div className="slds-float_left slds-text-title_bold filter_heading" key="filterName">
            {`${filterName && filterName !== ''
                ? `${isEditFilter ? 'Edit ' : ''}Filter Preset ${filterName}`
                : 'Filters'
              }`}
          </div>,

          isEditFilter && (
            <div className="slds-float_right" key="delete">
              <Button
                className="delete_button"
                label="Delete Preset"
                key="delete"
                onClick={handleDeleteModalVisible}
              />
            </div>
          ),
        ]}
      >
        <section className="slds-p-around_medium slds-theme_shade">
          {/* {subInventory && subInventory.length > 0 && (
            <div className="full-width_button">
              <span className="slds-form-element__label">Sub-Inventory</span>
              {subInventory &&
                subInventory.map((row: any) => (
                  <div className="slds-col_padded">
                    <Checkbox
                      assistiveText={{
                        label: row.value,
                      }}
                      checked={
                        selectedFilters &&
                        selectedFilters.subInventoryName &&
                        selectedFilters.subInventoryName.indexOf(row.id) > -1
                      }
                      id={row.id}
                      labels={{
                        label: row.value,
                      }}
                      onChange={(_event: any, { checked }: { checked: boolean }): void => {
                        handleCheckboxChange(row.value, _event.target, 'subInventoryName');
                      }}
                    />
                  </div>
                ))}
            </div>
          )} */}
          {userView === 'By Account' ?
            (<><div className="full-width_button">
              <Combobox
                id="product"
                options={comboboxFilterAndLimit({
                  searchedValue: inputValue,
                  options: productGroup || [],
                  selection: selectedFilters?.productGroup || [],
                })}
                events={{
                  onChange: handleChangeOnType,
                  onRequestRemoveSelectedOption: (event: any, data: any): void => handleSelect(data, 'productGroup'),
                  onSelect: handleSelectItem,
                }}
                optionsAddItem={[]}
                labels={{
                  label: `Product Group`,
                  placeholder: 'Select Product Group',
                  noOptionsFound: 'No data found',
                }}
                disabled={false}
                multiple
                placeholder="Select Product Group"
                menuPosition="relative"
                value={inputValue}
                hasInputSpinner={loadingProductGroup}
                selection={selectedFilters ? selectedFilters.productGroup || [] : []}
                variant="readonly" />
              <p style={{marginBottom:-12,fontSize:16}}>Sales Rep</p>
              <AutocompleteSingleSelectPicker
                items={salesRepId}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'salesRepId');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.salesRepId || [] : []}
                placeholder="Sales Rep"
                label=""
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="salesRepId"
                autoSelectValue={false}
              />

              {/* <Combobox
                id="salesRep"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'salesRepId'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'salesRepId'),
                }}
                labels={{
                  label: `Sales Rep`,
                  placeholder: 'Select',
                  noOptionsFound: 'No data found',
                }}
                placeholder="Select"
                hasInputSpinner={loadingSalesReps}
                options={salesRepId || []}
                selection={selectedFilters ? selectedFilters.salesRepId || [] : []}
                variant="readonly"
              /> */}
            </div>
              <div style={{ paddingTop: '5%' }}>
                <div className="full-width_button">
                  <Combobox
                    id="isExpiredInventory"
                    events={{
                      onSelect: (event: any, data: any): void => handleSelect(data, 'isExpiredInventory'),
                      onRequestRemoveSelectedOption: (event: any, data: any): void =>
                        handleSelect(data, 'isExpiredInventory'),
                    }}
                    labels={{
                      label: `Expired Inventory`,
                      placeholder: 'Select',
                      noOptionsFound: 'No data found',
                    }}
                    placeholder="Select"
                    hasInputSpinner={loadingSalesReps}
                    options={isExpiredInventory || []}
                    selection={selectedFilters ? selectedFilters.isExpiredInventory || [] : []}
                    variant="readonly"
                  />
                  <span className="slds-form-element__label" style={{ marginTop: '5%' }}>Expiring</span>
                  <span className="slds-form-element__label" style={{ float: 'right' }}>
                    0-100 days
                  </span>
                  <Slider
                    id="min-max-id"
                    assistiveText={{ label: 'Expiring' }}
                    value={sliderSelectedValue}
                    min={0}
                    max={100}
                    disabled={disableSlider}
                    onChange={handleSliderChange} />
                  <span>min: 0</span>
                  <span style={{ float: 'right' }}>max: 100</span>
                </div>
                {/* <div className="full-width_button">
                    <div className="fltcheckedIn">
                        <Checkbox
                            labels={{
                                label: 'Checked In',
                            }}
                            id="chkCheckedIn"
                            variant="toggle"
                        />
                    </div>
                </div> */}
              </div></>) : (<><div className="full-width_button">
                {/* <h1 className="slds-m-bottom--xx-small">Territory</h1> */}
                <Combobox
                  id="territory"
                  labels={{
                    label: `Territory`,
                    placeholder: 'Select',
                    noOptionsFound: 'No data found',
                  }}
                  events={{
                    onSelect: (event: any, data: any): void => handleSelect(data, 'subInventoryName'),
                    onRequestRemoveSelectedOption: (event: any, data: any): void => handleSelect(data, 'subInventoryName'),
                  }}
                  multiple
                  placeholder="Select"
                  options={territoryOptions || []}
                  selection={selectedFilters ? selectedFilters.subInventoryName : []}
                  variant="readonly"
                />

                {/* <div className="slds-card slds-p-around--medium"> */}
                {/* <h1 className="slds-m-bottom--small">Sales Rep</h1> */}
                <AutocompleteSingleSelectPicker
                  items={salesRepId}
                  setSelectedItem={(result: any): void => {
                    handleSelect(result, 'salesRepId');
                  }}
                  disabled={false}
                  selectedItem={selectedFilters ? selectedFilters.salesRepId || [] : []}
                  placeholder="Sales Rep"
                  label="Sales Rep"
                  withAddMenuButton={false}
                  fetchItems={(searchText: string): string => searchText}
                  filter
                  name="SalesRep"
                  autoSelectValue={false}
                />
                {/* <Combobox
                id="salesRep"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'salesRepId'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'salesRepId'),
                }}
                labels={{
                  label: `Sales Rep`,
                  placeholder: 'Select',
                  noOptionsFound: 'No data found',
                }}
                placeholder="Select"
                hasInputSpinner={loadingSalesReps}
                options={salesRepId || []}
                selection={selectedFilters ? selectedFilters.salesRepId || [] : []}
                variant="readonly"
              /> */}
                {/* </div> */}

                {/* <div className="full-width_button"> */}
                {/* <h1 className="slds-m-bottom--xx-small">Account</h1> */}
                <Combobox
                  id="Account"
                  events={{
                    onSelect: (event: any, data: any): void => handleSelect(data, 'accountnum'),
                    onRequestRemoveSelectedOption: (event: any, data: any): void => handleSelect(data, 'accountnum'),
                  }}
                  labels={{
                    label: `Account`,
                    placeholder: 'Select account',
                    noOptionsFound: 'No data found',
                  }}
                  multiple
                  placeholder="Select account"
                  options={listedAccounts || []}
                  hasInputSpinner={loadingPrimaryAccounts || false}
                  selection={selectedFilters ? selectedFilters.accountnum || [] : []}
                  variant="readonly" />
                {/* </div> */}
              </div>
                <div style={{ paddingTop: '5%' }}>
                  <div className="full-width_button"  >
                    <Combobox
                      id="isExpiredInventory"
                      events={{
                        onSelect: (event: any, data: any): void => handleSelect(data, 'isExpiredInventory'),
                        onRequestRemoveSelectedOption: (event: any, data: any): void =>
                          handleSelect(data, 'isExpiredInventory'),
                      }}
                      labels={{
                        label: `Expired Inventory`,
                        placeholder: 'Select',
                        noOptionsFound: 'No data found',
                      }}
                      placeholder="Select"
                      hasInputSpinner={loadingSalesReps}
                      options={isExpiredInventory || []}
                      selection={selectedFilters ? selectedFilters.isExpiredInventory || [] : []}
                      variant="readonly"
                    />
                    <span className="slds-form-element__label" style={{ marginTop: '5%' }}>Expiring</span>
                    <span className="slds-form-element__label" style={{ float: 'right' }}>
                      0-100 days
                    </span>
                    <Slider
                      id="min-max-id"
                      assistiveText={{ label: 'Expiring' }}
                      value={sliderSelectedValue}
                      disabled={disableSlider}
                      min={0}
                      max={100}
                      onChange={handleSliderChange} />
                    <span>min: 0</span>
                    <span style={{ float: 'right' }}>max: 100</span>
                  </div>
                </div></>)}
          {/* {saveModalVisible && (
                        <SaveFilter
                            handleSaveModalVisible={handleSaveModalVisible}
                            saveModalVisible={saveModalVisible}
                            handleCreateFilterPreset={handleCreateFilterPreset}
                            selectedFilterName={filterName}
                        />
                    )}
                    {deleteModalVisible && (
                        <DeleteFilterPreset
                            deleteModalVisible={deleteModalVisible}
                            handleDeleteModalVisible={handleDeleteModalVisible}
                            handleDeleteFilterPreset={handleDeleteFilterPreset}
                        />
                    )} */}
        </section>
      </Modal>
    </IconSettings>
  );
};
/** Filter Inventory modal component */
export default FilterInventory;
