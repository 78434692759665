/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

/**
 * React component for Input.
 * @module src/components/shared/Checkbox
 */
import React, { FC, useCallback } from 'react';
import Radio from '@salesforce/design-system-react/components/radio-group/radio';

interface Props {
  handleRadioButtonChange: (event: any, type: any) => void;
  accountChecked: any;
  label: any;
  items: any;
  disabled?: any;
}

const RadioButton: FC<Props> = props => {
  const { handleRadioButtonChange, accountChecked, label, items, disabled } = props;
  return (
    <div
      className="slds-grid"
      style={{
        justifyContent: 'space-between',
        marginBottom: label === 'Specific Shipping Details' ? '0.75rem' : '',
      }}
    >
      {label !== 'Account Types' && (
        <span className="slds-text-body_small slds-text-title_bold">{label}</span>
      )}
      <div className="slds-grid">
        {items &&
          items.map((value: any) => (
            <div style={{ marginRight: '30px' }}>
              <Radio
                className="radio-group"
                key={value.label}
                id={value.label}
                onChange={(event: { target: { value: any } }) =>
                  handleRadioButtonChange(event, 'accounts')
                }
                defaultChecked={value.name === 'myAccounts'}
                labels={{
                  label: value.label,
                }}
                value={value.label}
                checked={accountChecked === value.label}
                variant="base"
                size="large"
                disabled={disabled}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

/** React component for Checkbox. */
export default RadioButton;
