/**
 * Redux file for getting/setting event filters
 * @module src/store/ducks/inventoryFilters
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_IN_FILTERS = 'SET_IN_FILTERS';
const CLEAR_IN_FILTERS = 'CLEAR_IN_FILTERS';

interface SetInventoryFiltersActionType {
  payload: string | null;
  type: typeof SET_IN_FILTERS;
}

interface CleaInventoryFiltersActionType {
  type: typeof CLEAR_IN_FILTERS;
}

/** eventFilters action type */

export type InventoryFilterActionType =
  | SetInventoryFiltersActionType
  | CleaInventoryFiltersActionType;

/** set eventFilters action creator */

export const setInventoryFilters = (state: string | null): InventoryFilterActionType => {
  return {
    payload: state,
    type: SET_IN_FILTERS,
  };
};

/** clear eventFilters action creator */

export const clearInventoryFilters = (): CleaInventoryFiltersActionType => {
  return {
    type: CLEAR_IN_FILTERS,
  };
};

/** eventFilters state type */

export type InventoryFilterState = string | null;

/** eventFilters reducer */

export default (state: InventoryFilterState = null, action: ActionType): InventoryFilterState => {
  switch (action.type) {
    case SET_IN_FILTERS: {
      return action.payload;
    }
    case CLEAR_IN_FILTERS: {
      return null;
    }
    default:
      return state;
  }
};

/** selector to get accessToken */

export const getInventoryFilters = (state: State): InventoryFilterState => {
  return state.inventoryFilters;
};
