/*
 * Event Details GraphQL specification
 * @module src/graphql/getInventoryReturnList
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Inventory Request list GraphQL API query */
export const GET_INVENTORY_RETURN_LIST = gql`
  query getInventoryReturnList(
    $limit: Int
    $offset: Int
    $type: String
    $filters: InventoryReturnFilters
    $orderBy: String
    $orderSortType: String
  ) {
    getInventoryReturnList(
      limit: $limit
      offset: $offset
      type: $type
      filters: $filters
      orderBy: $orderBy
      orderSortType: $orderSortType
    ) {
      salesRepId
      assignedOPSId
      status
      type
      createdDate
      orderNumber
      fromSubInventoryCode
      toSubInventoryCode
      salesOrganization
      accountId
      accountNumber
      accountName
      id
      externalId
      salesRepName
      assignedOPSName
      visualIndicator
      shipmentDate
      submittedDate
      returnExpiryDate
      trackingNumber
      returnReason
      createdByUserId
      createdByName
      territoryNumber
      parentEventId
      parentEventType
      parentEventStatus
      parentCaseExternalId
      parentCaseId
      parentEventSubType
    }
  }
`;
