/*
 * Workflow Permissions GraphQL specification
 * @module src/graphql/workflowPermissions
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export interface WorkflowPermission {
  id: string;
}

/** Workflow Permissions GraphQL data interface */
export interface WorkflowPermissionsData {
  workflowPermissions: WorkflowPermission[];
}

/** Workflow Permissions GraphQL API query */
export const GET_WORKFLOW_PERMISSIONS = gql`
  {
    workflowPermissions {
      id
    }
  }
`;

/** Workflow Permissions GraphQL API query */
export const GET_WORKFLOW_PERMISSION = gql`
  query workflowPermission($division: String, $workflow: String, $state: String, $action: String!) {
    workflowPermission(division: $division, workflow: $workflow, state: $state, action: $action) {
      id
    }
  }
`;
