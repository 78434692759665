/**
 * Redux file for getting/setting event filters
 * @module src/store/ducks/spinner
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const START_LOADING = 'START_LOADING';
const STOP_LOADING = 'STOP_LOADING';

export type SpinnerState = {
  isLoading: boolean;
} | null;

export type StartLoadingActionType = {
  payload: SpinnerState;
  type: typeof START_LOADING;
};

export type StopLoadingActionType = {
  payload: SpinnerState;
  type: typeof STOP_LOADING;
};

export const startLoading = (): StartLoadingActionType => {
  return {
    payload: { isLoading: true },
    type: START_LOADING,
  };
};

export const stopLoading = (): StopLoadingActionType => {
  return {
    payload: { isLoading: false },
    type: STOP_LOADING,
  };
};

/** spinner reducer */
export default (state: SpinnerState = null, action: ActionType): SpinnerState => {
  switch (action.type) {
    case START_LOADING: {
      return action.payload;
    }
    case STOP_LOADING: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const getSpinnerState = (state: State): SpinnerState => {
  return state.spinnerState;
};
