/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable */
/**
 * React component for Upload File.
 * @module src/components/shared/UploadFile/index
 */
import React, { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Icon from '@salesforce/design-system-react/components/icon';
import { GET_SAS_URI } from '../../../graphql/getSasUri';
interface Props {
  label: string;
  buttonText: string;
  handleUploadFiles?: ((value: string, label: string) => void | undefined) | undefined;
  handleDeleteAttachments?: ((value: any, label: string) => void | undefined) | undefined;
  uploadedFiles?: File[];
  required?: boolean;
  uploadedShippingLabelFiles?: File[];
  handleOnChange?: (event: any) => void;
}

interface File {
  manual?: boolean;
  imageurl: string;
  name: string;
}

const UploadFile: FC<Props> = props => {
  const {
    label,
    buttonText,
    handleUploadFiles,
    uploadedFiles,
    handleDeleteAttachments,
    uploadedShippingLabelFiles,
    required,
    handleOnChange
  } = props;
  const { data: sasUriData } = useQuery(GET_SAS_URI);
  // Todo Convert the funtion to UseCallback when performing some operation on it
  const handleChange = (event: any, label: any): void => {
    // eslint-disable-next-line no-console
    if (handleUploadFiles) {
      if (handleOnChange) {
        handleOnChange(event.target);
      }
      handleUploadFiles(event.target, label);
    }
  };
  const onInputClick = (event: any) => {
    event.target.value = '';
 };
  
  const handleAttachmentsView = (file: File): void => {
    const url = `${file.imageurl.split('?')[0]}?${sasUriData?.getSasUri.sasUri}`;
    window.open(url, '_blank');
  };

  const handleDeleteAttachment = (file: any, label: string): void => {
    if (handleDeleteAttachments) {
      handleDeleteAttachments(file, label);
    }
  };
  const optionalText = required ? '*' : '';
  return (
    <div className="slds-form-element">
      <span className="slds-required" style={{fontSize: '15px'}} title="required">{optionalText}</span>
      <span className="slds-form-element__label" id="file-selector-primary-label">
        {label}
      </span>
      <div className="slds-form-element__control">
        <div className="slds-size_3-of-3 slds-box">
          <div className="slds-file-selector slds-file-selector_files slds-align_absolute-center">
            <input
              type="file"
              className="slds-file-selector__input slds-assistive-text"
              accept="image/*, application/*"
              id={`file-upload-input-${label}`}
              aria-labelledby="file-selector-primary-label file-selector-secondary-label"
              onChange={(event: any) => handleChange(event, label)} onClick={(event: any) => onInputClick(event)}
            />
            <label
              className="slds-file-selector__body"
              htmlFor={`file-upload-input-${label}`}
              id="file-selector-secondary-label"
            >
              <span className="slds-file-selector__button slds-button slds-button_neutral">
                <div className="uploadButton">
                  <Icon category="utility" name="upload" size="x-small" />
                </div>

                <span className="slds-p-left_small slds-p-top_xxx-small">{buttonText}</span>
              </span>
            </label>
          </div>
          {label === 'Attachments' && uploadedFiles?.length ? (
            <>
              <div style={{ fontWeight: 'bold' }}>Added Attachments</div>
              {/* <div style={{ display: 'flex', flexDirection: 'column' }}> */}
              {uploadedFiles?.map(file => (
                <>
                  <div
                    style={{
                      cursor: 'pointer',
                      float: 'left',
                      marginRight: '10px',
                    }}
                    onClick={(e: React.MouseEvent<Element, MouseEvent>): void =>
                      handleAttachmentsView(file)
                    }
                    aria-hidden="true"
                  >
                    {file.name}
                  </div>
                  <div
                    style={{ cursor: 'pointer', marginLeft: '30px' }}
                    onClick={(): void => handleDeleteAttachment(file, label)}
                    aria-hidden="true"
                  >
                    <Icon style={{ fill: 'red' }} category="utility" name="delete" size="x-small" />
                  </div>
                </>
              ))}
              {/* </div> */}
            </>
          ) : null}
          {label === 'Shipping Label' && uploadedShippingLabelFiles?.length ? (
            <>
              <div style={{ fontWeight: 'bold' }}>Added Attachments</div>
              {/* <div style={{ display: 'flex', flexDirection: 'column' }}> */}
              {uploadedShippingLabelFiles?.map(file => (
                <>
                  <div
                    style={{
                      cursor: 'pointer',
                      float: 'left',
                      marginRight: '10px',
                    }}
                    onClick={(e: React.MouseEvent<Element, MouseEvent>): void =>
                      handleAttachmentsView(file)
                    }
                    aria-hidden="true"
                  >
                    {file.name}
                  </div>
                  <div
                    style={{ cursor: 'pointer', marginLeft: '30px' }}
                    onClick={(): void => handleDeleteAttachment(file, label)}
                    aria-hidden="true"
                  >
                    <Icon style={{ fill: 'red' }} category="utility" name="delete" size="x-small" />
                  </div>
                </>
              ))}
              {/* </div> */}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

/** React component for Upload File. */
export default UploadFile;
