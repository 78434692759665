/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-unused-vars*/
import React, { FC, useState } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
// import Input from '@salesforce/design-system-react/components/input';
import InventoryCountDetails from './InventoryCountTable';
import { CYCLE_COUNT } from '../../../../util/constants';
import { getEventIcon } from '../../../../util/utilityFunctions';

interface Props {
  cycleCountDetailTable: any[];
  cycleCountDetails: any;
  refetchCycleCountDetails: any;
  refetch?: () => void;
  getCycleCountLineItemsDetails?: () => void;
  getCycleCountLineItemsDetailsForExcel?: () => void;
  opsActionType?: any;
}

const CycleCountDetailTable: FC<Props> = ({
  cycleCountDetailTable,
  refetch,
  cycleCountDetails,
  getCycleCountLineItemsDetails,
  getCycleCountLineItemsDetailsForExcel,
  refetchCycleCountDetails,
  opsActionType,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  // Set Active Tab
  const refetchedOntab = localStorage.getItem('refetchedOnTab');
  if (refetchedOntab) {
    setSelectedTabIndex(Number(refetchedOntab));
    // Clear Refetch Tab
    localStorage.setItem('refetchedOnTab', '');
  }
  const handleTabSelect = (value: number): void => {
    setSelectedTabIndex(value);
  };

  const iconPath = getEventIcon(CYCLE_COUNT);

  return (
    <div style={{ padding: '16px' }}>
      <IconSettings iconPath={iconPath}>
        <Tabs
          variant="scoped"
          id="usage-tabs-scoped"
          onSelect={handleTabSelect}
          defaultSelectedIndex={0}
          selectedIndex={selectedTabIndex}
        >
          <TabsPanel label="Inventory Count">
            <InventoryCountDetails
              cycleCountDetails={cycleCountDetails}
              inventoryCountTableDetails={cycleCountDetailTable}
              refetch={refetch}
              refetchCycleCountDetails={refetchCycleCountDetails}
              getCycleCountLineItemsDetails={getCycleCountLineItemsDetails}
              getCycleCountLineItemsDetailsForExcel={getCycleCountLineItemsDetailsForExcel}
            />
          </TabsPanel>
        </Tabs>
      </IconSettings>
    </div>
  );
};

export default CycleCountDetailTable;
