/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { getSvgIcon } from '../../../util/utilityFunctions';

interface Status {
  caseExternalId: string;
  assignedUserName: string;
  showRedWarningIcon?: boolean;
  showBackorderdWarning?: boolean;
}
export interface Props {
  children?: FC;
  item?: Status;
  handleReset: any;
}

const ResetCell: FC<Props> = ({
  item = {
    caseExternalId: '',
    showRedWarningIcon: false,
    showBackorderdWarning: false,
    assignedUserName: null,
  },
  handleReset,
}) => {
  const { caseExternalId, assignedUserName } = item;
  const iconReset = assignedUserName ? getSvgIcon('reset') : getSvgIcon('resetDisabled');
  return (
    <IconSettings iconPath="/icons">
      <DataTableCell title="Reset">
        <div onClick={() => assignedUserName && handleReset(caseExternalId)}>
          <img
            src={iconReset}
            alt="backorder"
            style={{ width: '30px', height: '30px', marginLeft: '15px' }}
          />
        </div>
      </DataTableCell>
    </IconSettings>
  );
};

export default ResetCell;
