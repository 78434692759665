/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_CYCLE_COUNT_REQUEST_LISTS = gql`
  query getCycleCountRequestList(
    $limit: Int
    $offset: Int
    $orderBy: String
    $orderSortType: String
    $type: String
    $filters: CycleCountRequestFilters
  ) {
    getCycleCountRequestList(
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      orderSortType: $orderSortType
      filters: $filters
      type: $type
    ) {
      caseId
      caseExternalId
      caseSFID
      cycleCountExternalId
      cycleCountId
      sfid
      startDate
      endDate
      status
      salesRep
      inventoryLocation
      territory
      countType
      isInventoryHold
      isInventoryConsigmentHold
      assignedUserId
      assignedUserName
      submittedDate
    }
  }
`;
