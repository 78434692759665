/**
 * Module for Deleting Filter Preset.
 * @module src/components/FilterEvent/DeleteFilterPreset
 */
import React, { FC } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import './index.scss';

interface Props {
  deleteModalVisible: boolean;
  handleDeleteModalVisible: () => void;
  handleDeleteFilterPreset: () => void;
}

const DeleteFilterPreset: FC<Props> = ({
  deleteModalVisible,
  handleDeleteModalVisible,
  handleDeleteFilterPreset,
}) => {
  return (
    <Modal
      className="delete-preset-modal default-modal_header default-modal_footer"
      isOpen={deleteModalVisible}
      onRequestClose={handleDeleteModalVisible}
      ariaHideApp={false}
      footer={[
        <Button label="Yes" variant="brand" onClick={handleDeleteFilterPreset} key="delete_yes" />,
        <Button label="No" onClick={handleDeleteModalVisible} key="delete_no" />,
      ]}
      heading="Delete Filter Preset"
    >
      <div className="slds-text-align--center">
        <div>
          <h1 className="slds-m-around--large ">Are you sure you want to delete this preset?</h1>
        </div>
      </div>
    </Modal>
  );
};

/** Custom component for Deleting Filter Preset */
export default DeleteFilterPreset;
