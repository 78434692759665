/*
 * User Notifications GraphQL specification
 * @module src/graphql/getUserNotificationCount
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/** User Info GraphQL API query */
export const GET_USER_NOTIFICATIONS_COUNT = gql`
  query getUserNotificationCount($userId: String) {
    getUserNotificationCount(userId: $userId) {
      notificationCount
    }
  }
`;
