/**
 * Module for Cancelling evnet creation.
 * @module src/components/CreateEditSurgeryEvent/CancelEventCreationModal
 */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import './index.scss';

interface Props {
  isReturn?: boolean;
  isCycleCount?: boolean;
  isInventory?: boolean;
  isTransfer?: boolean;
  isNew?: boolean;
  canceModalVisible: boolean;
  handleCancelModal: () => void;
  handleCancelModalConfirmPress: () => void;
  edit?: boolean;
}

const CancelEventModal: FC<Props> = ({
  isReturn,
  isInventory,
  isCycleCount,
  canceModalVisible,
  isTransfer,
  isNew,
  handleCancelModal,
  handleCancelModalConfirmPress,
  edit,
}) => {
  return (
    <Modal
      className="cancel-creation-modal default-modal_header default-modal_footer"
      isOpen={canceModalVisible}
      onRequestClose={handleCancelModal}
      ariaHideApp={false}
      footer={[
        <Button label="Yes" onClick={handleCancelModalConfirmPress} key="yes" />,
        <Button label="No" variant="brand" onClick={handleCancelModal} key="no" />,
      ]}
      heading="Cancel Confirmation"
    >
      <div className="slds-text-align--center">
        <div>
          <h1 className="slds-m-around--large ">
            {isCycleCount
              ? 'Are you sure you want to cancel this Cycle Count?'
              : edit
              ? `Are you sure you want to cancel this record update?`
              : `Are you sure you want to cancel this
            ${isInventory || isReturn || isTransfer || isNew ? 'record' : ''}
            creation?`}
          </h1>
        </div>
      </div>
    </Modal>
  );
};

/** Custom component for Cancelling evnet creation */
export default CancelEventModal;
