/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useState, useCallback, useEffect } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { escapeRegExp } from 'lodash';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Pill from '@salesforce/design-system-react/components/pill';
import Icon from '@salesforce/design-system-react/components/icon';
import DatePicker from '../Shared/DatePicker';
import { getUserId } from '../../store/ducks/userId';
import { getUserInfo } from '../../store/ducks/userInfo';
import { GET_SALES_REPS } from '../../graphql/getSalesReps';
import AutocompleteSingleSelectPicker from '../Shared/AutocompleteSingleSelectPicker';
import AutocompleteSingleSelectPickerWithSearch from '../Shared/AutocompleteSingleSelectPickerWithSearch';
import { GET_FILTERED_PRODUCT_GROUPS } from '../../graphql/getFilteredProductGroups';
import { GET_FILTERED_ACCOUNTS } from '../../graphql/getFilteredAccounts';
import {
  DATE_VIEW_FORMATTER,
  FILTER_CYCLECOUNT_STATUS,
  COUNT_TYPE_ACTIONS,
  COUNT_TYPE_ACTIONS_NON_SM,
  FILTER_CYCLE_COUNT,
  NOTIFY_TYPE,
  SPORTS_MED,
} from '../../util/constants';
import { GET_USER_TERRITORIES } from '../../graphql/getUserTerritories';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import { GET_ACCOUNTS } from '../../graphql/getAccounts';

//import { clearOrderFilters } from '../../store/ducks/orderFilters';

interface Props {
  handleToggleFilterCycleCount: () => void;
  filterModalVisible: boolean;
  handleFilterCycleCount: (filter: DynamicType | null) => void;
  resetFilters: () => void;
  selectedFilter?: DynamicType | null;
  type?: string;
  // //   handleEditFilter: (editEvent: boolean) => void;
}

interface SelectedData {
  id: string;
  label: string;
}

interface DynamicType {
  [key: string]: any;
}

interface ValueInterface {
  value: string;
}
interface SelectedFilters {
  status: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  account: SelectedData[];
  salesRep: SelectedData[];
  countType: string[];
}

// enum DetailTypes {
//   InventoryLocation = 'UserTerritory',
// }

enum DetailTypes {
  Account = 'Account',
  SalesRep = 'SalesRep',
  ProcedureDetail = 'ProcedureDetail',
  CoveringRep = 'CoveringRep',
  Surgeon = 'Surgeon',
  userTerritory = 'UserTerritory',
  TBAAccount = 'TBAAccount',
  ProductGroup = 'ProductGroup',
}

enum DetailTypesSM {
  InventoryLocation = 'UserTerritory',
}
const FilterCycleCount: FC<Props> = ({
  handleToggleFilterCycleCount,
  filterModalVisible,
  selectedFilter,
  handleFilterCycleCount,
  resetFilters,
  //   handleEditFilter,
}) => {
  const filterValues = selectedFilter?.filterValues;
  const userId:any = useSelector(getUserId);
  const userInfo = useSelector(getUserInfo);
  // const dispatch = useDispatch();
  // // useState
  const [selectedFilters, setSelectedFilters] = useState<DynamicType | null>(filterValues || {});

  const { data: territoryData, loading: loadingTerritory } = useQuery(GET_USER_TERRITORIES, {
    variables: {
      userId: userId,
    },
  });

  const [inputValueAccount, setInputValueAccount] = useState('');
  const [accountOptions, setAccountOptions] = useState<any>();
  const [inputValue, setInputValue] = useState('');

  const [
    getEligibleAccounts,
    { data: eligibleAccountsData, loading: loadingEligibleAccounts },
  ] = useLazyQuery(GET_ACCOUNTS, { fetchPolicy: 'no-cache' });

  const [getSalesRepsItems, { data: salesRepsItems, loading: loadingSalesRep }] = useLazyQuery(
    GET_SALES_REPS
  );

  const { data: filteredproductGroups, loading: loadingProductGroup } = useQuery(
    GET_FILTERED_PRODUCT_GROUPS
  );

  useEffect(() => {
    getSalesRepsItems();
    if (selectedFilters && selectedFilters.salesRep) {
      getEligibleAccounts({
        variables: {
          salesRepId: selectedFilters.salesRep[0]?.id,
          limit: 1000
        },
      });
    }else{
      getEligibleAccounts({
        variables: {
          limit: 1000
        },
      });
    }
  }, [selectedFilters]);

  useEffect(()=>{
    if(selectedFilters && selectedFilters.account && selectedFilters.salesRep){
      selectedFilters.account=[];
    }
  },[selectedFilters?.salesRep])

  const getApiDateTime = useCallback((eventDate: any, name: string): string => {
    const formattedSelectedDate = new Date(eventDate);
    let updatedDate;

    if (name.toLowerCase().includes('enddate') || name.toLowerCase().includes('todate')) {
      updatedDate = new Date(formattedSelectedDate).setHours(23);
      updatedDate = new Date(updatedDate).setMinutes(59);
      updatedDate = new Date(updatedDate).setSeconds(59);
    } else {
      updatedDate = new Date(formattedSelectedDate).setHours(0);
      updatedDate = new Date(updatedDate).setMinutes(0);
      updatedDate = new Date(updatedDate).setSeconds(0);
    }

    const finalDate = format(new Date(updatedDate), DATE_VIEW_FORMATTER);
    return finalDate;
  }, []);

  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any): void => {
      // For selected filter value
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };

      setSelectedFilters(selectedFilterValues);
    },
    [selectedFilters]
  );

  const handleSelect = useCallback(
    (data: any, name: string) => {
      const { selection } = data;
      if ((name === 'salesRep' || name === 'account') && data) {
        setFilterValue(name, data);
      } else {
        setFilterValue(name, selection);
      }
    },
    [setFilterValue]
  );

  const handleSelectItemAccount = useCallback(
    (_event, data: any) => {
      const { selection } = data;
      setInputValueAccount('');
      setFilterValue('inventoryLocation', selection);
    },
    [setFilterValue]
  );

  // const handleChangeOnAccount = useCallback(
  //   (_event, selectedValue: ValueInterface) => {
  //     const { value } = selectedValue;
  //     _event.persist();
  //     setInputValueAccount(value);
  //   },
  //   [setInputValueAccount]
  // );

  // const handleRemoveSelectionAccount = useCallback(
  //   (_event, data: any) => {
  //     const { selection } = data;
  //     setInputValueAccount('');
  //     setFilterValue('inventoryLocation', selection);
  //   },
  //   [setFilterValue]
  // );

  const getAccountdata = (searchText: string): void => {
    getEligibleAccounts({
    variables: {
      salesRepId: (selectedFilters && selectedFilters.salesRep && selectedFilters.salesRep[0]) ? selectedFilters.salesRep[0]?.id : '',
      limit: 1000,
      filters: {
        searchText,
      },
    },
  });
};

  const comboboxFilterAndLimit = (parameters: any): any => {
    const { searchedValue, options, selection } = parameters;
    const inputValueRegExp = new RegExp(escapeRegExp(searchedValue), 'ig');
    return options.filter((option: any) => {
      const searchTermFound = option?.label ? option?.label.match(inputValueRegExp) : false;
      const isSeparator = false;
      const notAlreadySelected = !selection?.some((sel: any) => sel?.id === option?.id);
      return (!searchedValue || isSeparator || searchTermFound) && notAlreadySelected;
    });
  };

  const reset = (): void => {
    handleToggleFilterCycleCount();
    setSelectedFilters(null);
    // setSelectedOrderTypes([]);
    resetFilters();
    //   dispatch(clearOrderFilters());
  };

  const handleChangeDate = (selectedDate: any, name: string): void => {
    if (selectedDate) {
      const utcDate = getApiDateTime(selectedDate, name);
      setFilterValue(name, utcDate);
    }
  };

  const handleApplyFilter = (): void => {
    handleToggleFilterCycleCount();
    handleFilterCycleCount(selectedFilters);
  };

  const handleCheckboxChange = (value: any, checked: boolean): void => {
    if (checked) {
      // setFilterValue('countType', [...selectedFilters?.countType, value])
      const countTypes = selectedFilters?.countType || [];
      countTypes.push(value);
      setFilterValue('countType', countTypes);
    } else {
      const countType = selectedFilters?.countType?.filter((item: any) => item !== value) || [];
      setFilterValue('countType', countType);
    }
    // setFilterValue('countType', event.target.value);
  };

  const optionsWithLabelTerritory = (subInventoryCode: any): object =>
    subInventoryCode &&
    subInventoryCode.map((elem: { value: any; __typename: string; subInventoryCode: any }) => {
      if ([DetailTypesSM.InventoryLocation].indexOf(elem.__typename as any) >= 0) {
        return { ...elem, label: `${elem.value}` };
      }
    });

  const optionsWithSalesRepLabel = (value: []): any[] =>
    value &&
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      if (elem.__typename === 'SalesRep') {
        return { ...elem, label: `${elem.value}` };
      }
      return { ...elem, label: `${elem.value}` };
    });

  const optionsWithLabel = (value: any): object =>
    value &&
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      if (elem.__typename === 'UserTerritory') {
        return { ...elem, label: `${elem?.value}` };
      }
      if ([DetailTypes.Account].indexOf(elem.__typename as any) >= 0) {
        return { ...elem, label: ` ${elem.number} ${elem.value}` };
      }
      return { ...elem, label: `${elem?.number} - ${elem?.name}` };
    });

  const optionsWithPGLabel = (value: any): object =>
    value &&
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      if ([DetailTypes.ProductGroup].indexOf(elem.__typename as any) >= 0) {
        return { ...elem, label: `${elem.value}` };
      }
    });

  const handleChangeOnType = useCallback(
    (_event, selectedValue: ValueInterface) => {
      const { value } = selectedValue;
      _event.persist();
      setInputValue(value);
    },
    [setInputValue]
  );

  const removeDuplicates = (array: any): any => {
    const newArray: any[] = [];
    array.filter((item: any) => {
      const i = newArray.findIndex(x => x?.number === item?.number);
      if (i <= -1) {
        newArray.push(item);
      }
      return null;
    });
    return newArray;
  };
  const handleSelectPG = useCallback(
    (data: any, name: string) => {
      if (data?.selection) {
        const { selection } = data;
        setFilterValue(name, selection);
      } else {
        setFilterValue(name, data);
      }
    },
    [setFilterValue, userId]
  );

  const salesRepsData = optionsWithSalesRepLabel(salesRepsItems?.getSalesReps);
  const productGroup = optionsWithPGLabel(filteredproductGroups?.getProductGroups);

  useEffect(() => {
    // if (territoryData?.getUserTerritories && userInfo?.businessunit === SPORTS_MED) {
    //   setAccountOptions(optionsWithLabelTerritory(territoryData?.getUserTerritories));
    // } else {
      if (eligibleAccountsData?.getAccounts) {
        setAccountOptions(optionsWithLabel(removeDuplicates(eligibleAccountsData?.getAccounts)));
      }
  //  }
  }, [territoryData, eligibleAccountsData]);

  const handleSelectItem = useCallback(
    (_event, data: any) => {
      const { selection } = data;
      setInputValue('');
      const updatedSelection: Selection[] = Object.values(
        selection.reduce((acc: any, cur: any) => Object.assign(acc, { [cur.id]: cur }), {})
      );
      handleSelectPG(updatedSelection, 'productGroup');
    },
    [setInputValue, handleSelectPG]
  );

  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={filterModalVisible}
        onRequestClose={handleToggleFilterCycleCount}
        footer={[
          <Button
            className="slds-float_left slds-text-color_default"
            label="Cancel"
            key="Cancel"
            onClick={handleToggleFilterCycleCount}
          />,
          <Button label="Reset Filter" key="Reset" onClick={reset} />,
          <Button
            className="yellow-btn"
            label="Apply"
            onClick={handleApplyFilter}
            disabled={
              selectedFilters?.endDate &&
              selectedFilters?.startDate &&
              selectedFilters.endDate < selectedFilters.startDate
            }
            variant="brand"
            key="Apply"
          />,
        ]}
        heading={[
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div className="slds-float_left slds-text-title_bold filter_heading" key="filterName">
            Filters
          </div>,
        ]}
      >
        <section className="slds-p-around_medium slds-theme_shade">
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--xx-small">Attributes</h1>
            <div className="filter_date_container" style={{ marginTop: '-17px' }}>
              <p className="selectbox_label" style={{ marginBottom: '-4px' }}>
                Count Type
              </p>
              <Combobox
                id="countType"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'countType'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'countType'),
                }}
                labels={{
                  label: ``,
                  placeholderReadOnly: 'Select Count Type',
                  noOptionsFound: 'No data found',
                }}
                multiple
                // placeholder="Select Count Type"
                options={FILTER_CYCLE_COUNT || []}
                selection={selectedFilters ? selectedFilters?.countType : []}
                variant="readonly"
              />
            </div>
            <div className="filter_date_container">
              <p className="selectbox_label" style={{ marginBottom: '-4px' }}>
                Status
              </p>
              <Combobox
                id="status"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'status'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'status'),
                }}
                labels={{
                  label: ``,
                  placeholderReadOnly: 'Select Status',
                  noOptionsFound: 'No data found',
                }}
                multiple
                // placeholder="Select Status"
                options={FILTER_CYCLECOUNT_STATUS || []}
                selection={selectedFilters ? selectedFilters?.status : []}
                variant="readonly"
              />
            </div>
            <div className="filter_date_container">
              <p className="selectbox_label" style={{ marginBottom: '-4px' }}>
                Product Group
              </p>
              <Combobox
                id="product"
                options={comboboxFilterAndLimit({
                  searchedValue: inputValue,
                  options: productGroup || [],
                  selection: selectedFilters?.productGroup || [],
                })}
                events={{
                  onChange: handleChangeOnType,
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelectPG(data, 'productGroup'),
                  onSelect: handleSelectItem,
                }}
                optionsAddItem={[]}
                labels={{
                  label: ``,
                  placeholder: 'Select an Option',
                  noOptionsFound: 'No data found',
                }}
                disabled={false}
                multiple
                // placeholder="Select an Option"
                menuPosition="relative"
                value={inputValue}
                hasInputSpinner={loadingProductGroup}
                selection={selectedFilters ? selectedFilters?.productGroup || [] : []}
                variant="base"
              />
            </div>
          </div>
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--xx-small">Date</h1>
            <div className="filter_date_container" style={{ marginTop: '-17px' }}>
              <p className="selectbox_label">Start Date</p>
              <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="Select Start Date"
                    handleChangeDate={(data: any): void => handleChangeDate(data, 'startDate')}
                    disabled={false}
                    isFilterDate={true}
                    value={selectedFilters ? selectedFilters.startDate : null}
                  />
                </div>
              </div>
            </div>
            <div className="filter_date_container">
              <p className="selectbox_label">End Date</p>
              <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="Select End Date"
                    handleChangeDate={(data: any): void => handleChangeDate(data, 'endDate')}
                    disabled={false}
                    isFilterDate={true}
                    value={selectedFilters ? selectedFilters.endDate : null}
                  />
                </div>
              </div>
            </div>
            {selectedFilters?.endDate &&
              selectedFilters?.startDate &&
              selectedFilters.endDate < selectedFilters.startDate && (
                <Pill
                  labels={{
                    label: 'Error: To Date must be greater than or equal to From Date',
                  }}
                  hasError
                  icon={
                    <Icon
                      title="Error"
                      category="utility"
                      name="warning"
                      className="slds-icon-text-error"
                    />
                  }
                />
              )}
          </div>
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--xx-small">Other</h1>
            <div className="filter_date_container" style={{ marginTop: '-17px' }}>
              <p className="selectbox_label" style={{ marginBottom: '-12px' }}>
                Sales Rep
              </p>
              <AutocompleteSingleSelectPicker
                items={salesRepsData}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'salesRep');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters?.salesRep || [] : []}
                placeholder="Select Sales Rep"
                label=""
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="salesRep"
                autoSelectValue={false}
              />
            </div>
            <div className="filter_date_container" style={{ marginBottom: '10px' }}>
              <p className="selectbox_label" style={{ marginBottom: '-12px' }}>
                Account
              </p>
              <AutocompleteSingleSelectPickerWithSearch
                items={accountOptions}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'account');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters?.account || [] : []}
                placeholder="Select Account"
                label=""
                multiple={true}
                variant="base"
                withAddMenuButton={false}
                fetchItems={(searchText: string) => getAccountdata(searchText)}
                filter
                name="account"
                autoSelectValue={false}
                hasInputSpinnerCheck={loadingEligibleAccounts}
              />
            </div>
            {/* <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--xx-small">INVENTORY LOCATION</h1> */}
            {/* <Combobox
              id="Inventory Location"
              optionsAddItem={[]}
              events={{
                // onRequestOpen: (): void => {
                //   setOpenDropDownAccount(true);
                // },
                // onRequestClose: (): void => {
                //   setOpenDropDownAccount(false);
                // },
                onChange: handleChangeOnAccount,
                onRequestRemoveSelectedOption: handleRemoveSelectionAccount,
                onSelect: handleSelectItemAccount,
              }}
              // isOpen={openDropDownAccount}
              labels={{
                label: ``,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              options={FILTER_CYCLE_COUNT}
              multiple
              hasInputSpinner={userInfo?.businessunit === SPORTS_MED? loadingTerritory : loadingEligibleAccounts}
              selection={selectedFilters ? selectedFilters.inventoryLocation : [] || []}
              // menuItemVisibleLength={5}
              // menuPosition="relative"
              value={inputValueAccount}
              // variant="base"
            /> */}

            {/* <Combobox
              id="account"
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'inventoryLocation'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'inventoryLocation'),
              }}
              labels={{
                label: `Account`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              options={accountOptions || []}
              multiple
              placeholder="Select"
              options={inventoryLocation || []}
              selection={selectedFilters ? selectedFilters.inventoryLocation : [] || []}
              menuPosition="relative"
              variant="readonly"
            /> */}
          </div>
          {/* </div> */}
        </section>
      </Modal>
    </IconSettings>
  );
};

export default FilterCycleCount;
