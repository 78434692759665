/**
 * Redux file for getting/setting event filters
 * @module src/store/ducks/snackBar
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const OPEN_SNACK_BAR = 'OPEN_SNACK_BAR';
const CLOSE_SNACK_BAR = 'CLOSE_SNACK_BAR';

interface Notification {
  type: string;
  message: string;
}

export type SnackBarState = {
  notification: Notification;
  showSnack: boolean;
} | null;

export type OpenSnackBarActionType = {
  payload: SnackBarState;
  type: typeof OPEN_SNACK_BAR;
};

export type CloseSnackBarActionType = {
  type: typeof CLOSE_SNACK_BAR;
};

export const openSnackBar = (type: string, message: string): OpenSnackBarActionType => {
  return {
    payload: { notification: { type, message }, showSnack: true },
    type: OPEN_SNACK_BAR,
  };
};

export const closeSnackBar = (): CloseSnackBarActionType => {
  return {
    type: CLOSE_SNACK_BAR,
  };
};

/** snackbar reducer */
export default (state: SnackBarState = null, action: ActionType): SnackBarState => {
  switch (action.type) {
    case OPEN_SNACK_BAR: {
      return action.payload;
    }
    case CLOSE_SNACK_BAR: {
      return null;
    }
    default:
      return state;
  }
};

export const getSnackBar = (state: State): SnackBarState => {
  return state.snackBarState;
};
