/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-one-expression-per-line */
/**
 * Module for header on Surgical Detail Page
 * @module src/SurgicalDetail/SurgicalDetailHeader
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-console */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import Spinner from '@salesforce/design-system-react/components/spinner';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import Combobox from '@salesforce/design-system-react/components/combobox';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import CartComponent from '../CycleCountDetailTable/CartComponent';
import debounced from '../../../../util/debounced';
import { GET_PRODUCT_LIST } from '../../../../graphql/getProductList';
import CREATE_UPDATE_CYCLE_COUNT_LINE_ITEMS from '../../../../graphql/mutations/createUpdateCycleCountLineItems';
// import './index.scss';
import SnackBar from '../../../Shared/SnackBar';
import { MAX_QUANTITY, NOTIFY_TYPE } from '../../../../util/constants';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import RESUBMIT_ORDER_DETAILS from '../../../../graphql/mutations/resubmitOrderDetails';
import { GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS } from '../../../../graphql/getSubInventoryWithLotDetails';
import { GET_USER_TERRITORIES } from '../../../../graphql/getUserTerritories';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import { GET_FILTERED_PRODUCT_GROUPS } from '../../../../graphql/getFilteredProductGroups';
import { getHoldIcon } from '../../../../util/utilityFunctions';

interface Props extends RouteComponentProps {
  isOpen?: boolean;
  handleClose: () => void;
  refetch?: () => void;
  handleAddClick?: (list: any) => void;
  setShowSearchCatalogModal?: (check: boolean) => void;
  inventoryDetail?: any;
  history: any;
  isEventInventoryFlow?: boolean;
  isEdit?: boolean;
  type?: string;
  handleFilter: (filter: DynamicType | null) => void;
  selectedFilterPart?: DynamicType | null;
}

enum DetailTypes {
  ProductGroup = 'ProductGroup',
}
interface DynamicValue {
  [key: string]: any;
}

interface DynamicType {
  [key: string]: any;
}

const CycleCountAddItems: FC<Props> = ({
  isOpen,
  handleClose,
  inventoryDetail,
  isEdit = false,
  type,
  refetch,
  setShowSearchCatalogModal,
  handleAddClick,
  selectedFilterPart,
  handleFilter,
  // history,
  // isEventInventoryFlow,
}) => {
  const userInfo = useSelector(getUserInfo);
  const { open, notification, openSnackBar } = useSnackBar();
  const [isLoading, setLoader] = useState(false);
  const [catalogCount, setCatalogCount] = useState(0);
  const [subInvCount, setSubInvCount] = useState(0);
  const [productList, setProductList] = useState<any[]>([]);
  const [addedPartsLocal, setAddedPartsLocal] = useState<any>([]);
  const [subInventoryLotInfo, setSubInventoryLotInfo] = useState<any>([]);
  const [productListItems, { data: productItems, loading }] = useLazyQuery(GET_PRODUCT_LIST);
  const [userTerritories, setUserTerritories] = useState();
  const [
    getSubInventoryWithLotDetails,
    { data: subInventoryWithLotDetails, loading: loadingSubInventory },
  ] = useLazyQuery(GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS);
  const [reSubmitInventory] = useMutation(RESUBMIT_ORDER_DETAILS);
  const filterValues = selectedFilterPart?.filterValues;
  const [selectedFilters, setSelectedFilters] = useState<DynamicType | null>(filterValues || {});
  const [searchTerm, setSearchText] = useState('');
  const [selectedGrps, setSelectedGrps] = useState<any>(
    selectedFilters?.productGroup?.map((item: any) => item.id) || []
  );
  const [getUserTerritoriesItems, { data: userTerritoriesItems }] = useLazyQuery(
    GET_USER_TERRITORIES,
    {
      variables: {
        userId: userInfo?.id,
      },
    }
  );
  const [createUpdateCycleCountLineItems, { data: eventUsageMutation }] = useMutation(
    CREATE_UPDATE_CYCLE_COUNT_LINE_ITEMS
  );
  const getProductRecord = (product: any): any => {
    let returnVal = null;
    if (inventoryDetail && inventoryDetail.lineItems && inventoryDetail.lineItems.length) {
      returnVal = inventoryDetail.lineItems.find((rec: any) => rec.productId === product.productId);
    }
    return returnVal;
  };

  useEffect(() => {
    if (!isEdit) {
      if (productItems && productItems.getProductList && productItems.getProductList.length) {
        productItems.getProductList.forEach((list: any) => {
          const record = getProductRecord(list);
          /* eslint-disable no-param-reassign */
          const changedIndex = addedPartsLocal.find(
            (index: any) => index.productId === list.productId
          );
          list.counterValue = record ? Number(record.quantity) : 0;
          if (changedIndex) {
            list.counterValue = changedIndex.counterValue;
          }
          if (record) {
            list.usageExternalId = record.usageExternalId;
          }
          list.changed = false;
        });
        setProductList(productItems.getProductList);
      } else {
        setProductList([]);
      }
    } else {
      let counter = 0;
      const updateList = inventoryDetail.lineItems.map((record: any) => {
        counter += Number(record.quantity);
        return {
          ...record,
          changed: false,
          counterValue: Number(record.quantity),
        };
      });
      setProductList(updateList);
      setCatalogCount(counter);
    }
  }, [productItems]);

  const { data: filteredproductGroups, loading: loadingProductGroup } = useQuery(
    GET_FILTERED_PRODUCT_GROUPS
  );

  const optionsWithLabel = (value: any): object =>
    value &&
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === DetailTypes.ProductGroup) {
        return { ...elem, label: `${elem.value}` };
      }
    });
  const productGroup = optionsWithLabel(filteredproductGroups?.getProductGroups);

  const getProductLotRecord = useCallback(
    (product: any): any => {
      let returnVal = null;
      if (inventoryDetail && inventoryDetail.lineItems && inventoryDetail.lineItems.length) {
        returnVal = inventoryDetail.lineItems.find(
          (rec: any) => rec.lotNumber === product.lotNumber
        );
      }
      return returnVal;
    },
    [inventoryDetail]
  );

  useEffect(() => {
    getUserTerritoriesItems();
    if (userTerritoriesItems?.getUserTerritories) {
      const userTerritoriesItemsList = userTerritoriesItems?.getUserTerritories;
      if (userTerritoriesItemsList && userTerritoriesItemsList.length > 0) {
        setUserTerritories(userTerritoriesItemsList[0].value);
      }
    }
  }, [getUserTerritoriesItems, userTerritoriesItems]);

  useEffect(() => {
    // const output: any = ([] = [{ subInventoryName: userTerritories, lotInfo: [] }]);
    if (subInventoryWithLotDetails) {
      const searchedSubInventoryWithLot = subInventoryWithLotDetails?.getSubInventoryWithLotDetails;

      if (searchedSubInventoryWithLot.length) {
        const finalSubInvWithLotInfo: Array<any> = [];
        const productDescriptionLIst: Array<string> = [];
        searchedSubInventoryWithLot.forEach((record: any) => {
          /* eslint-disable no-param-reassign */
          const LotProduct = getProductLotRecord(record);
          record.counterValue = LotProduct ? Number(LotProduct.quantity) : 0;
          record.changed = false;
          record.maxPermissibleValue = Number(record.availableQuantity);
          if (productDescriptionLIst.indexOf(record.productDescription) >= 0) {
            const index = finalSubInvWithLotInfo.findIndex(
              (entry: any) => entry.name === record.productDescription
            );

            finalSubInvWithLotInfo[index].value.push(record);
          } else {
            finalSubInvWithLotInfo.push({
              name: record.productDescription,
              productNumber: record.productNumber,
              productOnHold: record.productOnHold,
              productOnHoldType: record.productOnHoldType,
              value: [record],
            });
            productDescriptionLIst.push(record.productDescription);
          }
        });
        setSubInventoryLotInfo(finalSubInvWithLotInfo);
      }
    }
  }, [getProductLotRecord, subInventoryWithLotDetails]);

  useEffect(() => {
    if (
      eventUsageMutation &&
      eventUsageMutation.createUpdateCycleCountLineItems &&
      eventUsageMutation.createUpdateCycleCountLineItems.message === 'success'
    ) {
      handleClose();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [eventUsageMutation]);

  const setEventPartQuantity = (searchedItem: string): void => {
    if (searchedItem.length > 2) {
      productListItems({
        variables: {
          searchText: searchedItem,
          caseExternalId: inventoryDetail?.caseExternalId,
          caseId: inventoryDetail.caseId,
          salesRepId: inventoryDetail?.salesRepDetails[0]?.salesRepId,
          filters: {
            productFamily: ['SPTSIMPLANTS'],
            productName: selectedGrps,
            businessUnit: [userInfo?.businessunit] || ['Sports Med'],
            userId: userInfo?.id,
          },
        },
      });
    } else {
      productListItems({
        variables: {
          searchText: '',
          caseExternalId: inventoryDetail?.caseExternalId,
          caseId: inventoryDetail.caseId,
          salesRepId: inventoryDetail?.salesRepDetails[0]?.salesRepId,
          filters: {
            productFamily: ['SPTSIMPLANTS'],
            productName: selectedGrps,
            businessUnit: [userInfo?.businessunit] || ['Sports Med'],
            userId: userInfo?.id,
          },
        },
      });
    }
  };

  const debouncedFetchSetItems = useCallback(debounced(500, setEventPartQuantity), [
    debounced,
    setEventPartQuantity,
  ]);

  // Get default Parts on load
  useEffect(() => {
    if (!isEdit) {
      productListItems({
        variables: {
          limit: 50,
          offset: 0,
          searchText: '',
          caseExternalId: inventoryDetail?.caseExternalId,
          caseId: inventoryDetail.caseId,
          salesRepId: inventoryDetail?.salesRepDetails[0]?.salesRepId,
          filters: {
            productFamily: ['SPTSIMPLANTS'],
            productName: selectedGrps,
            businessUnit: [userInfo?.businessunit] || ['Sports Med'],
            userId: userInfo?.id,
          },
        },
      });
    }
  }, [productListItems, isEdit]);

  const searchCatalog = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      setSearchText(searchedItem.value);
      const { value } = searchedItem;
      _event.persist();
      //   setInputValue(value);
      if (value && value.length >= 2) {
        debouncedFetchSetItems(value);
      }
    },
    [debouncedFetchSetItems]
  );

  const setSubInventoryQuantity = (searchedItem: string): void => {
    if (searchedItem.length > 2) {
      getSubInventoryWithLotDetails({
        variables: {
          subInventoryName: userTerritories,
          filters: {
            searchText: searchedItem,
          },
        },
      });
    } else {
      getSubInventoryWithLotDetails({
        variables: {
          subInventoryName: userTerritories,
          filters: {
            searchText: '',
          },
        },
      });
    }
  };

  const debouncedFetchSubInventoryItems = useCallback(debounced(500, setSubInventoryQuantity), [
    debounced,
    setSubInventoryQuantity,
  ]);

  const holdIconPath = getHoldIcon();
  // Get default Sub Inventories on load
  useEffect(() => {
    if (!isEdit) {
      getSubInventoryWithLotDetails({
        variables: {
          limit: 50,
          offset: 0,
          subInventoryName: userTerritories,
          filters: {
            searchText: '',
          },
        },
      });
    }
  }, [getSubInventoryWithLotDetails, isEdit, userTerritories]);

  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any): void => {
      // For selected filter value
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };

      setSelectedFilters(selectedFilterValues);
    },
    [selectedFilters]
  );

  const handleProductList = (selectedItem: any): void => {
    const selectedOptions = selectedItem?.map((item: any) => item.id);
    setSelectedGrps(selectedOptions);
    productListItems({
      variables: {
        caseExternalId: inventoryDetail?.caseExternalId,
        caseId: inventoryDetail.caseId,
        salesRepId: inventoryDetail?.salesRepDetails[0]?.salesRepId,
        filters: {
          productName: selectedOptions,
          businessUnit: [userInfo?.businessunit] || ['Sports Med'],
          userId: userInfo?.id,
        },
        searchText: searchTerm,
      },
    });
  };

  const handleSelect = useCallback(
    (data: any, name: string) => {
      const { selection } = data;
      setFilterValue(name, selection);
      handleProductList(selection);
    },
    [handleProductList, setFilterValue]
  );

  const handleCount = (event: any, data: { value: string; number: number }, product: any): void => {
    const updatedList = [...productList];
    let counter = catalogCount;
    let decremenmtFlag = false;
    updatedList.forEach((element: any, index: any) => {
      if (
        product.productId === element.productId &&
        product.usageExternalId === element.usageExternalId
      ) {
        /* eslint-disable no-param-reassign */
        decremenmtFlag = data.number < element.counterValue;
        element.counterValue = data.number < MAX_QUANTITY ? data.number : MAX_QUANTITY;
        element.changed = true;
        counter += element.changed ? 1 : 0;
        const addedIndex = addedPartsLocal.findIndex((e: any) => e.productId === element.productId);
        if (addedIndex > -1) {
          addedPartsLocal[addedIndex] = updatedList[index];
        } else {
          addedPartsLocal.push(updatedList[index]);
        }
      }
    });
    setProductList(updatedList);
    setAddedPartsLocal(addedPartsLocal);
    if (counter < catalogCount) {
      counter = counter === 1 && !decremenmtFlag ? catalogCount + 1 : counter;
    } else if (decremenmtFlag) {
      counter -= 2;
    }
    setCatalogCount(counter);
  };

  const handleCountalert = (
    event: any,
    data: { value: string; number: number },
    product: any
  ): void => {
    if (product?.productOnHold) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );
    }
    handleCount(event, data, product);
  };

  const onAddClick = (): void => {
    const payload: Array<any> = [];
    handleFilter(selectedFilters);
    addedPartsLocal.forEach((product: any): void => {
      if (product.changed && product.counterValue > 0) {
        payload.push({
          productId: product.productId,
          quantity: product.counterValue.toString(),
          availableQuantity: null,
          productNumber: product.productNumber,
          quantityDifference: null,
          productCost: null,
          costVariance: null,
          lotNumber: null,
        });
      }
    });

    const mutation = {
      cycleCountExternalId: inventoryDetail.cycleCountExternalId,
      cycleCountId: inventoryDetail.cycleCountId,
      formType: 'New',
      lineItems: payload,
    };
    createUpdateCycleCountLineItems({
      variables: mutation,
    }).catch((error: any) => {
      openSnackBar(NOTIFY_TYPE.ERROR, 'Error: Adding Line Items to Cycle count');
      /* eslint-disable no-console */
      console.log(error, ' - We are having issue updating the line items for Inventory Return');
    });
  };

  const dateCompare = (d1: any, d2: any): any => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);

    if (date1 > date2) {
      // console.log(`${d1} is greater than ${d2}`)
      return false;
    }
    // console.log(`${d2} is greater than ${d1}`)
    return true;
  };
  const handleDelete = (product: any): void => {
    // empty/clear quantity, it will delete ib Backend
    let counter = 0;
    const listItems = [...productList];
    listItems.forEach((i: any) => {
      if (i.productId === product.productId && i.usageExternalId === product.usageExternalId) {
        i.counterValue = 0;
        i.changed = true;
        i.deleted = true;
      }
      counter += i.counterValue;
    });
    setCatalogCount(counter);
    setProductList(listItems || []);
  };
  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      <div>
        <Modal
          isOpen={isOpen}
          dismissOnClickOutside={false}
          footer={[
            <Button
              label="Cancel"
              onClick={() => {
                handleClose();
                handleFilter(selectedFilters);
              }}
            />,
            <Button label={isEdit ? 'Update' : 'Add'} variant="brand" onClick={onAddClick} />,
          ]}
          onRequestClose={() => {
            handleClose();
            handleFilter(selectedFilters);
          }}
        >
          <div style={{ display: 'grid', padding: '0.75rem', gridTemplateColumns: '1fr 1fr' }}>
            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {isEdit ? 'Edit Parts' : 'Add Parts'}
            </div>
            <div style={{ textAlign: 'end' }}>
              <CartComponent count={catalogCount + subInvCount} color="black" textColor="white" />
            </div>
          </div>
          <div style={{ padding: '0rem 0.75rem 0rem 0.75rem' }}>
            {isLoading && <Spinner />}
            {!isEdit ? (
              <Tabs id="tabs-example-default" style={{ border: 'none' }}>
                {/* <TabsPanel label="My Sub Inventory">
                  <div>
                    <Input
                      assistiveText={{ spinner: 'Field data is loading' }}
                      iconRight={
                        <InputIcon
                          assistiveText={{
                            icon: 'Search',
                          }}
                          name="search"
                          category="utility"
                        />
                      }
                      onChange={(_event: any, searchItem: string): void =>
                        searchSubInventory(_event, searchItem)
                      }
                      hasSpinner={loadingSubInventory}
                      id="unique-id-4"
                      placeholder="Search My Sub Inventory"
                    /> 
                    <section className="slds-p-around_large-1">
                      {subInventoryLotInfo.map((subInv: any) => (
                        <div className="inventory-return-lot-info-name">
                          <div style={{ marginTop: '5px' }}>
                            {`${subInv.productNumber}-${
                              subInv.name ? subInv.name.toUpperCase() : '--'
                            }`}
                          </div>
                          {subInv.value &&
                            subInv.value.map((value: any) => (
                              <div className="inventory-return-lot-info">
                                {dateCompare(
                                  moment(value.expirationDate).format('MM/DD/YYYY'),
                                  moment().format('MM/DD/YYYY')
                                ) ? (
                                  <div style={{ color: 'red' }}>
                                    Lot # - {value.lotNumber || '--'} <br />
                                    Expiration Date:
                                    {value.expirationDate
                                      ? moment(value.expirationDate).format('MM/DD/YYYY')
                                      : '--'}
                                  </div>
                                ) : (
                                  <div>
                                    Lot # - {value.lotNumber || '--'} <br />
                                    Expiration Date:
                                    {value.expirationDate
                                      ? moment(value.expirationDate).format('MM/DD/YYYY')
                                      : '--'}
                                  </div>
                                )}
                                <Input
                                  id="counter-input-3"
                                  minValue={0}
                                  maxValue={value.maxPermissibleValue}
                                  onChange={(
                                    event: any,
                                    data: { value: string; number: number }
                                  ): void => {
                                    handleSubInvCount(event, data, value);
                                  }}
                                  value={value.counterValue}
                                  variant="counter"
                                />
                              </div>
                            ))}
                          <div />
                        </div>
                      ))}
                    </section>
                  </div>
                </TabsPanel>*/}
                <TabsPanel label="Catalog">
                  <div>
                    <div className="full-width_button-product" id="product-catalog">
                      <Combobox
                        id="product"
                        events={{
                          onSelect: (event: any, data: any): void =>
                            handleSelect(data, 'productGroup'),
                          onRequestRemoveSelectedOption: (event: any, data: any): void =>
                            handleSelect(data, 'productGroup'),
                        }}
                        labels={{
                          placeholderReadOnly: 'Select Product Group',
                        }}
                        multiple
                        placeholder="Select"
                        options={productGroup || []}
                        hasInputSpinner={loadingProductGroup}
                        variant="readonly"
                        selection={selectedFilters ? selectedFilters.productGroup : []}
                      />
                    </div>
                    {!isEdit && (
                      <div className="catalog-input">
                        <Input
                          assistiveText={{ spinner: 'Field data is loading' }}
                          iconRight={
                            <InputIcon
                              assistiveText={{
                                icon: 'Search',
                              }}
                              name="search"
                              category="utility"
                            />
                          }
                          onChange={(_event: any, searchItem: string): void =>
                            searchCatalog(_event, searchItem)
                          }
                          hasSpinner={loading}
                          id="unique-id-4"
                          placeholder="Search Catalog"
                        />
                      </div>
                    )}
                    <section className="slds-p-around_large-1">
                      {productList &&
                        productList.map((product: any) => (
                          <div
                            style={{
                              padding: '5px',
                              borderBottom: '1px solid #545857',
                              height: '3rem',
                              display: 'grid',
                              gridTemplateColumns: '4fr 1fr',
                            }}
                          >
                            <div style={{ marginTop: '5px' }}>
                              {`${
                                product.productNumber ? product.productNumber : product.productNo
                              } ${product.productdescription}`}
                              {product.productOnHold ? (
                                <>
                                  <img
                                    src={holdIconPath}
                                    className="logo-image"
                                    alt="company"
                                    style={{ marginLeft: '8px', marginRight: '8px' }}
                                  />
                                  On Hold
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                            <div>
                              <Input
                                id="counter-input-3"
                                styleInput={{ width: '110px' }}
                                minValue={0}
                                maxValue={MAX_QUANTITY}
                                disabled={
                                  !!(
                                    product &&
                                    product.lineVisualIndicator &&
                                    product.lineVisualIndicator.toLowerCase() === 'approved'
                                  )
                                }
                                onChange={(
                                  event: any,
                                  data: { value: string; number: number }
                                ): void => {
                                  handleCountalert(event, data, product);
                                }}
                                value={product.counterValue}
                                variant="counter"
                              />
                            </div>
                          </div>
                        ))}
                    </section>
                  </div>
                </TabsPanel>
              </Tabs>
            ) : (
              <section className="slds-p-around_large-1">
                {productList &&
                  productList.map((product: any) => {
                    return !product.deleted ? (
                      <div
                        style={{
                          padding: '5px',
                          borderBottom: '1px solid #545857',
                          height: '3rem',
                          display: 'grid',
                          gridTemplateColumns: '4fr 1.5fr 0.5fr',
                        }}
                      >
                        <div style={{ marginTop: '5px' }}>
                          {`${product.productNumber || product.productNo || ''} ${
                            product.productdescription
                          }`}
                          {product.productOnHold ? (
                            <img
                              src={holdIconPath}
                              className="logo-image"
                              alt="company"
                              style={{ marginLeft: '8px', marginRight: '8px' }}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        <div>
                          <Input
                            id="counter-input-3"
                            styleInput={{ width: '110px' }}
                            minValue={0}
                            maxValue={MAX_QUANTITY}
                            disabled={
                              !!(
                                product &&
                                product.lineVisualIndicator &&
                                product.lineVisualIndicator.toLowerCase() === 'approved'
                              )
                            }
                            onChange={(
                              event: any,
                              data: { value: string; number: number }
                            ): void => {
                              handleCountalert(event, data, product);
                            }}
                            value={product.counterValue}
                            variant="counter"
                          />
                        </div>
                        <div style={{ textAlign: 'end', paddingTop: '5px' }}>
                          <Button
                            iconCategory="utility"
                            iconName="delete"
                            iconSize="large"
                            iconVariant="bare"
                            variant="icon"
                            onClick={(): void => handleDelete(product)}
                          />
                        </div>
                      </div>
                    ) : null;
                  })}
              </section>
            )}
          </div>
        </Modal>
      </div>
    </IconSettings>
  );
};

/** Custom Header on Inventory Add Items Page **/
export default withRouter(CycleCountAddItems);
