/**
 * Module for Custom Edit event Cell.
 * @module src/Events/EventsList
 */
/* eslint-disable no-constant-condition */
import React, { FC, useCallback } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import { EVENT_STATUS } from '../../../util/constants';

interface WorkflowPermission {
  workflowPermission: {
    id: string;
  } | null;
}

interface EventId {
  eventId: string;
  eventType: string;
  eventSubType: string;
  externalId: string;
  eventStatus: string;
  id: string;
}

interface Options {
  label: string;
  value: string;
}

interface DropdownOption {
  label: string;
  value: string;
}

interface Props {
  handleEditEvent: (
    id: string,
    externalId: string,
    eventType: string,
    eventSubType: string
  ) => void;
  handleCancelEvent: (
    id: string,
    externalId: string,
    eventType: string,
    eventSubType: string,
    eventId: string
  ) => void;
  handleShowDropdownOptions: (eventStatus: string) => () => void;
  enableEditButton: WorkflowPermission;
  enableCancelButton: WorkflowPermission;
  item?: EventId;
}

const EventslistEventEditCell: FC<Props> = ({
  handleEditEvent,
  handleCancelEvent,
  handleShowDropdownOptions,
  enableEditButton,
  enableCancelButton,
  item = {
    eventId: '',
    eventType: '',
    eventSubType: '',
    externalId: '',
    id: '',
    eventStatus: '',
  },
}) => {
  const { externalId, id, eventType, eventSubType, eventStatus, eventId } = item;
  const handleDropdownSelect = useCallback(
    (selectedOption: DropdownOption): void => {
      if (selectedOption.value === 'edit') {
        handleEditEvent(id, externalId, eventType, eventSubType);
      } else if (selectedOption.value === 'cancel') {
        handleCancelEvent(id, externalId, eventType, eventSubType, eventId);
      }
    },
    [handleEditEvent, id, externalId, eventType, eventSubType, handleCancelEvent, eventId]
  );

  const eventEditOption = [{ label: 'Edit', value: 'edit' }];
  const eventOptions = [
    { label: 'Edit', value: 'edit' },
    { label: 'Cancel', value: 'cancel' },
  ];
  const eventCancelOption = [{ label: 'Cancel', value: 'cancel' }];
  const eventNoOptions = [{ label: 'No Permissions', value: 'noPermissions' }];

  const handleDropodownOptions = (): Options[] => {
    let options = [];
    if (enableEditButton && enableCancelButton) {
      options = eventOptions;
    } else if (enableEditButton) {
      options = eventEditOption;
    } else if (eventStatus !== EVENT_STATUS.CANCELLED) {
      options = eventCancelOption;
    } else {
      options = eventNoOptions;
    }
    return options;
  };

  return (
    <DataTableCell title="">
      {eventType !== 'Cycle Count' ? (
        <Dropdown
          align="right"
          assistiveText={{ icon: 'More Options' }}
          iconCategory="utility"
          iconName="down"
          iconSize="small"
          iconVariant="border-filled"
          onOpen={handleShowDropdownOptions(eventStatus)}
          id="page-header-dropdown-object-home-nav-right"
          options={handleDropodownOptions()}
          width="xx-small"
          onSelect={handleDropdownSelect}
        />
      ) : (
        <Dropdown
          align="right"
          assistiveText={{ icon: 'More Options' }}
          iconCategory="utility"
          iconName="down"
          iconSize="small"
          iconVariant="border-filled"
          id="page-header-dropdown-object-home-nav-right"
          width="xx-small"
          disabled
        />
      )}
    </DataTableCell>
  );
};

/** Custom Event edit cell */
export default EventslistEventEditCell;
