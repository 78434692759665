/**
 * Graphql mutation to delete event inventory
 * @module src/graphql/deleteEventInventory
 */
import gql from 'graphql-tag';

/** creating event */
const DELETE_INVENTORY_FAVORITE = gql`
  mutation deleteInventoryFavorite($nameOfFavorite: String) {
    deleteInventoryFavorite(nameOfFavorite: $nameOfFavorite) {
      _id
      nameOfFavorite
      user
      serviceLevel
      userDetails {
        sfId
        name
      }
      salesRep {
        id
        name
      }
      account {
        id
        name
      }
      shipTo {
        id
        name
      }
      lineItems {
        part {
          id
          name
        }
        lotNumber
        quantity
      }
    }
  }
`;

export default DELETE_INVENTORY_FAVORITE;
