/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_SAS_URI = gql`
  query getSasUri {
    getSasUri {
      sasUri
      account
      containerName
    }
  }
`;
