/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Combobox from '@salesforce/design-system-react/components/combobox';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
// import './index.scss';
import { GET_OPS_LIST } from '../../../../graphql/getOpsList';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import ASSIGN_INVENTORY_REQUEST from '../../../../graphql/mutations/assignInventoryRequest';
import { NOTIFY_TYPE } from '../../../../util/constants';

interface Assign {
  assignedUserName: string;
  assignedUserId: string;
  caseExternalId: string;
}

interface Props {
  children?: FC;
  item?: Assign;
  openSnackBar?: any;
  refetch: any;
}

interface OpsList {
  id: string;
  value: string;
}

interface OpsDropdownSelect {
  label: string;
  value: string;
  id: string;
}

const InventoryRequestsAssignDataCell: FC<Props> = ({
  item = {
    assignedUserName: null,
    caseExternalId: '',
    assignedUserId: null,
  },
  openSnackBar,
  refetch,
}) => {
  const { assignedUserId, caseExternalId, assignedUserName } = item;
  const [getOpsList, { data }] = useLazyQuery(GET_OPS_LIST);
  const [options, setOptions] = useState<OpsDropdownSelect[]>();
  const [selectedOps, setSelectedOps] = useState<OpsDropdownSelect[]>();
  const [assignToOps, { data: assignRespone }] = useMutation(ASSIGN_INVENTORY_REQUEST);
  const userInfo = useSelector(getUserInfo);
  useEffect(() => {
    getOpsList({
      variables: {
        personaName: userInfo?.personas,
      },
    });
  }, [getOpsList, userInfo]);

  useEffect(() => {
    if (assignedUserName && assignedUserId && userInfo?.personas !== '3PL Ops') {
      setSelectedOps([
        {
          label: assignedUserName,
          value: assignedUserName,
          id: assignedUserId,
        },
      ]);
    }
  }, [assignedUserName, assignedUserId, userInfo]);

  useEffect(() => {
    if (data && data.getOPSList) {
      const optionList = data.getOPSList.map((i: OpsList) => {
        return {
          label: i.value,
          value: i.value,
          id: i.id,
        };
      });
      setOptions(optionList);
      if (
        userInfo?.personas === '3PL Ops' &&
        assignedUserName !== null &&
        assignedUserId !== null
      ) {
        const assigned3PLOps = data.getOPSList.find((i: OpsList) => i.value === assignedUserName);
        if (assigned3PLOps) {
          setSelectedOps([
            {
              label: assignedUserName,
              value: assignedUserName,
              id: assignedUserId,
            },
          ]);
        }
      }
    }
  }, [data, assignedUserName, assignedUserId, userInfo]);

  useEffect(() => {
    if (assignRespone && assignRespone.assignInventoryRequest.message === NOTIFY_TYPE.SUCCESS) {
      openSnackBar(NOTIFY_TYPE.SUCCESS, 'Request Assigned Successfully');
      refetch();
    }
  }, [assignRespone, openSnackBar, refetch]);
  const handleSelectDropdownItem = (option: any): void => {
    const { selection } = option;
    setSelectedOps(selection);
    if (selection) {
      assignToOps({
        variables: {
          externalId: caseExternalId,
          // assignedUser: selection.length > 0 ? selection[0].id : '',
          assignedUser: selection[0].id,
        },
      });
    }
  };
  return (
    <DataTableCell title={assignedUserName || 'Select an option'}>
      <div>
        <Combobox
          id="combobox-readonly-single"
          menuPosition="overflowBoundaryElement"
          events={{
            onSelect: (_event: any, option: any): void => handleSelectDropdownItem(option),
          }}
          labels={{
            placeholder: 'Select',
          }}
          options={options || []}
          selection={selectedOps}
          variant="readonly"
        />
      </div>
    </DataTableCell>
  );
};

export default InventoryRequestsAssignDataCell;
