/*
 * Event Details GraphQL specification
 * @module src/graphql/workflowPermissions
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Event Details GraphQL API query */
export const GET_UPCOMING_EVENTS = gql`
  query getUpcomingEventsList(
    $salesRepId: String
    $accountId: String
    $currentEventId: String
    $currentEventExternalId: String
    $filters: UpcomingEventsFilters
  ) {
    getUpcomingEventsList(
      salesRepId: $salesRepId
      accountId: $accountId
      currentEventId: $currentEventId
      currentEventExternalId: $currentEventExternalId
      filters: $filters
    ) {
      id
      externalId
      eventType
      eventSubType
      eventId
      eventStatus
      eventStartDate
      eventEndDate
    }
  }
`;
