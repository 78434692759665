import gql from 'graphql-tag';

// interface Comment {
//   comment: string;
//   postTime: string;
//   commentType: string;
// }

// interface TransferInventoryLineItem {
//   lotNumber: string;
//   partNumber: string;
//   productId: string;
//   quantity: string;
// }

// eslint-disable-next-line import/prefer-default-export
export const POST_TRANSFER_INVENTORY_AFTER_SFID = gql`
  mutation transferInventoryAfterSfid(
    $caseSFID: String
    $parentEventId: String
    $parentEventExternalId: String
    $eventId: String
    $eventExternalId: String
    $accountId: String!
    $salesRepId: String!
    $receivingRepId: String!
    $fromSubInventoryCode: String
    $toSubInventoryCode: String
    $salesOrganization: String
    $type: String
    $comments: [Comment]
    $lineItems: [TransferInventoryLineItem]
    $attachments: [Attachment]
  ) {
    transferInventoryAfterSfid(
      caseSFID: $caseSFID
      parentEventId: $parentEventId
      parentEventExternalId: $parentEventExternalId
      eventId: $eventId
      eventExternalId: $eventExternalId
      accountId: $accountId
      salesRepId: $salesRepId
      receivingRepId: $receivingRepId
      fromSubInventoryCode: $fromSubInventoryCode
      toSubInventoryCode: $toSubInventoryCode
      salesOrganization: $salesOrganization
      type: $type
      comments: $comments
      lineItems: $lineItems
      attachments: $attachments
    ) {
      message
      externalId
      sfid
    }
  }
`;
