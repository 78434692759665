/*
 * Get User Branches GraphQL specification
 * @module src/graphql/getUserBranches
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_USER_BRANCHES = gql`
  query getUserBranches($region: String!, $division: String!, $userId: String!) {
    getUserBranches(region: $region, division: $division, userId: $userId) {
      id
      label: name
    }
  }
`;
