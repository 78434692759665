/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import EventStatusBadge from '../../../Shared/EventStatusBadge';

interface Item {
  attachmentType: string;
}

interface Props {
  children?: FC;
  item?: Item;
  columnName: string;
}

const CustomeDataCell: FC<Props> = (props: any) => {
  const { accountName, accountNumber, erpOrderNumber, orderType } = props.item;
  const { columnName } = props;
  const accountCellDetails = () => {
    if (accountName === null && accountNumber === null) {
      return '--';
    }
    if (accountNumber === null) {
      return accountName;
    }
    if (accountName === null) {
      return accountNumber;
    }
    return `${accountNumber} - ${accountName}`;
  };

  let statusValue;
  if (columnName === 'status') {
    if (
      (props.item?.recordType === 'Inventory Request' ||
        props.item?.recordType === 'Inventory Return') &&
      props.item?.status === 'Failed'
    ) {
      statusValue = 'Approved';
    } else if (
      props.item?.recordType === 'Inventory Request' &&
      props.item?.status === 'Approved' &&
      !props.item?.orderId &&
      props.item?.externalId
    ) {
      statusValue = 'Completed';
    } else {
      statusValue = props.item?.status;
    }
  }

  return (
    <>
      <DataTableCell {...props}>
        {columnName === 'status' ? <EventStatusBadge status={statusValue} /> : <></>}
        {columnName === 'erpOrderNumber' && (erpOrderNumber !== null ? erpOrderNumber : '--')}
        {columnName === 'orderType' && (orderType !== null ? orderType : '--')}
        {columnName === 'accountName' && accountCellDetails()}
      </DataTableCell>
    </>
  );
};

CustomeDataCell.displayName = DataTableCell.displayName;

export default CustomeDataCell;
