/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useState, useCallback, useEffect } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import Input from '@salesforce/design-system-react/components/input';
import { useSelector } from 'react-redux';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Combobox from '@salesforce/design-system-react/components/combobox';
import { getUserId } from '../../../../store/ducks/userId';

interface Props {
  handleToggleFilterCycleCount: () => void;
  filterModalVisible: boolean;
  handleFilterCycleCount: (filter: DynamicType | null) => void;
  resetFilters: () => void;
  selectedFilter?: DynamicType | null;
  type?: string;
  cycleCountDetails: any;
  // //   handleEditFilter: (editEvent: boolean) => void;
}
interface DynamicType {
  [key: string]: any;
}

interface DropdownSelect {
  id: string;
  label?: string;
  value: string;
}

const FilterCycleCount: FC<Props> = ({
  handleToggleFilterCycleCount,
  filterModalVisible,
  selectedFilter,
  handleFilterCycleCount,
  resetFilters,
  //   handleEditFilter,
  cycleCountDetails,
}) => {
  const filterValues = selectedFilter?.filterValues;
  const userId = useSelector(getUserId);
  // const dispatch = useDispatch();
  // // useState
  const [selectedFilters, setSelectedFilters] = useState<DynamicType | null>(filterValues || {});
  const [selectedSalesRep, setSelectedSalesRep] = useState<DropdownSelect[]>();
  const [salesRepOptions, setSalesRepOptions] = useState<DropdownSelect[]>();

  const optionsWithLabel = (arr: []): any[] =>
    arr &&
    arr.map((elem: any) => {
      return { ...elem, label: `${elem.salesRepName}` };
    });

  useEffect(() => {
    if (cycleCountDetails && cycleCountDetails.salesRepDetails) {
      setSalesRepOptions(optionsWithLabel(cycleCountDetails.salesRepDetails));
    }
  }, [cycleCountDetails]);

  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any): void => {
      // For selected filter value
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };

      setSelectedFilters(selectedFilterValues);
    },
    [selectedFilters]
  );

  const handleSelect = useCallback(
    (data: any, name: string) => {
      const { selection } = data;
      if (name === 'salesRepId') {
        setSelectedSalesRep(data.selection);
      }
      setFilterValue(name, selection);
    },
    [setFilterValue]
  );

  const handleChangeText = (event: any, name: any): void => {
    event.persist();
    const { value } = event.target;
    setFilterValue(name, value);
  };

  const reset = (): void => {
    setSelectedFilters(null);
    setSelectedSalesRep([]);
    resetFilters();
  };

  const handleApplyFilter = (): void => {
    handleToggleFilterCycleCount();
    handleFilterCycleCount(selectedFilters);
  };

  console.log('selectedFilters', selectedFilters, selectedSalesRep);

  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={filterModalVisible}
        onRequestClose={handleToggleFilterCycleCount}
        footer={[
          <Button
            className="slds-float_left slds-text-color_default"
            label="Cancel"
            key="Cancel"
            onClick={handleToggleFilterCycleCount}
          />,
          <Button label="Reset Filter" key="Reset" onClick={reset} />,
          <Button
            className="yellow-btn"
            label="Apply"
            onClick={handleApplyFilter}
            disabled={
              selectedFilters?.endDate &&
              selectedFilters?.startDate &&
              selectedFilters.endDate < selectedFilters.startDate
            }
            variant="brand"
            key="Apply"
          />,
        ]}
        heading={[
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div className="slds-float_left slds-text-title_bold filter_heading" key="filterName">
            Filters
          </div>,
        ]}
      >
        <section className="slds-p-around_medium slds-theme_shade">
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds--xx-small">Sales Reps</h1>
            <Combobox
              id="salesRepId"
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'salesRepId'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'salesRepId'),
              }}
              labels={{
                label: `Sales Rep`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              multiple
              placeholder="Select"
              options={salesRepOptions || []}
              selection={selectedFilters ? selectedFilters.salesRepId : []}
              variant="readonly"
            />
          </div>
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--xx-small">Cost Delta Range</h1>
            <Input
              id="base-id"
              label="Minimum Cost Delta"
              onChange={(event: any): void => handleChangeText(event, 'fromCost')}
              value={selectedFilters ? selectedFilters.fromCost : []}
              maxLength="50"
            />
            <Input
              id="base-id"
              label="Maximum Cost Delta"
              onChange={(event: any): void => handleChangeText(event, 'toCost')}
              value={selectedFilters ? selectedFilters.toCost : []}
              maxLength="50"
            />
          </div>
        </section>
      </Modal>
    </IconSettings>
  );
};

export default FilterCycleCount;
