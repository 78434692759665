/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Module for Cancelling evnet creation.
 * @module src/components/CreateEditSurgeryEvent/CancelEventCreationModal
 */
import React, { FC } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import './index.scss';

interface Props {
  showEditConfirmationModal: boolean;
  handleModalToggle: () => void;
  handlelSaveAsFavoriteModalConfirmPress: () => void;
  handleSaveAsName: (e: any) => void;
  favName: string;
  inventoryRequestFav: any;
}

const FavoriteSaveAsConfirmationModal: FC<Props> = ({
  handleSaveAsName,
  inventoryRequestFav,
  favName,
  showEditConfirmationModal,
  handleModalToggle,
  handlelSaveAsFavoriteModalConfirmPress,
}) => {
  const { nameOfFavorite } = inventoryRequestFav;

  const handleSaveAsName1 = (e: any): void => {
    handleSaveAsName(e.target.value);
  };

  return (
    <Modal
      className="save-as-fav-modal default-modal_header default-modal_footer"
      isOpen={showEditConfirmationModal}
      onRequestClose={handleModalToggle}
      ariaHideApp={false}
      footer={[
        <Button label="Cancel" onClick={handleModalToggle} key="no" />,
        <Button
          label="Save"
          variant="brand"
          onClick={handlelSaveAsFavoriteModalConfirmPress}
          key="yes"
        />,
      ]}
      heading={nameOfFavorite}
    >
      <div style={{ padding: '15px' }}>
        <Input
          aria-describedby="favorite_name"
          id="unique-id-4"
          label="Enter Favorite Name"
          placeholder="Favorite Name"
          value={favName}
          onChange={handleSaveAsName1}
        />
      </div>
    </Modal>
  );
};

/** Custom component for Cancelling evnet creation */
export default FavoriteSaveAsConfirmationModal;
