/**
 * Graphql mutation to create filter preset
 * @module src/graphql/createFilterPreset
 */
import gql from 'graphql-tag';

interface FilterData {
  id: string;
  label: string;
}

interface FilterPresets {
  status: [FilterData];
  salesReps: [FilterData];
  coveringReps: [FilterData];
  account: [FilterData];
  branch: [FilterData];
  franchise: [FilterData];
  surgeon: [FilterData];
  procedureDetail: [FilterData];
  startDate: string;
  endDate: string;
  usage: string;
  eventType: [string];
}

/** create filter preset */
const CREATE_FILTER_PRESET = gql`
  mutation createFilterPreset(
    $userId: String!
    $filterValues: FilterPresets
    $name: String!
    $type: String
  ) {
    createFilterPreset(userId: $userId, filterValues: $filterValues, name: $name, type: $type) {
      message
    }
  }
`;

export default CREATE_FILTER_PRESET;
