/**
 * Module for Custom Surgeon Cell.
 * @module src/Events/EventsList
 */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';

interface Surgeon {
  surgeonFirstName: string;
  surgeonLastName: string;
}
interface Props {
  item?: Surgeon;
}

const EventsListSurgeonCell: FC<Props> = ({
  item = {
    surgeonFirstName: '',
    surgeonLastName: '',
  },
}) => {
  const { surgeonFirstName, surgeonLastName } = item;

  return (
    <DataTableCell title={`${surgeonFirstName || ''} ${surgeonLastName || ''}`}>
      <div>
        {surgeonFirstName && surgeonLastName && (
          <p className="wrap-text">{`${surgeonFirstName} ${surgeonLastName}`}</p>
        )}
      </div>
    </DataTableCell>
  );
};

/** Custom Surgeon Cell */
export default EventsListSurgeonCell;
