/*
 * User Info GraphQL specification
 * @module src/graphql/getUserRepStockAccounts
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/** User Info GraphQL API query */
export const GET_USER_REP_STOCK_ACCOUNTS = gql`
  query getUserRepStockAccounts($salesRepId: String) {
    getUserRepStockAccounts(salesRepId: $salesRepId) {
      id
      value: name
      number
      branchId
      subInventoryCode
      territoryName
    }
  }
`;
