/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-unused-vars*/
import React, { FC, useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import { useSelector } from 'react-redux';
import CycleCountStatusCell from './CycleCountStatusCell';
import CycleCountCell from './CycleCountIdCell';
import CycleCountSalesRepCell from './CycleCountSalesRepCell';
import { getUserInfo } from '../../../store/ducks/userInfo';
import { NOTIFY_TYPE } from '../../../util/constants';
import CycleCountAccountNameCell from './CycleCountAccountNameCell';
import InventoryRequestsAssignDataCell from '../../Orders/OrderDataTable/InventoryAssignDataCell';
import ResetCell from '../../Orders/OrderDataTable/ResetCell';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import ASSIGN_INVENTORY_REQUEST from '../../../graphql/mutations/assignInventoryRequest';
import SnackBar from '../../Shared/SnackBar';

interface Props {
  cycleCounts: any[];
  sortCycleCountList: (list: any[]) => void;
  getCycleCounts?: (variables: any) => void;
  sortedcolumn: (property: any) => void;
  selectedFilterApiValues: any;
  type?: string;
  refetch?: any;
}

interface Sort {
  sortColumn: string;
  sortColumnDirection: {
    [key: string]: string;
  };
}

// CustomDataTableCell must have the same displayName as DataTableCell or it will be ignored.
CycleCountCell.displayName = DataTableCell.displayName;
CycleCountStatusCell.displayName = DataTableCell.displayName;
CycleCountSalesRepCell.displayName = DataTableCell.displayName;
CycleCountAccountNameCell.displayName = DataTableCell.displayName;

const CycleCountDataTable: FC<Props> = ({
  cycleCounts,
  sortedcolumn,
  getCycleCounts,
  selectedFilterApiValues,
  type,
  refetch,
}) => {
  const [sortObj, setSortObj] = useState<Sort>();
  const userInfo = useSelector(getUserInfo);
  const { open, notification, openSnackBar } = useSnackBar();
  const [assignToOps, { data: assignRespone }] = useMutation(ASSIGN_INVENTORY_REQUEST);
  const SPORTS_MED = '';
  const handleSort = (sortColumn: any): void => {
    const sortProperty = sortColumn.property;
    const { sortDirection } = sortColumn;
    const sort = {
      sortColumn: sortProperty,
      sortColumnDirection: {
        [sortProperty]: sortDirection,
      },
    };
    setSortObj(sort);
    sortedcolumn(sortColumn);
    if (getCycleCounts) {
      getCycleCounts({
        variables: {
          offset: 0,
          limit: 50,
          orderBy: sortProperty,
          orderSortType: sortDirection,
          type,
          filters: selectedFilterApiValues,
        },
      });
    }
  };

  useEffect(() => {
    if (assignRespone && assignRespone.assignInventoryRequest.message === NOTIFY_TYPE.SUCCESS) {
      openSnackBar(NOTIFY_TYPE.SUCCESS, 'Reset Assignment Successfully');
      refetch();
    }
  }, [assignRespone, openSnackBar, refetch]);
  const handleReset = (externalId: any): void => {
    assignToOps({
      variables: {
        externalId,
        assignedUser: '',
      },
    });
  };

  return (
    <>
      <DataTable
        assistiveText={{
          columnSort: 'sort this column',
          columnSortedAscending: 'asc',
          columnSortedDescending: 'desc',
        }}
        items={cycleCounts || []}
        id="DataTableExample-2"
        className="font-12"
        fixedLayout
        keyboardNavigation
        joined
        onSort={handleSort}
      >
        {type === 'REVIEW'
          ? [
              <DataTableColumn
                isSorted={sortObj?.sortColumn === 'submittedDate'}
                key="submittedDate"
                label="Submit Date"
                property="submittedDate"
                sortable
                sortDirection={sortObj?.sortColumnDirection.submittedDate}
                width="80px"
              />,
              <DataTableColumn
                key="caseId"
                label="Count"
                property="caseId"
                isSorted={sortObj?.sortColumn === 'caseId'}
                sortable
                sortDirection={sortObj?.sortColumnDirection.caseId}
                width="80px"
              >
                <CycleCountCell type={type} />
              </DataTableColumn>,
              userInfo?.businessunit !== SPORTS_MED && (
                <DataTableColumn
                  key="countType"
                  label="Type"
                  property="countType"
                  isSorted={sortObj?.sortColumn === 'countType'}
                  sortable
                  sortDirection={sortObj?.sortColumnDirection.countType}
                  width="85px"
                />
              ),
              <DataTableColumn
                isSorted={sortObj?.sortColumn === 'startDate'}
                key="startDate"
                label="Start Date"
                property="startDate"
                sortable
                sortDirection={sortObj?.sortColumnDirection.startDate}
                width="85px"
              />,
              <DataTableColumn
                isSorted={sortObj?.sortColumn === 'endDate'}
                key="endDate"
                label="End Date"
                property="endDate"
                sortable
                sortDirection={sortObj?.sortColumnDirection.endDate}
                width="90px"
              />,
              <DataTableColumn
                key="status"
                label="Status"
                property="status"
                width="85px"
                isSorted={sortObj?.sortColumn === 'status'}
                sortable
                sortDirection={sortObj?.sortColumnDirection.status}
              >
                <CycleCountStatusCell />
              </DataTableColumn>,
              <DataTableColumn
                key="salesRep"
                label={userInfo?.businessunit === SPORTS_MED ? 'Sales Reps' : 'Sales Rep'}
                property="salesRep"
                isSorted={sortObj?.sortColumn === 'salesRep'}
                sortable
                sortDirection={sortObj?.sortColumnDirection.salesRep}
                width="85px"
              >
                <CycleCountSalesRepCell />
              </DataTableColumn>,
              userInfo?.businessunit === SPORTS_MED && (
                <DataTableColumn
                  key="inventoryLocation"
                  label="Inventory Location"
                  property="inventoryLocation"
                  isSorted={sortObj?.sortColumn === 'inventoryLocation'}
                  sortable
                  sortDirection={sortObj?.sortColumnDirection.inventoryLocation}
                  width="120px"
                />
              ),
              userInfo?.businessunit === SPORTS_MED ? (
                <DataTableColumn
                  key="territory"
                  label="Territory"
                  property="territory"
                  isSorted={sortObj?.sortColumn === 'territory'}
                  sortable
                  sortDirection={sortObj?.sortColumnDirection.territory}
                  width="120px"
                />
              ) : (
                <DataTableColumn
                  key="territory"
                  label="Account"
                  property="territory"
                  isSorted={sortObj?.sortColumn === 'territory'}
                  sortable
                  width="120px"
                >
                  <CycleCountAccountNameCell />
                </DataTableColumn>
              ),
              userInfo?.businessunit === SPORTS_MED && (
                <DataTableColumn
                  key="countType"
                  label="Count Type"
                  property="countType"
                  isSorted={sortObj?.sortColumn === 'countType'}
                  sortable
                  sortDirection={sortObj?.sortColumnDirection.countType}
                  width="80px"
                />
              ),
              <DataTableColumn
                key="assigned"
                label="Assign To"
                property="assignedUserName"
                width="100px"
                isSorted={sortObj?.sortColumn === 'assignedUserName'}
                sortable
                sortDirection={sortObj?.sortColumnDirection.assignedUserName}
              >
                <InventoryRequestsAssignDataCell openSnackBar={openSnackBar} refetch={refetch} />
              </DataTableColumn>,
              <DataTableColumn key="assigned" label="" width="50px">
                <ResetCell handleReset={handleReset} />
              </DataTableColumn>,
            ]
          : [
              <DataTableColumn
                key="caseId"
                label="ID"
                property="caseId"
                isSorted={sortObj?.sortColumn === 'caseId'}
                sortable
                sortDirection={sortObj?.sortColumnDirection.caseId}
                width="100px"
              >
                <CycleCountCell />
              </DataTableColumn>,
              userInfo?.businessunit !== SPORTS_MED && (
                <DataTableColumn
                  key="countType"
                  label="Count Type"
                  property="countType"
                  isSorted={sortObj?.sortColumn === 'countType'}
                  sortable
                  sortDirection={sortObj?.sortColumnDirection.countType}
                  width="100px"
                />
              ),
              <DataTableColumn
                isSorted={sortObj?.sortColumn === 'startDate'}
                key="startDate"
                label="Start Date"
                property="startDate"
                sortable
                sortDirection={sortObj?.sortColumnDirection.startDate}
                width="120px"
              />,
              <DataTableColumn
                isSorted={sortObj?.sortColumn === 'endDate'}
                key="endDate"
                label="End Date"
                property="endDate"
                sortable
                sortDirection={sortObj?.sortColumnDirection.endDate}
                width="120px"
              />,
              <DataTableColumn
                key="status"
                label="Status"
                property="status"
                width="130px"
                isSorted={sortObj?.sortColumn === 'status'}
                sortable
                sortDirection={sortObj?.sortColumnDirection.status}
              >
                <CycleCountStatusCell />
              </DataTableColumn>,
              <DataTableColumn
                key="salesRep"
                label={userInfo?.businessunit === SPORTS_MED ? 'Sales Reps' : 'Sales Rep'}
                property="salesRep"
                isSorted={sortObj?.sortColumn === 'salesRep'}
                sortable
                sortDirection={sortObj?.sortColumnDirection.salesRep}
                width="150px"
              >
                <CycleCountSalesRepCell />
              </DataTableColumn>,
              userInfo?.businessunit === SPORTS_MED && (
                <DataTableColumn
                  key="inventoryLocation"
                  label="Inventory Location"
                  property="inventoryLocation"
                  isSorted={sortObj?.sortColumn === 'inventoryLocation'}
                  sortable
                  sortDirection={sortObj?.sortColumnDirection.inventoryLocation}
                  width="150px"
                />
              ),
              userInfo?.businessunit === SPORTS_MED ? (
                <DataTableColumn
                  key="territory"
                  label="Territory"
                  property="territory"
                  isSorted={sortObj?.sortColumn === 'territory'}
                  sortable
                  sortDirection={sortObj?.sortColumnDirection.territory}
                  width="180px"
                />
              ) : (
                <DataTableColumn
                  key="territory"
                  label="Account"
                  property="territory"
                  isSorted={sortObj?.sortColumn === 'territory'}
                  sortable
                  width="200px"
                >
                  <CycleCountAccountNameCell />
                </DataTableColumn>
              ),
              userInfo?.businessunit === SPORTS_MED && (
                <DataTableColumn
                  key="countType"
                  label="Count Type"
                  property="countType"
                  isSorted={sortObj?.sortColumn === 'countType'}
                  sortable
                  sortDirection={sortObj?.sortColumnDirection.countType}
                  width="100px"
                />
              ),
              <DataTableColumn
                key="inventoryHold"
                label="Inventory Hold"
                property="inventoryHold"
                isSorted={sortObj?.sortColumn === 'inventoryHold'}
                sortable
                sortDirection={sortObj?.sortColumnDirection.inventoryHold}
                width="130px"
              />,
            ]}
      </DataTable>
      <SnackBar open={open} notification={notification} />
    </>
  );
};

export default CycleCountDataTable;
