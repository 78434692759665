import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Button from '@salesforce/design-system-react/components/button';
import { BrowserRouter as Router, RouteComponentProps, withRouter } from 'react-router-dom';

interface EventId {
  caseExternalId: string;
  eventId: string;
  caseNumber: string;
  orderId: string;
  integrationStatus: string;
  status?: string;
}

// interface for props that the data table cell is getting default from data table.
export interface Props extends RouteComponentProps {
  children?: FC;
  item?: EventId;
  type?: string;
}

const OrderRequestIdCell: FC<Props> = ({
  item = {
    caseExternalId: null,
    eventId: null,
    caseNumber: null,
    orderId: null,
    integrationStatus: null,
    status: null,
  },
  history,
  type = null,
}) => {
  const { eventId, caseNumber, caseExternalId, orderId, integrationStatus, status } = item;
  const goToOrderDetails = (): void => {
    // if (type !== 'Review') {
    if (type && orderId && (type !== 'Review' || integrationStatus === 'Failed')) {
      history.push('/OrdersUsageDetails', { orderId, type, status });
    } else {
      history.push('/OrdersUsageDetails', { externalId: caseExternalId, type });
    }
    // }
  };

  return (
    <Router>
      <DataTableCell title={eventId || ''}>
        <Button label={eventId || caseNumber || 'TBD'} variant="base" onClick={goToOrderDetails} />
      </DataTableCell>
    </Router>
  );
};

export default withRouter(OrderRequestIdCell);
