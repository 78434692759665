/**
 * Redux file for getting/setting cycle count filters
 * @module src/store/ducks/cycleCountFilter
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_ALL_CYCLECOUNT_FILTERS = 'SET_ALL_CYCLECOUNT_FILTERS';
const CLEAR_ALL_CYCLECOUNT_FILTERS = 'CLEAR_ALL_CYCLECOUNT_FILTERS';
const SET_REVIEW_CYCLECOUNT_FILTERS = 'SET_REVIEW_CYCLECOUNT_FILTERS';
const CLEAR_REVIEW_CYCLECOUNT_FILTERS = 'CLEAR_REVIEW_CYCLECOUNT_FILTERS';

interface SetAllCycleCountFiltersActionType {
  payload: string | null;
  type: typeof SET_ALL_CYCLECOUNT_FILTERS;
}

interface ClearAllCycleCountFiltersActionType {
  type: typeof CLEAR_ALL_CYCLECOUNT_FILTERS;
}

interface SetReviewCycleCountFiltersActionType {
  payload: string | null;
  type: typeof SET_REVIEW_CYCLECOUNT_FILTERS;
}

interface ClearReviewCycleCountFiltersActionType {
  type: typeof CLEAR_REVIEW_CYCLECOUNT_FILTERS;
}

/** cycleCountFilters action type */

export type CycleCountFiltersActionType =
  | SetAllCycleCountFiltersActionType
  | ClearAllCycleCountFiltersActionType
  | SetReviewCycleCountFiltersActionType
  | ClearReviewCycleCountFiltersActionType;

/** set cycleCountFilters action creator */

export const setAllCycleCountFilters = (
  state: string | null
): SetAllCycleCountFiltersActionType => {
  return {
    payload: state,
    type: SET_ALL_CYCLECOUNT_FILTERS,
  };
};

/** clear cycleCountFilters action creator */

export const clearAllCycleCountFilters = (): ClearAllCycleCountFiltersActionType => {
  return {
    type: CLEAR_ALL_CYCLECOUNT_FILTERS,
  };
};

export const setReviewCycleCountFilters = (
  state: string | null
): SetReviewCycleCountFiltersActionType => {
  return {
    payload: state,
    type: SET_REVIEW_CYCLECOUNT_FILTERS,
  };
};

/** clear cycleCountFilters action creator */

export const clearReviewCycleCountFilters = (): ClearReviewCycleCountFiltersActionType => {
  return {
    type: CLEAR_REVIEW_CYCLECOUNT_FILTERS,
  };
};

/** cycleCountFilters state type */

export type CycleCountFiltersState = string | null;

/** cycleCountFilters reducer */

export default (
  state: CycleCountFiltersState = null,
  action: ActionType
): CycleCountFiltersState => {
  switch (action.type) {
    case SET_ALL_CYCLECOUNT_FILTERS: {
      return action.payload;
    }
    case CLEAR_ALL_CYCLECOUNT_FILTERS: {
      return null;
    }
    default:
      return state;
  }
};

export const reviewCycleCountFilterReducer = (
  state: CycleCountFiltersState = null,
  action: ActionType
): CycleCountFiltersState => {
  switch (action.type) {
    case SET_REVIEW_CYCLECOUNT_FILTERS: {
      return action.payload;
    }
    case CLEAR_REVIEW_CYCLECOUNT_FILTERS: {
      return null;
    }
    default:
      return state;
  }
};

/** selector to get cycleCountFilters */

export const getAllCycleCountFilters = (state: State): CycleCountFiltersState => {
  return state.allCycleCountFilters;
};

export const getReviewCycleCountFilters = (state: State): CycleCountFiltersState => {
  return state.reviewCycleCountFilters;
};
