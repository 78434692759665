/*
 * Event Details GraphQL specification
 * @module src/graphql/workflowPermissions
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  List View Configurations Query */
export const GET_LIST_VIEW_CONFIGURATIONS = gql`
  query getListViewConfigurations {
    getListViewConfigurations {
      eventlist {
        Id
        screenName
        label
        key
        property
        isShow
        isRequired
        isEditable
        description
        businessunitName
      }
      orderlist {
        Id
        screenName
        label
        key
        property
        isShow
        isRequired
        isEditable
        description
        businessunitName
      }
    }
  }
`;
