/* eslint-disable no-nested-ternary */
/**
 * App component file.
 * @module src/components/App
 */
/* eslint-disable no-console */
import React, { FC, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import Button from '@salesforce/design-system-react/components/button';
import env from '../../util/env';
import { GET_USER_INFO } from '../../graphql/getUserInfoData';
import { setAccessToken, getAccessToken, clearAccessToken } from '../../store/ducks/accessToken';
import { setRefreshToken, clearRefreshToken } from '../../store/ducks/refreshToken';
import { setUserId, getUserId, clearUserId } from '../../store/ducks/userId';
import { setUserInfo, clearUserInfo } from '../../store/ducks/userInfo';
import { clearEventFilters } from '../../store/ducks/eventFilters';
import { clearInventoryFilters } from '../../store/ducks/inventoryFilters';
import { clearInventoryRequestFilters } from '../../store/ducks/inventoryRequestFilters';
import { clearInventoryReturnsFilters } from '../../store/ducks/inventoryReturnsFilters';
import { clearInventoryTransferFilters } from '../../store/ducks/inventoryTransferFilters';
import { clearOrderFilters } from '../../store/ducks/orderFilters';
import { clearNotificationFilters } from '../../store/ducks/notificationFilters';
import exchangeTokens from '../../util/exchangeTokens';
import EventsTab from '../Events/EventsTab';
import { GET_WORKFLOW_PERMISSION } from '../../graphql/workflowPermissions';

const { grant_token: grantToken } = queryString.parse(window.location.search);
if (grantToken !== undefined && grantToken !== null && !Array.isArray(grantToken)) {
  window.history.replaceState({}, document.title, '/');
}

const Login: FC = () => {
  const dispatch = useDispatch();
  const accessTokenPayload = useSelector(getAccessToken);
  const [getUserInfo, { data }] = useLazyQuery(GET_USER_INFO);

  useEffect(() => {
    const execute = async (): Promise<void> => {
      if (grantToken !== undefined && grantToken !== null && !Array.isArray(grantToken)) {
        try {
          const { accessToken, refreshToken, id } = await exchangeTokens(grantToken);
          getUserInfo({ variables: { id } });
          dispatch(setAccessToken(accessToken));
          dispatch(setRefreshToken(refreshToken));
          dispatch(setUserId(id));
          getUserInfo({ variables: { id } });
          dispatch(setUserInfo(data ? data.getUserInfo[0] : {}));
        } catch (err) {
          // do nothing
        }
      }
    };
    execute();
  }, [dispatch, data, getUserInfo]);

  const { data: showSurgeonData } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      action: 'view_surgeon_cases',
    },
  });

  const id = useSelector(getUserId);

  const userInfo = (data && data.getUserInfo[0]) || id;

  const handleLogoutClick = useCallback((): void => {
    dispatch(clearAccessToken());
    dispatch(clearRefreshToken());
    dispatch(clearUserId());
    dispatch(clearUserInfo());
    dispatch(clearEventFilters());
    dispatch(clearInventoryFilters());
    dispatch(clearInventoryRequestFilters());
    dispatch(clearInventoryReturnsFilters());
    dispatch(clearInventoryTransferFilters());
    dispatch(clearOrderFilters());
    dispatch(clearNotificationFilters());
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout`;
  }, [dispatch]);

  // redirecting to auth redirect end point
  const handleLoginClick = useCallback((): void => {
    if (window?.location?.hostname === 'localhost') {
      window.open(`${env.REACT_APP_AUTH_URL}/azure?state=local`, '_self');
    } else {
      window.open(`${env.REACT_APP_AUTH_URL}/azure?state=web`, '_self');
    }
  }, []);
  return (
    <div>
      <div>
        {userInfo && accessTokenPayload ? (
          <EventsTab />
        ) : accessTokenPayload ? (
          <div>
            <p>In order to login to Event Inventory Management you need a Salesforce account.</p>
            <p>
              <Button onClick={handleLogoutClick}>Logout</Button>
            </p>
          </div>
        ) : (
          <div>{!showSurgeonData && <Button onClick={handleLoginClick}>Login</Button>}</div>
        )}
      </div>
    </div>
  );
};

/** App component */
export default Login;
