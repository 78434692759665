/**
 * Graphql mutation to cancel event
 * @module src/graphql/cancelEvent
 */
import gql from 'graphql-tag';

/** creating event */
const CANCEL_CASE_USAGE = gql`
  mutation cancelCaseUsages($externalId: String!, $salesRepId: String!) {
    cancelCaseUsages(externalId: $externalId, salesRepId: $salesRepId) {
      message
    }
  }
`;

export default CANCEL_CASE_USAGE;
