import gql from 'graphql-tag';

const UPDATE_INVENTORY_SUBMIT_PERMISSION = gql`
  mutation updateInventorySubmitPermission(
    $userId: String!
    $isInventoryHold: Boolean!
    $reasonCodeForHold: String!
    $reason: String
    $permissionExternalID: String
  ) {
    updateInventorySubmitPermission(
      userId: $userId
      isInventoryHold: $isInventoryHold
      reasonCodeForHold: $reasonCodeForHold
      reason: $reason
      permissionExternalID: $permissionExternalID
    ) {
      message
    }
  }
`;

export default UPDATE_INVENTORY_SUBMIT_PERMISSION;
