import React, { FC, useState } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import Alert from '@salesforce/design-system-react/components/alert';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import Spinner from '@salesforce/design-system-react/components/spinner';
import './index.scss';

interface Privacy {
  id: string;
  rightToBeForgotten: boolean;
  dataAccess: boolean;
}

interface Props {
  privacyModalVisible: boolean;
  handlePrivacyRequestModal: () => void;
  handlePrivacyRequest: (dataPrivacy: boolean, forgottenRequest: boolean) => void;
  updateRequestLoading: boolean;
}

const PrivacyRequest: FC<Props> = ({
  privacyModalVisible,
  handlePrivacyRequestModal,
  handlePrivacyRequest,
  updateRequestLoading,
}) => {
  const [dataPrivacy, setDataPrivacy] = useState(false);
  const [forgottenRequest, setForgottenRequest] = useState(false);

  const handleDataPrivacy = (): void => {
    setDataPrivacy(!dataPrivacy);
  };

  const handleForgottenRequest = (): void => {
    setForgottenRequest(!forgottenRequest);
  };

  const handleSubmitRequest = (): void => {
    handlePrivacyRequest(dataPrivacy, forgottenRequest);
  };

  return (
    <IconSettings iconPath="/icons">
      <div>
        <Modal
          className="privacy-request_modal default-modal_header default-modal_footer"
          isOpen={privacyModalVisible}
          dismissOnClickOutside={false}
          ariaHideApp={false}
          footer={[
            updateRequestLoading && (
              <div key="loader">
                <Spinner size="small" variant="base" assistiveText={{ label: 'Loading...' }} />
              </div>
            ),
            <Button label="Cancel" onClick={handlePrivacyRequestModal} />,
            <Button
              disabled={!dataPrivacy && !forgottenRequest}
              label="Submit"
              variant="brand"
              onClick={handleSubmitRequest}
            />,
          ]}
          onRequestClose={handlePrivacyRequestModal}
          heading="Privacy Request"
        >
          <div className="slds-text-align--left">
            <div>
              <h1 className="slds-p-around--medium ">
                Please indicate your privacy request preferences for your account.
              </h1>
            </div>
            <div className="language-selection-list">
              <div>
                <Checkbox
                  assistiveText={{
                    label: 'Default',
                  }}
                  id="checkbox-example"
                  labels={{
                    label: 'Data Access Request',
                  }}
                  checked={dataPrivacy}
                  onChange={handleDataPrivacy}
                />
                <p>Request my data as it relates to this customer portal and events.</p>
              </div>
              <div>
                <Checkbox
                  assistiveText={{
                    label: 'Default',
                  }}
                  id="checkbox-example1"
                  labels={{
                    label: 'Right To Be Forgotten Request',
                  }}
                  checked={forgottenRequest}
                  onChange={handleForgottenRequest}
                />
                <p>Delete my personal information including name and email address.</p>
              </div>
              <div className={forgottenRequest ? 'hidden_alert alert_request' : 'alert_request'}>
                {forgottenRequest && (
                  <Alert
                    labels={{
                      heading:
                        'This request will cancel your account and remove access to this customer portal.',
                    }}
                    variant="warning"
                  />
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </IconSettings>
  );
};

/** Privacy Request component */
export default PrivacyRequest;
