/*
 * @module src/graphql/getApplicableShipMethods
 */

/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_SHIP_METHODS = gql`
  query getShipMethods {
    getShipMethods {
      id
      code
      name
      description
      label
    }
  }
`;
