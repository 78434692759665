/* eslint-disable @typescript-eslint/camelcase */
/**
 * Redux file for getting/setting auth token
 * @module src/store/ducks/holdBanner
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_HOLD_Banner = 'SET_HOLD_Banner';
const CLEAR_HOLD_Banner = 'CLEAR_HOLD_Banner';

interface SetHoldBannerActionType {
  payload: boolean;
  type: typeof SET_HOLD_Banner;
}

interface ClearHoldBannerActionType {
  type: typeof CLEAR_HOLD_Banner;
}

/** accessToken action type */

export type HoldBannerActionType = SetHoldBannerActionType | ClearHoldBannerActionType;

/** set accessToken action creator */

export const setHoldBannerStatus = (state: boolean): HoldBannerActionType => {
  return {
    payload: state,
    type: SET_HOLD_Banner,
  };
};

/** clear accessToken action creator */

export const clearHoldBannerStatus = (): ClearHoldBannerActionType => {
  return {
    type: CLEAR_HOLD_Banner,
  };
};

/** accessToken state type */

export type HoldBannerState = boolean;

/** accessToken reducer */

export default (state: HoldBannerState = false, action: ActionType): HoldBannerState => {
  switch (action.type) {
    case SET_HOLD_Banner: {
      return action.payload;
    }
    case CLEAR_HOLD_Banner: {
      return false;
    }
    default:
      return state;
  }
};

/** selector to get accessToken */

export const getHoldBannerStatus = (state: State): HoldBannerState => {
  return state.holdBannerStatus;
};
