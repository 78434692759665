/*
 * Event Details GraphQL specification
 * @module src/graphql/getInventoryReturnList
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Inventory Request list GraphQL API query */
export const GET_INVENTORY_RETURN_QUEUE_LIST = gql`
  query getReviewInventoryReturnList(
    $limit: Int
    $offset: Int
    $type: String
    $filters: ReviewInventoryReturnFilters
  ) {
    getReviewInventoryReturnList(limit: $limit, offset: $offset, type: $type, filters: $filters) {
      salesRepId
      assignedOPSId
      status
      type
      returnType
      createdDate
      orderNumber
      fromSubInventoryCode
      toSubInventoryCode
      salesOrganization
      accountId
      accountNumber
      accountName
      id
      externalId
      salesRepName
      assignedOPSName
      visualIndicator
      shipmentDate
      trackingNumber
      returnReason
      submittedDate
      territory
    }
  }
`;
