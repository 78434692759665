/*
 * Get Procedure Groups GraphQL specification
 * @module src/graphql/getProcedureTypes
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_PRODUCT_GROUP = gql`
  query getProductGroups {
    getProductGroups {
      id
      value
    }
  }
`;
