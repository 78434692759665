import React, { FC, useEffect, useState } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Button from '@salesforce/design-system-react/components/button';
import { BrowserRouter as Router, RouteComponentProps, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { OPS_ACTION_TYPE } from '../../../../util/constants';
import { getUserInfo } from '../../../../store/ducks/userInfo';

interface EventId {
  caseExternalId: string;
  eventId: string;
  type: string;
  assignedUserId?: string;
  assignedUserName?: string;
  diplayOrderType?: string;
  visualIndicator?: string;
  orderId?: string;
}

// interface for props that the data table cell is getting default from data table.
export interface Props extends RouteComponentProps {
  children?: FC;
  item?: EventId;
  action?: string;
}

const InventoryRequestIdCell: FC<Props> = ({
  item = {
    caseExternalId: null,
    eventId: null,
    type: '',
    assignedUserId: null,
    assignedUserName: null,
    diplayOrderType: '',
    visualIndicator: '',
    orderId: null,
  },
  history,
}) => {
  const {
    eventId,
    caseExternalId,
    assignedUserId,
    visualIndicator,
    orderId,
    diplayOrderType,
  } = item;
  const userInfo = useSelector(getUserInfo);
  const [currentOps, setAction] = useState(OPS_ACTION_TYPE.ASSIGN);

  useEffect(() => {
    if (assignedUserId) {
      if (assignedUserId === userInfo?.id) setAction(OPS_ACTION_TYPE.REVIEW);
    }
  }, [assignedUserId, userInfo]);

  const goToInventoryDetails = (): void => {
    if (
      item.type === 'Pickup Return' ||
      item.type === 'Inventory Return' ||
      item.type === 'Standard Return'
    ) {
      history.push('/inventoryReturnApproval', {
        externalId: caseExternalId,
        opsAction: currentOps,
      });
    }

    if (
      item.diplayOrderType === 'Pickup Return' ||
      item.diplayOrderType === 'Inventory Return' ||
      item.diplayOrderType === 'Standard Return'
    ) {
      history.push('/inventoryReturnDetails', {
        externalId: caseExternalId,
        orderType: diplayOrderType,
        type: 'Failed',
        orderId,
        opsAction: currentOps,
      });
    }

    if (item.type === 'Inventory Request') {
      history.push('/InventoryQueueRequestDetails', {
        caseExternalId,
        opsAction: currentOps,
      });
    }

    if (item.diplayOrderType === 'Inventory Request') {
      history.push('/inventoryRequestDetails', {
        externalId: orderId,
        type: 'Failed',
        opsAction: currentOps,
      });
    }

    if (
      item.diplayOrderType === 'Transfer' ||
      item.diplayOrderType === 'Rep to Rep' ||
      item.diplayOrderType === 'Rep to Customer' ||
      item.diplayOrderType === 'Customer to Rep'
    ) {
      history.push('/inventoryTransferDetails', {
        externalId: caseExternalId,
        transferType: 'Failed',
        visualIndicator,
        status: visualIndicator,
        opsAction: currentOps,
      });
    }
  };
  return (
    <Router>
      <DataTableCell title={eventId || 'TBD'}>
        <Button label={eventId || 'TBD'} variant="base" onClick={goToInventoryDetails} />
      </DataTableCell>
    </Router>
  );
};

export default withRouter(InventoryRequestIdCell);
