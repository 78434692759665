/**
 * Redux file for getting/setting auth token
 * @module src/store/ducks/refreshToken
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
const CLEAR_REFRESH_TOKEN = 'CLEAR_REFRESH_TOKEN';

interface SetRefreshTokenActionType {
  payload: string;
  type: typeof SET_REFRESH_TOKEN;
}

interface ClearRefreshTokenActionType {
  type: typeof CLEAR_REFRESH_TOKEN;
}

/** refreshToken action type */
export type RefreshTokenActionType = SetRefreshTokenActionType | ClearRefreshTokenActionType;

/** set refreshToken action creator */
export const setRefreshToken = (state: string): RefreshTokenActionType => {
  return {
    payload: state,
    type: SET_REFRESH_TOKEN,
  };
};

/** clear refreshToken action creator */
export const clearRefreshToken = (): ClearRefreshTokenActionType => {
  return {
    type: CLEAR_REFRESH_TOKEN,
  };
};

/** refreshToken state type */
export type RefreshTokenState = string | null;

/** refreshToken reducer */
export default (state: RefreshTokenState = null, action: ActionType): RefreshTokenState => {
  switch (action.type) {
    case SET_REFRESH_TOKEN:
      return action.payload;
    case CLEAR_REFRESH_TOKEN:
      return null;
    default:
      return state;
  }
};

/** selector to get refreshToken */
export const getRefreshToken = (state: State): RefreshTokenState => state.refreshToken;
