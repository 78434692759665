/**
 * Graphql mutation to cancel event
 * @module src/graphql/cancelEvent
 */
import gql from 'graphql-tag';

/** creating event */
const CANCEL_EVENT = gql`
  mutation cancelEvent($sfid: String, $externalId: String, $comments: String, $reason: String) {
    cancelEvent(sfid: $sfid, externalId: $externalId, comments: $comments, reason: $reason) {
      message
    }
  }
`;

export default CANCEL_EVENT;
