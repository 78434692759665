/**
 * Module for Custom Event Id Cell.
 * @module src/Events/EventsList
 */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Button from '@salesforce/design-system-react/components/button';
import { BrowserRouter as Router, RouteComponentProps, withRouter } from 'react-router-dom';

interface EventId {
  eventId: string;
  eventType: string;
  externalId: string;
  id: string;
  cycleCountExternalId?: string;
}

// interface for props that the data table cell is getting default from data table.
export interface Props extends RouteComponentProps {
  children?: FC;
  item?: EventId;
}

const EventsListEventIdCell: FC<Props> = ({
  item = {
    eventId: null,
    eventType: null,
    externalId: null,
    id: null,
    cycleCountExternalId: null,
  },
  history,
}) => {
  const { eventId, eventType, externalId, id, cycleCountExternalId } = item;

  const goToSurgicalDetail = (): void => {
    if (eventType === 'Cycle Count') {
      history.push('/cycleCountDetails', {
        cycleCountExternalId,
      });
    } else {
      history.push('/surgicalDetails', {
        eventType,
        externalId,
        id,
      });
    }
  };

  return (
    <Router>
      <DataTableCell title={eventId || 'TBD'}>
        {/* {eventType !== 'Cycle Count' && ( */}
        <Button label={eventId || 'TBD'} variant="base" onClick={goToSurgicalDetail} />
        {/* )} */}
        {/* {eventType === 'Cycle Count' && <p>{eventId}</p>} */}
      </DataTableCell>
    </Router>
  );
};

/** Custom EventId cell */
export default withRouter(EventsListEventIdCell);
