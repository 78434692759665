/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/**
 * Module for Custom Rep/Covering Cell.
 * @module src/Events/EventsList
 */
/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import Input from '@salesforce/design-system-react/components/input';
import Spinner from '@salesforce/design-system-react/components/spinner';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import CREATE_UPDATE_CYCLE_COUNT_LINE_ITEMS from '../../../../graphql/mutations/createUpdateCycleCountLineItems';
import debounced from '../../../../util/debounced';
import { MAX_QUANTITY } from '../../../../util/constants';
import { getUserInfo } from '../../../../store/ducks/userInfo';

interface Quantity {
  quantity: string;
  availablequantity: string;
  costVariance: string;
  cycleCountId: string;
  cycleCountExternalId: string;
  createdBy: string;
  createdByDetails: any;
  id: string;
  isOpen: boolean;
  needToDisplay: boolean;
}
interface Props {
  item?: Quantity;
  handleRefetchLineItems?: (action: any) => void;
  disabled: boolean;
  isOps?: any;
  inProgress?: any;
  countType?: string;
}

const QuantityDeltaCell: FC<Props> = ({
  item = {
    quantity: '',
    availablequantity: '',
    costVariance: '',
    cycleCountId: '',
    cycleCountExternalId: '',
    createdBy: '',
    createdByDetails: [],
    id: '',
    isOpen: false,
    needToDisplay: false,
    countType: '',
  },
  handleRefetchLineItems,
  disabled,
  isOps,
  inProgress,
  countType,
}) => {
  const {
    quantity,
    createdByDetails,
    cycleCountId,
    cycleCountExternalId,
    id,
    isOpen,
    needToDisplay,
    createdBy,
  } = item;
  const [itemList, setList] = useState<any>([]);
  const [totalQ, setTotalQ] = useState(0);
  const [createUpdateCycleCountLineItems, { data: eventUsageMutation }] = useMutation(
    CREATE_UPDATE_CYCLE_COUNT_LINE_ITEMS
  );
  const userInfo = useSelector(getUserInfo);
  useEffect(() => {
    let totalq = 0;
    if (createdByDetails && createdByDetails.length) {
      createdByDetails.forEach((list: any) => {
        /* eslint-disable no-param-reassign */
        list.counterValue = list.quantity;
        totalq += Number(list.quantity);
      });
      setTotalQ(totalq);
      setList(createdByDetails);
    }
  }, [createdByDetails]);

  useEffect(() => {
    if (
      eventUsageMutation &&
      eventUsageMutation.createUpdateCycleCountLineItems &&
      eventUsageMutation.createUpdateCycleCountLineItems.message === 'success'
    ) {
      if (handleRefetchLineItems) {
        handleRefetchLineItems('update');
      }
    }
  }, [eventUsageMutation, handleRefetchLineItems]);

  const setUpdate = (mutation: any): void => {
    if (mutation) {
      createUpdateCycleCountLineItems({
        variables: mutation,
      });
    }
  };

  const debouncedFetchItems = useCallback(debounced(500, setUpdate), [debounced, setUpdate]);

  const UpdateLineItems = (
    event: any,
    lineid: string,
    extId: string,
    countValue?: string
  ): void => {
    // console.log('changed');
    const payload: Array<any> = [];

    const updatedList = [...createdByDetails];
    updatedList.forEach((element: any) => {
      if (element.externalId === extId) {
        /* eslint-disable no-param-reassign */
        element.counterValue = countValue || event.target.value;
      }
    });
    setList(updatedList);

    createdByDetails.forEach((i: any): void => {
      if (i.externalId === extId) {
        if (countValue || event?.target?.value !== '') {
          payload.push({
            sfid: lineid,
            externalId: extId,
            quantity: countValue || event.target.value,
          });

          const mutation = {
            cycleCountExternalId,
            cycleCountId,
            formType: 'New',
            lineItems: payload,
            countType,
          };

          debouncedFetchItems(mutation);
        }
      }
    });
  };

  const handleCountalert = (event: any, data: any) => {
    UpdateLineItems(event, createdByDetails[0]?.sfid, createdByDetails[0]?.externalId, data?.value);
  };

  return needToDisplay ? (
    <DataTableCell title={totalQ} className="right-align">
      {isOps && (
        <>
          {totalQ}
          {isOpen &&
            createdByDetails?.map((lineItem: any) => (
              <div className="border-line-input">
                <Input
                  defaultValue={lineItem.counterValue}
                  disabled={disabled}
                  onBlur={(_event: any): void =>
                    UpdateLineItems(_event, lineItem.sfid, lineItem.externalId)
                  }
                  id="quantity"
                />
              </div>
            ))}
        </>
      )}
      {!isOps && (
        <div className="border-line-input">
          {/* <Input
            defaultValue={quantity}
            disabled={disabled}
            onBlur={(_event: any): void =>
              UpdateLineItems(_event, createdByDetails[0]?.sfid, createdByDetails[0]?.externalId)
            }
            id="quantity"
          /> */}

          <Input
            id="unique-id-4"
            minValue={0}
            maxValue={MAX_QUANTITY}
            disabled={!inProgress || disabled || createdBy !== userInfo?.id}
            onChange={(event: any, data: { value: string; number: number }): void => {
              handleCountalert(event, data);
            }}
            value={quantity}
            variant="counter"
          />
        </div>
      )}
    </DataTableCell>
  ) : null;
};

/** Custom Rep/Covering cell */
export default QuantityDeltaCell;
