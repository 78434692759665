/**
 * Custom Footer component on detail popover to navigate to event details.
 * @module src/components/Events/EventsCalendar/EventsCalendarDay/EventDetailPanel
 */
import React, { FC } from 'react';
import Button from '@salesforce/design-system-react/components/button';
import './index.scss';

interface Props {
  handleNavigateToEventDetail: (eventType: string, externalId: string, id: string) => void;
  eventData: {
    event: {
      eventType: string;
      externalId: string;
      eventId: string;
      id: string;
      eventStatus: string;
      procedures: string;
      eventDate: string;
      eventStartDate: Date;
      eventEndDate: Date;
      eventTime: string;
      accountName: string;
      eventName: string;
      surgeonFirstName: string;
      surgeonLastName: string;
      surgeonName?: string;
      eventSubType: string;
      salesRep: string;
      coveringReps: string;
      cycleCountExternalId?: string;
    };
  };
}

const EventDetailPanelFooter: FC<Props> = ({ eventData, handleNavigateToEventDetail }) => {
  const { event } = eventData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const externalId: any =
    event.eventType === 'Cycle Count' ? event?.cycleCountExternalId : event.externalId;
  return (
    <Button
      className="popover-footer-event"
      onClick={(): void => handleNavigateToEventDetail(event.eventType, externalId, event.id)}
    >
      <p>Go To Event Details</p>
    </Button>
  );
};

/** Custom Footer component on Popover */
export default EventDetailPanelFooter;
