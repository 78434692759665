/**
 * Redux file for getting/setting event filters
 * @module src/store/ducks/inventoryReviewFilters
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_IN_REVIEW_FILTERS = 'SET_IN_REVIEW_FILTERS';
const CLEAR_IN_REVIEW_FILTERS = 'CLEAR_IN_REVIEW_FILTERS';

interface SetInventoryReviewFiltersActionType {
  payload: string | null;
  type: typeof SET_IN_REVIEW_FILTERS;
}

interface ClearInventoryReviewFiltersActionType {
  type: typeof CLEAR_IN_REVIEW_FILTERS;
}

/** eventFilters action type */

export type InventoryReviewFilterActionType =
  | SetInventoryReviewFiltersActionType
  | ClearInventoryReviewFiltersActionType;

/** set eventFilters action creator */

export const setInventoryReviewFilters = (
  state: string | null
): InventoryReviewFilterActionType => {
  return {
    payload: state,
    type: SET_IN_REVIEW_FILTERS,
  };
};

/** clear eventFilters action creator */

export const clearInventoryReviewFilters = (): ClearInventoryReviewFiltersActionType => {
  return {
    type: CLEAR_IN_REVIEW_FILTERS,
  };
};

/** eventFilters state type */

export type InventoryReviewFilterState = string | null;

/** eventFilters reducer */

export default (
  state: InventoryReviewFilterState = null,
  action: ActionType
): InventoryReviewFilterState => {
  switch (action.type) {
    case SET_IN_REVIEW_FILTERS: {
      return action.payload;
    }
    case CLEAR_IN_REVIEW_FILTERS: {
      return null;
    }
    default:
      return state;
  }
};

/** selector to get accessToken */

export const getInventoryReviewFilters = (state: State): InventoryReviewFilterState => {
  return state.inventoryReviewFilters;
};
