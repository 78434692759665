/**
 * Graphql mutation to create Notification settings
 * @module src/graphql/createUpdateInventoryReturnLineItems
 */
import gql from 'graphql-tag';

const CREATE_UPDATE_CYCLE_COUNT_LINE_ITEMS = gql`
  mutation createUpdateCycleCountLineItems(
    $cycleCountId: String
    $cycleCountExternalId: String
    $formType: String
    $countType: String
    $lineItems: [CycleCountLineItems]
  ) {
    createUpdateCycleCountLineItems(
      cycleCountId: $cycleCountId
      cycleCountExternalId: $cycleCountExternalId
      formType: $formType
      countType: $countType
      lineItems: $lineItems
    ) {
      message
      externalId
    }
  }
`;

export default CREATE_UPDATE_CYCLE_COUNT_LINE_ITEMS;
