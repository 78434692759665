/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import RadioButtonGroup from '@salesforce/design-system-react/components/radio-button-group';
import Radio from '@salesforce/design-system-react/components/radio-button-group/radio';
import { format } from 'date-fns';
import { MONTH_FORMATTER, DAY_FORMATTER } from '../../../../util/constants';

interface Props {
  currentSelectedView: string;
  toolbar: any;
}

const CustomToolbar: FC<Props> = ({ currentSelectedView, toolbar }) => {
  const [selectedView, setSelectedView] = useState(currentSelectedView);
  const goToBack = (): void => {
    toolbar.onNavigate('PREV');
  };

  const goToNext = (): void => {
    toolbar.onNavigate('NEXT');
  };

  const goToCurrent = (): void => {
    const now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.onNavigate('TODAY');
  };

  const goToDayView = (): void => {
    setSelectedView('day');
    toolbar.onView('day');
  };
  const goToWeekView = (): void => {
    setSelectedView('week');
    toolbar.onView('week');
  };
  const goToMonthView = (): void => {
    setSelectedView('month');
    toolbar.onView('month');
  };

  return (
    <div className="slds-grid slds-wrap slds-grid_align-spread slds-grid_vertical-align-center slds-p-bottom_large">
      <div className="slds-col d-inherit">
        <div className="slds-button-group calendar-header_day">
          <Button label="Today" onClick={goToCurrent} />
        </div>
        <ButtonGroup
          id="button-group-icon-group-1"
          className="calendar-header_button-group arrow_buttons"
        >
          <Button
            assistiveText={{ icon: 'chevronleft' }}
            buttonVariant="icon"
            iconName="chevronleft"
            iconCategory="utility"
            iconVariant="border"
            variant="icon"
            onClick={goToBack}
          />
          <Button
            assistiveText={{ icon: 'chevronright' }}
            iconName="chevronright"
            iconCategory="utility"
            iconVariant="border"
            variant="icon"
            onClick={goToNext}
          />
        </ButtonGroup>
      </div>
      <div className="slds-col">
        {(toolbar.view === 'month' || toolbar.view === 'week') && (
          <h1 className="calender-heading_main">
            {format(new Date(toolbar.date), MONTH_FORMATTER)}
          </h1>
        )}
        {toolbar.view === 'day' && (
          <h1 className="calender-heading_main">{format(new Date(toolbar.date), DAY_FORMATTER)}</h1>
        )}
      </div>
      <div className="slds-col blank-legend_hide">
        <RadioButtonGroup
          className="calendar-header_button-group"
          id="button-group-more-icon"
          // TODO: Change type of event from any to more specific type
          onChange={(event: any): void => {
            if (event.target.value === 'month') {
              goToMonthView();
            }
            if (event.target.value === 'week') {
              goToWeekView();
            }
            if (event.target.value === 'day') {
              goToDayView();
            }
          }}
        >
          <Radio
            key="Month"
            value="month"
            labels={{
              label: 'Month',
            }}
            checked={selectedView === 'month'}
            variant="button-group"
          />
          <Radio
            key="Week"
            value="week"
            labels={{
              label: 'Week',
            }}
            checked={selectedView === 'week'}
            variant="button-group"
          />
          <Radio
            key="Day"
            value="day"
            labels={{
              label: 'Day',
            }}
            checked={selectedView === 'day'}
            variant="button-group"
          />
        </RadioButtonGroup>
      </div>
    </div>
  );
};

export default CustomToolbar;
