/*
 * Get Bill To Addresses GraphQL specification
 * @module src/graphql/getUserEAccount
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_USER_E_ACCOUNT = gql`
  query getUserEAccount($userId: String!) {
    getUserEAccount(userId: $userId) {
      id
      value: name
      number
      branchId
      subInventoryCode
      territoryName
      __typename
    }
  }
`;
