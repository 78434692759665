/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-closing-tag-location */

import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';

interface Status {
  eventStatus: string;
  accountName?: string;
  accountNumber: string;
  status: string;
}
export interface Props {
  children?: FC;
  item?: Status;
  type?: string;
}

const AccountCell: FC<Props> = ({
  item = {
    accountNumber: '',
    accountName: '',
    status: '',
  },
  type = null,
}) => {
  const { accountName, accountNumber } = item;
  return (
    <DataTableCell title={accountName || ''}>
      {type === 'Favorites' ? (
        <div>{`${accountName}`}</div>
      ) : (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >{`${accountNumber} - ${accountName}`}</div>
      )}
    </DataTableCell>
  );
};

export default AccountCell;
