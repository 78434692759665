/*
 * Filtered User Branches GraphQL specification
 * @module src/graphql/getFilteredUserBranches
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Filtered User Branches GraphQL API query */
export const GET_FILTERED_USER_BRANCHES = gql`
  query getFilteredUserBranches($region: String!, $division: String!, $userId: String!) {
    getFilteredUserBranches(userId: $userId, region: $region, division: $division) {
      id
      label: name
    }
  }
`;
