/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Module for Custom Rep/Covering Cell.
 * @module src/Events/EventsList
 */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';

interface Quantity {
  quantity: string;
  availablequantity: string;
  productdescription: string;
  createdByDetails: any[];
  id: string;
  quantitydifference: string;
  isOpen: boolean;
  needToDisplay: boolean;
}
interface Props {
  item?: Quantity;
}

const TableRowCell: FC<Props> = ({
  item = {
    quantity: '',
    availablequantity: '',
    productdescription: '',
    createdByDetails: [],
    id: '',
    quantitydifference: '',
    isOpen: false,
    needToDisplay: false,
  },
}) => {
  const { createdByDetails, id, quantitydifference, isOpen, needToDisplay } = item;

  return needToDisplay ? (
    <DataTableCell title={quantitydifference} className="center-align-spacing">
      {quantitydifference}
      {isOpen &&
        createdByDetails?.map((lineItem: any) => (
          <div className="border-line">
            <DataTableCell>{lineItem.createdByName}</DataTableCell>
          </div>
        ))}
    </DataTableCell>
  ) : null;
};

/** Custom Rep/Covering cell */
export default TableRowCell;
