/*
 * User Info GraphQL specification
 * @module src/graphql/getUserNotificationSettings
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/** User Info GraphQL API query */
export const GET_USER_NOTIFICATION_SETTINGS = gql`
  query getUserNotificationSettings($userId: String, $businessunit: String) {
    getUserNotificationSettings(userId: $userId, businessunit: $businessunit) {
      userId
      notificationSettings {
        id
        category
        subCategory
        both
        inApp
        email
        off
        businessunitCategoryId
        isRequired
        isEnabled
        description
        createdDate
        updateDate
        updatedBy
        createdBy
      }
    }
  }
`;
