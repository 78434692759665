/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

/**
 * Module for Cancelling evnet creation.
 * @module src/components/CreateEditSurgeryEvent/CancelEventCreationModal
 */
import React, { FC } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import './index.scss';

import Combobox from '@salesforce/design-system-react/components/combobox';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';

interface Props {
  inputValue: string;
  selection: [];
  favName: string;
  inventoryRequestFav: any;
  showEditConfirmationModal: boolean;
  handleModalToggle: () => void;
  handlelEditFavoriteModalConfirmPress: () => void;
  handleEditName: (e: any) => void;
  handleDropDownSet: (event: any, data: any) => void;
  comment?: string;
  setComment: (text: string) => void;
}

const FavoriteSaveAsConfirmationModal: FC<Props> = ({
  // favName,
  inputValue,
  comment,
  setComment,
  selection,
  // handleEditName,
  inventoryRequestFav,
  showEditConfirmationModal,
  handleModalToggle,
  handleDropDownSet,
  handlelEditFavoriteModalConfirmPress,
}) => {
  const { nameOfFavorite } = inventoryRequestFav;

  // const [FavName, setFavName] = useState(nameOfFavorite);

  // const handleSaveAsName = (e: any): void => {
  //   if (e.target.value) {
  //     setFavName(e.target.value);
  //   }
  // };

  // const handleSaveAsName1 = (e: any): void => {
  //   handleEditName(e.target.value);
  // };

  const handleDropDown = (event: any, data: { selection: any }) => {
    handleDropDownSet('', data.selection);
  };

  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="edit-fav-modal default-modal_header default-modal_footer"
        isOpen={showEditConfirmationModal}
        footer={[
          <Button label="Cancel" onClick={handleModalToggle} key="no" />,
          <Button
            label="Save"
            variant="brand"
            onClick={handlelEditFavoriteModalConfirmPress}
            key="yes"
          />,
        ]}
        onRequestClose={handleModalToggle}
        heading={nameOfFavorite}
      >
        <section className="slds-p-around_small">
          {/* <div className="slds-form-element slds-m-bottom_small">
            <Input
              aria-describedby="favorite_name"
              id="unique-id-4"
              label="Enter Favorite Name"
              placeholder="Favorite Name"
              value={favName}
              onChange={handleSaveAsName1}
            />
          </div> */}
          <div className="slds-form-element slds-m-bottom_small">
            <Combobox
              id="combobox-readonly-single"
              events={{
                onSelect: handleDropDown,
              }}
              labels={{
                label: 'Service Level',
                placeholder: 'Service Level',
              }}
              options={[
                // { label: 'Ground', value: 'Ground' },
                // {
                //   label: 'Standard Overnight',
                //   value: 'Standard Overnight',
                // },
                // {
                //   label: 'Priority Overnight',
                //   value: 'Priority Overnight',
                // },
                {
                  label: 'By Close of Business',
                  value: 'By Close of Business',
                },
                {
                  label: 'AM',
                  value: 'AM',
                },
                {
                  label: 'First AM',
                  value: 'First AM',
                },
                {
                  label: 'Hold for Pickup',
                  value: 'Hold for Pickup',
                },
                {
                  label: '3PL Delivery',
                  value: '3PL Delivery',
                },
              ]}
              selection={selection}
              value={inputValue}
              variant="readonly"
            />
            {/* <div className="slds-form-element__control">
            <input id="opptyName" className="slds-input" type="text" placeholder="Enter name" />
          </div> */}
          </div>
          <div className="slds-form-element slds-m-bottom_small">
            <label className="slds-form-element__label" htmlFor="Comments">
              Comments
            </label>
            <div className="slds-form-element__control">
              <textarea
                id="Comments"
                className="slds-textarea"
                placeholder="Enter Comments"
                value={comment}
                onChange={(e: any): void => setComment(e.target.value)}
              />
            </div>
          </div>
        </section>
      </Modal>
    </IconSettings>
  );
};

/** Custom component for Cancelling evnet creation */
export default FavoriteSaveAsConfirmationModal;
