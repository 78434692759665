/*
 * Get Procedure Details GraphQL specification
 * @module src/graphql/getProcedureTypes
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_PROCEDURE_TYPES = gql`
  query getProcedureTypes {
    getProcedureTypes {
      id
      name
    }
  }
`;
