/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * React component for Multi Select Picker.
 * @module src/components/shared/AutocompleteMultiSelectPicker
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Icon from '@salesforce/design-system-react/components/icon';
import escapeRegExp from 'lodash.escaperegexp';

interface Selection {
  name: string;
  __typename: string;
  id: string;
  label: string;
  value?: string;
}

interface SelectedData {
  selection: Selection[];
}

interface ValueInterface {
  value: string;
}

type SelectionType = Selection[];

interface Props {
  items: any;
  selectedItems: SelectionType;
  setSelectedItems: (items: SelectionType) => void;
  label: string;
  placeholder?: string;
  addItemLabel?: string;
  withAddMenuButton: boolean;
  disabled: boolean;
  key?: string;
  required?: boolean;
  filterPicker?: boolean;
  name?: string;
}

const AutocompleteMultiSelectPicker: FC<Props> = props => {
  const [inputValue, setInputValue] = useState('');
  // const [open, setOpen] = useState(false);
  const {
    items,
    setSelectedItems,
    selectedItems,
    label,
    placeholder,
    addItemLabel = '',
    withAddMenuButton,
    disabled,
    required,
    filterPicker = false,
  } = props;
  const [savedOptions] = useState(selectedItems || []);
  const handleChange = useCallback(
    (_event, selectedValue: ValueInterface) => {
      const { value } = selectedValue;
      _event.persist();
      setInputValue(value);
    },
    [setInputValue]
  );

  const handleRemoveSelection = useCallback(
    (_event, data: SelectedData) => {
      if (!disabled) {
        const { selection } = data;
        setInputValue('');
        setSelectedItems(selection);
      }
    },
    [disabled, setSelectedItems]
  );

  const handleSelectItem = useCallback(
    (_event, data: SelectedData) => {
      const { selection } = data;
      setInputValue('');
      const updatedSelection: Selection[] = Object.values(
        selection.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
      );
      // setOpen(false);
      setSelectedItems(updatedSelection);
    },
    [setInputValue, setSelectedItems]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const comboboxFilterAndLimit = (parameters: any): SelectionType => {
    const { searchedValue, options, selection } = parameters;
    const inputValueRegExp = new RegExp(escapeRegExp(searchedValue), 'ig');
    return options.filter((option: Selection) => {
      const searchTermFound = option.label ? option.label.match(inputValueRegExp) : false;
      const isSeparator = false;
      const notAlreadySelected = !selection.some((sel: Selection) => sel.id === option.id);

      return (!searchedValue || isSeparator || searchTermFound) && notAlreadySelected;
    });
  };

  const optionalText = required ? '' : '';

  const optionsWithLabel =
    items &&
    items.map((elem: any) => {
      // eslint-disable-next-line no-underscore-dangle
      // if (elem.__typename === 'CoveringRep') {
      return { ...elem, label: elem.value };
      // }
      // eslint-disable-next-line no-underscore-dangle
      // if (elem.__typename === 'ProcedureDetail') {
      //   return { ...elem, ...{ elem, label: `${elem.name}` } };
      // }
    });

  const eventOptions = filterPicker
    ? comboboxFilterAndLimit({
        searchedValue: inputValue,
        options: optionsWithLabel || [],
        selection: selectedItems,
      })
    : optionsWithLabel || [];
  useEffect(() => {
    if (items && items.length > 0) {
      if (savedOptions && savedOptions.length > 0) {
        // this is to trigger change/selection handler on Edit to show saved options selection on UI
        const selOptions = items.filter((i: any) =>
          savedOptions.find((sel: any) => sel.id === i.id)
        );
        const withLabels =
          selOptions &&
          selOptions.map((i: any) => {
            return { ...i, label: i.value };
          });
        const data = { selection: withLabels || [] };
        handleSelectItem({}, data);
      } else if (items && items?.length === 1 && label === 'Covering Rep') {
        // this is to trigger change/selection handler on Edit to show saved options selection on UI
        const withLabels =
          items &&
          items.map((i: any) => {
            return { ...i, label: i.value };
          });
        const data = { selection: withLabels || [] };
        handleSelectItem({}, data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, savedOptions]);

  return (
    <div
      className={
        !disabled
          ? 'slds-form-element slds-m-bottom_smal'
          : 'slds-form-element slds-m-bottom_smal disabled-input'
      }
      key={label}
    >
      <Combobox
        id={label}
        disabled={disabled}
        singleInputDisabled={disabled}
        optionsAddItem={
          withAddMenuButton
            ? [
                {
                  id: 'options-add-id-1',
                  icon: (
                    <Icon
                      assistiveText={{ label: 'Add' }}
                      category="utility"
                      size="x-small"
                      name="add"
                    />
                  ),
                  label: (): [JSX.Element] => [<span>{addItemLabel}</span>],
                },
              ]
            : []
        }
        events={{
          // onRequestOpen: (): void => {
          //   setOpen(true);
          // },
          // onRequestClose: (): void => {
          //   setOpen(false);
          // },
          onChange: handleChange,
          onRequestRemoveSelectedOption: handleRemoveSelection,
          onSelect: handleSelectItem,
        }}
        // isOpen={open}
        labels={{
          label: `${label + optionalText}`,
          placeholder,
          noOptionsFound: 'No data found',
        }}
        options={eventOptions || []}
        multiple
        menuItemVisibleLength={5}
        menuPosition="relative"
        selection={selectedItems}
        value={inputValue}
        variant={filterPicker ? 'base' : 'readonly'}
        required={required}
      />
    </div>
  );
};

/** React component for Autocomplete Multi Select Picker. */
export default AutocompleteMultiSelectPicker;
