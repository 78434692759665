/**
 * Custom Popover component to display event details.
 * @module src/components/Events/EventsCalendar/EventsCalendarDay/EventDetailPanel
 */
import React, { FC } from 'react';
import Icon from '@salesforce/design-system-react/components/icon';
import { format } from 'date-fns';
import './index.scss';
import moment from 'moment';
import { MOCK_OR_EVENT_TYPE } from '../../../../../util/constants';

interface Props {
  eventData: {
    event: {
      eventType: string;
      externalId: string;
      eventId: string;
      id: string;
      eventStatus: string;
      procedures: string;
      eventDate: string;
      eventStartDate: Date;
      eventEndDate: Date;
      eventTime: string;
      accountName: string;
      eventName: string;
      surgeonFirstName: string;
      surgeonLastName: string;
      surgeonName?: string;
      eventSubType: string;
      salesRep: string;
      coveringReps: string;
      territory: string;
      eventDescription: string;
    };
  };
}

const EventDetailPanel: FC<Props> = ({ eventData }) => {
  const { event } = eventData;
  const {
    eventId,
    eventType,
    accountName,
    eventName,
    surgeonFirstName,
    surgeonLastName,
    eventStartDate,
    eventEndDate,
    procedures,
    salesRep,
    coveringReps,
    territory,
    eventDescription,
    eventSubType,
  } = event;
  const formatter = 'MM/d/yyyy';
  const timedata = new Date();
  const newtime = timedata
    .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
    .substring(4)
    .match(/\b(\w)/g);
  const TimeZoneData = newtime?.join('');
  return (
    <div>
      <div className="pophover_calendar">
        <h3>{eventId}</h3>
        <div>
          {eventType === 'Cycle Count' ? (
            <p>
              <span>{territory}</span>
            </p>
          ) : (
            <p />
          )}
        </div>
        {eventType === 'Surgery-Usage Only' || eventType === 'Surgery' ? (
          <h1>{accountName}</h1>
        ) : (
          <>
            <h1>{accountName}</h1>
            <span className="event-description">{eventDescription}</span>
            <h1>{eventName}</h1>
          </>
        )}
        {eventType !== 'Workshop Event' && surgeonFirstName && surgeonLastName && (
          <h2>{`${surgeonFirstName} ${surgeonLastName}`}</h2>
        )}
      </div>

      <div>
        <ul className="surgery-modal-listing">
          <li>
            <div className="svg-icon">
              <Icon
                assistiveText={{ label: 'clock' }}
                category="utility"
                name="clock"
                size="small"
              />
            </div>
            <div>
              {(eventType === 'Surgery-Usage Only' ||
                (eventType === 'Demo' && eventSubType !== MOCK_OR_EVENT_TYPE) ||
                eventType === 'Surgery') && (
                <div style={{ display: 'flex' }}>
                  <p>{format(new Date(eventStartDate), formatter)}</p>
                  <span style={{ paddingLeft: '5px' }}>
                    {`${new Date(eventStartDate).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}`}
                    {`  ${TimeZoneData}`}
                  </span>
                </div>
              )}
              {(eventType === 'Trial' || eventSubType === MOCK_OR_EVENT_TYPE) && (
                <>
                  <div style={{ display: 'flex' }}>
                    {eventStartDate ? (
                      <>
                        <p>{moment(eventStartDate).format('L')}</p>
                        <span style={{ paddingLeft: '5px' }}>
                          {moment(eventStartDate).format('LT')}
                          {`  ${TimeZoneData}`}
                        </span>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div style={{ display: 'flex' }}>
                    {eventEndDate ? (
                      <>
                        <p>{moment(eventEndDate).format('L')}</p>
                        <span style={{ paddingLeft: '5px' }}>
                          {moment(eventEndDate).format('LT')}
                          {`  ${TimeZoneData}`}
                        </span>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              )}
              {eventType === 'Cycle Count' && (
                <p>
                  {`${format(new Date(eventStartDate), formatter)} - ${format(
                    new Date(eventEndDate),
                    formatter
                  )}`}
                  {`  ${TimeZoneData}`}
                </p>
              )}
            </div>
          </li>
          {eventType !== 'Cycle Count' &&
            eventSubType !== 'Show-n-Go' &&
            eventSubType !== MOCK_OR_EVENT_TYPE && (
              <li>
                <div className="svg-icon">
                  <Icon
                    assistiveText={{ label: 'Account' }}
                    category="utility"
                    name="work_order_type"
                    size="small"
                  />
                </div>
                <div>
                  <p>
                    {`Procedure: `}
                    <span>{procedures}</span>
                  </p>
                </div>
              </li>
            )}
          <hr />
          {eventType !== 'Cycle Count' && (
            <li>
              <div className="svg-icon">
                <Icon
                  assistiveText={{ label: 'Account' }}
                  category="utility"
                  name="user"
                  size="small"
                />
              </div>
              <div>
                <span>{salesRep}</span>
              </div>
            </li>
          )}
          <li>
            <div className="svg-icon">
              <Icon
                assistiveText={{ label: 'Account' }}
                category="utility"
                name="people"
                size="small"
              />
            </div>
            <div>
              <span>{coveringReps}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

/** Calendar PopOver component to display event details */
export default EventDetailPanel;
