import gql from 'graphql-tag';

// Creating a fragment to clean up code

const FRAGMENT_FORM_FIELD = gql`
  fragment fragmentFormField on FormField {
    kind
    name
    fieldLabel
    fieldType
    required
    optionsStatic {
      label
      value
      id
    }
    optionsPlaceHolder
    optionsDynamic
    optionsDependentField
    optionsDynamicDependent
    optionsAutoSelected
    characterLimit
    showTooltip
    permissionSets
    showOnWeb
    showAddButton
    addButtonText
    keypadType
    isShow
    minValue
    maxValue
    date
    time
    allowBackDate
    isFailed
  }
`;

export default FRAGMENT_FORM_FIELD;
