/**
 * Graphql mutation to update Notification settings
 * @module src/graphql/updateInventory
 */
import gql from 'graphql-tag';

/** updating Notification settings  */
const UPDATE_LOT_LOCATION = gql`
  mutation updateLotLocation($id: String, $externalId: String, $location: String) {
    updateLotLocation(id: $id, externalId: $externalId, location: $location) {
      message
    }
  }
`;

export default UPDATE_LOT_LOCATION;
