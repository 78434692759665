/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * React component for Input.
 * @module src/components/shared/Checkbox
 */
import React, { FC, useCallback } from 'react';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import './index.scss';

interface Props {
  label: string;
  setIsChecked: (checked: boolean) => void;
  isChecked: boolean;
  key?: string;
  disabled?: boolean;
}

const Toggle: FC<Props> = props => {
  const { label, setIsChecked, isChecked, key, disabled = false } = props;

  const handleChange = useCallback(() => {
    setIsChecked(!isChecked);
  }, [isChecked, setIsChecked]);

  return (
    <div className="slds-form-element slds-m-bottom_small" key={key}>
      <Checkbox
        labels={{
          label: <span className="slds-text-body_small slds-text-title_bold">{label}</span>,
          toggleDisabled: '',
          toggleEnabled: '',
        }}
        id={key}
        variant="toggle"
        onChange={handleChange}
        checked={isChecked}
        disabled={disabled || false}
      />
    </div>
  );
};

/** React component for Checkbox. */
export default Toggle;
