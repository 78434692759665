/**
 * Module for Custom Rep/Covering Cell.
 * @module src/Events/EventsList
 */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
// import './index.scss';

interface Reps {
  accountNumber: string;
  accountName: string;
}
interface Props {
  item?: Reps;
}

const InventoryAccountCell: FC<Props> = ({
  item = {
    accountName: '',
    accountNumber: '',
  },
}) => {
  const { accountNumber, accountName } = item;
  return (
    <DataTableCell title={`${accountNumber} - ${accountName}`}>
      <p className="wrap-text font-12">{`${accountNumber} - ${accountName}`}</p>
    </DataTableCell>
  );
};

/** Custom Rep/Covering cell */
export default InventoryAccountCell;
