/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable import/no-cycle */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import Modal from '@salesforce/design-system-react/components/modal';
// import Combobox from '@salesforce/design-system-react/components/combobox';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import moment from 'moment';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import CartComponent from '../CartComponent';
import debounced from '../../../../../../util/debounced';
import { GET_PRODUCT_LIST } from '../../../../../../graphql/getProductList';
import './index.scss';
import CREATE_UPDATE_USAGE_LINE_ITEMS from '../../../../../../graphql/mutations/createUpdateUsageSheetLineItems';
import { GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS } from '../../../../../../graphql/getSubInventoryWithLotDetails';
import { MAX_QUANTITY, USAGE_TYPE, NOTIFY_TYPE } from '../../../../../../util/constants';
import useSnackBar from '../../../../../../util/customHooks/useSnackBar';
import {
  getDateDifferenceInDays,
  getHoldIcon,
  GetActualQty,
} from '../../../../../../util/utilityFunctions';
import SnackBar from '../../../../../Shared/SnackBar';
import Collapsible from './collapsible';
import RESUBMIT_ORDER_DETAILS from '../../../../../../graphql/mutations/resubmitOrderDetails';
import { GET_FILTERED_PRODUCT_GROUPS } from '../../../../../../graphql/getFilteredProductGroups';
import { getUserInfo } from '../../../../../../store/ducks/userInfo';
import AutocompleteSingleSelectPicker from '../../../../../Shared/AutocompleteMultiSelectPicker';

interface Props extends RouteComponentProps {
  isOpen: boolean;
  handleClose: () => void;
  usageDetails: any;
  refetch: () => void;
  territory: string;
  isERPOrder: boolean;
  handleFilter: (filter: DynamicType | null) => void;
  selectedFilter?: DynamicType | null;
  count: number;
  modlenData?: any;
}
enum DetailTypes {
  ProductGroup = 'ProductGroup',
}
interface DynamicValue {
  [key: string]: any;
}

interface DynamicType {
  [key: string]: any;
}

const AddEditLineItems: FC<Props> = ({
  isOpen,
  handleClose,
  usageDetails,
  refetch,
  territory,
  isERPOrder,
  selectedFilter,
  handleFilter,
  count,
  modlenData,
}) => {
  const userInfo = useSelector(getUserInfo);
  const [loader, setLoader] = useState(false);
  const filterValues = selectedFilter?.filterValues;
  const { open, notification, openSnackBar } = useSnackBar();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isExpanded, setIsExpanded] = useState(true);
  const [catalogCount, setCatalogCount] = useState(count);
  const [subInvCount, setSubInvCount] = useState(0);
  const [productList, setProductList] = useState([]);
  const [tabName, setTabName] = useState(
    usageDetails?.diplayOrderType === 'Bill Only' ? 'Repstock' : 'Catalog'
  );
  const [subInventoryLotInfo, setSubInventoryLotInfo] = useState<any>([]);
  const [selectedFilters, setSelectedFilters] = useState<DynamicType | null>(filterValues || {});
  const [repSelectedFilters, setRepSelectedFilters] = useState<DynamicType | null>(
    filterValues || {}
  );
  const [searchTerm, setSearchText] = useState('');
  const [selectedGrps, setSelectedGrps] = useState<any>(
    selectedFilters?.productGroup?.map((item: any) => item.id) || []
  );
  const [isSearchEdItem, setIsSearchedItem] = useState(false);
  const [addedPartsLocal, setAddedPartsLocal] = useState<any>([]);
  const [addedSunInvPartLocal, setAddedSunInvPartLocal] = useState<any>([]);
  const [productListItems, { data: productItems, loading }] = useLazyQuery(GET_PRODUCT_LIST);
  const [
    getSubInventoryWithLotDetails,
    { data: subInventoryWithLotDetails, loading: loadingSubInventory },
  ] = useLazyQuery(GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS, { fetchPolicy: 'no-cache' }); // USELAZYQUERY
  const [createUpdateUsageLineItems, { data: usageLineItems }] = useMutation(
    CREATE_UPDATE_USAGE_LINE_ITEMS
  );
  const [resubmitOrderLineItems] = useMutation(RESUBMIT_ORDER_DETAILS);
  const getProductRecord = useCallback(
    (product: any): any => {
      let returnVal = null;
      if (usageDetails && usageDetails.lineItems && usageDetails.lineItems.length) {
        returnVal = usageDetails.lineItems.find((rec: any) => rec.productId === product.productId);
      }
      return returnVal;
    },
    [usageDetails]
  );
  const { data: filteredproductGroups, loading: loadingProductGroup } = useQuery(
    GET_FILTERED_PRODUCT_GROUPS,
    {
      variables: {
        caseId: usageDetails?.caseId ? usageDetails?.caseId : '',
        caseExternalId: usageDetails?.caseExternalId ? usageDetails?.caseExternalId : '',
        salesRepId: usageDetails?.salesRepId ? usageDetails?.salesRepId : '',
        tabName,
      },
    }
  );
  const [productListUpdated, setProductListUpdated] = useState<any[]>([]);
  // eslint-disable-next-line prefer-const
  let [cartCount, setCartCount] = useState(0);
  const updateProducts: Array<any> = productListUpdated;
  const optionsWithLabel = (value: any): object =>
    value &&
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === DetailTypes.ProductGroup) {
        return { ...elem, label: `${elem.value}` };
      }
    });
  const productGroup = optionsWithLabel(filteredproductGroups?.getProductGroups);

  const getProductLotRecord = useCallback(
    (product: any): any => {
      let returnVal = null;
      if (usageDetails && usageDetails.lineItems && usageDetails.lineItems.length) {
        returnVal = usageDetails.lineItems.find(
          (rec: any) =>
            rec.productId === product.productId &&
            rec.lotNumber === product.lotNumber &&
            rec.caseUsageSubInventoryCode === product.subInventoryCode
        );
      }
      return returnVal;
    },
    [usageDetails]
  );

  useEffect(() => {
    if (productItems && productItems.getProductList && productItems.getProductList.length) {
      productItems.getProductList.forEach((list: any) => {
        const record = getProductRecord(list);
        const changedIndex = addedPartsLocal.find(
          (index: any) => index.productId === list.productId
        );
        /* eslint-disable no-param-reassign */
        list.counterValue = record ? Number(0) : 0;
        if (changedIndex) {
          list.counterValue = changedIndex.counterValue;
        }
        if (record) {
          list.usageExternalId = record.usageExternalId;
          list.alreadyAdded = true;
        }
        list.changed = false;
        productListUpdated.forEach((data: any) => {
          if (data.productId === list.productId) {
            list.changed = false;
            list.counterValue = data.counterValue;
          }
        });
      });
      setProductList(productItems.getProductList);
      setCartCount(catalogCount);
    } else {
      setProductList([]);
    }
  }, [addedPartsLocal, getProductRecord, productItems, usageDetails.lineItems]);

  const handleFilterOpening = (title: any, code: any): void => {
    // eslint-disable-next-line no-unused-expressions
    subInventoryLotInfo?.forEach((subInv: any) => {
      const newSubInventoryParts: any = subInv.productDescription[0].value;
      newSubInventoryParts.forEach((subInventorySinglePart: any) => {
        const uniqueParts: any = [];
        uniqueParts.push(subInventorySinglePart.value[0]);
        subInventorySinglePart.value.forEach((prt: any) => {
          let flag: any;
          uniqueParts.forEach((ele: any) => {
            if (!(ele.lotNumber === prt.lotNumber)) {
              flag = true;
            } else {
              flag = false;
            }
          });
          if (flag) {
            uniqueParts.push(prt);
          }
        });
        subInventorySinglePart.value = uniqueParts;
      });
      // eslint-disable-next-line no-unused-expressions
      subInv?.productDescription.forEach((accounts: any) => {
        if (code === accounts.subInventoryCode) {
          accounts.isChecked = !accounts.isChecked;
        }
      });
    });
    setSubInventoryLotInfo(subInventoryLotInfo);
    setIsExpanded(prev => !prev);
  };
  useEffect(() => {
    // const output: any = ([] = [{ subInventoryName: userTerritories, lotInfo: [] }]);
    if (subInventoryWithLotDetails) {
      const searchedSubInventoryWithLot = subInventoryWithLotDetails?.getSubInventoryWithLotDetails;

      if (searchedSubInventoryWithLot.length) {
        const finalSubInvWithLotInfo: Array<any> = [];
        const finalSubInvWithLotAccountInfo: Array<any> = [];
        const productDescriptionLIst: Array<any> = [];
        const repAccount: Array<any> = [];
        searchedSubInventoryWithLot.forEach((record: any) => {
          /* eslint-disable no-param-reassign */
          const LotProduct = getProductLotRecord(record);
          record.alreadyAdded = !!LotProduct;
          record.counterValue = LotProduct ? Number(LotProduct.quantity) : 0;
          record.changed = false;
          record.maxPermissibleValue = Number(record.availableQuantity);
          const changedIndex = addedSunInvPartLocal.find(
            (index: any) =>
              index.lotNumber === record.lotNumber && index.productId === record.productId
          );
          if (changedIndex) {
            record.counterValue = changedIndex.counterValue;
          }
          const data = productDescriptionLIst?.filter(
            (item: any) =>
              item.prodNum === record.productNumber &&
              item.subInventoryCode === record.subInventoryCode
          );
          if (data && data.length > 0) {
            const index = finalSubInvWithLotInfo.findIndex(
              (entry: any) =>
                entry.productNumber === record.productNumber &&
                entry.subInventoryCode === record.subInventoryCode
            );

            finalSubInvWithLotInfo[index].value.push(record);
          } else {
            finalSubInvWithLotInfo.push({
              name: record.productDescription,
              productNumber: record.productNumber,
              productOnHold: record.productOnHold,
              productOnHoldType: record.productOnHoldType,
              subInventoryCode: record.subInventoryCode,
              subInventoryName: record.subInventoryName,
              value: [record],
              isrepstock: record.isrepstock,
            });
            productDescriptionLIst.push({
              prodNum: record.productNumber,
              prodName: record.productDescription,
              subInventoryCode: record.subInventoryCode,
            });
          }
        });
        finalSubInvWithLotInfo.forEach((subInv: any) => {
          if (repAccount.map(e => e.subInventoryCode).indexOf(subInv.subInventoryCode) >= 0) {
            const index = finalSubInvWithLotAccountInfo.findIndex(
              (entry: any) => entry.subInventoryCode === subInv.subInventoryCode
            );
            // eslint-disable-next-line no-unused-expressions
            finalSubInvWithLotAccountInfo[index]?.productDescription[0].value.push(subInv);
          } else {
            finalSubInvWithLotAccountInfo.push({
              subInventoryCode: subInv.subInventoryCode,
              subInventoryName: subInv.subInventoryName,
              name: subInv.name,
              productNumber: subInv.productNumber,
              productOnHold: subInv.productOnHold,
              productOnHoldType: subInv.productOnHoldType,
              isrepstock: subInv.isrepstock,
              productDescription: [
                {
                  name: subInv.name,
                  productNumber: subInv.productNumber,
                  productOnHold: subInv.productOnHold,
                  productOnHoldType: subInv.productOnHoldType,
                  subInventoryCode: subInv.subInventoryCode,
                  subInventoryName: subInv.subInventoryName,
                  isChecked: isSearchEdItem,
                  isrepstock: subInv.isrepstock,
                  value: [subInv],
                },
              ],
            });
            repAccount.push({
              subInventoryCode: subInv.subInventoryCode,
              subInventoryName: subInv.subInventoryName,
            });
          }
        });
        // setSubInventoryLotInfo(finalSubInvWithLotInfo);
        setSubInventoryLotInfo(finalSubInvWithLotAccountInfo);
        setIsSearchedItem(false);
      } else {
        setSubInventoryLotInfo([]);
      }
    }
  }, [addedSunInvPartLocal, getProductLotRecord, subInventoryWithLotDetails]);

  useEffect(() => {
    if (usageLineItems && usageLineItems.createUpdateUsageSheetLineItems) {
      setLoader(true);
      if (usageLineItems.createUpdateUsageSheetLineItems.message === 'success') {
        handleClose();
        refetch();
      }
    }
  }, [handleClose, refetch, usageLineItems]);

  useEffect(() => {
    setRepSelectedFilters({});
    setSelectedFilters({});
    setSelectedGrps([]);
  }, [tabName]);

  const setEventPartQuantity = (searchedItem: string): void => {
    if (searchedItem.length > 2) {
      productListItems({
        variables: {
          searchText: searchedItem,
          caseId: usageDetails.caseId ? usageDetails.caseId : null,
          caseExternalId: usageDetails.caseExternalId ? usageDetails.caseExternalId : null,
          salesRepId: usageDetails.salesRepId ? usageDetails.salesRepId : null,
          filters: {
            productName: selectedGrps,
            businessUnit: [userInfo?.businessunit] || ['Sports Med'],
            userId: userInfo?.id,
          },
        },
      });
    } else {
      productListItems({
        variables: {
          searchText: '',
          caseId: usageDetails.caseId ? usageDetails.caseId : null,
          caseExternalId: usageDetails.caseExternalId ? usageDetails.caseExternalId : null,
          salesRepId: usageDetails.salesRepId ? usageDetails.salesRepId : null,
          filters: {
            productName: selectedGrps,
            businessUnit: [userInfo?.businessunit] || ['Sports Med'],
            userId: userInfo?.id,
          },
        },
      });
    }
  };

  const debouncedFetchSetItems = useCallback(debounced(500, setEventPartQuantity), [
    debounced,
    setEventPartQuantity,
  ]);

  // Get default Parts on load
  useEffect(() => {
    productListItems({
      variables: {
        limit: 50,
        offset: 0,
        searchText: '',
        caseId: usageDetails.caseId ? usageDetails.caseId : null,
        caseExternalId: usageDetails.caseExternalId ? usageDetails.caseExternalId : null,
        salesRepId: usageDetails.salesRepId ? usageDetails.salesRepId : null,
        filters: {
          productName: selectedGrps,
          businessUnit: [userInfo?.businessunit] || ['Sports Med'],
          userId: userInfo?.id,
        },
      },
    });
  }, [productListItems, tabName, selectedGrps]);

  const searchCatalog = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      setSearchText(searchedItem.value);
      const { value } = searchedItem;
      _event.persist();
      //   setInputValue(value);
      if (value && value.length >= 2) {
        debouncedFetchSetItems(value);
      } else {
        debouncedFetchSetItems(value);
      }
    },
    [debouncedFetchSetItems]
  );

  const setSubInventoryQuantity = (searchedItem: string): void => {
    if (searchedItem.length > 2) {
      getSubInventoryWithLotDetails({
        variables: {
          subInventoryCode: usageDetails.accountNumber,
          salesRepId: usageDetails?.salesRepId,
          tabName,
          filters: {
            searchText: searchedItem,
          },
        },
      });
    } else {
      getSubInventoryWithLotDetails({
        variables: {
          subInventoryCode: usageDetails.accountNumber,
          salesRepId: usageDetails?.salesRepId,
          tabName,
          filters: {
            searchText: '',
          },
        },
      });
    }
  };

  const debouncedFetchSubInventoryItems = useCallback(debounced(500, setSubInventoryQuantity), [
    debounced,
    setSubInventoryQuantity,
  ]);
  // Get default Sub Inventories on load
  useEffect(() => {
    if (usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY) {
      getSubInventoryWithLotDetails({
        variables: {
          limit: 50,
          offset: 0,
          subInventoryCode: usageDetails.accountNumber,
          salesRepId: usageDetails?.salesRepId,
          tabName,
          filters: {
            searchText: '',
          },
        },
      });
    }
  }, [getSubInventoryWithLotDetails, usageDetails, territory, tabName]);

  const searchSubInventory = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      //   setInputValue(value);
      if (value && value.length >= 2) {
        debouncedFetchSubInventoryItems(value);
        setIsSearchedItem(true);
      }
    },
    [debouncedFetchSubInventoryItems]
  );
  const holdIconPath = getHoldIcon();
  const handleCount = (
    event: any,
    data: { value: string; number: number },
    product: any,
    sourceLocation: any
  ): void => {
    const updatedList = [...productList];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const counter = 0;
    let partsCounter = catalogCount;
    let decremenmtFlag = false;
    updatedList.forEach((element: any, index: number) => {
      if (product.productId === element.productId) {
        if (product.counterValue > data.number) cartCount -= product.counterValue - data.number;
        else {
          cartCount += data.number - product.counterValue;
        }
        setProductListUpdated(updateProducts);
        /* eslint-disable no-param-reassign */
        decremenmtFlag = data.number < element.counterValue;
        element.counterValue = data.number < MAX_QUANTITY ? data.number : MAX_QUANTITY;
        element.changed = true;
        element.sourceLocation = sourceLocation;
        partsCounter += element.changed ? 1 : 0;
        const addedIndex = addedPartsLocal.findIndex((e: any) => e.productId === element.productId);
        if (addedIndex > -1) {
          addedPartsLocal[addedIndex] = updatedList[index];
        } else {
          addedPartsLocal.push(updatedList[index]);
        }
      }
    });
    setAddedPartsLocal(addedPartsLocal);
    setProductList(updatedList);
    setCatalogCount(cartCount);
    setCartCount(cartCount);
    if (partsCounter < catalogCount) {
      partsCounter = partsCounter === 1 && !decremenmtFlag ? catalogCount + 1 : partsCounter;
    } else if (decremenmtFlag) {
      partsCounter -= 2;
    }
    setCatalogCount(partsCounter);
  };

  const handleCountalert = (
    event: any,
    data: { value: string; number: number },
    product: any,
    sourceLocation: any
  ): void => {
    if (product?.productOnHold) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );
    }
    handleCount(event, data, product, sourceLocation);
  };
  const handleSubInvCount = (
    event: any,
    data: { value: string; number: number },
    subInv: any,
    sourceLocation: any
  ): void => {
    const subInvWithLotInfo = [...subInventoryLotInfo];
    let counter = subInvCount;
    let decremenmtFlag = false;
    subInvWithLotInfo.forEach((element: any) => {
      // eslint-disable-next-line no-unused-expressions
      element?.productDescription.forEach((item: any) => {
        // eslint-disable-next-line no-unused-expressions
        item?.value.forEach((elem: any) => {
          if (elem.name === subInv.productDescription) {
            elem.value.forEach((data1: any, index: any) => {
              if (data1.lotNumber === subInv.lotNumber) {
                decremenmtFlag = data.number < data1.counterValue;
                data1.changed = true;
                data1.counterValue =
                  data.number < subInv.maxPermissibleValue
                    ? data.number
                    : subInv.maxPermissibleValue;
                counter += data1.changed ? 1 : 0;
                data1.sourceLocation = sourceLocation;
                const sunInvIndex = addedSunInvPartLocal.findIndex(
                  (e: any) => e.lotNumber === subInv.lotNumber
                );
                if (sunInvIndex > -1) {
                  addedSunInvPartLocal[sunInvIndex] = elem.value[index];
                } else {
                  addedSunInvPartLocal.push(elem.value[index]);
                }
              }
            });
          }
        });
      });
    });
    setSubInventoryLotInfo(subInvWithLotInfo);
    setAddedSunInvPartLocal(addedSunInvPartLocal);
    if (counter < subInvCount) {
      counter = counter === 1 && decremenmtFlag ? subInvCount + 1 : counter;
    } else if (decremenmtFlag) {
      counter -= 2;
    }
    setSubInvCount(counter);
  };

  const handleSubInvCountalert = (
    event: any,
    data: { value: string; number: number },
    subInv: any,
    sourceLocation: any
  ): void => {
    if (subInv?.productOnHold) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );
    }
    handleSubInvCount(event, data, subInv, sourceLocation);
  };
  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any, label: string): void => {
      // For selected filter value
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };
      if (label === 'repstock') {
        setRepSelectedFilters(selectedFilterValues);
      } else {
        setSelectedFilters(selectedFilterValues);
      }
    },
    [selectedFilters, repSelectedFilters]
  );

  const handleProductList = (selectedItem: any, type: string): void => {
    const selectedOptions = selectedItem?.map((item: any) => item.id);
    setSelectedGrps(selectedOptions);
    if (type === 'catalog') {
      productListItems({
        variables: {
          filters: {
            productName: selectedOptions,
            businessUnit: [userInfo?.businessunit] || ['Sports Med'],
            userId: userInfo?.id,
          },
          searchText: searchTerm,
          caseId: usageDetails.caseId ? usageDetails.caseId : null,
          caseExternalId: usageDetails.caseExternalId ? usageDetails.caseExternalId : null,
          salesRepId: usageDetails.salesRepId ? usageDetails.salesRepId : null,
        },
      });
    } else {
      getSubInventoryWithLotDetails({
        variables: {
          limit: 50,
          offset: 0,
          subInventoryCode: usageDetails.accountNumber,
          salesRepId: usageDetails?.salesRepId,
          tabName,
          filters: {
            productName: selectedOptions,
            searchText: '',
          },
        },
      });
    }
  };

  const handleSelect = useCallback(
    (data: any, name: string, type: string, label: string) => {
      // const { selection } = data;
      setFilterValue(name, data, label);
      handleProductList(data, type);
    },
    [handleProductList, setFilterValue]
  );
  const handleCompletedTab = (e: any): void => {
    if (e === 0) {
      setTabName('Repstock');
    } else if (e === 1) {
      setTabName('Customer');
    } else if (e === 2) {
      setTabName('Catalog');
    } else {
      setTabName('Repstock');
    }
  };

  const onAddClick = (): void => {
    setLoader(true);
    handleFilter(selectedFilters);
    const payload: Array<any> = [];
    let serialNbr = usageDetails?.lineItems?.length || 0;
    addedPartsLocal.forEach((product: any): void => {
      let actualQty;

      if (modlenData) {
        const priceItem = modlenData.find(
          (price: any) => price.productNumber === product.productNumber
        );
        actualQty = GetActualQty(
          usageDetails?.diplayOrderType,
          priceItem?.sellingUOM || 'PCE',
          product?.salesUnitOfMeasure,
          product?.packContent,
          product?.counterValue
        );
      }

      if (product.changed && product.counterValue) {
        serialNbr += 1;
        payload.push({
          sfid: product.sfid,
          externalId: product.externalId,
          productId: product.productId,
          quantity: product.counterValue.toString(),
          lotNumber: product.lotNumber,
          isLotNumber: product.isLotNumber,
          productNumber: product.productNumber,
          serialNumber: serialNbr.toString(),
          productFamily: product.productFamily,
          subInventoryCode: product?.subInventoryCode || '',
          actualQuantity: actualQty?.toString(),
          // sourceLocation: product.sourceLocation,
          actualPackContent: product.packContent ? product.packContent : '1',
          ...(isERPOrder && {
            caseUsageId: product.caseUsageId,
            caseUsageExternalId: product.caseUsageExternalId,
            priceBookEntryId: product.priceBookEntryId,
            salesUnitOfMeasure: product.salesUnitOfMeasure,
            deliveredDate: product.deliveredDate,
          }),
        });
      }
    });
    addedSunInvPartLocal.forEach((lotInfo: any) => {
      const { sourceLocation } = lotInfo;

      if (lotInfo.changed && lotInfo.counterValue) {
        serialNbr += 1;
        payload.push({
          sfid: lotInfo.sfid,
          externalId: lotInfo.externalId,
          lotNumber: lotInfo.lotNumber,
          productId: lotInfo.productId,
          quantity: lotInfo.counterValue.toString(),
          isLotNumber: lotInfo.isLotNumber,
          productNumber: lotInfo.productNumber,
          serialNumber: serialNbr.toString(),
          sourceLocation,
          actualPackContent: lotInfo.packContent ? lotInfo.packContent : '1',
          subInventoryCode: lotInfo?.subInventoryCode || '',
          ...(isERPOrder && {
            caseUsageId: lotInfo.caseUsageId,
            caseUsageExternalId: lotInfo.caseUsageExternalId,
            priceBookEntryId: lotInfo.priceBookEntryId,
            salesUnitOfMeasure: lotInfo.salesUnitOfMeasure,
            deliveredDate: lotInfo.deliveredDate,
          }),
        });
      }
    });
    if (isERPOrder) {
      const mutation = {
        id: usageDetails?.id,
        salesRepId: usageDetails?.salesRepId,
        accountId: usageDetails?.accountId,
        submitType: 'Update',
        requestedFrom:
          usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY ? 'BillOnly' : 'ShipAndBill',
        lineItems: payload,
      };
      resubmitOrderLineItems({
        variables: mutation,
      }).then((res: any): void => {
        if (res && res.data?.resubmitOrderDetails?.message === 'success') {
          handleClose();
          refetch();
        }
      });
    } else {
      const mutation = {
        caseId: usageDetails.caseId,
        caseExternalId: usageDetails.caseExternalId,
        salesRepId: usageDetails.salesRepId,
        formType: 'New',
        lineItems: payload,
        system: 'Web',
        version: process.env.REACT_APP_VERSION || '0.0.1',
      };
      createUpdateUsageLineItems({
        variables: mutation,
      }).catch(error => {
        setLoader(false);
        const errMsg = error?.message?.includes('Error:')
          ? error.message.split('Error:')[1]
          : error.message.split(':')[1] || 'Something went wrong. Please try again.';
        openSnackBar(NOTIFY_TYPE.ERROR, errMsg);
      });
    }
  };

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      <div>
        <Modal
          isOpen={isOpen}
          footer={[
            <div className="usage-modal-footer">
              {(loader || loadingSubInventory || loading) && <Spinner size="small" />}
              <Button
                label="Cancel"
                onClick={() => {
                  handleClose();
                  handleFilter(selectedFilters);
                }}
              />
              <Button label="Add" variant="brand" onClick={onAddClick} />
            </div>,
          ]}
          onRequestClose={() => {
            handleClose();
            handleFilter(selectedFilters);
          }}
          dismissOnClickOutside={false}
        >
          <div style={{ display: 'grid', padding: '0.75rem', gridTemplateColumns: '1fr 1fr' }}>
            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>Add Parts</div>
            <div style={{ textAlign: 'end' }}>
              <CartComponent count={catalogCount + subInvCount} color="black" textColor="white" />
            </div>
          </div>
          <div style={{ padding: '0rem 0.75rem 0rem 0.75rem' }}>
            <Tabs
              id="tabs-example-default"
              style={{ border: 'none' }}
              onSelect={(e: any): void => handleCompletedTab(e)}
            >
              {usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY && (
                <TabsPanel label="Repstock">
                  <div>
                    <div className="full-width_button-product" id="product-catalog">
                      {/* <Combobox
                        id="product"
                        events={{
                          onSelect: (event: any, data: any): void =>
                            handleSelect(data, 'productGroup', 'subInventory', 'repstock'),
                          onRequestRemoveSelectedOption: (event: any, data: any): void =>
                            handleSelect(data, 'productGroup', 'subInventory', 'repstock'),
                        }}
                        labels={{
                          label: `Select Product Group`,
                          placeholder: 'Select Product Group',
                          noOptionsFound: 'No data found',
                        }}
                        multiple
                        placeholder="Select"
                        options={productGroup || []}
                        hasInputSpinner={loadingProductGroup}
                        variant="readonly"
                        selection={repSelectedFilters ? repSelectedFilters.productGroup : []}
                      /> */}
                      {!loadingProductGroup && (
                        <AutocompleteSingleSelectPicker
                          items={productGroup}
                          setSelectedItems={(result: any): void => {
                            handleSelect(result, 'productGroup', 'subInventory', 'repstock');
                          }}
                          disabled={false}
                          selectedItems={
                            selectedFilters && selectedFilters?.productGroup
                              ? selectedFilters?.productGroup || []
                              : []
                          }
                          placeholder="Select Product Group"
                          label=""
                          withAddMenuButton={false}
                          // fetchItems={(searchText: string): string => searchText}
                          // filter
                          filterPicker
                          name="productGroup"
                          // autoSelectValue={false}
                        />
                      )}
                    </div>
                    <div className="catalog-input">
                      <Input
                        assistiveText={{ spinner: 'Field data is loading' }}
                        iconRight={
                          <InputIcon
                            assistiveText={{
                              icon: 'Search',
                            }}
                            name="search"
                            category="utility"
                          />
                        }
                        onChange={(_event: any, searchItem: string): void =>
                          searchSubInventory(_event, searchItem)
                        }
                        hasSpinner={loadingSubInventory}
                        id="unique-id-4"
                        placeholder="Search Part"
                      />
                    </div>
                    <div style={{ float: 'right', marginRight: '5px' }}>
                      {/* <div
                        className="inventory-return-lot-info-name"
                        style={{ display: 'inline-block' }}
                      >
                        Legend:
                      </div> */}
                      <div
                        style={{
                          display: 'inline-block',
                          margin: 'auto',
                          float: 'right',
                          marginRight: '5px',
                        }}
                      >
                        <span style={{ marginLeft: '10px' }}>
                          <Icon
                            // assistiveText={{ label: 'Announcement' }}
                            category="utility"
                            name="notification"
                            size="x-small"
                            style={{ fill: '#000084', marginRight: '5px' }}
                          />
                          Expiring in 90 days
                        </span>
                        <span style={{ marginLeft: '10px' }}>
                          <Icon
                            // assistiveText={{ label: 'Announcement' }}
                            category="utility"
                            name="clock"
                            size="x-small"
                            style={{ fill: '#FFAE42', marginRight: '5px' }}
                          />
                          30 days
                        </span>
                        <span style={{ marginLeft: '10px' }}>
                          <Icon
                            // assistiveText={{ label: 'Announcement' }}
                            category="utility"
                            name="warning"
                            size="x-small"
                            style={{ fill: '#D2042D', marginRight: '5px' }}
                          />
                          Expired
                        </span>
                        <span style={{ marginLeft: '10px', marginRight: '5px' }}>
                          <img
                            src={holdIconPath}
                            className="logo-image"
                            alt="company"
                            style={{ marginLeft: '8px', marginRight: '8px' }}
                          />
                          On Hold
                        </span>
                      </div>
                    </div>
                    <section className="slds-p-around_large-1">
                      {!loadingSubInventory &&
                        subInventoryLotInfo.map((subInv: any) => {
                          return (
                            <>
                              {subInv?.productDescription &&
                                subInv?.productDescription.map((subInvCode: any) => {
                                  return (
                                    <>
                                      <Collapsible
                                        open={subInvCode?.isChecked}
                                        handleFilterOpening={handleFilterOpening}
                                        title={`${subInvCode?.subInventoryCode} - ${subInvCode?.subInventoryName}`}
                                        code={subInvCode?.subInventoryCode}
                                      >
                                        {subInvCode?.value &&
                                          subInvCode?.value.map((item: any) => {
                                            return (
                                              <div className="inventory-return-lot-info-name">
                                                <div style={{ marginTop: '5px' }}>
                                                  {`${item.productNumber} ${
                                                    item.name ? item.name.toUpperCase() : '--'
                                                  }`}
                                                  {item.productOnHoldType === 'ITEM' ? (
                                                    <>
                                                      <img
                                                        src={holdIconPath}
                                                        className="logo-image"
                                                        alt="company"
                                                        style={{
                                                          marginLeft: '8px',
                                                          marginRight: '8px',
                                                        }}
                                                      />
                                                      {/* On Hold */}
                                                    </>
                                                  ) : (
                                                    ''
                                                  )}
                                                </div>
                                                {item.value &&
                                                  item.value.map((value: any) => {
                                                    const diffDays = getDateDifferenceInDays(
                                                      new Date(value.expirationDate).toString(),
                                                      new Date().toString()
                                                    );
                                                    let icon: any;
                                                    let color: any;
                                                    if (diffDays < 90) {
                                                      icon = 'notification';
                                                      color = '#000084';
                                                    }
                                                    if (diffDays < 30) {
                                                      icon = 'clock';
                                                      color = '#FFAE42';
                                                    }
                                                    if (diffDays < 0) {
                                                      icon = 'warning';
                                                      color = '#D2042D';
                                                    }
                                                    return (
                                                      <div
                                                        className="inventory-return-lot-info"
                                                        style={{
                                                          color:
                                                            Number(value.maxPermissibleValue) <= 0
                                                              ? 'lightgray'
                                                              : '',
                                                        }}
                                                      >
                                                        <span>
                                                          Lot #/Serial # - {value.lotNumber || '--'}
                                                          {item?.productOnHoldType === 'ITEM' ||
                                                          value?.productOnHoldType === 'ITEM' ? (
                                                            <img
                                                              src={holdIconPath}
                                                              className="logo-image"
                                                              alt="company"
                                                              style={{
                                                                marginLeft: '5px',
                                                                marginRight: '5px',
                                                                marginTop: '2px',
                                                                position: 'absolute',
                                                                // left: '42%',
                                                              }}
                                                            />
                                                          ) : (
                                                            ''
                                                          )}
                                                          {(item?.serialRecalled &&
                                                            item?.serialRecalled.includes(
                                                              value?.lotNumber
                                                            )) ||
                                                          (value?.serialRecalled &&
                                                            value?.serialRecalled.includes(
                                                              value?.lotNumber
                                                            )) ? (
                                                            // eslint-disable-next-line react/jsx-indent
                                                            <>
                                                              <img
                                                                src={holdIconPath}
                                                                className="logo-image"
                                                                alt="company"
                                                                style={{
                                                                  marginLeft: '5px',
                                                                  marginRight: '5px',
                                                                  marginTop: '2px',
                                                                  position: 'absolute',
                                                                  // left: '42%',
                                                                }}
                                                              />
                                                            </>
                                                          ) : (
                                                            ''
                                                          )}{' '}
                                                          <br />
                                                          <br />
                                                          Expiration Date:{' '}
                                                          {value.expirationDate
                                                            ? moment(value.expirationDate).format(
                                                                'MM/DD/YYYY'
                                                              )
                                                            : '--'}
                                                          {value?.expirationDate && icon && (
                                                            <Icon
                                                              // assistiveText={{ label: 'Announcement' }}
                                                              category="utility"
                                                              name={icon}
                                                              size="x-small"
                                                              style={{
                                                                fill: color,
                                                                marginLeft: '15px',
                                                              }}
                                                            />
                                                          )}
                                                        </span>

                                                        <Input
                                                          id="counter-input-3"
                                                          styleContainer={{ width: '110px' }}
                                                          styleInput={{ padding: '0px' }}
                                                          disabled={value.alreadyAdded}
                                                          minValue={0}
                                                          maxValue={value.maxPermissibleValue}
                                                          onChange={(
                                                            event: any,
                                                            data: { value: string; number: number }
                                                          ): void => {
                                                            handleSubInvCountalert(
                                                              event,
                                                              data,
                                                              value,
                                                              'Repstock'
                                                            );
                                                          }}
                                                          value={value.counterValue}
                                                          variant="counter"
                                                        />
                                                      </div>
                                                    );
                                                  })}
                                                <div />
                                              </div>
                                            );
                                          })}
                                      </Collapsible>
                                    </>
                                  );
                                })}
                            </>
                          );
                        })}
                    </section>
                  </div>
                </TabsPanel>
              )}
              {usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY && (
                <TabsPanel label="Customer">
                  <div>
                    <div className="full-width_button-product" id="product-catalog">
                      {/* <Combobox
                        id="product"
                        events={{
                          onSelect: (event: any, data: any): void =>
                            handleSelect(data, 'productGroup', 'subInventory', 'customer'),
                          onRequestRemoveSelectedOption: (event: any, data: any): void =>
                            handleSelect(data, 'productGroup', 'subInventory', 'customer'),
                        }}
                        labels={{
                          label: `Select Product Group`,
                          placeholder: 'Select Product Group',
                          noOptionsFound: 'No data found',
                        }}
                        multiple
                        placeholder="Select"
                        options={productGroup || []}
                        hasInputSpinner={loadingProductGroup}
                        variant="readonly"
                        selection={selectedFilters ? selectedFilters.productGroup : []}
                      /> */}
                      <AutocompleteSingleSelectPicker
                        items={productGroup}
                        setSelectedItems={(result: any): void => {
                          handleSelect(result, 'productGroup', 'subInventory', 'customer');
                        }}
                        disabled={false}
                        selectedItems={
                          selectedFilters && selectedFilters?.productGroup
                            ? selectedFilters?.productGroup || []
                            : []
                        }
                        placeholder="Select Product Group"
                        label=""
                        withAddMenuButton={false}
                        // fetchItems={(searchText: string): string => searchText}
                        // filter
                        filterPicker
                        name="productGroup"
                        // autoSelectValue={false}
                      />
                    </div>
                    <div className="catalog-input">
                      <Input
                        assistiveText={{ spinner: 'Field data is loading' }}
                        iconRight={
                          <InputIcon
                            assistiveText={{
                              icon: 'Search',
                            }}
                            name="search"
                            category="utility"
                          />
                        }
                        onChange={(_event: any, searchItem: string): void =>
                          searchSubInventory(_event, searchItem)
                        }
                        hasSpinner={loadingSubInventory}
                        id="unique-id-4"
                        placeholder="Search Part"
                      />
                    </div>
                    <div style={{ margin: 'auto', float: 'right', marginRight: '5px' }}>
                      {/* <div
                        className="inventory-return-lot-info-name"
                        style={{ display: 'inline-block' }}
                      >
                        Legend:
                      </div> */}
                      <div
                        style={{
                          display: 'inline-block',
                          margin: 'auto',
                          float: 'right',
                          marginRight: '5px',
                        }}
                      >
                        <span style={{ marginLeft: '10px' }}>
                          <Icon
                            // assistiveText={{ label: 'Announcement' }}
                            category="utility"
                            name="notification"
                            size="x-small"
                            style={{ fill: '#000084', marginRight: '5px' }}
                          />
                          Expiring in 90 days
                        </span>
                        <span style={{ marginLeft: '10px' }}>
                          <Icon
                            // assistiveText={{ label: 'Announcement' }}
                            category="utility"
                            name="clock"
                            size="x-small"
                            style={{ fill: '#FFAE42', marginRight: '5px' }}
                          />
                          30 days
                        </span>
                        <span style={{ marginLeft: '10px' }}>
                          <Icon
                            // assistiveText={{ label: 'Announcement' }}
                            category="utility"
                            name="warning"
                            size="x-small"
                            style={{ fill: '#D2042D', marginRight: '5px' }}
                          />
                          Expired
                        </span>
                        <span style={{ marginLeft: '10px', marginRight: '5px' }}>
                          <img
                            src={holdIconPath}
                            className="logo-image"
                            alt="company"
                            style={{ marginLeft: '8px', marginRight: '8px' }}
                          />
                          On Hold
                        </span>
                      </div>
                    </div>
                    <section className="slds-p-around_large-1">
                      {!loadingSubInventory &&
                        subInventoryLotInfo.map((subInv: any) => {
                          return (
                            <>
                              {subInv?.productDescription &&
                                subInv?.productDescription.map((item: any) => {
                                  return (
                                    <>
                                      {item?.value &&
                                        item?.value.map((part: any) => {
                                          return (
                                            <>
                                              <div className="inventory-return-lot-info-name">
                                                <div style={{ marginTop: '5px' }}>
                                                  {`${part.productNumber} ${
                                                    part.name ? part.name.toUpperCase() : '--'
                                                  }`}

                                                  {part.productOnHoldType === 'ITEM' ? (
                                                    <img
                                                      src={holdIconPath}
                                                      className="logo-image"
                                                      alt="company"
                                                      style={{
                                                        marginLeft: '8px',
                                                        marginRight: '8px',
                                                      }}
                                                    />
                                                  ) : (
                                                    ''
                                                  )}
                                                </div>
                                                {part.value &&
                                                  part.value.map((value: any) => {
                                                    const diffDays = getDateDifferenceInDays(
                                                      new Date(value.expirationDate).toString(),
                                                      new Date().toString()
                                                    );
                                                    let icon: any;
                                                    let color: any;
                                                    if (diffDays < 90) {
                                                      icon = 'notification';
                                                      color = '#000084';
                                                    }
                                                    if (diffDays < 30) {
                                                      icon = 'clock';
                                                      color = '#FFAE42';
                                                    }
                                                    if (diffDays < 0) {
                                                      icon = 'warning';
                                                      color = '#D2042D';
                                                    }
                                                    return (
                                                      <div
                                                        className="inventory-return-lot-info"
                                                        style={{
                                                          color:
                                                            Number(value.maxPermissibleValue) <= 0
                                                              ? 'lightgray'
                                                              : '',
                                                        }}
                                                      >
                                                        <span>
                                                          Lot #/Serial # - {value.lotNumber || '--'}
                                                          {item?.productOnHoldType === 'ITEM' ||
                                                          value?.productOnHoldType === 'ITEM' ? (
                                                            <img
                                                              src={holdIconPath}
                                                              className="logo-image"
                                                              alt="company"
                                                              style={{
                                                                marginLeft: '5px',
                                                                marginRight: '5px',
                                                                marginTop: '2px',
                                                                position: 'absolute',
                                                                // left: '42%',
                                                              }}
                                                            />
                                                          ) : (
                                                            ''
                                                          )}
                                                          {(item?.serialRecalled &&
                                                            item?.serialRecalled.includes(
                                                              value?.lotNumber
                                                            )) ||
                                                          (value?.serialRecalled &&
                                                            value?.serialRecalled.includes(
                                                              value?.lotNumber
                                                            )) ? (
                                                            // eslint-disable-next-line react/jsx-indent
                                                            <>
                                                              <img
                                                                src={holdIconPath}
                                                                className="logo-image"
                                                                alt="company"
                                                                style={{
                                                                  marginLeft: '5px',
                                                                  marginRight: '5px',
                                                                  marginTop: '2px',
                                                                  position: 'absolute',
                                                                  // left: '42%',
                                                                }}
                                                              />
                                                            </>
                                                          ) : (
                                                            ''
                                                          )}{' '}
                                                          <br />
                                                          Expiration Date:{' '}
                                                          {value.expirationDate
                                                            ? moment(value.expirationDate).format(
                                                                'MM/DD/YYYY'
                                                              )
                                                            : '--'}
                                                          {value.expirationDate && icon && (
                                                            <Icon
                                                              // assistiveText={{ label: 'Announcement' }}
                                                              category="utility"
                                                              name={icon}
                                                              size="x-small"
                                                              style={{
                                                                fill: color,
                                                                marginLeft: '15px',
                                                              }}
                                                            />
                                                          )}
                                                        </span>

                                                        <Input
                                                          id="counter-input-3"
                                                          styleContainer={{ width: '110px' }}
                                                          styleInput={{ padding: '0px' }}
                                                          disabled={value.alreadyAdded}
                                                          minValue={0}
                                                          maxValue={value.maxPermissibleValue}
                                                          onChange={(
                                                            event: any,
                                                            data: { value: string; number: number }
                                                          ): void => {
                                                            handleSubInvCountalert(
                                                              event,
                                                              data,
                                                              value,
                                                              'Customer'
                                                            );
                                                          }}
                                                          value={value.counterValue}
                                                          variant="counter"
                                                        />
                                                      </div>
                                                    );
                                                  })}
                                                <div />
                                              </div>
                                            </>
                                          );
                                        })}
                                    </>
                                  );
                                })}
                            </>
                          );
                        })}
                    </section>
                  </div>
                </TabsPanel>
              )}
              <TabsPanel label="Catalog">
                <div>
                  <div className="full-width_button-product" id="product-catalog">
                    {/* <Combobox
                      id="product"
                      events={{
                        onSelect: (event: any, data: any): void =>
                          handleSelect(data, 'productGroupCat', 'catalog', 'catalog'),
                        onRequestRemoveSelectedOption: (event: any, data: any): void =>
                          handleSelect(data, 'productGroupCat', 'catalog', 'catalog'),
                      }}
                      labels={{
                        label: `Select Product Group`,
                        placeholder: 'Select Product Group',
                        noOptionsFound: 'No data found',
                      }}
                      multiple
                      placeholder="Select"
                      options={productGroup || []}
                      hasInputSpinner={loadingProductGroup}
                      variant="readonly"
                      selection={selectedFilters ? selectedFilters.productGroupCat : []}
                    /> */}
                    <AutocompleteSingleSelectPicker
                      items={productGroup}
                      setSelectedItems={(result: any): void => {
                        handleSelect(result, 'productGroup', 'subInventory', 'catalog');
                      }}
                      disabled={false}
                      selectedItems={
                        selectedFilters && selectedFilters?.productGroup
                          ? selectedFilters?.productGroup || []
                          : []
                      }
                      placeholder="Select Product Group"
                      label=""
                      withAddMenuButton={false}
                      // fetchItems={(searchText: string): string => searchText}
                      // filter
                      filterPicker
                      name="productGroup"
                      // autoSelectValue={false}
                    />
                  </div>
                  <div className="catalog-input">
                    <Input
                      assistiveText={{ spinner: 'Field data is loading' }}
                      iconRight={
                        <InputIcon
                          assistiveText={{
                            icon: 'Search',
                          }}
                          name="search"
                          category="utility"
                        />
                      }
                      onChange={(_event: any, searchItem: string): void =>
                        searchCatalog(_event, searchItem)
                      }
                      hasSpinner={loading}
                      id="unique-id-4"
                      placeholder="Search Catalog"
                    />
                  </div>
                  <section className="slds-p-around_large-1">
                    {productList &&
                      productList.map((product: any) => (
                        <div
                          style={{
                            padding: '5px',
                            borderBottom: '1px solid #545857',
                            height: '3rem',
                            display: 'grid',
                            gridTemplateColumns: '4fr 1fr',
                          }}
                        >
                          <div style={{ marginTop: '5px' }}>
                            {`${product.productNumber} ${product.productdescription}`}
                            {product.productOnHold ? (
                              <>
                                <img
                                  src={holdIconPath}
                                  className="logo-image"
                                  alt="company"
                                  style={{ marginLeft: '8px', marginRight: '8px' }}
                                />
                                On Hold
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            <Input
                              id="counter-input-3"
                              styleInput={{ width: '110px' }}
                              minValue={0}
                              maxValue={MAX_QUANTITY}
                              onChange={(
                                event: any,
                                data: { value: string; number: number }
                              ): void => {
                                handleCountalert(event, data, product, null);
                              }}
                              value={product.counterValue}
                              variant="counter"
                            />
                          </div>
                        </div>
                      ))}
                  </section>
                </div>
              </TabsPanel>
            </Tabs>
          </div>
        </Modal>
      </div>
    </IconSettings>
  );
};

export default withRouter(AddEditLineItems);
