/* eslint-disable @typescript-eslint/no-explicit-any */
//* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars*/
/* eslint-disable react/jsx-curly-newline*/
/**
 * Module for header on Notification page
 * @module src/NotificationHeader
 * Module for header on Notification Center Page
 */
import React, { FC, ReactNode, useState, useCallback } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import { getSvgIcon } from '../../../util/utilityFunctions';

interface Props {
  handleNotificationSearch: (event: any, val: string) => void;
  handleToggleNotificationFilter: () => void;
  refetchNotifications?: () => void;
  handleActionDropdownSelect?: any;
}
const NotificationHeader: FC<Props> = ({
  handleNotificationSearch,
  handleToggleNotificationFilter,
  refetchNotifications,
  handleActionDropdownSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleModalToggle = useCallback((): void => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const iconPath = getSvgIcon('notificationIcon');

  const headerActions = (): ReactNode => (
    <PageHeaderControl>
      <Dropdown
        iconCategory="utility"
        iconSize="small"
        align="right"
        iconName="down"
        iconPosition="right"
        label="Actions"
        options={[
          {
            label: 'Mark as Unread',
            value: 'mark_as_unread',
          },
          {
            label: 'Mark as Read',
            value: 'mark_as_read',
          },
          {
            label: 'Mark Important',
            value: 'mark_important',
          },
          {
            label: 'Delete',
            value: 'delete',
          },
        ]}
        onSelect={(value: string): void => handleActionDropdownSelect(value)}
      />
    </PageHeaderControl>
  );

  const headerControls = (): ReactNode => (
    <>
      <PageHeaderControl className="slds-m-left_large">
        <div className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
          <div className="slds-col_padded">
            <Input
              iconRight={
                <InputIcon
                  assistiveText={{
                    icon: 'Search',
                  }}
                  name="search"
                  category="utility"
                />
              }
              id="search-case-id"
              placeholder="Search by Subject"
              onChange={(_event: any, searchItem: string): void =>
                handleNotificationSearch(_event, searchItem)
              }
            />
          </div>
        </div>
      </PageHeaderControl>
      <PageHeaderControl className="slds-m-left_large">
        <Button
          iconCategory="utility"
          className="slds-text-color_default"
          iconName="filterList"
          label="Filter"
          iconPosition="left"
          onClick={handleToggleNotificationFilter}
        />
      </PageHeaderControl>
    </>
  );

  return (
    <div>
      <IconSettings iconPath="/icons">
        <PageHeader
          className="notification_header"
          icon={<img src={iconPath} className="logo-image" alt="Notification Center" />}
          onRenderActions={headerActions}
          onRenderControls={headerControls}
          title="Notification Center"
          label=""
          truncate
          variant="object-home"
        />
      </IconSettings>
    </div>
  );
};

export default NotificationHeader;
