/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from 'react';
import moment from 'moment';
import Icon from '@salesforce/design-system-react/components/icon';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import Input from '@salesforce/design-system-react/components/input';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './index.scss';
import { useSelector } from 'react-redux';
import { MAX_QUANTITY, USAGE_TYPE, NOTIFY_TYPE } from '../../../../../util/constants';
import SnackBar from '../../../../Shared/SnackBar';
import useSnackBar from '../../../../../util/customHooks/useSnackBar';
import { GetActualQty, getHoldIcon, getSvgIcon } from '../../../../../util/utilityFunctions';
import OrderStatusCell from '../../../../Orders/OrderDataTable/OrderStatusCell';
import { getUserInfo } from '../../../../../store/ducks/userInfo';
import InputWithAutoSuggestion from './InputWithAutoSuggestion';

interface Props {
  usageDetails?: any;
  viewOnly: boolean;
  isAssignUser: boolean;
  items: any[];
  setItems: any;
  isERPOrder: boolean;
  isERPOrderFailed: boolean;
  orderDetails?: any;
  partListConfig?: any;
  type: string;
  saveParts: any;
}

const PartsTabularDetails: FC<Props> = ({
  items,
  usageDetails,
  viewOnly,
  isAssignUser,
  setItems,
  isERPOrder,
  isERPOrderFailed,
  orderDetails,
  partListConfig,
  type,
  saveParts,
}) => {
  const userInfo = useSelector(getUserInfo);
  const [openSaveConfirmModal, setOpenSaveConfirmModal] = useState(false);
  const [modalUniqueId, setModalUniqueId] = useState('');
  const handleDragOnHand = (result: any): void => {
    if (!result.destination) {
      return;
    }
    const listItems = [...items];
    const reorderedItem = listItems.splice(result.source.index, 1);
    listItems.splice(result.destination.index, 0, ...reorderedItem);
    setItems(listItems);
    saveParts(true);
  };
  const { open, notification, openSnackBar } = useSnackBar();
  const handleUpdates = (): void => {
    const payload: Array<any> = [];
  };

  const savePartsCheck = (): void => {
    setOpenSaveConfirmModal(false);
    const listItems = items.map((i: any) => {
      if (Number(i.quantity) === 0 && i.uniqueId === modalUniqueId) {
        return {
          ...i,
          quantity: '',
          deleted: true,
        };
      }
      return i;
    });
    setItems(listItems || []);
    saveParts(true);
  };

  const handleCount = (
    event: any,
    data: { number: number },
    id: string,
    content: number,
    name: string
  ): void => {
    const duplicateParts = [];
    const listItems = items.map((i: any) => {
      if (i.uniqueId === id) {
        const product: any = items?.find(
          (item: any) =>
            item.lotNumber === i.lotNumber &&
            item.productNumber === i.productNumber &&
            item.externalId !== i.externalId &&
            item.sourceLocation === i.sourceLocation
        );
        const sUOM = name && name.toUpperCase().charAt(0);
        let qty = data.number > i.maxQuantity ? i.maxQuantity : data.number;
        if (
          !i.isValidLotSerialCombination &&
          i.iscatalog &&
          usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY
        ) {
          qty = data.number;
          // actualqty = data.number;
        }
        const actualqty = GetActualQty(
          usageDetails.diplayOrderType,
          name,
          i.salesUnitOfMeasure,
          i.packContent,
          data.number
        );

        if (
          product &&
          duplicateParts.length === 0 &&
          i.isValidLotSerialCombination &&
          !product.isValidLotSerialCombination
        ) {
          const itemToUpdate = product.iscatalog ? product : i;
          itemToUpdate.isLotValueUpdated = true;
          itemToUpdate.caseUsageSubInventoryCode = i.caseUsageSubInventoryCode;
          duplicateParts.push(product);
        }

        return {
          ...i,
          quantity: qty.toString(),
          actualQuantity: parseFloat(actualqty.toString()).toFixed(2) || '',
        };
      }
      return i;
    });

    setItems(listItems);
    if (data.number === 0) {
      setOpenSaveConfirmModal(true);
      setModalUniqueId(id);
    } else {
      saveParts(true);
    }
  };

  const handleCountalert = (
    event: any,
    data: { number: number },
    id: string,
    content: number,
    name: string,
    onHold: string
  ): void => {
    if (onHold) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );
    }
    handleCount(event, data, id, content, name);
  };
  const handleWasted = (event: any, id: string): void => {
    const listItems = items.map((i: any) => {
      if (items.length === 1 && Number(i.quantity) === 0) {
        saveParts(false);
      } else {
        saveParts(true);
      }
      if (i.uniqueId === id) {
        return {
          ...i,
          wasted: event.target.checked,
          overridePrice: i?.overridePrice,
          priceOverride: i?.priceOverride,
          priceOverrideType: event.target.checked ? 'DOLLAR' : i?.priceOverrideType,
          usgPrice: i.contractPrice ? i?.contractPrice : i?.listPrice,
          isLotValueUpdated: true,
        };
      }
      return i;
    });
    setItems(listItems || []);
    // saveParts(true);
  };

  const handleSelectDropdownItem = (data: any, id: any): void => {
    const listItems = items.map((i: any) => {
      let sourceLocationChanged = false;
      if (i.uniqueId === id) {
        if (i.sourceLocation && i.sourceLocation !== data.selection[0]?.value) {
          sourceLocationChanged = true;
        }
        const product: any = items?.find(
          (value: any) =>
            value.lotNumber === i.lotNumber &&
            value.productNumber === i.productNumber &&
            value.externalId !== i.externalId &&
            value.sourceLocation === i.sourceLocation
        );

        if (product && product.iscatalog && !product.isValidLotSerialCombination) {
          product.isLotValueUpdated = true;
          product.caseUsageSubInventoryCode = i.caseUsageSubInventoryCode;
        }

        return {
          ...i,
          previousLotNumber: i?.lotNumber?.trim(),
          isLotValueUpdated: i?.lotNumber !== null && i?.lotNumber !== '',
          sourceLocation: data.selection,
          caseUsageSubInventoryCode: sourceLocationChanged ? '' : i.caseUsageSubInventoryCode,
          lotNumber: sourceLocationChanged ? '' : i.lotNumber,
        };
      }
      return i;
    });

    setItems(listItems || []);
    listItems?.map((i: any) => {
      if (items.length === 1 && Number(i.quantity) === 0) {
        saveParts(false);
      } else {
        saveParts(true);
      }
    });
    // saveParts(true);
    // handleUpdates();
  };
  const handleSelect = (event: any, data: any, id: string): void => {
    const listItems = items.map((i: any) => {
      if (i.uniqueId === id) {
        const product: any = items?.find(
          (value: any) =>
            (value.lotNumber === i.lotNumber ||
              value.lotNumber === data?.selection?.[0]?.lotNumber) &&
            value.productNumber === i.productNumber &&
            value.externalId !== i.externalId &&
            value.sourceLocation === i.sourceLocation
        );

        let maxQuantity = MAX_QUANTITY;
        const qty = i.quantity;
        const actualqty = i.actualQuantity;
        if (data.selection && data.selection[0]) {
          const { availableqty, lotNumber } = data.selection[0];
          if (lotNumber === i.lotNumber) {
            // Edit Same Lot
            maxQuantity = Number(availableqty) + Number(i.originalQty);
          } else {
            // Selected New Lot
            maxQuantity = Number(availableqty);
            // qty = 0; // Initiate
            // actualqty = 0;
          }
        }
        if (product) {
          product.isLotValueUpdated = true;
          product.caseUsageSubInventoryCode = i.caseUsageSubInventoryCode;
        }
        if (
          usageDetails?.status === 'Failed' &&
          i?.iscatalog === true &&
          data.selection &&
          data.selection[0]
        ) {
          i.isLotValueUpdated = true;
        }
        return {
          ...i,
          selectedLot: data.selection || [],
          previousLotNumber: i?.lotNumber?.trim(),
          maxQuantity,
          quantity: qty,
          actualQuantity: actualqty,
        };
      }
      return i;
    });
    setItems(listItems || []);
    if (data.number !== 0) {
      saveParts(true);
    }

    listItems.forEach((i: any) => {
      if (i.quantity === 0 && i.uniqueId === id) {
        setOpenSaveConfirmModal(true);
        setModalUniqueId(id);
      }
    });
    if (data.number !== 0) {
      saveParts(true);
    }
  };
  const getBgColorCode = (expiresInDays: number): string => {
    if (expiresInDays <= 20) {
      return 'red';
    }
    if (expiresInDays <= 40) {
      return 'orange';
    }
    if (expiresInDays <= 60) {
      return 'yellow';
    }
    return 'white';
  };
  const getTextColorCode = (expiresInDays: number): string => {
    if (expiresInDays > 40 && expiresInDays <= 60) {
      return 'black';
    }
    return 'white';
  };
  const holdIconPath = getHoldIcon();
  const catalogInd = getSvgIcon('catalog');
  const handleDelete = (lineItem: any): void => {
    // empty/clear quantity, it will delete ib Backend
    let deletedItem: any = {};

    const listItems = items.map((i: any) => {
      if (i.uniqueId === lineItem.uniqueId) {
        deletedItem = i;
        return {
          ...i,
          quantity: '',
          deleted: true,
        };
      }

      if (
        i.lotNumber === lineItem.lotNumber &&
        i.productNumber === lineItem.productNumber &&
        i.iscatalog &&
        !i.isValidLotSerialCombination &&
        i.sourceLocation === lineItem.sourceLocation &&
        Number(i.quantity) > 0
      ) {
        return {
          ...i,
          caseUsageSubInventoryCode: deletedItem ? deletedItem?.caseUsageSubInventoryCode : '',
          isLotValueUpdated: true,
        };
      }

      return i;
    });
    setItems(listItems || []);
    saveParts(true);
  };
  const gridTemplateCols = (): string => {
    const configured = partListConfig.length <= 6;
    const billOnlyTemplate = configured ? 'bill-only-cols-configured' : 'bill-only-cols';
    const shipAndBillTemplate = configured ? 'ship-bill-cols-configured' : 'ship-bill-cols';
    const gridTemplate =
      usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY
        ? billOnlyTemplate
        : shipAndBillTemplate;
    if (isERPOrder) {
      if (isERPOrderFailed) {
        return `erp-failed-${gridTemplate}`;
      }
      return `erp-processed-${gridTemplate}`;
    }
    return gridTemplate;
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSuggestionSelect = (suggestion: any, lineItem: any) => {
    const listItems = items.map((i: any) => {
      if (i.uniqueId === lineItem.uniqueId) {
        const product: any = items?.find(
          (value: any) =>
            value.lotNumber === i.lotNumber &&
            value.productNumber === i.productNumber &&
            value.externalId !== i.externalId &&
            value.sourceLocation === i.sourceLocation
        );

        if (product && product.iscatalog && !product.isValidLotSerialCombination) {
          product.isLotValueUpdated = true;
          product.caseUsageSubInventoryCode = lineItem.caseUsageSubInventoryCode;
        }

        return {
          ...i,
          previousLotNumber: i?.lotNumber?.trim(),
          isLotValueUpdated: i?.lotNumber?.trim() !== suggestion?.label?.trim(),
          lotNumber: suggestion?.label?.trim(),
          caseUsageSubInventoryCode: Number(suggestion.availableqty)
            ? suggestion.subInventoryCode
            : '',
        };
      }
      return i;
    });
    setItems(listItems || []);
    saveParts(true);
  };

  const updateLotNumber = (event: any, id: string): void => {
    const listItems = items.map((i: any) => {
      if (i.uniqueId === id) {
        return {
          ...i,
          previousLotNumber: i?.lotNumber?.trim(),
          isLotValueUpdated: i?.lotNumber?.trim() !== event?.target?.value?.trim(),
          lotNumber: event?.target?.value?.trim(),
        };
      }
      return i;
    });
    setItems(listItems || []);
    saveParts(true);
  };

  return (
    <>
      <div className="parts-table-container marginAdjust">
        <SnackBar open={open} notification={notification} />
        {openSaveConfirmModal && (
          <Modal
            className="delete-preset-modal default-modal_header default-modal_footer"
            isOpen={openSaveConfirmModal}
            onRequestClose={(): void => setOpenSaveConfirmModal(false)}
            ariaHideApp={false}
            footer={[
              <Button label="Yes" variant="brand" onClick={savePartsCheck} key="save_yes" />,
              <Button
                label="No"
                onClick={(): void => setOpenSaveConfirmModal(false)}
                key="save_no"
              />,
            ]}
            heading="Confirmation"
          >
            <div className="slds-text-align--center">
              <div>
                <h1 className="slds-m-around--large ">
                  {
                    'Part(s) will be removed as the quantity is 0. Are you sure you want to proceed?'
                  }
                </h1>
              </div>
            </div>
          </Modal>
        )}
        <DragDropContext onDragEnd={handleDragOnHand}>
          <Droppable droppableId="items">
            {(provided: any): any => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <div className={`parts-table-header ${gridTemplateCols()}`}>
                  {partListConfig.includes('part_number') && (
                    <span style={{ textAlign: 'left' }}>Part</span>
                  )}
                  {usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY &&
                    partListConfig.includes('lotSerial_number') && (
                      <span className="widthAdjust">Lot/Serial</span>
                    )}

                  {partListConfig.includes('qty') && <span className="widthAdjust">Qty</span>}
                  {usageDetails?.orderType === 'ZOR' && orderDetails?.status !== 'Closed' && (
                    <span>Status</span>
                  )}
                  {partListConfig.includes('actual_qty') && <span>Actual Qty</span>}
                  {usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY &&
                    partListConfig.includes('wasted') && <span>Wasted</span>}
                  {usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY && (
                    <span>Source Location</span>
                  )}
                  {partListConfig.includes('expiration_date') && <span>Expiration Date</span>}
                  {usageDetails.diplayOrderType !== USAGE_TYPE.BILL_ONLY &&
                    isERPOrder &&
                    !isERPOrderFailed && <span>Tracking Number</span>}
                  <span>Action</span>
                  <span>Sequence Parts</span>
                </div>
                {items &&
                  items.map((lineItem: any, index: number) => {
                    // const sourceLocation =
                    //   userInfo?.businessunit === 'Sports Medicine' &&
                    //   typeof lineItem.sourceLocation !== 'string'
                    //     ? 'Repstock'
                    //     : lineItem.sourceLocation;

                    let isLineItemExpired: any = false;
                    if (lineItem.expirationDate) {
                      const expirationDate = moment(lineItem.expirationDate).format('MM/DD/YYYY');
                      const currentDate = moment().format('MM/DD/YYYY');
                      if (new Date(expirationDate) < new Date(currentDate)) {
                        if (usageDetails.eventDateTime) {
                          const surgeryDate = moment(usageDetails?.eventDateTime).format(
                            'MM/DD/YYYY'
                          );
                          if (new Date(expirationDate) < new Date(surgeryDate)) {
                            isLineItemExpired = true;
                          }
                        } else {
                          isLineItemExpired = true;
                        }
                      }
                    }
                    return !lineItem.deleted ? (
                      <Draggable
                        key={lineItem.uniqueId}
                        draggableId={lineItem.uniqueId}
                        index={index}
                      >
                        {(provided: any): any => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <div className={`parts-table-row ${gridTemplateCols()}`}>
                              {partListConfig.includes('part_number') && (
                                <p
                                  className="vertical-align-middle"
                                  style={{
                                    color:
                                      !lineItem?.isValidLotSerialCombination &&
                                      lineItem.iscatalog &&
                                      lineItem.lotValidation === 'true' &&
                                      usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY &&
                                      type !== 'Failed' &&
                                      type !== 'Processed' &&
                                      orderDetails?.status !== 'Failed'
                                        ? 'red'
                                        : '#080707',
                                    overflowWrap: 'break-word',
                                    textAlign: 'left',
                                  }}
                                >
                                  {`${lineItem.productNumber} - ${lineItem.productdescription}`}
                                  {lineItem?.productOnHold ? (
                                    <img
                                      src={holdIconPath}
                                      className="logo-image"
                                      alt="company"
                                      style={{ marginLeft: '8px', marginRight: '8px' }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                  {lineItem?.iscatalog &&
                                    usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY && (
                                      <img
                                        src={catalogInd}
                                        className="slds-tooltip-trigger"
                                        alt="catalog part"
                                        style={{
                                          marginLeft: '25px',
                                          marginRight: '8px',
                                          cursor: 'default',
                                        }}
                                        title="This part was selected from the Catalog"
                                      />
                                    )}
                                </p>
                              )}
                              {usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY &&
                                partListConfig.includes('lotSerial_number') && (
                                  <div className="row-control-lot widthAdjust">
                                    {(lineItem?.iscatalog &&
                                      type !== 'Failed' &&
                                      orderDetails?.status !== 'Failed') ||
                                    (lineItem?.iscatalog &&
                                      type === 'Failed' &&
                                      orderDetails?.status !== 'Failed' &&
                                      lineItem.lotValidation === 'false') ? (
                                      <InputWithAutoSuggestion
                                        disabled={
                                          viewOnly || !lineItem.sourceLocation || isAssignUser
                                        }
                                        lineItem={lineItem}
                                        handleSuggestionSelect={handleSuggestionSelect}
                                      />
                                    ) : (
                                      <Combobox
                                        disabled={
                                          viewOnly ||
                                          isAssignUser ||
                                          !lineItem.sourceLocation ||
                                          lineItem.sourceLocation === 'Catalog'
                                        }
                                        id="lotNumber"
                                        events={{
                                          onSelect: (event: any, data: any): void =>
                                            handleSelect(event, data, lineItem.uniqueId),
                                        }}
                                        labels={{
                                          placeholder: 'Select',
                                          noOptionsFound: 'No data found',
                                        }}
                                        placeholder="Select"
                                        options={
                                          lineItem.sourceLocation && lineItem?.lotOptions
                                            ? lineItem.lotOptions
                                            : [] || []
                                        }
                                        selection={lineItem.selectedLot || []}
                                        variant="readonly"
                                      />
                                    )}
                                  </div>
                                )}

                              {partListConfig.includes('qty') && (
                                <div className="row-control widthAdjust">
                                  <Input
                                    disabled={viewOnly || isAssignUser}
                                    id="counter-input"
                                    minValue={0}
                                    maxValue={
                                      (lineItem.iscatalog && lineItem.lotValidation === 'false') ||
                                      (lineItem.iscatalog &&
                                        !lineItem.isValidLotSerialCombination &&
                                        orderDetails?.status !== 'Failed')
                                        ? MAX_QUANTITY
                                        : Number(lineItem.maxQuantity)
                                    }
                                    value={lineItem.quantity}
                                    variant="counter"
                                    onChange={(event: any, data: { number: number }): void => {
                                      handleCountalert(
                                        event,
                                        data,
                                        lineItem.uniqueId,
                                        lineItem.packContent || 1,
                                        lineItem.sellingUoM,
                                        lineItem.productOnHold
                                      );
                                    }}
                                  />
                                </div>
                              )}
                              {usageDetails.orderType === 'ZOR' &&
                                orderDetails?.status !== 'Closed' && (
                                  <div className="row-control" style={{ marginTop: '8px' }}>
                                    <OrderStatusCell
                                      item={{
                                        status: orderDetails?.lineItems[index]?.partStatus,
                                        showBackorderdWarning:
                                          Number(
                                            orderDetails?.lineItems[index]?.backorderedQuantity
                                          ) > 0,
                                      }}
                                    />
                                  </div>
                                )}
                              {partListConfig.includes('actual_qty') && (
                                <div className="vertical-align-middle widthAuto">
                                  <p>
                                    {parseFloat(lineItem.actualQuantity).toFixed(2) !== 'Infinity'
                                      ? parseFloat(lineItem.actualQuantity).toFixed(2)
                                      : 0}
                                  </p>
                                </div>
                              )}
                              {usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY &&
                                partListConfig.includes('wasted') && (
                                  <div className="widthAuto">
                                    <Checkbox
                                      labels={{
                                        toggleEnabled: '',
                                        toggleDisabled: '',
                                      }}
                                      variant="toggle"
                                      disabled={viewOnly || isAssignUser}
                                      checked={lineItem.wasted}
                                      onChange={(e: any): void => {
                                        handleWasted(e, lineItem.uniqueId);
                                      }}
                                    />
                                  </div>
                                )}
                              {usageDetails.diplayOrderType === USAGE_TYPE.BILL_ONLY && (
                                <div className="row-control widthAuto">
                                  {lineItem.sourceLocation &&
                                  (!lineItem.iscatalog ||
                                    (lineItem.iscatalog &&
                                      isERPOrderFailed &&
                                      orderDetails?.status !== 'Failed')) &&
                                  typeof lineItem.sourceLocation === 'string' ? (
                                    <div className="vertical-align-middle">
                                      {lineItem.sourceLocation}
                                    </div>
                                  ) : (
                                    <Combobox
                                      disabled={viewOnly || isAssignUser}
                                      id="sourceLocation"
                                      events={{
                                        onSelect: (_event: any, data: any): void =>
                                          handleSelectDropdownItem(data, lineItem.uniqueId),
                                      }}
                                      labels={{
                                        placeholder: 'Select',
                                        noOptionsFound: 'No data found',
                                      }}
                                      placeholder="Select"
                                      options={[
                                        { value: 'Repstock', label: 'Repstock' },
                                        {
                                          value: 'Customer',
                                          label: 'Customer',
                                          disabled: false,
                                        },
                                      ]}
                                      selection={
                                        typeof lineItem.sourceLocation === 'string'
                                          ? [
                                              {
                                                label: lineItem.sourceLocation,
                                                value: lineItem.sourceLocation,
                                              },
                                            ]
                                          : lineItem.sourceLocation || []
                                      }
                                      variant="readonly"
                                    />
                                  )}
                                </div>
                              )}
                              {partListConfig.includes('expiration_date') && (
                                <div
                                  className="vertical-align-middle widthAuto"
                                  style={{ color: isLineItemExpired ? 'red' : '#080707' }}
                                >
                                  {(lineItem.selectedLot &&
                                    lineItem.selectedLot[0]?.expirationDate) ||
                                  lineItem.expirationDate
                                    ? moment(
                                        lineItem?.selectedLot?.[0]?.expirationDate
                                          ? lineItem?.selectedLot?.[0]?.expirationDate
                                          : lineItem.expirationDate
                                      ).format('MM/DD/YYYY')
                                    : ''}
                                </div>
                              )}
                              {usageDetails.diplayOrderType !== USAGE_TYPE.BILL_ONLY &&
                                isERPOrder &&
                                !isERPOrderFailed && (
                                  <div className="vertical-align-middle widthAuto">
                                    {lineItem.trackingNumber || '--'}
                                  </div>
                                )}
                              <div
                                className="vertical-align-middle"
                                style={{ paddingLeft: '10px' }}
                              >
                                <Button
                                  disabled={viewOnly || isAssignUser}
                                  iconCategory="utility"
                                  iconName="delete"
                                  iconSize="large"
                                  iconVariant="bare"
                                  variant="icon"
                                  onClick={(): void => {
                                    handleDelete(lineItem);
                                  }}
                                />
                              </div>
                              <div
                                className="vertical-align-middle"
                                // style={{ paddingLeft: '40px' }}
                              >
                                <Icon
                                  className="line-item-dnd"
                                  assistiveText={{ label: 'Drag to Adjust' }}
                                  category="utility"
                                  name="drag_and_drop"
                                  size="x-small"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ) : null;
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

export default PartsTabularDetails;
