import { Form } from '../../components/Shared/FormEngine';

const EventTransfer: Form = [
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'tabNavigator',
        fieldLabel: 'Tab Navigator',
        fieldType: 'tabNavigator',
        required: false,
        optionsPlaceHolder: '',
        optionsDynamic: '',
        isShow: true,
        optionsStatic: [
          { value: 'details', label: 'Details' },
          { value: 'parts', label: 'Parts' },
        ],
        optionsAutoSelected: false,
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'account',
        fieldLabel: 'Account',
        fieldType: 'static',
        required: true,
        optionsPlaceHolder: 'Pick an Account',
        isShow: true,
        optionsDynamic: 'getSourceAccounts',
        optionsAutoSelected: true,
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'event',
        fieldLabel: 'Event',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: 'Pick an event',
        isShow: true,
        optionsDynamic: 'getUpcomingEventsList',
        optionsAutoSelected: true,
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'Attachments',
        fieldLabel: 'Attachments',
        isShow: true,
        fieldType: 'Attachments',
        required: false,
        optionsAutoSelected: false,
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'comments',
        fieldLabel: 'Comments',
        fieldType: 'comments',
        required: false,
        optionsAutoSelected: false,
        isShow: true,
        characterLimit: 32000,
        optionsPlaceHolder: 'Enter comments for new event',
        showOnWeb: true,
        permissionSets: [],
        showTooltip: true,
      },
    ],
  },
];

export default EventTransfer;
