import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_SALES_REPS_SUBMIT_PERMISSOIN = gql`
  query getAllSalesRepsWithSubmitPermission($filters: OPSListFilters) {
    getAllSalesRepsWithSubmitPermission(filters: $filters) {
      id
      value
      lastUpdatedDate
      lastUpdatedBy
      reasons {
        permissionExternalId
        permissionsfid
        reasonCodeForHold
        isinventoryhold
        holdReason
        dateInitiated
        __typename
      }
    }
  }
`;
