/*
 * Filtered Procedure Types GraphQL specification
 * @module src/graphql/getFilteredProcedureTypes
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Filtered Procedure Types GraphQL API query */
export const GET_FILTERED_PROCEDURE_TYPES = gql`
  query getProcedureTypes {
    getProcedureTypes {
      id
      name
    }
  }
`;
