/**
 * Surgery Form JSON.
 * @module src/util/FormJson/surgery
 */
import { Form } from '../../components/Shared/FormEngine';

const showGo: Form = [
  {
    kind: 'field',
    name: 'topInfo',
    fieldLabel: '*Required fields',
    fieldType: 'text',
    required: true,
    optionsAutoSelected: false,
    isShow: true,
    permissionSets: [],
    date: false,
    time: false,
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'salesRep',
        fieldLabel: 'Sales Rep',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        isShow: true,
        optionsPlaceHolder: 'Sales Rep',
        optionsDynamic: 'getSalesReps',
        optionsDependentField: [],
        optionsAutoSelected: true,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'tbaAccount',
        fieldLabel: 'Account Types',
        fieldType: 'radio',
        isShow: true,
        required: false,
        optionsPlaceHolder: 'Account Types',
        optionsDynamic: 'getTbaAccounts',
        optionsDependentField: [],
        optionsStatic: [
          { value: 'My Accounts', label: 'My Accounts', id: 'My Accounts' },
          { value: 'TBA Accounts', label: 'TBA Accounts', id: 'TBA Accounts' },
        ],
        optionsAutoSelected: false,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'account',
        fieldLabel: 'Account',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        isShow: true,
        optionsPlaceHolder: 'Account',
        optionsDynamic: 'getAccounts',
        optionsDependentField: ['salesRep'],
        optionsAutoSelected: false,
        permissionSets: [],
      },

      {
        kind: 'field',
        name: 'eventDate',
        fieldLabel: 'Start Date/Time',
        fieldType: 'dateTimePicker',
        required: true,
        isShow: true,
        optionsAutoSelected: false,
        permissionSets: [],
        date: true,
        time: true,
      },
      {
        characterLimit: 20,
        fieldLabel: 'Duration (hours)',
        fieldType: 'input',
        kind: 'field',
        name: 'eventDuration',
        optionsAutoSelected: false,
        optionsDependentField: [],
        optionsDynamic: '',
        optionsPlaceHolder: 'eventDuration',
        isShow: true,
        permissionSets: [],
        required: true,
        keypadType: 'number-pad',
      },
      {
        kind: 'field',
        name: 'eventDescription',
        fieldLabel: 'Event Description',
        fieldType: 'textArea',
        required: true,
        isShow: true,
        optionsAutoSelected: false,
        optionsPlaceHolder: '',
        optionsDynamic: '',
        optionsDependentField: [],
        permissionSets: [],
        characterLimit: 32000,
      },
    ],
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'contact',
        fieldLabel: 'Contact',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: false,
        isShow: true,
        optionsAutoSelected: false,
        optionsPlaceHolder: 'Contact',
        optionsDynamic: 'getSurgeons',
        optionsDependentField: ['account'],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'coveringRep',
        fieldLabel: 'Covering Rep',
        fieldType: 'selectMultiDynamicPicker',
        required: false,
        isShow: true,
        optionsPlaceHolder: 'Covering Rep',
        optionsDynamic: 'getCoveringReps',
        // optionsDependentField: ['account'],
        optionsDependentField: ['salesRep'],
        optionsAutoSelected: false,
        permissionSets: [],
      },
    ],
  },
  {
    kind: 'field',
    name: 'opportunity',
    fieldLabel: 'Opportunity',
    fieldType: 'selectSingleDynamicAutoCompletePicker',
    required: true,
    isShow: true,
    optionsPlaceHolder: 'Opportunity',
    optionsDynamic: 'getOpportunity',
    optionsDependentField: ['account'],
    optionsAutoSelected: true,
    permissionSets: [],
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'eventLocation',
        fieldLabel: 'Event Location',
        fieldType: 'radio',
        isShow: true,
        required: false,
        optionsPlaceHolder: 'Event Location',
        optionsStatic: [
          {
            label: 'Ship To Address',
            value: 'Ship To Address',
            id: 'Ship To Address',
          },
          {
            label: 'Other Address',
            value: 'Other Address',
            id: 'Other Address',
          },
        ],
        optionsDependentField: [],
        optionsAutoSelected: false,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'locationDescription',
        fieldLabel: 'Location Description',
        fieldType: 'textArea',
        required: true,
        isShow: true,
        optionsAutoSelected: false,
        optionsPlaceHolder: '',
        optionsDynamic: '',
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'street1',
        fieldLabel: 'Street 1',
        fieldType: 'textBox',
        required: true,
        isShow: true,
        optionsAutoSelected: false,
        optionsPlaceHolder: '',
        optionsDynamic: '',
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'street2',
        fieldLabel: 'Street 2',
        fieldType: 'textBox',
        required: false,
        isShow: true,
        optionsAutoSelected: false,
        optionsPlaceHolder: '',
        optionsDynamic: '',
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'country',
        fieldLabel: 'Country',
        // fieldType: 'selectSingleDynamicAutoCompletePicker',
        fieldType: 'input',
        required: false,
        isShow: true,
        optionsPlaceHolder: 'Country',
        optionsDynamic: '',
        optionsDependentField: [],
        optionsAutoSelected: false,
        permissionSets: [],
        optionsStatic: [
          {
            label: 'US',
            value: 'US',
            id: 'US',
          },
        ],
      },

      {
        kind: 'field',
        name: 'city',
        fieldLabel: 'City',
        // fieldType: 'selectSingleDynamicAutoCompletePicker',
        fieldType: 'textBox',
        required: true,
        isShow: true,
        optionsPlaceHolder: 'City',
        optionsDynamic: '',
        optionsDependentField: [],
        optionsAutoSelected: false,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'state',
        fieldLabel: 'State',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        // fieldType: 'textArea',
        required: true,
        isShow: true,
        optionsPlaceHolder: 'State',
        optionsDynamic: 'getStates',
        optionsDependentField: [],
        optionsAutoSelected: false,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'zipCode',
        fieldLabel: 'Zip Code',
        // fieldType: 'selectSingleDynamicAutoCompletePicker',
        fieldType: 'textBox',
        required: true,
        isShow: true,
        optionsPlaceHolder: 'Zip Code',
        optionsDynamic: '',
        optionsDependentField: [],
        optionsAutoSelected: false,
        permissionSets: [],
        keypadType: 'default',
      },
    ],
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'comments',
        fieldLabel: 'Comments',
        fieldType: 'comments',
        required: false,
        isShow: true,
        optionsAutoSelected: false,
        characterLimit: 32000,
        permissionSets: [],
      },
    ],
  },
];

/** Exporting surgery form json */
export default showGo;
