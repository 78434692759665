/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Combobox from '@salesforce/design-system-react/components/combobox';
import EventStatusBadge from '../../../Shared/EventStatusBadge';
import './index.scss';
import { RETURNS_WORKFLOW_ACTION_OPTION, WORKFLOW_ACTION } from '../../../../util/constants';

interface Action {
  workflowAction: string;
  userworkflowAction?: [];
  footerRow?: boolean;
  lineVisualIndicator?: string;
}
export interface Props {
  children?: FC;
  item?: Action;
  viewOnly: boolean;
  handleUserWorkflowAction: (item: any) => void;
}

const InventoryApproveRejectDataCell: FC<Props> = ({
  item = {
    workflowAction: '',
    userworkflowAction: [],
    lineVisualIndicator: '',
  },
  viewOnly,
  handleUserWorkflowAction,
}) => {
  const { workflowAction, userworkflowAction, footerRow, lineVisualIndicator } = item;
  const [userAction, setUserAction] = useState(userworkflowAction || []);
  const handleSelectDropdownItem = (data: any): void => {
    setUserAction(data.selection);
    const obj = {
      ...item,
      userworkflowAction: data.selection,
    };
    handleUserWorkflowAction(obj);
  };
  let statusToShow = workflowAction;
  switch (workflowAction) {
    case 'Approve':
      statusToShow = 'Approved';
      break;
    case 'Accept':
      statusToShow = 'Approved';
      break;
    case 'Reject':
      statusToShow = 'Rejected';
      break;
    case 'Cancel':
      statusToShow = 'Cancelled';
      break;
    default:
      statusToShow = workflowAction;
  }
  return footerRow ? (
    <DataTableCell key="-1" title="footer" />
  ) : (
    <DataTableCell key={item} title={workflowAction || ''}>
      {workflowAction &&
      workflowAction !== WORKFLOW_ACTION.NO_ACTION &&
      workflowAction !== 'NoAction' ? (
        <p>
          <EventStatusBadge status={lineVisualIndicator || statusToShow} />
        </p>
      ) : (
        <div>
          <Combobox
            id="combobox-readonly-single"
            menuPosition="overflowBoundaryElement"
            disabled={viewOnly}
            events={{
              onSelect: (_event: any, data: any): void => handleSelectDropdownItem(data),
            }}
            labels={{
              placeholder: 'Select',
            }}
            options={RETURNS_WORKFLOW_ACTION_OPTION || []}
            selection={userAction}
            variant="readonly"
          />
        </div>
      )}
    </DataTableCell>
  );
};

export default InventoryApproveRejectDataCell;
