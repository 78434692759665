/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Module for Custom Rep/Covering Cell.
 * @module src/Events/EventsList
 */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';

interface Quantity {
  quantity: string;
  availablequantity: string;
  productNumber: string;
  createdByDetails: any[];
  id: string;
  isOpen: boolean;
  needToDisplay: false;
}
interface Props {
  item?: Quantity;
}

const PartNumberCell: FC<Props> = ({
  item = {
    quantity: '',
    availablequantity: '',
    productNumber: '',
    createdByDetails: [],
    id: '',
    isOpen: false,
    needToDisplay: false,
  },
}) => {
  const {
    quantity,
    availablequantity,
    productNumber,
    createdByDetails,
    isOpen,
    needToDisplay,
  } = item;
  return needToDisplay ? (
    <DataTableCell title={productNumber} className="left-align">
      {quantity !== availablequantity ? (
        <span style={{ color: 'red' }}>{productNumber}</span>
      ) : (
        <span>{productNumber}</span>
      )}
      {isOpen &&
        createdByDetails?.map((lineItem: any) => (
          <div className="border-line">
            <DataTableCell>{lineItem.createdByName}</DataTableCell>
          </div>
        ))}
    </DataTableCell>
  ) : null;
};

/** Custom Rep/Covering cell */
export default PartNumberCell;
