/* eslint-disable import/named */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import React, { FC, useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Spinner from '@salesforce/design-system-react/components/spinner';
import { UsageSheetDetails } from '../../UsageDetailsHeader';
import PricingHeader from './PricingHeader';
// import LineItems from '../Parts/LineItems';
import PricingTabularDetails from './PricingTabularDetails';
import CREATE_UPDATE_USAGE_LINE_ITEMS from '../../../../../graphql/mutations/createUpdateUsageSheetLineItems';
import { MAX_QUANTITY, USAGE_TYPE, NOTIFY_TYPE } from '../../../../../util/constants';
import useSnackBar from '../../../../../util/customHooks/useSnackBar';
import RESUBMIT_ORDER_DETAILS from '../../../../../graphql/mutations/resubmitOrderDetails';
import SnackBar from '../../../../Shared/SnackBar';
import { GetActualQty } from '../../../../../util/utilityFunctions';

interface Props {
  usageDetails?: UsageSheetDetails;
  viewOnly: boolean;
  refetch: () => void;
  eventType?: string;
  count: number;
  updatedPrice: any;
  isAssignUser: boolean;
  isERPOrder: boolean;
  pricingDetailsConfig?: any;
  type: string;
  wasted?: boolean;
  actualQty?: boolean;
  detailsConfig?: any;
  screenLoading?: boolean;
}

const UsagePricing: FC<Props> = ({
  usageDetails,
  refetch,
  eventType,
  count,
  isAssignUser,
  viewOnly,
  updatedPrice,
  isERPOrder,
  pricingDetailsConfig,
  wasted,
  actualQty,
  detailsConfig,
  type,
  screenLoading,
}) => {
  const [loader, setLoader] = useState(true);
  const { open, notification, openSnackBar } = useSnackBar();
  const [items, setItems] = useState<any[]>([]);
  const [valuesUpdated, setValuesUpdated] = useState(false);
  const [createUpdateUsageLineItems] = useMutation(CREATE_UPDATE_USAGE_LINE_ITEMS);
  const [resubmitOrderLineItems] = useMutation(RESUBMIT_ORDER_DETAILS);
  let enabledPricingTabs: any[] = [];
  if (pricingDetailsConfig) {
    pricingDetailsConfig.forEach((item: any) => {
      if (item.isShow === true) {
        enabledPricingTabs = [...enabledPricingTabs, item.mappingKey];
      }
    });
    if (wasted) {
      enabledPricingTabs.push('wasted');
    }
    if (actualQty) {
      enabledPricingTabs.push('actual_qty');
    }
  }
  useEffect(() => {
    if (usageDetails) {
      const { lineItems } = usageDetails;
      const parts = lineItems.map((i: any) => {
        let discount = '';
        if (i.priceOverrideType === 'PERCENTAGE') {
          discount = i.priceOverride;
        } else if (i.listPrice && Number(i.listPrice) > 0) {
          // discount
          const discOffList = (1 - Number(i.usgPrice) / Number(i.listPrice)) * 100;
          discount = parseFloat(discOffList.toString()).toFixed(2);
        }
        // MAX Qty
        const maxQuantity = i.lotNumber
          ? Number(i.availableQuantity) + Number(i.quantity)
          : MAX_QUANTITY;
        return {
          ...i,
          uniqueId: isERPOrder ? i.sfid || i.externalId : i.externalId,
          originalUsgPrice: i.usgPrice,
          originalPriceType: i.priceType,
          discountPercentage: discount,
          // allowOverride: true, // default allow override
          maxQuantity,
        };
      });
      setItems(parts);
      if (parts.length === 0) {
        setLoader(false); // No parts so stop loader
      }
    }
  }, [isERPOrder, usageDetails]);
  useEffect(() => {
    if (updatedPrice?.getUpdatedResolvedPrice) {
      const lineItms = items.map((i: any) => {
        const priceItem = updatedPrice.getUpdatedResolvedPrice.find(
          (price: any) => price.productNumber === i.productNumber
        );
        const actualQtyValue = GetActualQty(
          usageDetails?.diplayOrderType || '',
          priceItem?.sellingUOM,
          i.salesUnitOfMeasure,
          i.packContent,
          i.quantity
        );
        let discount = '';
        if (priceItem?.listPrice && Number(priceItem.listPrice) > 0) {
          // discount
          const discOffList = (1 - Number(i.usgPrice) / Number(priceItem.listPrice)) * 100;
          discount = parseFloat(discOffList.toString()).toFixed(2);
        }
        return {
          ...i,
          contractPrice: priceItem?.resolvedPrice || 0,
          contractName: priceItem?.contractName || '',
          listPrice: priceItem?.listPrice || 0,
          discountPercentage: discount,
          sellingUoM: priceItem?.sellingUOM,
          actualQuantity: actualQtyValue,
          // allowOverride,
          // priceOverride: !viewOnly && !allowOverride ? priceItem?.resolvedPrice : i.priceOverride,
          // usgPrice: !viewOnly && !allowOverride ? priceItem?.resolvedPrice : i.usgPrice,
        };
      });
      // setItems(lineItms.sort((a: any, b: any) => a.serialNumber - b.serialNumber) || []);
      setItems(lineItms || []);
      setLoader(false);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedPrice]);
  const saveErpOrderLineItems = (): void => {
    const payload: Array<any> = [];
    items.forEach((product: any, index: number): void => {
      payload.push({
        sfid: product.sfid,
        externalId: product.caseUsageExternalId || product.externalId,
        productId: product.productId,
        productNumber: product.productNumber,
        isLotNumber: product?.isLotNumber,
        quantity: product.quantity.toString(),
        actualQuantity: parseFloat(product.actualQuantity.toString()).toFixed(2),
        wasted: product.wasted || false,
        lotNumber: (product.selectedLot && product.selectedLot[0]?.lotNumber) || product.lotNumber,
        serialNumber: (index + 1).toString(),
        usgPrice: product.usgPrice,
        deleted: product.quantity === '' ? true : null,
        priceOverride: product.priceOverride || null,
        overridePrice: !!product.priceOverride,
        priceOverrideType: product.priceOverrideType,
        priceBookEntryId: product.priceBookEntryId,
        salesUnitOfMeasure: product.salesUnitOfMeasure,
        deliveredDate: product.deliveredDate,
        caseUsageId: product.caseUsageId,
        caseUsageExternalId: product.caseUsageExternalId,
        subInventoryCode: product.caseUsageSubInventoryCode || '',
        sourceLocation: product.sourceLocation
          ? typeof product.sourceLocation === 'string'
            ? product?.sourceLocation === 'Catalog'
              ? ''
              : product?.sourceLocation || ''
            : product?.sourceLocation[0]?.value
          : null,
        usgTotalAmount: parseFloat((product.actualQuantity * product.usgPrice).toString()).toFixed(
          2
        ),
      });
    });
    const mutation = {
      id: usageDetails?.id,
      salesRepId: usageDetails?.salesRepId,
      accountId: usageDetails?.accountId,
      submitType: 'Update',
      requestedFrom:
        usageDetails?.diplayOrderType === USAGE_TYPE.BILL_ONLY ? 'BillOnly' : 'ShipAndBill',
      lineItems: payload,
    };
    resubmitOrderLineItems({
      variables: mutation,
    }).then(() => {
      setLoader(false);
      refetch();
    });
  };
  const saveOrderRequestLineItems = (): void => {
    const payload: Array<any> = [];
    items.forEach((product: any, index: number): void => {
      payload.push({
        sfid: product.sfid,
        externalId: product.externalId,
        productId: product.productId,
        quantity: product.quantity.toString(),
        actualQuantity: parseFloat(product.actualQuantity.toString()).toFixed(2),
        deleted: product.quantity === '' ? true : null,
        lotNumber: (product.selectedLot && product.selectedLot[0]?.lotNumber) || product.lotNumber,
        isLotNumber: product?.isLotNumber,
        productNumber: product.productNumber,
        serialNumber: (index + 1).toString(),
        usgPrice: product.usgPrice,
        priceOverride: product.priceOverride || null,
        overridePrice: !!product.priceOverride,
        wasted: product.wasted,
        priceOverrideType: product.priceOverrideType,
        iscatalog: product.iscatalog,
        sourceLocation: product.sourceLocation
          ? typeof product.sourceLocation === 'string'
            ? product.sourceLocation
            : product?.sourceLocation[0]?.value
          : null,
        subInventoryCode: product?.caseUsageSubInventoryCode || '',
        previousLotNumber: product.previousLotNumber,
        isLotValueUpdated: product?.isLotValueUpdated || false,
        isValidLotSerialCombination: product.isValidLotSerialCombination,
        usgTotalAmount: parseFloat((product.actualQuantity * product.usgPrice).toString()).toFixed(
          2
        ),
      });
    });
    const mutation = {
      caseId: usageDetails?.caseId,
      caseExternalId: usageDetails?.caseExternalId,
      salesRepId: usageDetails?.salesRepId,
      formType: 'Update',
      lineItems: payload,
      system: 'Web',
      version: process.env.REACT_APP_VERSION || '0.0.1',
      // tabName: 'Pricing',
    };
    createUpdateUsageLineItems({
      variables: mutation,
    })
      .then((res: any) => {
        // setLoader(false);
        const data = res?.data?.createUpdateUsageSheetLineItems;

        if (data?.isMaxQuantityExceeded) {
          setTimeout(() => {
            openSnackBar(
              NOTIFY_TYPE.WARNING,
              'Quantity Exceeds available inventory. Please add additional lines from catalog with the quantity difference.'
            );
          }, 1500);
        }
        refetch();
      })
      .catch(res => {
        if (res && res.message) {
          openSnackBar(NOTIFY_TYPE.ERROR, res.message);
          setLoader(false);
        } else {
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
        }
      });
  };
  const handleSave = (): void => {
    setLoader(true);
    if (isERPOrder) {
      saveErpOrderLineItems();
    } else {
      saveOrderRequestLineItems();
    }
  };
  useEffect(() => {
    if (valuesUpdated) {
      handleSave();
      setValuesUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesUpdated]);
  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      <PricingHeader count={count} viewOnly={viewOnly} handleSave={handleSave} />
      <PricingTabularDetails
        usageDetails={usageDetails}
        refetch={refetch}
        items={items}
        setItems={setItems}
        isAssignUser={isAssignUser}
        viewOnly={viewOnly}
        eventType={eventType}
        isERPOrder={isERPOrder}
        pricingListConfig={enabledPricingTabs}
        handleSave={setValuesUpdated}
        configs={detailsConfig}
        type={type}
        updatedPrice={updatedPrice?.getUpdatedResolvedPrice}
      />
      {(loader || screenLoading) && <Spinner size="medium" variant="base" />}
    </IconSettings>
  );
};

export default UsagePricing;
