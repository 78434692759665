import gql from 'graphql-tag';

// interface UpdateTransferLineItems {
//   id: string;
//   externalId: string;
//   partNumber: string;
//   lotNumber: string;
//   productId: string;
//   quantity: string;
//   priceBookEntryId: string;
//   unitPrice: string;
//   status: string;
//   approvalStatus: string;
// }

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_TRANSFER_INVENTORY = gql`
  mutation updateTransferInventory(
    $orderId: String
    $orderExternalId: String
    $accountId: String
    $salesRepId: String
    $receivingRepId: String
    $fromSubInventoryCode: String
    $toSubInventoryCode: String
    $salesOrganization: String
    $status: String
    $system: String
    $version: String
    $lineItems: [UpdateTransferLineItems]
    $comments: [Comment]
  ) {
    updateTransferInventory(
      status: $status
      orderId: $orderId
      orderExternalId: $orderExternalId
      accountId: $accountId
      salesRepId: $salesRepId
      system: $system
      version: $version
      receivingRepId: $receivingRepId
      fromSubInventoryCode: $fromSubInventoryCode
      toSubInventoryCode: $toSubInventoryCode
      salesOrganization: $salesOrganization
      lineItems: $lineItems
      comments: $comments
    ) {
      message
      externalId
    }
  }
`;
export default UPDATE_TRANSFER_INVENTORY;
