/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */
/**
 * Modal for Filter Inventory
 * @module src/components/Inventory/InventoryFilter/index
 */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useState, useCallback, useEffect } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import { useQuery } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Combobox from '@salesforce/design-system-react/components/combobox';
import './index.scss';
import { REGION } from '../../../../util/constants';
//import DeleteFilterPreset from './DeleteFilterPreset';
import { getUserId } from '../../../../store/ducks/userId';
import { clearInventoryHoldFilters } from '../../../../store/ducks/inventroyHoldFilters';
import escapeRegExp from 'lodash.escaperegexp';
import { GET_FILTERED_SALES_REPS } from '../../../../graphql/getFilteredSalesReps';

interface Props {
  handleToggleFilterInventory: () => void;
  filterInventoryModalVisible: boolean;
  selectedFilter?: FilterPreset | DynamicType | null;
  handleFilterHold: (filter: DynamicType | null) => void;
  isEditFilter: boolean;
  userView?: string;
  ItemsData?: any;
  filteredUserList?: any;
}

interface SelectedData {
  id: string;
  label: string;
}

interface DynamicType {
  [key: string]: any;
}
interface ValueInterface {
  value: string;
}
interface FilterPreset {
  filterValues: SelectedFilters;
  filterExternalId?: string;
  filterName?: string;
}

interface SelectedFilters {
  productGroup: SelectedData[];
  salesRepId: SelectedData[];
}

enum DetailTypes {
  SalesRepBU = 'SalesRepBU',
  Account = 'Account',
  Territory = 'UserTerritory',
  SalesRep = 'SalesRep',
}
interface DropdownSelect {
  id: string;
  label?: string;
  value: string;
}
interface Selection {
  name: string;
  __typename: string;
  id: string;
  label: string;
  value?: string;
}
interface SelectedData {
  selection: Selection[];
}
type SelectionType = Selection[];
const UserHoldFilter: FC<Props> = ({
  handleToggleFilterInventory,
  filterInventoryModalVisible,
  selectedFilter,
  handleFilterHold,
  isEditFilter,
  userView,
  ItemsData,
  filteredUserList,
}) => {
  const filterValues = selectedFilter?.filterValues;
  const filterName = selectedFilter ? selectedFilter.filterName : '';
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [reasonOptions, setReasonsOption] = useState<DropdownSelect[] | undefined>();
  // useState
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<DynamicType | null>(filterValues || null);
  const [inputValue, setInputValue] = useState('');
  const [reasonInputValue, setReasonInputValue] = useState('');

  const userId = useSelector(getUserId);
  const { data: filteredSalesReps, loading: loadingSalesReps } = useQuery(GET_FILTERED_SALES_REPS, {
    variables: {
      userId,
      region: REGION,
    },
  });

  useEffect(() => {
    const reasonData = ItemsData?.getAllSalesRepsWithSubmitPermission;
    if (reasonData && reasonData.length > 0) {
      // const allReasonValues = reasonData
      //   .flatMap((element: any) =>
      //     element.reasons.map((reason: any) => ({
      //       id: reason.holdReason,
      //       label: reason.holdReason,
      //       value: reason.holdReason,
      //     }))
      //   )
      //   .sort();
      // setReasonsOption(allReasonValues);

      const uniqueReasonsSet = new Set<string>();
      const uniqueReasonsOptions: DropdownSelect[] = [];

      reasonData.forEach((element: any) => {
        element.reasons.forEach((reason: any) => {
          const holdReason = reason.holdReason;
          if (holdReason !== null) {
            const normalizedReason = holdReason.trim().toLowerCase();
            if (!uniqueReasonsSet.has(normalizedReason)) {
              uniqueReasonsSet.add(normalizedReason);
              uniqueReasonsOptions.push({
                id: holdReason,
                label: holdReason,
                value: holdReason,
              });
            }
          }
        });
      });
      setReasonsOption(uniqueReasonsOptions);
    }
  }, [ItemsData]);

  useEffect(() => {
    setSelectedFilters(filterValues);
  }, [filterValues]);

  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any): void => {
      // For selected filter value
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };
      setSelectedFilters(selectedFilterValues);
    },
    [selectedFilters]
  );
  const resetFilters = (): void => {
    handleToggleFilterInventory();
    setSelectedFilters(null);
    handleFilterHold(null);
    dispatch(clearInventoryHoldFilters());
  };

  const handleApplyFilter = (): void => {
    handleToggleFilterInventory();
    handleFilterHold(selectedFilters);
  };

  const handleChangeOnType = useCallback(
    (_event, selectedValue: ValueInterface) => {
      const { value } = selectedValue;
      _event.persist();
      setInputValue(value);
    },
    [setInputValue]
  );

  const handleChangeOnReason = useCallback(
    (_event, selectedValue: ValueInterface) => {
      const { value } = selectedValue;
      _event.persist();
      setReasonInputValue(value);
    },
    [reasonInputValue]
  );

  const handleDeleteModalVisible = useCallback((): void => {
    setDeleteModalVisible(!deleteModalVisible);
  }, [deleteModalVisible]);

  // eslint-disable-next-line no-underscore-dangle
  const optionsWithLabel = (value: any): object =>
    value &&
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      if (
        [DetailTypes.SalesRepBU, DetailTypes.Territory, DetailTypes.SalesRep].indexOf(
          elem.__typename as any
        ) >= 0
      ) {
        return { ...elem, label: `${elem.value}` };
      }
      if ([DetailTypes.Account].indexOf(elem.__typename as any) >= 0) {
        return { ...elem, label: `${elem.number}  ${elem.value}` };
      }
    });

  const handleSelect = useCallback(
    (data: any, name: string) => {
      if (data.selection) {
        const { selection } = data;
        setFilterValue(name, selection);
      } else {
        setFilterValue(name, data);
      }
    },
    [setFilterValue, userId]
  );
  const handleSelectItem = useCallback(
    (_event, data: SelectedData) => {
      const { selection } = data;
      setInputValue('');
      const updatedSelection: Selection[] = Object.values(
        selection.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
      );
      setOpen(false);
      handleSelect(updatedSelection, 'user');
    },
    [setInputValue, handleSelect]
  );

  const handleReasonSelectItem = useCallback(
    (_event, data: SelectedData) => {
      const { selection } = data;
      setReasonInputValue('');
      const updatedSelection: Selection[] = Object.values(
        selection.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
      );
      setOpen(false);
      handleSelect(updatedSelection, 'reason');
    },
    [reasonInputValue, handleSelect]
  );

  const comboboxFilterAndLimit = (parameters: any): SelectionType => {
    const { searchedValue, options, selection } = parameters;
    const inputValueRegExp = new RegExp(escapeRegExp(searchedValue), 'ig');
    return options.filter((option: Selection) => {
      const searchTermFound = option?.label ? option?.label.match(inputValueRegExp) : false;
      const isSeparator = false;
      const notAlreadySelected = !selection.some((sel: Selection) => sel.id === option.id);

      return (!searchedValue || isSeparator || searchTermFound) && notAlreadySelected;
    });
  };

  const filteredUserListLabel = optionsWithLabel(filteredUserList);
  const type = [
    {
      value: 'Custom Hold',
      id: '1',
      label: 'Custom Hold',
    },
    {
      value: 'Cycle Count Hold',
      id: '2',
      label: 'Cycle Count Hold',
    },
    {
      value: 'Trial Hold',
      id: '3',
      label: 'Trial Hold',
    },
    {
      value: 'Sunshine Act Hold',
      id: '4',
      label: 'Sunshine Act Hold',
    },
  ];

  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={filterInventoryModalVisible}
        onRequestClose={handleToggleFilterInventory}
        footer={[
          <Button
            className="slds-float_left slds-text-color_default"
            label="Cancel"
            disabled={
              selectedFilters?.endDate &&
              selectedFilters?.startDate &&
              selectedFilters.endDate < selectedFilters.startDate
            }
            key="Cancel"
            onClick={handleToggleFilterInventory}
          />,
          <Button label="Reset Filter" key="Reset" onClick={resetFilters} />,
          <Button
            className="yellow-btn"
            label="Apply"
            onClick={handleApplyFilter}
            disabled={!selectedFilters}
            variant="brand"
            key="Apply"
          />,
        ]}
        heading={[
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div className="slds-float_left slds-text-title_bold filter_heading" key="filterName">
            {`${
              filterName && filterName !== ''
                ? `${isEditFilter ? 'Edit ' : ''}Filter Preset ${filterName}`
                : 'Filters'
            }`}
          </div>,

          isEditFilter && (
            <div className="slds-float_right" key="delete">
              <Button
                className="delete_button"
                label="Delete Preset"
                key="delete"
                onClick={handleDeleteModalVisible}
              />
            </div>
          ),
        ]}
      >
        <section className="slds-p-around_medium slds-theme_shade">
          <>
            <div className="full-width_button">
              <span className="filtterTabName">Attributes</span>
              <Combobox
                id="user"
                options={comboboxFilterAndLimit({
                  searchedValue: inputValue,
                  options: filteredUserListLabel || [],
                  selection: selectedFilters?.user || [],
                })}
                events={{
                  onChange: handleChangeOnType,
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'user'),
                  onSelect: handleSelectItem,
                }}
                optionsAddItem={[]}
                labels={{
                  label: `User`,
                  placeholder: 'Select an Option',
                  noOptionsFound: 'No data found',
                }}
                disabled={false}
                multiple
                placeholder="Select an Option"
                menuPosition="relative"
                value={inputValue}
                selection={selectedFilters ? selectedFilters.user || [] : []}
                variant="base"
              />
              <Combobox
                id="type"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'type'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'type'),
                }}
                labels={{
                  label: `Type`,
                  placeholder: 'Select',
                  noOptionsFound: 'No data found',
                }}
                multiple
                placeholder="Select"
                hasInputSpinner={loadingSalesReps}
                options={type || []}
                selection={selectedFilters ? selectedFilters.type || [] : []}
                variant="readonly"
              />

              <Combobox
                id="reason"
                options={comboboxFilterAndLimit({
                  searchedValue: reasonInputValue,
                  options: reasonOptions || [],
                  selection: selectedFilters?.reason || [],
                })}
                events={{
                  onChange: handleChangeOnReason,
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'reason'),
                  onSelect: handleReasonSelectItem,
                }}
                optionsAddItem={[]}
                labels={{
                  label: `Reason`,
                  placeholder: 'Select an Option',
                  noOptionsFound: 'No data found',
                }}
                disabled={false}
                placeholder="Select an Option"
                menuPosition="relative"
                value={reasonInputValue}
                selection={selectedFilters ? selectedFilters.reason || [] : []}
                variant="base"
              />
            </div>
          </>
        </section>
      </Modal>
    </IconSettings>
  );
};
/** Filter Inventory modal component */
export default UserHoldFilter;
