/**
 * Module for Custom Event Status Cell.
 * @module src/Events/EventsList
 */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Icon from '@salesforce/design-system-react/components/icon';
import Badge from '@salesforce/design-system-react/components/badge';
import EventStatusBadge from '../../Shared/EventStatusBadge';
import './index.scss';

interface Status {
  eventStatus: string;
  submitUsage: boolean;
}
export interface Props {
  children?: FC;
  item?: Status;
}

const EventsListEventStatusCell: FC<Props> = ({
  item = {
    eventStatus: '',
  },
}) => {
  const { eventStatus, submitUsage } = item;
  return (
    <DataTableCell title={eventStatus || 'NEW'}>
      <EventStatusBadge status={eventStatus || 'NEW'} />
      {submitUsage && (
        <Badge
          id="badge-base-example-light"
          color="light"
          className="check-budge"
          content="Usage"
          icon={<Icon category="utility" name="check" size="xx-small" />}
        />
      )}
    </DataTableCell>
  );
};

/** Custom Event Status cell */
export default EventsListEventStatusCell;
