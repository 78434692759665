/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Module for Custom Rep/Covering Cell.
 * @module src/Events/EventsList
 */
import React, { FC, useEffect, useState, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Input from '@salesforce/design-system-react/components/input';
import CREATE_UPDATE_CYCLE_COUNT_LINE_ITEMS from '../../../../graphql/mutations/createUpdateCycleCountLineItems';
import debounced from '../../../../util/debounced';
import { getUserInfo } from '../../../../store/ducks/userInfo';

interface Quantity {
  quantity: string;
  availablequantity: string;
  productdescription: string;
  cycleCountId: string;
  cycleCountExternalId: string;
  createdByDetails: any[];
  lotNumber: string;
  createdBy: string;
  id: string;
  isOpen: boolean;
  needToDisplay: boolean;
  isManual: boolean;
}
interface Props {
  item?: Quantity;
  handleRefetchLineItems?: (action: any) => void;
  cycleCountDetails: any;
  isOps?: any;
  inProgress?: any;
  disabled?: boolean;
}

const TableRowCell: FC<Props> = ({
  item = {
    quantity: '',
    availablequantity: '',
    productdescription: '',
    createdBy: '',
    createdByDetails: [],
    cycleCountId: '',
    cycleCountExternalId: '',
    lotNumber: '',
    id: '',
    isOpen: false,
    needToDisplay: false,
    isManual: false,
  },
  isOps,
  inProgress,
  handleRefetchLineItems,
  cycleCountDetails,
  disabled,
}) => {
  const {
    createdByDetails,
    lotNumber,
    cycleCountId,
    cycleCountExternalId,
    isOpen,
    needToDisplay,
    createdBy,
  } = item;
  const [itemList, setList] = useState<any>([]);
  const [createUpdateCycleCountLineItems, { data: eventUsageMutation }] = useMutation(
    CREATE_UPDATE_CYCLE_COUNT_LINE_ITEMS
  );
  const userInfo = useSelector(getUserInfo);
  useEffect(() => {
    if (
      eventUsageMutation &&
      eventUsageMutation.createUpdateCycleCountLineItems &&
      eventUsageMutation.createUpdateCycleCountLineItems.message === 'success'
    )
      if (handleRefetchLineItems) {
        handleRefetchLineItems('update');
      }
  }, [eventUsageMutation, handleRefetchLineItems]);

  const setUpdate = (mutation: any): void => {
    if (mutation) {
      createUpdateCycleCountLineItems({
        variables: mutation,
      });
    }
  };

  const debouncedFetchItems = useCallback(debounced(200, setUpdate), [debounced, setUpdate]);

  const UpdateLineItems = (event: any, lineid: string, extId: string): void => {
    const payload: Array<any> = [];

    const updatedList = [...createdByDetails];
    updatedList.forEach((element: any) => {
      if (element.externalId === extId) {
        /* eslint-disable no-param-reassign */
        element.lotNumber = event.target.value;
      }
    });
    setList(updatedList);

    createdByDetails?.forEach((i: any): void => {
      if (i.externalId === extId) {
        payload.push({
          sfid: lineid,
          externalId: extId,
          lotNumber: event.target.value || i.lotNumber,
          quantity: i.quantity,
        });
        const mutation = {
          cycleCountExternalId,
          cycleCountId,
          formType: 'New',
          lineItems: payload,
        };
        debouncedFetchItems(mutation);
      }
    });
  };

  return needToDisplay ? (
    <DataTableCell
      title={lotNumber || 'enter lot number'}
      className={`${isOps ? 'lotNumber' : 'center-align'}`}
    >
      {(createdByDetails &&
        isOps &&
        createdByDetails[0]?.createdByPersona?.toLowerCase().includes('branch op')) ||
      (!isOps && cycleCountDetails?.status?.toLowerCase() === 'in progress') ? (
        <Input
          defaultValue={
            createdByDetails[0]?.lotNumber !== null ? createdByDetails[0]?.lotNumber : ''
          }
          onBlur={(_event: any): void =>
            UpdateLineItems(_event, createdByDetails[0]?.sfid, createdByDetails[0]?.externalId)
          }
          id="unique-id-4"
          disabled={
            !!(
              (cycleCountDetails &&
                (cycleCountDetails.status.toLowerCase() === 'cancelled' ||
                  cycleCountDetails.status.toLowerCase() === 'completed')) ||
              disabled ||
              (!isOps && createdBy !== userInfo?.id) ||
              (!item?.isManual && createdByDetails[0]?.lotNumber)
            )
          }
        />
      ) : (
        lotNumber
      )}
      {isOpen &&
        createdByDetails?.map((lineItem: any) => (
          <div className="border-line">
            <DataTableCell>{lineItem.createdByName}</DataTableCell>
          </div>
        ))}
    </DataTableCell>
  ) : null;
};

/** Custom Rep/Covering cell */
export default TableRowCell;
