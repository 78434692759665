/**
 * Module for Cancelling evnet creation.
 * @module src/components/CreateEditSurgeryEvent/CancelEventCreationModal
 */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';

interface Props {
  isReturn?: boolean;
  isCycleCount?: boolean;
  isInventory?: boolean;
  canceModalVisible: boolean;
  handleCancelModal: () => void;
  handleCancelModalConfirmPress: () => void;
}

const CancelModal: FC<Props> = ({
  canceModalVisible,
  handleCancelModal,
  handleCancelModalConfirmPress,
}) => {
  return (
    <Modal
      className="delete-modal default-modal_header default-modal_footer"
      isOpen={canceModalVisible}
      onRequestClose={handleCancelModal}
      ariaHideApp={false}
      footer={[
        <Button label="Yes" onClick={handleCancelModalConfirmPress} key="yes" />,
        <Button label="No" variant="brand" onClick={handleCancelModal} key="no" />,
      ]}
      heading="Confirm Delete"
    >
      <div className="slds-text-align--center">
        <div>
          <h1 className="slds-m-around--large ">Are you sure you want to delete ?</h1>
        </div>
      </div>
    </Modal>
  );
};

/** Custom component for Cancelling evnet creation */
export default CancelModal;
