/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect } from 'react';
import { Grid, Chip, makeStyles } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { GET_SALES_REPS } from '../../../graphql/getSalesReps';
import { GET_OPS_LIST } from '../../../graphql/getOpsList';
import { GET_PRIMARY_ACCOUNT } from '../../../graphql/getPrimaryAccount';
import './index.scss';

interface Props {
  selectedFilter: any;
  handleFilter: (filters: any) => void;
}
interface FilterPreset {
  filterValues: any;
  filterExternalId?: string;
  filterName?: string;
}
interface DropdownSelect {
  id: string;
  label?: string;
  value: string;
}
interface OpsDropdownSelect {
  label: string;
  value: string;
  id: string;
}
interface DynamicValue {
  [key: string]: any;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
    padding: '0px',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));
const FilterTagReviewRequest: FC<Props> = ({ selectedFilter, handleFilter }) => {
  const classes = useStyles();
  const [filterTags, setFilterTags] = useState<any[] | null>();
  const { data: salesRepsData } = useQuery(GET_SALES_REPS);
  const { data: opsData } = useQuery(GET_OPS_LIST);
  const { data: accounts } = useQuery(GET_PRIMARY_ACCOUNT);
  const [salesRepOptions, setSalesRepOptions] = useState<DropdownSelect[]>();
  const [options, setOptions] = useState<OpsDropdownSelect[]>();
  const [accountsData, setAccounts] = useState<any[]>();
  const optionsWithLabel = (arr: []): any[] =>
    arr &&
    arr.map((elem: any) => {
      return { ...elem, label: `${elem.value}` };
    });
  // Filter Tags
  useEffect(() => {
    if (salesRepsData?.getSalesReps) {
      setSalesRepOptions(optionsWithLabel(salesRepsData.getSalesReps));
    }
  }, [salesRepsData]);
  useEffect(() => {
    if (opsData && opsData?.getOPSList) {
      const optionList = optionsWithLabel(opsData?.getOPSList);
      optionList.unshift({ id: 'Unassigned', value: 'Unassigned', label: 'UNASSIGNED' });
      setOptions(optionList);
    }
  }, [opsData]);
  useEffect(() => {
    if (accounts && accounts?.getPrimaryAccount) {
      const accountsList = optionsWithLabel(accounts?.getPrimaryAccount);
      setAccounts(accountsList);
    }
  }, [accounts]);
  useEffect(() => {
    const tags: any[] = [];
    const filters = selectedFilter && selectedFilter;
    Object.keys(filters || {}).forEach((key: any) => {
      const value = filters[key];
      if (value) {
        let tagName;
        if (Array.isArray(value)) {
          if (value.length > 1) {
            if (value[0] && typeof value[0] === 'object') {
              tagName = `${value[0].label} ${value.length > 1 ? `(+${value.length - 1})` : ''}`;
            } else {
              tagName = `${value[0]} ${value.length > 1 ? `(+${value.length - 1})` : ''}`;
            }
          } else if (value[0] && typeof value[0] === 'object') {
            tagName = value[0].label || '';
          } else {
            // eslint-disable-next-line no-lonely-if
            if (key.toLowerCase().includes('assignedto')) {
              const filteredAssign = options?.filter((item: any) => item.id === value[0]);
              if (filteredAssign && filteredAssign.length > 0) {
                tagName = filteredAssign[0].label;
              }
            } else if (key.toLowerCase().includes('salesrep')) {
              const filteredSalesRep = salesRepOptions?.filter((item: any) => item.id === value[0]);
              if (filteredSalesRep && filteredSalesRep.length > 0) {
                tagName = filteredSalesRep[0].label;
              }
            } else if (key.toLowerCase().includes('account')) {
              const filteredaccount = accountsData?.filter((item: any) => item.id === value[0]);
              if (filteredaccount && filteredaccount.length > 0) {
                tagName = filteredaccount[0].label;
              }
            } else {
              // eslint-disable-next-line prefer-destructuring
              tagName = value[0];
            }
          }
        } else if (typeof value === 'object') {
          tagName = value.label || '';
        } else if (typeof value === 'string') {
          if (key.toLowerCase().includes('date')) {
            tagName = moment(value).format('L');
          } else if (key.toLowerCase().includes('salesrep')) {
            const filteredSalesRep = salesRepOptions?.filter((item: any) => item.id === value);
            if (filteredSalesRep && filteredSalesRep.length > 0) {
              tagName = filteredSalesRep[0].label;
            }
          } else if (key.toLowerCase().includes('assignedto')) {
            const filteredAssign = options?.filter((item: any) => item.id === value);
            if (filteredAssign && filteredAssign.length > 0) {
              tagName = filteredAssign[0].label;
            }
          } else {
            tagName = value;
          }
        } else if (typeof value === 'boolean') {
          tagName = key
            .split(/(?=[A-Z])/)
            .map((s: any) => s.toUpperCase())
            .join(' ');
        } else {
          tagName = value;
        }
        tags.push({ key, value, tagName });
      }
    });
    setFilterTags(tags.filter((i: any) => i.tagName));
  }, [selectedFilter, salesRepOptions, options, accountsData]);
  const handleDeleteTag = (name: any): void => {
    if (selectedFilter) {
      let filterValues: any;
      // eslint-disable-next-line prefer-const
      filterValues = selectedFilter;
      if (name.includes('expirationFromDate') || name.includes('expirationToDate')) {
        // Inventory List: Expiratation Days
        const { expirationFromDate, expirationToDate, ...newFilterValues } = filterValues;
        handleFilter(newFilterValues);
      } else if (name === 'startDate') {
        // Events: Date Range
        const { startDate, endDate, ...newFilterValues } = filterValues;
        handleFilter(newFilterValues);
      } else {
        const { [name]: remove, ...newFilterValues } = filterValues;
        handleFilter(newFilterValues);
      }
    }
  };
  return (
    <Grid container xs={12} className="filter_tag_container">
      {filterTags &&
        filterTags.length > 0 &&
        filterTags.map((tag: any) => (
          <Chip
            label={tag.tagName}
            size="small"
            className={classes.chip}
            onDelete={(): void => handleDeleteTag(tag.key)}
          />
        ))}
    </Grid>
  );
};

export default FilterTagReviewRequest;
