/**
 * Module for Custom Rep/Covering Cell.
 * @module src/Events/EventsList
 */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import './index.scss';

interface Reps {
  salesRepName: string;
  coveringReps: string;
}
interface Props {
  item?: Reps;
  showSalesRep?: boolean;
  showCoveringRep?: boolean;
}

const InventoryAssignReturnListRepCoveringCell: FC<Props> = ({
  showSalesRep,
  showCoveringRep,
  item = {
    salesRepName: '',
    coveringReps: '',
  },
}) => {
  const { salesRepName, coveringReps } = item;
  return (
    <DataTableCell title={salesRepName || coveringReps || ''}>
      {showSalesRep ? (
        <p className="wrap-text font-12">{showSalesRep ? salesRepName : '--'}</p>
      ) : showCoveringRep ? (
        <p className="wrap-text font-12">{showCoveringRep ? coveringReps : '--'}</p>
      ) : null}
    </DataTableCell>
  );
};

/** Custom Rep/Covering cell */
export default InventoryAssignReturnListRepCoveringCell;
