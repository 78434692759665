import gql from 'graphql-tag';

// interface Comment {
//   comment: string;
//   postTime: string;
//   commentType: string;
// }

// interface TransferInventoryLineItem {
//   lotNumber: string;
//   partNumber: string;
//   productId: string;
//   quantity: string;
// }

// eslint-disable-next-line import/prefer-default-export
export const POST_TRANSFER_INVENTORY = gql`
  mutation transferInventory(
    $parentEventId: String
    $parentEventExternalId: String
    $eventId: String
    $eventExternalId: String
    $accountId: String!
    $salesRepId: String!
    $receivingRepId: String!
    $fromSubInventoryCode: String
    $toSubInventoryCode: String
    $salesOrganization: String
    $type: String
    $system: String
    $version: String
    $comments: [Comment]
    $lineItems: [TransferInventoryLineItem]
    $attachments: [Attachment]
  ) {
    transferInventory(
      parentEventId: $parentEventId
      parentEventExternalId: $parentEventExternalId
      eventId: $eventId
      eventExternalId: $eventExternalId
      accountId: $accountId
      salesRepId: $salesRepId
      system: $system
      version: $version
      receivingRepId: $receivingRepId
      fromSubInventoryCode: $fromSubInventoryCode
      toSubInventoryCode: $toSubInventoryCode
      salesOrganization: $salesOrganization
      type: $type
      comments: $comments
      lineItems: $lineItems
      attachments: $attachments
    ) {
      message
      externalId
      sfid
    }
  }
`;
