/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactNode } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import Button from '@salesforce/design-system-react/components/button';
import '../../../index.scss';

interface Props {
  handleGeneratePdf: () => void;
  handleSubmit: () => void;
  handleReSubmitOrder: () => void;
  handleSave: () => void;
  viewOnly: boolean;
  isERPOrderFailed: boolean;
  orderResubmitPermission: boolean;
  usageDetails?: any;
  holddata?: any;
}

const SurgerySheetHeader: FC<Props> = ({
  handleGeneratePdf,
  handleSubmit,
  handleReSubmitOrder,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleSave,
  viewOnly,
  isERPOrderFailed,
  orderResubmitPermission,
  usageDetails,
  holddata,
}) => {
  const actions = (): ReactNode => (
    <PageHeaderControl>
      <Button
        label="Generate PDF"
        iconCategory="utility"
        iconName="paste"
        iconPosition="left"
        onClick={handleGeneratePdf}
      />
      {/* <Button
        disabled={viewOnly}
        style={{ background: viewOnly ? 'lightgray' : '' }}
        className="yellow-btn"
        label="Save"
        onClick={handleSave}
      /> */}
      {/* {isERPOrderFailed ? (
        <Button
          className="yellow-btn"
          label="Resubmit"
          onClick={handleReSubmitOrder}
          disabled={!orderResubmitPermission || viewOnly}
          style={{ background: !orderResubmitPermission || viewOnly ? 'lightgray' : '' }}
        />
      ) : (
        <Button
          className="yellow-btn"
          label="Submit"
          onClick={
            usageDetails?.status?.toLowerCase() === 'failed' ? handleReSubmitOrder : handleSubmit
          }
          disabled={viewOnly || (userOnHold && isHoldUser)}
          style={{ background: viewOnly ? 'lightgray' : '' }}
        />
      )} */}
    </PageHeaderControl>
  );

  return (
    <IconSettings iconPath="/icons">
      <PageHeader
        className="slds-page-header-usage slds-page-header__name-title-inventory slds-m-around_small slds-m-bottom_none"
        onRenderActions={actions}
        title={<span style={{ fontSize: '18px' }}>Usage Sheet</span>}
        variant="record-home"
        id="header"
      />
    </IconSettings>
  );
};

export default SurgerySheetHeader;
