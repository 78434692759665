/**
 * Module for event status badge.
 * @module src/components/Shared/EventStatusBadge
 */
import React, { FC } from 'react';
import { EVENT_STATUS, INVENTORY_STATUS, ORDER_STATUS, LOT_STATUS } from '../../../util/constants';
import styles from './index.module.scss';

interface Props {
  status: string;
}
const EventStatusBadge: FC<Props> = ({ status }) => {
  let badgeClass;
  switch (status) {
    case EVENT_STATUS.NEW:
      badgeClass = styles['badge--new'];
      break;
    case EVENT_STATUS.REQUESTED:
      badgeClass = styles['badge--requested'];
      break;
    case EVENT_STATUS.SHIPPED:
      badgeClass = styles['badge--shipped'];
      break;
    case EVENT_STATUS.PORTAL:
      badgeClass = styles['badge--portal'];
      break;
    case EVENT_STATUS.ASSIGNED:
      badgeClass = styles['badge--assigned'];
      break;
    case EVENT_STATUS.COMPLETED:
      badgeClass = styles['badge--completed'];
      break;
    case EVENT_STATUS.CLOSED:
      badgeClass = styles['badge--closed'];
      break;
    case EVENT_STATUS.CANCELLED:
      badgeClass = styles['badge--cancelled'];
      break;
    case EVENT_STATUS.CANCEL:
      badgeClass = styles['badge--cancelled'];
      break;
    case EVENT_STATUS.RETURNING:
      badgeClass = styles['badge--returning'];
      break;
    case INVENTORY_STATUS.APPROVED:
      badgeClass = styles['badge--approved'];
      break;
    case INVENTORY_STATUS.REJECTED:
      badgeClass = styles['badge--rejected'];
      break;
    case ORDER_STATUS.SUBMITTED:
      badgeClass = styles['badge--submitted'];
      break;
    case ORDER_STATUS.BOOKED:
      badgeClass = styles['badge--booked'];
      break;
    case ORDER_STATUS.COMPLETE:
      badgeClass = styles['badge--completed'];
      break;
    case ORDER_STATUS.FAILED:
      badgeClass = styles['badge--failed'];
      break;
    case LOT_STATUS.EXPIRED:
      badgeClass = styles['badge--rejected'];
      break;
    case LOT_STATUS.HOLD:
      badgeClass = styles['badge--requested'];
      break;
    case LOT_STATUS.RECALL:
      badgeClass = styles['badge--recall'];
      break;
    case EVENT_STATUS.INPROGRESS:
      badgeClass = styles['badge--inprogress'];
      break;
    case INVENTORY_STATUS.PARTIAL:
      badgeClass = styles['badge--booked'];
      break;
    case INVENTORY_STATUS.BACKORDER:
      badgeClass = styles['badge--inprogress'];
      break;
    case INVENTORY_STATUS.THREEPLREVIEW:
      badgeClass = styles['badge--3pl'];
      break;
    default:
      badgeClass = '';
  }
  return (
    <div className={`${styles.badge} ${badgeClass}`}>
      <span className={styles.badge__title}>{status}</span>
    </div>
  );
};

export default EventStatusBadge;
