/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Button from '@salesforce/design-system-react/components/button';
import EventStatusBadge from '../../Shared/EventStatusBadge';
import './index.scss';

interface Props extends RouteComponentProps {
  children?: FC;
  items?: any;
}

const TransferList: FC<Props> = ({ items, history }) => {
  const goToOrderDetails = (e: any, externalId: any, type: any, status: any): void => {
    history.push('/inventoryTransferDetails', {
      externalId,
      type,
      status,
    });
  };
  return (
    <>
      <div className="parts-list-table-container">
        <div className="parts-list-table-header list-grid-5-cols">
          <p>Transfer</p>
          <p>Transfer Type</p>
          <p>Direction</p>
          <p>Status</p>
          <p>Destination Account</p>
        </div>
        {items &&
          items.map((lineItem: any) => (
            <div className="parts-list-table-row list-grid-5-cols">
              <p className="vertical-align-middle">
                <Button
                  label={lineItem.name || 'TBD'}
                  variant="base"
                  onClick={(e: any): void =>
                    goToOrderDetails(e, lineItem?.externalId, lineItem?.type, lineItem?.status)
                  }
                />
              </p>
              <p className="vertical-align-middle">{lineItem?.type || ''}</p>
              <p className="vertical-align-middle">{lineItem?.direction || ''}</p>
              <p className="vertical-align-middle">
                <EventStatusBadge status={lineItem?.status || 'NEW'} />
              </p>
              <p className="vertical-align-middle">
                {`${lineItem?.destinationAccountNumber} - ${lineItem?.destinationAccountName}`}
              </p>
            </div>
          ))}
      </div>
    </>
  );
};

export default withRouter(TransferList);
