/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable dot-notation */
/* eslint-disable no-console */

import React, { FC, ReactNode, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import Spinner from '@salesforce/design-system-react/components/spinner';
import RadioGroup from '@salesforce/design-system-react/components/radio-group';
import Radio from '@salesforce/design-system-react/components/radio-group/radio';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import Button from '@salesforce/design-system-react/components/button';
import Icon from '@salesforce/design-system-react/components/icon';
import Collapsible from '../Collapsible';
import {
  NOTIFICATIONS_OPTIONS,
  NOTIFICATIONS_RADIO_OPTIONS,
  NOTIFY_TYPE,
} from '../../../util/constants';
import { GET_USER_NOTIFICATION_SETTINGS } from '../../../graphql/getUserNotificationSettings';
import CREATE_UPDATE_USER_NOTIFICATION_CONFIG from '../../../graphql/mutations/createUpdateUserNotificationConfig';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import SnackBar from '../../Shared/SnackBar';
import { getUserId } from '../../../store/ducks/userId';
import { getUserInfo } from '../../../store/ducks/userInfo';
import DisplayRecordIndicator from '../../Shared/DisplayRecordIndicator';

interface Props {
  type: string;
}

// eslint-disable-next-line no-empty-pattern
const NotificationSetting: FC<RouteComponentProps> = ({ location }) => {
  const [timeStamp, setTimeStamp] = useState<any>('');
  const [
    getUserNotificationSettings,
    {
      data: getUserNotificationSettingsData,
      refetch: refetchNotificationSettings,
      loading: getNotificationLoader,
    },
  ] = useLazyQuery(GET_USER_NOTIFICATION_SETTINGS, {
    fetchPolicy: 'no-cache',
  });
  const { open, notification, openSnackBar } = useSnackBar();
  const id = useSelector(getUserId);
  const userInfo = useSelector(getUserInfo);
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenInventory, setIsOpenInventory] = useState(true);
  const [isOpenOrders, setIsOpenOrders] = useState(true);
  const [isCycleCount, setIsCycleCount] = useState(true);
  const [notificationSettings, setNotificationSettings] = useState<any>();
  const [checkhandleChange, setCheckhandleChange] = useState(false);
  const [
    createUpdateUserNotificationConfig,
    { data: notificationSettingsData, loading },
  ] = useMutation(CREATE_UPDATE_USER_NOTIFICATION_CONFIG);

  useEffect(() => {
    getUserNotificationSettings({
      variables: { userId: id, businessunit: userInfo?.businessunit },
    });
  }, [getUserNotificationSettings, userInfo]);

  useEffect(() => {
    if (notificationSettingsData && notificationSettingsData.createUpdateUserNotificationConfig) {
      if (notificationSettingsData.createUpdateUserNotificationConfig.message === 'success') {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Notification Settings Saved Successfully');
        refetchNotificationSettings();
      }
    }
  }, [notificationSettingsData]);

  useEffect(() => {
    if (
      getUserNotificationSettingsData &&
      getUserNotificationSettingsData.getUserNotificationSettings
    ) {
      const data = getUserNotificationSettingsData.getUserNotificationSettings.notificationSettings;
      setNotificationSettings(data);
      setCheckhandleChange(false);
    }
  }, [getUserNotificationSettingsData, refetchNotificationSettings]);

  useEffect(() => {
    const createdDateTime = notificationSettings?.sort(
      (a: any, b: any) => new Date(a.createdDate - b.createdDate)
    );
    const updateDateTime = notificationSettings?.sort(
      (a: any, b: any) => new Date(a.updateDate - b.updateDate)
    );
    const record = {
      createdBy:
        createdDateTime?.length > 0 && createdDateTime[0]?.createdBy
          ? createdDateTime[0]?.createdBy
          : 'System',
      createdDate: createdDateTime?.length > 0 && createdDateTime[0]?.createdDate,
      updatedBy: updateDateTime?.length > 0 && updateDateTime[0]?.updatedBy,
      updatedDate: updateDateTime?.length > 0 && updateDateTime[0]?.updateDate,
    };
    setTimeStamp(record);
  }, [notificationSettings]);

  const handleFilterOpening = (title: any): void => {
    if (title === 'Events') {
      setIsOpen(prev => !prev);
    }
    if (title === 'Inventory') {
      setIsOpenInventory(prev => !prev);
    }
    if (title === 'Orders') {
      setIsOpenOrders(prev => !prev);
    }
    if (title === 'Cycle Count') {
      setIsCycleCount(prev => !prev);
    }
  };

  const handleExpandAll = (): void => {
    setIsOpen(true);
    setIsOpenInventory(true);
    setIsOpenOrders(true);
    setIsCycleCount(true);
  };

  const handleCollapseAll = (): void => {
    setIsOpen(false);
    setIsOpenInventory(false);
    setIsOpenOrders(false);
    setIsCycleCount(false);
  };

  const handleCancel = (): void => {
    getUserNotificationSettings({
      variables: { userId: id, businessunit: userInfo?.businessunit },
    });
  };

  const handleSave = (): void => {
    let mutation: any = {};
    notificationSettings.forEach((e: any) => {
      delete e.businessunitCategoryId;
      delete e.isRequired;
      delete e.isEnabled;
      delete e.description;
      delete e.createdDate;
      delete e.updatedBy;
      delete e.createdBy;
      delete e.updateDate;
      delete e['__typename'];
    });
    mutation = {
      notificationSettings,
      userId: id,
    };
    createUpdateUserNotificationConfig({
      variables: mutation,
    }).catch(error => {
      const errMsg = error.message.split(':')[1] || 'Something went wrong. Please try again.';
      openSnackBar(NOTIFY_TYPE.ERROR, errMsg);
    });
    if (!checkhandleChange) {
      getUserNotificationSettings({
        variables: { userId: id, businessunit: userInfo?.businessunit },
      });
    }
  };

  const handleChange = (event: any, item: any): void => {
    const { value } = event.target;
    const updatedNotificationSettings = [...notificationSettings];
    updatedNotificationSettings.forEach((element: any) => {
      if (item.id) {
        if (item.id === element.id && item.subCategory === element.subCategory) {
          const getFirstTruthyItem = Object.keys(element).find(i => element[i] === true);
          /* eslint-disable no-param-reassign */
          if (getFirstTruthyItem) {
            element[getFirstTruthyItem] = false;
            element[value] = true;
          }
        }
      }
    });
    setCheckhandleChange(true);
    setNotificationSettings(updatedNotificationSettings);
  };
  const actions = (): ReactNode => (
    <>
      <PageHeaderControl>
        <DisplayRecordIndicator recordInfo={timeStamp} screen="settings" />
      </PageHeaderControl>
      <PageHeaderControl>
        <Button
          style={{ color: 'black', marginRight: '10px', fontWeight: 600 }}
          align="right"
          label="Cancel"
          onClick={handleCancel}
        />
        <Button className="yellow-btn" align="right" label="Save" onClick={handleSave} />
      </PageHeaderControl>
    </>
  );

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      {(loading || getNotificationLoader) && (
        <Spinner size="large" variant="base" assistiveText={{ label: 'Loading...' }} />
      )}
      <PageHeader
        className="slds-m-around_small slds-m-bottom_none"
        icon={
          <img
            src="icons/notification.svg"
            className="logo-image"
            alt="company"
            style={{ width: '2.75rem', height: '2.75rem' }}
          />
        }
        onRenderActions={actions}
        title={
          <div className="slds-grid slds-grid_vertical-align-center slds-p-top_xx-small">
            <span className="slds-col event_id">Notification Settings</span>
          </div>
        }
        variant="record-home"
      />
      <div
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none transferDetails"
        style={{ display: 'inline-block' }}
      >
        <div className="slds-x-small-buttons_horizontal" style={{ paddingBottom: '20px' }}>
          <Button
            style={{ color: 'black', marginRight: '20px', fontWeight: 600 }}
            label="Expand All"
            onClick={handleExpandAll}
          />
          <Button
            style={{ color: 'black', fontWeight: 600 }}
            label="Collapse All"
            onClick={handleCollapseAll}
          />
          <div style={{ float: 'right', fontWeight: 600, fontSize: 14 }}>
            <span style={{ color: 'red' }}>*</span> Required Notification
          </div>
        </div>

        <div>
          <Collapsible open={isOpen} handleFilterOpening={handleFilterOpening} title="Events">
            <div id="settings">
              {notificationSettings &&
                notificationSettings.map(
                  (item: any) =>
                    item.category === 'Events' &&
                    item.isEnabled && (
                      <div style={{ marginLeft: '20px', marginRight: '60px' }}>
                        <span
                          style={{ fontWeight: 600, fontSize: 14, marginBottom: '10px' }}
                          className="slds-grid slds-text-body_small"
                        >
                          {item.isRequired && <span style={{ color: 'red' }}>* </span>}
                          {` ${item.subCategory}`}
                          <span id="tooltip">
                            <Icon
                              category="utility"
                              name="info"
                              size="x-small"
                              style={{
                                fill: 'rgb(133 125 125)',
                                marginBottom: '3px',
                                marginLeft: '5px',
                              }}
                            />
                            <span className="tooltiptext">{item.description}</span>
                          </span>
                        </span>
                        {NOTIFICATIONS_RADIO_OPTIONS.map((value: any) => (
                          <Radio
                            className="radio-group"
                            disabled={item.isRequired && value.label === 'Off'}
                            onChange={(event: any) => handleChange(event, item)}
                            labels={{ label: value.label }}
                            value={value.key}
                            checked={item[value.key] === true}
                            variant="base"
                            size="medium"
                          />
                        ))}
                      </div>
                    )
                )}
            </div>
          </Collapsible>
          <Collapsible
            open={isOpenInventory}
            handleFilterOpening={handleFilterOpening}
            title="Inventory"
          >
            <div id="settings">
              {notificationSettings &&
                notificationSettings.map(
                  (item: any) =>
                    item.category === 'Inventory' &&
                    item.isEnabled && (
                      <div style={{ marginLeft: '20px', marginRight: '60px' }}>
                        <span
                          style={{ fontWeight: 600, fontSize: 14, marginBottom: '10px' }}
                          className="slds-grid slds-text-body_small"
                        >
                          {item.isRequired && <span style={{ color: 'red' }}>* </span>}
                          {` ${item.subCategory}`}
                          <span id="tooltip">
                            <Icon
                              category="utility"
                              name="info"
                              size="x-small"
                              style={{
                                fill: 'rgb(133 125 125)',
                                marginBottom: '3px',
                                marginLeft: '5px',
                              }}
                            />
                            <span className="tooltiptext">{item.description}</span>
                          </span>
                        </span>
                        {NOTIFICATIONS_RADIO_OPTIONS.map((value: any) => (
                          <Radio
                            className="radio-group"
                            disabled={item.isRequired && value.label === 'Off'}
                            onChange={(event: any) => handleChange(event, item)}
                            labels={{ label: value.label }}
                            value={value.key}
                            checked={item[value.key] === true}
                            variant="base"
                            size="medium"
                          />
                        ))}
                      </div>
                    )
                )}
            </div>
          </Collapsible>
          <Collapsible open={isOpenOrders} handleFilterOpening={handleFilterOpening} title="Orders">
            <div id="settings">
              {notificationSettings &&
                notificationSettings.map(
                  (item: any) =>
                    item.category === 'Orders' &&
                    item.isEnabled && (
                      <div style={{ marginLeft: '20px', marginRight: '60px' }}>
                        <span
                          style={{ fontWeight: 600, fontSize: 14, marginBottom: '10px' }}
                          className="slds-grid slds-text-body_small"
                        >
                          {item.isRequired && <span style={{ color: 'red' }}>* </span>}
                          {` ${item.subCategory}`}
                          <span id="tooltip">
                            <Icon
                              category="utility"
                              name="info"
                              size="x-small"
                              style={{
                                fill: 'rgb(133 125 125)',
                                marginBottom: '3px',
                                marginLeft: '5px',
                              }}
                            />
                            <span className="tooltiptext">{item.description}</span>
                          </span>
                        </span>
                        {NOTIFICATIONS_RADIO_OPTIONS.map((value: any) => (
                          <Radio
                            className="radio-group"
                            disabled={item.isRequired && value.label === 'Off'}
                            onChange={(event: any) => handleChange(event, item)}
                            labels={{ label: value.label }}
                            value={value.key}
                            checked={item[value.key] === true}
                            variant="base"
                            size="medium"
                          />
                        ))}
                      </div>
                    )
                )}
            </div>
          </Collapsible>
          <Collapsible
            open={isCycleCount}
            handleFilterOpening={handleFilterOpening}
            title="Cycle Count"
          >
            <div id="settings">
              {notificationSettings &&
                notificationSettings.map(
                  (item: any) =>
                    item.category === 'Cycle Count' &&
                    item.isEnabled && (
                      <div style={{ marginLeft: '20px', marginRight: '60px' }}>
                        <span
                          style={{ fontWeight: 600, fontSize: 14, marginBottom: '10px' }}
                          className="slds-grid slds-text-body_small"
                        >
                          {item.isRequired && <span style={{ color: 'red' }}>* </span>}
                          {` ${item.subCategory}`}
                          <span id="tooltip">
                            <Icon
                              category="utility"
                              name="info"
                              size="x-small"
                              style={{
                                fill: 'rgb(133 125 125)',
                                marginBottom: '3px',
                                marginLeft: '5px',
                              }}
                            />
                            <span className="tooltiptext">{item.description}</span>
                          </span>
                        </span>
                        {NOTIFICATIONS_RADIO_OPTIONS.map((value: any) => (
                          <Radio
                            className="radio-group"
                            disabled={item.isRequired && value.label === 'Off'}
                            onChange={(event: any) => handleChange(event, item)}
                            labels={{ label: value.label }}
                            value={value.key}
                            checked={item[value.key] === true}
                            variant="base"
                            size="medium"
                          />
                        ))}
                      </div>
                    )
                )}
            </div>
          </Collapsible>
        </div>
      </div>
    </IconSettings>
  );
};

export default withRouter(NotificationSetting);
