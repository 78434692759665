import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import { BrowserRouter as Router, RouteComponentProps, withRouter } from 'react-router-dom';

interface EventId {
  serviceLevel: string;
}

// interface for props that the data table cell is getting default from data table.
export interface Props extends RouteComponentProps {
  children?: FC;
  item?: EventId;
  action?: string;
}

const InventoryRequestIdCell: FC<Props> = ({
  item = {
    serviceLevel: null,
  },
}) => {
  const { serviceLevel } = item;
  return (
    <Router>
      <DataTableCell
        title={serviceLevel === '3PL' || serviceLevel === '3PL Delivery' ? 'Yes' : 'No'}
      >
        <span>{serviceLevel === '3PL' || serviceLevel === '3PL Delivery' ? 'Yes' : 'No'}</span>
      </DataTableCell>
    </Router>
  );
};

export default withRouter(InventoryRequestIdCell);
