import React, { FC } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Button from '@salesforce/design-system-react/components/button';

interface Props {
  requestModalVisible: boolean;
  handleRequestSubmittedModal: () => void;
}

const RequestSubmitted: FC<Props> = ({ requestModalVisible, handleRequestSubmittedModal }) => {
  return (
    <IconSettings iconPath="/icons">
      <div>
        <Modal
          className="submitted_modal_container"
          isOpen={requestModalVisible}
          footer={[<Button label="OK" onClick={handleRequestSubmittedModal} key="submitRequest" />]}
          onRequestClose={handleRequestSubmittedModal}
          heading="Request Submitted"
        >
          <div>
            <div className="slds-m-around_xx-large">
              <p className="slds-m-around_x-small submitted_modal">
                Thank you for submitting your request. Stryker will process your query in accordance
                with the applicable legal timelines.
              </p>
            </div>
          </div>
        </Modal>
      </div>
    </IconSettings>
  );
};

export default RequestSubmitted;
