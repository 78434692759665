/**
 * Exporting all Form JSON.
 * @module src/util/formJson
 */
import SurgeryFormJson from './surgery';
import SurgeryUsageOnlyFormJson from './surgeryUsageOnly';
import NewTrialFormJson from './newTrial';
import WorkshopFormJson from './workshop';
import BillOnlyUsageSheetFormJson from './billOnlyUsageSheet';
import ShipAndBillUsageSheetFormJson from './shipAndBillUsageSheet';
import InventoryRequest from './inventoryRequest';
import InventoryRequestWithShipping from './inventoryRequestWithShippingDetails';
import ShowGo from './showGo';
import CustomerTransfer from './customerTransfer';
import EventTransfer from './eventTransfer';
import RepTransfer from './repTransfer';
import PickupReturn from './pickUpReturn';
import StandardReturn from './standardReturn';
import ShowGoWithLocation from './showGoWithLocation';
import { Form } from '../../components/Shared/FormEngine';
import { USAGE_TYPE } from '../constants';
import MockORWithLocation from './MockORWithLocation';
import MockOR from './MockOR';
import HoldNewRequest from './holdNewRequest';

/** Exporting all form json */
export const getSchemeJson = (eventType: string): Form => {
  switch (eventType) {
    case 'Surgery Event': {
      return SurgeryFormJson;
    }
    case 'Surgery-Usage Only': {
      return SurgeryUsageOnlyFormJson;
    }
    case 'Inventory Request': {
      return InventoryRequest;
    }
    case 'Inventory Request Shipping': {
      return InventoryRequestWithShipping;
    }
    case 'Trial Events': {
      return NewTrialFormJson;
    }
    case 'Workshop Events': {
      return WorkshopFormJson;
    }
    case USAGE_TYPE.BILL_ONLY: {
      return BillOnlyUsageSheetFormJson;
    }
    case USAGE_TYPE.SHIP_AND_BILL: {
      return ShipAndBillUsageSheetFormJson;
    }
    case 'ShowGo': {
      return ShowGo;
    }
    case 'Customer Transfer': {
      return CustomerTransfer;
    }
    case 'Rep Transfer': {
      return RepTransfer;
    }
    case 'Event Transfer': {
      return EventTransfer;
    }
    case 'Pickup Return': {
      return PickupReturn;
    }
    case 'Inventory Return': {
      return StandardReturn;
    }
    case 'ShowGoWithLocation': {
      return ShowGoWithLocation;
    }
    case 'mockOR': {
      return MockOR;
    }
    case 'mockORWithLocation': {
      return MockORWithLocation;
    }
    case 'holdNewRequest': {
      return HoldNewRequest;
    }
    default:
      return SurgeryFormJson;
  }
};

export {
  SurgeryFormJson,
  SurgeryUsageOnlyFormJson,
  NewTrialFormJson,
  WorkshopFormJson,
  BillOnlyUsageSheetFormJson,
  ShipAndBillUsageSheetFormJson,
};
