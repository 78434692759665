/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import Icon from '@salesforce/design-system-react/components/icon';
import { ATTACHMENT_TYPE } from '../../../../../../../util/constants';

interface Props {
  children?: FC;
  item?: FileItem;
  handleAction: (option: any, id: string) => void;
}

interface FileItem {
  name: string;
  attachmentType: string;
  showOnUsageSheet: boolean;
}

const FileActionDataCell: FC<Props> = ({
  item = { name: '', attachmentType: '', showOnUsageSheet: false },
  handleAction,
}) => {
  const { name, attachmentType, showOnUsageSheet } = item;
  return (
    <DataTableCell>
      <IconSettings iconPath="/icons">
        <Dropdown
          assistiveText={{ icon: 'More Options' }}
          menuPosition="overflowBoundaryElement"
          iconCategory="utility"
          iconName="down"
          iconVariant="border-filled"
          onSelect={(option: any): void => {
            handleAction(option, name);
          }}
          multiple
          options={[
            {
              label: (
                <>
                  <Icon
                    assistiveText={{ label: 'Add' }}
                    category="utility"
                    size="x-small"
                    name={showOnUsageSheet ? 'check' : 'add'}
                  />
                  <span style={{ padding: '5px ' }}>Include in Usage Sheet</span>
                </>
              ),
              value: 'pdf',
            },
            {
              label: (
                <>
                  <Icon
                    assistiveText={{ label: 'Add' }}
                    category="utility"
                    size="x-small"
                    name={attachmentType === ATTACHMENT_TYPE.PO ? 'check' : 'add'}
                  />
                  <span style={{ padding: '5px' }}>PO Label</span>
                </>
              ),
              value: 'po',
            },
            { label: 'Delete', value: 'del' },
          ]}
        />
      </IconSettings>
    </DataTableCell>
  );
};

FileActionDataCell.displayName = DataTableCell.displayName;

export default FileActionDataCell;
