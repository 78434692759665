/*
 * User Info GraphQL specification
 * @module src/graphql/getUserInfo
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/** User Info GraphQL API query */
export const GET_ADMIN_FROM_CONFIG = gql`
  query getAdminFormConfigurations($screen: String) {
    getAdminFormConfigurations(screen: $screen) {
      isBUFormRequired
      configurations {
        formId
        businessunitId
        moduleCode
        moduleName
        screenCode
        screenName
        fieldCode
        fieldName
        mappingKey
        isShow
        isRequired
        isEditable
        serialNumber
        description
        header
        isheader
        businessunitName
        createdDate
        updatedDate
        updatedBy
        createdBy
      }
      businessRules {
        formId
        rules
        serialNumber
        values
        isRequired
        description
        screenName
        moduleName
        screenType
        businessunitName
        screenCode
        businessunitId
        isheader
        value_type
        header
        iseditable
        createdDate
        updatedDate
        updatedBy
        createdBy
      }
      parts {
        formId
        businessunitId
        moduleCode
        moduleName
        screenCode
        screenName
        fieldCode
        fieldName
        mappingKey
        isShow
        isRequired
        isEditable
        serialNumber
        description
        businessunitName
      }
    }
  }
`;
