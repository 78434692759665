/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useState, useCallback, useEffect } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Pill from '@salesforce/design-system-react/components/pill';
import Icon from '@salesforce/design-system-react/components/icon';
import RadioGroup from '@salesforce/design-system-react/components/radio-group';
import Radio from '@salesforce/design-system-react/components/radio-group/radio';
import moment from 'moment';
import DatePicker from '../Shared/DatePicker';
import { getUserId } from '../../store/ducks/userId';
import { getUserInfo } from '../../store/ducks/userInfo';
import {
  DATE_VIEW_FORMATTER,
  FILTER_NOTIFICATIONS_TYPE,
  READ_NOTIFICATION_FILTERS,
  FLAG_FILTERS,
} from '../../util/constants';
import { GET_USER_TERRITORIES } from '../../graphql/getUserTerritories';
import { GET_SALES_REPS } from '../../graphql/getSalesReps';
import { clearNotificationFilters } from '../../store/ducks/notificationFilters';

interface Props {
  handleToggleNotificationFilter: () => void;
  filterModalVisible: boolean;
  handleFilterNotification: (filter: DynamicType | null) => void;
  resetFilters: () => void;
  selectedFilter?: DynamicType | null;
  type?: string;
  // //   handleEditFilter: (editEvent: boolean) => void;
}

interface SelectedData {
  id: string;
  label: string;
}

interface DynamicType {
  [key: string]: any;
}

interface SelectedFilters {
  status: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  inventoryLocation: SelectedData[];
}

interface DropdownSelect {
  id: string;
  label?: string;
  value: string;
}

interface ReadNotifications {
  key: string;
  label: string;
}

const FilterCycleCount: FC<Props> = ({
  handleToggleNotificationFilter,
  filterModalVisible,
  selectedFilter,
  handleFilterNotification,
  resetFilters,
  //   handleEditFilter,
}) => {
  const userInfo = useSelector(getUserInfo);
  const filterValues = selectedFilter?.filterValues;
  const dispatch = useDispatch();
  const { data: salesRepsData } = useQuery(GET_SALES_REPS);
  const [salesRepOptions, setSalesRepOptions] = useState<DropdownSelect[]>();
  const [selectedFilters, setSelectedFilters] = useState<DynamicType | null>(filterValues || {});
  const [checkedReadOption, setCheckedReadOption] = useState('');
  const [checkedFlaggedOption, setCheckedFlaggedOption] = useState('');
  const saleRepCheck =
    (userInfo && userInfo.personas === 'Branch Op') ||
    (userInfo && userInfo.personas === 'Sales Associate') ||
    (userInfo && userInfo.personas === 'Sales Manager');

  useEffect(() => {
    let selectedData = {};
    if (!checkedReadOption && !selectedFilters?.readNotifications) {
      setCheckedReadOption('All');
      selectedData = Object.assign(selectedFilters, {
        readNotifications: 'All',
      });
      setSelectedFilters(selectedData);
    }
    if (!checkedFlaggedOption && !selectedFilters?.starFlag) {
      setCheckedFlaggedOption('All');
      selectedData = Object.assign(selectedFilters, {
        starFlag: 'All',
      });
      setSelectedFilters(selectedData);
    }
  }, []);

  // const getApiDateTime = useCallback((eventDate: any, name: string): string => {
  //   const formattedSelectedDate = new Date(eventDate);
  //   let updatedDate;

  //   if (name.toLowerCase().includes('notificationFromDate') || name.toLowerCase().includes('notificationTodate')) {
  //     updatedDate = moment(formattedSelectedDate)
  //     .format('MM-DD-YYYY')
  //   } else {
  //     updatedDate = moment(formattedSelectedDate)
  //     .format('MM-DD-YYYY')
  //   }

  //   const finalDate = updatedDate
  //   return finalDate;
  // }, []);

  const getDate = useCallback((date: any): string => {
    const formattedSelectedDate = new Date(date);
    const finalDate = format(new Date(formattedSelectedDate), 'yyyy-MM-dd');
    return finalDate;
  }, []);

  const optionsWithLabel = (arr: []): any[] =>
    arr &&
    arr.map((elem: any) => {
      return { ...elem, label: `${elem.value}` };
    });

  useEffect(() => {
    if (salesRepsData?.getSalesReps) {
      setSalesRepOptions(optionsWithLabel(salesRepsData.getSalesReps));
    }
  }, [salesRepsData]);

  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any): void => {
      // For selected filter value
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };

      setSelectedFilters(selectedFilterValues);
    },
    [selectedFilters]
  );

  const handleSelect = useCallback(
    (data: any, name: string) => {
      const { selection } = data;
      setFilterValue(name, selection);
    },
    [setFilterValue]
  );

  const reset = (): void => {
    handleToggleNotificationFilter();
    setSelectedFilters(null);
    resetFilters();
    dispatch(clearNotificationFilters());
  };

  const handleChangeDate = (selectedDate: any, name: string): void => {
    if (selectedDate) {
      const utcDate = getDate(selectedDate);
      setFilterValue(name, utcDate);
    }
  };

  const handleRadioButtonChange = (event: any, name: string): void => {
    let selectedData = {};
    if (name === 'readNotifications') {
      setCheckedReadOption(event.target.value);
      if (event.target.value === 'All') {
        selectedData = Object.assign(selectedFilters, {
          readNotifications: 'All',
        });
      } else if (event.target.value === 'unreadOnly') {
        selectedData = Object.assign(selectedFilters, {
          readNotifications: 'Unread only',
        });
      } else if (event.target.value === 'readOnly') {
        selectedData = Object.assign(selectedFilters, {
          readNotifications: 'Read only',
        });
      }
      setSelectedFilters(selectedData);
    }

    if (name === 'starFlag') {
      setCheckedFlaggedOption(event.target.value);
      if (event.target.value === 'All') {
        selectedData = Object.assign(selectedFilters, {
          starFlag: 'All',
        });
      } else if (event.target.value === 'flagged') {
        selectedData = Object.assign(selectedFilters, {
          starFlag: 'Flagged only',
        });
      } else if (event.target.value === 'unflagged') {
        selectedData = Object.assign(selectedFilters, {
          starFlag: 'Not Flagged',
        });
      }
      setSelectedFilters(selectedData);
    }
  };

  const handleApplyFilter = (): void => {
    handleToggleNotificationFilter();
    handleFilterNotification(selectedFilters);
  };

  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={filterModalVisible}
        onRequestClose={handleToggleNotificationFilter}
        footer={[
          <Button
            className="slds-float_left slds-text-color_default"
            label="Cancel"
            key="Cancel"
            onClick={handleToggleNotificationFilter}
          />,
          <Button label="Reset Filter" key="Reset" onClick={reset} />,
          <Button
            className="yellow-btn"
            label="Apply"
            onClick={handleApplyFilter}
            disabled={
              selectedFilters?.notificationToDate &&
              selectedFilters?.notificationFromDate &&
              selectedFilters.notificationToDate < selectedFilters.notificationFromDate
            }
            variant="brand"
            key="Apply"
          />,
        ]}
        heading={[
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div className="slds-float_left slds-text-title_bold filter_heading" key="filterName">
            Filters
          </div>,
        ]}
      >
        <section className="slds-p-around_medium slds-theme_shade">
          {saleRepCheck && (
            <>
              <div className="slds-card slds-p-around--medium" id="notification-salesRepOptions">
                <h1 className="slds-m-bottom--xx-small">Sales Rep</h1>
                <Combobox
                  id="sales Rep"
                  events={{
                    onSelect: (event: any, data: any): void => handleSelect(data, 'salesRep'),
                    onRequestRemoveSelectedOption: (event: any, data: any): void =>
                      handleSelect(data, 'salesRep'),
                  }}
                  labels={{
                    label: `Sales Rep`,
                    placeholder: 'Select Sales Rep',
                    noOptionsFound: 'No data found',
                  }}
                  multiple
                  placeholder="Select Sales Rep"
                  options={salesRepOptions || []}
                  selection={selectedFilters ? selectedFilters.salesRep : []}
                  variant="readonly"
                />
              </div>
            </>
          )}
          <div className="slds-card slds-p-around--medium" id="notificationType">
            <h1 className="slds-m-bottom--xx-small">Type</h1>
            <Combobox
              id="type"
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'type'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'type'),
              }}
              labels={{
                label: `Type`,
                placeholder: 'Select Type',
                noOptionsFound: 'No data found',
              }}
              multiple
              placeholder="Select Type"
              options={FILTER_NOTIFICATIONS_TYPE || []}
              selection={selectedFilters ? selectedFilters.type : []}
              variant="readonly"
            />
          </div>
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--xx-small">Notification Date</h1>
            {/* <span className='date-labels'>From Date</span>
            <span className='date-labels to-date' style={{marginLeft:'188px'}}>To Date</span> */}
            <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
              <div className="slds-col slds-align_absolute-center">
                <DatePicker
                  label="From Date"
                  placeholder="From Date"
                  handleChangeDate={(data: any): void =>
                    handleChangeDate(data, 'notificationFromDate')
                  }
                  disabled={false}
                  isFilterDate={true}
                  value={selectedFilters ? selectedFilters.notificationFromDate : null}
                />
              </div>
              <div className="slds-col slds-align_absolute-center">
                <span></span>
              </div>
              <div className="slds-col slds-align_absolute-center">
                <DatePicker
                  label="To Date"
                  placeholder="To Date"
                  handleChangeDate={(data: any): void =>
                    handleChangeDate(data, 'notificationToDate')
                  }
                  disabled={false}
                  isFilterDate={true}
                  value={selectedFilters ? selectedFilters.notificationToDate : null}
                />
              </div>
            </div>
          </div>

          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--small">Read Notifications</h1>
            <div className="slds-grid">
              <RadioGroup
                className="slds-grid"
                onChange={(_event: any): void => {
                  handleRadioButtonChange(_event, 'readNotifications');
                }}
              >
                {READ_NOTIFICATION_FILTERS.map(value => (
                  <Radio
                    className="radio-group-with-space"
                    labels={{ label: value.label }}
                    value={value.key}
                    checked={
                      selectedFilters?.readNotifications === value.label ||
                      checkedReadOption === value.key
                    }
                    variant="base"
                    size="medium"
                  />
                ))}
              </RadioGroup>
            </div>
          </div>

          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--small">Flag</h1>
            <div className="slds-grid">
              <RadioGroup
                className="slds-grid"
                onChange={(_event: any): void => {
                  handleRadioButtonChange(_event, 'starFlag');
                }}
              >
                {FLAG_FILTERS.map(value => (
                  <Radio
                    className="radio-group-with-space"
                    labels={{ label: value.label }}
                    value={value.key}
                    checked={
                      selectedFilters?.starFlag === value.label ||
                      checkedFlaggedOption === value.key
                    }
                    variant="base"
                    size="medium"
                  />
                ))}
              </RadioGroup>
            </div>
          </div>

          {selectedFilters?.notificationToDate &&
            selectedFilters?.notificationFromDate &&
            selectedFilters.notificationToDate < selectedFilters.notificationFromDate && (
              <Pill
                labels={{
                  label: 'Error: To Date must be greater than or equal to From Date',
                }}
                hasError
                icon={
                  <Icon
                    title="Error"
                    category="utility"
                    name="warning"
                    className="slds-icon-text-error"
                  />
                }
              />
            )}
        </section>
      </Modal>
    </IconSettings>
  );
};

export default FilterCycleCount;
