/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Module for header on Surgical Detail Page
 * @module src/SurgicalDetail/SurgicalDetailHeader
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { unset } from 'lodash';
import Combobox from '@salesforce/design-system-react/components/combobox';
import { useSelector } from 'react-redux';
import debounced from '../../../../util/debounced';
import { GET_PRODUCT_LIST } from '../../../../graphql/getProductList';
import { GET_FILTERED_PRODUCT_GROUPS } from '../../../../graphql/getFilteredProductGroups';
import './index.scss';
import SnackBar from '../../../Shared/SnackBar';
import { MAX_QUANTITY, NOTIFY_TYPE } from '../../../../util/constants';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import CartComponent from '../../../Shared/CartComponent';
import CREATE_UPDATE_INVENTORY_FAVORITE from '../../../../graphql/mutations/createUpdateInventoryFavorites';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import { getHoldIcon } from '../../../../util/utilityFunctions';

interface Props extends RouteComponentProps {
  isOpen?: boolean;
  handleClose: () => void;
  inventoryRequestFav?: any;
  history: any;
  isEventInventoryFlow?: boolean;
  updateLineItems: (item: any) => void;
  handleFilter: (filter: DynamicType | null) => void;
  selectedFilter?: DynamicType | null;
}

interface DynamicType {
  [key: string]: any;
}

enum DetailTypes {
  ProductGroup = 'ProductGroup',
}

const InventoryFavoriteAddItems: FC<Props> = ({
  isOpen,
  handleClose,
  inventoryRequestFav,
  history,
  updateLineItems,
  // isEventInventoryFlow,
  selectedFilter,
  handleFilter,
}) => {
  const userInfo = useSelector(getUserInfo);
  const { open, notification, openSnackBar } = useSnackBar();
  const [productList, setProductList] = useState([]);
  const [addPartsLocally, setAddPartsLocally] = useState<any[]>(inventoryRequestFav.lineItems);
  const quantityCount = inventoryRequestFav?.lineItems.reduce(
    (accumulator: any, current: { quantity: any }) => accumulator + Number(current.quantity),
    0
  );
  const [catalogCount, setCatalogCount] = useState(quantityCount);
  // eslint-disable-next-line prefer-const
  let [cartCount, setCartCount] = useState(0);
  const filterValues = selectedFilter?.filterValues;
  const [selectedFilters, setSelectedFilters] = useState<DynamicType | null>(filterValues || {});
  const [searchTerm, setSearchText] = useState('');
  const [selectedGrps, setSelectedGrps] = useState<any>(
    selectedFilters?.productGroup?.map((item: any) => item.id) || []
  );
  const { data: filteredproductGroups, loading: loadingProductGroup } = useQuery(
    GET_FILTERED_PRODUCT_GROUPS
  );
  const [productListItems, { data: productItems, loading }] = useLazyQuery(GET_PRODUCT_LIST);

  const [createUpdateInventoryFavorite, { data: favoriteCreateUpdateMutation }] = useMutation(
    CREATE_UPDATE_INVENTORY_FAVORITE
  );

  const optionsWithLabel = (value: any): object =>
    value &&
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === DetailTypes.ProductGroup) {
        return { ...elem, label: `${elem.value}` };
      }
    });
  const productGroup = optionsWithLabel(filteredproductGroups?.getProductGroups);

  const getProductRecord = (product: any): any => {
    let returnVal = null;
    if (
      inventoryRequestFav &&
      inventoryRequestFav.lineItems &&
      inventoryRequestFav.lineItems.length
    ) {
      returnVal = inventoryRequestFav.lineItems.find(
        (rec: any) => rec.part?.id === product.productId
      );
    }
    return returnVal;
  };

  useEffect(() => {
    if (productItems && productItems.getProductList && productItems.getProductList.length) {
      productItems.getProductList.forEach((list: any) => {
        const record = getProductRecord(list);
        /* eslint-disable no-param-reassign */
        const changedIndex = addPartsLocally.find(
          (index: any) => index.part?.id === list.productId
        );
        list.counterValue = record ? Number(record.quantity) : 0;
        if (changedIndex) {
          list.counterValue = changedIndex.counterValue || Number(changedIndex.quantity);
        }
        if (record) {
          list.usageExternalId = record.usageExternalId;
        }
        list.changed = false;
      });
      setCartCount(catalogCount);
      setProductList(productItems.getProductList);
    }
  }, [productItems]);

  useEffect(() => {
    if (
      favoriteCreateUpdateMutation &&
      favoriteCreateUpdateMutation.createUpdateInventoryFavorite
    ) {
      handleClose();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [favoriteCreateUpdateMutation]);

  const setEventPartQuantity = (searchedItem: string): void => {
    if (searchedItem.length > 2) {
      productListItems({
        variables: {
          searchText: searchedItem,
          caseId: inventoryRequestFav.caseId ? inventoryRequestFav.caseId : null,
          caseExternalId: inventoryRequestFav.externalId ? inventoryRequestFav.externalId : null,
          salesRepId:
            inventoryRequestFav.salesRep && inventoryRequestFav.salesRep.id
              ? inventoryRequestFav.salesRep.id
              : null,
          filters: {
            productName: selectedGrps,
            businessUnit: [userInfo?.businessunit] || ['Sports Med'],
            userId: userInfo?.id,
          },
        },
      });
    } else {
      productListItems({
        variables: {
          searchText: '',
          caseId: inventoryRequestFav.caseId ? inventoryRequestFav.caseId : null,
          caseExternalId: inventoryRequestFav.externalId ? inventoryRequestFav.externalId : null,
          salesRepId:
            inventoryRequestFav.salesRep && inventoryRequestFav.salesRep.id
              ? inventoryRequestFav.salesRep.id
              : null,
          filters: {
            productName: selectedGrps,
            businessUnit: [userInfo?.businessunit] || ['Sports Med'],
            userId: userInfo?.id,
          },
        },
      });
    }
  };

  const debouncedFetchSetItems = useCallback(debounced(500, setEventPartQuantity), [
    debounced,
    setEventPartQuantity,
  ]);
  const holdIconPath = getHoldIcon();
  // Get default Parts on load
  useEffect(() => {
    productListItems({
      variables: {
        searchText: '',
        caseId: inventoryRequestFav.caseId ? inventoryRequestFav.caseId : null,
        caseExternalId: inventoryRequestFav.externalId ? inventoryRequestFav.externalId : null,
        salesRepId:
          inventoryRequestFav.salesRep && inventoryRequestFav.salesRep.id
            ? inventoryRequestFav.salesRep.id
            : null,
        filters: {
          productName: selectedGrps,
          businessUnit: [userInfo?.businessunit] || ['Sports Med'],
          userId: userInfo?.id,
        },
      },
    });
  }, [productListItems]);

  const searchCatalog = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      //   setInputValue(value);
      if (value && value.length >= 2) {
        debouncedFetchSetItems(value);
      }
    },
    [debouncedFetchSetItems]
  );

  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any): void => {
      // For selected filter value
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };

      setSelectedFilters(selectedFilterValues);
    },
    [selectedFilters]
  );

  const handleProductList = (selectedItem: any): void => {
    const selectedOptions = selectedItem?.map((item: any) => item.id);
    setSelectedGrps(selectedOptions);
    productListItems({
      variables: {
        caseId: inventoryRequestFav.caseId ? inventoryRequestFav.caseId : null,
        caseExternalId: inventoryRequestFav.externalId ? inventoryRequestFav.externalId : null,
        salesRepId:
          inventoryRequestFav.salesRep && inventoryRequestFav.salesRep.id
            ? inventoryRequestFav.salesRep.id
            : null,
        filters: {
          productName: selectedOptions,
          businessUnit: [userInfo?.businessunit] || ['Sports Med'],
          userId: userInfo?.id,
        },
        searchText: searchTerm,
      },
    });
  };

  const handleSelect = useCallback(
    (data: any, name: string) => {
      const { selection } = data;
      setFilterValue(name, selection);
      handleProductList(selection);
    },
    [handleProductList, setFilterValue]
  );

  const handleCount = (event: any, data: { value: string; number: number }, product: any): void => {
    const updatedList = [...productList];
    let partsCounter = catalogCount;
    let decremenmtFlag = false;
    updatedList.forEach((element: any, i: number) => {
      if (product.productId === element.productId) {
        if (product.counterValue > data.number) cartCount -= product.counterValue - data.number;
        else {
          cartCount += data.number - product.counterValue;
        }
        /* eslint-disable no-param-reassign */
        decremenmtFlag = data.number < element.counterValue;
        element.counterValue = data.number < MAX_QUANTITY ? data.number : MAX_QUANTITY;
        element.changed = true;
        partsCounter += element.changed ? 1 : 0;
        const addedIndex = addPartsLocally.findIndex((e: any) => {
          if (e?.part) {
            return e?.part?.id === element.productId;
            // eslint-disable-next-line no-else-return
          } else {
            return e.productId === element.productId;
          }
        });
        if (addedIndex > -1) {
          addPartsLocally[addedIndex] = updatedList[i];
        } else {
          addPartsLocally.push(updatedList[i]);
        }
      }
    });
    setAddPartsLocally(addPartsLocally);
    setProductList(updatedList);
    if (partsCounter < catalogCount) {
      partsCounter = partsCounter === 1 && !decremenmtFlag ? catalogCount + 1 : partsCounter;
    } else if (decremenmtFlag) {
      partsCounter -= 2;
    }
    setCatalogCount(partsCounter);
  };
  const handleCountalert = (
    event: any,
    data: { value: string; number: number },
    product: any
  ): void => {
    if (product?.productOnHold) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );
    }
    handleCount(event, data, product);
  };
  const onAddClick = (): void => {
    const payload: Array<any> = [];
    addPartsLocally.forEach((product: any): void => {
      if (product?.part) {
        payload.push({
          part: {
            id: product?.part?.id,
            name: product?.part?.name,
          },
          lotNumber: product.lotNumber || '',
          quantity: product?.quantity.toString(),
        });
      } else if (product.counterValue !== 0) {
        payload.push({
          part: {
            id: product.productId,
            name: `${product.productNumber ? product.productNumber : product.productNo} ${
              product.productdescription
            }`,
          },
          lotNumber: product.lotNumber || '',
          quantity: product.counterValue.toString(),
        });
      }
    });

    const mutation = { ...inventoryRequestFav };
    mutation.lineItems = payload;

    // eslint-disable-next-line no-underscore-dangle
    delete mutation.__typename;
    // eslint-disable-next-line no-underscore-dangle
    delete mutation._id;
    // eslint-disable-next-line no-underscore-dangle
    unset(mutation, 'account.__typename');
    unset(mutation, 'salesRep.__typename');
    unset(mutation, 'shipTo.__typename');
    unset(mutation, 'userDetails.__typename');

    mutation.lineItems.forEach(function deleteKey(v: any): void {
      // eslint-disable-next-line no-underscore-dangle
      delete v.part.__typename;
      // eslint-disable-next-line no-underscore-dangle
      delete v.__typename;
      delete v?.counterValue;
      delete v?.changed;
    });
    delete mutation.favComments;
    delete mutation.comments;
    mutation.recordType = 'update';
    createUpdateInventoryFavorite({
      variables: mutation,
    })
      .then(() => {
        updateLineItems(mutation.lineItems);
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Line Items of Inventory Fav successfully updated');
      })
      .catch((error: any) => {
        openSnackBar(NOTIFY_TYPE.ERROR, 'Error: Updating Line Items to Inventory Fav');
        /* eslint-disable no-console */
        console.log(error, ' - We are having issue updating the line items for Inventory Fav');
      });
  };
  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      <div>
        <Modal
          isOpen={isOpen}
          dismissOnClickOutside={false}
          footer={[
            <Button label="Cancel" onClick={handleClose} />,
            <Button label="Add" variant="brand" onClick={onAddClick} />,
          ]}
          onRequestClose={handleClose}
        >
          <div style={{ display: 'grid', padding: '0.75rem', gridTemplateColumns: '1fr 1fr' }}>
            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>Add Parts</div>
            <div style={{ textAlign: 'end' }}>
              <CartComponent count={catalogCount} color="black" textColor="white" />
            </div>
          </div>
          <div style={{ padding: '0rem 0.75rem 0rem 0.75rem' }}>
            <Tabs id="tabs-example-default" style={{ border: 'none' }}>
              <TabsPanel label="Catalog">
                <div>
                  <div className="full-width_button-product" id="product-catalog">
                    <Combobox
                      id="product"
                      events={{
                        onSelect: (event: any, data: any): void =>
                          handleSelect(data, 'productGroup'),
                        onRequestRemoveSelectedOption: (event: any, data: any): void =>
                          handleSelect(data, 'productGroup'),
                      }}
                      labels={{
                        label: `Select Product Group`,
                        placeholder: 'Select Product Group',
                        noOptionsFound: 'No data found',
                      }}
                      placeholder="Select"
                      options={productGroup || []}
                      hasInputSpinner={loadingProductGroup}
                      selection={selectedFilters ? selectedFilters.productGroup : []}
                      variant="readonly"
                    />
                  </div>
                  <div className="catalog-input">
                    <Input
                      assistiveText={{ spinner: 'Field data is loading' }}
                      iconRight={
                        <InputIcon
                          assistiveText={{
                            icon: 'Search',
                          }}
                          name="search"
                          category="utility"
                        />
                      }
                      onChange={(_event: any, searchItem: string): void =>
                        searchCatalog(_event, searchItem)
                      }
                      hasSpinner={loading}
                      id="unique-id-4"
                      placeholder="Search Catalog"
                    />
                  </div>
                  <section className="slds-p-around_large-1">
                    {productList &&
                      productList.map((product: any) => (
                        <div
                          style={{
                            padding: '5px',
                            borderBottom: '1px solid #545857',
                            height: '3rem',
                            display: 'grid',
                            gridTemplateColumns: '4fr 1fr',
                          }}
                        >
                          <div style={{ marginTop: '5px' }}>
                            {`${
                              product.productNumber ? product.productNumber : product.productNo
                            } ${product.productdescription}`}
                            {product.productOnHold ? (
                              <>
                                <img
                                  src={holdIconPath}
                                  className="logo-image"
                                  alt="company"
                                  style={{ marginLeft: '8px', marginRight: '8px' }}
                                />
                                On Hold
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            <Input
                              id="counter-input-3"
                              minValue={0}
                              maxValue={MAX_QUANTITY}
                              disabled={
                                !!(
                                  product &&
                                  product.lineVisualIndicator &&
                                  product.lineVisualIndicator.toLowerCase() === 'approved'
                                )
                              }
                              onChange={(
                                event: any,
                                data: { value: string; number: number }
                              ): void => {
                                handleCountalert(event, data, product);
                              }}
                              value={product.counterValue}
                              variant="counter"
                            />
                          </div>
                        </div>
                      ))}
                  </section>
                </div>
              </TabsPanel>
            </Tabs>
          </div>
        </Modal>
      </div>
    </IconSettings>
  );
};

/** Custom Header on Inventory Add Items Page **/
export default withRouter(InventoryFavoriteAddItems);
