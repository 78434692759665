/*
 * Get Branches GraphQL specification
 * @module src/graphql/getBranches
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_USER_CC_HOLD = gql`
  query getCycleCountHoldInfo($id: String, $accountId: String, $name: String) {
    getCycleCountHoldInfo(id: $id, accountId: $accountId, name: $name) {
      id
      onHold
      onHoldReason
    }
  }
`;
