import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_SALES_REPS = gql`
  query getAllSalesReps($filters: OPSListFilters) {
    getAllSalesReps(filters: $filters) {
      id
      value
      branchId
      subInventoryCode
      salesOrganization
      hospital
    }
  }
`;
