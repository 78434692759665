/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Module for Custom Rep/Covering Cell.
 * @module src/Events/EventsList
 */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';

interface Quantity {
  needToDisplay: boolean;
  expirationDate: string;
}
interface Props {
  item?: Quantity;
}

const ExpirationDate: FC<Props> = ({
  item = {
    needToDisplay: false,
    expirationDate: '',
  },
}) => {
  const { needToDisplay, expirationDate } = item;

  return needToDisplay ? (
    <DataTableCell title={expirationDate} className="center-align">
      <div>{expirationDate || '--'}</div>
    </DataTableCell>
  ) : null;
};

/** Custom Rep/Covering cell */
export default ExpirationDate;
