/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Button from '@salesforce/design-system-react/components/button';
import { BrowserRouter as Router, RouteComponentProps, withRouter } from 'react-router-dom';
import { getEventIconInner } from '../../../util/utilityFunctions';

interface EventId {
  caseExternalId: string;
  eventId: string;
  caseNumber: string;
  orderId: string;
  eventType: string;
  parentCaseExternalId?: string;
  parentCaseId?: string;
  parentEventStartDate?: string;
  parentEventId?: string;
  parentEventType?: any;
  parentEventStatus?: string;
}

// interface for props that the data table cell is getting default from data table.
export interface Props extends RouteComponentProps {
  children?: FC;
  item?: EventId;
  type?: string;
}

const OrderRequestIdCell: FC<Props> = ({
  item = {
    caseExternalId: null,
    eventId: null,
    caseNumber: null,
    eventType: null,
    parentCaseExternalId: null,
    parentCaseId: null,
    parentEventStartDate: null,
    parentEventId: null,
    parentEventType: null,
    parentEventStatus: null,
  },
  history,
}) => {
  const { parentEventType, eventType, parentEventId, parentCaseExternalId } = item;

  const goToSurgicalDetail = (): void => {
    history.push('/surgicalDetails', {
      eventType,
      externalId: parentCaseExternalId,
      id: parentEventId,
    });
  };
  const iconPath = getEventIconInner(parentEventType);
  return (
    <Router>
      <DataTableCell title={parentEventId || ''}>
        {parentEventId ? (
          <>
            <img src={iconPath} className="logo-image" alt="company" />
            <Button
              style={{ margin: '5px 5px 5px 5px' }}
              label={parentEventId || ''}
              variant="base"
              onClick={goToSurgicalDetail}
            />
          </>
        ) : (
          '--'
        )}
      </DataTableCell>
    </Router>
  );
};

export default withRouter(OrderRequestIdCell);
