/*
 * Get SubInventory GraphQL specification
 * @module src/graphql/getPartDetails
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export interface PartDetail {
  subInventoryName: string;
  productNumber: string;
  productDescription: string;
  lotNumber: string;
  availableqty: string;
  onhandqty: string;
  lotonhold: boolean;
  recalled: boolean;
  expirationDate: Date;
  expired: boolean;
}

/** SubInventory Items GraphQL data interface */
export interface SubInventoryData {
  partDetails: PartDetail[];
}

export interface Filters {
  ownership?: string;
  status?: string[];
  searchText?: string;
  subInventoryName?: string;
  productNumber?: string;
  expirationFromDate?: Date | string;
  expirationToDate?: Date | string;
  productGroup?: string;
}
export const GET_PARTDETAILS = gql`
  query getPartDetails(
    $limit: Int
    $offset: Int
    $subInventoryName: String!
    $productNumber: String!
  ) {
    getPartDetails(
      limit: $limit
      offset: $offset
      subInventoryName: $subInventoryName
      productNumber: $productNumber
    ) {
      subInventoryName
      productNumber
      productDescription
      partonhold
      lotDetails {
        id
        lotNumber
        expirationDate
        availableqty
        onhandqty
        lotonhold
      }
    }
  }
`;
