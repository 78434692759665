/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import moment from 'moment';
import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Button from '@salesforce/design-system-react/components/button';
import EventStatusBadge from '../../Shared/EventStatusBadge';
import './index.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends RouteComponentProps {
  children?: FC;
  items?: any;
}

// eslint-disable-next-line no-empty-pattern
const ReturnsList: FC<Props> = ({ items, history }) => {
  const goToReturnDetails = (e: any, externalId: any, orderId: any, status: any): void => {
    if (
      status === 'New' ||
      status === 'Cancelled' ||
      status === 'Label Ready' ||
      status === 'Label Requested' ||
      status === 'Requested'
    ) {
      history.push('/inventoryReturnDetails', {
        externalId,
        type: 'Returns',
        orderId,
      });
    } else if (status === 'Failed') {
      history.push('/inventoryReturnDetails', {
        externalId,
        type: 'Failed',
        orderId,
      });
    } else {
      history.push('/inventoryReturnDetails', {
        externalId,
        type: 'Completed',
        orderId,
      });
    }
  };
  return (
    <>
      <div className="parts-list-table-container">
        <div className="parts-list-table-header list-grid-4-cols">
          <p>Return</p>
          <p>Return Type</p>
          <p>Status</p>
          <p>Return Expiration</p>
        </div>
        {items &&
          items?.map((lineItem: any) => (
            <div className="parts-list-table-row list-grid-4-cols">
              <p className="vertical-align-middle">
                <Button
                  label={lineItem.name || 'TBD'}
                  variant="base"
                  onClick={(e: any) =>
                    goToReturnDetails(
                      e,
                      lineItem?.externalId,
                      lineItem?.orderSfid,
                      lineItem?.status
                    )
                  }
                />
              </p>
              <p className="vertical-align-middle">
                {lineItem?.type === 'Inventory Return' ? 'Standard Return' : lineItem?.type}
              </p>
              <p className="vertical-align-middle">
                <EventStatusBadge
                  status={
                    (lineItem?.status === 'Failed' &&
                    (lineItem?.visualIndicator === 'Failed' ||
                      lineItem?.visualIndicator === 'Approved')
                      ? 'Approved'
                      : lineItem?.status) || 'NEW'
                  }
                />
              </p>
              <p className="vertical-align-middle">
                {' '}
                {lineItem?.returnExpiration ? moment(lineItem?.returnExpiration).format('L') : '--'}
              </p>
            </div>
          ))}
      </div>
    </>
  );
};

export default withRouter(ReturnsList);
