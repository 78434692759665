/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Module for Custom Rep/Covering Cell.
 * @module src/Events/EventsList
 */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';

interface Quantity {
  quantity: string;
  availablequantity: string;
  productdescription: string;
  createdByDetails: any[];
  id: string;
  isOpen: boolean;
  needToDisplay: boolean;
  productNumber: string;
  isCycleCountStarted: boolean;
  productDescription?: string;
}

interface Props {
  item?: Quantity;
}

const PartDescriptionCellForRep: FC<Props> = ({
  item = {
    quantity: '',
    availablequantity: '',
    productdescription: '',
    productDescription: '',
    productNumber: '',
    createdByDetails: [],
    id: '',
    isOpen: false,
    needToDisplay: false,
  },
}) => {
  const { needToDisplay, productNumber, productdescription, productDescription } = item;

  return needToDisplay ? (
    <DataTableCell title={productDescription || productdescription} className="left-align">
      {/* {quantity !== availablequantity ? (
        <div style={{ color: 'red' }}>{`${productNumber}-${productdescription}`}</div>
      ) : ( */}
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {`${productNumber}-${productDescription || productdescription}`}
      </div>
      {/* )} */}
    </DataTableCell>
  ) : null;
};

/** Custom Rep/Covering cell */
export default PartDescriptionCellForRep;
