/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-lonely-if */
/* eslint-disable no-fallthrough */
import React, { FC, useState, useEffect, ReactNode } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Tab, Tabs, Paper } from '@material-ui/core';
import './index.scss';
import ErpOrderList from './ErpOrderList/index';
import OrderRequests from './OrderRequests';
import { GET_WORKFLOW_PERMISSIONS } from '../../graphql/workflowPermissions';
import { getUserInfo } from '../../store/ducks/userInfo';
import { setHoldBannerStatus } from '../../store/ducks/holdBanner';

const Orders: FC<RouteComponentProps> = ({ history, location }) => {
  const [showOrders, setShowOrders] = useState(false);
  const [showProcessedOrders, setShowProcessedOrders] = useState(false);
  const [showFailedOrders, setShowFailedOrders] = useState(false);
  const [showReviewOrders, setShowReviewOrders] = useState(false);
  const [tabPanel, setTabPanel] = useState<ReactNode>();
  const userInfo = useSelector(getUserInfo);
  const dispatch = useDispatch();
  useQuery(GET_WORKFLOW_PERMISSIONS);

  useEffect(() => {
    dispatch(setHoldBannerStatus(false));
  }, [dispatch]);
  useEffect(() => {
    if (
      (userInfo && userInfo.personas === 'Branch Op') ||
      userInfo?.personas === 'Branch Op;Business Admin'
    ) {
      // Do not have access to Orders: Redirect to Home
      history.push('/Orders/ReviewOrders');
    }
  }, [history, userInfo]);
  useEffect(() => {
    const { pathname } = location;
    setShowProcessedOrders(false);
    setShowFailedOrders(false);
    setShowReviewOrders(false);
    setShowOrders(false);

    if (pathname.includes('/Orders/Processed')) {
      setShowProcessedOrders(true);
      setTabPanel(<ErpOrderList type="Processed" />);
    } else if (pathname.includes('/Orders/Failed')) {
      setShowFailedOrders(true);
      setTabPanel(<ErpOrderList type="Failed" />);
    } else if (pathname.includes('/Orders/ReviewOrders')) {
      setShowReviewOrders(true);
      setTabPanel(<OrderRequests type="Review" />);
    } else {
      if (
        (userInfo && userInfo.personas === 'Branch Op') ||
        (userInfo && userInfo.personas === 'Agency OPS') ||
        userInfo?.personas === 'Branch Op;Business Admin'
      ) {
        setShowReviewOrders(true);
        setTabPanel(<OrderRequests type="Review" />);
      } else {
        setShowOrders(true);
        setTabPanel(<OrderRequests type="Open" />);
      }
    }
  }, [location, userInfo]);

  const handleTabChange = (event: any): void => {
    const tabName = event.target.innerText;
    switch (tabName) {
      case 'Orders':
        history.push('/Orders');
        return;
      case 'Processed':
        history.push('/Orders/Processed');
        return;
      case 'Review Queue':
        history.push('/Orders/ReviewOrders');
        return;
      case 'Failed':
        history.push('/Orders/Failed');
        return;
      default:
        if (
          (userInfo && userInfo.personas === 'Branch Op') ||
          userInfo?.personas === 'Branch Op;Business Admin'
        ) {
          history.push('/Orders/ReviewOrders');
        } else {
          history.push('/Orders');
        }
    }
  };
  return (
    <div>
      <Paper className="paper" square>
        <Tabs
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          onChange={handleTabChange}
        >
          {!(
            (userInfo && userInfo.personas === 'Branch Op') ||
            userInfo?.personas === 'Agency OPS' ||
            userInfo?.personas === 'Branch Op;Business Admin'
          ) && <Tab className={showOrders ? 'active' : 'inactive'} label="Open" />}
          {!(
            (userInfo && userInfo.personas === 'Branch Op') ||
            userInfo?.personas === 'Agency OPS' ||
            userInfo?.personas === 'Branch Op;Business Admin'
          ) && <Tab className={showFailedOrders ? 'active' : 'inactive'} label="Failed" />}
          {!(
            (userInfo && userInfo.personas === 'Branch Op') ||
            userInfo?.personas === 'Branch Op;Business Admin' ||
            userInfo?.personas === 'Agency OPS'
          ) && <Tab className={showProcessedOrders ? 'active' : 'inactive'} label="Processed" />}
          {((userInfo && userInfo.personas === 'Agency OPS;Agency CSR') ||
            (userInfo && userInfo.personas === 'Agency CSR') ||
            (userInfo && userInfo.personas === 'CSR;Branch Op') ||
            (userInfo && userInfo.personas === 'Branch Op') ||
            (userInfo && userInfo.personas === 'CSR') ||
            (userInfo && userInfo.personas === 'Agency OPS') ||
            (userInfo && userInfo.personas === 'CSR;Branch Op;Business Admin') ||
            (userInfo && userInfo.personas === 'Branch Op;Business Admin')) && (
            <Tab className={showReviewOrders ? 'active' : 'inactive'} label="Review Queue" />
          )}
        </Tabs>
      </Paper>
      <div className="slds-p-horizontal_large slds-p-vertical_x-large events_list">{tabPanel}</div>
    </div>
  );
};
export default withRouter(Orders);
