/**
 * Module for Cancelling evnet creation.
 * @module src/components/CreateEditSurgeryEvent/CancelEventCreationModal
 */
import React, { FC } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import './index.scss';

interface Props {
  showEditConfirmationModal: boolean;
  handleModalToggle: () => void;
  handlelEditEventModalConfirmPress: () => void;
}

const SurgicalDetailEditConfirmationModal: FC<Props> = ({
  showEditConfirmationModal,
  handleModalToggle,
  handlelEditEventModalConfirmPress,
}) => {
  return (
    <Modal
      className="cancel-creation-modal default-modal_header default-modal_footer"
      isOpen={showEditConfirmationModal}
      onRequestClose={handleModalToggle}
      ariaHideApp={false}
      footer={[
        <Button label="Yes" onClick={handlelEditEventModalConfirmPress} key="yes" />,
        <Button label="No" variant="brand" onClick={handleModalToggle} key="no" />,
      ]}
      heading="Edit Confirmation"
    >
      <div className="slds-text-align--center">
        <div>
          <h1 className="slds-m-around--large ">
            Any edits made to this event will not update the related records.
            <br />
            <span style={{ textAlign: 'center' }}>Do you want to continue?</span>
          </h1>
        </div>
      </div>
    </Modal>
  );
};

/** Custom component for Cancelling evnet creation */
export default SurgicalDetailEditConfirmationModal;
