/**
 * Graphql mutation to create return inventory
 * @module src/graphql/createReturnInventory
 */
import gql from 'graphql-tag';

/** creating return inventory */

interface Comment {
  comment: string;
  postTime: string;
  commentType: string;
}
const CREATE_RETURN_INVENTORY = gql`
  mutation returnInventory(
    $orderId: String
    $orderExternalId: String
    $eventId: String
    $eventExternalId: String
    $accountId: String!
    $branchId: String
    $salesRepId: String!
    $fromSubInventoryCode: String
    $salesOrganization: String
    $shipDate: Date
    $trackingId: String
    $returnReason: String
    $formType: String!
    $returnType: String
    $shipToId: String
    $isshippingLabelRequired: Boolean
    $liftGateNeeded: Boolean
    $dockAvailable: Boolean
    $shortTruckOnly: Boolean
    $onsiteContractName: String
    $onsiteContractPhone: String
    $onsiteContractEmail: String
    $system: String
    $version: String
    $onsiteContractInstructions: String
    $lineItems: [UpdateReturnLineItems]
    $comments: [Comment]
    $attachments: [Attachment]
    $shippingLabelAttachments: [Attachment]
  ) {
    returnInventory(
      orderId: $orderId
      orderExternalId: $orderExternalId
      eventId: $eventId
      eventExternalId: $eventExternalId
      accountId: $accountId
      branchId: $branchId
      salesRepId: $salesRepId
      fromSubInventoryCode: $fromSubInventoryCode
      salesOrganization: $salesOrganization
      shipDate: $shipDate
      trackingId: $trackingId
      returnReason: $returnReason
      formType: $formType
      returnType: $returnType
      shipToId: $shipToId
      isshippingLabelRequired: $isshippingLabelRequired
      liftGateNeeded: $liftGateNeeded
      dockAvailable: $dockAvailable
      shortTruckOnly: $shortTruckOnly
      onsiteContractName: $onsiteContractName
      onsiteContractPhone: $onsiteContractPhone
      system: $system
      version: $version
      onsiteContractEmail: $onsiteContractEmail
      onsiteContractInstructions: $onsiteContractInstructions
      comments: $comments
      attachments: $attachments
      shippingLabelAttachments: $shippingLabelAttachments
      lineItems: $lineItems
    ) {
      message
      externalId
      sfid
    }
  }
`;

export default CREATE_RETURN_INVENTORY;
