/**
 * Module for Custom Date/Time Cell.
 * @module src/Events/EventsList
 */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import moment from 'moment';
import { format } from 'date-fns';

interface DateTime {
  createdDate: Date;
}
export interface Props {
  item?: DateTime;
}

const OrderListDateTimeCell: FC<Props> = ({
  item = {
    createdDate: null,
  },
}) => {
  const { createdDate } = item;
  const formatter = 'M/d/yyyy';
  const startDate = new Date(moment.utc(createdDate || null).format('YYYY-MM-DD HH:mm:ss'));
  const timedata = new Date();
  const newtime = timedata
    .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
    .substring(4)
    .match(/\b(\w)/g);
  const TimeZoneData = newtime?.join('');
  return (
    <DataTableCell>
      <div style={{ display: 'flex' }}>
        <p className="font-12 font-600" style={{ paddingLeft: '5px' }}>
          <p className="font-12 font-600">
            {createdDate ? createdDate && format(new Date(startDate), formatter) : '--'}
          </p>
          {createdDate &&
            new Date(createdDate).toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          {createdDate ? ` ${TimeZoneData}` : ''}
        </p>
      </div>
    </DataTableCell>
  );
};

/** Custom Date/Time cell */
export default OrderListDateTimeCell;
