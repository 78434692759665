/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC } from 'react';
import Card from '@salesforce/design-system-react/components/card';
import Icon from '@salesforce/design-system-react/components/icon';
import DataTable from '@salesforce/design-system-react/components/data-table';
import Spinner from '@salesforce/design-system-react/components/spinner';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import FileActionDataCell from './DataTableCells/FileActionDataCell';
import CustomDataCell from './DataTableCells/CustomDataCell';
import { ATTACHMENT_TYPE } from '../../../../../../util/constants';

interface Props {
  viewOnly: boolean;
  showProgress: boolean;
  uploadedFiles: File[];
  setUploadFilesData: (files: File[]) => void;
  handleUploadFiles: (event: any) => Promise<void>;
  deleteFile: (id: string) => void;
  setValuesUpdated: any;
}

interface File {
  id?: string;
  name: string;
  imageurl: string;
  showOnUsageSheet: boolean;
  attachmentType: string;
}

const Attachments: FC<Props> = ({
  viewOnly,
  showProgress,
  uploadedFiles,
  handleUploadFiles,
  setUploadFilesData,
  setValuesUpdated,
  deleteFile,
}) => {
  const handleAction = (option: any, name: string): void => {
    let files;
    if (option.value === 'del') {
      const fileToDel = uploadedFiles.find((item: any) => item.name === name);
      if (fileToDel && fileToDel.id) {
        // already saved in DB, so delete from DB
        deleteFile(fileToDel.id);
      }
      files = uploadedFiles.filter((item: any) => item.name !== name);
    } else {
      files = uploadedFiles.map((item: any) => {
        if (item.name === name) {
          if (option.value === 'pdf') {
            return {
              ...item,
              showOnUsageSheet: !item.showOnUsageSheet,
            };
          }
          if (option.value === 'po') {
            return {
              ...item,
              attachmentType:
                item.attachmentType !== ATTACHMENT_TYPE.PO
                  ? ATTACHMENT_TYPE.PO
                  : ATTACHMENT_TYPE.USAGE,
            };
          }
        }
        return item;
      });
    }
    setValuesUpdated(true);
    setUploadFilesData(files);
  };

  const onInputClick = (event: any) => {
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  };

  return (
    <Card
      id="Attachments"
      headerActions={
        <>
          <input
            type="file"
            className="slds-file-selector__input slds-assistive-text"
            accept="image/*, application/pdf"
            id="file-upload-input-01"
            aria-labelledby="file-selector-primary-label file-selector-secondary-label"
            onChange={handleUploadFiles}
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            onClick={(event: any) => onInputClick(event)}
            disabled={viewOnly}
          />
          <label
            className="slds-file-selector__body"
            htmlFor="file-upload-input-01"
            id="file-selector-secondary-label"
          >
            <span className="slds-file-selector__button slds-button slds-button_neutral">
              <div className="uploadButton">
                <Icon category="utility" name="upload" size="x-small" />
              </div>

              <span className="slds-p-left_small slds-p-top_xxx-small">Upload File</span>
            </span>
          </label>
        </>
      }
      heading={`Attachments (${uploadedFiles.length})`}
    >
      <div style={{ overflow: 'auto' }}>
        <DataTable items={uploadedFiles || []} id="DataTableExample-1-default">
          {[
            <DataTableColumn key="name" label="File Name" property="name" />,
            <DataTableColumn
              key="showOnUsageSheet"
              label="Include in Usage Sheet"
              property="showOnUsageSheet"
            >
              <CustomDataCell indicator="showOnUsageSheet" />
            </DataTableColumn>,
            <DataTableColumn key="attachmentType" label="PO Label" property="attachmentType">
              <CustomDataCell indicator={ATTACHMENT_TYPE.PO} />
            </DataTableColumn>,
            !viewOnly && (
              <DataTableColumn key="contact" label="" property="contact">
                <FileActionDataCell handleAction={handleAction} />
              </DataTableColumn>
            ),
          ]}
        </DataTable>
      </div>
      {showProgress && <Spinner size="small" />}
    </Card>
  );
};

export default Attachments;
