/**
 * Creating our app store.
 * @module src/store
 */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import reducer from './ducks';

const persistConfig = {
  key: 'root',
  storage,
  whiteList: ['accessToken', 'refreshToken', 'onlineStatus', 'userInfo'],
};
const persistedReducer = persistReducer(persistConfig, reducer);

/** Redux store */
const store = createStore(
  persistedReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

/** Redux store  */
export default store;

/** Redux Storage peristor */
export const persistor = persistStore(store);
