/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_ORDER_LIST = gql`
  query getOrderList(
    $limit: Int
    $offset: Int
    $irCasedataOffset: Int
    $orderType: String
    $screenType: String
    $orderBy: String
    $orderSortType: String
    $filters: OrderFilters
  ) {
    getOrderList(
      limit: $limit
      offset: $offset
      irCasedataOffset: $irCasedataOffset
      orderType: $orderType
      screenType: $screenType
      orderBy: $orderBy
      orderSortType: $orderSortType
      filters: $filters
    ) {
      salesRepId
      receivingRepId
      offsetData
      status
      type
      diplayOrderType
      createdDate
      submittedDate
      orderNumber
      erpOrderNumber
      effectiveDate
      fromSubInventoryCode
      toSubInventoryCode
      recordTypeId
      priceBook2Id
      salesOrganization
      orderId
      assignedUserId
      assignedUserName
      orderExternalId
      accountId
      accountNumber
      accountName
      salesRepName
      receivingRepName
      caseId
      caseExternalId
      caseNumber
      parentEventId
      parentEventStatus
      parentCaseExternalId
      parentCaseId
      parentEventType
      parentEventSubType
      eventSubType
      orderTerritoryName
      caseTerritoryName
      failureReason
      serviceLevel
      poNumber
      patientId
      eventDateTime
      surgeonId
      surgeonFirstName
      surgeonLastName
      surgeonErpCode
      procedureId
      procedureName
      totalAmount
      visualIndicator
      showBackorderdWarning
      showRedWarningIcon
      eventId
      eventType
      casesSubmittedDate
      needByDate
      casesCreatedDate
      returnExpiryDate
    }
  }
`;
