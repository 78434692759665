/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/**
 * Module for Cloning Event
 * @module src/SurgicalDetail/SurgicalDetailCloneEvent/
 */
import React, { FC, useState, useCallback, useEffect } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import SwitchToggle from '../../Shared/Checkbox';
import './index.scss';
import CreateEditSurgeryEvent from '../../CreateEditSurgeryEvent';
import { GET_COMMENT_HISTORY } from '../../../graphql/getCommentHistory';
import { GET_WORKFLOW_PERMISSION } from '../../../graphql/workflowPermissions';
import InventoryRequest from '../../Inventory/InventoryRequest';
import { GET_FORM_CONFIGURATIONS } from '../../../graphql/getFormConfigurations';
import { getUserInfo } from '../../../store/ducks/userInfo';
import CancelEventModal from '../../CreateEditSurgeryEvent/CancelEventCreationModal';

interface SalesTeam {
  id: string;
  label: string;
}

interface ProcedureType {
  id: string;
  label: string;
  type: string;
}

interface EventInventoryList {
  id: string;
  externalId: string;
  type: string;
  name: string;
  createdDate: string;
  status: string;
  serviceLevel: string;
  needByDate: string;
}

interface EventInventory {
  getEventInventory: {
    kits: Kits[];
    parts: Parts[];
  };
}

interface WorkflowPermission {
  workflowPermission: {
    id: string;
  } | null;
}

interface Kits {
  id: string;
  externalId: string;
  kitId: string;
  quantity: string;
  status: string;
  kit_name: string;
  kit_item_code: string;
}

interface Parts {
  id: string;
  externalId: string;
  productId: string;
  quantity: string;
  status: string;
  part_code: string;
  part_description: string;
}
interface Props {
  cloneEventModalVisible: boolean;
  handleCloneEventModal: () => void;
  handleCloseCloneEventModal: () => void;
  eventDetail: {
    eventType: string;
    eventId: string;
    division: string;
    eventStatus: string;
    eventSubType: string;
    eventInventory: EventInventoryList[] | null;
    eventName: string;
    eventAddress: string;
    branchId: string;
    branch: string;
    accountId: string;
    accountNumber: string;
    accountName: string;
    surgeonId: string;
    surgeonFirstName: string;
    surgeonLastName: string;
    surgeonErpCode: string;
    procedures: string;
    salesRep: string;
    salesRepId: string;
    coveringReps: string;
    navigation: boolean;
    mako: boolean;
    revisions: boolean;
    eventStartDate: Date;
    eventEndDate: Date;
    shippingName: string;
    shippingId: string;
    billingName: string;
    billingId: string;
    deliveryService: string;
    specialInstruction: string;
    customerPoNo: string;
    organizedBy: string;
    numberOfParticipants: string;
    trailContractSigned: boolean;
    eventShippingLocation: string;
    proceduresList: ProcedureType[] | null;
    salesTeams: SalesTeam[];
    caseExternalId: string;
    caseId: string;
    comments: string;
    eventDescription?: string;
    eventLocation?: any;
    isOtherAddress?: any;
    locationDescription?: string;
    street1?: string;
    street2?: string;
    city?: string;
    state?: any;
    zipCode: any;
  };
  eventInventoryData: any;
  handleEditModalVisible: () => void;
  editModalVisible: boolean;
  showSurgeonData: WorkflowPermission;
}

const CloneEvent: FC<Props> = ({
  // cloneEventModalVisible,
  handleCloneEventModal,
  eventDetail,
  eventInventoryData,
  handleCloseCloneEventModal,
  handleEditModalVisible,
  // editModalVisible,
  showSurgeonData,
}) => {
  const userInfo = useSelector(getUserInfo);
  const [showCloneEventModal, setShowCloneEventModal] = useState(false);
  const [cloneRequest, setCloneRequest] = useState(true);
  const [showEditEventModal, setShowEditEventModal] = useState(false);
  const [canceModalVisible, setCanceModalVisible] = useState(false);
  // const [cloneInventory, setCloneInventory] = useState(
  //   !(eventDetail.eventInventory && eventDetail.eventInventory.length > 1)
  // ); Bug 425226
  const [cloneInventory, setCloneInventory] = useState(false);
  const [cloneComments, setCloneComments] = useState(false);
  const [cloneUsages, setCloneUsages] = useState(false);
  const [isInventoryOpne, setIsInventoryOpen] = useState(false);
  const [eventCloneId, setEventCloneId] = useState('');
  const [invData, setInvData] = useState('');
  const [lineItems, setLineItems] = useState([]);
  const [getCommentHistory, { loading: commentsLoading, data: commentHistory }] = useLazyQuery(
    GET_COMMENT_HISTORY
  );
  const [getFormConfigurations, { data: formdata }] = useLazyQuery(GET_FORM_CONFIGURATIONS);

  const cloneEvent = useCallback(
    (externalId: string, flowType: string, invDetails: any): void => {
      if (flowType === 'clone' && cloneInventory) {
        setIsInventoryOpen(true);
        setShowCloneEventModal(false);
        setEventCloneId(externalId);
        setInvData(invDetails);
      }
    },
    [setEventCloneId, setIsInventoryOpen, setShowCloneEventModal, cloneInventory]
  );

  const handleEventInventoryModalToggle = (): void => {
    handleCloneEventModal();
  };

  const handleCancelButton = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };
  const handleCancelModal = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };
  const handleCancelModalConfirmPress = (): void => {
    setCanceModalVisible(!canceModalVisible);
    setIsInventoryOpen(false);
  };

  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: showFranchise } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'clone',
      action: 'enable_franchise',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: showCountry } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'clone',
      action: 'enable_country',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: showAccount } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'clone',
      action: 'enable_account',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: showSurgeon } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'clone',
      action: 'enable_surgeon',
    },
  });

  const getLineItems = (items: React.SetStateAction<never[]>): void => {
    setLineItems(items);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: showSalesRep } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'clone',
      action: 'enable_salesRep',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: showSpecificIdentifier } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'clone',
      action: 'enable_specific_identifier',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: showCoveringRep } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'clone',
      action: 'enable_covering_rep',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: showShipTo } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'clone',
      action: 'enable_ship_to',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: showBillTo } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'clone',
      action: 'enable_bill_to',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: showOrganisedBy } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'clone',
      action: 'enable_organized_by',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: showNumberOfParticipant } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'clone',
      action: 'enable_no_of_participant',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: showContract } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'clone',
      action: 'enable_contract_clone',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: showShippingLocation } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'clone',
      action: 'enable_shipping_location',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: showProcedures } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'clone',
      action: 'enable_procedures',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: showSalesTeams } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'clone',
      action: 'enable_sales_team',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: customerComments } = useQuery(GET_COMMENT_HISTORY, {
    variables: {
      sfid: eventDetail.caseId,
      caseExternalId: eventDetail.caseExternalId,
      commentType: 'Customer',
    },
  });

  // Preparing updated event detail data according to permission sets.
  let updatedEventDetail = {};
  Object.keys(eventDetail).forEach(key => {
    if (key === 'deliveryService') {
      updatedEventDetail = { ...updatedEventDetail, [key]: 'Next Day' };
    } else if (key === 'division' /* && showFranchise*/) {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (key === 'branchId' || key === 'branch' /* && showCountry*/) {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (key === 'accountId' || key === 'accountName' /* && showAccount*/) {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (
      key === 'surgeonId' ||
      key === 'surgeonFirstName' ||
      key === 'surgeonLastName' /* &&
      showSurgeon*/
    ) {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (key === 'proceduresList' /* && showProcedures*/) {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (key === 'salesRepId' || key === 'salesRep' /* && showSalesRep*/) {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (key === 'coveringReps' /* && showCoveringRep*/) {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (
      key === 'navigation' ||
      key === 'mako' ||
      key === 'revisions' /* &&
      showSpecificIdentifier
    */
    ) {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (key === 'shippingName' || key === 'shippingId' /* && showShipTo*/) {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (key === 'billingName' || key === 'billingId' /* && showBillTo*/) {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (key === 'organizedBy' /* && showOrganisedBy*/) {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (key === 'numberOfParticipants' /* && showNumberOfParticipant*/) {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (key === 'trailContractSigned' /* && showContract*/) {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (key === 'eventShippingLocation' /* && showShippingLocation*/) {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (key === 'salesTeams' /* && showSalesTeams*/) {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (key === 'eventType' || key === 'eventSubType' || key === 'eventId') {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (key === 'eventInventory') {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (key === 'eventDescription') {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    } else if (key === 'isOtherAddress' && eventDetail[key] === true) {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    }
    if (key === 'locationDescription') {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    }
    if (key === 'street1') {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    }
    if (key === 'street2') {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    }
    if (key === 'city') {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    }
    if (key === 'state') {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    }
    if (key === 'zipCode') {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    }
    if (key === 'comments') {
      updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    }
    // else if (key === 'eventInventory') {
    //   updatedEventDetail = { ...updatedEventDetail, [key]: eventDetail[key] };
    // }
  });
  updatedEventDetail = { ...updatedEventDetail, eventStatus: eventDetail.eventStatus };
  const handleClickCloneButton = useCallback((): void => {
    setShowCloneEventModal(!showCloneEventModal);
    setCloneRequest(false);
    if (showCloneEventModal) {
      if (!cloneInventory) {
        handleCloneEventModal();
      }
    }
  }, [showCloneEventModal, cloneInventory, handleCloneEventModal]);

  const handleClickCreateEditSurgeryCloneButton = useCallback((): void => {
    if (
      !eventInventoryData ||
      (eventInventoryData &&
        eventInventoryData.getInventoryRequestDetails &&
        !eventInventoryData.getInventoryRequestDetails.lineItems.length)
    ) {
      handleCloseCloneEventModal();
    }
    setShowCloneEventModal(!showCloneEventModal);
    setCloneRequest(false);
    if (showCloneEventModal) {
      if (!cloneInventory) {
        handleCloneEventModal();
      }
    }
  }, [
    eventInventoryData,
    showCloneEventModal,
    handleCloseCloneEventModal,
    cloneInventory,
    handleCloneEventModal,
  ]);
  const handleClickEditCloseModal = useCallback((): void => {
    setCloneRequest(false);
    setShowEditEventModal(!showEditEventModal);
    handleCloneEventModal();
    handleEditModalVisible();
  }, [showEditEventModal, handleCloneEventModal, handleEditModalVisible]);

  const handleToggleChange = useCallback(
    (toggleType: string, result: boolean) => {
      if (toggleType === 'Inventory') {
        setCloneInventory(result);
      } else if (toggleType === 'Usage') {
        setCloneUsages(result);
      } else if (toggleType === 'Internal Comments') {
        getCommentHistory({
          variables: {
            sfid: eventDetail.caseId,
            caseExternalId: eventDetail.caseExternalId,
            commentType: 'Internal',
          },
        });
        setCloneComments(result);
      }
    },
    [eventDetail.caseExternalId, eventDetail.caseId, getCommentHistory]
  );
  const filterRule: any =
    // eslint-disable-next-line no-unused-expressions
    userInfo?.businessRules?.filter((br: any) => {
      if (br.rules === 'Clone Event with Records') {
        return br.isRequired;
      }
    });
  const isClone = filterRule && filterRule.length > 0 && filterRule[0].isRequired;
  return (
    <IconSettings iconPath="/icons">
      <div>
        <>
          {cloneRequest && (
            <Modal
              className="clone-option_modal default-modal_header default-modal_footer"
              isOpen={cloneRequest}
              footer={[
                <Button
                  label="Clone Event"
                  variant="brand"
                  onClick={handleClickCloneButton}
                  disabled={commentsLoading || !isClone}
                  key="clone_event"
                />,
              ]}
              onRequestClose={handleCloneEventModal}
              heading="Clone Options"
            >
              <div>
                <div className="slds-text-align--left">
                  <p className="slds-m-horizontal--medium slds-m-vertical--medium ">
                    Select the details you would like to include when cloning this event.
                  </p>
                  <p className="slds-m-horizontal--medium slds-m-vertical--medium ">
                    Please note if the event has multiple Inventory Requests, they will not be
                    included with the Cloned Event
                    {/* <p>
                      - Part Details, Attachments, and Signatures will not be included with Usage.
                    </p> */}
                  </p>
                </div>
                <div className="clone-option-checkbox slds-m-horizontal--medium">
                  <div className="slds-form__item custom_label-hide" role="listitem">
                    <div className="slds-form-element slds-form-element_edit  slds-form-element_stacked slds-hint-parent">
                      <div className="slds-form-element__control slds-custom_input slds-custom_checkboxes">
                        <div className="slds-form-element__control ">
                          <SwitchToggle
                            label="Inventory"
                            setIsChecked={(result: boolean): void => {
                              handleToggleChange('Inventory', result);
                            }}
                            disabled={
                              !!(
                                eventDetail.eventInventory && eventDetail.eventInventory.length > 1
                              )
                            }
                            isChecked={cloneInventory}
                            key="Inventory"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="slds-form__item custom_label-hide" role="listitem">
                    <div className="slds-form-element slds-form-element_edit  slds-form-element_stacked slds-hint-parent">
                      <div className="slds-form-element__control slds-custom_input slds-custom_checkboxes">
                        <div className="slds-form-element__control ">
                          <SwitchToggle
                            label="Usage"
                            setIsChecked={(result: boolean): void => {
                              handleToggleChange('Usage', result);
                            }}
                            isChecked={cloneUsages}
                            key="Usage"
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="slds-form__item custom_label-hide" role="listitem">
                    <div className="slds-form-element slds-form-element_edit  slds-form-element_stacked slds-hint-parent">
                      <div className="slds-form-element__control slds-custom_input slds-custom_checkboxes">
                        <div className="slds-form-element__control ">
                          <SwitchToggle
                            label="Internal Comments"
                            setIsChecked={(result: boolean): void => {
                              handleToggleChange('Internal Comments', result);
                            }}
                            isChecked={cloneComments}
                            key="Internal Comments"
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </Modal>
          )}

          {showCloneEventModal && (
            <CreateEditSurgeryEvent
              toggleOpen={handleClickCreateEditSurgeryCloneButton}
              clonedLineItems={getLineItems}
              isOpen={showCloneEventModal}
              eventType={eventDetail.eventType}
              eventSubType={eventDetail.eventSubType}
              eventLabel={eventDetail.eventType}
              eventDetail={updatedEventDetail}
              eventInventoryData={cloneInventory ? eventInventoryData : null}
              isInvCloneTrue={cloneInventory}
              internalComments={cloneComments ? commentHistory.getCommentHistory : null}
              showSurgeonData={showSurgeonData}
              callEventDetail={cloneEvent}
            />
          )}
        </>
        {invData && (
          <InventoryRequest
            isOpen={isInventoryOpne}
            toggleOpen={handleEventInventoryModalToggle}
            formData={formdata}
            eventType="Request Inventory"
            eventExternalId={eventCloneId}
            isEventInvClone={!!eventCloneId}
            allLineItemsClone={lineItems}
            inventoryDetail={invData}
            isClone={isInventoryOpne}
            handleCancelButton={handleCancelButton}
          />
        )}
        <CreateEditSurgeryEvent
          toggleOpen={handleClickEditCloseModal}
          isOpen={showEditEventModal}
          eventType={eventDetail.eventType}
          eventSubType={eventDetail.eventSubType}
          eventLabel={eventDetail.eventType}
          eventDetail={updatedEventDetail || {}}
          customerComments={customerComments ? customerComments.getCommentHistory : []}
          showSurgeonData={showSurgeonData}
        />
        {canceModalVisible && (
          <CancelEventModal
            isInventory
            canceModalVisible={canceModalVisible}
            handleCancelModal={handleCancelModal}
            handleCancelModalConfirmPress={handleCancelModalConfirmPress}
          />
        )}
      </div>
    </IconSettings>
  );
};

/** Clone Event component */
export default CloneEvent;
