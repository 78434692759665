/**
 * Bill Only Usage Sheet Form JSON.
 * @module src/util/FormJson/usageSheet
 */
import { Form } from '../../components/Shared/FormEngine';

const BillOnlyUsageSheet: Form = [
  {
    kind: 'field',
    name: 'topInfo',
    fieldLabel: '*Required fields', // TODO. Replace after localization
    fieldType: 'text',
    required: true,
    isShow: true,
    optionsAutoSelected: false,
    permissionSets: [],
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'tabNavigator',
        fieldLabel: 'Tab Navigator',
        fieldType: 'tabNavigator',
        required: false,
        optionsPlaceHolder: '',
        optionsDynamic: '',
        isShow: true,
        optionsAutoSelected: false,
        optionsDependentField: [],
        optionsStatic: [
          { value: 'details', label: 'Details' },
          { value: 'parts', label: 'Parts' },
        ],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'salesRep',
        fieldLabel: 'Sales Rep',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        isShow: true,
        optionsPlaceHolder: 'Sales Rep',
        optionsDynamic: 'getSalesReps',
        optionsDependentField: [],
        optionsAutoSelected: false,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'tbaAccount',
        fieldLabel: 'Account Types',
        fieldType: 'radio',
        isShow: true,
        required: false,
        optionsPlaceHolder: 'Account Types',
        optionsDynamic: 'getTbaAccounts',
        optionsDependentField: [],
        optionsStatic: [
          { value: 'My Accounts', label: 'My Accounts', id: 'My Accounts' },
          { value: 'TBA Accounts', label: 'TBA Accounts', id: 'TBA Accounts' },
        ],
        optionsAutoSelected: false,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'account',
        fieldLabel: 'Account',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        isShow: true,
        optionsPlaceHolder: 'Account',
        optionsDynamic: 'getAccounts',
        optionsDependentField: ['salesRep'],
        optionsAutoSelected: false,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'shipTo',
        fieldLabel: 'Ship To',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        isShow: true,
        optionsPlaceHolder: 'Ship To',
        optionsDynamic: 'getShipToAddresses',
        optionsDependentField: ['account'],
        optionsAutoSelected: false,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'billTo',
        fieldLabel: 'Bill To',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        isShow: true,
        optionsPlaceHolder: 'Bill To',
        optionsDynamic: 'getBillToAddresses',
        optionsDependentField: ['account'],
        optionsAutoSelected: false,
        permissionSets: [],
      },
    ],
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'po',
        fieldLabel: 'PO',
        fieldType: 'input',
        required: false,
        isShow: true,
        optionsPlaceHolder: 'PO',
        optionsDynamic: '',
        optionsDependentField: [],
        optionsAutoSelected: false,
        characterLimit: 35,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'surgeryDate',
        fieldLabel: 'Surgery Date',
        fieldType: 'datePicker',
        allowBackDate: true,
        required: false,
        isShow: true,
        optionsPlaceHolder: 'Surgery Date',
        optionsDynamic: '',
        optionsDependentField: [],
        optionsAutoSelected: false,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'contact',
        fieldLabel: 'Surgeon',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: false,
        isShow: true,
        optionsPlaceHolder: 'Surgeon',
        optionsDynamic: 'getSurgeons',
        optionsDependentField: ['account'],
        optionsAutoSelected: false,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'procedure',
        fieldLabel: 'Procedure',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        isShow: true,
        optionsPlaceHolder: 'Procedure',
        optionsDynamic: 'getProcedureTypes',
        optionsDependentField: [],
        optionsAutoSelected: false,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'patientID',
        fieldLabel: 'Patient ID',
        fieldType: 'input',
        required: false,
        isShow: true,
        optionsPlaceHolder: 'Patient ID',
        optionsDynamic: 'getBillTo',
        optionsDependentField: [],
        optionsAutoSelected: false,
        permissionSets: [],
      },
    ],
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'customerComments',
        fieldLabel: 'Customer Comments',
        fieldType: 'textArea',
        required: false,
        isShow: true,
        optionsPlaceHolder:
          'Do not use to enter patient information, complaints, or set extension request.',
        showOnWeb: true,
        optionsAutoSelected: false,
        characterLimit: 32000,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'internalComments',
        fieldLabel: 'Internal Comments',
        fieldType: 'textArea',
        required: false,
        isShow: true,
        optionsPlaceHolder:
          'Do not use to enter patient information, complaints, or set extension request.',
        showOnWeb: true,
        optionsAutoSelected: false,
        characterLimit: 32000,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'comments',
        fieldLabel: 'Comments',
        fieldType: 'comments',
        required: false,
        isShow: true,
        optionsAutoSelected: false,
        showOnWeb: false,
        characterLimit: 32000,
      },
    ],
  },
];

/** Exporting BIll Only Usage Sheet form json */
export default BillOnlyUsageSheet;
