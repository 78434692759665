/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Pill from '@salesforce/design-system-react/components/pill';
// import { EVENT_DETAIL_OPTIONS } from '../../../util/constants';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import { Grid } from '@material-ui/core';
import GridList from '@material-ui/core/GridList';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import GridListTile from '@material-ui/core/GridListTile';
import Button from '@salesforce/design-system-react/components/button';
import './index.scss';
import { format } from 'date-fns';
import TextArea from '../../Shared/TextArea';
import EventStatusBadge from '../../Shared/EventStatusBadge';
import { GET_LOTDETAILS, LotDetail } from '../../../graphql/getLotDetails';
import UPDATE_LOT_LOCATION from '../../../graphql/mutations/updateLotLocation';

interface FetchMoreType {
  variables: {
    offset: number;
    limit: number;
  };
  // TODO: Specify a more specific type
  updateQuery: string;
}

interface ExpirationDate {
  expirationDateDiff: number;
}
interface Props {
  // subInventoryNames: SubInventory[];
  fetchMore: (args0: FetchMoreType) => void;
  refetch: () => void;
  onrefresh: () => void;
  subInventoryName: string;
  productNumber: string;
}
interface Product {
  label: string;
}

const PartDetails: FC<RouteComponentProps> = ({ history, location }) => {
  const [getLotDetails, { data, loading }] = useLazyQuery(GET_LOTDETAILS, {
    fetchPolicy: 'no-cache',
  });
  const [saveLotLocation] = useMutation(UPDATE_LOT_LOCATION);
  const { state } = location;
  const { lotNumber } = state.item;
  const { subInventoryName, productNumber } = state;
  const [lotDetails, setLotDetails] = useState<LotDetail[]>([]);
  const [lotLocation, setLotLocation] = useState('');
  const [expirationDateIndication, SetExpirationDateIndication] = useState(-1);
  const [alert, setAlert] = useState(false);
  const formatter = 'MM/dd/yyyy';
  useEffect(() => {
    // calling getSubInventories only for the first time to get initial data
    if (lotDetails && lotDetails.length === 0) {
      getLotDetails({
        variables: {
          subInventoryName,
          productNumber,
          lotNumber,
        },
      });
    }
  }, [lotDetails, getLotDetails, data, subInventoryName, productNumber, lotNumber]);
  const dateDiff = (startDate: string | number | Date, timeEnd: string | number | Date): number => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(timeEnd);
    const oneDay = 1000 * 60 * 60 * 24;
    const result = Math.ceil((newStartDate.getTime() - newEndDate.getTime()) / oneDay);
    return result;
  };
  useEffect(() => {
    // if data is coming from graphql, setting it in state to render
    // Also used for setting data when calling useLazyQuery object
    if (data && data.getLotDetails) {
      setLotDetails(data.getLotDetails);
      setLotLocation(data.getLotDetails[0].location);
      const dDiff = dateDiff(
        format(new Date(data.getLotDetails[0].expirationDate), formatter),
        format(new Date(), formatter)
      );
      SetExpirationDateIndication(Math.abs(dDiff));
    }
  }, [data]);
  const handleChangeText = (value: string): void => {
    const textValue = value;
    const blankSpaceOnly = textValue ? !textValue.replace(/\s/g, '').length : true;
    if (!blankSpaceOnly) {
      setLotLocation(value);
    } else {
      setLotLocation('');
    }
  };
  const handleSaveLotLocation = (): void => {
    // POST request using axios
    const arrLotLocation = {
      id: lotDetails && lotDetails[0] && lotDetails[0].id, // 'aEac00000004Cb6CAE',
      externalId: lotDetails && lotDetails[0] && lotDetails[0].externalId, // 'SMCAREP2-0234020280-21131AG2-2021-06-18',
      location: lotLocation,
    };
    saveLotLocation({
      variables: arrLotLocation,
    }).then(res => {
      if (res.data.updateLotLocation.message === 'success') {
        setAlert(true);
      } else {
        setAlert(false);
      }
    });
  };
  const getBgColorCode = (): string => {
    if (expirationDateIndication <= 20) {
      return 'red';
    }
    if (expirationDateIndication <= 40) {
      return 'orange';
    }
    if (expirationDateIndication <= 60) {
      return 'yellow';
    }
    return 'white';
  };
  const getTextColorCode = (): string => {
    if (expirationDateIndication > 40 && expirationDateIndication <= 60) {
      return 'black';
    }
    return 'white';
  };
  return (
    <div>
      <div className="lotbacklink">
        <IconSettings iconPath="/icons">
          <Button
            style={{ color: 'black' }}
            iconCategory="utility"
            iconName="back"
            iconPosition="left"
            label="Back"
            onClick={history.goBack}
          />
        </IconSettings>
      </div>
      <div className="slds-p-horizontal_large slds-p-vertical_x-large events_list topheader">
        <IconSettings iconPath="/icons">
          <PageHeader
            className="inventory_header"
            icon={
              <Icon
                assistiveText={{ label: 'Opportunity' }}
                category="standard"
                name="event"
                style={{ backgroundColor: '#FFB500' }}
              />
            }
            label="Lot"
            title={lotNumber}
            truncate
            variant="object-home"
          />
          {loading ? (
            <Spinner
              size="large"
              variant="base"
              assistiveText={{ label: 'Loading...' }}
              key="spinner"
            />
          ) : (
            <div className="container">
              <div className="cardHeaderContainerLot">
                <Grid item xs={12}>
                  <GridList cols={12}>
                    <GridListTile style={{ padding: '22px', width: '33.33%', height: '0%' }}>
                      <div className="label">
                        <span>Quantity</span>
                      </div>
                      <div className="availableqtyLot">
                        {lotDetails && lotDetails[0] && lotDetails[0].availableqty
                          ? lotDetails && lotDetails[0] && lotDetails[0].availableqty
                          : '0'}
                      </div>
                      <div className="onhandqtyLot">
                        {lotDetails && lotDetails[0] && lotDetails[0].onhandqty
                          ? lotDetails[0].onhandqty
                          : '0'}
                      </div>
                    </GridListTile>
                    <GridListTile style={{ padding: '22px', width: '33.33%', height: '0%' }}>
                      <div className="label">
                        <span className="label">Alert Status</span>
                      </div>
                      <div>
                        {lotDetails && lotDetails[0]?.alertstatus && (
                          <EventStatusBadge status={lotDetails[0]?.alertstatus || 'Expired'} />
                        )}
                      </div>
                    </GridListTile>
                    <GridListTile style={{ padding: '22px', width: '33.33%', height: '0%' }}>
                      <div className="label">
                        <span>Expiration Date</span>
                      </div>
                      <div>
                        <div className="lotexpiryDate">
                          {lotDetails &&
                            lotDetails[0] &&
                            `${
                              lotDetails[0].expirationDate
                                ? format(new Date(lotDetails[0].expirationDate), formatter)
                                : '--'
                            }`}
                        </div>
                        <div
                          style={{
                            backgroundColor: getBgColorCode(),
                            color: getTextColorCode(),
                            display: expirationDateIndication > 60 ? 'none' : 'block',
                          }}
                          className="lotexpiryDateIndicator"
                        >
                          {`< ${expirationDateIndication} Days`}
                        </div>
                      </div>
                    </GridListTile>
                  </GridList>
                </Grid>
                <Grid item xs={12}>
                  <GridList cols={12}>
                    <GridListTile style={{ padding: '15px', width: '33.33%', height: '0%' }}>
                      <div className="label">
                        <span>Product Group</span>
                      </div>
                      <div>
                        {lotDetails && lotDetails[0] && lotDetails[0].productGroup
                          ? lotDetails[0].productGroup
                          : 'Product Group'}
                      </div>
                    </GridListTile>
                    <GridListTile style={{ padding: '15px', width: '33.33%', height: '0%' }}>
                      <div className="label">
                        <span>Days of Shelf</span>
                      </div>
                      <div>
                        <span className="label">
                          {lotDetails &&
                            lotDetails[0] &&
                            `${dateDiff(
                              format(new Date(), formatter),
                              format(new Date(lotDetails[0].dateReceived), formatter)
                            )} Days`}
                        </span>
                      </div>
                    </GridListTile>
                    <GridListTile style={{ padding: '15px', width: '33.33%', height: '0%' }}>
                      <div>
                        <TextArea
                          label="Location"
                          maxLength="4000"
                          showTooltip={false}
                          tooltipText=""
                          key="lotLocation"
                          value={lotLocation || ''}
                          required={false}
                          handleChangeText={handleChangeText}
                          disabled={false}
                        />
                      </div>
                      <div className="lotButtonSave">
                        {alert ? (
                          <Pill
                            labels={{
                              label: 'Success: Lot location saved successfully.',
                            }}
                            icon={
                              <Icon
                                title="Success"
                                category="utility"
                                name="success"
                                className="slds-icon-text-success"
                              />
                            }
                          />
                        ) : (
                          ''
                        )}
                        <Button label="Save" onClick={handleSaveLotLocation} key="Save" />
                      </div>
                    </GridListTile>
                  </GridList>
                </Grid>
              </div>
            </div>
          )}
        </IconSettings>
      </div>
    </div>
  );
};

export default withRouter(PartDetails);
