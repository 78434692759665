/**
 * Module for Event Tabs.
 * @module src/components/Events/EventsTab/index
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { startOfMonth, endOfMonth } from 'date-fns/esm';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
// import { useSelector } from 'react-redux';
import Events from '..';
import { GET_EVENT_ITEMS } from '../../../graphql/getEventItems';
import { getUserId } from '../../../store/ducks/userId';
import { getEventFilters } from '../../../store/ducks/eventFilters';
import { EVENT_TABS } from '../../../util/constants';
// import { getUserId } from '../../../store/ducks/userId';
// import { getUserInfo } from '../../../store/ducks/userInfo';
import './index.scss';
import {
  GET_WORKFLOW_PERMISSIONS,
  GET_WORKFLOW_PERMISSION,
} from '../../../graphql/workflowPermissions';

enum viewTab {
  'list' = 1,
  'calender' = 0,
  'card-view' = 2,
}

interface SelectedData {
  id: string;
  label: string;
  __typename?: string;
}

interface Filters {
  status?: string[];
  salesReps?: string[];
  coveringReps?: string[];
  account?: string;
  branch?: string[];
  franchise?: string[];
  surgeon?: string;
  procedureDetail?: string[];
  procedureType?: string[];
  startDate?: Date | string;
  endDate?: Date | string;
  usage?: string;
  eventType?: string[];
  search?: string;
}

interface DynamicValue {
  [key: string]: any;
}

const EventsTab: FC = () => {
  // const isCustomerPortal = window.location !== window.parent.location;
  const { tab }: any = useParams();
  const getTabValue = (tabValue: string): number => {
    return Number(viewTab[tabValue as any]);
  };
  const tabValue = tab ? getTabValue(tab) : 0;
  const LIMIT = 50;
  // const id = useSelector(getUserId);
  // const userInfo = useSelector(getUserInfo);
  const [activeTab, setActiveTab] = useState('All');
  const [activeTabClick, setActiveTabClick] = useState(0);
  const [offset, setOffset] = useState(0);
  const [
    getEventsData,
    { loading, data, refetch: refetchEventItems },
  ] = useLazyQuery(GET_EVENT_ITEMS, { fetchPolicy: 'no-cache' });

  const [getWorkflowPermissions] = useLazyQuery(GET_WORKFLOW_PERMISSIONS);
  const id = useSelector(getUserId);
  const { data: enableRequestedButton } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'button',
      action: 'enable_requested_button',
    },
  });
  useEffect(() => {
    getWorkflowPermissions();
  }, [getWorkflowPermissions]);

  const getFilterApiValue = (filterValues: DynamicValue): Filters => {
    let reducedFilterValues = {};

    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          let values;
          // These are the only two fields which we need to send the name not Id. line 100 && key !== 'eventType'
          if (key === 'franchise' || key === 'coveringReps') {
            values = filterValues[key].map((item: SelectedData) => item.label);
          } else if (key === 'event') {
            values = filterValues[key].map((item: SelectedData) => item);
          } else {
            values = filterValues[key].map((item: SelectedData) => item.id);
          }
          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (Array.isArray(filterValues[key]) && filterValues[key]) {
          if (filterValues[key].length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  const filterStore = useSelector(getEventFilters);

  useEffect(() => {
    if (tabValue === 0) {
      let calendarDate = new Date();
      const selDate = localStorage.getItem('calendarDate');
      if (selDate) {
        calendarDate = JSON.parse(selDate);
      }
      const start = startOfMonth(calendarDate);
      const end = endOfMonth(calendarDate);
      const setFilters = JSON.parse(filterStore || '{}');
      if (setFilters) {
        const filters = getFilterApiValue(setFilters.filterValues);
        getEventsData({
          variables: {
            startDate: start,
            endDate: end,
            offset: 0,
            limit: 5000,
            id,
            filters,
          },
        });
      } else {
        getEventsData({
          variables: {
            startDate: start,
            endDate: end,
            offset: 0,
            limit: 5000,
            id,
            filters: {},
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabSelect = (value: number): void => {
    if (value === EVENT_TABS.ALL) {
      // If same tab is clicked again and child component does not get to know that
      // value has been updated so this is done
      setActiveTabClick(activeTabClick + 1);
      setActiveTab('All');
      setOffset(0);
      // getEventsData({
      //   variables: {
      //     offset: 0,
      //     limit: LIMIT,
      //     id,
      //     isSalesRep: !!(userInfo?.personas === PERSONAS_SALES_RESP),
      //   },
      // });
    } else {
      setActiveTabClick(activeTabClick + 1);
      setActiveTab('Requested');
      setOffset(0);
      // getEventsData({
      //   variables: {
      //     offset: 0,
      //     limit: LIMIT,
      //     id,
      //     isSalesRep: !!(userInfo?.personas === PERSONAS_SALES_RESP),
      //     filters: { status: ['Requested', 'Failed'] },
      //   },
      // });
    }
  };
  // useEffect(() => {
  //   getEventsData({
  //     variables: {
  //       offset,
  //       limit: LIMIT,
  //       id,
  //       isSalesRep: !!(userInfo?.personas === PERSONAS_SALES_RESP),
  //     },
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <IconSettings iconPath="/icons">
      {
        // enableRequestedButton ? (
        //   <div className="custom-yellow-bg_tabs">
        //     <Tabs
        //       variant="scoped"
        //       id="tabs-example-scoped"
        //       className="event_tabs"
        //       onSelect={handleTabSelect}
        //     >
        //       <TabsPanel label="All" id="All">
        //         <Events
        //           activeTab={activeTab}
        //           getEventsData={getEventsData}
        //           loading={loading}
        //           data={data}
        //           refetchEventItems={refetchEventItems}
        //           setOffset={setOffset}
        //           offset={offset}
        //           activeTabClick={activeTabClick}
        //         />
        //       </TabsPanel>
        //       <TabsPanel label="Requested" id="Requested">
        //         <Events
        //           activeTab={activeTab}
        //           getEventsData={getEventsData}
        //           loading={loading}
        //           data={data}
        //           refetchEventItems={refetchEventItems}
        //           setOffset={setOffset}
        //           offset={offset}
        //           activeTabClick={activeTabClick}
        //         />
        //       </TabsPanel>
        //     </Tabs>
        //   </div>
        // ) :
        <Events
          activeTab={activeTab}
          getEventsData={getEventsData}
          loading={loading}
          data={data}
          refetchEventItems={refetchEventItems}
          setOffset={setOffset}
          offset={offset}
          activeTabClick={activeTabClick}
          tabValue={tabValue}
        />
      }
    </IconSettings>
  );
};

/** Events Tab component**/
export default EventsTab;
