/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Module for Cancel Event
 * @module src/components/Inventory/InventoryRequestCancel/index
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import Pill from '@salesforce/design-system-react/components/pill';
import Icon from '@salesforce/design-system-react/components/icon';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
// import Icon from '@salesforce/design-system-react/components/icon';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Button from '@salesforce/design-system-react/components/button';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import './index.scss';
import { getUserId } from '../../../store/ducks/userId';
import { GET_CANCELLATION_REASONS } from '../../../graphql/getCancellationReasons';

interface Props {
  cancelInventoryModalVisible: boolean;
  handleCancelInventoryModal: () => void;
  eventType: string;
  eventId: string;
  cancelInventory: (reason: string) => void;
  cancelInventoryLoading: boolean;
}

interface CancelReason {
  id: number;
  name: string;
}

const CancelInventory: FC<Props> = ({
  cancelInventoryModalVisible,
  handleCancelInventoryModal,
  eventType,
  eventId,
  cancelInventory,
  cancelInventoryLoading,
}) => {
  const [selectedReason, setSelectedReason] = useState('');
  const [cancelReasons, setReasons] = useState<string[]>([]);
  const REGION = 'EU';
  const USER_ID = useSelector(getUserId);

  const { data: cancellationReasons, loading } = useQuery(GET_CANCELLATION_REASONS, {
    variables: {
      userId: USER_ID,
      region: REGION,
    },
  });

  const selectReason = (reason: string): void => {
    setSelectedReason(reason);
  };

  const handleCancelInventory = (): void => {
    if (cancelInventory) {
      cancelInventory(selectedReason);
    }
  };

  // const cancelButtonDisabled = !!(selectedReason === '');

  useEffect(() => {
    if (cancellationReasons && cancellationReasons.getCancellationReasons) {
      let reasonsString = '';
      const reasons = cancellationReasons.getCancellationReasons;

      reasons.forEach((reason: any): void => {
        if (reason.reasons && reasonsString !== '') {
          reasonsString = `${reasonsString};${reason.reasons}`;
        } else if (reason.reasons) {
          reasonsString = `${reason.reasons}`;
        }
      });
      const reasonsArr = reasonsString.split(';');
      setReasons(reasonsArr);
    }
  }, [cancellationReasons]);

  return (
    <IconSettings iconPath="/icons">
      <Modal
        isOpen={cancelInventoryModalVisible}
        onRequestClose={handleCancelInventoryModal}
        dismissOnClickOutside={false}
        ariaHideApp={false}
        heading={
          <div className="slds-float_left slds-text-title_bold slds-text-heading_medium">{`${eventType} ${eventId}`}</div>
        }
        footer={[
          <div className="slds-align_absolute-center event_cancel_button" key="cancel_event">
            {cancelInventoryLoading && (
              <Spinner
                size="small"
                variant="base"
                assistiveText={{ label: 'Event creation Loading...' }}
              />
            )}
            <Button label="No" onClick={handleCancelInventoryModal} />
            <Button
              style={{ backgroundColor: '#ffb501' }}
              label="Yes"
              onClick={handleCancelInventory}
            />
          </div>,
        ]}
      >
        <section className={loading ? 'cancel_section' : ''}>
          {loading ? (
            <div className="slds-p-around_small customised-button-list">
              <Spinner size="large" variant="base" assistiveText={{ label: 'Loading...' }} />
            </div>
          ) : (
            <div style={{ position: 'relative', padding: '1rem' }}>
              <div className="cancel-success" />

              {`Are you sure you want to cancel this Inventory?`}
            </div>
          )}
        </section>
      </Modal>
    </IconSettings>
  );
};

/** Cancel Inventory **/
export default CancelInventory;
