/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// import { BrowserRouter as Router, RouteComponentProps, withRouter } from 'react-router-dom';
import Button from '@salesforce/design-system-react/components/button';
import EventStatusBadge from '../../Shared/EventStatusBadge';
import { getUSDString } from '../../../util/utilityFunctions';
import './index.scss';

export interface Props extends RouteComponentProps {
  children?: FC;
  items?: any;
}
// eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-unused-vars
const OrdersList: FC<Props> = ({ items, history }) => {
  const goToOrderDetails = (e: any, externalId: any, orderId: any, status: any): void => {
    if (status === 'New' || status === 'Cancelled' || status === 'Requested') {
      history.push('/OrdersUsageDetails', {
        externalId,
      });
    } else if (
      status === 'Submitted' ||
      status === 'Booked' ||
      status === 'Entered' ||
      status === 'Closed' ||
      status === 'Partial' ||
      status === 'Completed'
    ) {
      history.push('/OrdersUsageDetails', {
        orderId,
        type: 'Processed',
      });
    } else if (status === 'Failed') {
      history.push('/OrdersUsageDetails', {
        orderId,
        type: 'Failed',
      });
    } else {
      history.push('/OrdersUsageDetails', {
        externalId,
      });
    }
  };
  return (
    <>
      <div className="parts-list-table-container">
        <div className="parts-list-table-header list-grid-5-cols">
          <p>Order</p>
          <p>Order Type</p>
          <p>Status</p>
          <p>Amount</p>
          <p>PO</p>
        </div>
        {items &&
          items?.map((lineItem: any) => (
            <div className="parts-list-table-row list-grid-5-cols">
              <p className="vertical-align-middle">
                <Button
                  label={lineItem.name || 'TBD'}
                  variant="base"
                  onClick={(e: any) =>
                    goToOrderDetails(e, lineItem?.externalId, lineItem?.orderSfid, lineItem?.status)
                  }
                />
              </p>
              <p className="vertical-align-middle">
                {lineItem?.type === 'Bill' ? 'Bill Only' : lineItem?.type}
              </p>
              <p className="vertical-align-middle">
                <EventStatusBadge status={lineItem?.status || lineItem?.visualIndicator || 'NEW'} />
              </p>
              <p className="vertical-align-middle">{getUSDString(lineItem?.usgtotalamount)}</p>
              <p className="vertical-align-middle">{lineItem?.poNumber}</p>
            </div>
          ))}
      </div>
    </>
  );
};

export default withRouter(OrdersList);
