import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import moment from 'moment';

interface Status {
  eventStatus: string;
  visualIndicator?: string;
  submittedDate: string;
  status: string;
}
export interface Props {
  children?: FC;
  item?: Status;
  type?: string;
}

const SubmitDateCell: FC<Props> = ({
  item = {
    eventStatus: '',
    submittedDate: '',
    status: '',
  },
}) => {
  const { submittedDate } = item;
  const expDate = moment(new Date(submittedDate)).format('MM/DD/YYYY');
  return (
    <DataTableCell title={submittedDate || ''}>
      <div>{submittedDate ? expDate : '--'}</div>
    </DataTableCell>
  );
};

export default SubmitDateCell;
