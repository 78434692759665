/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable dot-notation */
/* eslint-disable no-console */

import React, { FC, ReactNode, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import Spinner from '@salesforce/design-system-react/components/spinner';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import Button from '@salesforce/design-system-react/components/button';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import { Tab, Tabs, Paper } from '@material-ui/core';
import Collapsible from '../Collapsible';
import { NOTIFY_TYPE } from '../../../util/constants';
import { GET_NOTIFICATION_CONFIGURATION } from '../../../graphql/getNotificationConfiguration';
import CREATE_UPDATE_NOTIFICATION_CONFIG from '../../../graphql/mutations/createUpdateNotificationConfig';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import SnackBar from '../../Shared/SnackBar';
import { getUserId } from '../../../store/ducks/userId';
import { getUserInfo } from '../../../store/ducks/userInfo';
import DisplayRecordIndicator from '../../Shared/DisplayRecordIndicator';

interface Props {
  type: string;
}

// eslint-disable-next-line no-empty-pattern
const NotificationConfiguration: FC<RouteComponentProps> = ({ location }) => {
  const userInfo = useSelector(getUserInfo);
  const [timeStamp, setTimeStamp] = useState<any>('');
  const [
    getNotificationConfigurations,
    {
      data: getNotificationConfigurationData,
      refetch: refetchNotificationConfigurations,
      loading: getNotificationLoader,
    },
  ] = useLazyQuery(GET_NOTIFICATION_CONFIGURATION, {
    fetchPolicy: 'no-cache',
    variables: { businessunit: userInfo?.businessunit || 'Sports Med' },
  });
  const { open, notification, openSnackBar } = useSnackBar();
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenInventory, setIsOpenInventory] = useState(true);
  const [isOpenOrders, setIsOpenOrders] = useState(true);
  const [isCycleCount, setIsCycleCount] = useState(true);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [notificationConfigurations, setNotificationconfigurations] = useState<any>(null);
  const [
    createUpdateNotificationConfig,
    { data: notificationConfigurationData, loading },
  ] = useMutation(CREATE_UPDATE_NOTIFICATION_CONFIG);
  const [disabledIds, setDisabledIds] = useState<any>([]);
  useEffect(() => {
    getNotificationConfigurations({
      variables: { businessunit: userInfo?.businessunit || 'Sports Med' },
    });
  }, [getNotificationConfigurations, userInfo]);

  useEffect(() => {
    if (
      notificationConfigurationData &&
      notificationConfigurationData.createUpdateNotificationConfig
    ) {
      if (notificationConfigurationData.createUpdateNotificationConfig.message === 'success') {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Notification Settings Saved Successfully');
        refetchNotificationConfigurations();
      }
    }
  }, [notificationConfigurationData]);

  useEffect(() => {
    if (
      getNotificationConfigurationData &&
      getNotificationConfigurationData.getNotificationConfiguration
    ) {
      const data = getNotificationConfigurationData.getNotificationConfiguration;
      setNotificationconfigurations(data);
    }
  }, [getNotificationConfigurationData, refetchNotificationConfigurations]);

  useEffect(() => {
    const createdDateTime = notificationConfigurations?.sort(
      (a: any, b: any) => new Date(a.createdDate - b.createdDate)
    );
    const updateDateTime = notificationConfigurations?.sort(
      (a: any, b: any) => new Date(a?.updatedDate - b?.updatedDate)
    );
    const record = {
      createdBy:
        createdDateTime?.length > 0 && createdDateTime[0]?.createdBy
          ? createdDateTime[0]?.createdBy
          : 'System',
      createdDate: createdDateTime?.length > 0 && createdDateTime[0]?.createdDate,
      updatedBy: updateDateTime?.length > 0 && updateDateTime[0]?.updatedBy,
      updatedDate: updateDateTime?.length > 0 && updateDateTime[0]?.updatedDate,
    };
    setTimeStamp(record);
  }, [notificationConfigurations]);

  const handleFilterOpening = (title: any): void => {
    if (title === 'Events') {
      setIsOpen(prev => !prev);
    }
    if (title === 'Inventory') {
      setIsOpenInventory(prev => !prev);
    }
    if (title === 'Orders') {
      setIsOpenOrders(prev => !prev);
    }
    if (title === 'Cycle Count') {
      setIsCycleCount(prev => !prev);
    }
  };

  const handleExpandAll = (): void => {
    setIsOpen(true);
    setIsOpenInventory(true);
    setIsOpenOrders(true);
    setIsCycleCount(true);
  };

  const handleCollapseAll = (): void => {
    setIsOpen(false);
    setIsOpenInventory(false);
    setIsOpenOrders(false);
    setIsCycleCount(false);
  };

  const handleCancel = (): void => {
    setDisabledIds([]);
    getNotificationConfigurations({
      variables: { businessunit: userInfo?.businessunit || 'Sports Med' },
    });
  };

  const handleSave = (): void => {
    let mutation: any = {};
    const payload: Array<any> = [];
    notificationConfigurations.forEach((e: any): void => {
      payload.push({
        id: e.id,
        emailCategoryId: e.emailCategoryId,
        businessunitId: e.businessunitId,
        enabled: e.enabled,
        required: e.required,
        description: e.description,
        businessunitName: e.businessunitName,
        subCategory: e.subCategory,
        category: e.category,
      });
    });
    mutation = {
      notificationConfiguration: payload,
    };
    createUpdateNotificationConfig({
      variables: mutation,
    }).catch(error => {
      const errMsg = error.message.split(':')[1] || 'Something went wrong. Please try again.';
      openSnackBar(NOTIFY_TYPE.ERROR, errMsg);
    });
  };

  const handleChange = (event: any, item: any, toggledProperty: string): void => {
    const updatedNotificationSettings = notificationConfigurations.map((element: any) => {
      if (item.id) {
        if (item.id === element.id && item.subCategory === element.subCategory) {
          if (toggledProperty === 'required') {
            return { ...element, required: event.target.checked ? 'true' : 'false' };
          }
          if (toggledProperty === 'enabled') {
            if (event.target.checked === false) {
              if (disabledIds.indexOf(item.id) === -1) {
                setDisabledIds([...disabledIds, item.id]);
              }
              return { ...element, enabled: 'false', required: 'false' };
            }
            setDisabledIds(
              disabledIds.filter((id: any) => {
                return id !== item.id;
              })
            );
            return {
              ...element,
              enabled: 'true',
            };
          }
        }
      }
      return element;
    });
    setNotificationconfigurations(updatedNotificationSettings);
  };

  const actions = (): ReactNode => (
    <>
      <PageHeaderControl>
        <DisplayRecordIndicator recordInfo={timeStamp} screen="config" />
      </PageHeaderControl>
      <PageHeaderControl>
        <Button
          style={{ color: 'black', marginRight: '10px', fontWeight: 600 }}
          align="right"
          label="Cancel"
          onClick={handleCancel}
        />
        <Button className="yellow-btn" align="right" label="Save" onClick={handleSave} />
      </PageHeaderControl>
    </>
  );

  const handleTabSelect = (value: number): void => {
    setSelectedTabIndex(value);
  };

  return (
    <div>
      {/* <Paper className="paper" square>
        <Tabs
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          // value={value}
          // onChange={handleTabSelect}
          aria-label="notifications_selection"
        >
          <Tab label="Notifications" className="active" />
        </Tabs>
      </Paper> */}
      <IconSettings iconPath="/icons">
        <SnackBar open={open} notification={notification} />
        {(loading || getNotificationLoader) && (
          <Spinner size="large" variant="base" assistiveText={{ label: 'Loading...' }} />
        )}
        <PageHeader
          className="slds-m-around_small slds-m-bottom_none"
          icon={
            <Icon
              assistiveText={{ label: 'Opportunity' }}
              category="standard"
              name="event"
              style={{ backgroundColor: '#FFB500' }}
            />
          }
          onRenderActions={actions}
          title={
            <div className="notification_header">
              <span className="slds-col event_id">Notifications</span>
            </div>
          }
          variant="record-home"
        />
        <div
          className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none transferDetails"
          style={{ display: 'block' }}
        >
          <div className="slds-x-small-buttons_horizontal" style={{ paddingBottom: '20px' }}>
            <Button
              style={{ color: 'black', marginRight: '20px', fontWeight: 600 }}
              label="Expand All"
              onClick={handleExpandAll}
            />
            <Button
              style={{ color: 'black', fontWeight: 600 }}
              label="Collapse All"
              onClick={handleCollapseAll}
            />
            <div style={{ float: 'right', fontWeight: 600, fontSize: 14 }}>
              <span style={{ color: 'red' }}>*</span> Required Notification
            </div>
          </div>

          <div className="notification-config-container">
            <Collapsible open={isOpen} handleFilterOpening={handleFilterOpening} title="Events">
              <div className="notification-cols-header options-table-row">
                <p />
                <p>Enabled</p>
                <p>Required</p>
                <p>Description</p>
              </div>
              {notificationConfigurations &&
                notificationConfigurations.map(
                  (item: any) =>
                    item.category === 'Events' && (
                      <div className="notification-cols-row options-table-row">
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          {` ${item.subCategory}`}
                        </span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          <Checkbox
                            labels={{
                              toggleEnabled: '',
                              toggleDisabled: '',
                            }}
                            variant="toggle"
                            checked={item.enabled === 'true'}
                            onChange={(e: any): void => {
                              handleChange(e, item, 'enabled');
                            }}
                          />
                        </span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          <Checkbox
                            labels={{
                              toggleEnabled: '',
                              toggleDisabled: '',
                            }}
                            disabled={!!disabledIds.includes(item.id) || item.enabled === 'false'}
                            variant="toggle"
                            checked={item.required === 'true'}
                            onChange={(e: any): void => {
                              handleChange(e, item, 'required');
                            }}
                          />
                        </span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          <p>{item.description || 'Notification Description goes here'}</p>
                        </span>
                      </div>
                    )
                )}
            </Collapsible>
            <Collapsible
              open={isOpenInventory}
              handleFilterOpening={handleFilterOpening}
              title="Inventory"
            >
              <div className="notification-cols-header options-table-row">
                <p />
                <p>Enabled</p>
                <p>Required</p>
                <p>Description</p>
              </div>
              {notificationConfigurations &&
                notificationConfigurations.map(
                  (item: any) =>
                    item.category === 'Inventory' && (
                      <div className="notification-cols-row options-table-row">
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          {` ${item.subCategory}`}
                        </span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          <Checkbox
                            labels={{
                              toggleEnabled: '',
                              toggleDisabled: '',
                            }}
                            variant="toggle"
                            checked={item.enabled === 'true'}
                            onChange={(e: any): void => {
                              handleChange(e, item, 'enabled');
                            }}
                          />
                        </span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          <Checkbox
                            labels={{
                              toggleEnabled: '',
                              toggleDisabled: '',
                            }}
                            variant="toggle"
                            disabled={!!disabledIds.includes(item.id) || item.enabled === 'false'}
                            checked={item.required === 'true'}
                            onChange={(e: any): void => {
                              handleChange(e, item, 'required');
                            }}
                          />
                        </span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          <p>{item.description || 'Notification Description goes here'}</p>
                        </span>
                      </div>
                    )
                )}
            </Collapsible>
            <Collapsible
              open={isOpenOrders}
              handleFilterOpening={handleFilterOpening}
              title="Orders"
            >
              <div className="notification-cols-header options-table-row">
                <p />
                <p>Enabled</p>
                <p>Required</p>
                <p>Description</p>
              </div>
              {notificationConfigurations &&
                notificationConfigurations.map(
                  (item: any) =>
                    item.category === 'Orders' && (
                      <div className="notification-cols-row options-table-row">
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          {` ${item.subCategory}`}
                        </span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          <Checkbox
                            labels={{
                              toggleEnabled: '',
                              toggleDisabled: '',
                            }}
                            variant="toggle"
                            checked={item.enabled === 'true'}
                            onChange={(e: any): void => {
                              handleChange(e, item, 'enabled');
                            }}
                          />
                        </span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          <Checkbox
                            labels={{
                              toggleEnabled: '',
                              toggleDisabled: '',
                            }}
                            variant="toggle"
                            disabled={!!disabledIds.includes(item.id) || item.enabled === 'false'}
                            checked={item.required === 'true'}
                            onChange={(e: any): void => {
                              handleChange(e, item, 'required');
                            }}
                          />
                        </span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          <p>{item.description || 'Notification Description goes here'}</p>
                        </span>
                      </div>
                    )
                )}
            </Collapsible>

            <Collapsible
              open={isCycleCount}
              handleFilterOpening={handleFilterOpening}
              title="Cycle Count"
            >
              <div className="notification-cols-header options-table-row">
                <p />
                <p>Enabled</p>
                <p>Required</p>
                <p>Description</p>
              </div>
              {notificationConfigurations &&
                notificationConfigurations.map(
                  (item: any) =>
                    item.category === 'Cycle Count' && (
                      <div className="notification-cols-row options-table-row">
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          {` ${item.subCategory}`}
                        </span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          <Checkbox
                            labels={{
                              toggleEnabled: '',
                              toggleDisabled: '',
                            }}
                            variant="toggle"
                            checked={item.enabled === 'true'}
                            onChange={(e: any): void => {
                              handleChange(e, item, 'enabled');
                            }}
                          />
                        </span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          <Checkbox
                            labels={{
                              toggleEnabled: '',
                              toggleDisabled: '',
                            }}
                            variant="toggle"
                            disabled={!!disabledIds.includes(item.id) || item.enabled === 'false'}
                            checked={item.required === 'true'}
                            onChange={(e: any): void => {
                              handleChange(e, item, 'required');
                            }}
                          />
                        </span>
                        <span style={{ fontSize: 14, marginBottom: '10px' }}>
                          <p>{item.description || 'Notification Description goes here'}</p>
                        </span>
                      </div>
                    )
                )}
            </Collapsible>
          </div>
        </div>
      </IconSettings>
    </div>
  );
};

export default withRouter(NotificationConfiguration);
