/*
 * Event Details GraphQL specification
 * @module src/graphql/getEventInventory
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Event inventory GraphQL API query */
export const GET_EVENT_INVENTORY_DETAILS = gql`
  query getEventInventoryDetails(
    $salesRepId: String
    $subInventoryName: [String]
    $filters: EventInventoryFilters
  ) {
    getEventInventoryDetails(
      salesRepId: $salesRepId
      subInventoryName: $subInventoryName
      filters: $filters
    ) {
      territoryCode
      territoryName
      accounts {
        accountId
        accountNumber
        accountName
        events {
          eventId
          eventExternalId
          eventNumber
          eventName
          eventStartDate
          eventEndDate
          eventType
          eventSubType
          salesRepId
          salesRepName
          daysAtEvent
          lineItems {
            sfid
            externalId
            productId
            lotNumber
            quantity
            actualQuantity
            productNo
            productNumber
            productdescription
            productOnHoldType
            productOnHold
            serialRecalled
            daysAtEvent
          }
        }
      }
    }
  }
`;
