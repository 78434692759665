/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import Card from '@salesforce/design-system-react/components/card';
import Icon from '@salesforce/design-system-react/components/icon';
import { Grid } from '@material-ui/core';
import Button from '@salesforce/design-system-react/components/button';
import { useSelector } from 'react-redux';
import moment from 'moment';
import TextArea from '../../../Shared/TextArea';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import '../index.scss';

interface Props {
  inventoryDetails: InventoryDetails;
  setUserEnteredComment: (val: any) => void;
  viewOnly: boolean;
}

interface Comments {
  id?: string;
  value: string;
  type?: string;
  postTime: string;
  user?: string;
}

interface InventoryDetails {
  salesRep?: string;
  eventStatus?: string;
  eventId?: string;
  accountNumber?: string;
  accountName?: string;
  subInventoryCode?: string;
  suibmitDate?: string;
  shippingAddessLine1?: string;
  shippingAddessLine2?: string;
  shippingPostalcode?: string;
  shippingCity?: string;
  shippingState?: string;
  serviceLevel?: string;
  submitDate?: string;
  comments: Comments[];
}

const Comments: FC<Props> = ({ inventoryDetails, viewOnly, setUserEnteredComment }) => {
  const { comments = [] } = inventoryDetails;
  const [input, setInput] = useState('');
  const [commentsList, setCommentList] = useState<Comments[]>();
  const userInfo = useSelector(getUserInfo);
  useEffect(() => {
    if (comments) {
      const result = comments.filter((e: any) => e.type === 'Customer');
      setCommentList(result);
    }
  }, [comments]);
  const handleAdd = (): void => {
    if (input) {
      const obj = {
        value: input,
        postTime: moment().toString(),
        user: `${userInfo?.firstName} ${userInfo?.lastName}`,
      };
      setCommentList([...(commentsList || []), obj]);
      setUserEnteredComment([...(commentsList || []), obj]);
      setInput('');
    }
  };
  return (
    <div className="slds-m-around_small">
      <Card
        id="Comments"
        heading={`Comments  ${
          commentsList && commentsList.length > 0 ? `(${commentsList.length})` : ``
        }`}
        icon={<Icon category="standard" name="feedback" size="small" />}
      >
        <hr className="card-divider" />
        <div className="review-comments-container">
          <div className="comments-container">
            {commentsList &&
              commentsList.map((comment: Comments) => (
                <div className="comment-body">
                  <p className="comment-user-name">{comment.user || ''}</p>
                  <h4 className="comment-timestamp">
                    {`${moment(comment.postTime).format('L')} at ${moment(comment.postTime).format(
                      'LT'
                    )}`}
                  </h4>
                  <p className="comments_text">{comment.value}</p>
                </div>
              ))}
          </div>
          <TextArea
            label="Add Comment"
            tooltipText="Add Comment"
            showTooltip={false}
            disabled={viewOnly}
            handleChangeText={(text: string): void => setInput(text)}
            value={input}
            maxLength="200"
          />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button align="right" label="Add" onClick={handleAdd} disabled={viewOnly} />
            </Grid>
          </Grid>
        </div>
      </Card>
    </div>
  );
};

export default Comments;
