/**
 * Graphql mutation to edit comment
 * @module src/graphql/editComment
 */
import gql from 'graphql-tag';

/** editing comment */
const CREATE_COMMENT = gql`
  mutation createComment(
    $comment: String
    $caseExternalId: String!
    $postTime: String
    $commentType: String!
  ) {
    createComment(
      comment: $comment
      caseExternalId: $caseExternalId
      postTime: $postTime
      commentType: $commentType
    ) {
      message
    }
  }
`;

export default CREATE_COMMENT;
