/* eslint-disable */
import * as React from 'react'
import PropTypes from 'prop-types';

function unreadNotifications(props:any) {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-10px' }}>
<path d="M17.1618 23.8713H16.9668C16.2257 23.8713 15.6016 23.2472 15.6016 22.5062V18.0207C15.6016 14.4714 12.6373 11.6241 9.04894 11.7801C5.69459 11.9361 3.12032 14.8224 3.12032 18.2157V22.5452C3.12032 23.2862 2.49626 23.8713 1.75518 23.8713H1.56016C0.702073 23.8713 0 24.6124 0 25.4705V26.0555C0 26.3285 0.273028 26.6016 0.585061 26.6016H18.1369C18.4489 26.6016 18.7219 26.3285 18.7219 26.0165V25.4315C18.7219 24.5734 18.0199 23.8713 17.1618 23.8713ZM11.2722 28.1617H7.44977C7.21575 28.1617 7.02073 28.3958 7.05973 28.6298C7.25475 29.7219 8.22985 30.502 9.36097 30.502C10.4921 30.502 11.4672 29.6829 11.6622 28.6298C11.7012 28.3958 11.5062 28.1617 11.2722 28.1617Z" fill="black"/>
<ellipse cx="19.5016" cy="10" rx="10.1411" ry="10" fill="white"/>
<text x="20" y="12" textAnchor="middle" fontSize="8" fontWeight="bolder" fill={"black"}>
            {props.count}
        </text>
</svg>
  )
}

unreadNotifications.propTypes = {
    count: PropTypes.number,
    color: PropTypes.string,
    textColor: PropTypes.string
};

export default unreadNotifications
