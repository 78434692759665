/**
 * New Trial JSON.
 * @module src/util/formJsons/newTrial
 */
import { Form } from '../../components/Shared/FormEngine';

const formJson: Form = [
  {
    kind: 'field',
    name: 'topInfo',
    fieldLabel: '*Required fields',
    fieldType: 'text',
    required: true,
    isShow: true,
    optionsAutoSelected: false,
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'salesRep',
        fieldLabel: 'Sales Rep',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        isShow: true,
        optionsPlaceHolder: 'Sales Rep',
        optionsDynamic: 'getSalesReps',
        optionsDependentField: [],
        optionsAutoSelected: true,
      },
      {
        kind: 'field',
        name: 'tbaAccount',
        fieldLabel: 'Account Types',
        fieldType: 'radio',
        isShow: true,
        required: false,
        optionsPlaceHolder: 'Account Types',
        optionsDynamic: 'getTbaAccounts',
        optionsDependentField: [],
        optionsStatic: [
          { value: 'My Accounts', label: 'My Accounts', id: 'My Accounts' },
          { value: 'TBA Accounts', label: 'TBA Accounts', id: 'TBA Accounts' },
        ],
        optionsAutoSelected: false,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'account',
        fieldLabel: 'Account',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        isShow: true,
        optionsPlaceHolder: 'Account',
        optionsDynamic: 'getAccounts',
        optionsDependentField: ['salesRep'],
        optionsAutoSelected: true,
      },
      {
        kind: 'field',
        name: 'startDate',
        fieldLabel: 'Start Date/Time',
        fieldType: 'dateTimePicker',
        required: true,
        isShow: true,
        optionsAutoSelected: false,
        maxValue: 'endDate',
        date: true,
        time: true,
      },
      {
        kind: 'field',
        name: 'endDate',
        fieldLabel: 'End Date/Time',
        fieldType: 'dateTimePicker',
        required: true,
        isShow: true,
        optionsAutoSelected: false,
        minValue: 'startDate',
        date: true,
        time: true,
      },
    ],
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'contact',
        fieldLabel: 'Surgeon',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: false,
        isShow: true,
        optionsPlaceHolder: 'Surgeon',
        optionsDynamic: 'getSurgeons',
        optionsDependentField: ['account'],
        optionsAutoSelected: true,
        showAddButton: false,
        addButtonText: 'Add Surgeon',
      },
      {
        kind: 'field',
        name: 'coveringRep',
        fieldLabel: 'Covering Rep',
        fieldType: 'selectMultiDynamicPicker',
        required: false,
        isShow: true,
        optionsPlaceHolder: 'Covering Rep',
        optionsDynamic: 'getCoveringReps',
        optionsDependentField: ['salesRep'],
        optionsAutoSelected: false,
      },
      {
        kind: 'field',
        name: 'procedureType',
        fieldLabel: 'Procedure',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        isShow: true,
        optionsPlaceHolder: 'Procedure',
        optionsDynamic: 'getProcedureType',
        optionsDependentField: [],
        optionsAutoSelected: true,
      },
      {
        kind: 'field',
        name: 'opportunity',
        fieldLabel: 'Opportunity',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        isShow: true,
        optionsPlaceHolder: 'Opportunity',
        optionsDynamic: 'getOpportunity',
        optionsDependentField: ['account'],
        optionsAutoSelected: true,
        // permissionSets: [],
      },
    ],
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'trialLocation',
        fieldLabel: 'Trial Location',
        fieldType: 'textArea',
        required: false,
        isShow: true,
        optionsAutoSelected: false,
        characterLimit: 255,
        showTooltip: false,
      },

      {
        kind: 'field',
        name: 'comments',
        fieldLabel: 'Comments',
        fieldType: 'comments',
        required: false,
        isShow: true,
        optionsAutoSelected: false,
        characterLimit: 32000,
      },
    ],
  },
];

/** Exporting newTrial json */
export default formJson;
