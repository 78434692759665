import gql from 'graphql-tag';

const RESUBMIT_ORDER_DETAILS = gql`
  mutation resubmitOrderDetails(
    $attachments: [Attachment]
    $shippingLabelAttachments: [Attachment]
    $id: String
    $externalId: String
    $caseId: String
    $caseExternalId: String
    $shippingId: String
    $billingId: String
    $poNumber: String
    $patientId: String
    $surgeonId: String
    $accountId: String
    $eventDateTime: Date
    $shipMethod: String
    $serviceLevel: String
    $needByDate: Date
    $salesRepId: String
    $receivingRepId: String
    $fromSubInventoryCode: String
    $toSubInventoryCode: String
    $salesOrganization: String
    $type: String
    $requestedFrom: String
    $division: String
    $branchId: String
    $procedureTypeIds: String
    $eventType: String
    $shipToId: String
    $billToId: String
    $formType: String
    $submitType: String
    $comments: [Comment]
    $lineItems: [ReSubmitOrderLineItems]
    $liftGateNeeded: Boolean
    $dockAvailable: Boolean
    $shortTruckOnly: Boolean
    $onsiteContractName: String
    $onsiteContractPhone: String
    $onsiteContractEmail: String
    $onsiteContractInstructions: String
    $deliveryContractName: String
    $deliveryContractPhone: String
    $deliveryContractEmail: String
    $deliveryContractInstructions: String
    $isshippingLabelRequired: Boolean
    $isShippingDetails: Boolean
    $shipDate: Date
  ) {
    resubmitOrderDetails(
      attachments: $attachments
      shippingLabelAttachments: $shippingLabelAttachments
      id: $id
      externalId: $externalId
      caseId: $caseId
      caseExternalId: $caseExternalId
      shippingId: $shippingId
      billingId: $billingId
      poNumber: $poNumber
      patientId: $patientId
      surgeonId: $surgeonId
      accountId: $accountId
      eventDateTime: $eventDateTime
      shipMethod: $shipMethod
      serviceLevel: $serviceLevel
      needByDate: $needByDate
      salesRepId: $salesRepId
      receivingRepId: $receivingRepId
      fromSubInventoryCode: $fromSubInventoryCode
      toSubInventoryCode: $toSubInventoryCode
      salesOrganization: $salesOrganization
      type: $type
      requestedFrom: $requestedFrom
      division: $division
      branchId: $branchId
      procedureTypeIds: $procedureTypeIds
      eventType: $eventType
      shipToId: $shipToId
      billToId: $billToId
      formType: $formType
      submitType: $submitType
      comments: $comments
      lineItems: $lineItems
      liftGateNeeded: $liftGateNeeded
      dockAvailable: $dockAvailable
      shortTruckOnly: $shortTruckOnly
      onsiteContractName: $onsiteContractName
      onsiteContractPhone: $onsiteContractPhone
      onsiteContractEmail: $onsiteContractEmail
      onsiteContractInstructions: $onsiteContractInstructions
      deliveryContractName: $deliveryContractName
      deliveryContractPhone: $deliveryContractPhone
      deliveryContractEmail: $deliveryContractEmail
      deliveryContractInstructions: $deliveryContractInstructions
      isshippingLabelRequired: $isshippingLabelRequired
      shipDate: $shipDate
      isShippingDetails: $isShippingDetails
    ) {
      message
      sfid
    }
  }
`;

export default RESUBMIT_ORDER_DETAILS;
