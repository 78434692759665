/**
 * Redux file for getting/setting event filters
 * @module src/store/ducks/eventFilters
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_EVENT_FILTERS = 'SET_EVENT_FILTERS';
const CLEAR_EVENT_FILTERS = 'CLEAR_EVENT_FILTERS';

interface SetEventFiltersActionType {
  payload: string | null;
  type: typeof SET_EVENT_FILTERS;
}

interface ClearEventFiltersActionType {
  type: typeof CLEAR_EVENT_FILTERS;
}

/** eventFilters action type */

export type EventFiltersActionType = SetEventFiltersActionType | ClearEventFiltersActionType;

/** set eventFilters action creator */

export const setEventFilters = (state: string | null): EventFiltersActionType => {
  return {
    payload: state,
    type: SET_EVENT_FILTERS,
  };
};

/** clear eventFilters action creator */

export const clearEventFilters = (): ClearEventFiltersActionType => {
  return {
    type: CLEAR_EVENT_FILTERS,
  };
};

/** eventFilters state type */

export type EventFiltersState = string | null;

/** eventFilters reducer */

export default (state: EventFiltersState = null, action: ActionType): EventFiltersState => {
  switch (action.type) {
    case SET_EVENT_FILTERS: {
      return action.payload;
    }
    case CLEAR_EVENT_FILTERS: {
      return null;
    }
    default:
      return state;
  }
};

/** selector to get accessToken */

export const getEventFilters = (state: State): EventFiltersState => {
  return state.eventFilters;
};
