/**
 * Module for Custom Oracle Status Cell.
 * @module src/Events/EventsList
 */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';

interface OracleStatus {
  orderStatus: string;
  orderOracleNumber: string;
}
interface Props {
  item?: OracleStatus;
}

const EventsListOracleStatusCell: FC<Props> = ({
  item = {
    orderStatus: null,
    orderOracleNumber: null,
  },
}) => {
  const { orderStatus, orderOracleNumber } = item;

  return (
    <DataTableCell title={orderStatus || orderOracleNumber || ''}>
      {!orderStatus ? (
        <p className="wrap-text font-12 font-600">{orderOracleNumber || ''}</p>
      ) : (
        <div>
          <p className="wrap-text font-12 font-600">{orderStatus || ''}</p>
          <p className="wrap-text">{orderOracleNumber || ''}</p>
        </div>
      )}
    </DataTableCell>
  );
};

/** Custom Oracle Status Cell */
export default EventsListOracleStatusCell;
