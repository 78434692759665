import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Button from '@salesforce/design-system-react/components/button';
import { BrowserRouter as Router, RouteComponentProps, withRouter } from 'react-router-dom';

interface EventId {
  externalId: string;
  orderNumber: string;
}

// interface for props that the data table cell is getting default from data table.
export interface Props extends RouteComponentProps {
  children?: FC;
  item?: EventId;
  action?: string;
}

const InventoryRequestIdCell: FC<Props> = ({
  item = {
    externalId: null,
    orderNumber: null,
  },
  history,
  action,
}) => {
  const { orderNumber, externalId } = item;

  const goToInventoryDetails = (): void => {
    history.push('/inventoryReturnApproval', {
      externalId,
      opsAction: action,
    });
  };
  return (
    <Router>
      <DataTableCell title={orderNumber || 'TBD'}>
        <Button label={orderNumber || 'TBD'} variant="base" onClick={goToInventoryDetails} />
      </DataTableCell>
    </Router>
  );
};

export default withRouter(InventoryRequestIdCell);
