/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * Module for Custom Rep/Covering Cell.
 * @module src/Events/EventsList
 */
/* eslint-disable no-nested-ternary */
import React, { FC, useState, useCallback, useEffect } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import { COMMENTS, EMPTY_COMMENTS } from '../../../../util/constants';
import { getEventIcon } from '../../../../util/utilityFunctions';
import AddCommentsModal from './AddCommentsModal';

interface Quantity {
  quantity: string;
  availablequantity: string;
  eventType: string;
  createdByDetails: any;
  id: string;
  isOpen: boolean;
  needToDisplay: boolean;
}
interface Props {
  item?: Quantity;
  disabled: boolean;
}

const CCOpsComments: FC<Props> = ({
  item = {
    quantity: '',
    availablequantity: '',
    createdByDetails: [],
    id: '',
    isOpen: '',
    needToDisplay: false,
  },
  disabled,
}) => {
  const [isOpenModal, setIsOpen] = useState(false);
  const { createdByDetails, id, isOpen, needToDisplay } = item;
  const iconPathNoComments = getEventIcon(EMPTY_COMMENTS);
  const iconPathComments = getEventIcon(COMMENTS);
  let commentsList: never[] = [];
  let hasComments: never[] = [];

  const handleCommentsModal = (): void => {
    setIsOpen(true);
  };

  const handleModalToggle = useCallback((): void => {
    setIsOpen(!isOpenModal);
  }, [isOpenModal]);

  const handleModalToggleOnAdd = useCallback((): void => {
    if (isOpenModal) {
      window.location.reload();
    }
    setIsOpen(!isOpenModal);
  }, [isOpenModal]);

  commentsList = createdByDetails?.map((itemComments: any) => {
    return {
      comments: itemComments.comments,
      sfid: itemComments.sfid,
      externalId: itemComments.externalId,
    };
  });

  hasComments = createdByDetails?.filter((list: any) => list.comments !== null);

  return needToDisplay ? (
    <DataTableCell title="comments" className="center-align-spacing">
      <span>
        <img
          src={hasComments?.length > 0 ? iconPathComments : iconPathNoComments}
          onClick={handleCommentsModal}
          className="logo-image"
          alt="discrepancy"
        />
        <AddCommentsModal
          comments={commentsList}
          createCommentLoading={false}
          isOpen={isOpenModal}
          toggleOpen={handleModalToggle}
          toggleOpenOnAdd={handleModalToggleOnAdd}
          handleCancelButton={handleModalToggle}
          disabled={disabled}
        />
      </span>
      {isOpen &&
        createdByDetails?.map((lineItem: any) => (
          <div className="border-line">
            <DataTableCell>{lineItem.createdByName}</DataTableCell>
          </div>
        ))}
    </DataTableCell>
  ) : null;
};

/** Custom Rep/Covering cell */
export default CCOpsComments;
