/*
 * Event Items GraphQL specification
 * @module src/graphql/getEventItems
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export interface Event {
  territory: string;
  eventType: string;
  externalId: string;
  eventId: string;
  id: string;
  eventStatus: string;
  procedures: string;
  eventDate: string;
  eventStartDate: Date;
  eventEndDate: Date;
  eventTime: string;
  accountName: string;
  eventName: string;
  surgeonFirstName: string;
  surgeonLastName: string;
  surgeonName?: string;
  eventSubType: string;
  salesRep: string;
  coveringReps: string;
  duration: string;
  submitUsage: boolean;
  submitUsageDateTime: Date;
  eventDuration: string;
  eventDescription: string;
  cycleCountExternalId?: string;
}

/** Event Items GraphQL data interface */
export interface EventItemsData {
  eventItems: Event[];
}

interface Filters {
  status?: string[];
  salesReps?: string[];
  coveringReps?: string[];
  account?: string[];
  branch?: string[];
  franchise?: string[];
  surgeon?: string[];
  procedureDetail?: string[];
  startDate?: Date | string;
  orderSortType: string;
  orderBy: string;
  endDate?: Date | string;
  usage?: string;
  eventType?: string[];
  search?: string;
  ownership?: string;
}

/** Event Items GraphQL API query */
export const GET_EVENT_ITEMS = gql`
  query eventItems(
    $limit: Int
    $offset: Int
    $isSalesRep: Boolean
    $startDate: Date
    $endDate: Date
    $orderSortType: String
    $orderBy: String
    $filters: Filters
  ) {
    eventItems(
      limit: $limit
      offset: $offset
      isSalesRep: $isSalesRep
      startDate: $startDate
      orderSortType: $orderSortType
      orderBy: $orderBy
      endDate: $endDate
      filters: $filters
    ) {
      eventType
      eventId
      externalId
      id
      eventStatus
      procedures
      eventStartDate
      eventEndDate
      accountNumber
      accountName
      eventName
      eventAddress
      surgeonFirstName
      surgeonLastName
      surgeonErpCode
      salesRep
      coveringReps
      eventSubType
      createdById
      cycleCountId
      cycleCountExternalId
      eventDuration
      eventDescription
    }
  }
`;
