/**
 * Graphql mutation to delete filter preset
 * @module src/graphql/deleteFilterPreset
 */
import gql from 'graphql-tag';

/** delete filter preset */
const DELETE_FILTER_PRESET = gql`
  mutation deleteFilterPreset($externalId: String!) {
    deleteFilterPreset(externalId: $externalId) {
      message
    }
  }
`;

export default DELETE_FILTER_PRESET;
