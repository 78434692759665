/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Module for Cancel Event
 * @module src/components/SurgicalDetail/SurgeicalDetailCancelEvent/index
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
// import Icon from '@salesforce/design-system-react/components/icon';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Button from '@salesforce/design-system-react/components/button';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import './index.scss';
import { getUserId } from '../../../store/ducks/userId';
import { GET_CANCELLATION_REASONS } from '../../../graphql/getCancellationReasons';

interface Props {
  cancelModalVisible: boolean;
  handleCancelEventModal: () => void;
  eventType: string;
  eventId: string;
  cancelEvent: (reason: string) => void;
  cancelEventLoading: boolean;
}

interface CancelReason {
  id: number;
  name: string;
}

const CancelEvent: FC<Props> = ({
  cancelModalVisible,
  handleCancelEventModal,
  eventType,
  eventId,
  cancelEvent,
  cancelEventLoading,
}) => {
  const [selectedReason, setSelectedReason] = useState('');
  const [cancelReasons, setReasons] = useState<string[]>([]);
  const REGION = 'EU';
  const USER_ID = useSelector(getUserId);

  const { data: cancellationReasons, loading } = useQuery(GET_CANCELLATION_REASONS, {
    variables: {
      userId: USER_ID,
      region: REGION,
    },
  });

  const selectReason = (reason: string): void => {
    setSelectedReason(reason);
  };

  const handleCancelEvent = (): void => {
    cancelEvent(selectedReason);
  };

  // const cancelButtonDisabled = !!(selectedReason === '');

  useEffect(() => {
    if (cancellationReasons && cancellationReasons.getCancellationReasons) {
      let reasonsString = '';
      const reasons = cancellationReasons.getCancellationReasons;

      reasons.forEach((reason: any): void => {
        if (reason.reasons && reasonsString !== '') {
          reasonsString = `${reasonsString};${reason.reasons}`;
        } else if (reason.reasons) {
          reasonsString = `${reason.reasons}`;
        }
      });
      const reasonsArr = reasonsString.split(';');
      setReasons(reasonsArr);
    }
  }, [cancellationReasons]);

  return (
    <IconSettings iconPath="/icons">
      <Modal
        isOpen={cancelModalVisible}
        onRequestClose={handleCancelEventModal}
        dismissOnClickOutside={false}
        ariaHideApp={false}
        heading={
          <div className="slds-float_left slds-text-title_bold slds-text-heading_medium">{`${eventType} ${eventId}`}</div>
        }
        footer={[
          <div className="slds-align_absolute-center event_cancel_button" key="cancel_event">
            {cancelEventLoading && (
              <Spinner
                size="small"
                variant="base"
                assistiveText={{ label: 'Event creation Loading...' }}
              />
            )}
            <Button
              label="No"
              // disabled={cancelButtonDisabled}
              onClick={handleCancelEventModal}
            />
            <Button
              style={{ backgroundColor: '#ffb501' }}
              label="Yes"
              // disabled={cancelButtonDisabled}
              onClick={handleCancelEvent}
            />
          </div>,
        ]}
      >
        <section className={loading ? 'cancel_section' : ''}>
          {/* <div className="slds-grid slds-p-vertical_x-small slds-theme_warning slds-theme_alert-texture">
            <div className=" slds-size_0.8-of-12 slds-m-left_small ">
              <Icon
                assistiveText={{ label: 'Opportunity' }}
                category="utility"
                name="warning"
                size="x-small"
                style={{ color: '#000' }}
              />
            </div>
            <div className=" slds-size_11-of-12 slds-p-left_x-small slds-m-right_small">
              <span>Please indicate the reason for cancelling this event</span>
            </div>
          </div> */}

          {loading ? (
            <div className="slds-p-around_small customised-button-list">
              <Spinner size="large" variant="base" assistiveText={{ label: 'Loading...' }} />
            </div>
          ) : (
            <div style={{ position: 'relative', padding: '1rem' }}>
              {`Are you sure you want to cancel this Event?`}
              {/* {`Are you sure you want to cancel this ${eventType} ${eventId}`} */}
            </div>
            // cancelReasons &&
            // cancelReasons.map(reason => {
            //   return (
            //     <div className="slds-p-around_small customised-button-list" key={reason}>
            //       {reason === selectedReason && (
            //         <Icon
            //           assistiveText={{ label: 'Cancel Reason' }}
            //           category="utility"
            //           name="check"
            //           size="x-small"
            //           style={{ color: '#000' }}
            //         />
            //       )}

            //       <Button className="" onClick={(): void => selectReason(reason)}>
            //         <div>{reason}</div>
            //       </Button>
            //     </div>
            //   );
            // })
          )}
        </section>
      </Modal>
    </IconSettings>
  );
};

/** Cancel Event **/
export default CancelEvent;
