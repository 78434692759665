/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Combobox from '@salesforce/design-system-react/components/combobox';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
// import Spinner from '@salesforce/design-system-react/components/spinner';
// import './index.scss';
import moment from 'moment';
import Pill from '@salesforce/design-system-react/components/pill';
import DatePicker from '../../../Shared/DatePicker';
import AutocompleteSingleSelectPicker from '../../../Shared/AutocompleteSingleSelectPicker';
import { GET_SALES_REPS } from '../../../../graphql/getSalesReps';
import { GET_USER_TERRITORIES } from '../../../../graphql/getUserTerritories';
import { REPROCESS_RECORD_TYPE_OPTIONS, INVENTORY_3PL_OPTIONS } from '../../../../util/constants';
import { GET_PRODUCT_GROUP } from '../../../../graphql/getProductGroups';
import { GET_ACCOUNTS } from '../../../../graphql/getAccounts';
import { GET_OPS_LIST } from '../../../../graphql/getOpsList';
import { getUserInfo } from '../../../../store/ducks/userInfo';

interface DynamicType {
  [key: string]: any;
}
interface Props {
  openFilter: boolean;
  handleToggleFilter: () => void;
  applyFilters: (filters: object | null) => void;
  selectedFilter?: DynamicType | null;
}

interface SelectedData {
  id: string;
  label: string;
}
interface DropdownSelect {
  id: string;
  label?: string;
  value: string;
}

interface DateFilter {
  submitFromDate?: string;
  submitToDate?: string;
  needByDateFromDate?: string;
  needByDateToDate?: string;
}

interface OpsDropdownSelect {
  label: string;
  value: string;
  id: string;
}

const optionsWithLabel = (arr: []): any[] =>
  arr &&
  arr.map((elem: any) => {
    return { ...elem, label: `${elem.value}` };
  });

const optionsWithLabelAccount = (arr: []): any[] =>
  arr &&
  arr.map((elem: any) => {
    return {
      ...elem,
      label:
        elem?.isRepStockAccount === true
          ? ` * ${elem.number} ${elem.value}`
          : ` ${elem.number} ${elem.value}`,
    };
  });

const ReprocessQueueFilter: FC<Props> = ({
  openFilter,
  handleToggleFilter,
  applyFilters,
  selectedFilter,
}) => {
  const { data: salesRepsData } = useQuery(GET_SALES_REPS);
  // const { data: opsData } = useQuery(GET_OPS_LIST);
  const [getOpsList, { data: opsData }] = useLazyQuery(GET_OPS_LIST);
  const [open, setOpen] = useState(false);
  const [openType, setOpenType] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [salesRepOptions, setSalesRepOptions] = useState<DropdownSelect[]>();
  const [accountsOptions, setAccountsOptions] = useState<DropdownSelect[]>();
  const [selectedSalesRep, setSelectedSalesRep] = useState<DropdownSelect[]>();
  const [selectedAccount, setSelectedAccount] = useState<DropdownSelect[]>();
  const [selectedRecordType, setSelectedRecordType] = useState<DropdownSelect[]>();
  const [selectedThreePl, setSelectedThreePl] = useState<DropdownSelect[]>([]);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState<DropdownSelect[]>();
  const [selectedProductGroup, setSelectedProductGroup] = useState<DropdownSelect[]>();
  const [dates, setDates] = useState<DateFilter | null>(null);
  const [filter, setFilter] = useState<DynamicType | null>(selectedFilter || null);
  const [productGroup, { data: productGroupItems }] = useLazyQuery(GET_PRODUCT_GROUP);
  const productGroupList = productGroupItems?.getProductGroups;
  const [options, setOptions] = useState<OpsDropdownSelect[]>();
  const optionsWithLabelProduct =
    productGroupList &&
    productGroupList.map((elem: any) => {
      // eslint-disable-next-line no-underscore-dangle
      return { ...elem, label: `${elem.value}` };
    });
  const userInfo = useSelector(getUserInfo);
  const [getAccounts, { data: primaryAccountItems }] = useLazyQuery(GET_ACCOUNTS, {
    fetchPolicy: 'no-cache',
  });
  const accountItemsData = primaryAccountItems?.getAccounts;
  useEffect(() => {
    if (salesRepsData?.getSalesReps) {
      setSalesRepOptions(optionsWithLabel(salesRepsData.getSalesReps));
    }
  }, [salesRepsData]);
  useEffect(() => {
    if (accountItemsData) {
      setAccountsOptions(optionsWithLabelAccount(accountItemsData));
    }
  }, [accountItemsData]);

  useEffect(() => {
    if (userInfo) {
      getOpsList({
        variables: {
          personaName: userInfo?.personas,
        },
      });
    }
  }, [getOpsList, userInfo]);
  useEffect(() => {
    productGroup();
  }, [productGroup]);

  useEffect(() => {
    if (selectedFilter) {
      if (selectedFilter.assignedTo) {
        const filteredAssign = options?.filter(
          (item: any) => item.id === selectedFilter.assignedTo[0]
        );
        setSelectedAssignedTo(filteredAssign);
      } else {
        setSelectedAssignedTo([]);
      }
      if (selectedFilter.productGroups) {
        const output = selectedFilter.productGroups.map((item: any) => ({
          id: item,
          value: item,
          label: item.toUpperCase(),
        }));
        setSelectedProductGroup(output);
      } else {
        setSelectedProductGroup([]);
      }
      if (selectedFilter.threePL) {
        if (Array.isArray(selectedFilter.threePL)) {
          const output = selectedFilter.selectedThreePl.map((item: any) => ({
            id: item,
            value: item,
            label: item.toUpperCase(),
          }));
          setSelectedThreePl(output);
        }
        if (!Array.isArray(selectedFilter.threePL)) {
          const output = [
            {
              id: selectedFilter.threePL,
              value: selectedFilter.threePL,
              label: selectedFilter.threePL,
            },
          ];

          setSelectedThreePl(output);
        }
      } else {
        setSelectedThreePl([]);
      }
      if (selectedFilter.recordType) {
        const output = selectedFilter.recordType.map((item: any) => {
          if (item === 'Customer to Rep') {
            return {
              id: REPROCESS_RECORD_TYPE_OPTIONS[5].id,
              value: REPROCESS_RECORD_TYPE_OPTIONS[5].value,
              label: REPROCESS_RECORD_TYPE_OPTIONS[5].label,
              data: REPROCESS_RECORD_TYPE_OPTIONS[5].data,
            };
          }
          if (item === 'Rep to Rep') {
            return {
              id: REPROCESS_RECORD_TYPE_OPTIONS[3].id,
              value: REPROCESS_RECORD_TYPE_OPTIONS[3].value,
              label: REPROCESS_RECORD_TYPE_OPTIONS[3].label,
              data: REPROCESS_RECORD_TYPE_OPTIONS[3].data,
            };
          }
          if (item === 'Rep to Customer') {
            return {
              id: REPROCESS_RECORD_TYPE_OPTIONS[4].id,
              value: REPROCESS_RECORD_TYPE_OPTIONS[4].value,
              label: REPROCESS_RECORD_TYPE_OPTIONS[4].label,
              data: REPROCESS_RECORD_TYPE_OPTIONS[4].data,
            };
          }
          if (item === 'Inventory Request') {
            return {
              id: REPROCESS_RECORD_TYPE_OPTIONS[0].id,
              value: REPROCESS_RECORD_TYPE_OPTIONS[0].value,
              label: REPROCESS_RECORD_TYPE_OPTIONS[0].label,
              data: REPROCESS_RECORD_TYPE_OPTIONS[0].data,
            };
          }
          return {
            id: item,
            value: item,
            label: item,
            data: item,
          };
        });
        setSelectedRecordType(output);
      } else {
        setSelectedRecordType([]);
      }
      if (selectedFilter.account) {
        const filteredSalesRep = accountsOptions?.filter(
          (item: any) => item.id === selectedFilter.account[0]
        );
        setSelectedAccount(filteredSalesRep);
      } else {
        setSelectedAccount([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options,
    selectedFilter,
    setSelectedAssignedTo,
    setSelectedAccount,
    setSelectedProductGroup,
    setSelectedThreePl,
    setSelectedRecordType,
  ]);

  useEffect(() => {
    if (selectedFilter?.salesRep) {
      const filteredSalesRep = salesRepOptions?.filter(
        (item: any) => item.id === selectedFilter.salesRep[0]
      );
      setSelectedSalesRep(filteredSalesRep);
    } else {
      setSelectedSalesRep([]);
    }
  }, [selectedFilter, salesRepOptions]);

  useEffect(() => {
    if (opsData && opsData?.getOPSList) {
      const optionList = optionsWithLabel(opsData?.getOPSList);
      optionList.unshift({ id: 'Unassigned', value: 'Unassigned', label: 'UNASSIGNED' });
      setOptions(optionList);
    }
  }, [opsData]);

  useEffect(() => {
    if (selectedSalesRep && selectedSalesRep[0]?.id) {
      getAccounts({
        variables: {
          salesRepId: selectedSalesRep[0]?.id,
        },
      });
    } else {
      getAccounts();
    }
  }, [getAccounts, selectedSalesRep]);

  useEffect(() => {
    if (selectedFilter) {
      setFilter(selectedFilter);
    }
  }, [selectedFilter]);

  const handleChangeDate = (selectedDate: any, name: string): void => {
    if (selectedDate && name) {
      const obj = {
        ...filter,
        [name]: moment(selectedDate).format('YYYY-MM-DD'),
      };
      setFilter(obj);
      setDates({ ...dates, [name]: selectedDate });
    }
  };

  const handleSelect = (data: any, name: any): void => {
    if (data.selection) {
      let filterData = data.selection.map((a: any) =>
        name === 'salesRep' || name === 'assignedTo' ? a.id : a.value
      );

      if (name === 'threePL') {
        filterData = data.selection[0].value;
      }

      if (name === 'recordType') {
        filterData = data.selection.map((a: any) => a.data);
      }

      const obj = {
        ...filter,
        [name]: filterData,
      };
      setFilter(obj);
      switch (name) {
        case 'salesRep':
          setSelectedSalesRep(data.selection);
          break;
        case 'assignedTo':
          setSelectedAssignedTo(data.selection);
          break;
        case 'threePL':
          setSelectedThreePl(data.selection);
          break;
        case 'recordType':
          setSelectedRecordType(data.selection);
          break;
        case 'productGroups':
          setSelectedProductGroup(data.selection);
          break;
        default:
      }
    } else {
      if (name === 'salesRep') {
        setSelectedSalesRep(data);
      }
      if (name === 'account') {
        setSelectedAccount(data);
      }
      if (name === 'assignedTo') {
        setSelectedAssignedTo(data);
      }
      const filterData = data.map((a: any) => a.id);
      const obj = {
        ...filter,
        [name]: filterData,
      };
      setFilter(obj);
    }
  };
  const handleApplyFilter = (): void => {
    applyFilters(filter);
    handleToggleFilter();
  };
  const resetFilters = (): void => {
    setSelectedSalesRep([]);
    setSelectedAccount([]);
    setSelectedAssignedTo([]);
    setSelectedThreePl([]);
    setSelectedRecordType([]);
    setSelectedProductGroup([]);
    setDates(null);
    setFilter(null);
    applyFilters(null);
    // handleToggleFilter();
  };

  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={openFilter}
        onRequestClose={handleToggleFilter}
        footer={[
          <Button
            className="slds-float_left slds-text-color_default"
            label="Cancel"
            onClick={handleToggleFilter}
          />,
          <Button label="Reset Filter" key="Reset" onClick={resetFilters} />,
          <Button
            className="yellow-btn"
            label="Apply"
            onClick={handleApplyFilter}
            variant="brand"
            key="Apply"
          />,
        ]}
        heading={[
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div className="slds-float_left slds-text-title_bold filter_heading" key="filterName">
            Filters
          </div>,
        ]}
      >
        <section className="slds-p-around_medium slds-theme_shade">
          <div className="slds-card slds-p-around--medium">
            <span className="filtterTabName">Attributes</span>
            <p className="selectbox_label" style={{ marginBottom: '-5px', marginTop: '6px' }}>
              Record Type
            </p>
            <Combobox
              id="recordType"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpen(false);
                  setOpenType(true);
                },
                onRequestClose: (event: any): void => {
                  setOpenType(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'recordType'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'recordType'),
              }}
              labels={{
                label: ``,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              isOpen={openType}
              multiple
              placeholder="Select"
              options={REPROCESS_RECORD_TYPE_OPTIONS || []}
              selection={selectedRecordType ? selectedRecordType || [] : []}
              variant="readonly"
            />
            <p className="selectbox_label" style={{ marginBottom: '-5px', marginTop: '6px' }}>
              3PL Delivery
            </p>
            <Combobox
              id="3PL Delivery"
              labels={{
                label: ``,
                placeholder: 'Select an Option',
                noOptionsFound: 'No data found',
              }}
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'threePL'),
              }}
              placeholder="Select an Option"
              options={INVENTORY_3PL_OPTIONS || ''}
              selection={selectedThreePl || ''}
              variant="readonly"
            />
            <p className="selectbox_label" style={{ marginBottom: '-5px', marginTop: '6px' }}>
              Product Group
            </p>
            <Combobox
              id="productGroups"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpen(false);
                  setOpenType(false);
                  setOpenProduct(true);
                },
                onRequestClose: (event: any): void => {
                  setOpenProduct(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'productGroups'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'productGroups'),
              }}
              labels={{
                label: ``,
                placeholder: 'Select an Option',
                noOptionsFound: 'No data found',
              }}
              isOpen={openProduct}
              multiple
              placeholder="Select an Option"
              options={optionsWithLabelProduct || []}
              selection={selectedProductGroup ? selectedProductGroup || [] : []}
              variant="readonly"
            />
          </div>
          {/* </section> */}
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--small">Date</h1>
            <div className="date-error">
              {((dates?.needByDateToDate &&
                dates?.needByDateFromDate &&
                dates?.needByDateToDate < dates?.needByDateFromDate) ||
                (dates?.submitToDate &&
                  dates?.submitFromDate &&
                  dates?.submitToDate < dates?.submitFromDate)) && (
                <Pill
                  labels={{
                    label: 'Error: To Date must be greater than or equal to From Date',
                  }}
                  hasError
                />
              )}
            </div>
            <div>
              <p className="selectbox_label" style={{ marginBottom: '1px', marginTop: '6px' }}>
                Submitted Date
              </p>
              <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="From"
                    handleChangeDate={(data: any): void => handleChangeDate(data, 'submitFromDate')}
                    disabled={false}
                    isFilterDate
                    value={dates?.submitFromDate || null}
                  />
                </div>
                <div className="slds-col slds-align_absolute-center">
                  <span>-</span>
                </div>
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="To"
                    handleChangeDate={(data: any): void => handleChangeDate(data, 'submitToDate')}
                    disabled={false}
                    isFilterDate
                    value={dates?.submitToDate || null}
                  />
                </div>
              </div>
            </div>
            <div>
              <p className="selectbox_label" style={{ marginBottom: '1px', marginTop: '6px' }}>
                Need by Date
              </p>
              <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="From"
                    handleChangeDate={(data: any): void => {
                      handleChangeDate(data, 'needByDateFromDate');
                    }}
                    disabled={false}
                    isFilterDate
                    value={dates?.needByDateFromDate || null}
                  />
                </div>
                <div className="slds-col slds-align_absolute-center">
                  <span>-</span>
                </div>
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="To"
                    handleChangeDate={(data: any): void => {
                      handleChangeDate(data, 'needByDateToDate');
                    }}
                    disabled={false}
                    isFilterDate
                    value={dates?.needByDateToDate || null}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <section className="slds-p-around_medium slds-theme_shade"> */}
          <div className="slds-card slds-p-around--medium">
            <span className="filtterTabName">Other</span>
            <p className="selectbox_label" style={{ marginBottom: '-13px', marginTop: '6px' }}>
              Sales Rep
            </p>
            <AutocompleteSingleSelectPicker
              items={salesRepOptions}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'salesRep');
              }}
              disabled={false}
              selectedItem={selectedSalesRep || []}
              placeholder="Select an Option"
              label=""
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="salesRep"
              autoSelectValue={false}
            />
            <p className="selectbox_label" style={{ marginBottom: '-15px', marginTop: '6px' }}>
              Account
            </p>
            <AutocompleteSingleSelectPicker
              items={accountsOptions}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'account');
              }}
              disabled={false}
              selectedItem={selectedAccount || []}
              placeholder="Select an Option"
              label=""
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="account"
              autoSelectValue={false}
            />
            <p className="selectbox_label" style={{ marginBottom: '-14px', marginTop: '6px' }}>
              Assigned To
            </p>
            <AutocompleteSingleSelectPicker
              items={options}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'assignedTo');
              }}
              disabled={false}
              selectedItem={selectedAssignedTo || []}
              placeholder="Select an Option"
              label=""
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="assignedTo"
              autoSelectValue={false}
            />
          </div>
        </section>
      </Modal>
    </IconSettings>
  );
};

export default ReprocessQueueFilter;
