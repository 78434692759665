/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable */
/**
 * Modal for Filter event
 * @module src/components/InventoryReturnQueueFilter/index
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import moment from 'moment';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Pill from '@salesforce/design-system-react/components/pill';
import Icon from '@salesforce/design-system-react/components/icon';
import Combobox from '@salesforce/design-system-react/components/combobox';
import DatePicker from '../../../Shared/DatePicker';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import './index.scss';
import {
  FILTER_INVENTORY_REQUEST_TYPE,
  FILTER_INVENTORY_RETURNS_STATUSES,
} from '../../../../util/constants';
import { GET_PRODUCT_GROUP } from '../../../../graphql/getProductGroups';
import { GET_SALES_REPS } from '../../../../graphql/getSalesReps';
import { GET_USER_TERRITORIES } from '../../../../graphql/getUserTerritories';
import { GET_OPS_LIST } from '../../../../graphql/getOpsList';
import AutocompleteSingleSelectPicker from '../../../Shared/AutocompleteSingleSelectPicker';

interface SelectedData {
  id: string;
  label: string;
}

interface FilterPreset {
  filterValues: SelectedFilters;
  filterExternalId?: string;
  filterName?: string;
}

interface SelectedFilters {
  status: SelectedData[];
  shipFromDate: Date | string;
  shipToDate: Date | string;
  returnExpiryFromDate: Date | string;
  returnExpiryToDate: Date | string;
  requestedFromDate: Date | string;
  requestedToDate: Date | string;
  salesRep: any;
  search: string;
}

interface DynamicType {
  [key: string]: any;
}
interface DropdownSelect {
  id: string;
  label?: string;
  value: string;
}
interface OpsDropdownSelect {
  label: string;
  value: string;
  id: string;
}
interface Props {
  handleToggleFilterInventoryReturn: (filters: DynamicType | null) => void;
  filterInventoryReturnModalVisible: boolean;
  type?: string;
  selectedFilter?: DynamicType | null;
  applyFilters: (filters: object | null) => void;
}

const InventoryReturnQueueFilter: FC<Props> = ({
  handleToggleFilterInventoryReturn,
  filterInventoryReturnModalVisible,
  selectedFilter,
  applyFilters,
}) => {
  const filterValues = selectedFilter;
  const [open, setOpen] = useState(false);
  const [openType, setOpenType] = useState(false);
  const { data: salesRepsData } = useQuery(GET_SALES_REPS);
  const [selectedSalesRep, setSelectedSalesRep] = useState<DropdownSelect[]>();
  const [territoryOptions, setTerritoryOptions] = useState<DropdownSelect[]>();
  const [options, setOptions] = useState<OpsDropdownSelect[]>();
  const [salesRepOptions, setSalesRepOptions] = useState<any>();
  const [selectedFilters, setSelectedFilters] = useState<DynamicType | null>(selectedFilter || {}); // For Selected Value
  const [productGroup, { data: productGroupItems }] = useLazyQuery(GET_PRODUCT_GROUP);
  const [getTerritories, { data: territoryData }] = useLazyQuery(GET_USER_TERRITORIES);
  const productGroupList = productGroupItems?.getProductGroups;
  const userInfo = useSelector(getUserInfo);
  const getDate = useCallback((date: any): string => {
    const formattedSelectedDate = new Date(date);
    const finalDate = format(new Date(formattedSelectedDate), 'yyyy-MM-dd');
    return finalDate;
  }, []);
  const [getOpsList, { data: opsData }] = useLazyQuery(GET_OPS_LIST);
  useEffect(() => {
    productGroup();
  }, [productGroup]);

  useEffect(() => {
    if (userInfo) {
      getOpsList({
        variables: {
          personaName: userInfo?.personas,
        },
      });
    }
  }, [getOpsList, userInfo]);

  useEffect(() => {
    if (filterValues) {
      setSelectedFilters(filterValues);
      if (filterValues.status) {
        setFilterValue('status', [{ id: filterValues.status, label: filterValues.status }]);
      }
      if (filterValues.assignedTo) {
        const filteredAssign = options?.filter((item: any) => item.id === filterValues.assignedTo);
        setFilterValue('assignedTo', filteredAssign);
      }
      if (filterValues.salesRep) {
        const filteredSalesRep = salesRepOptions?.filter(
          (item: any) => item.id === filterValues.salesRep
        );
        setFilterValue('salesRep', filteredSalesRep);
      }
      if (filterValues.territory) {
        setFilterValue('territory', [
          { id: filterValues.territory, label: filterValues.territory.toUpperCase() },
        ]);
      }
    }
  }, [filterValues, setSelectedFilters]);

  const optionsWithLabelSalesrep = (arr: []): any[] =>
    arr &&
    arr.map((elem: any) => {
      return { ...elem, label: `${elem.value}` };
    });

  useEffect(() => {
    if (salesRepsData?.getSalesReps) {
      setSalesRepOptions(optionsWithLabelSalesrep(salesRepsData.getSalesReps));
    }
  }, [salesRepsData]);

  useEffect(() => {
    if (territoryData?.getUserTerritories) {
      setTerritoryOptions(optionsWithLabelSalesrep(territoryData.getUserTerritories));
    }
  }, [territoryData]);

  useEffect(() => {
    if (selectedFilters && selectedFilters?.salesRep) {
      getTerritories({
        variables: {
          userId: selectedFilters?.salesRep[0]?.id,
        },
      });
    }
  }, [getTerritories, selectedFilters]);

  useEffect(() => {
    if (opsData && opsData?.getOPSList) {
      const optionList = optionsWithLabelSalesrep(opsData?.getOPSList);
      optionList.unshift({ id: 'Unassigned', value: 'Unassigned', label: 'UNASSIGNED' });
      setOptions(optionList);
    }
  }, [opsData]);

  useEffect(() => {
    if (salesRepsData?.getSalesReps) {
      setSalesRepOptions(optionsWithLabelSalesrep(salesRepsData.getSalesReps));
    }
  }, [salesRepsData]);

  useEffect(() => {
    if (territoryData?.getUserTerritories) {
      setTerritoryOptions(optionsWithLabelSalesrep(territoryData.getUserTerritories));
    }
  }, [territoryData]);

  useEffect(() => {
    if (selectedFilters && selectedFilters?.salesRep) {
      getTerritories({
        variables: {
          userId: selectedFilters?.salesRep[0]?.id,
        },
      });
    }
  }, [getTerritories, selectedFilters]);

  useEffect(() => {
    if (opsData && opsData?.getOPSList) {
      const optionList = optionsWithLabelSalesrep(opsData?.getOPSList);
      optionList.unshift({ id: 'Unassigned', value: 'Unassigned', label: 'UNASSIGNED' });
      setOptions(optionList);
    }
  }, [opsData]);

  useEffect(() => {
    if (productGroupList && selectedFilters?.productGroup) {
      const record =
        productGroupList &&
        productGroupList.filter((rec: any) => rec.id === selectedFilters?.productGroup);
      const optionsWithLabel1 =
        record &&
        record.map((elem: any) => {
          // eslint-disable-next-line no-underscore-dangle
          return { ...elem, label: `${elem.id}` };
        });
      setFilterValue('productGroup', optionsWithLabel1);
    }
  }, [productGroupList]);

  const handleChangeDate = (selectedDate: any, name: string): void => {
    if (selectedDate) {
      const utcDate = getDate(selectedDate);
      setFilterValue(name, utcDate);
    }
  };

  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any): void => {
      // For selected filter value
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };

      setSelectedFilters(selectedFilterValues);
    },
    [selectedFilters]
  );

  const handleSelect = (data: any, name: string) => {
    const { selection } = data;
    if (name === 'salesRep') {
      setFilterValue(name, data);
    } else {
      setFilterValue(name, selection);
    }
  };

  const handleApplyFilter = (): void => {
    const filters = {
      ...selectedFilters,
      productGroup: (selectedFilters as any)?.productGroup?.[0].id,
      // status: (selectedFilters as any)?.status?.map((item: any) => item.id),
    };
    applyFilters(filters);
    handleToggleFilterInventoryReturn(filters);
  };

  const resetFilters = (): void => {
    setSelectedSalesRep([]);
    setSelectedFilters(null);
    applyFilters(null);
    handleToggleFilterInventoryReturn(null);
  };

  const optionsWithLabel =
    productGroupList &&
    productGroupList.map((elem: any) => {
      // eslint-disable-next-line no-underscore-dangle
      return { ...elem, label: `${elem.value}` };
    });

  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={filterInventoryReturnModalVisible}
        onRequestClose={handleToggleFilterInventoryReturn}
        footer={[
          <Button
            className="slds-float_left slds-text-color_default"
            label="Cancel"
            key="cancel"
            onClick={handleToggleFilterInventoryReturn}
          />,
          <Button label="Reset" key="Reset" onClick={resetFilters} />,
          <Button
            className="yellow-btn"
            label="Apply Filters"
            onClick={handleApplyFilter}
            variant="brand"
            key="Apply"
            disabled={
              (selectedFilters?.shipToDate &&
                selectedFilters?.shipFromDate &&
                selectedFilters.shipToDate < selectedFilters.shipFromDate) ||
              (selectedFilters?.requestedToDate &&
                selectedFilters?.requestedFromDate &&
                selectedFilters.requestedToDate < selectedFilters.requestedFromDate) ||
              (selectedFilters?.returnExpiryToDate &&
                selectedFilters?.returnExpiryFromDate &&
                selectedFilters.returnExpiryToDate < selectedFilters.returnExpiryFromDate)
            }
          />,
        ]}
        heading={'Filter'}
      >
        <section className="slds-p-around_medium slds-theme_shade">
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--small">Attributes</h1>
            {/* <p className="selectbox_label">Status</p> */}
            <Combobox
              id="status"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpen(true);
                  setOpenType(false);
                },
                onRequestClose: (event: any): void => {
                  setOpen(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'status'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'status'),
              }}
              labels={{
                label: `Status`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              isOpen={open}
              placeholder="Select"
              options={FILTER_INVENTORY_RETURNS_STATUSES || []}
              selection={selectedFilters ? selectedFilters.status || [] : []}
              variant="readonly"
            />
            {/* <p className="selectbox_label">Type</p> */}
            <Combobox
              id="returnType"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpenType(true);
                },
                onRequestClose: (event: any): void => {
                  setOpenType(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'returnType'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'returnType'),
              }}
              labels={{
                label: `Type`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              isOpen={openType}
              placeholder="Select"
              options={FILTER_INVENTORY_REQUEST_TYPE || []}
              selection={selectedFilters ? selectedFilters.returnType || [] : []}
              variant="readonly"
            />
            {/* <p className="selectbox_label">Product Group</p> */}
            <Combobox
              id="productGroup"
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'productGroup'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'productGroup'),
              }}
              labels={{
                label: `Product Group`,
                placeholder: 'Select Product Group',
                noOptionsFound: 'No data found',
              }}
              placeholder="Select Product Group"
              options={optionsWithLabel || []}
              selection={selectedFilters ? selectedFilters.productGroup || [] : []}
              variant="readonly"
            />
          </div>
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--small">DATE</h1>
            <div className="date-error">
              {((selectedFilters?.shipToDate &&
                selectedFilters?.shipFromDate &&
                selectedFilters.shipToDate < selectedFilters.shipFromDate) ||
                (selectedFilters?.requestedToDate &&
                  selectedFilters?.requestedFromDate &&
                  selectedFilters.requestedToDate < selectedFilters.requestedFromDate) ||
                (selectedFilters?.returnExpiryToDate &&
                  selectedFilters?.returnExpiryFromDate &&
                  selectedFilters.returnExpiryToDate < selectedFilters.returnExpiryFromDate)) && (
                  <Pill
                    labels={{
                      label: 'Error: To Date must be greater than or equal to From Date',
                    }}
                    hasError
                    icon={
                      <Icon
                        title="Error"
                        category="utility"
                        name="warning"
                        className="slds-icon-text-error"
                      />
                    }
                  />
                )}
            </div>
            <>
              <div>
                <p className="dateLabel">Requested Date</p>
                <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                  <div className="slds-col slds-align_absolute-center">
                    <DatePicker
                      label=""
                      placeholder="From"
                      handleChangeDate={(data: any): void =>
                        handleChangeDate(data, 'requestedFromDate')
                      }
                      disabled={false}
                      isFilterDate
                      value={
                        selectedFilters && selectedFilters.requestedFromDate
                          ? moment(selectedFilters.requestedFromDate).format()
                          : null
                      }
                    />
                  </div>
                  <div className="slds-col slds-align_absolute-center">
                    <span>-</span>
                  </div>
                  <div className="slds-col slds-align_absolute-center">
                    <DatePicker
                      label=""
                      placeholder="To"
                      handleChangeDate={(data: any): void =>
                        handleChangeDate(data, 'requestedToDate')
                      }
                      disabled={false}
                      isFilterDate
                      value={
                        selectedFilters && selectedFilters.requestedToDate
                          ? moment(selectedFilters.requestedToDate).format()
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
              <div>
                <p className="dateLabel">Ship Date</p>
                <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                  <div className="slds-col slds-align_absolute-center">
                    <DatePicker
                      label=""
                      placeholder="From"
                      handleChangeDate={(data: any): void => handleChangeDate(data, 'shipFromDate')}
                      disabled={false}
                      isFilterDate={true}
                      value={selectedFilters ? selectedFilters.shipFromDate : null}
                    />
                  </div>
                  <div className="slds-col slds-align_absolute-center">
                    <span>-</span>
                  </div>
                  <div className="slds-col slds-align_absolute-center">
                    <DatePicker
                      label=""
                      placeholder="To"
                      handleChangeDate={(data: any): void => handleChangeDate(data, 'shipToDate')}
                      disabled={false}
                      isFilterDate={true}
                      value={selectedFilters ? selectedFilters.shipToDate : null}
                    />
                  </div>
                </div>
              </div>
              <div>
                <p className="dateLabel">Return Expiration Date</p>
                <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                  <div className="slds-col slds-align_absolute-center">
                    <DatePicker
                      label=""
                      placeholder="From"
                      handleChangeDate={(data: any): void =>
                        handleChangeDate(data, 'returnExpiryFromDate')
                      }
                      disabled={false}
                      isFilterDate={true}
                      value={selectedFilters ? selectedFilters.returnExpiryFromDate : null}
                    />
                  </div>
                  <div className="slds-col slds-align_absolute-center">
                    <span>-</span>
                  </div>
                  <div className="slds-col slds-align_absolute-center">
                    <DatePicker
                      label=""
                      placeholder="To"
                      handleChangeDate={(data: any): void =>
                        handleChangeDate(data, 'returnExpiryToDate')
                      }
                      disabled={false}
                      isFilterDate={true}
                      value={selectedFilters ? selectedFilters.returnExpiryToDate : null}
                    />
                  </div>
                </div>
              </div>
            </>
          </div>
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--small">Other</h1>
            <p style={{marginBottom:-12,fontSize:16}}>Sales Rep</p>
            <AutocompleteSingleSelectPicker
              items={salesRepOptions || []}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'salesRep');
              }}
              disabled={false}
              selectedItem={selectedFilters ? selectedFilters.salesRep || [] : []}
              placeholder="Sales Rep"
              label=""
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="salesRep"
              autoSelectValue={false}
            />
            <Combobox
              id="territory"
              labels={{
                label: `Territory`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'territory'),
              }}
              placeholder="Select"
              options={territoryOptions || []}
              selection={selectedFilters ? selectedFilters.territory || [] : []}
              variant="readonly"
            />
            <Combobox
              id="assigned"
              labels={{
                label: `Assigned To`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'assignedTo'),
              }}
              placeholder="Select"
              options={options || []}
              selection={selectedFilters ? selectedFilters.assignedTo || [] : []}
              variant="readonly"
            />
          </div>
        </section>
      </Modal>
    </IconSettings>
  );
};

/** Filter event modal component */
export default InventoryReturnQueueFilter;
