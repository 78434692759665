/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, ReactNode } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
// import Popover from '@salesforce/design-system-react/components/popover';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import { useSelector } from 'react-redux';
import { getEventIcon } from '../../../util/utilityFunctions';
import { getUserInfo } from '../../../store/ducks/userInfo';

interface Props {
  // handleNewReturn: () => void;
  handleSearch: (event: any, val: string) => void;
  handleReturnsFilter: () => void;
  handleNewReturnsActionDropdownSelect: (val: any) => void;
}

interface UserInfo {
  contactId?: string;
  division: string | null;
  divisions: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  personas: string;
  userName: string | null;
  userType: string;
  businessunit: string;
  assignedScreens: any;
}

export const getReturnLabels = (userInfo: any): any => {
  const labels: { label: string; value: string }[] = [];

  const requiredForms = userInfo?.assignedScreens;

  if (requiredForms && requiredForms.length > 0) {
    const pickUpReturnForm = requiredForms.find(
      (item: any) => item.screen === 'Pickup Return - Create'
    );

    const standardReturnForm = requiredForms.find(
      (item: any) => item.screen === 'Standard Return - Create'
    );

    if (pickUpReturnForm && pickUpReturnForm?.isRequired) {
      labels.push({ label: 'Pickup Return', value: 'pickupReturn' });
    }

    if (standardReturnForm && standardReturnForm?.isRequired) {
      labels.push({ label: 'Standard Return', value: 'inventoryReturns' });
    }
  }

  return labels;
};

const ReturnHeader: FC<Props> = ({
  handleSearch,
  handleReturnsFilter,
  // handleNewReturn,
  handleNewReturnsActionDropdownSelect,
}) => {
  const userInfo: UserInfo | null = useSelector(getUserInfo);

  const labels = getReturnLabels(userInfo);

  const headerActions = (): ReactNode => (
    <PageHeaderControl>
      {labels.length > 0 && (
        <ButtonGroup id="button-group-page-header-actions">
          {/* <Button
          iconCategory="utility"
          className="slds-text-color_default"
          iconName="add"
          label="New"
          iconPosition="left"
          onClick={handleNewReturn}
        /> */}
          <Dropdown
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="add"
            iconPosition="left"
            label="New"
            options={labels || []}
            onSelect={(value: string): void => handleNewReturnsActionDropdownSelect(value)}
          />
        </ButtonGroup>
      )}
    </PageHeaderControl>
  );
  const headerControls = (): ReactNode => (
    <>
      <PageHeaderControl className="slds-m-left_large">
        <Input
          iconRight={
            <InputIcon
              assistiveText={{
                icon: 'Search',
              }}
              name="search"
              category="utility"
            />
          }
          id="search-by-product"
          onChange={(_event: any, searchItem: string): void => handleSearch(_event, searchItem)}
          placeholder="Search by Product"
        />
      </PageHeaderControl>
      <PageHeaderControl className="slds-m-left_large">
        <Button
          iconCategory="utility"
          className="slds-text-color_default"
          iconName="filterList"
          label="Filter"
          iconPosition="left"
          onClick={handleReturnsFilter}
        />
      </PageHeaderControl>
    </>
  );

  const iconPath = getEventIcon('Inventory');

  return (
    <div>
      <IconSettings iconPath="/icons">
        <PageHeader
          className="events_header"
          icon={<img src={iconPath} className="logo-image" alt="orders" />}
          label="Inventory"
          onRenderActions={headerActions}
          onRenderControls={headerControls}
          title="Returns"
          truncate
          variant="object-home"
        />
      </IconSettings>
    </div>
  );
};

export default ReturnHeader;
