/**
 * Graphql mutation for update privacy request
 * @module src/graphql/mutations
 */
import gql from 'graphql-tag';

/** Update Privacy Request */
const UPDATE_PRIVACY_REQUEST = gql`
  mutation updatePrivacyRequestInfo(
    $id: String!
    $rightToBeForgotten: Boolean
    $dataAccess: Boolean
  ) {
    updatePrivacyRequestInfo(
      id: $id
      rightToBeForgotten: $rightToBeForgotten
      dataAccess: $dataAccess
    ) {
      message
    }
  }
`;

export default UPDATE_PRIVACY_REQUEST;
