/*
 * Event Details GraphQL specification
 * @module src/graphql/workflowPermissions
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Event Details GraphQL API query */
export const GET_EVENT_DETAILS = gql`
  query getEventDetails($id: String, $externalId: String!) {
    getEventDetails(id: $id, externalId: $externalId) {
      caseId
      caseExternalId
      salesRepId
      eventType
      eventSubType
      eventId
      division
      eventStatus
      eventName
      eventAddress
      branchId
      branch
      accountId
      accountNumber
      accountName
      surgeonId
      surgeonFirstName
      surgeonLastName
      surgeonErpCode
      procedures
      salesRep
      coveringReps
      eventStartDate
      eventEndDate
      cancelReason
      cancelComment
      orderType
      createdDate
      updatedDate
      updatedBy
      createdBy
      poNumber
      patientId
      shippingAddessLine1
      shippingAddessLine2
      shippingPostalcode
      shippingCity
      shippingState
      billingAddessLine1
      billingAddessLine2
      billingPostalcode
      billingCity
      billingState
      isTBAAccount
      shipToAddress
      shippingId
      eventDuration
      eventDescription
      locationDescription
      street1
      street2
      city
      state
      zipCode
      isOtherAddress
      isShipToAddress
      opportunity
      opportunityId
      salesTeams {
        id
        value
      }
      proceduresList {
        id
        value: name
        type
      }
      comments {
        id
        externalId
        value: comments
        type
      }
      eventInventory {
        id
        externalId
        type
        name
        createdDate
        status
        serviceLevel
        needByDate
        visualIndicator
        orderSfid
      }
      eventOrder {
        id
        externalId
        type
        name
        createdDate
        status
        poNumber
        needByDate
        visualIndicator
        usgtotalamount
        orderSfid
      }
      eventReturn {
        id
        externalId
        type
        name
        createdDate
        status
        orderSfid
        returnExpiration
      }
      eventTransfer {
        id
        externalId
        type
        name
        status
        destinationAccountNumber
        destinationAccountName
        direction
      }
    }
  }
`;
