/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from '@salesforce/design-system-react/components/icon';
import moment from 'moment';
import { getHoldIcon, getWhiteBGIcon } from '../../../util/utilityFunctions';

interface Props {
  userView?: string;
  gridData?: any;
  selectedPart?: any;
  selectedAccount?: any;
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  colSpacing: {
    // width: '13%',
    textAlign: 'right',
  },
  hoverSpacing: {
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
  },
});

const StyledTableRow = withStyles(theme => ({
  root: {
    height: 35,
    backgroundColor: '#f4f4f4',
  },
}))(TableRow);

const StyledDataTableRow = withStyles(theme => ({
  root: {
    height: 35,
  },
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
  root: {
    padding: '0px 16px',
    fontWeight: 'bold',
  },
}))(TableCell);

const StyledCollapsibleTableCell = withStyles(theme => ({
  root: {
    padding: '0px 0px',
    fontWeight: 'bold',
  },
}))(TableCell);

const StyledTableLotCell = withStyles(theme => ({
  root: {
    padding: '0px 16px',
    fontWeight: 'bold',
    color: '#006DCC',
  },
}))(TableCell);

const Row = (props: any) => {
  const { row, selectedView } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const holdIconPath: any = getHoldIcon();
  const holdwhitePath: any = getWhiteBGIcon();
  const history = useHistory();
  const handleLink = (params: any) => {
    if (params) {
      history.push('/ReservedTransactions', {
        productId: params?.productId,
        lotNumber: params?.lotNumber,
        productDescription: params?.productDescription,
        productNumber: params?.productNumber,
        accountNumber: row.accountNumber,
      });
    }
  };
  const handleLinkClick = (params: any) => {
    handleLink(params);
  };
  return (
    <>
      <StyledDataTableRow className={classes.root}>
        <StyledTableCell style={{ paddingLeft: '1%', paddingRight: '1%' }}>
          <span>
            {row.isAnylotExpired ? (
              <Icon
                category="utility"
                name="warning"
                size="x-small"
                style={{
                  fill: '#D2042D',
                  // margin: '10px',
                }}
              />
            ) : (
              ''
            )}

            {(!open && row.onholdReason === 'LOT') || (!open && row.onholdReason === 'SERIAL') ? (
              <img
                src={holdIconPath}
                className="logo-image"
                alt="company"
                style={{
                  marginLeft: row.onholdReason && row.isAnylotExpired ? '8px' : '0px',
                  // marginRight: '8px'
                }}
              />
            ) : (
              ''
            )}
            {row.onholdReason === 'ITEM' ? (
              <img
                src={holdIconPath}
                className="logo-image"
                alt="company"
                style={{
                  marginLeft: row.onholdReason && row.isAnylotExpired ? '8px' : '0px',
                  // marginRight: '8px'
                }}
              />
            ) : (
              ''
            )}
            {/* {(!open && row.onholdReason === 'LOT') ||
            (!open && row.onholdReason === 'SERIAL') ||
            row.onholdReason === 'ITEM' ? (
              ''
            ) : (
              <img src={holdwhitePath} className="logo-image" alt="company" />
            )} */}
            <IconButton
              aria-label="expand row"
              className={classes.hoverSpacing}
              size="small"
              onClick={() => setOpen(!open)}
            >
              {!open ? (
                <Icon
                  assistiveText={{ label: 'Announcement' }}
                  category="utility"
                  name="chevronright"
                  size="x-small"
                  style={{
                    fill: '#000',
                    display: 'inline-block',
                    marginLeft:
                      row.onholdReason && row.isAnylotExpired
                        ? '10px'
                        : row.onholdReason || row.isAnylotExpired
                        ? '35px'
                        : '50px',
                  }}
                />
              ) : (
                <Icon
                  assistiveText={{ label: 'Announcement' }}
                  category="utility"
                  name="chevrondown"
                  size="x-small"
                  style={{
                    fill: '#000',
                    display: 'inline-block',
                    marginLeft:
                      row.onholdReason === 'ITEM' || row.isAnylotExpired ? '35px' : '50px',
                  }}
                />
              )}
            </IconButton>
          </span>
        </StyledTableCell>
        {selectedView === 'By Account' ? (
          <StyledTableCell style={{ width: '20%' }}>
            {`${row.productNumber} ${row.productDescription}`}
          </StyledTableCell>
        ) : (
          <StyledTableCell style={{ width: '20%' }}>
            {row.accountDescription !== '' ? `${row.accountDescription}` : row.subInventoryName}
          </StyledTableCell>
        )}
        <StyledTableCell style={{ width: '15%' }}>{}</StyledTableCell>
        <StyledTableCell style={{ width: '12%' }}>{}</StyledTableCell>
        <StyledTableCell
          className={classes.colSpacing}
          style={{
            textAlign: 'right',
            paddingRight: window.innerWidth > 1280 ? '1.45rem' : '1.25rem',
            width: '12%',
          }}
        >
          {row.totalavailableqty}
        </StyledTableCell>
        <StyledTableCell
          className={classes.colSpacing}
          style={{
            textAlign: 'right',
            paddingRight: window.innerWidth > 1280 ? '2.1rem' : '1.85rem',
            width: '12%',
          }}
        >
          <span
            role="button"
            tabIndex={0}
            className="slds-button"
            onClick={
              () =>
                handleLink({
                  productId: row.productId,
                  productDescription: row.productDescription,
                  productNumber: row.productNumber,
                  accountNumber: row.accountNumber,
                })
              // eslint-disable-next-line react/jsx-curly-newline
            }
            onKeyDown={
              () =>
                handleLinkClick({
                  productId: row.productId,
                  productDescription: row.productDescription,
                  productNumber: row.productNumber,
                  accountNumber: row.accountNumber,
                })
              // eslint-disable-next-line react/jsx-curly-newline
            }
            style={{ cursor: 'pointer' }}
          >
            {row.totalreservedqty}
          </span>
        </StyledTableCell>
        <StyledTableCell style={{ width: '12%' }} className={classes.colSpacing}>
          {row.totalonhandqty}
        </StyledTableCell>
      </StyledDataTableRow>
      <TableRow>
        <StyledCollapsibleTableCell colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                {row.lotDetails.map((lot: any) => (
                  <StyledDataTableRow>
                    <StyledTableCell style={{ width: '10%' }} />
                    <StyledTableCell style={{ width: '20%' }} />
                    <StyledTableLotCell
                      style={{
                        width: '15%',
                        textAlign: 'left',
                      }}
                    >
                      {lot.lotNumber}
                      {row.onholdReason === 'ITEM' ? (
                        <img
                          src={holdIconPath}
                          className="logo-image"
                          alt="company"
                          style={{
                            marginLeft: '4px',
                            marginRight: '2px',
                            position: 'absolute',
                            // left: '55%',
                          }}
                        />
                      ) : (
                        ''
                      )}

                      {lot.serialRecalled && lot.serialRecalled.includes(lot?.lotNumber) ? (
                        // {lot.lotonhold === true ? (
                        <img
                          src={holdIconPath}
                          className="logo-image"
                          alt="company"
                          style={{
                            position: 'absolute',
                            marginLeft: '4px',
                            marginTop: '2px',
                            // left: '55%',
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </StyledTableLotCell>
                    <StyledTableCell style={{ width: '12%', textAlign: 'center' }}>
                      {/* {lot.expirationDate ? moment(lot.expirationDate).format('MM/DD/YYYY') : '--'} */}
                      {lot.expirationDate ? lot.expirationDate : '--'}
                      {/* <span>
                        {lot.isExpired && (
                          <Icon
                            category="utility"
                            name="warning"
                            size="x-small"
                            style={{ fill: '#D2042D', marginLeft: '5px' }}
                          />
                        )}
                        {lot.isExpiredWithin30days && (
                          <Icon
                            category="utility"
                            name="clock"
                            size="x-small"
                            style={{ fill: '#FFAE42', marginLeft: '5px' }}
                          />
                        )}
                        {lot.isExpiredWithin90days && (
                          <Icon
                            category="utility"
                            name="notification"
                            size="x-small"
                            style={{ fill: '#000084', marginLeft: '5px' }}
                          />
                        )}
                      </span> */}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.colSpacing}
                      style={{
                        textAlign: 'right',
                        width: '12%',
                        paddingRight: window.innerWidth > 1280 ? '1.45rem' : '1.25rem',
                        // paddingRight:
                        //   row.onholdReason === 'LOT' || row.onholdReason === 'SERIAL'
                        //     ? '2.8rem'
                        //     : '2.8rem',
                      }}
                    >
                      {lot.availableqty}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.colSpacing}
                      style={{
                        textAlign: 'right',
                        width: '12%',
                        paddingRight: window.innerWidth > 1280 ? '2.1rem' : '1.85rem',
                        // paddingRight:
                        //   row.onholdReason === 'LOT' || row.onholdReason === 'SERIAL'
                        //     ? '35px'
                        //     : '55px',
                      }}
                    >
                      <span
                        role="button"
                        tabIndex={0}
                        className="slds-button"
                        onClick={
                          () =>
                            handleLink({
                              productId: row.productId,
                              lotNumber: lot.lotNumber,
                              productNumber: row.productNumber,
                              productDescription: row.productDescription,
                              accountNumber: row.accountNumber,
                            })
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                        onKeyDown={
                          () =>
                            handleLinkClick({
                              productId: row.productId,
                              lotNumber: lot.lotNumber,
                              productNumber: row.productNumber,
                              productDescription: row.productDescription,
                              accountNumber: row.accountNumber,
                            })
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        {lot.reservedeqty}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        width: '12%',
                      }}
                      className={classes.colSpacing}
                    >
                      {lot.onhandqty}
                    </StyledTableCell>
                  </StyledDataTableRow>
                ))}
              </Table>
            </Box>
          </Collapse>
        </StyledCollapsibleTableCell>
      </TableRow>
    </>
  );
};

const InventoryListAccountView: React.FC<Props> = ({
  userView,
  gridData,
  selectedPart,
  selectedAccount,
}) => {
  const classes = useRowStyles();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <StyledTableRow style={{ width: '15%', textAlign: 'left' }}>
          <StyledTableCell style={{ width: '10%' }} />
          <StyledTableCell style={{ width: '20%' }}>
            {userView === 'By Account' ? 'Part' : 'Account'}
          </StyledTableCell>
          <StyledTableCell style={{ width: '15%', textAlign: 'left' }}>Lot/Serial</StyledTableCell>
          <StyledTableCell style={{ width: '12%', textAlign: 'left' }}>
            Expiration Date
          </StyledTableCell>
          <StyledTableCell className={classes.colSpacing}>
            Available
            <span id="tooltip" title="On Hand - Reserved = Available">
              <Icon
                category="utility"
                name="info"
                size="x-small"
                style={{
                  marginBottom: '3px',
                  marginLeft: '10px',
                }}
              />
            </span>
          </StyledTableCell>
          <StyledTableCell className={classes.colSpacing}>
            EIM Reserved
            <span
              id="tooltip"
              title="The quantity committed to pending transactions not fully processed in the system of record."
            >
              <Icon
                category="utility"
                name="info"
                size="x-small"
                style={{
                  marginBottom: '3px',
                  marginLeft: '10px',
                }}
              />
            </span>
          </StyledTableCell>
          <StyledTableCell className={classes.colSpacing}>
            On Hand
            <span
              id="tooltip"
              title="The physical quantity the system of record has for this location."
            >
              <Icon
                category="utility"
                name="info"
                size="x-small"
                style={{
                  marginBottom: '3px',
                  marginLeft: '10px',
                }}
              />
            </span>
          </StyledTableCell>
        </StyledTableRow>
        {(userView === 'By Account' && selectedAccount && selectedAccount.length > 0) ||
        (userView === 'By Part' && selectedPart && selectedPart.length > 0) ? (
          <TableBody>
            {gridData &&
              gridData.map((row: any) => (
                <Row key={row.productNumber} row={row} selectedView={userView} />
              ))}
          </TableBody>
        ) : (
          <TableBody>
            {userView === 'By Account' && (!selectedAccount || selectedAccount?.length === 0) && (
              <>
                <StyledTableCell />
                <StyledTableCell style={{ fontWeight: 'bold' }}>
                  Select an Account to view Inventory
                </StyledTableCell>
              </>
            )}
            {userView === 'By Part' && (!selectedPart || selectedPart?.length === 0) && (
              <>
                <StyledTableCell />
                <StyledTableCell style={{ fontWeight: 'bold' }}>
                  Select a Part to view Inventory Across Accounts
                </StyledTableCell>
              </>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default InventoryListAccountView;
