/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Notification settings component.
 * @module src/components/App/NotificationSettings/index
 */
import React, { FC, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Button from '@salesforce/design-system-react/components/button';
import Modal from '@salesforce/design-system-react/components/modal';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import './index.scss';
import { GET_WORKFLOW_PERMISSION } from '../../../graphql/workflowPermissions';

interface Notification {
  [key: string]: boolean;
}
interface Props {
  notificationModalVisible: boolean;
  handleNotificationModal: () => void;
  notification: Notification;
  handleNotificationsSavePress: (setings: Notification) => void;
  loading: boolean;
}

interface ChecklistKey {
  [Key: string]: {
    status: boolean;
    text: string;
  };
}

interface WorkflowPermission {
  workflowPermission: {
    id: string;
  } | null;
}

interface Workflow {
  [key: string]: WorkflowPermission;
}

const checkList = {
  eventInvite: {
    status: false,
    text: 'Event Invite',
  },
  eventUpdate: {
    status: false,
    text: 'Event Update',
  },
  eventCancellation: {
    status: true,
    text: `Event Cancellation`,
  },
  eventInitiated: {
    status: true,
    text: 'Event Initiated from Provider',
  },
  inventoryShipment: {
    status: false,
    text: 'Inventory Shipment',
  },
  customerComment: {
    status: true,
    text: 'Customer Comment',
  },
  atpNotification: {
    status: false,
    text: 'ATP Notification',
  },
  urgentReplenish: {
    status: true,
    text: 'Urgent Replenish',
  },
  pickupReminder: {
    status: false,
    text: 'Urgent Replenish',
  },
};

const NotificationSettings: FC<Props> = ({
  notificationModalVisible,
  handleNotificationModal,
  notification,
  handleNotificationsSavePress,
  loading,
}) => {
  const { data: enableEventInvite } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'notification',
      action: 'enable_event_invite',
    },
  });
  const { data: enableEventUpdate } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'notification',
      action: 'enable_event_update',
    },
  });
  const { data: enableEventCancellation } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'notification',
      action: 'enable_event_cancellation',
    },
  });
  const { data: enableEventInitiated } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'notification',
      action: 'enable_event_initiated',
    },
  });
  const { data: enableInventoryShipment } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'notification',
      action: 'enable_inventory_shipment',
    },
  });
  const { data: enableCustomerComment } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'notification',
      action: 'enable_customer_comment',
    },
  });
  const { data: enableAtpNotification } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'notification',
      action: 'enable_atp',
    },
  });
  const { data: enableUrgentReplenishNotification } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'notification',
      action: 'enable_urgent_replenish',
    },
  });

  const workflowPermissions: Workflow = {
    eventInvite: enableEventInvite,
    eventUpdate: enableEventUpdate,
    eventCancellation: enableEventCancellation,
    eventInitiated: enableEventInitiated,
    inventoryShipment: enableInventoryShipment,
    customerComment: enableCustomerComment,
    atpNotification: enableAtpNotification,
    urgentReplenish: enableUrgentReplenishNotification,
  };

  const [checkBoxItems, setcheckBoxItems] = useState<ChecklistKey>(checkList);

  const setCheckboxItems = (): void => {
    const newChecklist = { ...checkBoxItems };
    Object.entries(notification).map(([key, value]) => {
      if (key !== '__typename' && newChecklist[key]) {
        newChecklist[key].status = value;
      }
      return newChecklist;
    });
    setcheckBoxItems(newChecklist);
  };

  useEffect(() => {
    if (notification) {
      setCheckboxItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const setStatus = (key: string) => (): void => {
    const newChecklist = { ...checkBoxItems };
    newChecklist[key].status = !checkBoxItems[key].status;
    setcheckBoxItems(newChecklist);
  };

  const handleCancelPress = (): void => {
    setCheckboxItems();
    handleNotificationModal();
  };

  const handleSaveNotifications = (): void => {
    let notificationApiValues = {};
    Object.entries(checkBoxItems).map(([key, value]) => {
      notificationApiValues = { ...notificationApiValues, [key]: value.status };
      return notificationApiValues;
    });
    handleNotificationsSavePress(notificationApiValues);
  };

  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="notification-modal default-modal_header default-modal_footer"
        isOpen={notificationModalVisible}
        dismissOnClickOutside={false}
        ariaHideApp={false}
        footer={[
          <Button label="Cancel" disable={loading} onClick={handleCancelPress} key="cancel" />,
          <Button
            label="Done"
            disable={loading}
            variant="brand"
            onClick={handleSaveNotifications}
            key="done"
          />,
        ]}
        onRequestClose={handleNotificationModal}
        heading="Notifications"
      >
        <div className="slds-text-align--left">
          {Object.entries(checkBoxItems).map(([key, value]) => {
            return (
              <>
                {key === 'eventInvite' && (
                  <div className="slds-text-align--left testing" key="eventInviteHeading">
                    <h2 className="slds-m-horizontal--medium slds-m-vertical--medium ">
                      Outlook Calendar
                    </h2>
                  </div>
                )}
                {key === 'eventInitiated' && (
                  <div className="slds-text-align--left testing" key="eventInitiatedHeading">
                    <h2 className="slds-m-horizontal--medium slds-m-vertical--medium ">Other</h2>
                  </div>
                )}
                {workflowPermissions[key] && (
                  <div className="clone-option-checkbox" key={key}>
                    <div className="slds-form__item custom_label-hide" role="listitem">
                      <div className="slds-form-element slds-form-element_edit slds-form-element_stacked slds-hint-parent">
                        <div className="slds-form-element__control slds-custom_input slds-custom_checkboxes">
                          <div className="slds-form-element__control ">
                            <div className="slds-form-element__static">{value.text}</div>
                            <div className="slds-button slds-button_icon slds-input__icon slds-input__icon_right">
                              <div className="slds-col_padded">
                                <Checkbox
                                  id={key}
                                  variant="toggle"
                                  onChange={setStatus(key)}
                                  checked={value.status || false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </Modal>
    </IconSettings>
  );
};

/** Notification settings component */
export default NotificationSettings;
