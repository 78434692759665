import gql from 'graphql-tag';

const DELETE_ATTACHMENT = gql`
  mutation deleteAttachment($id: String) {
    deleteAttachment(id: $id) {
      message
    }
  }
`;

export default DELETE_ATTACHMENT;
