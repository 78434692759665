/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useEffect, useState } from 'react';
import Tabs from '@salesforce/design-system-react/components/tabs';
import Card from '@salesforce/design-system-react/components/card';
import Button from '@salesforce/design-system-react/components/button';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import { useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DropdownTrigger from '@salesforce/design-system-react/components/menu-dropdown/button-trigger';
import { getUserInfo } from '../../../../../../store/ducks/userInfo';
import './index.scss';
import UsageComments from './UsageComments';
import AddCommentsModal from './AddCommentsModal';
import { getUTCDateTime } from '../../../../../../util/utilityFunctions';
import CREATE_COMMENT from '../../../../../../graphql/mutations/createComment';

interface DropdownButton {
  label: string;
  value: string;
}

interface Comment {
  id: string;
  comment: string;
  createdDate: string;
  commentType: string;
  commentExternalId: string;
  postTime: string;
  userId: string;
  sfid: string;
  userName: string;
}
interface Props {
  usageDetails?: any;
  viewOnly: boolean;
}
interface Comments {
  id?: string;
  value: string;
  type?: string;
  postTime?: string;
  user?: string;
}

const Comments: FC<Props> = ({ usageDetails, viewOnly }) => {
  const [comments, setComments] = useState<Comments[]>([]);
  const [addCommentType, setAddCommentType] = useState('');
  const userInfo = useSelector(getUserInfo);
  const [createComment, { loading: createCommentLoading }] = useMutation(CREATE_COMMENT);
  useEffect(() => {
    if (usageDetails?.comments) {
      setComments(usageDetails.comments.reverse());
    }
  }, [usageDetails]);
  const handleDropdownButtonPress = (selectedItem: DropdownButton): void => {
    const { value } = selectedItem;
    setAddCommentType(value);
  };
  const closeModal = (): void => {
    setAddCommentType('');
  };
  const handleAdd = (input: string): void => {
    const type = addCommentType === 'internalComment' ? 'Internal' : 'Customer';
    // Create
    createComment({
      variables: {
        comment: input,
        caseExternalId: usageDetails.caseExternalId,
        postTime: getUTCDateTime(moment().toString()),
        commentType: type,
      },
    }).then(() => closeModal());
    const obj = {
      value: input,
      postTime: moment().toString(),
      type,
      user: `${userInfo?.firstName} ${userInfo?.lastName}`,
    };
    const updatedComments = [obj, ...comments];
    setComments(updatedComments);
  };

  return (
    <>
      <Card
        id="Comments"
        headerActions={
          <Dropdown
            disabled={viewOnly}
            assistiveText={{ icon: 'More Options' }}
            align="right"
            tabIndex="-1"
            width="x-small"
            onSelect={(value: DropdownButton): void => {
              handleDropdownButtonPress(value);
            }}
            options={[
              { label: 'Internal Comment', value: 'internalComment' },
              { label: 'Customer Comment', value: 'customerComment' },
            ]}
          >
            <DropdownTrigger>
              <Button
                iconCategory="utility"
                iconName="down"
                iconPosition="right"
                className="color-black_button"
                label="New"
              />
            </DropdownTrigger>
          </Dropdown>
        }
        heading="Comments"
      >
        <hr className="card-divider" />
        <Tabs id="tabs-example-default" className="comment-user_tabs">
          <TabsPanel label="Internal" className="slds-p-horizontal_small">
            <UsageComments comments={comments} type="Internal" />
          </TabsPanel>
          <TabsPanel label="Customer">
            <UsageComments comments={comments} type="Customer" />
          </TabsPanel>
        </Tabs>
      </Card>
      {addCommentType && (
        <AddCommentsModal
          commentModalVisible={addCommentType !== ''}
          closeModal={closeModal}
          handleAdd={handleAdd}
          createCommentLoading={createCommentLoading}
        />
      )}
    </>
  );
};

export default Comments;
