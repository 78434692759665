/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useState, useCallback, useEffect } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { escapeRegExp } from 'lodash';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Pill from '@salesforce/design-system-react/components/pill';
import Icon from '@salesforce/design-system-react/components/icon';
import DatePicker from '../../Shared/DatePicker';
import { getUserId } from '../../../store/ducks/userId';
import { getUserInfo } from '../../../store/ducks/userInfo';
import { GET_SALES_REPS } from '../../../graphql/getSalesReps';
import AutocompleteSingleSelectPicker from '../../Shared/AutocompleteSingleSelectPicker';
import AutocompleteSingleSelectPickerWithSearch from '../../Shared/AutocompleteSingleSelectPickerWithSearch';
import {
  DATE_VIEW_FORMATTER, FILTER_CYCLECOUNT_STATUS,
  FILTER_CYCLE_COUNT,
  SPORTS_MED
} from '../../../util/constants';
import { GET_USER_TERRITORIES } from '../../../graphql/getUserTerritories';
import { GET_FILTERED_PRODUCT_GROUPS } from '../../../graphql/getFilteredProductGroups';
import { GET_ACCOUNTS } from '../../../graphql/getAccounts';
import { GET_OPS_LIST } from '../../../graphql/getOpsList';
import CREATE_FILTER_PRESET from '../../../graphql/mutations/createFilterPreset';
import UPDATE_FILTER_PRESET from '../../../graphql/mutations/updateFilterPreset';
import DELETE_FILTER_PRESET from '../../../graphql/mutations/deleteFilterPreset';
import SaveFilter from '../../FilterEvent/SaveFilter';
import DeleteFilterPreset from '../../FilterEvent/DeleteFilterPreset';

interface Props {
  handleToggleFilterCycleCount: () => void;
  filterModalVisible: boolean;
  handleFilterCycleCount: (filter: DynamicType | null) => void;
  resetFilters: () => void;
  selectedFilter?: DynamicType | null;
  type?: string;
  handleEditFilter: (editEvent: boolean) => void;
  handleClearFilter: (externalId: string) => void;
  refetchFilterPresets: () => void;
  isEditFilter: boolean;
}

interface SelectedData {
  key: any;
  number: any;
  id: string;
  label: string;
}

interface OpsDropdownSelect {
  label: string;
  value: string;
  id: string;
}

interface DynamicType {
  [key: string]: any;
}

interface ValueInterface {
  value: string;
}
interface SelectedFilters {
  status: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  inventoryLocation: SelectedData[];
  salesRep: SelectedData[];
  countType: string[]
}

enum DetailTypes {
  Account = 'Account',
  SalesRep = 'SalesRep',
  ProcedureDetail = 'ProcedureDetail',
  CoveringRep = 'CoveringRep',
  Surgeon = 'Surgeon',
  userTerritory = 'UserTerritory',
  TBAAccount = 'TBAAccount',
  ProductGroup = "ProductGroup"
}

enum DetailTypesSM {
  InventoryLocation = 'UserTerritory',
}

const FilterCycleCount: FC<Props> = ({
  handleToggleFilterCycleCount,
  filterModalVisible,
  selectedFilter,
  handleFilterCycleCount,
  resetFilters,
  handleEditFilter,
  handleClearFilter,
  refetchFilterPresets,
  isEditFilter,
}) => {
  const filterValues = selectedFilter?.filterValues;
  const filterName = selectedFilter ? selectedFilter.filterName : '';
  const filterExternalId = selectedFilter ? selectedFilter.filterExternalId : null;

  const userId = useSelector(getUserId);
  const userInfo = useSelector(getUserInfo);
  const [createFilterPreset, { data: filterPresets }] = useMutation(CREATE_FILTER_PRESET);
  const [updateFilterPreset, { data: updatedfilterPresets }] = useMutation(UPDATE_FILTER_PRESET);
  const [deleteFilterPreset] = useMutation(DELETE_FILTER_PRESET);

  // const dispatch = useDispatch();
  // // useState
  const [selectedFilters, setSelectedFilters] = useState<DynamicType>(filterValues || {});
  const [options, setOptions] = useState<any[]>();

  const { data: territoryData, loading: loadingTerritory } = useQuery(GET_USER_TERRITORIES, {
    variables: {
      userId: userId,
    },
  });
  const { data: filteredproductGroups, loading: loadingProductGroup } = useQuery(
    GET_FILTERED_PRODUCT_GROUPS
  );
  const [accountOptions, setAccountOptions] = useState<any>();

  const [
    getEligibleAccounts,
    { data: eligibleAccountsData, loading: loadingEligibleAccounts },
  ] = useLazyQuery(GET_ACCOUNTS, { fetchPolicy: 'no-cache' });

  const [getSalesRepsItems, { data: salesRepsItems, loading: loadingSalesRep }] = useLazyQuery(
    GET_SALES_REPS
  );
  const [getOpsList, { data: opsList }] = useLazyQuery(GET_OPS_LIST);

  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [territory, setTerritory] = useState<any>(null);
  const [inputValue, setInputValue] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  useEffect(() => {
    getSalesRepsItems();
    if (selectedFilters && selectedFilters.salesRep) {
      getEligibleAccounts({
        variables: {
          salesRepId: selectedFilters.salesRep[0]?.id,
          limit: 1000
        },
      });
    } else {
      getEligibleAccounts({
        variables: {
          limit: 1000
        },
      });
    }
  }, [selectedFilters])

  useEffect(()=>{
    if(selectedFilters && selectedFilters.account && selectedFilters.salesRep){
      selectedFilters.account=[];
    }
  },[selectedFilters?.salesRep])

  const optionsWithLabel = (value: any): object =>
    value &&
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      if (
        [
          DetailTypes.ProductGroup,
        ].indexOf(elem.__typename as any) >= 0
      ) {
        return { ...elem, label: `${elem.value}` };
      } if (
        [
          DetailTypes.Account,
        ].indexOf(elem.__typename as any) >= 0
      ) {
        return { ...elem, label: `${elem.number} ${elem.value}`, id: `${elem.number}` };
      }
    });


  useEffect(() => {
    getSalesRepsItems();
    getOpsList({
      variables: {
        personaName: userInfo?.personas,
      },
    });
  }, [getSalesRepsItems, getOpsList, userInfo]);

  useEffect(() => {
    if (opsList && opsList.getOPSList) {
      const optionList = opsList.getOPSList.map((i: any) => {
        return {
          label: i.value,
          value: i.value,
          id: i.id,
          type: 'assignedTo'
        };
      });
      optionList.unshift({ id: 'Unassigned', value: 'Blank', label: 'Unassigned', type: 'assignedTo' });
      setOptions(optionList);
    }
  }, [opsList]);

  const productGroup = optionsWithLabel(filteredproductGroups?.getProductGroups);

  const getApiDateTime = useCallback((eventDate: any, name: string): string => {
    const formattedSelectedDate = new Date(eventDate);
    let updatedDate;

    if (name.toLowerCase().includes('enddate') || name.toLowerCase().includes('todate')) {
      updatedDate = new Date(formattedSelectedDate).setHours(23);
      updatedDate = new Date(updatedDate).setMinutes(59);
      updatedDate = new Date(updatedDate).setSeconds(59);
    } else {
      updatedDate = new Date(formattedSelectedDate).setHours(0);
      updatedDate = new Date(updatedDate).setMinutes(0);
      updatedDate = new Date(updatedDate).setSeconds(0);
    }

    const finalDate = format(new Date(updatedDate), DATE_VIEW_FORMATTER);
    return finalDate;
  }, []);

  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any): void => {
      // For selected filter value
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };

      setSelectedFilters(selectedFilterValues);
    },
    [selectedFilters]
  );

  const handleSelect = useCallback(
    (data: any, name: string) => {
      const { selection } = data;
      if ((name === 'salesRep' || name === 'account') && data) {
        setFilterValue(name, data);
      } else if (name === 'assignedTo' && data) {
        setFilterValue(name, data)
      } else {
        setFilterValue(name, selection);
      }
    },
    [setFilterValue]
  );

  const handleSelectPG = useCallback(
    (data: any, name: string) => {
      if (data.selection) {
        const { selection } = data;
        setFilterValue(name, selection);
      } else {
        setFilterValue(name, data);
      }
    },
    [setFilterValue, userId]
  );

  const getAccountdata = (searchText: string): void => {
      getEligibleAccounts({
      variables: {
        salesRepId: (selectedFilters && selectedFilters.salesRep && selectedFilters.salesRep[0]) ? selectedFilters.salesRep[0]?.id : '',
        limit: 1000,
        filters: {
          searchText,
        },
      },
    });
  };

  const comboboxFilterAndLimit = (parameters: any): any => {
    const { searchedValue, options, selection } = parameters;
    const inputValueRegExp = new RegExp(escapeRegExp(searchedValue), 'ig');
    return options.filter((option: any) => {
      const searchTermFound = option?.label ? option?.label.match(inputValueRegExp) : false;
      const isSeparator = false;
      const notAlreadySelected = !selection?.some((sel: any) => sel?.id === option?.id);
      return (!searchedValue || isSeparator || searchTermFound) && notAlreadySelected;
    });
  };

  const reset = (): void => {
    handleToggleFilterCycleCount();
    setSelectedFilters({});
    // setSelectedOrderTypes([]);
    resetFilters();
    //   dispatch(clearOrderFilters());
  };

  const handleChangeDate = (selectedDate: any, name: string): void => {
    if (selectedDate) {
      const utcDate = getApiDateTime(selectedDate, name);
      setFilterValue(name, utcDate);
    }
  };

  const handleApplyFilter = (): void => {
    handleToggleFilterCycleCount();
    handleFilterCycleCount(selectedFilters);
  };

  const handleSelectItem = useCallback(
    (_event, data: any) => {
      const { selection } = data;
      setInputValue('');
      const updatedSelection: Selection[] = Object.values(
        selection.reduce((acc: any, cur: any) => Object.assign(acc, { [cur.id]: cur }), {})
      );
      handleSelectPG(updatedSelection, 'productGroup');
    },
    [setInputValue, handleSelect]
  );

  const handleCreateFilterPreset = (name: string): void => {
    let reducedFilterValues = {};
    if (selectedFilters) {
      Object.keys(selectedFilters).forEach((key: string) => {
        if (Array.isArray(selectedFilters[key]) && key !== 'eventType') {
          if(key === 'salesRep') {
            const values = selectedFilters[key].map((item: SelectedData) => {
              const itemCopy = { id: item.id, label: item.label };
              return { ...itemCopy };
            });
            reducedFilterValues = { ...reducedFilterValues, salesReps: [...values] };
          }
          else {
            if(key === 'countType') {
              const values = selectedFilters[key].map((item: SelectedData) => {
                const itemCopy = { id: item?.key, label: item?.label };
                return { ...itemCopy };
              });
              reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
            } else {
              const values = selectedFilters[key].map((item: SelectedData) => {
                const itemCopy = { id: item.id, label: item.label };
                return { ...itemCopy };
              });
              reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
            }
        }
        } else if (key !== '__typename' && selectedFilters[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: selectedFilters[key] };
        }
      });
    }
    const filterData = { ...reducedFilterValues };

    if (!filterExternalId || filterExternalId === '') {
      createFilterPreset({
        variables: {
          userId,
          filterValues: filterData,
          name,
          type: 'CycleCount',
        },
      });
    } else if (filterExternalId) {
      updateFilterPreset({
        variables: {
          externalId: filterExternalId,
          filterValues: filterData,
          name,
          type: 'CycleCount',
        },
      });
    }
  };

  const handleSaveModalVisible = (): void => {
    if (!filterExternalId || filterExternalId === '') {
      setSaveModalVisible(!saveModalVisible);
    } else {
      handleCreateFilterPreset(filterName);
    }
  };

  const handleDeleteModalVisible = useCallback((): void => {
    setDeleteModalVisible(!deleteModalVisible);
  }, [deleteModalVisible]);

  const handleDeleteFilterPreset = (): void => {
    deleteFilterPreset({
      variables: {
        externalId: filterExternalId,
      },
    }).then(response => {
      // if delete filter preset is successful
      const deleteUsageResponse =
        response.data &&
        response.data.deleteFilterPreset &&
        response.data.deleteFilterPreset.message === 'success';
      if (deleteUsageResponse) {
        handleDeleteModalVisible();
        handleToggleFilterCycleCount();
        handleClearFilter(filterExternalId);
        if (refetchFilterPresets) {
          handleEditFilter(false);
          refetchFilterPresets();
        }
      }
    });
  };

  useEffect(() => {
    if (
      updatedfilterPresets &&
      updatedfilterPresets.updateFilterPreset &&
      updatedfilterPresets.updateFilterPreset.message === 'success'
    ) {
      handleToggleFilterCycleCount();
      if (refetchFilterPresets) {
        handleEditFilter(false);
        refetchFilterPresets();
        reset();
      }
    }
  }, [
    handleEditFilter,
    handleFilterCycleCount,
    handleToggleFilterCycleCount,
    refetchFilterPresets,
    selectedFilters,
    updatedfilterPresets,
  ]);

  useEffect(() => {
    if (
      filterPresets &&
      filterPresets.createFilterPreset &&
      filterPresets.createFilterPreset.message === 'success'
    ) {
      handleToggleFilterCycleCount();
      if (refetchFilterPresets) {
        handleEditFilter(false);
        refetchFilterPresets();
      }
    }
  }, [
    filterPresets,
    handleEditFilter,
    handleFilterCycleCount,
    handleToggleFilterCycleCount,
    refetchFilterPresets,
    selectedFilters,
  ]);


  const optionsWithLabelTerritory = (subInventoryCode: any): object =>
    subInventoryCode &&
    subInventoryCode.map((elem: {
      value: any; __typename: string; subInventoryCode: any
    }) => {
      if ([DetailTypesSM.InventoryLocation].indexOf(elem.__typename as any) >= 0) {
        return { ...elem, label: `${elem.subInventoryCode}` };
      }
    });

  const optionsWithSalesRepLabel = (value: []): any[] =>
    value &&
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      if (elem.__typename === 'SalesRep') {
        return { ...elem, label: `${elem.value}` };
      }
      return { ...elem, label: `${elem.value}` };
    });

  const removeDuplicates = (array: any): any => {
    const newArray: any[] = [];
    array.filter((item: any) => {
      const i = newArray.findIndex(x => x?.number === item?.number);
      if (i <= -1) {
        newArray.push(item);
      }
      return null;
    });
    return newArray;
  };

  const salesRepsData = optionsWithSalesRepLabel(salesRepsItems?.getSalesReps);

  useEffect(() => {
      // if (territoryData?.getUserTerritories && userInfo?.businessunit === SPORTS_MED) {
      //   setAccountOptions(
      //     optionsWithLabelTerritory(territoryData?.getUserTerritories)
      //   );
      // } else {
        if (eligibleAccountsData?.getAccounts) {
          setAccountOptions(
            optionsWithLabel(
              removeDuplicates(eligibleAccountsData?.getAccounts)
            )
          );
        }
  }, [territoryData, eligibleAccountsData]);
  const handleChangeOnType = useCallback(
    (_event, selectedValue: ValueInterface) => {
      const { value } = selectedValue;
      _event.persist();
      setInputValue(value);
    },
    [setInputValue]
  );

  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={filterModalVisible}
        onRequestClose={handleToggleFilterCycleCount}
        footer={[
          <Button
            className="slds-float_left slds-text-color_default"
            label="Save Filter"
            disabled={
              selectedFilters?.endDate &&
              selectedFilters?.startDate &&
              selectedFilters.endDate < selectedFilters.startDate
            }
            key="Save"
            onClick={handleSaveModalVisible}
          />,
          <Button label="Reset Filter" key="Reset" onClick={reset} />,
          <Button
            className="yellow-btn"
            label="Apply"
            onClick={handleApplyFilter}
            disabled={
              selectedFilters?.endDate &&
              selectedFilters?.startDate &&
              selectedFilters.endDate < selectedFilters.startDate
            }
            variant="brand"
            key="Apply"
          />,
        ]
        }
        heading={[
          <div className="slds-float_left slds-text-title_bold filter_heading" key="filterName">
            {`${filterName && filterName !== ''
              ? `${isEditFilter ? 'Edit ' : ''}Filter Preset ${filterName}`
              : 'Filters'
              }`}
          </div>,

          isEditFilter && (
            <div className="slds-float_right" key="delete">
              <Button
                className="delete_button"
                label="Delete Preset"
                key="delete"
                onClick={handleDeleteModalVisible}
              />
            </div>
          ),
        ]}
      >
        <section className="slds-p-around_medium slds-theme_shade">
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--xx-small">Attributes</h1>
            <p className="selectbox_label" style={{marginBottom: '-4px'}}>Count Type</p>
            <Combobox
              id="countType"
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'countType'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'countType'),
              }}
              labels={{
                label: ``,
                placeholder: 'Select Count Type',
                noOptionsFound: 'No data found',
              }}
              multiple
              placeholder="Select Count Type"
              options={FILTER_CYCLE_COUNT || []}
              selection={selectedFilters ? selectedFilters?.countType || [] : []}
              variant="readonly"
            />
            <div className="filter_date_container">
            <p className="selectbox_label" style={{marginBottom: '-4px'}}>Status</p>
            <Combobox
              id="status"
              events={{
                onSelect: (event: any, data: any): void => handleSelect(data, 'status'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'status'),
              }}
              labels={{
                label: ``,
                placeholder: 'Select Status',
                noOptionsFound: 'No data found'
              }}
              multiple
              placeholder="Select Status"
              options={FILTER_CYCLECOUNT_STATUS || []}
              selection={selectedFilters ? selectedFilters?.status || [] : []}
              variant="readonly"
            />
            </div>
            <div className="filter_date_container">
            <p className="selectbox_label" style={{marginBottom: '-4px'}}>Product Group</p>
            <Combobox
              id="product"
              options={comboboxFilterAndLimit({
                searchedValue: inputValue,
                options: productGroup || [],
                selection: selectedFilters?.productGroup || [],
              })}
              events={{
                onChange: handleChangeOnType,
                onRequestRemoveSelectedOption: (event: any, data: any): void => handleSelectPG(data, 'productGroup'),
                onSelect: handleSelectItem,
              }}
              optionsAddItem={[]}
              labels={{
                label: ``,
                placeholder: 'Select an Option',
                noOptionsFound: 'No data found',
              }}
              disabled={false}
              multiple
              placeholder="Select an Option"
              menuPosition="relative"
              value={inputValue}
              hasInputSpinner={loadingProductGroup}
              selection={selectedFilters ? selectedFilters.productGroup || [] : []}
              variant="base" />
              </div>
          </div>
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--xx-small">Date</h1>
              <p className="selectbox_label">Submit Date</p>
              <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="Select Submit Date"
                    handleChangeDate={(data: any): void => handleChangeDate(data, 'submittedDate')}
                    disabled={false}
                    isFilterDate={true}
                    value={selectedFilters ? selectedFilters?.submittedDate : null}
                  />
                </div>
              </div>
            <div className="filter_date_container">
              <p className="selectbox_label">Start Date</p>
              <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="Select Start Date"
                    handleChangeDate={(data: any): void => handleChangeDate(data, 'startDate')}
                    disabled={false}
                    isFilterDate={true}
                    value={selectedFilters ? selectedFilters?.startDate : null}
                  />
                </div>
              </div>
            </div>
            <div className="filter_date_container">
              <p className="selectbox_label">End Date</p>
              <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                <div className="slds-col slds-align_absolute-center">
                  <DatePicker
                    label=""
                    placeholder="Select End Date"
                    handleChangeDate={(data: any): void => handleChangeDate(data, 'endDate')}
                    disabled={false}
                    isFilterDate={true}
                    value={selectedFilters ? selectedFilters?.endDate : null}
                  />
                </div>
              </div>
            </div>
            {selectedFilters?.endDate &&
              selectedFilters?.startDate &&
              selectedFilters.endDate < selectedFilters.startDate && (
                <Pill
                  labels={{
                    label: 'Error: To Date must be greater than or equal to From Date',
                  }}
                  hasError
                  icon={
                    <Icon
                      title="Error"
                      category="utility"
                      name="warning"
                      className="slds-icon-text-error"
                    />
                  }
                />
              )}
          </div>

          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--xx-small">Other</h1>
            <p className="selectbox_label" style={{marginBottom: '-10px'}}>Sales Rep</p>
            <AutocompleteSingleSelectPicker
              items={salesRepsData}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'salesRep');
              }}
              disabled={false}
              selectedItem={selectedFilters ? selectedFilters?.salesRep || [] : []}
              placeholder="Select an Option"
              label=""
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="salesRep"
              autoSelectValue={false}
            />
            <div className="filter_date_container">
            <p className="selectbox_label" style={{marginBottom: '-10px'}}>Account</p>
            <AutocompleteSingleSelectPickerWithSearch
                items={accountOptions}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'account');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters?.account || [] : []}
                multiple={true}
                variant="base"
                placeholder="Select an Option"
                label=""
                withAddMenuButton={false}
                fetchItems={(searchText: string) => getAccountdata(searchText)}
                filter
                name="account"
                autoSelectValue={false}
                hasInputSpinnerCheck={loadingEligibleAccounts}
              />
            </div>
            <div className="filter_date_container">
              <p className="selectbox_label" style={{marginBottom: '-10px'}}>Assigned To</p>
              <AutocompleteSingleSelectPicker
                items={options}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'assignedTo');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters?.assignedTo || [] : []}
                placeholder="Select an Option"
                label=""
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="assignedTo"
                autoSelectValue={false}
              />
            </div>
          </div>
          {saveModalVisible && (
            <SaveFilter
              handleSaveModalVisible={handleSaveModalVisible}
              saveModalVisible={saveModalVisible}
              handleCreateFilterPreset={handleCreateFilterPreset}
              selectedFilterName={filterName}
            />
          )}
          {deleteModalVisible && (
            <DeleteFilterPreset
              deleteModalVisible={deleteModalVisible}
              handleDeleteModalVisible={handleDeleteModalVisible}
              handleDeleteFilterPreset={handleDeleteFilterPreset}
            />
          )}
        </section>
      </Modal>
    </IconSettings>
  );
};

export default FilterCycleCount;
