import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import EventStatusBadge from '../../Shared/EventStatusBadge';
import { getSvgIcon } from '../../../util/utilityFunctions';

interface Status {
  eventStatus: string;
  visualIndicator?: string;
  parentEventStatus: string;
  status: string;
  showRedWarningIcon: boolean;
  showBackorderdWarning: boolean;
  thresholdQuantityIndicator: boolean;
}
export interface Props {
  children?: FC;
  item?: Status;
  type?: string;
}

const OrderRequestStatusCell: FC<Props> = ({
  item = {
    eventStatus: '',
    parentEventStatus: '',
    status: '',
    visualIndicator: '',
    thresholdQuantityIndicator: false,
    showRedWarningIcon: false,
    showBackorderdWarning: false,
  },
  type = null,
}) => {
  const {
    eventStatus,
    status,
    visualIndicator,
    showRedWarningIcon,
    showBackorderdWarning,
    thresholdQuantityIndicator,
  } = item;
  const statusToShow =
    // eslint-disable-next-line no-nested-ternary
    visualIndicator === 'Rejected' || visualIndicator === 'Approved'
      ? eventStatus
      : visualIndicator;
  let orderStatus = statusToShow;
  let showWarningIcon = false;
  const iconBackorder = getSvgIcon('backorder');
  const iconThreshold = getSvgIcon('threshold');

  if (
    type !== 'Processed' &&
    type !== 'Failed' &&
    eventStatus === 'Requested' &&
    visualIndicator === 'Rejected'
  ) {
    showWarningIcon = true;
  }

  if (type === 'Processed' || type === 'Failed') {
    orderStatus = visualIndicator === 'Completed' ? visualIndicator : status;
    showWarningIcon = showRedWarningIcon;
  }

  return (
    <IconSettings iconPath="/icons">
      <DataTableCell title={orderStatus || 'NEW'}>
        <div style={{ display: 'flex' }}>
          <EventStatusBadge status={orderStatus || 'NEW'} />
          {showWarningIcon && (
            <div style={{ marginLeft: '5px' }}>
              <Icon
                title="Rejected"
                category="utility"
                name="warning"
                size="x-small"
                style={{ color: '#b83c27' }}
                className="slds-icon-text-error"
              />
            </div>
          )}
          {showBackorderdWarning && (
            <img
              src={iconBackorder}
              alt="backorder"
              style={{ width: '20px', height: '15px', marginLeft: '5px' }}
            />
          )}
          {thresholdQuantityIndicator && (
            <img
              className="slds-tooltip-trigger"
              title="Threshold Exceed"
              src={iconThreshold}
              alt="backorder"
              style={{ width: '20px', height: '15px', marginLeft: '5px' }}
            />
          )}
        </div>
      </DataTableCell>
    </IconSettings>
  );
};

export default OrderRequestStatusCell;
