/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * App component file.
 * @module src/components/App
 */
/* eslint-disable no-console */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import Spinner from '@salesforce/design-system-react/components/spinner';
import { GET_TRANSLATIONS } from '../../graphql/getTranslations';
import i18n from '../../util/i18n';

interface Resources {
  [key: string]: any;
}

const TranslationTest: FC = () => {
  const { t } = useTranslation();

  const changeLanguage = (language: string) => (): any => {
    i18n.changeLanguage(language);
  };

  const transKey = 'Translation-'.concat(i18n.language);
  const { data: translationData, loading: translationLoading } = useQuery(GET_TRANSLATIONS, {
    variables: {
      translationKey: transKey,
    },
  });

  // Included the loading parameter so data has time to be returned
  if (translationLoading) {
    return <Spinner size="medium" variant="base" assistiveText={{ label: 'Loading...' }} />;
  }

  if (!i18n.hasResourceBundle(i18n.language, 'translation')) {
    const payloadResource: Resources = {};
    if (translationData !== undefined) {
      const translationJSON = JSON.parse(translationData?.getTranslations);
      Object.keys(translationJSON).forEach(name => {
        if (Object.prototype.hasOwnProperty.call(translationJSON, name)) {
          const label = translationJSON[name];
          // Some labels are empty. Do we want to use them or get rid of them?
          // If we use them, how should we display them as a key value pair?
          // Currently, no english translation has a label. That means the name should be displayed
          if (label === '') {
            payloadResource[name] = name;
          } else {
            payloadResource[name] = label;
          }
        }
      });

      i18n.addResourceBundle(i18n.language, 'translation', payloadResource);
    }
  }

  /* 
  Translation is currently the only namespace being written into
  Namespaces allow to seperate translations that get loaded into multiple files. 
  https://www.i18next.com/principles/namespaces
  */

  return (
    <div className="slds-p-horizontal_large slds-p-vertical_xx-large">
      <h1 className="slds-m-around--large">Page 1</h1>
      {t('total')}
      <br />
      <br />
      <button type="button" onClick={changeLanguage('de')()}>
        German
      </button>
      <button type="button" onClick={changeLanguage('en_US')()}>
        English
      </button>
    </div>
  );
};

/** App component */
export default TranslationTest;
