/**
 * Redux file for getting/setting event filters
 * @module src/store/ducks/inventroyHoldFilters
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_IN_HOLD_FILTERS = 'SET_IN_HOLD_FILTERS';
const CLEAR_IN_HOLD_FILTERS = 'CLEAR_IN_HOLD_FILTERS';

interface SetInventoryHoldFiltersActionType {
  payload: string | null;
  type: typeof SET_IN_HOLD_FILTERS;
}

interface ClearInventoryHoldFiltersActionType {
  type: typeof CLEAR_IN_HOLD_FILTERS;
}

/** eventFilters action type */

export type InventoryHoldFilterActionType =
  | SetInventoryHoldFiltersActionType
  | ClearInventoryHoldFiltersActionType;

/** set eventFilters action creator */

export const setInventoryHoldFilters = (state: string | null): InventoryHoldFilterActionType => {
  return {
    payload: state,
    type: SET_IN_HOLD_FILTERS,
  };
};

/** clear eventFilters action creator */

export const clearInventoryHoldFilters = (): ClearInventoryHoldFiltersActionType => {
  return {
    type: CLEAR_IN_HOLD_FILTERS,
  };
};

/** eventFilters state type */

export type InventoryHoldFilterState = string | null;

/** eventFilters reducer */

export default (
  state: InventoryHoldFilterState = null,
  action: ActionType
): InventoryHoldFilterState => {
  switch (action.type) {
    case SET_IN_HOLD_FILTERS: {
      return action.payload;
    }
    case CLEAR_IN_HOLD_FILTERS: {
      return null;
    }
    default:
      return state;
  }
};

/** selector to get accessToken */

export const getInventoryHoldFilters = (state: State): InventoryHoldFilterState => {
  return state.inventroyHoldFilters;
};
