/*
 * Get Ship To Addresses GraphQL specification
 * @module src/graphql/getStates
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_STATES = gql`
  query getStates {
    getStates {
      id
      code
      name
    }
  }
`;
