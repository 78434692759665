/**
 * Redux file for getting/setting online status.
 * @module src/store/ducks/onlineStatus
 */

/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_ONLINE = 'SET_ONLINE';

interface SetOnlineActionType {
  payload: boolean;
  type: typeof SET_ONLINE;
}
/** onlineAction type */
export type OnlineActionType = SetOnlineActionType;

/** action creator  */
export const setOnlineStatus = (state: boolean): OnlineActionType => {
  return {
    payload: state,
    type: SET_ONLINE,
  };
};

/** reducer type */
export type OnlineState = boolean;

/** reducer */
export default (state: OnlineState = true, action: ActionType): OnlineState => {
  switch (action.type) {
    case SET_ONLINE:
      return action.payload;
    default:
      return state;
  }
};

/** selector to get online status */
export const getOnlineStatus = (state: State): OnlineState => state.onlineStatus;
