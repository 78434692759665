/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Calendar Component using react-big-calendar.
 * @module src/components/Calendar/
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
// import { useLazyQuery } from '@apollo/react-hooks';
import Spinner from '@salesforce/design-system-react/components/spinner';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  startOfMonth,
  endOfMonth,
  addHours,
  addMinutes,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
} from 'date-fns/esm';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// import { GET_EVENT_ITEMS } from '../../../graphql/getEventItems';
// import { addHours } from 'date-fns';
import { EVENT_STATUS, MOCK_OR_EVENT_TYPE } from '../../../util/constants';
import EventsCalendarDay from './EventsCalendarDay';
import CustomToolBar from './CustomToolbar';
import { getUserId } from '../../../store/ducks/userId';
import './index.scss';
import { getLocalDateTime } from '../../../util/utilityFunctions';

const localizer = momentLocalizer(moment);

interface Event {
  eventType: string;
  externalId: string;
  eventId: string;
  id: string;
  eventStatus: string;
  procedures: string;
  eventDate: string;
  eventStartDate: Date;
  eventEndDate: Date;
  eventTime: string;
  accountName: string;
  eventName: string;
  surgeonFirstName: string;
  surgeonLastName: string;
  surgeonName?: string;
  eventSubType: string;
  salesRep: string;
  coveringReps: string;
  duration: string;
  eventDuration: any;
}

interface Filters {
  status?: string[];
  salesReps?: string[];
  coveringReps?: string[];
  account?: string;
  branch?: string[];
  franchise?: string[];
  surgeon?: string;
  procedureDetail?: string[];
  startDate?: Date | string;
  endDate?: Date | string;
  usage?: string;
  eventType?: string[];
  search?: string;
}

// Custom Event container to deploy custom event component on the calendar.
const CustomEventContainer = ({
  handleNavigateToEventDetail,
  currentView,
}: {
  handleNavigateToEventDetail: (eventType: string, externalId: string, id: string) => void;
  currentView: string;
  // TODO: Change type of props from any to more specific type
}) => (props: any): React.ReactElement => {
  return (
    <EventsCalendarDay
      eventData={props}
      handleNavigateToEventDetail={handleNavigateToEventDetail}
      currentView={currentView}
    />
  );
};

// Custom toolbar container to deploy custom toolbar component on the calendar.
const CustomToolbarContainer = ({
  currentView,
}: {
  currentView: string;
  // TODO: Change type of props from any to more specific type
}) => (props: any): React.ReactElement => {
  return <CustomToolBar toolbar={props} currentSelectedView={currentView} />;
};

// Funtion to return custom styling for the custom event component.
// TODO: Change return type from any to more specific type
const eventStyleGetter = (event: Event): any => {
  let backgroundColor;
  let borderColor;
  switch (event.eventStatus) {
    case EVENT_STATUS.NEW:
      backgroundColor = 'rgba(184, 60, 39, 0.15)';
      borderColor = ' #b83c27';
      break;
    case EVENT_STATUS.REQUESTED:
      backgroundColor = 'rgba(255, 181, 0, 0.15)';
      borderColor = '#CF6E29';
      break;
    case EVENT_STATUS.SHIPPED:
      backgroundColor = 'rgba(88, 184, 55, 0.15)';
      borderColor = '#58B837';
      break;
    case EVENT_STATUS.PORTAL:
      backgroundColor = 'rgba(76, 125, 135, 0.15)';
      borderColor = '#4C7D87';
      break;
    case EVENT_STATUS.ASSIGNED:
      backgroundColor = 'rgba(255, 181, 0, 0.15)';
      borderColor = '#FFB500';
      break;
    case EVENT_STATUS.COMPLETED:
      backgroundColor = 'rgba(169, 202, 245)';
      borderColor = '#2B72CC';
      break;
    case EVENT_STATUS.CANCELLED:
      backgroundColor = 'rgba(0,0,0, 0.15)';
      borderColor = '#000000';
      break;
    case EVENT_STATUS.CANCEL:
      backgroundColor = 'rgba(0,0,0, 0.15)';
      borderColor = '#000000';
      break;
    case EVENT_STATUS.CLOSED:
      backgroundColor = 'rgba(178,180,174, 0.15)';
      borderColor = '#B2B4AE';
      break;
    default:
      backgroundColor = '';
  }
  const style = {
    backgroundColor,
    opacity: 0.8,
    color: '#000',
    border: '0px',
    display: 'block',
    borderRadius: '4px',
    borderLeft: `6px solid ${borderColor}`,
  };
  return {
    style,
  };
};

export interface Props extends RouteComponentProps {
  children?: FC;
  activeTab: string;
  selectedFilterApiValues: Filters | null;
  eventItems: Event[];
  getEventsData: (variables: any) => void;
  loading: boolean;
}

type View = 'month' | 'week' | 'work_week' | 'day' | 'agenda';

const EventsCalendar: FC<Props> = ({
  history,
  activeTab,
  selectedFilterApiValues,
  eventItems,
  getEventsData,
  loading,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [eventItem, setEventItems] = useState(eventItems);
  const [currentView, setCurrentView] = useState<View>('month');
  const userId = useSelector(getUserId);
  const handleNavigateToEventDetail = (eventType: string, externalId: string, id: string): void => {
    if (eventType === 'Cycle Count') {
      history.push('/cycleCountDetails', {
        cycleCountExternalId: externalId,
      });
    } else {
      history.push('/surgicalDetails', {
        eventType,
        externalId,
        id,
        calendarView: currentView,
        calendarDate: currentDate,
      });
    }
  };
  const getEvents = (
    startDate: Date,
    endDate: Date,
    selectedFilters: Filters | null = null
  ): void => {
    if (activeTab === 'Requested' && !selectedFilterApiValues) {
      getEventsData({
        variables: { startDate, endDate, id: userId, filters: { status: ['Requested', 'Failed'] } },
      });
    } else {
      let filters;
      if (selectedFilters) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { startDate: stDt, endDate: endDt, ...restFilters } = selectedFilters;
        filters = restFilters;
      } else {
        filters = {
          status: ['New', 'Assigned', 'Requested', 'Shipped'],
        };
      }
      getEventsData({
        variables: { startDate, endDate, id: userId, filters },
      });
    }
  };
  useEffect(() => {
    let filterEventItem = eventItems;
    if (!selectedFilterApiValues?.status?.includes('Cancelled')) {
      filterEventItem = eventItems.filter((item: any) => item.eventStatus !== 'Cancelled');
    }
    setEventItems(
      filterEventItem && filterEventItem.length > 0
        ? filterEventItem.map((item: Event) => {
            if (item.eventType === 'Trial' || item.eventSubType === MOCK_OR_EVENT_TYPE) {
              return {
                ...item,
                eventStartDate: new Date(getLocalDateTime(item.eventStartDate)),
                eventEndDate: new Date(getLocalDateTime(item.eventEndDate)),
              };
            }
            if (item.eventType === 'Cycle Count') {
              return {
                ...item,
                eventStartDate: new Date(
                  moment.utc(item.eventStartDate).format('YYYY-MM-DD HH:mm:ss')
                ),
                eventEndDate: addMinutes(
                  new Date(moment.utc(item.eventEndDate).format('YYYY-MM-DD HH:mm:ss')),
                  Number(15)
                ),
                coveringReps: item?.coveringReps ? item.coveringReps?.replace(',', ', ') : '--',
              };
            }
            return {
              ...item,
              eventStartDate: new Date(getLocalDateTime(item.eventStartDate)),
              eventEndDate: item.eventEndDate
                ? new Date(getLocalDateTime(item.eventEndDate))
                : addHours(
                    new Date(getLocalDateTime(item.eventStartDate)),
                    Number(item?.eventDuration ? item?.eventDuration : 1)
                  ),
            };
          })
        : []
    );
  }, [eventItems, selectedFilterApiValues]);

  const onNavigate = (date: Date): void => {
    let view = currentView;
    const selView = localStorage.getItem('calendarView');
    // const userView = JSON.stringify(localStorage.getItem('selectedView'));
    if (selView) {
      view = JSON.parse(selView);
      setCurrentView(view);
    }
    //  else if (userView) {
    //   view = JSON.parse(userView);
    //   setCurrentView(view);
    // }
    if (view === 'month') {
      const start = startOfMonth(new Date(date));
      const end = endOfMonth(new Date(date));
      setCurrentDate(date);
      localStorage.setItem('monthSelected', JSON.stringify(date));
      getEvents(start, end, selectedFilterApiValues);
    } else if (view === 'week') {
      const start = startOfWeek(new Date(date));
      const end = endOfWeek(new Date(date));
      setCurrentDate(date);
      localStorage.setItem('weekSelected', JSON.stringify(date));
      getEvents(start, end, selectedFilterApiValues);
    } else if (view === 'day') {
      const start = startOfDay(new Date(date));
      const end = endOfDay(new Date(date));
      setCurrentDate(date);
      localStorage.setItem('daySelected', JSON.stringify(date));
      getEvents(start, end, selectedFilterApiValues);
    }
  };

  useEffect(() => {
    let calendarDate = currentDate;
    const selDate = localStorage.getItem('calendarDate');
    if (selDate) {
      calendarDate = JSON.parse(selDate);
    }
    onNavigate(calendarDate);
    return (): void => {
      localStorage.setItem('calendarView', '');
      localStorage.setItem('calendarDate', '');
      localStorage.setItem('selectedView', '');
    };
  }, []);

  useEffect(() => {
    if (selectedFilterApiValues?.startDate) {
      const start = new Date(selectedFilterApiValues?.startDate || '');
      const end = new Date(selectedFilterApiValues?.endDate || '');
      setCurrentDate(start);
      getEvents(start, end, selectedFilterApiValues);
    }
  }, [selectedFilterApiValues]);

  const onView = (view: View): void => {
    localStorage.setItem('selectedView', view);
    localStorage.setItem('calendarView', JSON.stringify(view) || '');
    if (view === 'month') {
      const start = startOfMonth(new Date(currentDate));
      const end = endOfMonth(new Date(currentDate));
      setCurrentView(view);
      getEvents(start, end, selectedFilterApiValues);
    } else if (view === 'week') {
      const start = startOfWeek(new Date(currentDate));
      const end = endOfWeek(new Date(currentDate));
      setCurrentView(view);
      getEvents(start, end, selectedFilterApiValues);
    } else if (view === 'day') {
      const start = startOfDay(new Date(currentDate));
      const end = endOfDay(new Date(currentDate));
      setCurrentView(view);
      getEvents(start, end, selectedFilterApiValues);
    }
  };

  return (
    <div className="calender-outer_div ">
      {loading && <Spinner size="large" variant="base" assistiveText={{ label: 'Loading...' }} />}
      <Calendar
        localizer={localizer}
        events={eventItem}
        startAccessor="eventStartDate"
        endAccessor="eventEndDate"
        titleAccessor="eventType"
        view={currentView}
        culture="en-GB"
        date={new Date(currentDate)}
        components={{
          toolbar: CustomToolbarContainer({
            currentView,
          }),
          event: CustomEventContainer({
            handleNavigateToEventDetail,
            currentView,
          }),
        }}
        eventPropGetter={eventStyleGetter}
        onNavigate={onNavigate}
        onView={onView}
        step={15}
        timeslots={1}
      />
    </div>
  );
};

/** Calendar component */
export default withRouter(EventsCalendar);
