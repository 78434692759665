/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Combobox from '@salesforce/design-system-react/components/combobox';
import EventStatusBadge from '../../../Shared/EventStatusBadge';
import './index.scss';
import { RETURNS_TYPE_OPTION, WORKFLOW_ACTION } from '../../../../util/constants';

interface Action {
  workflowAction: string;
  userworkflowAction?: [];
  footerRow?: boolean;
  lineVisualIndicator?: string;
  type: string;
}
export interface Props {
  children?: FC;
  item?: Action;
  viewOnly: boolean;
  handleUserWorkflowAction: (item: any) => void;
}

const InventoryReturnTypeDataCell: FC<Props> = ({
  item = {
    workflowAction: null,
    userworkflowAction: [],
    lineVisualIndicator: '',
  },
  viewOnly,
  handleUserWorkflowAction,
}) => {
  const { workflowAction, footerRow, type } = item;
  const [userAction, setUserAction] = useState(type || []);
  const handleSelectDropdownItem = (data: any): void => {
    setUserAction(data.selection);
    const obj = {
      ...item,
      type: data.selection,
    };
    handleUserWorkflowAction(obj);
  };
  let popover = type;
  if (Array.isArray(type)) {
    popover = type[0].value;
  }
  return footerRow ? (
    <DataTableCell key="-1" title="footer" />
  ) : (
    <DataTableCell key={item} title={popover || ''}>
      {type &&
      workflowAction &&
      workflowAction !== WORKFLOW_ACTION.NO_ACTION &&
      workflowAction !== 'NoAction' ? (
        <p>
          <EventStatusBadge status={type} />
        </p>
      ) : (
        <div>
          <Combobox
            id="combobox-readonly-single"
            menuPosition="overflowBoundaryElement"
            disabled={viewOnly}
            events={{
              onSelect: (_event: any, data: any): void => handleSelectDropdownItem(data),
            }}
            labels={{
              placeholder: 'Select',
            }}
            options={RETURNS_TYPE_OPTION || []}
            selection={userAction}
            variant="readonly"
            // defaultValue={userAction}
          />
        </div>
      )}
    </DataTableCell>
  );
};

export default InventoryReturnTypeDataCell;
