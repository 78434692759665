/**
 * Counter component for showing counter used in Jest testing.
 * @module src/components/App/Header/index
 */
import React, { FC, useState, useCallback, useEffect } from 'react';
import Alert from '@salesforce/design-system-react/components/alert';
import AlertContainer from '@salesforce/design-system-react/components/alert/container';

import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CustomHeader from '../CustomHeader';
import NotificationSettings from '../NotificationSettings';
import { GET_NOTIFICATION_SETTINGS } from '../../../graphql/getNotificationSettings';
import UPDATE_NOTIFICATION_SETTINGS from '../../../graphql/mutations/updateNotificationSettings';
import CREATE_NOTIFICATION_SETTINGS from '../../../graphql/mutations/createNotificationSettings';
import './index.scss';
import {
  GET_WORKFLOW_PERMISSION,
  GET_WORKFLOW_PERMISSIONS,
} from '../../../graphql/workflowPermissions';

interface Settings {
  [key: string]: boolean;
}

const Header: FC = () => {
  useQuery(GET_WORKFLOW_PERMISSIONS);
  const isCustomerPortal = window.location !== window.parent.location;

  const [notificationModalVisible, setNotificationModalVisible] = useState(false);
  const [cookieAlert, setCookieAlert] = useState(true);

  const { data: notificationSettings, refetch: refetchNotifications } = useQuery(
    GET_NOTIFICATION_SETTINGS
  );
  useQuery(GET_WORKFLOW_PERMISSIONS);
  const { data: showSurgeonData } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      action: 'view_surgeon_cases',
    },
  });
  const { data: isCsrUser } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'is_csr_user',
    },
  });
  const { data: isOpsUser } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'is_ops_user',
    },
  });

  const isVisible = localStorage.getItem('cookiePopupVisible') || null;

  useEffect(() => {
    if (!isVisible) {
      localStorage.setItem('cookiePopupVisible', 'true');
    }
  }, [isVisible]);

  const [
    createNotificationSettings,
    { data: createSettingsResponse, loading: createLoading },
  ] = useMutation(CREATE_NOTIFICATION_SETTINGS);

  const [
    updateNotificationSettings,
    { data: updateSettingsResponse, loading: updateLoading },
  ] = useMutation(UPDATE_NOTIFICATION_SETTINGS);

  const notification = notificationSettings?.getNotificationSettings;

  const handleNotificationModal = useCallback((): void => {
    setNotificationModalVisible(!notificationModalVisible);
  }, [notificationModalVisible]);

  useEffect(() => {
    if (
      createSettingsResponse &&
      notificationModalVisible &&
      createSettingsResponse.createNotificationSettings &&
      createSettingsResponse.createNotificationSettings.message === 'success'
    ) {
      handleNotificationModal();
      if (refetchNotifications) {
        refetchNotifications();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSettingsResponse, refetchNotifications]);

  useEffect(() => {
    if (
      updateSettingsResponse &&
      notificationModalVisible &&
      updateSettingsResponse.updateNotificationSettings &&
      updateSettingsResponse.updateNotificationSettings.message === 'success'
    ) {
      handleNotificationModal();
      if (refetchNotifications) {
        refetchNotifications();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchNotifications, updateSettingsResponse]);

  const handleNotificationsSavePress = (settings: Settings): void => {
    if (notification) {
      updateNotificationSettings({
        variables: {
          ...settings,
        },
      });
    } else {
      createNotificationSettings({
        variables: {
          ...settings,
        },
      });
    }
  };

  const loading = createLoading || updateLoading;

  const handleCookieAlert = (): void => {
    setCookieAlert(false);
    localStorage.setItem('cookiePopupVisible', 'false');
  };

  const handleCookieLink = (): void => {
    window.open('https://www.stryker.com/gb/en/legal/cookie-disclaimer.html');
  };

  return (
    <IconSettings iconPath="/icons">
      {cookieAlert && showSurgeonData && isVisible === 'true' && (
        <AlertContainer className="cookies_alert_container">
          <Alert
            dismissible
            className="cookies_alert"
            labels={{
              heading:
                'We use cookies to customise content for your viewing and for analytics. If you continue to browse this website, we will assume that you are happy to receive all our cookies. For further information, please read our ',
              headingLink: 'cookie policy',
            }}
            onRequestClose={handleCookieAlert}
            onClickHeadingLink={handleCookieLink}
          />
        </AlertContainer>
      )}

      <div>
        {!isCustomerPortal && (
          <CustomHeader
            isOpsUser={isOpsUser}
            isCsrUser={isCsrUser}
            handleNotificationModal={handleNotificationModal}
            refetchNotifications={refetchNotifications}
          />
        )}

        <NotificationSettings
          notificationModalVisible={notificationModalVisible}
          handleNotificationModal={handleNotificationModal}
          notification={notification}
          handleNotificationsSavePress={handleNotificationsSavePress}
          loading={loading}
        />
      </div>
    </IconSettings>
  );
};

/** Header component */
export default Header;
