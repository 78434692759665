/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import Spinner from '@salesforce/design-system-react/components/spinner';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { GET_ALL_SALES_REPS_SUBMIT_PERMISSOIN } from '../../../graphql/getAllSalesRepsWithSubmitPermission';
import { GET_ALL_USERA_BY_BU } from '../../../graphql/getAllUsersByBU';
import UPDATE_INVENTORY_SUBMIT_PERMISSION from '../../../graphql/mutations/updateInventorySubmitPermission';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import { NOTIFY_TYPE } from '../../../util/constants';
import Accordion from './Accordian';
import HoldNewRequest from './HoldNewRequest';
import UserHoldFilter from './UserHoldFilter';
import FilterTags from '../../Shared/FilterTags';
import CancelEventModal from '../../CreateEditSurgeryEvent/CancelEventCreationModal';
import DisplayRecordIndicator from '../../Shared/DisplayRecordIndicator';
import {
  getInventoryHoldFilters,
  setInventoryHoldFilters,
} from '../../../store/ducks/inventroyHoldFilters';
import SnackBar from '../../Shared/SnackBar';
import debounced from '../../../util/debounced';
import { getEventIcon } from '../../../util/utilityFunctions';
import { GET_USER_INFO } from '../../../graphql/getUserInfoData';

interface SelectedReason {
  label: any;
  value: any;
}

interface Reason {
  permissionExternalId: string | null;
  permissionsfid: string | null;
  reasonCodeForHold: string;
  isinventoryhold: boolean;
  __typename: string;
}

interface SalesRep {
  id: string;
  value: string;
  reasons: Reason[];
}

interface Data {
  getAllSalesRepsWithSubmitPermission: SalesRep[];
}

interface SalesRepData {
  getAllSalesRepsWithSubmitPermission: SalesRep[];
}

interface SelectedData {
  id: string;
  label: string;
  __typename?: string;
}

interface Filters {
  user?: string[];
  type?: string[];
  reason?: string;
  searchText?: string;
}

interface Filter {
  user: SelectedData[];
  type: SelectedData[];
  reason?: SelectedData[];
  searchText?: string;
}

interface FilterPreset {
  filterValues: Filter;
  filterExternalId?: string;
  filterName?: string;
}

interface DynamicValue {
  [key: string]: any;
}

const initialRepsData: SalesRepData = {
  getAllSalesRepsWithSubmitPermission: [
    // initial data here
  ],
};

const RepOnHold: FC = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const [getAllPermission, { data: salesRepsData, loading: loadingRepHold }] = useLazyQuery(
    GET_ALL_SALES_REPS_SUBMIT_PERMISSOIN,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getuserInfo, { data: userInfo }] = useLazyQuery(GET_USER_INFO, {
    fetchPolicy: 'no-cache',
  });

  const { data: salesRepsItems } = useQuery(GET_ALL_USERA_BY_BU);
  // const { data: salesRepsData } = useQuery(GET_ALL_SALES_REPS_SUBMIT_PERMISSOIN);
  const [reps, setReps] = useState<SalesRepData>(initialRepsData);
  const [isOpen, setisOpen] = useState(false);
  const [canceModalVisible, setCanceModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState<FilterPreset | DynamicValue | null>(null);
  const [searchText, setSearchText] = useState('');
  const { open, notification, openSnackBar } = useSnackBar();

  useEffect(() => {
    if (salesRepsData) {
      setReps(salesRepsData);
      const updatedBy = salesRepsData?.getAllSalesRepsWithSubmitPermission[0]?.lastUpdatedBy;
      if (updatedBy !== 'System') {
        getuserInfo({
          variables: {
            id: updatedBy,
          },
        });
      }
    }
  }, [salesRepsData, getuserInfo]);

  const handleToggleFilter = (): void => {
    setOpenFilter(!openFilter);
  };

  const showModal = (): void => {
    setisOpen(!isOpen);
  };

  const getFilterApiValue = (filterValues: DynamicValue): Filters => {
    let reducedFilterValues = {};

    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          const values = filterValues[key].map((item: SelectedData) =>
            key === 'type' ? item.label : item.id
          );
          if (values && values.length > 0) {
            const filterReason = values[0];
            if (key === 'type') {
              const replacementIndex = values.indexOf('Sunshine Act Hold');
              if (replacementIndex !== -1) {
                values[replacementIndex] = 'Regulatory Noncompliance';
              }
            }
            if (key === 'reason') {
              reducedFilterValues = { ...reducedFilterValues, [key]: filterReason };
            } else {
              reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
            }
          }
        } else if (Array.isArray(filterValues[key]) && filterValues[key]) {
          if (filterValues[key].length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  const handleRefetchItems = useCallback(
    (filterData: Filters | null): void => {
      const dataFilter = filterData;
      getAllPermission({
        variables: {
          filters: dataFilter,
        },
      });
    },
    [getAllPermission]
  );

  const handleSelectSearch = (searchedItem: string): void => {
    const filterValuesObj = { searchText: searchedItem };
    setSearchText(searchedItem);

    const filters = getFilterApiValue(selectedFilter?.filterValues);
    const selectedFilterValues = {
      ...filters,
      ...filterValuesObj,
    };
    handleRefetchItems(selectedFilterValues);
  };

  const debouncedFetchReturnItems = useCallback(debounced(500, handleSelectSearch), [
    debounced,
    handleSelectSearch,
  ]);

  const handleSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      if (value && value.length >= 3) {
        debouncedFetchReturnItems(value);
      } else {
        debouncedFetchReturnItems(value);
      }
    },
    [debouncedFetchReturnItems]
  );

  const headerActions = (): ReactNode => (
    <>
      <PageHeaderControl className="slds-m-left_large">
        <ButtonGroup variant="list" id="button-group-page-header-actions">
          <Button
            iconCategory="utility"
            className="slds-text-color_default"
            label="New"
            iconName="add"
            iconPosition="left"
            onClick={showModal}
          />
        </ButtonGroup>
      </PageHeaderControl>
    </>
  );

  const headerControls = (): ReactNode => {
    let updatedBy =
      salesRepsData && salesRepsData?.getAllSalesRepsWithSubmitPermission[0]?.lastUpdatedBy;
    const updatedDate =
      salesRepsData && salesRepsData?.getAllSalesRepsWithSubmitPermission[0]?.lastUpdatedDate;
    if (updatedBy !== 'System') {
      updatedBy =
        userInfo && `${userInfo.getUserInfo[0]?.firstName} ${userInfo.getUserInfo[0]?.lastName}`;
    }
    const obj = { updatedBy, updatedDate };
    return (
      <>
        <PageHeaderControl>
          <div className="slds-form-element__control" style={{ width: '300px' }}>
            {/* <AutocompleteSingleSelectPicker
              items={filteredRepStockAccount}
              setSelectedItem={(result: any): void => {
                handleSelectSearch(result);
              }}
              disabled={false}
              selectedItem={[]}
              placeholder="Search By User"
              label=""
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="account"
              autoSelectValue={false}
            /> */}

            <Input
              iconRight={
                <InputIcon
                  assistiveText={{
                    icon: 'Search',
                  }}
                  name="search"
                  category="utility"
                />
              }
              id="search-by-product"
              onChange={(_event: any, searchItem: string): void => handleSearch(_event, searchItem)}
              placeholder="Search by User"
            />
          </div>
        </PageHeaderControl>
        <PageHeaderControl className="slds-align-middle">
          <Button
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="filterList"
            label="Filter"
            iconPosition="left"
            onClick={handleToggleFilter}
          />
        </PageHeaderControl>
        <PageHeaderControl className="slds-align-middle">
          <DisplayRecordIndicator recordInfo={obj} screen="inventory" />
        </PageHeaderControl>
      </>
    );
  };

  const onSucessUpdate = (): void => {
    let filters = getFilterApiValue(selectedFilter?.filterValues);
    if (searchText) {
      const filterValuesObj = { searchText };
      filters = {
        ...filters,
        ...filterValuesObj,
      };
    }
    handleRefetchItems(filters);
  };

  const [updateSubmitPermission] = useMutation(UPDATE_INVENTORY_SUBMIT_PERMISSION);
  const handlePermissionOnHold = (
    itemId: string,
    id: string,
    isOnHold: boolean,
    reason: string,
    reasonCodeForHold: string
  ): void => {
    const updatedReps = reps?.getAllSalesRepsWithSubmitPermission.map(item => {
      if (item.id === itemId) {
        const updatedReasons = item.reasons.map(reasonList => {
          if (reasonList.permissionExternalId === id) {
            return {
              ...reasonList,
              isinventoryhold: isOnHold,
            };
          }
          return reasonList;
        });

        return {
          ...item,
          reasons: updatedReasons,
        };
      }
      return item;
    });
    setReps(prevState => ({
      ...prevState,
      getAllSalesRepsWithSubmitPermission: updatedReps,
    }));

    const apireasonCodeForHold =
      reasonCodeForHold === 'Sunshine Act Hold' ? 'Regulatory Noncompliance' : reasonCodeForHold;

    updateSubmitPermission({
      variables: {
        userId: itemId,
        isInventoryHold: isOnHold,
        reasonCodeForHold: apireasonCodeForHold,
        reason,
        permissionExternalID: id,
      },
    })
      .then(() => {
        onSucessUpdate();
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'The changes have been applied.');
      })
      .catch(() => {
        openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
      });
  };

  useEffect(() => {
    getAllPermission();
  }, [getAllPermission]);

  const filterStore = useSelector(getInventoryHoldFilters);

  useEffect(() => {
    const setFilters = JSON.parse(filterStore || '{}');
    if (setFilters && setFilters.filterValues) {
      setSelectedFilter(setFilters);
      const filters = getFilterApiValue(setFilters.filterValues);
      handleRefetchItems(filters);
    }
  }, [filterStore, handleRefetchItems]);

  const handleFilterRequest = (
    filterValues: Filter | DynamicValue | null,
    filterName?: string
  ): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setSelectedFilter(filter);
    if (filterValues) {
      let filters = getFilterApiValue(filterValues);
      if (searchText) {
        const filterValuesObj = { searchText };
        filters = {
          ...filters,
          ...filterValuesObj,
        };
      }
      handleRefetchItems(filters);
      dispatch(setInventoryHoldFilters(JSON.stringify(filter)));
    } else {
      handleRefetchItems(null);
    }
  };

  const handleCancelButton = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleCancelModalConfirmPress = (): void => {
    setCanceModalVisible(!canceModalVisible);
    showModal();
  };
  const handleCancelModal = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const filteredRepStockAccount = salesRepsItems?.getAllUsersByBU || [];
  const iconPath = getEventIcon('Inventory');
  return (
    <>
      <IconSettings iconPath="/icons">
        <SnackBar open={open} notification={notification} />
        <PageHeader
          style={{ maxHeight: '100px' }}
          className="events_header"
          icon={<img src={iconPath} className="logo-image" alt="orders" />}
          onRenderActions={headerActions}
          onRenderControls={(): ReactNode => headerControls()}
          title="User Holds"
          truncate
          variant="object-home"
        />
        {selectedFilter && selectedFilter.filterValues && (
          <FilterTags selectedFilter={selectedFilter} handleFilter={handleFilterRequest} />
        )}
        {/* <InventoryFilter openFilter={openFilter} handleToggleFilter={handleToggleFilter} /> */}
        {loadingRepHold && (
          <Spinner
            size="large"
            variant="base"
            assistiveText={{ label: 'Loading...' }}
            key="spinner"
          />
        )}
        {reps && reps?.getAllSalesRepsWithSubmitPermission.length > 0 ? (
          <div>
            <Accordion
              gridData={reps?.getAllSalesRepsWithSubmitPermission}
              handlePermissionOnHold={handlePermissionOnHold}
            />
          </div>
        ) : (
          <div className="no-data-found-container">
            <h1 className="no-data-found-text">No Records Found</h1>
          </div>
        )}
        <HoldNewRequest
          isEdit={false}
          isOpen={isOpen}
          toggleOpen={showModal}
          formData={[]}
          handleCancelButton={handleCancelButton}
          onSucessUpdate={onSucessUpdate}
        />
        <UserHoldFilter
          handleToggleFilterInventory={handleToggleFilter}
          filterInventoryModalVisible={openFilter}
          selectedFilter={selectedFilter}
          handleFilterHold={handleFilterRequest}
          isEditFilter={false}
          userView=""
          ItemsData={reps}
          filteredUserList={filteredRepStockAccount}
        />
        {canceModalVisible && (
          <CancelEventModal
            isInventory
            canceModalVisible={canceModalVisible}
            handleCancelModal={handleCancelModal}
            handleCancelModalConfirmPress={handleCancelModalConfirmPress}
          />
        )}
      </IconSettings>
    </>
  );
};

export default RepOnHold;
