/**
 * Graphql mutation to create Notification settings
 * @module src/graphql/createOrDeleteFavourites
 */
import gql from 'graphql-tag';

const CREATE_UPDATE_FAVOURITE_CONTACT = gql`
  mutation createOrDeleteFavourites(
    $salesRepId: String
    $favourites: [FavouriteEmail]
    $accountId: String
  ) {
    createOrDeleteFavourites(
      salesRepId: $salesRepId
      favourites: $favourites
      accountId: $accountId
    ) {
      message
      __typename
    }
  }
`;

export default CREATE_UPDATE_FAVOURITE_CONTACT;
