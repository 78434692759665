/**
 * Redux file for getting/setting notificationFilters
 * @module src/store/ducks/notificationFilters
 */
/* eslint-disable import/no-cycle */
import { ActionType, State } from '.';

// action
const SET_NOTIFICATION_FILTERS = 'SET_NOTIFICATION_FILTERS';
const CLEAR_NOTIFICATION_FILTERS = 'CLEAR_NOTIFICATION_FILTERS';

interface SetNotificationFiltersActionType {
  payload: string | null;
  type: typeof SET_NOTIFICATION_FILTERS;
}

interface ClearNotificationFiltersActionType {
  type: typeof CLEAR_NOTIFICATION_FILTERS;
}

/** notificationFilters action type */

export type NotificationFilterActionType =
  | SetNotificationFiltersActionType
  | ClearNotificationFiltersActionType;

/** set notificationFilters action creator */

export const setNotificationFilters = (state: string | null): NotificationFilterActionType => {
  return {
    payload: state,
    type: SET_NOTIFICATION_FILTERS,
  };
};

/** clear notificationFilters action creator */

export const clearNotificationFilters = (): ClearNotificationFiltersActionType => {
  return {
    type: CLEAR_NOTIFICATION_FILTERS,
  };
};

/** notificationFilters state type */

export type NotificationFilterState = string | null;

/** notificationFilters reducer */

export default (
  state: NotificationFilterState = null,
  action: ActionType
): NotificationFilterState => {
  switch (action.type) {
    case SET_NOTIFICATION_FILTERS: {
      return action.payload;
    }
    case CLEAR_NOTIFICATION_FILTERS: {
      return null;
    }
    default:
      return state;
  }
};

/** selector to get accessToken */

export const getNotificationFilters = (state: State): NotificationFilterState => {
  return state.notificationFilters;
};
