/**
 * Module for Custom Account/Event Name Cell.
 * @module src/Events/EventsList
 */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import './index.scss';
//  Interface for Data table data type for all the columns
export interface Props {
  children?: FC;
  item?: AccountEventName;
}

interface AccountEventName {
  accountName?: string;
  eventName?: string;
  territory?: string;
  eventType?: string;
}
const EventsListAccountEventNameCell: FC<Props> = ({
  item = {
    accountName: '',
    eventName: '',
    territory: '',
    eventType: '',
  },
}) => {
  const { accountName, eventName, territory, eventType } = item;
  return (
    <DataTableCell title={accountName || eventName || ''}>
      {eventType === 'Cycle Count' && <p className="wrap-text font-12 font-600">{territory}</p>}
      {!eventName ? (
        <p className="wrap-text font-12 font-600">{accountName}</p>
      ) : (
        <div>
          <p className="wrap-text font-12 font-600">{eventName}</p>
          <p className="wrap-text">{accountName}</p>
        </div>
      )}
    </DataTableCell>
  );
};

/** Custom Account/Event Name cell */
export default EventsListAccountEventNameCell;
