/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * React component for DisplayRecordIndicator.
 * @module src/components/shared/Checkbox
 */
import React, { FC, useState } from 'react';
import './index.scss';
import moment from 'moment';
import ClickOutside from '../../Events/EventsHeader/clickOutside';

interface Props {
  recordInfo?: any;
  screen?: any;
}

const DisplayRecordIndicator: FC<Props> = ({ recordInfo, screen }) => {
  const [setOpen, setIsOpen] = useState(false);
  const handleChange = (): void => {
    setIsOpen(!setOpen);
  };

  const getTimezoneName = (formattedDate: any) => {
    const currentDate = new Date(formattedDate);
    const short = currentDate.toLocaleDateString(undefined);
    const full = currentDate.toLocaleDateString(undefined, { timeZoneName: 'long' });
    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
    }
    return full;
  };

  const getTimeStamp = (formattedDate: any) => {
    const currentDateTime = formattedDate ? new Date(formattedDate) : new Date();
    const hour =
      currentDateTime.getHours() === 0
        ? 12
        : currentDateTime.getHours() > 12
        ? currentDateTime.getHours() - 12
        : currentDateTime.getHours();
    const min =
      currentDateTime.getMinutes() < 10
        ? `0${currentDateTime.getMinutes()}`
        : currentDateTime.getMinutes();
    const ampm = currentDateTime.getHours() < 12 ? 'AM' : 'PM';
    const time = `${hour}:${min} ${ampm}`;

    return time;
  };

  return (
    <div className="display-icon">
      {setOpen && (
        <ClickOutside onClick={handleChange}>
          {screen === 'config' || screen === 'settings' || screen === 'inventory' ? (
            <span className={`container-div-${screen}`}>
              <div className="popup-cols text">
                <p>Last Updated:</p>
                <p>
                  <span className="userName">{recordInfo?.updatedBy}</span>
                  <br />
                  {recordInfo?.updatedDate || recordInfo?.updateDate ? (
                    <span className="timeStamp">
                      {moment(recordInfo?.updatedDate || recordInfo?.updateDate).format(
                        'MM/DD/YYYY'
                      )}
                      {`${' '}`}
                      {getTimeStamp(recordInfo?.updatedDate || recordInfo?.updateDate)}
                      {`${' '}`}
                      {getTimezoneName(new Date())
                        .match(/\b([A-Z])/g)
                        ?.join('')}
                    </span>
                  ) : (
                    ''
                  )}
                </p>
              </div>
            </span>
          ) : (
            <span className="container-div">
              <p className="popup-cols text">
                <span>Created:</span>
                <span>
                  <span className="userName">{recordInfo?.createdBy}</span>
                  <br />
                  {recordInfo?.createdDate ? (
                    <span className="timeStamp">
                      {moment(recordInfo?.createdDate).format('MM/DD/YYYY')}
                      {`${' '}`}
                      {getTimeStamp(recordInfo?.createdDate)}
                      {`${' '}`}
                      {getTimezoneName(new Date())
                        .match(/\b([A-Z])/g)
                        ?.join('')}
                    </span>
                  ) : (
                    <span className="timeStamp">
                      {moment().format('MM/DD/YYYY')}
                      {`${' '}`}
                      {getTimeStamp(recordInfo?.createdDate)}
                      {`${' '}`}
                      {getTimezoneName(new Date())
                        .match(/\b([A-Z])/g)
                        ?.join('')}
                    </span>
                  )}
                </span>
              </p>
              <p className="popup-cols text">
                <span>Last Updated:</span>
                <span>
                  <span className="userName">{recordInfo?.updatedBy}</span>
                  <br />
                  {recordInfo?.updatedDate || recordInfo?.updateDate ? (
                    <span className="timeStamp">
                      {moment(recordInfo?.updatedDate || recordInfo?.updateDate).format(
                        'MM/DD/YYYY'
                      )}
                      {`${' '}`}
                      {getTimeStamp(recordInfo?.updatedDate || recordInfo?.updateDate)}
                      {`${' '}`}
                      {getTimezoneName(new Date())
                        .match(/\b([A-Z])/g)
                        ?.join('')}
                    </span>
                  ) : !recordInfo?.createdDate ? (
                    <span className="timeStamp">
                      {moment().format('MM/DD/YYYY')}
                      {`${' '}`}
                      {getTimeStamp(null)}
                      {`${' '}`}
                      {getTimezoneName(new Date())
                        .match(/\b([A-Z])/g)
                        ?.join('')}
                    </span>
                  ) : (
                    ''
                  )}
                </span>
              </p>
            </span>
          )}
        </ClickOutside>
      )}
      <span id="calendar-icon" onClick={handleChange}>
        <img src="/icons/calendar.svg" style={{ marginLeft: '2px' }} />
      </span>
    </div>
  );
};

/** React component for DisplayRecordIndicator. */
export default DisplayRecordIndicator;
