import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NOTIFY_TYPE } from '../constants';
import { getSnackBar, openSnackBar as osb, closeSnackBar as csb } from '../../store/ducks/snackBar';

export interface Notification {
  type: string;
  message: string;
}

interface SnackBar {
  open: boolean;
  notification: Notification;
  openSnackBar: (type: string, message: string, isSticky?: boolean) => void;
}

const useSnackBar = (bubbleUp = false): SnackBar => {
  const [open, setOpen] = useState(false);
  const [notification, setNotfication] = useState<Notification>({
    type: NOTIFY_TYPE.ERROR,
    message: 'Something went wrong. Please try again...',
  });

  const snackBarState = useSelector(getSnackBar);
  const dispatch = useDispatch();

  const openSnackBar = useCallback((type: string, message: string, isSticky?: boolean): void => {
    if (bubbleUp) {
      if (message.indexOf('GraphQL error') > -1) {
        // eslint-disable-next-line no-param-reassign
        message = message.replace('GraphQL error:', '');
      }
      dispatch(osb(type, message));
      setTimeout(() => {
        dispatch(csb());
      }, 5000);
    } else {
      setOpen(true);
      if (message.indexOf('GraphQL error') > -1) {
        // eslint-disable-next-line no-param-reassign
        message = message.replace('GraphQL error:', '');
        setNotfication({ type, message });
      } else {
        setNotfication({ type, message });
      }
      if (!isSticky) {
        setTimeout(() => {
          setOpen(false);
        }, 5000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    open: bubbleUp ? snackBarState?.showSnack ?? false : open,
    notification: bubbleUp ? snackBarState?.notification ?? notification : notification,
    openSnackBar,
  };
};

export default useSnackBar;
