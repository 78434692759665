/**
 * Graphql mutation to create Notification settings
 * @module src/graphql/createUpdateUserNotificationConfig
 */
import gql from 'graphql-tag';

const CREATE_UPDATE_USER_NOTIFICATION_CONFIG = gql`
  mutation createUpdateUserNotificationConfig(
    $userId: String
    $notificationSettings: [UserNotificationSettings]
  ) {
    createUpdateUserNotificationConfig(
      userId: $userId
      notificationSettings: $notificationSettings
    ) {
      message
    }
  }
`;

export default CREATE_UPDATE_USER_NOTIFICATION_CONFIG;
