/* eslint-disable */
/**
 * Module for inventory return filter.
 * @module src/components/inventory/invntoryReturns/inventoryReturnFilter
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Modal from '@salesforce/design-system-react/components/modal';
import Pill from '@salesforce/design-system-react/components/pill';
import Icon from '@salesforce/design-system-react/components/icon';
import Button from '@salesforce/design-system-react/components/button';
import Combobox from '@salesforce/design-system-react/components/combobox';
import { FILTER_TRANSFER_STATUS, FILTER_TRANSFER_TYPE } from '../../../../util/constants';
import DatePicker from '../../../Shared/DatePicker';
import './index.scss';
import { GET_PRODUCT_GROUP } from '../../../../graphql/getProductGroups';
import { GET_ALL_SALES_REPS } from '../../../../graphql/getAllSalesReps';
import { GET_FILTERED_ACCOUNTS } from '../../../../graphql/getFilteredAccounts';
import { clearInventoryTransferFilters } from '../../../../store/ducks/inventoryTransferFilters';
import { getUserId } from '../../../../store/ducks/userId';
import AutocompleteSingleSelectPickerWithSearch from '../../../Shared/AutocompleteSingleSelectPickerWithSearch';
import { GET_PRIMARY_ACCOUNT } from '../../../../graphql/getPrimaryAccount';
import AutocompleteSingleSelectPicker from '../../../Shared/AutocompleteSingleSelectPicker';
import { NonSalesRep } from '../../../../util/constants';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import Spinner from '@salesforce/design-system-react/components/spinner';
import { GET_SALES_REPS } from '../../../../graphql/getSalesReps';

interface SelectedData {
  id: string;
  label: string;
}
interface DynamicType {
  [key: string]: any;
}
interface FilterPreset {
  filterValues: SelectedFilters;
  filterExternalId?: string;
  filterName?: string;
}

interface SelectedFilters {
  status: SelectedData[];
  submitToDate: Date | string;
  submitFromDate: Date | string;
  requestedToDate: Date | string;
  requestedFromDate: Date | string;
  search: string;
  productGroup: any;
  salesRep: any;
}
interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  isRequest: boolean;
  handleFilterRequest?: (filter: DynamicType | null) => void;
  selectedFilter?: FilterPreset | DynamicType | null;
  currentTab?: number;
}
enum DetailTypes {
  salesRep = "SalesRep"
}

const InventoryReturnFilter: FC<Props> = ({
  isOpen,
  toggleOpen,
  currentTab,
  handleFilterRequest,
  selectedFilter,
}) => {
  const filterValues = selectedFilter?.filterValues;
  const [open, setOpen] = useState(false);
  const [openType, setOpenType] = useState(false);
  const [openReceive, setOpenReceive] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<DynamicType | null>(filterValues || {}); // For Selected Value
  const [productGroup, { data: productGroupItems }] = useLazyQuery(GET_PRODUCT_GROUP);
  const productGroupList = productGroupItems?.getProductGroups;
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const userInfo = useSelector(getUserInfo);
  const [accountOptions, setAccountOptions] = useState<any>();


  const getDate = useCallback((date: any): string => {
    const formattedSelectedDate = new Date(date);
    const finalDate = format(new Date(formattedSelectedDate), 'yyyy-MM-dd');
    return finalDate;
  }, []);
  const { data: filteredAccounts, loading: loadingAccounts } = useQuery(GET_FILTERED_ACCOUNTS, {
    variables: {
      userId,
    },
  });
  const [getPrimaryAccountItems, { data: primaryAccountItems, loading: loadingPrimaryAccounts }] = useLazyQuery(
    GET_PRIMARY_ACCOUNT,
    {
      fetchPolicy: 'no-cache',
    }
  ); // USELAZYQUERY
  const accountItemsData = primaryAccountItems?.getPrimaryAccount;

  //  const [getSalesRepsItems, { data: getAllSalesReps, loading: loadingSalesRep }] = useLazyQuery(
  //   GET_ALL_SALES_REPS
  // );
  const [getSalesRepsItems, { data: salesRepsItems, loading: loadingSalesRep }] = useLazyQuery(
    GET_SALES_REPS
  );
  const fetchItems = useCallback(() => {
    getSalesRepsItems();
  }, [getSalesRepsItems]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  useEffect(() => {
    productGroup();
    getPrimaryAccountItems({
      variables: {
        getAll: true,
      }
    })
  }, [productGroup, getPrimaryAccountItems]);

  useEffect(() => {
    if (productGroupList && selectedFilters?.productGroup) {
      const record =
        productGroupList &&
        productGroupList.filter((rec: any) => rec.id === selectedFilters?.productGroup);
      const optionsWithLabel1 =
        record &&
        record.map((elem: any) => {
          // eslint-disable-next-line no-underscore-dangle
          return { ...elem, label: `${elem.id}` };
        });
      setFilterValue('productGroup', optionsWithLabel1);
    }
  }, [productGroupList]);

  const handleChangeDate = (selectedDate: any, name: string): void => {
    if (selectedDate) {
      const utcDate = getDate(selectedDate);
      setFilterValue(name, utcDate);
    }
  };

  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any): void => {
      // For selected filter value
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };

      setSelectedFilters(selectedFilterValues);
    },
    [selectedFilters]
  );

  const handleSelect = useCallback((data: any, name: string) => {
    const { selection } = data;
    if (name === 'accountId' || name === 'recevingAccount' || name === 'salesRep' || name === 'productGroup' && data) {
      setFilterValue(name, data);
    } else {
      setFilterValue(name, selection);
    }
  },[setFilterValue]);

  const handleApplyFilter = (): void => {
    toggleOpen();
    const filters = {
      ...selectedFilters,
      // recevingAccount: (selectedFilters as any)?.recevingAccount?.[0].label,
      productGroup: (selectedFilters as any)?.productGroup?.[0].id,
    };
    if (handleFilterRequest) {
      handleFilterRequest(filters);
    }
  };

  const resetFilters = (): void => {
    toggleOpen();
    setSelectedFilters(null);
    dispatch(clearInventoryTransferFilters());
    if (handleFilterRequest) {
      handleFilterRequest(null);
    }
  };

  const optionsWithLabel =
    productGroupList &&
    productGroupList.map((elem: any) => {
      // eslint-disable-next-line no-underscore-dangle
      return { ...elem, label: `${elem.value}` };
    });
  // eslint-disable-next-line no-underscore-dangle
  const optionsAccountsWithLabel = (value: any): object =>
    value &&
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      if (['OPSList'].indexOf(elem.__typename as any) >= 0) {
        return { ...elem, label: `${elem.value}`, id: `${elem.value}` };
      }
    });
  const isUserOps = NonSalesRep?.find((rec: any) => rec?.value === userInfo?.personas) ? true : false;

    const optionsWithLabelAccount = (arr: []): any[] =>
      arr &&
      arr.map((elem: any) => {
        return { ...elem, label: elem?.isRepStockAccount === true && !isUserOps ? ` * ${elem.number} ${elem.value}` : ` ${elem.number} ${elem.value}` };
      });
  
    useEffect(() => {
        if (primaryAccountItems?.getPrimaryAccount) {
          setAccountOptions(
            optionsWithLabelAccount(primaryAccountItems?.getPrimaryAccount)
          );
        }
  }, [ primaryAccountItems]);
  

    const getFiltersObject = (item: any) => {
      if(!Array.isArray(item)) {
        return [{id: item, value: item, label: item}];
      }
      return item;
    }  
  const FILTERDATA = FILTER_TRANSFER_STATUS[currentTab ? currentTab : 0];
  // const accounts = optionsAccountsWithLabel(filteredAccounts?.getAccounts);
  const optionsWithSalesRepLabel = (value: []): any[] =>
    value &&
    // eslint-disable-next-line consistent-return
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === DetailTypes.salesRep) {
        return { ...elem, label: `${elem.value}` };
      } else {
        return { ...elem, label: `${elem.value}` };
      }
    });
  const salesRepsDataOptions = optionsWithSalesRepLabel(salesRepsItems?.getSalesReps);

  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={toggleOpen}
        dismissOnClickOutside={false}
        footer={[
          <Button
            className="slds-float_left slds-text-color_default"
            label="Cancel"
            key="Save"
            onClick={toggleOpen}
          />,
          <Button label="Reset Filters" key="Reset" onClick={resetFilters} />,
          <Button
            className="yellow-btn"
            label="Apply"
            onClick={handleApplyFilter}
            disabled={
              (selectedFilters?.submitToDate &&
                selectedFilters?.submitFromDate &&
                selectedFilters.submitToDate < selectedFilters.submitFromDate) ||
              (selectedFilters?.requestedToDate &&
                selectedFilters?.requestedFromDate &&
                selectedFilters.requestedToDate < selectedFilters.requestedFromDate)
            }
            variant="brand"
            key="Apply"
          />,
        ]}
        heading={[
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div className="slds-float_left slds-text-title_bold filter_heading" key="filterName">
            Filters
          </div>,
        ]}
      >
        {loadingPrimaryAccounts && <Spinner />}

        <section className="slds-p-around_medium slds-theme_shade">
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--small">Attributes</h1>
            <p className="selectbox_label">Status</p>
            <Combobox
              id="status"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpen(true);
                  setOpenType(false);
                },
                onRequestClose: (event: any): void => {
                  setOpen(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'status'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'status'),
              }}
              labels={{
                //   label: `Status`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              isOpen={open}
              multiple
              placeholder="Select"
              options={FILTERDATA || []}
              selection={selectedFilters ? selectedFilters.status || [] : []}
              variant="readonly"
            />
            <p className="selectbox_label">Transfer Type</p>
            <Combobox
              id="transferType"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpenType(true);
                },
                onRequestClose: (event: any): void => {
                  setOpenType(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'transferType'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'transferType'),
              }}
              labels={{
                //   label: `Status`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              isOpen={openType}
              placeholder="Select"
              options={FILTER_TRANSFER_TYPE || []}
              selection={selectedFilters ? selectedFilters.transferType || [] : []}
              variant="readonly"
            />
            <p className="selectbox_label">Receiving Account</p>
            {/* <Combobox
              id="recevingAccount"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpenReceive(true);
                  setOpenType(false);
                },
                onRequestClose: (event: any): void => {
                  setOpenReceive(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'recevingAccount'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'recevingAccount'),
              }}
              labels={{
                //   label: `Receiving Account`,
                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              isOpen={openReceive}
              placeholder="Select"
              options={salesRepsDataOptions || []}
              selection={selectedFilters ? selectedFilters.recevingAccount || [] : []}
              variant="readonly"
            /> */}

            <AutocompleteSingleSelectPicker
              items={accountOptions}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'recevingAccount');
              }}
              disabled={false}
              selectedItem={selectedFilters ? selectedFilters.recevingAccount || [] : []}
              placeholder="Select an Option"
              label="Receiving Account"
              withAddMenuButton={false}
              fetchItems={(searchText: string): any => {
                getPrimaryAccountItems({
                  variables: {
                    searchText: searchText,
                    getAll: true
                  },
                });
              }}
              filter
              name="recevingAccount"
              autoSelectValue={false}
            />
            {/* <p className="selectbox_label">Product Group</p> */}
            {/* <Combobox
              id="productGroup"
              events={{
                onRequestOpen: (event: any): void => {
                  setOpenProduct(true);
                  setOpenReceive(false);
                  setOpenType(false);
                },
                onRequestClose: (event: any): void => {
                  setOpenProduct(false);
                },
                onSelect: (event: any, data: any): void => handleSelect(data, 'productGroup'),
                onRequestRemoveSelectedOption: (event: any, data: any): void =>
                  handleSelect(data, 'productGroup'),
              }}
              labels={{
                //   label: `Product Group`,

                placeholder: 'Select',
                noOptionsFound: 'No data found',
              }}
              isOpen={openProduct}
              placeholder="Select"
              options={optionsWithLabel || []}
              selection={selectedFilters ? selectedFilters.productGroup || [] : []}
              variant="readonly"
            /> */}
            <AutocompleteSingleSelectPicker
              items={optionsWithLabel}
              setSelectedItem={(result: any): void => {
                handleSelect(result, 'productGroup');
              }}
              disabled={false}
              selectedItem={selectedFilters && selectedFilters?.productGroup ? getFiltersObject(selectedFilters?.productGroup) || [] : []}
              placeholder="Select Product Group"
              label="Product Group"
              labelpage="Product Group transfer"
              withAddMenuButton={false}
              fetchItems={(searchText: string): string => searchText}
              filter
              name="productGroup"
              autoSelectValue={false}
            />
            <div className="slds-form-element__control">
              <AutocompleteSingleSelectPicker
                items={salesRepsDataOptions}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'salesRep');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.salesRep || [] : []}
                placeholder="Sales Rep"
                label="Sales Rep"
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="salesRep"
                autoSelectValue={false}
              />
            </div>
          </div>
          <div className="slds-card slds-p-around--medium">
            <h1 className="slds-m-bottom--small">DATE</h1>
            <div className="date-error">
              {(selectedFilters?.submitToDate &&
                selectedFilters?.submitFromDate &&
                selectedFilters.submitToDate < selectedFilters.submitFromDate) ||
                (selectedFilters?.requestedToDate &&
                  selectedFilters?.requestedFromDate &&
                  selectedFilters.requestedToDate < selectedFilters.requestedFromDate && (
                    <Pill
                      labels={{
                        label: 'Error: To Date must be greater than or equal to From Date',
                      }}
                      hasError
                      icon={
                        <Icon
                          title="Error"
                          category="utility"
                          name="warning"
                          className="slds-icon-text-error"
                        />
                      }
                    />
                  ))}
            </div>
            {
              <>
                <div className="filter_date_container">
                  <p className="selectbox_label">Requested Date</p>
                  <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                    <div className="slds-col slds-align_absolute-center">
                      <DatePicker
                        label=""
                        placeholder="From"
                        handleChangeDate={(data: any): void =>
                          handleChangeDate(data, 'requestedFromDate')
                        }
                        disabled={false}
                        isFilterDate
                        value={selectedFilters ? selectedFilters.requestedFromDate : null}
                      />
                    </div>
                    <div className="slds-col slds-align_absolute-center">
                      <span>-</span>
                    </div>
                    <div className="slds-col slds-align_absolute-center">
                      <DatePicker
                        label=""
                        placeholder="To"
                        handleChangeDate={(data: any): void =>
                          handleChangeDate(data, 'requestedToDate')
                        }
                        disabled={false}
                        isFilterDate
                        value={selectedFilters ? selectedFilters.requestedToDate : null}
                      />
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </section>
      </Modal>
    </IconSettings>
  );
};

/** Custom component for Inventory Return Filter */
export default InventoryReturnFilter;
