import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import moment from 'moment';

interface DateTime {
  needByDate: Date;
  createdDate: string;
  submittedDate: string;
}
export interface Props {
  item?: DateTime;
  dateFor?: string;
}

const InventoryRequestDateTImeCell: FC<Props> = ({
  item = {
    needByDate: null,
    createdDate: null,
    submittedDate: null,
  },
  dateFor,
}) => {
  const { needByDate, createdDate, submittedDate } = item;

  let date = '';
  if (dateFor === 'created') {
    date = createdDate ? moment(createdDate).format('MM/DD/YYYY') : '';
  } else if (dateFor === 'submitted') {
    date = submittedDate ? moment(submittedDate).format('MM/DD/YYYY') : '';
  } else {
    date = needByDate
      ? moment(needByDate)
          .utc()
          .format('MM/DD/YYYY')
      : '';
  }

  return (
    <DataTableCell title={date}>
      <div>
        <p className="wrap-text font-12 font-600">{date}</p>
      </div>
    </DataTableCell>
  );
};

/** Custom Date/Time cell */
export default InventoryRequestDateTImeCell;
