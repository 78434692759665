/**
 * Graphql mutation to create Notification settings
 * @module src/graphql/createNotificationSettings
 */
import gql from 'graphql-tag';

/** create Notification settings  */
const CREATE_NOTIFICATION_SETTINGS = gql`
  mutation createNotificationSettings(
    $eventInvite: Boolean
    $eventUpdate: Boolean
    $eventCancellation: Boolean
    $eventInitiated: Boolean
    $inventoryShipment: Boolean
    $customerComment: Boolean
    $atpNotification: Boolean
    $urgentReplenish: Boolean
  ) {
    createNotificationSettings(
      eventInvite: $eventInvite
      eventUpdate: $eventUpdate
      eventCancellation: $eventCancellation
      eventInitiated: $eventInitiated
      inventoryShipment: $inventoryShipment
      customerComment: $customerComment
      atpNotification: $atpNotification
      urgentReplenish: $urgentReplenish
    ) {
      message
    }
  }
`;

export default CREATE_NOTIFICATION_SETTINGS;
