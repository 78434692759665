/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import React, { FC, ReactNode, useEffect, useState, useCallback } from 'react';
import Icon from '@salesforce/design-system-react/components/icon';
import Input from '@salesforce/design-system-react/components/input';
import { useMutation } from '@apollo/react-hooks';
import Button from '@salesforce/design-system-react/components/button';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
// import InventoryApproveRejectDataCell from '../DataTableCells/InventoryApproveRejectDataCell';
import InventoryApproveRejectDataCell from './DataTableCells/InventoryApproveRejectDataCell';
import InventoryCounterDataCell from './DataTableCells/InventoryCounterDataCell';
import InventoryReturnTypeDataCell from './DataTableCells/InventoryReturnTypeDataCell';
import InventoryRequestAddItems from './InventoryRequestAddItems';
import SnackBar from '../../Shared/SnackBar';
import EventStatusBadge from '../../Shared/EventStatusBadge';
import './index.scss';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import RESUBMIT_ORDER_DETAILS from '../../../graphql/mutations/resubmitOrderDetails';
import { MAX_QUANTITY, NOTIFY_TYPE } from '../../../util/constants';
import FilterTagsForModal from '../../Shared/FilterTagsForModal';

interface Props {
  inventoryReturnDetails: any;
  handleLineItemActions: (parts: any) => void;
  isEventInventoryFlow?: boolean;
  refetch: () => void;
  viewOnly: boolean;
  type?: string;
}
interface LineItems {
  availableQuantity: string;
  expirationDate: string;
  externalId: string;
  id: string;
  lotNumber: string;
  onhandQuantity: string;
  productDescription: string;
  productId: string;
  productNumber: string;
  quantity: string;
  actualQuantity: string;
  status: string;
  type: string;
  workflowAction: string;
}

interface DynamicValue {
  [key: string]: any;
}

interface SelectedData {
  id: string;
  value: string;
  __typename?: string;
}

// CustomDataTableCell must have the same displayName as DataTableCell or it will be ignored.
InventoryApproveRejectDataCell.displayName = DataTableCell.displayName;
InventoryReturnTypeDataCell.displayName = DataTableCell.displayName;
InventoryCounterDataCell.displayName = DataTableCell.displayName;

const PartsInfo: FC<Props> = ({
  inventoryReturnDetails,
  type,
  refetch,
  isEventInventoryFlow,
  handleLineItemActions,
}) => {
  const [showSearchCatalogModal, setShowSearchCatalogModal] = useState(false);
  const { open, notification, openSnackBar } = useSnackBar();
  const { lineItems } = inventoryReturnDetails;
  const [reSubmitInventory] = useMutation(RESUBMIT_ORDER_DETAILS);
  const [lineItemsData, setLineItemsData] = useState<any>();
  const [selectedFilter, setSelectedFilter] = useState<DynamicValue | null>(null);
  const [selectedFilterApiValues, setSelectedFilterApiValues] = useState<DynamicValue | null>(null);

  useEffect(() => {
    const finalLineItems = lineItems.map((item: LineItems) => {
      return {
        ...item,
        userworkflowAction: null,
      };
    });
    // handleLineItemActions(finalLineItems);
    setLineItemsData(finalLineItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItems]);

  const calculateActualQuantitity = useCallback(
    (item: any, onHandQuantity: any) => {
      const isPackContent = item?.salesUnitOfMeasure === 'PK' || item?.salesUnitOfMeasure === 'BX';

      if (!isPackContent) {
        return onHandQuantity;
      }

      const packOfContentCount = item?.packContent > 0 ? item?.packContent || 1 : 1;

      if (item?.productFamily === 'SPTSIMPLANTS') {
        return onHandQuantity * packOfContentCount;
      }

      return onHandQuantity;
    },

    []
  );

  const formatLineItemToReSubmit = (lineItemsArray: any): any => {
    const finalLineItems: Array<any> = [];
    lineItemsArray.map(
      (item: {
        productId: any;
        productNumber: any;
        quantity: any;
        actualQuantity: any;
        externalId: any;
        lotNumber: any;
        sfid: any;
        unitPrice: any;
        caseUsageExternalId: any;
        priceBookEntryId: any;
        salesUnitOfMeasure: any;
        deliveredDate: any;
        caseUsageId: any;
      }): any => {
        finalLineItems.push({
          caseUsageId: item.caseUsageId,
          caseUsageExternalId: item.caseUsageExternalId,
          priceBookEntryId: item.priceBookEntryId,
          salesUnitOfMeasure: item.salesUnitOfMeasure,
          deliveredDate: item.deliveredDate || null,
          productId: item.productId,
          productNumber: item.productNumber,
          quantity: item.quantity,
          actualQuantity: item.actualQuantity,
          lotNumber: item.lotNumber || '',
          externalId: item.externalId || null,
          sfid: item.sfid || '',
          unitPrice: item.unitPrice || '',
        });
      }
    );
    return finalLineItems;
  };

  const getFilterApiValue = (filterValues: DynamicValue): DynamicValue => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          const values = filterValues[key].map((item: SelectedData) => item.value);
          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  const handleFilter = (filterValues: DynamicValue | null, filterName?: string): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
    }
  };

  const onAddClick = (): void => {
    if (type === 'Failed') {
      // resubmitInventory();
      const payload: Array<any> = [];
      lineItemsData.forEach((product: any): void => {
        if (product.changed) {
          const actualQuantity = calculateActualQuantitity(product, product.counterValue);
          payload.push({
            sfid: product.sfid,
            productId: product.productId,
            quantity: product.counterValue.toString(),
            actualQuantity: actualQuantity.toString(),
            productNumber: product.productNumber,
            priceBookEntryId: product.priceBookEntryId,
            salesUnitOfMeasure: product.salesUnitOfMeasure,
            deliveredDate: null,
            ...(product.usageExternalId && { usageExternalId: product.usageExternalId }),
            ...(product.caseUsageId && { caseUsageId: product.caseUsageId }),
            ...(product.caseUsageExternalId && {
              caseUsageExternalId: product.caseUsageExternalId,
            }),
          });
        }
      });
      const mutation = {
        id: inventoryReturnDetails.id,
        caseId: inventoryReturnDetails.caseId,
        caseExternalId: inventoryReturnDetails.caseExternalId,
        externalId: inventoryReturnDetails.externalId,
        shippingId: inventoryReturnDetails.shippingId,
        accountId: inventoryReturnDetails.accountId,
        billingId: inventoryReturnDetails.billingId,
        shipMethod: inventoryReturnDetails.shipMethod,
        serviceLevel: inventoryReturnDetails.serviceLevel,
        salesRepId: inventoryReturnDetails.salesRepId,
        receivingRepId: inventoryReturnDetails.receivingRepId,
        fromSubInventoryCode: inventoryReturnDetails.fromSubInventoryCode,
        toSubInventoryCode: inventoryReturnDetails.toSubInventoryCode,
        salesOrganization: inventoryReturnDetails.salesOrganization,
        needByDate: inventoryReturnDetails.needByDate,
        requestedFrom: 'InventoryRequest',
        submitType: 'Update',
        // comments: userComment,
        lineItems: formatLineItemToReSubmit(payload),
      };
      reSubmitInventory({
        variables: mutation,
      }).then((res: any) => {
        if (res && res.data && res.data.resubmitOrderDetails?.message === NOTIFY_TYPE.SUCCESS) {
          openSnackBar(NOTIFY_TYPE.SUCCESS, 'Successfully deleted line item');
          if (refetch) {
            refetch();
          }
        }
      });
    }
  };

  const handleUserWorkflowAction = (changeItem: any): void => {
    const listItemsF = [...lineItemsData];
    listItemsF.forEach((e: any) => {
      if (
        e.productId === changeItem.productId &&
        e.caseUsageExternalId === changeItem.caseUsageExternalId
      ) {
        e.quantity = 0;
        e.actualQuantity = 0;
        e.counterValue = 0;
        e.changed = true;
        e.deleted = true;
      }
    });
    setLineItemsData(listItemsF);
    onAddClick();
  };

  const onHandleCloseSearchCatalogModal = (): void => {
    setShowSearchCatalogModal(false);
    setTimeout(() => {
      if (refetch) {
        refetch();
      }
    }, 1000);
  };

  const handleAddClick = (list: any): void => {
    const updatedList = lineItemsData.concat(list);
    // handleLineItemActions(updatedList);
    // setLineItemsData(updatedList);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAddModal = (check: any): void => {
    setShowSearchCatalogModal(false);
  };
  const handleCount = useCallback(
    (event: any, data: { value: string; number: number }, product: any): void => {
      const updatedList = [...lineItems];
      updatedList.forEach((element: any) => {
        if (
          product.productId === element.productId &&
          product.usageExternalId === element.usageExternalId
        ) {
          const packContent =
            product?.salesUnitOfMeasure === 'PK' || product?.salesUnitOfMeasure === 'BX';

          const packOfContentCount = product?.packContent > 0 ? product?.packContent || 1 : 1;

          if (product.productFamily === 'SPTSIMPLANTS' && packContent) {
            element.quantity =
              data.number < MAX_QUANTITY ? data.number.toString() : MAX_QUANTITY.toString();
            element.actualQuantity = (element.quantity * packOfContentCount).toString();
            element.changed = true;
          } else {
            /* eslint-disable no-param-reassign */
            element.quantity =
              data.number < MAX_QUANTITY ? data.number.toString() : MAX_QUANTITY.toString();
            element.actualQuantity = element.quantity;
            element.changed = true;
          }
        }
      });
      setLineItemsData(updatedList);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lineItemsData, setLineItemsData]
  );

  const actions = (): ReactNode => (
    <PageHeaderControl>
      <ButtonGroup className="slds-align-middle" id="button-group-page-header-actions">
        <Dropdown
          iconCategory="utility"
          iconSize="small"
          align="right"
          iconName="down"
          iconPosition="right"
          label="Add"
          onSelect={(selectedOption: any): void => {
            const { value } = selectedOption;
            if (value === 'search') {
              setShowSearchCatalogModal(true);
            }
          }}
          options={[{ label: 'Search', value: 'search' }]}
          disabled={type === 'Processed'}
          style={{ borderRadius: '5px' }}
        />
      </ButtonGroup>
    </PageHeaderControl>
  );
  const gridTemplateCols = (): string => {
    const gridTemplate = 'ir-cols';
    if (type === 'Failed') {
      return `failed-${gridTemplate}`;
    }
    return `processed-${gridTemplate}`;
  };
  return (
    <div className="slds-m-around_small slds-m-bottom_none">
      <SnackBar open={open} notification={notification} />
      <PageHeader
        className="slds-page-header__name-title-inventory-new"
        // style={{ color: 'black'  }}
        onRenderActions={actions}
        icon={<Icon category="standard" name="instore_locations" size="small" />}
        title="Inventory"
        variant="record-home"
        id="header"
      />
      <div style={{ overflow: 'auto' }}>
        <>
          <div className="parts-table-container-IR">
            <div>
              <div className={`parts-table-header ${gridTemplateCols()}`}>
                <p>Part Number</p>
                <p>Part Description</p>
                <p className="pad-left-60">Qty</p>
                {type === 'Failed' && <p>Actual Qty</p>}
                <p>Status</p>
                {type === 'Processed' && <p>Tracking Number</p>}
                <p>Action</p>
              </div>
              {lineItemsData &&
                lineItemsData.map((lineItem: any, index: number) => {
                  return (
                    <div>
                      <div>
                        <div className={`parts-table-row ${gridTemplateCols()}`}>
                          <p className="vertical-align-middle">{lineItem.productNumber}</p>
                          <p className="vertical-align-middle">{lineItem.productdescription}</p>
                          <div className="row-control">
                            <Input
                              disabled={type === 'Processed'}
                              id="counter-input"
                              minValue={0}
                              maxValue={MAX_QUANTITY}
                              value={lineItem.quantity}
                              variant="counter"
                              onChange={(
                                event: any,
                                data: { value: string; number: number }
                              ): void => {
                                handleCount(event, data, lineItem);
                              }}
                            />
                          </div>
                          {type === 'Failed' && (
                            <div className="row-control" style={{ marginTop: '5px' }}>
                              {lineItem.actualQuantity || ''}
                            </div>
                          )}
                          <p>
                            <EventStatusBadge status={lineItem.status || 'NEW'} />
                          </p>
                          {type === 'Processed' && <p>{lineItem.trackingNumber || '--'}</p>}
                          <div className="vertical-align-middle" style={{ paddingLeft: '10px' }}>
                            <Button
                              disabled={type === 'Processed'}
                              iconCategory="utility"
                              iconName="delete"
                              iconSize="large"
                              iconVariant="bare"
                              variant="icon"
                              onClick={(): void => {
                                handleUserWorkflowAction(lineItem);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      </div>
      {showSearchCatalogModal && (
        <InventoryRequestAddItems
          isOpen={showSearchCatalogModal}
          handleClose={onHandleCloseSearchCatalogModal}
          setShowSearchCatalogModal={handleAddModal}
          inventoryDetail={inventoryReturnDetails}
          handleAddClick={handleAddClick}
          isEventInventoryFlow={isEventInventoryFlow}
          type={type}
          quantityCount={0}
          refetch={refetch}
          selectedFilter={selectedFilter}
          handleFilter={handleFilter}
        />
      )}
      {selectedFilter && selectedFilter.filterValues && (
        <FilterTagsForModal selectedFilter={selectedFilter} handleFilter={handleFilter} />
      )}
    </div>
  );
};

export default PartsInfo;
