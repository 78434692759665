/*
 * Get Procedure Details GraphQL specification
 * @module src/graphql/getProcedureDetails
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_PROCEDURE_DETAILS = gql`
  query getProcedureDetails($division: String!) {
    getProcedureDetails(division: $division) {
      id
      label: name
    }
  }
`;
