/*
 * User Info GraphQL specification
 * @module src/graphql/getNotificationConfiguration
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_NOTIFICATION_CONFIGURATION = gql`
  query getNotificationConfiguration($businessunit: String) {
    getNotificationConfiguration(businessunit: $businessunit) {
      id
      emailCategoryId
      businessunitId
      enabled
      required
      description
      businessunitName
      subCategory
      category
      createdDate
      updatedDate
      updatedBy
      createdBy
    }
  }
`;
