import React, { FC, useEffect, useState } from 'react';
import Toast from '@salesforce/design-system-react/components/toast';
// import Icon from '@salesforce/design-system-react/components/icon';
import ToastContainer from '@salesforce/design-system-react/components/toast/container';
import { Notification } from '../../../util/customHooks/useSnackBar';

interface Props {
  open: boolean;
  notification: Notification;
}

const SnackBar: FC<Props> = ({ open, notification }) => {
  const [showAlert, setState] = useState<boolean>();
  const [notificationType, setType] = useState<string>();
  useEffect(() => {
    setState(open);
  }, [open]);
  useEffect(() => {
    setType(notification.type);
  }, [notification]);
  return (
    <>
      {showAlert && (
        <div>
          <ToastContainer>
            <Toast
              style={{ width: '600px' }}
              labels={{
                heading: [notification.message],
              }}
              // variant={notification.type}
              variant={notificationType}
              onRequestClose={(): void => setState(false)}
            />
          </ToastContainer>
        </div>
      )}
    </>
  );
};

export default SnackBar;
