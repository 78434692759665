/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Module for header on Inventory pgage
 * @module src/Inventory/InventoryHeader
 */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, ReactNode, useCallback, useState, useEffect } from 'react';
import Icon from '@salesforce/design-system-react/components/icon';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Popover from '@salesforce/design-system-react/components/popover';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import './index.scss';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../store/ducks/userInfo';
import { GET_FORM_CONFIGURATIONS } from '../../../graphql/getFormConfigurations';
import { GET_SALES_REPS } from '../../../graphql/getSalesReps';
import InventoryRequest from '../InventoryRequest';
import CancelEventModal from '../../CreateEditSurgeryEvent/CancelEventCreationModal';
import AutocompleteSingleSelectPicker from '../../Shared/AutocompleteSingleSelectPicker';
import AutocompleteSingleSelectPickerWithSearch from '../../Shared/AutocompleteSingleSelectPickerWithSearch';
import { getSvgIcon, getHoldIcon } from '../../../util/utilityFunctions';
import { GET_WORKFLOW_PERMISSION } from '../../../graphql/workflowPermissions';
import DisplayRecordIndicator from '../../Shared/DisplayRecordIndicator';

interface WorkflowPermission {
  workflowPermission: {
    id: string;
  } | null;
}
interface SelectedData {
  id: string;
  label: string;
}
interface Filter {
  status: SelectedData[];
  salesReps: SelectedData[];
  coveringReps: SelectedData[];
  account: SelectedData[];
  branch: SelectedData[];
  franchise: SelectedData[];
  surgeon: SelectedData[];
  procedureDetail: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  usage: string;
  eventType: string[];
  filterExternalId?: string;
  filterName?: string;
  search?: string;
}

interface ApiFilters {
  status?: string[];
  salesReps?: string[];
  coveringReps?: string[];
  account?: string;
  branch?: string[];
  franchise?: string[];
  surgeon?: string;
  procedureDetail?: string[];
  startDate?: Date | string;
  endDate?: Date | string;
  usage?: string;
  eventType?: string[];
  search?: string;
}

interface FilterPreset {
  filterValues: Filter;
  filterExternalId: string;
  filterName: string;
}

interface Props {
  // inventoryCount: number;
  selectedAccount: any;
  selectedPart: any;
  accountItemsData: any;
  handleInventorySearch: (name: string) => void;
  handleSelect: (data: any) => void;
  handleToggleFilterInventory: () => void;
  resetFilters: () => void;
  filterPresets: FilterPreset[];
  handleFilterInventory: (filterValues: Filter, filterName: string) => void;
  onEditPress: (filter: FilterPreset, isEditPress: boolean) => void;
  userView: string;
  partsData: any;
  invData: any;
  salesRepId?: any;
  getPrimaryAccountItems?: any;
  getSubInventoryWithLotDetails?: any;
  setSearchHappen?: any;
  loadingPrimaryAccounts?: boolean;
}

interface DropdownSelect {
  label: string;
  value: string;
  subType?: string;
}

interface UserInfo {
  contactId?: string;
  division: string | null;
  divisions: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  personas: string;
  userName: string | null;
  userType: string;
  businessunit: string;
}

const InventoryHeader: FC<Props> = ({
  handleInventorySearch,
  handleSelect,
  handleToggleFilterInventory,
  resetFilters,
  filterPresets,
  selectedAccount,
  accountItemsData,
  userView,
  partsData,
  selectedPart,
  invData,
  salesRepId,
  getPrimaryAccountItems,
  getSubInventoryWithLotDetails,
  setSearchHappen,
  loadingPrimaryAccounts,
}) => {
  // const filtersLength = selectedFilterApiValues ? Object.keys(selectedFilterApiValues).length : 0;
  const [eventType, setSelectedEventType] = useState('');
  const [searchTerm, setSearchText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const userInfo = useSelector(getUserInfo);
  const [canceModalVisible, setCanceModalVisible] = useState(false);
  const [getFormConfigurations, { data }] = useLazyQuery(GET_FORM_CONFIGURATIONS);
  const [inventorySearch, setInventorySearch] = useState('');
  const [filterPresetOpen, setFilterPresetOpen] = useState(false);
  const holdIconPath = getHoldIcon();
  const { data: isBizAdminUser } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'is_business_admin_user',
    },
  });
  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);

  useEffect(() => {
    let delayDebounceFn: NodeJS.Timeout;
    // eslint-disable-next-line prefer-const
    if (searchTerm?.length > 2) {
      delayDebounceFn = setTimeout(() => {
        handleInventorySearch(searchTerm);
      }, 2000);
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleRequestInventory = (): void => {
    setSelectedEventType('Request Inventory');
    setIsOpen(true);
  };

  const handleModalToggle = useCallback((): void => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleChange = (e: any): void => {
    setInventorySearch(e.target.value);
    setSearchText(e.target.value);
  };
  const handleFilterPresetPopoverClose = useCallback((): void => {
    setFilterPresetOpen(false);
  }, []);
  const handleFilterInventoryItems = (filterValues: Filter, filterName: string) => (): void => {
    setFilterPresetOpen(false);
    handleFilterInventoryItems(filterValues, filterName);
  };
  const handleEditPress = (filter: FilterPreset) => (): void => {
    setFilterPresetOpen(false);
    // onEditPress(filter, true);
  };
  const handleCancelButton = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };
  const handleCancelModal = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };
  const handleCancelModalConfirmPress = (): void => {
    setCanceModalVisible(!canceModalVisible);
    handleModalToggle();
  };
  const accountItemsDataHasInventory: any =
    accountItemsData &&
    accountItemsData
      .map((elem: any) => {
        return {
          ...elem,
          id: elem?.itHasInventory
            ? `-itHasInventory_enabled_${elem.id}`
            : `itHasInventory_disabled_${elem.id}`,
          itHasInventory: elem?.itHasInventory,
        };
      })
      .sort((a: any, b: any) => {
        // Sort in ascending order based on itHasInventory
        return b.itHasInventory - a.itHasInventory;
      });
  const headerActions = (): ReactNode => (
    <>
      {userView === 'By Account' ? (
        <PageHeaderControl>
          <div className="slds-form-element__control" style={{ width: '440px' }}>
            <AutocompleteSingleSelectPicker
              items={accountItemsDataHasInventory}
              setSelectedItem={(result: any): void => {
                handleSelect(result);
              }}
              disabled={false}
              selectedItem={selectedAccount || []}
              placeholder="Select an account"
              label="Account"
              withAddMenuButton={false}
              fetchItems={
                (searchText: string): any => {
                  getPrimaryAccountItems({
                    variables: {
                      filters: {
                        searchText,
                      },
                      salesRepId,
                      limit: 500,
                    },
                  });

                  if (searchText) {
                    setSearchHappen(true);
                  } else {
                    handleSelect(searchText);
                  }
                }
                // eslint-disable-next-line react/jsx-curly-newline
              }
              filter
              name="InventorySearchaccount"
              autoSelectValue={false}
              isLoading={loadingPrimaryAccounts}
            />
          </div>
        </PageHeaderControl>
      ) : (
        <PageHeaderControl>
          <div
            className="slds-form-element__control"
            style={{ width: '440px', marginBottom: '-13px' }}
          >
            <AutocompleteSingleSelectPickerWithSearch
              items={partsData}
              setSelectedItem={(result: any): void => {
                handleSelect(result);
              }}
              disabled={false}
              selectedItem={selectedPart || []}
              placeholder="Select a part"
              label=""
              withAddMenuButton={false}
              fetchItems={
                (searchText: string): string =>
                  getSubInventoryWithLotDetails({
                    variables: {
                      filters: {
                        searchText,
                      },
                      salesRepId,
                    },
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
              filter
              name="parts"
              autoSelectValue={false}
            />
          </div>
        </PageHeaderControl>
      )}
    </>
  );
  const headerControls = (): ReactNode => (
    <>
      <PageHeaderControl className="inventory-list-legends">
        <Input
          iconRight={
            <InputIcon
              assistiveText={{
                icon: 'Search',
              }}
              name="search"
              category="utility"
            />
          }
          id="search-case-id"
          placeholder={userView === 'By Account' ? 'Search by Part Info' : 'Search'}
          onChange={handleChange}
        />
      </PageHeaderControl>

      <PageHeaderControl className="slds-m-left_large">
        <ButtonGroup variant="list" id="button-group-page-header-controls">
          <Button
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="filterList"
            label="Filter"
            iconPosition="left"
            onClick={handleToggleFilterInventory}
          />
          <DisplayRecordIndicator
            recordInfo={invData?.length > 0 ? invData[0] : ''}
            screen="inventory"
          />
          <div className="slds-dropdown-trigger slds-dropdown-trigger_click filter-preset-popover">
            <Popover
              align="bottom"
              isOpen={filterPresetOpen}
              onClose={handleFilterPresetPopoverClose}
              onRequestClose={handleFilterPresetPopoverClose}
              ariaLabelledby=""
              body={
                <div className="filter-preset_dropdown">
                  <ul className="slds-dropdown__list" role="menu" aria-label="Show More">
                    <li className="slds-dropdown__item" role="presentation" key="reset">
                      <Button
                        className="slds-button slds-button_neutral filter-preset-reset-button"
                        onClick={resetFilters}
                      >
                        Reset
                      </Button>
                    </li>
                    {filterPresets &&
                      filterPresets.map((filter: FilterPreset) => {
                        return (
                          <li
                            className="slds-dropdown__item"
                            role="presentation"
                            key={filter.filterExternalId}
                          >
                            <Button
                              className="slds-button slds-button_neutral filter-preset-heading-button"
                              onClick={handleFilterInventoryItems(
                                filter.filterValues,
                                filter.filterName
                              )}
                            >
                              {filter.filterName}
                            </Button>
                            <Button
                              className="slds-button slds-button_neutral filter-preset-edit-button"
                              onClick={handleEditPress(filter)}
                            >
                              Edit
                            </Button>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              }
              id="popover-walkthrough"
            />
          </div>
        </ButtonGroup>
      </PageHeaderControl>
    </>
  );

  const iconPath = getSvgIcon('inventoryView');

  return (
    <div>
      <IconSettings iconPath="/icons">
        <PageHeader
          className="inventoryList_header"
          icon={<img src={iconPath} className="logo-image" alt="Inventory View" />}
          onRenderActions={headerActions}
          onRenderControls={headerControls}
          onRender
          title="Inventory View"
          truncate
          variant="object-home"
        />
      </IconSettings>
      {isOpen && (
        <InventoryRequest
          isOpen={isOpen}
          toggleOpen={handleModalToggle}
          formData={data}
          eventType={eventType}
          handleCancelButton={handleCancelButton}
        />
      )}
      {canceModalVisible && (
        <CancelEventModal
          isInventory
          canceModalVisible={canceModalVisible}
          handleCancelModal={handleCancelModal}
          handleCancelModalConfirmPress={handleCancelModalConfirmPress}
        />
      )}
    </div>
  );
};

export default InventoryHeader;
