/**
 * Surgery Form JSON.
 * @module src/util/FormJson/surgery
 */
import { Form } from '../../components/Shared/FormEngine';

const formJson: Form = [
  {
    kind: 'field',
    name: 'topInfo',
    fieldLabel: '*Required fields',
    fieldType: 'text',
    required: true,
    isShow: true,
    optionsStatic: [],
    optionsDependentField: [],
    optionsAutoSelected: false,
    showTooltip: false,
    permissionSets: [],
    showOnWeb: false,
    showAddButton: false,
    keypadType: 'default',
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'salesRep',
        fieldLabel: 'Sales Rep',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        isShow: true,
        optionsPlaceHolder: 'Sales Rep',
        optionsDynamic: 'getSalesReps',
        optionsDependentField: [],
        optionsAutoSelected: true,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'tbaAccount',
        fieldLabel: 'Account Types',
        fieldType: 'radio',
        isShow: true,
        required: false,
        optionsPlaceHolder: 'Account Types',
        optionsDynamic: 'getTbaAccounts',
        optionsDependentField: [],
        optionsStatic: [
          { value: 'My Accounts', label: 'My Accounts', id: 'My Accounts' },
          { value: 'TBA Accounts', label: 'TBA Accounts', id: 'TBA Accounts' },
        ],
        optionsAutoSelected: false,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'account',
        fieldLabel: 'Account',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        isShow: true,
        optionsPlaceHolder: 'Account',
        optionsDynamic: 'getAccounts',
        optionsDependentField: ['salesRep'],
        optionsAutoSelected: true,
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'eventDate',
        fieldLabel: 'Start Date/Time',
        fieldType: 'dateTimePicker',
        required: true,
        isShow: true,
        optionsAutoSelected: false,
        date: true,
        time: true,
      },
      {
        characterLimit: 20,
        fieldLabel: 'Duration (hours)',
        fieldType: 'input',
        kind: 'field',
        name: 'eventDuration',
        optionsAutoSelected: false,
        optionsDependentField: [],
        optionsDynamic: '',
        optionsPlaceHolder: 'eventDuration',
        permissionSets: [],
        required: true,
        isShow: true,
        keypadType: 'number-pad',
      },
    ],
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'contact',
        fieldLabel: 'Surgeon',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: false,
        isShow: true,
        optionsPlaceHolder: 'Surgeon',
        optionsDynamic: 'getSurgeons',
        optionsDependentField: ['account'],
        optionsAutoSelected: true,
        permissionSets: [],
        showAddButton: false,
        addButtonText: 'Add Surgeon',
      },
      {
        kind: 'field',
        name: 'coveringRep',
        fieldLabel: 'Covering Rep',
        fieldType: 'selectMultiDynamicPicker',
        required: false,
        isShow: true,
        optionsPlaceHolder: 'Covering Rep',
        optionsDynamic: 'getCoveringReps',
        optionsDependentField: ['salesRep'],
        optionsAutoSelected: false,
      },
      {
        kind: 'field',
        name: 'procedureType',
        fieldLabel: 'Procedure',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        isShow: true,
        optionsPlaceHolder: 'Procedure',
        optionsDynamic: 'getProcedureType',
        optionsDependentField: [],
        optionsAutoSelected: true,
      },
    ],
  },
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'comments',
        fieldLabel: 'Comments',
        fieldType: 'comments',
        required: false,
        isShow: true,
        optionsDependentField: [],
        optionsAutoSelected: false,
        characterLimit: 32000,
        permissionSets: [],
      },
    ],
  },
];

/** Exporting surgery form json */
export default formJson;
