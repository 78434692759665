/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_CASE_USAGE_SHEET_REVIEW_LIST = gql`
  query getCaseUsageSheetListOps(
    $limit: Int
    $offset: Int
    $type: String
    $orderBy: String
    $orderSortType: String
    $filters: CaseUsageSheetFiltersOps
  ) {
    getCaseUsageSheetListOps(
      limit: $limit
      offset: $offset
      type: $type
      orderBy: $orderBy
      orderSortType: $orderSortType
      filters: $filters
    ) {
      caseId
      caseExternalId
      salesRepId
      salesRep
      eventType
      eventId
      division
      eventStatus
      serviceLevel
      needByDate
      parentEventId
      parentEventType
      parentEventStatus
      parentCaseExternalId
      parentCaseId
      parentEventSubType
      branchId
      branch
      accountId
      accountNumber
      accountName
      createdDate
      shippingAddessLine1
      shippingAddessLine2
      shippingPostalcode
      shippingCity
      shippingState
      shippingId
      shippingAddressNumber
      billingAddessLine1
      billingAddessLine2
      billingPostalcode
      billingCity
      billingState
      billingId
      billingAddressNumber
      qty
      orderType
      shipMethod
      eventDateTime
      poNumber
      patientId
      surgeonId
      surgeonFirstName
      surgeonLastName
      surgeonErpCode
      procedureId
      procedureName
      totalAmount
      diplayordertype
      assignedUserId
      assignedUserName
      lotNumber
      serialNumber
      partNumber
      failureReason
      integrationStatus
      status
      orderNumber
      orderId
      orderExternalId
      caseNumber
    }
  }
`;
