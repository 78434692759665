/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Save Filter Modal.
 * @module src/components/FilterEvent/SaveFilter
 */
import React, { FC, useState } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import './index.scss';

interface Props {
  handleSaveModalVisible: () => void;
  saveModalVisible: boolean;
  handleCreateFilterPreset: (name: string) => void;
  selectedFilterName: string;
}
const SaveFilter: FC<Props> = ({
  saveModalVisible,
  handleSaveModalVisible,
  handleCreateFilterPreset,
  selectedFilterName,
}) => {
  const [filterName, setFilterName] = useState(selectedFilterName || '');

  const handleChangeText = (event: any): void => {
    event.persist();
    const { value } = event.target;
    setFilterName(value);
  };

  const handleCreatePreset = (): void => {
    handleCreateFilterPreset(filterName);
  };
  return (
    <Modal
      className="save-filter-modal default-modal_header default-modal_footer"
      ariaHideApp={false}
      isOpen={saveModalVisible}
      footer={[
        <Button label="Cancel" onClick={handleSaveModalVisible} key="save_filter_cancel" />,
        <Button
          label="Save"
          disabled={filterName === ''}
          variant="brand"
          onClick={handleCreatePreset}
          key="save_filter"
        />,
      ]}
      onRequestClose={handleSaveModalVisible}
      heading="Save Filters Preset"
    >
      <div className="slds-text-align--left">
        <div>
          <Input
            id="base-id"
            label="Enter Filter Name"
            className="slds-m-horizontal--large slds-m-top_small"
            onChange={handleChangeText}
            value={filterName}
            maxLength="50"
          />
        </div>
      </div>
    </Modal>
  );
};

/** Save Filter event modal component */
export default SaveFilter;
