/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars*/
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-else-return */
/* eslint-disable no-lonely-if */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */

/**
 * React component for Inventory Request.
 * @module src/components/NewRepTransfer/index
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Modal from '@salesforce/design-system-react/components/modal';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import FormEngine from '../../../Shared/FormEngine';
import { getSchemeJson } from '../../../../util/FormJson';
import { GET_ALL_USERA_BY_BU } from '../../../../graphql/getAllUsersByBU';
import { GET_FORM_CONFIGURATIONS } from '../../../../graphql/getFormConfigurations';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import { NOTIFY_TYPE } from '../../../../util/constants';
import SnackBar from '../../../Shared/SnackBar';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import UPDATE_INVENTORY_SUBMIT_PERMISSION from '../../../../graphql/mutations/updateInventorySubmitPermission';

interface VariableType {
  [key: string]: string;
}

interface File {
  manual?: boolean;
  id?: string;
  name: string;
  imageurl: string;
}
interface Props extends RouteComponentProps {
  toggleOpen: () => void;
  formData?: any;
  isOpen: boolean;
  handleCancelButton?: () => void;
  tranferDetails?: any;
  sourceAccount?: any;
  destAccount?: any;
  isEdit?: boolean;
  eventUsage?: boolean;
  editDetails?: any;
  onSucessUpdate: () => void;
}

const EVENTS_KEY: VariableType = {
  sourceAccount: 'fromSubInventoryCode',
  destinationAccount: 'toSubInventoryCode',
};

const EVENTS_KEY_FAILED: VariableType = {
  // country: 'branchId',
  account: 'accountId',
  surgeon: 'surgeonId',
  salesRep: 'salesRepId',
  shipTo: 'shippingId',
  needByDate: 'needByDate',
  serviceLevel: 'serviceLevel',
  comments: 'comments',
};

const HoldNewRequest: FC<Props> = ({
  isOpen,
  toggleOpen,
  formData,
  handleCancelButton,
  isEdit,
  onSucessUpdate,
}) => {
  const [getFormConfigurations, { data }] = useLazyQuery(GET_FORM_CONFIGURATIONS);
  const [apiloading, setLoader] = useState(false);
  const { open, notification, openSnackBar } = useSnackBar();
  const [selectedTab, setSelectedTab] = useState('Details');
  const [getSalesRepsItems, { data: salesRepsItems }] = useLazyQuery(GET_ALL_USERA_BY_BU); // USELAZYQUERY
  const salesRepsData = salesRepsItems?.getAllUsersByBU;
  // const { data: salesRepsItems } = useQuery(GET_SALES_REPS);
  // const salesRepsData = salesRepsItems?.getSalesReps;
  const userInfo = useSelector(getUserInfo);
  const [repSelected, setRepSelected] = useState<any>();

  const [loading, setLoading] = useState(false);
  const getOptionsWithLabel = (addressData: any): [] => {
    if (addressData) {
      return (
        addressData &&
        addressData.map((item: any) => {
          if (item.__typename === 'Account') {
            return {
              ...item,
              label: item.isRepStockAccount
                ? `* ${item.number} ${item.value}`
                : `${item.number} ${item.value}`,
              // number: item.isRepStockAccount ? `* ${item.number}` : `${item.number}`,
            };
          } else if (item.__typename === 'UpcomingEventsList') {
            return {
              ...item,
              label: `${moment(item?.eventStartDate).format('YYYY/MM/DD')} - ${item?.eventId}`,
            };
          } else if (item.__typename === 'UserRepStockAccount') {
            return {
              ...item,
              label: `* ${item.number} ${item.value}`,
              isRepStockAccount: true,
              number: item.isRepStockAccount ? `* ${item.number}` : `${item.number}`,
            };
          } else {
            if (item.isOtherAddress) {
              return {
                ...item,
                label: `${item.address1 || ''}, ${item.address2 || ''}, ${item.city ||
                  ''}, ${item.state || ''}, ${item.name || ''}`,
              };
            } else {
              return {
                ...item,
                label: `${item.isPrimaryAddress ? '*' : ''} ${item.name} - ${
                  item.address1
                } ${item.address2 || ''}, ${item.state}, ${item.postalcode}`,
              };
            }
          }
        })
      );
    }
    return [];
  };
  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);

  const formJson = useCallback(() => {
    return getSchemeJson('holdNewRequest');
  }, []);

  const apiDataObj = {
    getSalesReps: salesRepsData,
  };
  useEffect(() => {
    getSalesRepsItems();
  }, [getSalesRepsItems]);

  const lazyQueryObject = {
    getSalesReps: getSalesRepsItems,
  };

  const [updateSubmitPermission] = useMutation(UPDATE_INVENTORY_SUBMIT_PERMISSION);
  const handleSaveRequest = (result: any): void => {
    const { holdReason, user } = result;
    updateSubmitPermission({
      variables: {
        userId: user[0].id,
        isInventoryHold: true,
        reasonCodeForHold: 'Custom Hold',
        reason: holdReason,
      },
    })
      .then(() => {
        onSucessUpdate();
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'The changes have been applied.');
        toggleOpen();
      })
      .catch(() => {
        openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
        toggleOpen();
      });
  };

  const handleSave = (result: any): void => {
    handleSaveRequest(result);
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedTab('Details');
    }
  }, [isOpen]);

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      <Modal
        className=""
        isOpen={isOpen}
        onRequestClose={apiloading ? '' : handleCancelButton}
        ariaHideApp
        heading={
          <div className="slds-float_left slds-text-title_bold">
            {/* {inventoryDetail && isClone
              ? `Clone - ${eventType}` || '--'
              : inventoryDetail
              ? `Edit - ${eventType}` || '--'
              : eventType} */}
            {`New Hold Request`}
          </div>
        }
        dismissOnClickOutside={false}
      >
        <section className="slds-p-around_small slds-theme_shade" key="form">
          {data && (
            <FormEngine
              formItems={formJson()}
              division="EU"
              apiData={apiDataObj}
              useLazyQueryObject={lazyQueryObject}
              handleSave={handleSave}
              toggleOpen={toggleOpen}
              isOpen={isOpen}
              handleCancelButton={handleCancelButton}
              createEventLoading={loading}
              createRequest={apiloading}
            />
          )}
        </section>
      </Modal>
    </IconSettings>
  );
};

/** React component for Inventory Request. */
export default withRouter(HoldNewRequest);
