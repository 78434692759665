import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
//  Interface for Data table data type for all the columns
interface Account {
  inventoryLocation: string;
  territory: string;
}

export interface Props {
  item?: Account;
}

const CycleCountAccountNameCell: FC<Props> = ({
  item = {
    territory: '',
    inventoryLocation: '',
  },
}) => {
  const { territory, inventoryLocation } = item;
  return (
    <DataTableCell title={`${inventoryLocation || ''} - ${territory || ''}`}>
      <p className="wrap-text font-12">{`${inventoryLocation || ''} - ${territory || ''}`}</p>
    </DataTableCell>
  );
};

/** Custom Account/Event Name cell */
export default CycleCountAccountNameCell;
