/*
 * Event Details GraphQL specification
 * @module src/graphql/workflowPermissions
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Event Details GraphQL API query */
export const GET_LINE_ITEMS_FOR_EVENTS = gql`
  query getLineItemsForEvents($eventExternalId: String!) {
    getLineItemsForEvents(eventExternalId: $eventExternalId) {
      productNumber
      productdescription
      totalActaulQty
      productOnHoldType
      productOnHold
      serialRecalled
      totalDispositionQty
      lotDetails {
        productNumber
        lotSerialNumber
        lotQuantity
        dispQuantity
        expirationDate
        isexpire30days
        isexpire90days
        isexpired
      }
    }
  }
`;
