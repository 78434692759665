//* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line react/jsx-wrap-multilines
import React, { FC } from 'react';
import { format } from 'date-fns';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import '../../index.scss';
// import { USAGE_TYPE } from '../../../../../util/constants';
import Env from '../../../../../util/env';

interface Props {
  open: boolean;
  showPrice: boolean;
  caseId: string;
  handleClosePdfModal: () => void;
  handleEmail: () => void;
  diplayOrderType: string;
  documentName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  surgeryDate?: any;
}

const SurgerySheetPdf: FC<Props> = ({
  open,
  showPrice,
  caseId,
  handleClosePdfModal,
  handleEmail,
  // diplayOrderType,
  documentName,
  surgeryDate,
}) => {
  const formattedDate = surgeryDate ? format(new Date(surgeryDate), 'MM/dd/yyyy') : '';
  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="surgery-sheet-pdf-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={open}
        onRequestClose={handleClosePdfModal}
        footer={[
          <Button label="Close" key="Close" onClick={handleClosePdfModal} />,
          <Button
            className="yellow-btn"
            label="Email"
            onClick={handleEmail}
            variant="brand"
            key="save"
          />,
        ]}
        heading={[
          <div>
            <div style={{ textAlign: 'center' }}>
              <p>{documentName}</p>
            </div>
            <p style={{ padding: '5px 15px', color: '#ff0000', fontSize: '0.7em' }}>
              The information on the PDF may be up to five minutes out of date. Please verify that
              the information on this PDF is current before sending it to customers.
            </p>
          </div>,
        ]}
      >
        <iframe
          title="Usage Sheet"
          id="serviceFrameSend"
          src={`${Env.REACT_APP_GRAPHQL_URL.replace('graphql', '')}services/${
            showPrice ? 'getInvoiceWithPrice' : 'getInvoiceWithoutPrice'
          }?case_id=${caseId}&toggle_price_flag=${showPrice}&surgery_date=${formattedDate}`}
          width="100%"
          height="100%"
          frameBorder="0"
        />
      </Modal>
    </IconSettings>
  );
};

export default SurgerySheetPdf;
