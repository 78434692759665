/*
 * Inventory Favorite GraphQL specification
 * @module src/graphql/getInventoryRequestFavoriteById
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Inventory Request Favorite GraphQL API query */
export const GET_INVENTORY_REQUEST_FAVORITE_ID = gql`
  query getInventoryFavoriteById($id: String) {
    getInventoryFavoriteById(id: $id) {
      _id
      nameOfFavorite
      user
      serviceLevel
      externalId
      caseId
      comments {
        comment
        postTime
        commentType
        externalId
      }
      userDetails {
        sfId
        name
      }
      salesRep {
        id
        name
      }
      account {
        id
        name
      }
      shipTo {
        id
        name
      }
      lineItems {
        part {
          id
          name
        }
        lotNumber
        quantity
      }
    }
  }
`;
