/**
 * Module for Custom Date/Time Cell.
 * @module src/Events/EventsList
 */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import { format, addMinutes } from 'date-fns';
import moment from 'moment';
import { MOCK_OR_EVENT_TYPE } from '../../../util/constants';

interface DateTime {
  eventDate: Date;
  eventTime: string;
  eventStartDate: Date;
  eventEndDate: Date;
  eventType: string;
  eventSubType: string;
}
export interface Props {
  item?: DateTime;
}

const EventsListDateTimeCell: FC<Props> = ({
  item = {
    eventDate: null,
    eventTime: null,
    eventStartDate: null,
    eventEndDate: null,
    eventType: null,
    eventSubType: null,
  },
}) => {
  const { eventStartDate, eventEndDate, eventType, eventSubType } = item;
  const formatter = 'M/d/yyyy';
  const date = eventEndDate ? format(new Date(eventEndDate), formatter) : '';
  const startDate = new Date(moment.utc(eventStartDate).format('YYYY-MM-DD HH:mm:ss'));
  const endDate = eventEndDate
    ? new Date(moment.utc(eventEndDate).format('YYYY-MM-DD HH:mm:ss'))
    : addMinutes(new Date(moment.utc(eventEndDate).format('YYYY-MM-DD HH:mm:ss')), Number(15));
  const timedata = new Date();
  const newtime = timedata
    .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
    .substring(4)
    .match(/\b(\w)/g);
  const TimeZoneData = newtime?.join('');
  return (
    <DataTableCell title={date}>
      {(eventType === 'Surgery' ||
        eventType === 'Surgery-Usage Only' ||
        (eventType === 'Demo' && eventSubType !== MOCK_OR_EVENT_TYPE)) && (
        <div style={{ display: 'flex' }}>
          <p className="font-12 font-600">{date}</p>
          <p className="font-12 font-600">
            Start Date
            {` : `}
            {eventStartDate ? eventStartDate && format(new Date(eventStartDate), formatter) : '--'}
          </p>
          <p className="font-12 font-600" style={{ paddingLeft: '5px' }}>
            {eventStartDate &&
              new Date(eventStartDate).toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
              })}
            {eventStartDate ? ` ${TimeZoneData}` : ''}
          </p>
        </div>
      )}

      {eventType === 'Cycle Count' && (
        <div>
          <p className="font-12 font-600">
            Start Date
            {` : `}
            {startDate ? startDate && format(new Date(startDate), formatter) : '--'}
          </p>
          <p className="font-12 font-600">
            End Date
            {` : `}
            {endDate ? endDate && format(new Date(endDate), formatter) : '--'}
          </p>
        </div>
      )}
      {(eventType === 'Trial' || (eventType === 'Demo' && eventSubType === MOCK_OR_EVENT_TYPE)) && (
        <div>
          {eventStartDate ? (
            <p className="font-12 font-600">
              Start Date
              {` : `}
              {moment(eventStartDate).format('L')}
              <span style={{ paddingLeft: '5px' }}>{moment(eventStartDate).format('LT')}</span>
              {eventStartDate ? ` ${TimeZoneData}` : ''}
            </p>
          ) : (
            ''
          )}
          {eventEndDate ? (
            <p className="font-12 font-600">
              End Date
              {` : `}
              {moment(eventEndDate).format('L')}
              <span style={{ paddingLeft: '5px' }}>{moment(eventEndDate).format('LT')}</span>
              {eventEndDate ? ` ${TimeZoneData}` : ''}
            </p>
          ) : (
            ''
          )}
        </div>
      )}
    </DataTableCell>
  );
};

/** Custom Date/Time cell */
export default EventsListDateTimeCell;
