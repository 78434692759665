import { Form } from '../../components/Shared/FormEngine';

const CustomerTransfer: Form = [
  {
    kind: 'section',
    fields: [
      {
        kind: 'field',
        name: 'tabNavigator',
        fieldLabel: 'Tab Navigator',
        fieldType: 'tabNavigator',
        required: false,
        optionsPlaceHolder: '',
        optionsDynamic: '',
        isShow: true,
        optionsStatic: [
          { value: 'details', label: 'Details' },
          { value: 'parts', label: 'Parts' },
        ],
        optionsAutoSelected: false,
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'sourceAccount',
        fieldLabel: 'Source Account',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: 'Pick an Account',
        optionsDynamic: 'getSourceAccounts',
        isShow: true,
        optionsAutoSelected: true,
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'destinationAccount',
        fieldLabel: 'Destination Account',
        fieldType: 'selectSingleDynamicAutoCompletePicker',
        required: true,
        optionsPlaceHolder: 'Pick an Account',
        optionsDynamic: 'getDestinationAccounts',
        isShow: true,
        optionsAutoSelected: false,
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'Attachments',
        fieldLabel: 'Attachments',
        fieldType: 'Attachments',
        required: false,
        isShow: true,
        optionsAutoSelected: false,
        optionsDependentField: [],
        permissionSets: [],
      },
      {
        kind: 'field',
        name: 'comments',
        fieldLabel: 'Comments',
        fieldType: 'comments',
        required: false,
        optionsAutoSelected: false,
        isShow: true,
        characterLimit: 32000,
        optionsPlaceHolder:
          'Do not use to enter patient information, complaints, or set extension request.',
        showOnWeb: true,
        permissionSets: [],
      },
    ],
  },
];

export default CustomerTransfer;
