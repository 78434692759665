/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Button from '@salesforce/design-system-react/components/button';
import moment from 'moment';
import Icon from '@salesforce/design-system-react/components/icon';
import EventStatusBadge from '../../Shared/EventStatusBadge';
import './index.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Props extends RouteComponentProps {
  children?: FC;
  items?: any;
  eventid: any;
}

const InventoryRequestList: FC<Props> = ({ items, eventid, history }) => {
  const goToOrderDetails = (e: any, externalId: any, orderId: any, status: any): void => {
    e.stopPropagation();
    if (status === 'New' || status === 'Requested' || (status === 'Cancelled' && !orderId)) {
      history.push('/inventoryRequestDetails', {
        externalId,
      });
    } else if (
      status === 'Completed' ||
      status === 'Submitted' ||
      status === 'Booked' ||
      status === 'Partial' ||
      (status === 'Cancelled' && orderId)
    ) {
      history.push('/inventoryRequestDetails', {
        externalId: orderId,
        type: 'Processed',
      });
    } else if (status === 'Failed') {
      history.push('/inventoryRequestDetails', {
        externalId: orderId,
        type: 'Failed',
      });
    } else {
      history.push('/inventoryRequestDetails', {
        externalId,
      });
    }
  };

  return (
    <>
      <div className="parts-list-table-container">
        <div className="parts-list-table-header list-grid-4-cols">
          <p>IR</p>
          <p>Status</p>
          <p>Service Level</p>
          <p>Submit Date</p>
        </div>
        {items &&
          items?.map((lineItem: any) => (
            <div className="parts-list-table-row list-grid-4-cols">
              <p>
                <Button
                  label={lineItem?.name || 'TBD'}
                  variant="base"
                  onClick={(e: any) =>
                    goToOrderDetails(e, lineItem?.externalId, lineItem?.orderSfid, lineItem?.status)
                  }
                />
              </p>
              <p className="vertical-align-middle">
                <div style={{ display: 'flex' }}>
                  <EventStatusBadge
                    status={
                      (lineItem?.status === 'Failed' &&
                      (lineItem?.visualIndicator === 'Failed' ||
                        lineItem?.visualIndicator === 'Approved')
                        ? 'Approved'
                        : lineItem?.status) || 'NEW'
                    }
                  />
                  {lineItem?.visualIndicator === 'Rejected' && (
                    <div style={{ marginLeft: '5px' }}>
                      <Icon
                        title="Rejected"
                        category="utility"
                        name="warning"
                        size="x-small"
                        style={{ color: '#b83c27' }}
                        className="slds-icon-text-error"
                      />
                    </div>
                  )}
                </div>
              </p>
              <p className="vertical-align-middle">{lineItem?.serviceLevel || '--'}</p>
              <p className="vertical-align-middle">
                {lineItem.createdDate ? moment(lineItem?.createdDate).format('L') : '--'}
              </p>
            </div>
          ))}
      </div>
    </>
  );
};

export default withRouter(InventoryRequestList);
