/*
 * Get Sales Reps GraphQL specification
 * @module src/graphql/getSalesReps
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_SALES_REPS = gql`
  query getSalesReps($filters: SalesRepFilters) {
    getSalesReps(filters: $filters) {
      id
      value
      hospital
      mobilephone
      owned
      subInventoryCode
      salesOrganization
      territoryName
      branchId
    }
  }
`;
