/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/**
 * Custom Footer component.
 * @module src/components/App/CustomFooter/index
 */
import React, { FC } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import './index.scss';

const CustomFooter: FC = () => {
  const getEnvironment = (): string => {
    if (window.location.host) {
      if (window.location.host.includes('test')) {
        return 'DEV';
      }
      if (window.location.host.includes('sit')) {
        return 'SIT';
      }
      if (window.location.host.includes('uat')) {
        return 'UAT';
      }
      if (window.location.host.includes('localhost')) {
        return 'LOCAL';
      }
      return 'PROD';
    }
    return 'LOCAL';
  };
  return (
    <IconSettings iconPath="/icons">
      <div className="footer_container">
        <footer style={{ height: '50px', background: '#545857', borderTop: '5px solid #ffb400' }}>
          <div style={{ color: 'white', marginTop: '10px', textAlign: 'center' }}>
            {`© Stryker ${new Date().getFullYear()} | EIM Version: ${getEnvironment()} - ${process
              .env.REACT_APP_VERSION || '0.0.1'}`}
          </div>
        </footer>
      </div>
    </IconSettings>
  );
};

/** Custom Footer component */
export default CustomFooter;
