/* eslint-disable no-console */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useCallback, useEffect } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Textarea from '@salesforce/design-system-react/components/textarea';
import Icon from '@salesforce/design-system-react/components/icon';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { GET_SALES_REPS } from '../../../../graphql/getSalesReps';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import { GET_SAS_URI } from '../../../../graphql/getSasUri';
import { GET_USER_TERRITORIES } from '../../../../graphql/getUserTerritories';
import { GET_ALL_SALES_REPS } from '../../../../graphql/getAllSalesReps';
import DELETE_ATTACHMENT from '../../../../graphql/mutations/deleteAttachment';
import UPDATE_ATTACHMENT from '../../../../graphql/mutations/inventoryReturnAttachments';
import useBlobFileService, { BlobFile } from '../../../../util/customHooks/useBlobFileService';
import { UPDATE_TRANSFER_INVENTORY } from '../../../../graphql/mutations/updateTransferInventory';
import { MAX_QUANTITY, NOTIFY_TYPE, OPSCSRUSERS } from '../../../../util/constants';
import useSnackBar from '../../../../util/customHooks/useSnackBar';

interface Item {
  hospital?: string;
  id: string;
  owned?: string;
  value: string;
  subInventoryCode?: string;
}

interface File {
  manual?: boolean;
  imageurl: string;
  name: string;
}

interface Attachment {
  manual?: boolean;
  id?: string;
  imageurl: string;
  name: string;
}
interface SelectedFilters {
  salesRep: SelectedData[];
}
interface SelectedData {
  id: string;
  label: string;
}
enum DetailTypes {
  salesRep = 'SalesRep',
  userTerritory = 'UserTerritory',
}
interface CommentItem {
  id?: string;
  sfid?: string;
  createdDate?: string;
  userName?: string;
  comment: string;
  postTime: string;
  commentType: string;
  commentExternalId?: string;
  userId: string | null;
}

interface Props {
  requestTransferModalVisible: boolean;
  tranferDetails?: any;
  formdata?: any;
  handleRequestTransferModal: () => void;
  handleRefetchItems: () => void;
}

interface DropdownSelect {
  id: string;
  label?: string;
  value: string;
}

const RequestTransfer: FC<Props> = ({
  requestTransferModalVisible,
  tranferDetails,
  formdata,
  handleRequestTransferModal,
  handleRefetchItems,
}) => {
  const { open, notification, openSnackBar } = useSnackBar();
  const { data: sasUriData } = useQuery(GET_SAS_URI);
  const userInfo = useSelector(getUserInfo);
  const [getTerritories, { data: territoryData, loading: territoryloading }] = useLazyQuery(
    GET_USER_TERRITORIES
  );
  const [
    getRecTerritories,
    { data: territoryRecData, loading: territoryRecloading },
  ] = useLazyQuery(GET_USER_TERRITORIES);
  const isUserOpsCsr = !!OPSCSRUSERS?.find((rec: any) => rec?.value === userInfo?.personas);
  const [salesRepValue, setSalesRepValue] = useState<any>();
  const [loading, setLoader] = useState(false);
  const { uploadFileToBlobStorage } = useBlobFileService(sasUriData?.getSasUri);
  const [uploadedFiles, setUploadFiles] = useState<File[]>(tranferDetails?.attachments);
  const [recivingSalesRepValue, setRecivingSalesRepValue] = useState<any>();
  const [territoryValue, setTerritoryValue] = useState<any>();
  const [recivingTerritoryValue, setRecivingTerritoryValue] = useState<any>();
  const [commentValue, setCommentValue] = useState<CommentItem[]>([]);
  const [saveButton, setSaveButton] = useState(true);
  // const [attachment, setAttachments] = useState<Image[]>([]);
  const [commentsRequired, setCommentsRequired] = useState(false);
  const [attachmentsRequired, setAttachmentsRequired] = useState(false);
  const [updateTransferInventory] = useMutation(UPDATE_TRANSFER_INVENTORY);
  const [updateTransferAttachment] = useMutation(UPDATE_ATTACHMENT);
  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT);

  const optionsWithLabel = (value: any): object =>
    value &&
    // eslint-disable-next-line consistent-return
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === 'OPSList') {
        return { ...elem, label: `${elem.value}` };
      }
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === DetailTypes.salesRep) {
        return { ...elem, label: `${elem.value}` };
      }
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === DetailTypes.userTerritory) {
        return { ...elem, label: `${elem.value}` };
      }
    });

  // USELAZYQUERY
  useEffect(() => {
    if (salesRepValue && salesRepValue[0]?.id) {
      getTerritories({
        variables: {
          userId: salesRepValue[0]?.id,
        },
      });
    }
  }, [getTerritories, salesRepValue]);

  const [getSalesRepsItems, { data: getAllSalesReps, loading: loadingSalesRep }] = useLazyQuery(
    GET_ALL_SALES_REPS
  );
  const [getSalesRep, { data: getSalesReps, loading: loadingAllSalesRep }] = useLazyQuery(
    GET_SALES_REPS
  );
  const [salesRepOptions, setSalesRepOptions] = useState<any>();
  const [salesRepAllOptions, setAllSalesRepOptions] = useState<any>();
  const salesRepsDataOptions = optionsWithLabel(getAllSalesReps?.getAllSalesReps);
  const salesRepsData = optionsWithLabel(getSalesReps?.getSalesReps);
  const territoryOptions = optionsWithLabel(territoryData?.getUserTerritories);
  const territoryRecOptions = optionsWithLabel(territoryRecData?.getUserTerritories);

  useEffect(() => {
    if (getSalesReps?.getSalesReps) {
      setSalesRepOptions(optionsWithLabel(getSalesReps?.getSalesReps));
    }
    if (getAllSalesReps?.getAllSalesReps) {
      setAllSalesRepOptions(optionsWithLabel(getAllSalesReps?.getAllSalesReps));
    }
  }, [getSalesReps, getAllSalesReps]);

  useEffect(() => {
    if (tranferDetails && tranferDetails?.salesRepId) {
      getTerritories({
        variables: {
          userId: tranferDetails?.salesRepId,
        },
      });
      const filteredSalesRep =
        Array.isArray(salesRepsData) &&
        salesRepsData.filter((item: Item) => item.id === tranferDetails?.salesRepId);
      if (filteredSalesRep) {
        setSalesRepValue(filteredSalesRep);
      }
    }
    if (tranferDetails && tranferDetails?.fromSubInventoryCode) {
      const filteredSalesRep =
        Array.isArray(territoryOptions) &&
        territoryOptions.filter(
          (item: Item) => item.subInventoryCode === tranferDetails?.fromSubInventoryCode
        );
      if (filteredSalesRep) {
        setTerritoryValue(filteredSalesRep);
      }
    }
    if (tranferDetails && tranferDetails?.receivingRepId) {
      getRecTerritories({
        variables: {
          userId: tranferDetails?.receivingRepId,
        },
      });
      const filteredSalesRep =
        Array.isArray(salesRepsDataOptions) &&
        salesRepsDataOptions.filter((item: Item) => item.id === tranferDetails?.receivingRepId);
      if (filteredSalesRep) {
        setRecivingSalesRepValue(filteredSalesRep);
      }
    }
    if (tranferDetails && tranferDetails?.toSubInventoryCode) {
      const filteredSalesRep =
        Array.isArray(territoryRecOptions) &&
        territoryRecOptions.filter(
          (item: Item) => item.subInventoryCode === tranferDetails?.toSubInventoryCode
        );
      if (filteredSalesRep) {
        setRecivingTerritoryValue(filteredSalesRep);
      }
    }
    if (tranferDetails && tranferDetails?.comments && tranferDetails?.comments.length > 0) {
      setCommentValue(tranferDetails?.comments[tranferDetails?.comments.length - 1].value);
    }
  }, [getSalesRepsItems, getAllSalesReps]);

  // useEffect(() => {
  //   if (territoryRecOptions && Array.isArray(territoryRecOptions)) {
  //     if (territoryRecOptions.length === 1) {
  //       setRecivingTerritoryValue(territoryRecOptions);
  //       setSaveButton(false);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getRecTerritories, territoryRecData]);
  useEffect(() => {
    if (commentsRequired && attachmentsRequired) {
      if (commentValue?.length > 0 && uploadedFiles?.length > 0 && recivingTerritoryValue) {
        setSaveButton(false);
      }
    } else if (commentsRequired || attachmentsRequired) {
      if (commentsRequired && commentValue?.length > 0) {
        setSaveButton(false);
      }
      if (attachmentsRequired) {
        if (attachmentsRequired) {
          setSaveButton(false);
        }
      }
    } else if (!commentsRequired && !attachmentsRequired) {
      if (recivingTerritoryValue) {
        setSaveButton(false);
      }
    } else {
      setSaveButton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRecTerritories, territoryRecData, recivingTerritoryValue, commentValue, uploadedFiles]);
  useEffect(() => {
    if (territoryOptions && Array.isArray(territoryOptions)) {
      if (territoryOptions?.length === 1) {
        setTerritoryValue(territoryOptions);
      } else {
        setTerritoryValue([]);
      }
    }
    if (territoryRecOptions && Array.isArray(territoryRecOptions)) {
      if (territoryRecOptions.length === 1) {
        setRecivingTerritoryValue(territoryRecOptions);
      } else {
        setRecivingTerritoryValue([]);
      }
    }
    if (
      formdata?.getFormConfigurations?.repTransfer[0]?.fields[4]?.fieldLabel === 'Comments' &&
      formdata?.getFormConfigurations?.repTransfer[0]?.fields[4]?.required === true
    ) {
      setCommentsRequired(true);
    } else {
      setCommentsRequired(false);
    }
    if (
      formdata?.getFormConfigurations?.repTransfer[0]?.fields[3]?.fieldLabel === 'Attachments' &&
      formdata?.getFormConfigurations?.repTransfer[0]?.fields[3]?.required === true
    ) {
      setAttachmentsRequired(true);
    } else {
      setAttachmentsRequired(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRecTerritories, territoryRecData, getTerritories, territoryData, formdata]);

  const fetchItems = useCallback(() => {
    getSalesRepsItems();
    getSalesRep();
  }, [getSalesRepsItems, getSalesRep]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const handleUploadFiles = async (event: any) => {
    const { files } = event;
    const file = files[0];
    setLoader(true);
    const response: BlobFile = await uploadFileToBlobStorage(file);
    if (response) {
      const fileToUpload = {
        manual: true,
        name: response?.blobName || file.name,
        imageurl: response?.imageurl.split('?')[0] || '',
      };
      const array = [...uploadedFiles, fileToUpload];
      setUploadFiles(array);
      setLoader(false);
      setSaveButton(false);
    }
  };

  const handleChange = (event: any): void => {
    // eslint-disable-next-line no-console
    if (handleUploadFiles) {
      handleUploadFiles(event.target);
    }
  };

  const handleAttachmentsView = (file: File): void => {
    const url = `${file.imageurl.split('?')[0]}?${sasUriData?.getSasUri.sasUri}`;
    window.open(url, '_blank');
  };

  const handleDeleteAttachments = (file: Attachment): void => {
    setLoader(true);
    setSaveButton(false);
    if (file.manual) {
      const filteredData = uploadedFiles.filter(
        (item: any) => JSON.stringify(item) !== JSON.stringify(file)
      );
      setUploadFiles(filteredData);
    } else {
      deleteAttachment({ variables: { id: file?.id } }).then(() => {
        const filteredData = uploadedFiles.filter(
          (item: any) => JSON.stringify(item) !== JSON.stringify(file)
        );
        setUploadFiles(filteredData);
        setLoader(false);
      });
    }
  };

  const handleSaveButton = useCallback(() => {
    const date = format(new Date(), 'yyyy-MM-dd hh:mm a').replace(' ', 'T');
    let uploadAttachments: any = uploadedFiles.filter((item: File) => item.manual);
    uploadAttachments = uploadAttachments.map((e: any) => ({ name: e.name, imageurl: e.imageurl }));
    const attachments = [...uploadAttachments];
    let mutation: any = {};
    let attachmentMutation: any = {
      orderId: tranferDetails && tranferDetails?.id,
      orderExternalId: tranferDetails && tranferDetails?.externalId,
    };
    mutation = {
      orderId: tranferDetails && tranferDetails?.id,
      orderExternalId: tranferDetails && tranferDetails?.externalId,
      salesRepId: salesRepValue ? salesRepValue[0].id : '',
      receivingRepId: recivingSalesRepValue ? recivingSalesRepValue[0].id : '',
      fromSubInventoryCode: territoryValue ? territoryValue[0].subInventoryCode : '',
      toSubInventoryCode: recivingTerritoryValue ? recivingTerritoryValue[0].subInventoryCode : '',
      accountId: salesRepValue ? salesRepValue[0].hospital : '',
      salesOrganization: 'F48',
      lineItems: [],
    };
    if (attachments && attachments.length > 0) {
      mutation = { ...mutation, attachments };
    }
    if (commentValue && commentValue.length > 0) {
      const comments = {
        comment: commentValue,
        postTime: date,
        commentType: 'Internal',
      };
      mutation = { ...mutation, comments };
    }
    if (tranferDetails && tranferDetails.comments.length > 0) {
      mutation = {
        ...mutation,
        comments: {
          externalId: tranferDetails?.comments[tranferDetails?.comments.length - 1].externalId,
          comment: commentValue,
          postTime: date,
          commentType: 'Internal',
        },
      };
    }
    if (attachments && attachments.length > 0) {
      attachmentMutation = { ...attachmentMutation, attachments };
    }
    updateTransferAttachment({
      variables: attachmentMutation,
    }).then(response => {
      if (
        response &&
        response?.data &&
        response?.data?.inventoryReturnAttachments &&
        response?.data?.inventoryReturnAttachments?.message === 'success' &&
        response?.data?.inventoryReturnAttachments?.externalId !== ''
      ) {
        setSaveButton(false);
      } else {
        console.log('We had a trouble saving data to salesforce. Please try again later');
      }
    });
    mutation = {
      ...mutation,
      system: 'Web',
      version: process.env.REACT_APP_VERSION || '0.0.1',
    };
    updateTransferInventory({
      variables: mutation,
    })
      .then(response => {
        if (
          response &&
          response?.data &&
          response?.data?.updateTransferInventory &&
          response?.data?.updateTransferInventory?.message === 'success' &&
          response?.data?.updateTransferInventory?.externalId !== ''
        ) {
          handleRefetchItems();
        } else {
          console.log('We had a trouble saving data to salesforce. Please try again later');
        }
      })
      .catch(error => {
        setLoader(false);
        setTimeout(() => {
          if (error && error.message) {
            setLoader(false);
            openSnackBar(NOTIFY_TYPE.ERROR, `${error.message}`);
          } else {
            openSnackBar(
              NOTIFY_TYPE.ERROR,
              'Error: We had a trouble saving data to salesforce. Please try again later'
            );
          }
        }, 1000);
      });
  }, [
    // eslint-disable-line react-hooks/exhaustive-deps
    updateTransferInventory,
    salesRepValue,
    recivingSalesRepValue,
    territoryValue,
    handleRefetchItems,
    recivingTerritoryValue,
    uploadedFiles,
    tranferDetails,
    commentValue,
    updateTransferAttachment,
  ]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleSelect = useCallback((data: any, name: string) => {
    const { selection } = data;
    if (name === 'salesRep') {
      setSalesRepValue(selection);
      setSaveButton(false);
    } else if (name === 'receivingRep') {
      if (selection && selection[0]?.id) {
        getRecTerritories({
          variables: {
            userId: selection[0]?.id,
          },
        });
      }
      setRecivingSalesRepValue(selection);
      setSaveButton(false);
    } else if (name === 'territory') {
      setTerritoryValue(selection);
      setSaveButton(false);
    } else if (name === 'receivingTerritory') {
      setRecivingTerritoryValue(selection);
      setSaveButton(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCommentChange = (event: any): void => {
    event.persist();
    setSaveButton(false);
    const textValue = event.target.value;
    const blankSpaceOnly = textValue ? !textValue.replace(/\s/g, '').length : true;
    if (!blankSpaceOnly) {
      setCommentValue(event.target.value);
    } else {
      setCommentValue([]);
    }
  };

  // const seenResults: Record<string, boolean> = {};
  // const results =
  //   Array.isArray(salesRepsDataOptions) &&
  //   Array.isArray(salesRepsData) &&
  //   salesRepsDataOptions.filter(i => {
  //     if (Object.prototype.hasOwnProperty.call(seenResults, i.id)) {
  //       return false;
  //     }
  //     seenResults[i.id] = true;
  //     return true;
  //   });

  const [recRep, setRecRepList] = useState<any>();
  useEffect(() => {
    if (salesRepValue && salesRepValue?.length > 0) {
      const filterRecRep =
        Array.isArray(salesRepsDataOptions) &&
        salesRepsDataOptions?.filter(
          (data: any) => data?.subInventoryCode !== salesRepValue[0]?.subInventoryCode
        );
      console.log(filterRecRep);
      setRecRepList(filterRecRep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesRepValue]);

  return (
    <IconSettings iconPath="/icons">
      <div>
        <Modal
          className="clone-option_modal default-modal_header default-modal_footer"
          isOpen={requestTransferModalVisible}
          footer={[
            <Button
              label="Cancel"
              key="cancel"
              className="slds-float_left slds-text-color_default"
              onClick={handleRequestTransferModal}
            />,
            <Button
              label="Save"
              variant="brand"
              onClick={handleSaveButton}
              disabled={
                !(
                  salesRepValue?.length > 0 &&
                  territoryValue?.length > 0 &&
                  recivingSalesRepValue?.length > 0 &&
                  recivingTerritoryValue?.length > 0
                ) || saveButton
              }
              key="save_transfer"
              className="slds-float_right"
            />,
          ]}
          onRequestClose={handleRequestTransferModal}
          heading="Edit Transfer"
        >
          <div>
            <div className="clone-option-checkbox slds-m-horizontal--medium">
              <div className="slds-form__item custom_label-hide" role="listitem">
                <div className="slds-form-element slds-form-element_edit  slds-form-element_stacked slds-hint-parent">
                  <div
                    className={
                      isUserOpsCsr &&
                      tranferDetails &&
                      tranferDetails?.visualIndicator === 'Approved'
                        ? 'slds-form-element slds-m-bottom_smal disabled-input'
                        : 'slds-form-element__control'
                    }
                  >
                    <Combobox
                      id="salesRep"
                      events={{
                        onSelect: (event: any, data: any): void => handleSelect(data, 'salesRep'),
                        onRequestRemoveSelectedOption: (event: any, data: any): void =>
                          handleSelect(data, 'salesRep'),
                      }}
                      labels={{
                        label: `Sales Rep`,
                        placeholder: 'Select',
                        noOptionsFound: 'No data found',
                      }}
                      placeholder="Select"
                      options={salesRepsData || []}
                      hasInputSpinner={loadingSalesRep}
                      selection={salesRepValue}
                      variant="readonly"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="slds-form__item custom_label-hide" role="listitem">
                <div className="slds-form-element slds-form-element_edit  slds-form-element_stacked slds-hint-parent">
                  <div
                    className={
                      isUserOpsCsr &&
                      tranferDetails &&
                      tranferDetails?.visualIndicator === 'Approved'
                        ? 'slds-form-element slds-m-bottom_smal disabled-input'
                        : 'slds-form-element__control'
                    }
                  >
                    <Combobox
                      id="terrtorySalesRep"
                      events={{
                        onSelect: (event: any, data: any): void => handleSelect(data, 'territory'),
                        onRequestRemoveSelectedOption: (event: any, data: any): void =>
                          handleSelect(data, 'territory'),
                      }}
                      labels={{
                        label: `Territory`,
                        placeholder: 'Select',
                        noOptionsFound: 'No data found',
                      }}
                      placeholder="Select"
                      options={territoryOptions || []}
                      hasInputSpinner={loadingSalesRep}
                      selection={territoryValue}
                      variant="readonly"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="slds-form__item custom_label-hide" role="listitem">
                <div className="slds-form-element slds-form-element_edit  slds-form-element_stacked slds-hint-parent">
                  <div
                    className={
                      isUserOpsCsr &&
                      tranferDetails &&
                      tranferDetails?.visualIndicator === 'Approved'
                        ? 'slds-form-element slds-m-bottom_smal disabled-input'
                        : 'slds-form-element__control'
                    }
                  >
                    <Combobox
                      id="recevingRep"
                      events={{
                        onSelect: (event: any, data: any): void =>
                          handleSelect(data, 'receivingRep'),
                        onRequestRemoveSelectedOption: (event: any, data: any): void =>
                          handleSelect(data, 'receivingRep'),
                      }}
                      labels={{
                        label: `Receiving Rep`,
                        placeholder: 'Select',
                        noOptionsFound: 'No data found',
                      }}
                      placeholder="Select"
                      options={recRep || []}
                      hasInputSpinner={loadingSalesRep}
                      selection={recivingSalesRepValue}
                      variant="readonly"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="slds-form__item custom_label-hide" role="listitem">
                <div className="slds-form-element slds-form-element_edit  slds-form-element_stacked slds-hint-parent">
                  <div
                    className={
                      isUserOpsCsr &&
                      tranferDetails &&
                      tranferDetails?.visualIndicator === 'Approved'
                        ? 'slds-form-element slds-m-bottom_smal disabled-input'
                        : 'slds-form-element__control'
                    }
                  >
                    <Combobox
                      id="terrtoryRecevingRep"
                      events={{
                        onSelect: (event: any, data: any): void =>
                          handleSelect(data, 'receivingTerritory'),
                        onRequestRemoveSelectedOption: (event: any, data: any): void =>
                          handleSelect(data, 'receivingTerritory'),
                      }}
                      labels={{
                        label: `Territory`,
                        placeholder: 'Select',
                        noOptionsFound: 'No data found',
                      }}
                      placeholder="Select"
                      options={territoryRecOptions || []}
                      hasInputSpinner={loadingSalesRep}
                      selection={recivingTerritoryValue}
                      variant="readonly"
                      required
                    />
                  </div>
                </div>
              </div>
              {formdata?.getFormConfigurations?.repTransfer[0]?.fields[3]?.fieldLabel ===
                'Attachments' &&
                formdata?.getFormConfigurations?.repTransfer[0]?.fields[3]?.isShow === true && (
                  <>
                    <div className="slds-form__item custom_label-hide" role="listitem">
                      <div className="slds-form-element slds-form-element_edit  slds-form-element_stacked slds-hint-parent">
                        <div className="slds-form-element">
                          <span style={{ color: '#c23934', margin: '0 0.125rem' }}>
                            {formdata?.getFormConfigurations?.repTransfer[0]?.fields[3]?.required
                              ? '*'
                              : ''}
                          </span>
                          <span
                            className="slds-form-element__label"
                            id="file-selector-primary-label"
                          >
                            Attachments
                          </span>
                          <div className="slds-form-element__control">
                            <div className="slds-size_3-of-3 slds-box">
                              <div className="slds-file-selector slds-file-selector_files slds-align_absolute-center">
                                <input
                                  type="file"
                                  className="slds-file-selector__input slds-assistive-text"
                                  accept="image/png"
                                  id="file-upload-input-01"
                                  aria-labelledby="file-selector-primary-label file-selector-secondary-label"
                                  onChange={handleChange}
                                />
                                {loading ? (
                                  <Spinner
                                    size="small"
                                    variant="base"
                                    assistiveText={{ label: 'Loading...' }}
                                    key="spinner"
                                  />
                                ) : (
                                  <label
                                    className="slds-file-selector__body"
                                    htmlFor="file-upload-input-01"
                                    id="file-selector-secondary-label"
                                  >
                                    <span className="slds-file-selector__button slds-button slds-button_neutral">
                                      <div className="uploadButton">
                                        <Icon category="utility" name="upload" size="x-small" />
                                      </div>

                                      <span className="slds-p-left_small slds-p-top_xxx-small">
                                        Browse...
                                      </span>
                                    </span>
                                  </label>
                                )}
                              </div>
                              {uploadedFiles?.length ? (
                                <>
                                  <div style={{ fontWeight: 'bold' }}>Added Attachments</div>
                                  <div>
                                    {uploadedFiles?.map(file => (
                                      <>
                                        <div
                                          style={{
                                            cursor: 'pointer',
                                            float: 'left',
                                            marginRight: '10px',
                                          }}
                                          onClick={(): void => handleAttachmentsView(file)}
                                          aria-hidden="true"
                                        >
                                          {file.name}
                                        </div>
                                        <div
                                          style={{ cursor: 'pointer' }}
                                          onClick={(): void => handleDeleteAttachments(file)}
                                          aria-hidden="true"
                                        >
                                          <Icon
                                            style={{ fill: 'red' }}
                                            category="utility"
                                            name="delete"
                                            size="x-small"
                                          />
                                        </div>
                                      </>
                                    ))}
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              {formdata?.getFormConfigurations?.repTransfer[0]?.fields[4]?.fieldLabel ===
                'Comments' &&
                formdata?.getFormConfigurations?.repTransfer[0]?.fields[4]?.isShow === true && (
                  <>
                    <div className="slds-form__item custom_label-hide" role="listitem">
                      <div className="slds-form-element slds-form-element_edit  slds-form-element_stacked slds-hint-parent">
                        <div className="slds-form-element slds-m-bottom_small" key="comments">
                          <div className="slds-form-element__label">
                            <span style={{ color: '#c23934', margin: '0 0.125rem' }}>
                              {formdata?.getFormConfigurations?.repTransfer[0]?.fields[4]?.required
                                ? '*'
                                : ''}
                            </span>
                            <span style={{ color: '#3e3e3c' }}>Comments</span>
                          </div>
                          <Textarea
                            id="requestTransferComments"
                            maxLength="500"
                            onChange={handleCommentChange}
                            value={commentValue || ''}
                            disabled={false}
                          />
                          <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <span
                              style={{ color: '#3e3e3c', fontSize: '12px' }}
                            >{`${commentValue.length}/32000`}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
          <section>
            {(loadingAllSalesRep || loadingSalesRep || territoryloading || territoryRecloading) && (
              <div className="slds-p-around_small customised-button-list">
                <Spinner size="large" variant="base" assistiveText={{ label: 'Loading...' }} />
              </div>
            )}
          </section>
        </Modal>
      </div>
    </IconSettings>
  );
};

/** Clone Event component */
export default RequestTransfer;
