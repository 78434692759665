/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_USER_TBA_ACCOUNTS = gql`
  query getUserTBAAccounts($salesRepId: String, $filters: TBAAccountFilters) {
    getUserTBAAccounts(salesRepId: $salesRepId, filters: $filters) {
      id
      value: name
      number
      branchId
    }
  }
`;
