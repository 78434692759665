/**
 * Graphql mutation to cancel event
 * @module src/graphql/cancelInventoryReturns
 */
import gql from 'graphql-tag';

/** creating event */
const CANCEL_INVENTORY_RETURNS = gql`
  mutation cancelInventoryReturns($externalId: String!) {
    cancelInventoryReturns(externalId: $externalId) {
      message
    }
  }
`;

export default CANCEL_INVENTORY_RETURNS;
