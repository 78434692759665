/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
/**
 * Modal for Filter event
 * @module src/components/FilterEvent/index
 */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useState, useCallback, useEffect, useMemo } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import Button from '@salesforce/design-system-react/components/button';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Pill from '@salesforce/design-system-react/components/pill';
import Icon from '@salesforce/design-system-react/components/icon';
import RadioGroup from '@salesforce/design-system-react/components/radio-group';
import Radio from '@salesforce/design-system-react/components/radio-group/radio';

import './index.scss';
import DatePicker from '../Shared/DatePicker';
import { getUserId } from '../../store/ducks/userId';
import { getUserInfo } from '../../store/ducks/userInfo';
import { GET_FILTERED_PROCEDURE_DETAILS } from '../../graphql/getFilteredProcedureDetails';
import { GET_FILTERED_FRANCHISES } from '../../graphql/getFilteredFranchises';
import { GET_FILTERED_USER_BRANCHES } from '../../graphql/getFilteredUserBranches';
import { GET_USER_TERRITORIES } from '../../graphql/getUserTerritories';
import { GET_FILTERED_SALES_REPS } from '../../graphql/getFilteredSalesReps';
import { GET_COVERING_REPS } from '../../graphql/getCoveringReps';
import { GET_SURGEONS } from '../../graphql/getSurgeons';
import { GET_OPPORTUNITIES } from '../../graphql/getOpportunities';
import {
  REGION,
  DATE_VIEW_FORMATTER,
  FILTER_EVENT_STATUS,
  eventTypesArr,
  EVENTS_TYPE_OPTIONS,
  DAYS_AT_EVENTS_OPTIONS,
  accountTypesArr,
  MOCK_OR_EVENT_TYPE,
  FILTER_EVENT_TYPES,
} from '../../util/constants';
import { GET_ACCOUNTS } from '../../graphql/getAccounts';
import { GET_PRODUCT_LIST } from '../../graphql/getProductList';
import { GET_WORKFLOW_PERMISSION } from '../../graphql/workflowPermissions';
import CREATE_FILTER_PRESET from '../../graphql/mutations/createFilterPreset';
import SaveFilter from './SaveFilter';
import UPDATE_FILTER_PRESET from '../../graphql/mutations/updateFilterPreset';
import DeleteFilterPreset from './DeleteFilterPreset';
import DELETE_FILTER_PRESET from '../../graphql/mutations/deleteFilterPreset';
import AutocompleteSingleSelectPickerWithSearch from '../Shared/AutocompleteSingleSelectPickerWithSearch';

import AutocompleteSingleSelectPicker from '../Shared/AutocompleteSingleSelectPicker';
import { GET_FILTERED_PROCEDURE_TYPES } from '../../graphql/getFilteredProcedureTypes';
import { GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS } from '../../graphql/getSubInventoryWithLotDetails';
import { GET_USER_TBA_ACCOUNTS } from '../../graphql/getUserTBAAccounts';

interface Props {
  handleToggleFilterEvent: () => void;
  isShowEventExposure?: boolean;
  filterEventModalVisible: boolean;
  selectedFilter?: FilterPreset | DynamicType | null;
  refetchFilterPresets: () => void;
  handleFilterEvents: (filter: DynamicType | null) => void;
  resetFilters: () => void;
  handleEditFilter: (editEvent: boolean) => void;
  isEditFilter: boolean;
  handleClearFilter: (externalId: string) => void;
}

interface UsageData {
  id: string;
  label: string;
}
interface ValueInterface {
  value: string;
}
interface SelectedData {
  id: string;
  label: string;
}

interface DynamicType {
  [key: string]: any;
}

interface FilterPreset {
  filterValues: SelectedFilters;
  filterExternalId?: string;
  filterName?: string;
}
interface DropdownSelect {
  id: string;
  label?: string;
  value: string;
}

interface SelectedFilters {
  status: SelectedData[];
  salesReps: SelectedData[];
  coveringReps: SelectedData[];
  account: SelectedData[];
  branch: SelectedData[];
  franchise: SelectedData[];
  surgeon: SelectedData[];
  procedureDetail: SelectedData[];
  procedureType?: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  usage: string;
  event: string;
  opportunity: SelectedData[];
  eventType: string[];
} 
interface IEvents {
  name: string;
  label: string;
}

enum DetailTypes {
  Account = 'Account',
  SalesRep = 'SalesRep',
  ProcedureDetail = 'ProcedureDetail',
  CoveringRep = 'CoveringRep',
  Surgeon = 'Surgeon',
  userTerritory = 'UserTerritory',
  TBAAccount = 'TBAAccount',
  OpportunitiesList = 'OpportunitiesList',
}

const FilterEvent: FC<Props> = ({
  handleToggleFilterEvent,
  isShowEventExposure,
  filterEventModalVisible,
  selectedFilter,
  refetchFilterPresets,
  handleFilterEvents,
  resetFilters,
  handleEditFilter,
  isEditFilter,
  handleClearFilter,
}) => {
  const filterValues = selectedFilter?.filterValues;
  const filterName = selectedFilter ? selectedFilter.filterName : '';
  const filterExternalId = selectedFilter ? selectedFilter.filterExternalId : null;
  const selectedEventTypeData = filterValues ? filterValues.eventType : [];
  const selectedEventData = filterValues ? filterValues.event : [];
  const usages = filterValues ? filterValues.usage : '';
  const selectedAccountType = filterValues ? filterValues.account : null;
  // useState
  const [selectedUsage, setSelectedUsage] = useState(usages || '');
  const [selectedEventTypes, setSelectedEventTypes] = useState<string[]>(
    selectedEventTypeData || []
  );
  const [selectedEvent, setSelectedEvent] = useState<string[]>(selectedEventData || []);
  const [territoryOptions, setTerritoryOptions] = useState<any[]>();
  // const [getTerritories, { data: territoryData }] = useLazyQuery(GET_USER_TERRITORIES);
  const [productListItems, { data: productItems }] = useLazyQuery(GET_PRODUCT_LIST);
  const [
    getSubInventoryWithLotDetails,
    { data: subInventoryWithLotDetails, loading: loadingSubInventory },
  ] = useLazyQuery(GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS);
  const userInfo = useSelector(getUserInfo);
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [territory, setTerritory] = useState<any>(null);
  const [inputValue, setInputValue] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<DynamicType>(filterValues || {}); // For Selected Value
  const getEventValue = () => {
    const eventsEntry: IEvents[] = eventTypesArr.filter(
      rec => filterValues && filterValues[rec.name]
    );
    if (eventsEntry.length) {
      return eventsEntry[0].label;
    }
    return '';
  };
  const eventValue = getEventValue();
  const [checked, setChecked] = eventValue ? useState(eventValue) : useState('');
  // const [accountChecked, setAccountChecked] = useState();
  const userId = useSelector(getUserId);
  // const userInfo = useSelector(getUserInfo);

  // Mutation & Queries
  const [getTerritories, { data: territoryData }] = useLazyQuery(GET_USER_TERRITORIES);
  const [getUserBranches, { data: filteredBranches }] = useLazyQuery(GET_FILTERED_USER_BRANCHES);
  const [createFilterPreset, { data: filterPresets }] = useMutation(CREATE_FILTER_PRESET);
  const [updateFilterPreset, { data: updatedfilterPresets }] = useMutation(UPDATE_FILTER_PRESET);
  const [deleteFilterPreset] = useMutation(DELETE_FILTER_PRESET);

  // Permission set queries
  const { data: enableSubmittedFilter } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'filter',
      action: 'enable_submitted',
    },
  });
  const { data: enablePendingFilter } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'filter',
      action: 'enable_pending',
    },
  });
  const { data: enableWorkshopFilter } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'filter',
      action: 'enable_workshop',
    },
  });
  const { data: enableCadavarFilter } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'filter',
      action: 'enable_workshop_cadaver_lab',
    },
  });
  const { data: enableCongressFilter } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'filter',
      action: 'enable_workshop_stryker_congress',
    },
  });
  const { data: enableMarketingFilter } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'filter',
      action: 'enable_workshop_marketing',
    },
  });
  const { data: enableOtherFilter } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'EU',
      workflow: 'filter',
      action: 'enable_workshop_others',
    },
  });

  const [getAccount, { data: filteredAccounts, loading: loadingAccounts }] = useLazyQuery(
    GET_ACCOUNTS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        userId,
        region: REGION,
        filters: {
          searchText: '',
        },
        salesRepId: '',
        limit: 0,
      },
    }
  );

  useEffect(() => {
    productListItems({
      variables: {
        filters: {
          businessUnit: [userInfo?.businessunit] || ['Sports Med'],
          userId:
            selectedFilters && selectedFilters.salesReps
              ? selectedFilters.salesReps[0]?.id
              : userInfo?.id,
        },
        salesRepId:
          selectedFilters && selectedFilters.salesReps
            ? selectedFilters.salesReps[0]?.id
            : userInfo?.id,
      },
    });
  }, [productListItems, selectedFilters]);

  useEffect(() => {
    getSubInventoryWithLotDetails({
      variables: {
        salesRepId: userInfo?.id || '',
        filters: {},
      },
    });
    getAccount({
      variables: {
        userId,
        region: REGION,
        filters: {
          searchText: '',
        },
        salesRepId: '',
        limit: 0,
      },
    });
  }, [getSubInventoryWithLotDetails]);

  useEffect(() => {
    if (selectedFilters?.salesReps) {
      const salesRepIds: String[] = [];
      selectedFilters?.salesReps.map((salesRep: any) => {
        salesRepIds.push(salesRep.id);
      });
      getCoveringReps({
        variables: {
          salesRepId: salesRepIds,
        },
      });
    } else {
      getCoveringReps({
        variables: {
          salesRepId: userInfo?.id || '',
        },
      });
    }
  }, [selectedFilters?.salesReps]);

  // const { data: filteredProcedureDetails } = useQuery(GET_FILTERED_PROCEDURE_DETAILS);

  // const { data: filteredFranchises, loading: loadingFranchises } = useQuery(
  //   GET_FILTERED_FRANCHISES,
  //   {
  //     variables: {
  //       userId,
  //     },
  //   }
  // );

  const { data: tbaAccountsItems } = useQuery(GET_USER_TBA_ACCOUNTS, {
    fetchPolicy: 'no-cache',
  });

  const tbaAccountsData = tbaAccountsItems?.getUserTBAAccounts;
  const { data: filteredSalesReps, loading: loadingSalesReps } = useQuery(GET_FILTERED_SALES_REPS, {
    variables: {
      userId,
      region: REGION,
    },
  });

  const [
    getCoveringReps,
    { data: filteredCoveringReps, loading: loadingCoveringReps },
  ] = useLazyQuery(
    GET_COVERING_REPS
    // {
    //   variables: {
    //     region: REGION,
    //     // "accountId": "001i000000pBHguAAG",
    //   },
    // }
  );

  const [getSurgeonsData, { data: filteredSurgeons, loading: loadingSurgeons }] = useLazyQuery(
    GET_SURGEONS
  );

  const [
    getOpportunitiesItem,
    { data: getOpportunities, loading: loadingOpportunities },
  ] = useLazyQuery(GET_OPPORTUNITIES);
  useEffect(() => {
    if (userId) {
      getTerritories({
        variables: {
          userId,
        },
      });
    }
    getOpportunitiesItem({
      variables: {},
    });
  }, [getTerritories, userId]);
  const { data: filteredProcedureTypes } = useQuery(GET_FILTERED_PROCEDURE_TYPES);

  const getApiDateTime = useCallback((eventDate: any, name: string): string => {
    const formattedSelectedDate = new Date(eventDate);
    let updatedDate;

    if (name === 'endDate') {
      updatedDate = new Date(formattedSelectedDate).setHours(23);
      updatedDate = new Date(updatedDate).setMinutes(59);
      updatedDate = new Date(updatedDate).setSeconds(59);
    } else {
      updatedDate = new Date(formattedSelectedDate).setHours(0);
      updatedDate = new Date(updatedDate).setMinutes(0);
      updatedDate = new Date(updatedDate).setSeconds(0);
    }

    const finalDate = format(new Date(updatedDate), DATE_VIEW_FORMATTER);
    return finalDate;
  }, []);

  /* useEffect(() => {
    if (filterValues && filterValues.accountType) {
      if (filterValues?.accountType === 'My Accounts') {
        setAccountChecked('My Accounts');
      } else {
        setAccountChecked('TBA Accounts');
      }
    } else {
      setAccountChecked('My Accounts');
    }
    setFilterValue('accountType', filterValues?.accountType || 'My Accounts');
    const accountDefault = Object.assign(selectedFilters, {
      accountType: filterValues?.accountType || 'My Accounts',
    });
    const selectedFilterValues = { ...selectedFilters, ...accountDefault };
    setSelectedFilters(selectedFilterValues);
  }, []);
  */

  // useEffect(() => {
  //   if(filterValues && filterValues.account) {
  //     console.log('filterValues', filterValues.account[0].__typename)
  //     if (filterValues.account[0].__typename === 'Account') {
  //       console.log('filterValues1111', filterValues.account[0].__typename)
  //       setAccountChecked('My Accounts');
  //     }
  //     if (filterValues.account[0].__typename === 'TBAAccount') {
  //       setAccountChecked('TBA Accounts');
  //     }
  //   }else {
  //     setAccountChecked('My Accounts');
  //   }
  // }, []);

  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any): void => {
      // For selected filter value
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };

      setSelectedFilters(selectedFilterValues);
    },
    [selectedFilters]
  );

  useEffect(() => {
    if (territoryData?.getUserTerritories) {
      setTerritoryOptions(optionsWithLabelTerritory(territoryData.getUserTerritories));
    }
  }, [territoryData]);

  const handleCheckboxChange = (label: string, checked: boolean, name: string): void => {
    if (checked && name === 'usage') {
      setSelectedUsage(label);
      setFilterValue(name, label);
    } else if (checked && name === 'eventType') {
      const eventTypesData = [...selectedEventTypes, label];
      setSelectedEventTypes(eventTypesData);
      setFilterValue(name, eventTypesData);
    } else if (checked && name === 'event') {
      const eventData = [...selectedEvent, label];
      setSelectedEvent(eventData);
      setFilterValue(name, eventData);
    } else if (!checked) {
      if (name === 'usage') {
        setSelectedUsage(null);
        setFilterValue(name, null);
      } else if (name === 'eventType') {
        const remainingEventTypes = selectedEventTypes.filter(eventType => eventType !== label);
        setSelectedEventTypes(remainingEventTypes);
        setFilterValue(name, remainingEventTypes);
      } else if (name === 'event') {
        const remainingEvent = selectedEvent.filter(eventType => eventType !== label);
        setSelectedEvent(remainingEvent);
        setFilterValue(name, remainingEvent);
      }
    }
  };

  /*
  const handleAccountRadioButtonChange = (event: any): void => {
    setAccountChecked(event.target.value);
    setFilterValue('account', []);
    const accountTypeSelected =
      event.target.value === 'TBA Accounts' ? 'TBA Accounts' : 'My Accounts';
    setFilterValue('accountType', accountTypeSelected);
    // handleSelect([],'account')
  };
  */

  /*const handleRadioButtonChange = (data: any): void => {
    let selectedFilterValues = {};
    const { selection } = data;

    setChecked(selection[0]?.label);
    if (selection[0]?.label === 'My Events') {
      selectedFilterValues = Object.assign(selectedFilters, {
        myEvents: true,
        assignedEvents: false,
        teamEvents: false,
      });
    } else if (selection[0]?.label === 'Assigned Events') {
      selectedFilterValues = Object.assign(selectedFilters, {
        myEvents: false,
        assignedEvents: true,
        teamEvents: false,
      });
    } else if (selection[0]?.label === 'Team Events') {
      selectedFilterValues = Object.assign(selectedFilters, {
        myEvents: false,
        assignedEvents: false,
        teamEvents: true,
      });
    }
    setSelectedFilters(selectedFilterValues);
  };*/

  const handleRadioButtonChange = (event: any): void => {
    let selectedFilterValues = {};
    setChecked(event.target.value);
    if (event.target.value === 'My Events') {
      selectedFilterValues = Object.assign(selectedFilters, {
        myEvents: true,
        assignedEvents: false,
        teamEvents: false,
      });
    } else if (event.target.value === 'Assigned Events') {
      selectedFilterValues = Object.assign(selectedFilters, {
        myEvents: false,
        assignedEvents: true,
        teamEvents: false,
      });
    } else if (event.target.value === 'Team Events') {
      selectedFilterValues = Object.assign(selectedFilters, {
        myEvents: false,
        assignedEvents: false,
        teamEvents: true,
      });
    }
    setSelectedFilters(selectedFilterValues);
  };
  const handleSelectData = useCallback(
    (data: any, name: string) => {
      if (selectedFilters['account']?.length > 0) {
        getOpportunitiesItem({
          variables: {
            accountId: selectedFilters['account'].id,
          },
        });
      }
      setFilterValue(name, data);
    },
    [setFilterValue]
  );

  const handleSelect = useCallback(
    (data: any, name: string) => {
      const { selection } = data;
      if (name === 'franchise' && selection && selection.length > 0) {
        getUserBranches({
          variables: {
            region: REGION,
            userId,
            division: selection[0].label,
          },
        });
      }
      if (name === 'salesReps' && selection && selection.length > 0) {
        getAccount({
          variables: {
            salesRepId: selection[0].id,
            region: REGION,
            filters: {
              searchText: '',
            },
            userId,
            limit: 0,
          },
        });
      }
      if (name === 'account' && data && data.length > 0) {
        getSurgeonsData({
          variables: {
            accountId: data[0].id,
          },
        });
      }
      if (name === 'account' && data && data.length > 0) {
        getOpportunitiesItem({
          variables: {
            accountId: data[0].id,
          },
        });
      }

      if (name === 'eventType') {
        setFilterValue(name, selection[0]?.label);
      }

      if ((name === 'productId' || name === 'accountId' || name === 'account') && data) {
        setFilterValue(name, data);
      } else if (name === 'salesReps') {
        setFilterValue(name, data);
      } else {
        setFilterValue(name, selection);
      }
    },
    [getUserBranches, setFilterValue, userId]
  );
  const getAccountdata = (searchText: string): void => {
    // getAccountdata(searchText)=>{
    getAccount({
      variables: {
        salesRepId: '',
        region: REGION,
        userId,
        limit: 1000,
        filters: {
          searchText: searchText,
        },
      },
    });
  };
  useEffect(() => {
    getTerritories({
      variables: {
        userId: userInfo?.id,
      },
    });
  }, [getTerritories]);

  // useEffect(() => {
  //   getTBAAccounts({
  //     variables: {
  //       salesRepId: userInfo?.id,
  //     },
  //   });
  // }, [getTBAAccounts]);

  useEffect(() => {
    if (!eventValue && !isShowEventExposure) {
      const value = 'My Events';
      const evnt = Object.assign(selectedFilters, {
        myEvents: true,
        assignedEvents: false,
        teamEvents: false,
      });
      setChecked(value);
      const selectedFilterValues = { ...selectedFilters, ...evnt };
      setSelectedFilters(selectedFilterValues);
    }
    if (selectedFilters['account']?.length > 0) {
      getOpportunitiesItem({
        variables: {
          accountId: selectedFilters['account'].id,
        },
      });
    }
    // if (isShowEventExposure) {
    //   const value = { };
    //   if(selectedFilters['eventType'].length > 0) {
    //   const evnt = Object.assign(selectedFilters, {
    //     eventType: [value],
    //   });
    //   setFilterValue('eventType', { });
    //   const selectedFilterValues = { ...selectedFilters, ...evnt };
    //   setSelectedFilters(selectedFilterValues);
    // }
    // }
  }, []);

  // useEffect(() => {
  //   const value = 'My Accounts';
  //   setAccountChecked(value);
  // }, []);

  useEffect(() => {
    if (selectedFilters && selectedFilters.franchise && !filteredBranches) {
      getUserBranches({
        variables: {
          region: REGION,
          userId,
          division: selectedFilters.franchise[0].label,
        },
      });
    }
  }, [filteredBranches, getUserBranches, selectedFilters, userId]);

  const reset = (): void => {
    // handleToggleFilterEvent();
    setSelectedFilters({});
    setSelectedEventTypes([]);
    setSelectedUsage('');
    // handleFilterEvents(null);
    resetFilters();
  };

  const handleChangeDate = (selectedDate: any, name: string): void => {
    if (selectedDate) {
      const utcDate = getApiDateTime(selectedDate, name);
      setFilterValue(name, utcDate);
    }
  };

  const handleApplyFilter = (): void => {
    handleToggleFilterEvent();
    handleFilterEvents(selectedFilters);
  };

  const handleCreateFilterPreset = (name: string): void => {
    let reducedFilterValues = {};
    if (selectedFilters) {
      Object.keys(selectedFilters).forEach((key: string) => {
        if (Array.isArray(selectedFilters[key])) {
          const values = selectedFilters[key].map((item: SelectedData) => {
            const itemCopy = { id: item.id, label: item.label };
            return { ...itemCopy };
          });
          reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
        } else if (key !== '__typename' && selectedFilters[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: selectedFilters[key] };
        }
      });
    }

    const filterData = { ...reducedFilterValues };

    if (!filterExternalId || filterExternalId === '') {
      createFilterPreset({
        variables: {
          userId,
          filterValues: filterData,
          name,
        },
      });
    } else if (filterExternalId) {
      updateFilterPreset({
        variables: {
          externalId: filterExternalId,
          filterValues: filterData,
          name,
        },
      });
    }
  };

  const handleSaveModalVisible = (): void => {
    if (!filterExternalId || filterExternalId === '') {
      setSaveModalVisible(!saveModalVisible);
    } else {
      handleCreateFilterPreset(filterName);
    }
  };

  const handleDeleteModalVisible = useCallback((): void => {
    setDeleteModalVisible(!deleteModalVisible);
  }, [deleteModalVisible]);

  const handleDeleteFilterPreset = (): void => {
    deleteFilterPreset({
      variables: {
        externalId: filterExternalId,
      },
    }).then(response => {
      // if delete filter preset is successful
      const deleteUsageResponse =
        response.data &&
        response.data.deleteFilterPreset &&
        response.data.deleteFilterPreset.message === 'success';
      if (deleteUsageResponse) {
        handleDeleteModalVisible();
        handleToggleFilterEvent();
        handleClearFilter(filterExternalId);
        if (refetchFilterPresets) {
          handleEditFilter(false);
          refetchFilterPresets();
        }
      }
    });
  };

  useEffect(() => {
    if (
      updatedfilterPresets &&
      updatedfilterPresets.updateFilterPreset &&
      updatedfilterPresets.updateFilterPreset.message === 'success'
    ) {
      handleToggleFilterEvent();
      if (refetchFilterPresets) {
        handleEditFilter(false);
        refetchFilterPresets();
        reset();
      }
    }
  }, [
    handleEditFilter,
    handleFilterEvents,
    handleToggleFilterEvent,
    refetchFilterPresets,
    selectedFilters,
    updatedfilterPresets,
  ]);

  useEffect(() => {
    if (
      filterPresets &&
      filterPresets.createFilterPreset &&
      filterPresets.createFilterPreset.message === 'success'
    ) {
      handleToggleFilterEvent();
      if (refetchFilterPresets) {
        handleEditFilter(false);
        refetchFilterPresets();
      }
    }
  }, [
    filterPresets,
    handleEditFilter,
    handleFilterEvents,
    handleToggleFilterEvent,
    refetchFilterPresets,
    selectedFilters,
  ]);

  const handleSelectItem = useCallback(
    (_event, data: any) => {
      const { selection } = data;
      setInputValue('');
      const updatedSelection: Selection[] = Object.values(
        selection.reduce((acc: any, cur: any) => Object.assign(acc, { [cur.id]: cur }), {})
      );
      setTerritory(updatedSelection);
    },
    [setInputValue, setTerritory]
  );

  const handleRemoveSelection = useCallback(
    (_event, data: any) => {
      const { selection } = data;
      setInputValue('');
      setTerritory(selection);
    },
    [setTerritory]
  );

  const handleChange = useCallback(
    (_event, selectedValue: ValueInterface) => {
      const { value } = selectedValue;
      _event.persist();
      setInputValue(value);
    },
    [setInputValue]
  );

  // eslint-disable-next-line no-underscore-dangle
  const optionsWithLabelTerritory = (arr: []): any[] =>
    arr &&
    arr.map((elem: any) => {
      return { ...elem, label: `${elem.value}` };
    });

  const optionsWithLabelPart = (arr: []): any[] =>
    arr &&
    arr.map((elem: any) => {
      return {
        ...elem,
        label: `${elem?.productNumber || elem?.productNo} ${elem?.productDescription ||
          elem?.productdescription}`,
        value: `${elem?.productNumber || elem?.productNo} ${elem?.productDescription ||
          elem?.productdescription}`,
      };
    });

  // eslint-disable-next-line no-underscore-dangle
  const optionsWithLabel = (value: any): any =>
    value &&
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      if (
        [DetailTypes.SalesRep, DetailTypes.CoveringRep, DetailTypes.Surgeon].indexOf(
          elem.__typename as any
        ) >= 0
      ) {
        return { ...elem, label: `${elem.value}` };
      }
      if (elem.__typename === DetailTypes.Account) {
        return { ...elem, label: `${elem.number} - ${elem.value}` };
      }
      if (elem.__typename === DetailTypes.OpportunitiesList) {
        return { ...elem, value: ` ${elem.name}` };
      }

      if ([DetailTypes.Account, DetailTypes.TBAAccount].indexOf(elem.__typename as any) >= 0) {
        return { ...elem, label: `${elem.number}-${elem.value}` };
      }
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === DetailTypes.userTerritory) {
        return { ...elem, label: `${elem.value}` };
      }
      if (elem.__typename === DetailTypes.ProcedureDetail) {
        return { ...elem, label: `${elem.name}` };
      } else {
        return { ...elem, label: `${elem.value}` };
      }
    });

  const surgeonDetails = optionsWithLabel(filteredSurgeons?.getSurgeons);
  const procedureTypes = optionsWithLabel(filteredProcedureTypes?.getProcedureTypes);
  const salesReps = optionsWithLabel(filteredSalesReps?.getSalesReps);
  const coveringReps = optionsWithLabel(filteredCoveringReps?.getCoveringReps);
  let accounts: any = [];
  accounts = useMemo(() => {
    if (filteredAccounts?.getAccounts) {
      return optionsWithLabel(filteredAccounts?.getAccounts);
    }
  }, [filteredAccounts?.getAccounts]);
  const opportunity = optionsWithLabel(getOpportunities?.getOpportunities);

  const partsData = optionsWithLabelPart(productItems?.getProductList);
  const filteredRepStockAccount = accounts?.filter((el: any) => {
    return el.isRepStockAccount !== true;
  });

  const tbaAccounts = optionsWithLabel(tbaAccountsItems?.getUserTBAAccounts);

  /*let selectedEventType: any = '';

  if (checked) {
    if (checked === 'My Events') {
      selectedEventType = eventTypesArr[0];
    } else if (checked === 'Assigned Events') {
      selectedEventType = eventTypesArr[1];
    }  else if (checked === 'Team Events') {
      selectedEventType = eventTypesArr[2];
    }
  }*/

  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={filterEventModalVisible}
        onRequestClose={handleToggleFilterEvent}
        footer={
          isShowEventExposure
            ? [
              <Button
                className="slds-float_left slds-text-color_default"
                label="Cancel"
                key="Cancel"
                onClick={handleToggleFilterEvent}
              />,
              <Button label="Reset" key="Reset" onClick={reset} />,
              <Button
                className="yellow-btn"
                label="Apply Filters"
                onClick={handleApplyFilter}
                disabled={
                  selectedFilters?.endDate &&
                  selectedFilters?.startDate &&
                  selectedFilters.endDate < selectedFilters.startDate
                }
                variant="brand"
                key="Apply"
              />,
            ]
            : [
              <Button
                className="slds-float_left slds-text-color_default"
                label="Save Filters"
                disabled={
                  selectedFilters?.endDate &&
                  selectedFilters?.startDate &&
                  selectedFilters.endDate < selectedFilters.startDate
                }
                key="Save"
                onClick={handleSaveModalVisible}
              />,
              <Button label="Reset" key="Reset" onClick={reset} />,
              <Button
                className="yellow-btn"
                label="Apply Filters"
                onClick={handleApplyFilter}
                disabled={
                  selectedFilters?.endDate &&
                  selectedFilters?.startDate &&
                  selectedFilters.endDate < selectedFilters.startDate
                }
                variant="brand"
                key="Apply"
              />,
            ]
        }
        heading={[
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div className="slds-float_left slds-text-title_bold filter_heading" key="filterName">
            {`${filterName && filterName !== ''
                ? `${isEditFilter ? 'Edit ' : ''}Filter Preset ${filterName}`
                : 'Filters'
              }`}
          </div>,

          isEditFilter && (
            <div className="slds-float_right" key="delete">
              <Button
                className="delete_button"
                label="Delete Preset"
                key="delete"
                onClick={handleDeleteModalVisible}
              />
            </div>
          ),
        ]}
      >
        {isShowEventExposure ? (
          <section className="slds-p-around_medium slds-theme_shade">
            <div className="slds-card slds-p-around--medium">
              <h1 className="slds-m-bottom--small">Attributes</h1>
              <AutocompleteSingleSelectPickerWithSearch
                items={filteredRepStockAccount || []}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'accountId');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.accountId || [] : []}
                placeholder="Select an Account"
                label="Accounts"
                withAddMenuButton={false}
                fetchItems={(searchText: string) => getAccountdata(searchText)}
                filter
                name="accountId"
                autoSelectValue={false}
              />
              <Combobox
                id="eventType"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'eventType'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'eventType'),
                }}
                labels={{
                  label: `Event Types`,
                  placeholder: 'Select',
                  noOptionsFound: 'No data found',
                }}
                placeholder="Select"
                hasInputSpinner={loadingAccounts}
                options={EVENTS_TYPE_OPTIONS || []}
                selection={selectedFilters ? selectedFilters.eventType || [] : []}
                variant="readonly"
              />
              <AutocompleteSingleSelectPickerWithSearch
                items={partsData}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'productId');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.productId || [] : []}
                placeholder="Select a part"
                label="Part"
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="productId"
                autoSelectValue={false}
              />
            </div>
            <div className="slds-card slds-p-around--medium">
              <h1 className="slds-m-bottom--small">Date</h1>
              <Combobox
                id="daysAtEvent"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'daysAtEvent'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'daysAtEvent'),
                }}
                labels={{
                  label: `Days at Event`,
                  placeholder: 'Select',
                  noOptionsFound: 'No data found',
                }}
                placeholder="Select"
                // hasInputSpinner={loadingAccounts}
                options={DAYS_AT_EVENTS_OPTIONS || []}
                selection={selectedFilters ? selectedFilters.daysAtEvent || [] : []}
                variant="readonly"
              />
            </div>

            <div className="slds-card slds-p-around--medium">
              <h1 className="slds-m-bottom--small">EVENT TYPES</h1>
              <Combobox
                id="eventType"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'eventType'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'eventType'),
                }}
                labels={{
                  label: `Events`,
                  placeholder: 'Select',
                  noOptionsFound: 'No data found',
                }}
                placeholder="Select"
                // hasInputSpinner={loadingAccounts}
                options={EVENTS_TYPE_OPTIONS || []}
                selection={selectedFilters ? selectedFilters.eventType || [] : []}
                variant="readonly"
              />
            </div>
            <div className="slds-card slds-p-around--medium">
              <h1 className="slds-m-bottom--small">Part</h1>
              <AutocompleteSingleSelectPickerWithSearch
                items={partsData}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'productId');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.productId || [] : []}
                placeholder="Select a part"
                label="Parts"
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="productId"
                autoSelectValue={false}
              />
            </div>
            <div className="slds-card slds-p-around--medium">
              <h1 className="slds-m-bottom--small">Sales Rep</h1>
              <AutocompleteSingleSelectPicker
                items={salesReps || []}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'salesReps');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.salesReps || [] : []}
                placeholder="Sales Rep"
                label=""
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="salesReps"
                autoSelectValue={false}
              />
              <Combobox
                id="territory"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'territory'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'territory'),
                }}
                labels={{
                  label: `Territory`,
                  placeholder: 'Select',
                  noOptionsFound: 'No data found',
                }}
                multiple
                placeholder="Select"
                hasInputSpinner={loadingSalesReps}
                options={territoryOptions || []}
                selection={selectedFilters ? selectedFilters.territory || [] : []}
                variant="readonly"
              />
            </div>
          </section>
        ) : (
          <section className="slds-p-around_medium slds-theme_shade">
            <div className="slds-card slds-p-around--medium">
              <h1 className="slds-m-bottom--small">Attributes</h1>
              <Combobox
                id="eventType"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'eventType'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'eventType'),
                }}
                labels={{
                  label: `Event Types`,
                  placeholderReadOnly: 'Select Event Types',
                  noOptionsFound: 'No data found',
                }}
                multiple
                placeholder="Select"
                // hasInputSpinner={loadingAccounts}
                options={FILTER_EVENT_TYPES || []}
                selection={selectedFilters ? selectedFilters.eventType || [] : []}
                variant="readonly"
              />

              <h1 className="slds-m-bottom--small events-header">Events</h1>
              <div className="slds-grid">
                <RadioGroup
                  onChange={(event: { target: { value: any } }) => handleRadioButtonChange(event)}
                >
                  {eventTypesArr.map(value => (
                    <Radio
                      className="radio-group"
                      key={value.label}
                      id={value.label}
                      labels={{
                        label:
                          value.label === 'My Events' ? (
                            <div style={{ display: 'inline', position: 'absolute', width: '100%' }}>
                              <p>{value.label}</p>
                              <p
                                style={{
                                  position: 'absolute',
                                  fontStyle: 'italic',
                                  width: '100%',
                                  fontSize: '13px',
                                  color: 'grey',
                                  top: '1px',
                                  left: '80px',
                                }}
                              >
                                {'(Primary & Covering)'}
                              </p>
                            </div>
                          ) : (
                            value.label
                          ),
                      }}
                      value={value.label}
                      checked={checked === value.label}
                      variant="base"
                    />
                  ))}
                </RadioGroup>
              </div>
              {/* <Combobox
                  id="events"
                  events={{
                    onSelect: (event: any, data: any): void => handleRadioButtonChange(data),
                    // onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    //   handleSelect(data, 'eventType'),
                  }}
                  labels={{
                    label: `Events`,
                    placeholder: 'Select',
                    noOptionsFound: 'No data found',
                  }}
                  placeholder="Select"
                  options={eventTypesArr || []}
                  selection={[selectedEventType] || []}
                  variant="readonly"
                /> */}
              <Combobox
                id="status"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'status'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'status'),
                }}
                labels={{
                  label: `Status`,
                  placeholderReadOnly: 'Select Status',
                  noOptionsFound: 'No data found',
                }}
                multiple
                placeholder="Select"
                options={FILTER_EVENT_STATUS || []}
                selection={selectedFilters ? selectedFilters.status || [] : []}
                variant="readonly"
              />
            </div>

            <div className="slds-card slds-p-around--medium">
              <h1 className="slds-m-bottom--xx-small">Date Range</h1>
              {/* <div className="slds-grid">
                {accountTypesArr.map(value => (
                  <div style={{ marginRight: '30px' }}>
                    <Radio
                      className="radio-group"
                      key={value.label}
                      id={value.label}
                      onChange={(event: { target: { value: any } }) =>
                        handleAccountRadioButtonChange(event)
                      }
                      defaultChecked={value.name === 'myAccounts'}
                      labels={{
                        label: value.label,
                      }}
                      value={value.label}
                      checked={accountChecked === value.label}
                      variant="base"
                      size="large"
                    />
                  </div>
                ))}
              </div> 
              <div className="full-width_button">
                <Combobox
                  id="account"
                  events={{
                    onSelect: (event: any, data: any): void => handleSelect(data, 'account'),
                    onRequestRemoveSelectedOption: (event: any, data: any): void =>
                      handleSelect(data, 'account'),
                  }}
                  labels={{
                    label: `Account`,
                    placeholder: 'Select',
                    noOptionsFound: 'No data found',
                  }}
                  placeholder="Select"
                  hasInputSpinner={loadingAccounts}
                  options={accountChecked === 'My Accounts' ? accounts || [] : tbaAccounts || []}
                  selection={selectedFilters ? selectedFilters.account || [] : []}
                  variant="readonly"
                />
              </div>*/}

              {/* <div className="full-width_button">
                <Combobox
                  id="contact"
                  events={{
                    onSelect: (event: any, data: any): void => handleSelect(data, 'surgeon'),
                    onRequestRemoveSelectedOption: (event: any, data: any): void =>
                      handleSelect(data, 'account'),
                  }}
                  labels={{
                    label: `Surgeon`,
                    placeholder: 'Select',
                    noOptionsFound: 'No data found',
                  }}
                  placeholder="Select"
                  hasInputSpinner={loadingSurgeons}
                  options={surgeonDetails || []}
                  selection={selectedFilters ? selectedFilters.surgeon || [] : []}
                  variant="readonly"
                />
              </div>

              <div className="full-width_button">
                <Combobox
                  id="procedure"
                  events={{
                    onSelect: (event: any, data: any): void => handleSelect(data, 'procedureType'),
                    onRequestRemoveSelectedOption: (event: any, data: any): void =>
                      handleSelect(data, 'account'),
                  }}
                  labels={{
                    label: `Procedure`,
                    placeholder: 'Select',
                    noOptionsFound: 'No data found',
                  }}
                  placeholder="Select"
                  hasInputSpinner={loadingAccounts}
                  options={procedureTypes || []}
                  selection={selectedFilters ? selectedFilters.procedureType || [] : []}
                  variant="readonly"
                />
              </div>

              <div className="full-width_button">
              <p style={{ fontSize: 16, marginBottom: -12 }}>Sales Rep</p>
              <AutocompleteSingleSelectPicker
                items={salesReps || []}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'salesReps');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.salesReps || [] : []}
                placeholder="Sales Rep"
                label=""
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="salesReps"
                autoSelectValue={false}
              />
              </div>

              <div className="full-width_button">
                <Combobox
                  id="coveringRep"
                  events={{
                    onSelect: (event: any, data: any): void => handleSelect(data, 'coveringReps'),
                    onRequestRemoveSelectedOption: (event: any, data: any): void =>
                      handleSelect(data, 'coveringReps'),
                  }}
                  labels={{
                    label: `Covering Rep`,
                    placeholder: 'Select',
                    noOptionsFound: 'No data found',
                  }}
                  multiple
                  placeholder="Select"
                  hasInputSpinner={loadingCoveringReps}
                  options={coveringReps || []}
                  selection={selectedFilters ? selectedFilters.coveringReps || [] : []}
                  variant="readonly"
                />
              </div>

              <div className="full-width_button">
                <Combobox
                  id="status"
                  events={{
                    onSelect: (event: any, data: any): void => handleSelect(data, 'status'),
                    onRequestRemoveSelectedOption: (event: any, data: any): void =>
                      handleSelect(data, 'status'),
                  }}
                  labels={{
                    label: `Status`,
                    placeholder: 'Select',
                    noOptionsFound: 'No data found',
                  }}
                  multiple
                  placeholder="Select"
                  options={FILTER_EVENT_STATUS || []}
                  selection={selectedFilters ? selectedFilters.status || [] : []}
                  variant="readonly"
                />
              </div> */}

              {/* <div className="full-width_button">
              <Combobox
                id="division"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'franchise'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'franchise'),
                }}
                labels={{
                  label: `Division / Franchise`,
                  placeholder: 'Select',
                  noOptionsFound: 'No data found',
                }}
                placeholder="Select"
                multiple
                hasInputSpinner={loadingFranchises}
                options={franchises || []}
                selection={selectedFilters ? selectedFilters.franchise || [] : []}
                variant="readonly"
              />
            </div> */}

              <div className="filter_date_container">
                <p className="selectbox_label">Date Range</p>
                <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                  <div className="slds-col slds-align_absolute-center">
                    <DatePicker
                      label=""
                      placeholder="From"
                      handleChangeDate={(data: any): void => handleChangeDate(data, 'startDate')}
                      disabled={false}
                      isFilterDate={true}
                      value={selectedFilters ? selectedFilters.startDate : null}
                    />
                  </div>
                  <div className="slds-col slds-align_absolute-center">
                    <span>-</span>
                  </div>
                  <div className="slds-col slds-align_absolute-center">
                    <DatePicker
                      label=""
                      placeholder="To"
                      handleChangeDate={(data: any): void => handleChangeDate(data, 'endDate')}
                      disabled={false}
                      isFilterDate={true}
                      value={selectedFilters ? selectedFilters.endDate : null}
                    />
                  </div>
                </div>
              </div>
              <div>
                {selectedFilters?.endDate &&
                  selectedFilters?.startDate &&
                  selectedFilters.endDate < selectedFilters.startDate && (
                    <Pill
                      labels={{
                        label: 'Error: To Date must be greater than or equal to From Date',
                      }}
                      hasError
                      icon={
                        <Icon
                          title="Error"
                          category="utility"
                          name="warning"
                          className="slds-icon-text-error"
                        />
                      }
                    />
                  )}
              </div>
            </div>

            <div className="slds-card slds-p-around--medium">
              <h1 className="slds-m-bottom--xx-small">Other</h1>
              {/* <div className="slds-grid">
                {accountTypesArr.map(value => (
                  <div style={{ marginRight: '30px' }}>
                    <Radio
                      className="radio-group"
                      key={value.label}
                      id={value.label}
                      onChange={(event: { target: { value: any } }) =>
                        handleAccountRadioButtonChange(event)
                      }
                      defaultChecked={value.name === 'myAccounts'}
                      labels={{
                        label: value.label,
                      }}
                      value={value.label}
                      checked={accountChecked === value.label}
                      variant="base"
                      size="large"
                    />
                  </div>
                ))}
              </div> */}
              <AutocompleteSingleSelectPickerWithSearch
                items={accounts || []}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'account');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.account || [] : []}
                placeholder="Select an Account"
                label="Account"
                withAddMenuButton={false}
                fetchItems={(searchText: string) => getAccountdata(searchText)}
                filter
                name="account"
                autoSelectValue={false}
              />
              <Combobox
                id="contact"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'surgeon'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'account'),
                }}
                labels={{
                  label: `Surgeon`,
                  placeholderReadOnly: 'Select Surgeon',
                  noOptionsFound: 'No data found',
                }}
                placeholder="Select"
                hasInputSpinner={loadingSurgeons}
                options={surgeonDetails || []}
                selection={selectedFilters ? selectedFilters.surgeon || [] : []}
                variant="readonly"
              />

              <Combobox
                id="procedure"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'procedureType'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'account'),
                }}
                labels={{
                  label: `Procedure`,
                  placeholderReadOnly: 'Select Procedure',
                  noOptionsFound: 'No data found',
                }}
                placeholder="Select"
                hasInputSpinner={loadingAccounts}
                options={procedureTypes || []}
                selection={selectedFilters ? selectedFilters.procedureType || [] : []}
                variant="readonly"
              />

              <AutocompleteSingleSelectPicker
                items={salesReps || []}
                setSelectedItem={(result: any): void => {
                  handleSelect(result, 'salesReps');
                }}
                disabled={false}
                selectedItem={selectedFilters ? selectedFilters.salesReps || [] : []}
                placeholder="Sales Rep"
                label=""
                withAddMenuButton={false}
                fetchItems={(searchText: string): string => searchText}
                filter
                name="salesReps"
                autoSelectValue={false}
              />
              
              <Combobox
                id="coveringRep"
                events={{
                  onSelect: (event: any, data: any): void => handleSelect(data, 'coveringReps'),
                  onRequestRemoveSelectedOption: (event: any, data: any): void =>
                    handleSelect(data, 'coveringReps'),
                }}
                labels={{
                  label: `Covering Rep`,
                  placeholderReadOnly: 'Select Covering Rep',
                  noOptionsFound: 'No data found',
                }}
                multiple
                placeholder="Select"
                hasInputSpinner={loadingCoveringReps}
                options={coveringReps || []}
                selection={selectedFilters ? selectedFilters.coveringReps || [] : []}
                variant="readonly"
              />
              <div className="filter_date_container" style={{ marginBottom: '42px' }}>
                <p
                  className="selectbox_label"
                  style={{ marginBottom: '-12px', fontSize: '15px', color: '#000' }}
                >
                  Opportunity
                </p>
                <AutocompleteSingleSelectPicker
                  items={opportunity || []}
                  setSelectedItem={(result: any): void => {
                    handleSelectData(result, 'opportunity');
                  }}
                  disabled={false}
                  selectedItem={selectedFilters ? selectedFilters.opportunity || [] : []}
                  placeholder="Select Opportunity"
                  label="Opportunity"
                  withAddMenuButton={false}
                  fetchItems={(searchText: string): string => searchText}
                  filter
                  name="opportunity"
                  autoSelectValue={true}
                />
              </div>
            </div>
            {saveModalVisible && (
              <SaveFilter
                handleSaveModalVisible={handleSaveModalVisible}
                saveModalVisible={saveModalVisible}
                handleCreateFilterPreset={handleCreateFilterPreset}
                selectedFilterName={filterName}
              />
            )}

            {deleteModalVisible && (
              <DeleteFilterPreset
                deleteModalVisible={deleteModalVisible}
                handleDeleteModalVisible={handleDeleteModalVisible}
                handleDeleteFilterPreset={handleDeleteFilterPreset}
              />
            )}
          </section>
        )}
      </Modal>
    </IconSettings>
  );
};

/** Filter event modal component */
export default FilterEvent;
