/**
 * Utility module to implement debouncing when user searches a string.
 * @module src/util/debounced
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
const debounced = (delay: number, fn: (...args: any) => void): ((...args: any) => void) => {
  let timerId: any;
  return (...args: any): void => {
    if (timerId !== null) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
};

/** Module to implement debouncing */
export default debounced;
