/**
 * Graphql mutation to create Notification settings
 * @module src/graphql/createUpdateUserNotificationConfig
 */
import gql from 'graphql-tag';

const CREATE_UPDATE_NOTIFICATION_CONFIG = gql`
  mutation createUpdateNotificationConfig(
    $notificationConfiguration: [UpdateNotificationConfiguration]
  ) {
    createUpdateNotificationConfig(notificationConfiguration: $notificationConfiguration) {
      message
    }
  }
`;

export default CREATE_UPDATE_NOTIFICATION_CONFIG;
