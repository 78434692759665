/**
 * Module for Custom Rep/Covering Cell.
 * @module src/Events/EventsList
 */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
// import './index.scss';

interface Reps {
  type: string;
  diplayOrderType: string;
}
interface Props {
  item?: Reps;
  state?: string;
}

const ReprocessTypeCell: FC<Props> = ({
  item = {
    diplayOrderType: '',
  },
}) => {
  let { diplayOrderType } = item;

  if (diplayOrderType === 'Inventory Request') {
    diplayOrderType = 'Inventory Requests';
  } else if (diplayOrderType === 'Inventory Return') {
    diplayOrderType = 'Standard Return';
  }
  return (
    <DataTableCell title={diplayOrderType}>
      <p className="wrap-text font-12">{diplayOrderType}</p>
    </DataTableCell>
  );
};

export default ReprocessTypeCell;
