/**
 * Graphql mutation to update business unit of user
 * @module src/graphql/updateUserBusinessunit
 */
import gql from 'graphql-tag';

/** updating Notification settings  */
const UPDATE_USER_BU = gql`
  mutation updateUserBusinessunit(
    $userId: String
    $currentBusinessunit: String
    $previousBusinessunit: String
  ) {
    updateUserBusinessunit(
      userId: $userId
      currentBusinessunit: $currentBusinessunit
      previousBusinessunit: $previousBusinessunit
    ) {
      message
    }
  }
`;

export default UPDATE_USER_BU;
