/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_NOTIFICATION_LISTS = gql`
  query getUserNotificationList(
    $limit: Int
    $offset: Int
    $userId: String
    $orderBy: String
    $orderSortType: String
    $filters: UserNotificationListFilters
  ) {
    getUserNotificationList(
      limit: $limit
      offset: $offset
      userId: $userId
      orderBy: $orderBy
      orderSortType: $orderSortType
      filters: $filters
    ) {
      id
      userId
      buCatTempId
      subject
      transactionType
      transactionNumber
      transactionSfid
      transactionExternalId
      transactionDate
      notificationCreatedDate
      isStar
      isRead
      salesrepid
      salesRepName
      createdBy
    }
  }
`;
