/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Module for header on InventoryFavoriteTable Page
 * @module src/SurgicalDetail/InventoryFavoriteTable
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FC, ReactNode, useState, useEffect } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { useMutation } from '@apollo/react-hooks';
import Input from '@salesforce/design-system-react/components/input';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import Spinner from '@salesforce/design-system-react/components/spinner';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { unset } from 'lodash';
import CartComponent from './cartComponent';
import './index.scss';
import InventoryFavoriteAddItems from '../InventoryFavoriteAddItems';
import { CYCLE_COUNT, MAX_QUANTITY, NOTIFY_TYPE } from '../../../../util/constants';
import { getEventIcon } from '../../../../util/utilityFunctions';
import SnackBar from '../../../Shared/SnackBar';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import CREATE_UPDATE_INVENTORY_FAVORITE from '../../../../graphql/mutations/createUpdateInventoryFavorites';

interface Props {
  inventoryRequestFav?: any;
  updateFavLineItems: (item: any) => void;
}

interface DynamicValue {
  [key: string]: any;
}

interface SelectedData {
  id: string;
  value: string;
  __typename?: string;
}

const InventoryFavoriteTable: FC<Props> = ({ inventoryRequestFav, updateFavLineItems }) => {
  const [irFavoriteData, setIrFavoriteData] = useState(inventoryRequestFav);
  const [productList, setProductList] = useState(irFavoriteData.lineItems);
  const { open, notification, openSnackBar } = useSnackBar();
  const [showSearchCatalogModal, setShowSearchCatalogModal] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<DynamicValue | null>(null);
  const [, setSelectedFilterApiValues] = useState<DynamicValue | null>(null);
  const updateLineItems = (items: any): void => {
    updateFavLineItems(items);
    const data = { ...irFavoriteData };
    data.lineItems = items;
    setIrFavoriteData(data);
    setProductList(items);
  };
  useEffect(() => {
    if (inventoryRequestFav) {
      setIrFavoriteData(inventoryRequestFav);
      setProductList(inventoryRequestFav.lineItems);
    }
  }, [inventoryRequestFav]);

  const tableClass = 'ir--fav-cols';

  const quantityCount = irFavoriteData?.lineItems.reduce(
    (accumulator: any, current: { quantity: any }) => accumulator + Number(current.quantity),
    0
  );
  const [count, setCount] = useState(quantityCount);

  const [createUpdateInventoryFavorite, { data: favoriteCreateUpdateMutation }] = useMutation(
    CREATE_UPDATE_INVENTORY_FAVORITE
  );

  const handleCloseFavoriteAddItems = (): void => {
    setShowSearchCatalogModal(!showSearchCatalogModal);
  };

  const getFilterApiValue = (filterValues: DynamicValue): DynamicValue => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          let values;
          if (key === 'salesRepId') {
            values = filterValues[key].map((item: any) => item.salesRepId);
          } else {
            values = filterValues[key].map((item: SelectedData) => item.id);
          }
          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  const handleFilter = (filterValues: DynamicValue | null, filterName?: string): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
    }
  };

  const handleUpdates = (): void => {
    const payload: Array<any> = [];
    productList.forEach((product: any): void => {
      if (Number(product.quantity) > 0) {
        payload.push({
          part: {
            id: product.part.id,
            name: product.part.name,
          },
          lotNumber: product.lotNumber || '',
          quantity: product.quantity,
        });
      }
    });

    const mutation = { ...inventoryRequestFav };
    mutation.lineItems = payload;

    // eslint-disable-next-line no-underscore-dangle
    delete mutation.__typename;
    // eslint-disable-next-line no-underscore-dangle
    delete mutation._id;
    // eslint-disable-next-line no-underscore-dangle
    unset(mutation, 'account.__typename');
    unset(mutation, 'salesRep.__typename');
    unset(mutation, 'shipTo.__typename');
    unset(mutation, 'userDetails.__typename');

    mutation.lineItems.forEach(function deleteKey(v: any): void {
      // eslint-disable-next-line no-underscore-dangle
      delete v.part.__typename;
      // eslint-disable-next-line no-underscore-dangle
      delete v.__typename;
      delete v?.counterValue;
      delete v?.changed;
    });
    delete mutation.favComments;
    delete mutation.comments;
    mutation.recordType = 'update';
    createUpdateInventoryFavorite({
      variables: mutation,
    })
      .then(() => {
        updateLineItems(mutation.lineItems);
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Line Items of Inventory Fav successfully updated');
      })
      .catch((error: any) => {
        openSnackBar(NOTIFY_TYPE.ERROR, 'Error: Updating Line Items to Inventory Fav');
        /* eslint-disable no-console */
        console.log(error, ' - We are having issue updating the line items for Inventory Fav');
      });
  };

  const handleCount = (event: any, data: { value: string; number: number }, product: any): void => {
    const updatedList = [...productList];
    updatedList.forEach((element: any) => {
      if (product.part.id === element.part.id) {
        /* eslint-disable no-param-reassign */
        element.quantity = (data.number < MAX_QUANTITY ? data.number : MAX_QUANTITY).toString();
      }
    });

    setProductList(updatedList);
    const counter = updatedList.reduce((acc: any, curr: any) => acc + Number(curr.quantity), 0);
    setCount(counter);
    handleUpdates();
  };

  const handleCountalert = (
    event: any,
    data: { value: string; number: number },
    product: any
  ): void => {
    if (product?.productOnHold) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );
    }
    handleCount(event, data, product);
  };

  const handleDelete = (product: any): void => {
    // empty/clear quantity, it will delete in Backend
    const listItems = [...productList];
    listItems.forEach((i: any, index: number) => {
      if (i.part.id === product.part.id) {
        i.quantity = '0';
        listItems.splice(index, 1);
      }
    });
    setProductList(listItems);
    const counter = listItems.reduce((acc: any, curr: any) => acc + Number(curr.quantity), 0);
    setCount(counter);
    handleUpdates();
  };

  const iconPath = getEventIcon(CYCLE_COUNT);

  const handleDragOnHand = (result: any): void => {
    if (!result.destination) {
      return;
    }
    const listItems = [...productList];
    const reorderedItem = listItems.splice(result.source.index, 1);
    listItems.splice(result.destination.index, 0, ...reorderedItem);
  };

  const handleAddParts = (): void => {
    setShowSearchCatalogModal(true);
  };

  const headerActions = (): ReactNode => (
    <PageHeaderControl>
      <ButtonGroup variant="list" id="button-group-page-header-actions">
        <div style={{ marginRight: '10px' }}>
          <CartComponent
            count={quantityCount}
            color="grey"
            textColor="white"
            cartTitle="Requested"
          />
        </div>

        <Button
          iconCategory="utility"
          iconSize="small"
          className="slds-text-color_default"
          label="Add"
          iconName="add"
          iconPosition="left"
          style={{ fontWeight: 'bold' }}
          onClick={handleAddParts}
        />
      </ButtonGroup>
    </PageHeaderControl>
  );
  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      {!productList && <Spinner />}
      <PageHeader
        className="events_header"
        icon={
          <img
            src={iconPath}
            className="logo-image"
            alt="company"
            style={{ width: '2.75rem', height: '2.75rem' }}
          />
        }
        onRenderActions={headerActions}
        variant="record-home"
        title="Inventory"
      />
      {showSearchCatalogModal && (
        <InventoryFavoriteAddItems
          isOpen={showSearchCatalogModal}
          handleClose={handleCloseFavoriteAddItems}
          inventoryRequestFav={irFavoriteData}
          updateLineItems={updateLineItems}
          selectedFilter={selectedFilter}
          handleFilter={handleFilter}
        />
      )}
      <div className="order-list-container">
        <DragDropContext onDragEnd={handleDragOnHand}>
          <Droppable droppableId="items">
            {(provided: any): any => (
              <div className="inventory-table-container">
                <div
                  className={`parts-table-header ${tableClass} `}
                  style={{
                    fontFamily: '"SFProText-Bold", "SF pro text Bold", "SF pro text", sans-serif',
                    color: '#000000',
                  }}
                >
                  <p>Part</p>
                  <p style={{ textAlign: 'center' }}>Requested Qty</p>
                  <p style={{ textAlign: 'center' }}>Action</p>
                </div>
                {productList?.length > 0 &&
                  productList.map((lineItem: any) => {
                    // eslint-disable-next-line no-nested-ternary
                    return lineItem.quantity > 0 ? (
                      <div
                        className={`parts-table-header ${tableClass} `}
                        style={{
                          background: 'white',
                          fontWeight: 'unset',
                          lineHeight: 1,
                        }}
                      >
                        <div style={{ paddingLeft: '5px', textAlign: 'left' }}>
                          {`${lineItem.part.name}`}
                        </div>
                        <div
                          style={{ paddingLeft: '50px', paddingRight: '50px', textAlign: 'center' }}
                        >
                          <div className="row-control">
                            <Input
                              id="counter-input-3"
                              minValue={1}
                              maxValue={MAX_QUANTITY}
                              onChange={(
                                event: any,
                                data: { value: string; number: number }
                              ): void => {
                                handleCountalert(event, data, lineItem);
                              }}
                              value={lineItem.quantity}
                              variant="counter"
                            />
                          </div>
                        </div>
                        <div
                          className="vertical-align-middle"
                          style={{ paddingLeft: '5px', textAlign: 'center' }}
                        >
                          <Button
                            iconCategory="utility"
                            iconName="delete"
                            iconSize="large"
                            iconVariant="bare"
                            variant="icon"
                            onClick={(): void => {
                              handleDelete(lineItem);
                            }}
                          />
                        </div>
                      </div>
                    ) : null;
                  })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </IconSettings>
  );
};

/** Custom Header on InventoryFavoriteTable Page **/
export default InventoryFavoriteTable;
