import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_OPS_LIST = gql`
  query getOPSList($personaName: String, $filters: OPSListFilters) {
    getOPSList(personaName: $personaName, filters: $filters) {
      id
      value
    }
  }
`;
