/* eslint-disable */
import React, { FC, useEffect, useState } from 'react';
import Card from '@salesforce/design-system-react/components/card';
import Icon from '@salesforce/design-system-react/components/icon';
import { Grid } from '@material-ui/core';
import Button from '@salesforce/design-system-react/components/button';
import { useSelector } from 'react-redux';
import moment from 'moment';
import SnackBar from '../../Shared/SnackBar';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import '../index.scss';
import TextArea from '../../Shared/TextArea';
import { NOTIFY_TYPE, PERSONA_CONDITION } from '../../../util/constants';
import { getUserInfo } from '../../../store/ducks/userInfo';

interface Props {
  inventoryReturnDetails: InventoryReturnDetails;
  setUserEnteredComment: (val: any) => void;
  viewOnly: boolean;
  type: string;
}

interface Comments {
  id?: string;
  value: string;
  type?: string;
  postTime: string;
  user?: string;
}

interface InventoryReturnDetails {
  eventStatus?: string;
  caseExternalId?: string;
  salesRep?: string;
  eventId?: string;
  accountNumber?: string;
  accountName?: string;
  subInventoryCode?: string;
  suibmitDate?: string;
  shippingAddessLine1?: string;
  shippingAddessLine2?: string;
  shippingPostalcode?: string;
  shippingCity?: string;
  shippingState?: string;
  serviceLevel?: string;
  submitDate?: string;
  comments: Comments[];
}

const Comments: FC<Props> = ({
  inventoryReturnDetails,
  // viewOnly,
  setUserEnteredComment,
  type,
  viewOnly,
}) => {
  const { open, notification, openSnackBar } = useSnackBar();
  const { comments = [] } = inventoryReturnDetails;
  const [input, setInput] = useState('');
  const isManualIR =
    type && inventoryReturnDetails?.eventStatus && inventoryReturnDetails?.caseExternalId
      ? type === 'Open' &&
      inventoryReturnDetails?.eventStatus === 'Approved' &&
      inventoryReturnDetails?.caseExternalId
      : false;
  const [commentsList, setCommentList] = useState<Comments[]>();
  const userInfo = useSelector(getUserInfo);
  useEffect(() => {
    if (comments) {
      const result = comments.filter(
        (e: any) => e.type === "Customer",
      );
      setCommentList(result);
    }
  }, [comments]);
  const handleAdd = (): void => {
    if (input) {
      setUserEnteredComment(input);
      const obj = {
        value: input,
        postTime: moment().toString(),
        user: `${userInfo?.firstName} ${userInfo?.lastName}`,
      };
      setCommentList([...(commentsList || []), obj]);
      setInput('');
    } else {
      openSnackBar(NOTIFY_TYPE.INFO, 'Please add your comment first');
    }
  };
  return (
    <div className="slds-m-around_small">
      <SnackBar open={open} notification={notification} />
      <Card
        id="Comments"
        heading={`Comments  ${
          commentsList && commentsList.length > 0 ? `(${commentsList.length})` : ``
        }`}
        icon={<Icon category="standard" name="feedback" size="small" />}
      >
        <hr className="card-divider" />
        <div className="review-comments-container">
          <div className="comments-container">
            {commentsList &&
              commentsList.map((comment: Comments) => (
                <div className="comment-body">
                  <p className="comment-user-name">{comment.user || ''}</p>
                  <h4 className="comment-timestamp">
                    {`${moment(comment.postTime).format('L')} at ${moment(comment.postTime).format(
                      'LT'
                    )}`}
                  </h4>
                  <p className="comments_text">{comment.value}</p>
                </div>
              ))}
          </div>
          <TextArea
            label="Add Comment"
            tooltipText="Add Comment"
            showTooltip={false}
            disabled={false}
            handleChangeText={(text: string): void => setInput(text)}
            value={input}
            maxLength="200"
          />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                align="right"
                label="Add"
                onClick={handleAdd}
                disabled={
                  type === 'Processed' || viewOnly || isManualIR ||
                  (type === 'Failed' &&
                    !(
                      userInfo &&
                      PERSONA_CONDITION.some(el => userInfo?.personas?.toLowerCase()?.includes(el))
                    ))
                }
                // disabled={viewOnly}
              />
            </Grid>
          </Grid>
        </div>
      </Card>
    </div>
  );
};

export default Comments;
