/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, ReactNode, useState } from 'react';
import Icon from '@salesforce/design-system-react/components/icon';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import InventoryReturnQueueFilter from '../InventoryReturnQueueFilter';
// import './index.scss';

interface Props {
  label: string;
  applyFilters: (filters: object | null) => void;
  handleSearch: (event: any, searchItem: any) => void;
  selectedFilter?: DynamicType | null;
}

interface DynamicType {
  [key: string]: any;
}

interface SelectedData {
  id: string;
  label: string;
  __typename?: string;
}
interface Filter {
  status: SelectedData[];
  salesReps: SelectedData[];
  coveringReps: SelectedData[];
  account: SelectedData[];
  branch: SelectedData[];
  franchise: SelectedData[];
  surgeon: SelectedData[];
  procedureDetail: SelectedData[];
  procedureType?: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  usage: string;
  eventType: string[];
  filterExternalId?: string;
  filterName?: string;
  search?: string;
}
interface FilterPreset {
  filterValues: Filter;
  filterExternalId?: string;
  nameOfFilter?: string;
}

interface DynamicValue {
  [key: string]: any;
}

const InventoryQueueHeader: FC<Props> = ({ label, applyFilters, handleSearch, selectedFilter }) => {
  const [filterInventoryReturnModalVisible, setFilterInventoryReturnModalVisible] = useState(false);

  const handleToggleFilterInventoryReturn = (): void => {
    // handleEditFilter(false);
    // setSelectedFilter(updatedSelectedFilter);
    setFilterInventoryReturnModalVisible(!filterInventoryReturnModalVisible);
  };

  const headerActions = (): ReactNode => (
    <>
      <PageHeaderControl>
        <div className="slds-align-middle">
          <div className="slds-col_padded">
            <Input
              iconRight={
                <InputIcon
                  assistiveText={{
                    icon: 'Search',
                  }}
                  name="search"
                  category="utility"
                />
              }
              id="search-case-id"
              placeholder="Search by ID"
              onChange={(_event: any, searchItem: string): void => handleSearch(_event, searchItem)}
            />
          </div>
        </div>
      </PageHeaderControl>
      <PageHeaderControl className="slds-align-middle">
        <ButtonGroup variant="list" id="button-group-page-header-controls">
          <Button
            iconCategory="utility"
            className="slds-text-color_default"
            iconName="filterList"
            label="Filter"
            iconPosition="left"
            onClick={handleToggleFilterInventoryReturn}
          />
        </ButtonGroup>
      </PageHeaderControl>
    </>
  );

  return (
    <IconSettings iconPath="/icons">
      <PageHeader
        className="transferdetail_header"
        icon={
          <Icon
            assistiveText={{ label: 'Opportunity' }}
            category="standard"
            name="assignment"
            style={{ backgroundColor: '#FFB500' }}
          />
        }
        label={label}
        onRenderActions={headerActions}
        // onRenderControls={headerControls}
        title="Inventory Returns"
        truncate
        variant="object-home"
      />
      <InventoryReturnQueueFilter
        handleToggleFilterInventoryReturn={handleToggleFilterInventoryReturn}
        filterInventoryReturnModalVisible={filterInventoryReturnModalVisible}
        applyFilters={applyFilters}
        selectedFilter={selectedFilter}
      />
    </IconSettings>
  );
};

export default InventoryQueueHeader;
