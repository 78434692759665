import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import EventStatusBadge from '../../Shared/EventStatusBadge';

interface Status {
  status: string;
}
export interface Props {
  children?: FC;
  item?: Status;
}

const OrderStatusCell: FC<Props> = ({
  item = {
    status: '',
  },
}) => {
  const { status } = item;
  return (
    <DataTableCell title={status || 'NEW'}>
      <EventStatusBadge status={status || 'NEW'} />
    </DataTableCell>
  );
};

export default OrderStatusCell;
