/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable-next-line react/jsx-wrap-multilines */
import React, { FC, useRef, useState } from 'react';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import SignaturePad from 'react-signature-canvas';
import './index.scss';

interface Props {
  open: boolean;
  handleToggleModal: () => void;
  handleSaveSignature: (url: string) => void;
}

interface DropdownSelect {
  id: string;
  label?: string;
  value: string;
}

const SignatureCanvas: FC<Props> = ({ open, handleToggleModal, handleSaveSignature }) => {
  const sigCanvas = useRef<SignaturePad>(null);
  const [dataUrl, setDataUrl] = useState('');
  const Clear = (): void => {
    if (sigCanvas.current) {
      setDataUrl('');
      sigCanvas.current.clear();
    }
  };
  const onEnd = (): void => {
    if (sigCanvas.current) {
      const url = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
      setDataUrl(url);
    }
  };
  const Save = (): void => {
    handleSaveSignature(dataUrl);
    Clear();
    handleToggleModal();
  };
  const onCancel = (): void => {
    Clear();
    handleToggleModal();
  };
  return (
    <IconSettings iconPath="/icons">
      <Modal
        className="filter-modal surgery-sheet-pdf default-modal_header default-modal_footer"
        ariaHideApp={false}
        isOpen={open}
        onRequestClose={onCancel}
        footer={[
          <Button label="Cancel" key="Cancel" onClick={onCancel} />,
          <Button label="Clear" key="Clear" onClick={Clear} disabled={!dataUrl} />,
          <Button
            className="yellow-btn"
            label="Save"
            onClick={Save}
            variant="brand"
            key="save"
            disabled={!dataUrl}
          />,
        ]}
        heading={[<div style={{ textAlign: 'start' }}>Signature</div>]}
      >
        <section className="slds-p-around_medium slds-theme_shade">
          <section style={{ background: 'white' }}>
            <div className="slds-p-around_large-1">
              <SignaturePad
                ref={sigCanvas}
                canvasProps={{ className: 'signatureCanvas' }}
                onEnd={onEnd}
              />
            </div>
          </section>
        </section>
      </Modal>
    </IconSettings>
  );
};

export default SignatureCanvas;
