/*
 * Get Branches GraphQL specification
 * @module src/graphql/getViewByPartAndAccount
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_VIEW_BYPART_ACCOUNTS_PARTS = gql`
  query getViewByPartAndAccount(
    $limit: Int
    $offset: Int
    $subInventoryName: String
    $screenType: String!
    $accountnum: String
    $productNumber: String
    $searchText: String
    $businessUnit: [String]
    $filters: PartListFilters
  ) {
    getViewByPartAndAccount(
      limit: $limit
      offset: $offset
      screenType: $screenType
      accountnum: $accountnum
      searchText: $searchText
      businessUnit: $businessUnit
      subInventoryName: $subInventoryName
      productNumber: $productNumber
      filters: $filters
    ) {
      subInventoryName
      productId
      productNumber
      productDescription
      onholdReason
      partonhold
      totalonhandqty
      totalavailableqty
      totalreservedqty
      accountNumber
      accountName
      accountDescription
      isAnylotExpired
      isRepStockAccount
      updatedDate
      updatedBy
      lotDetails {
        id
        lotNumber
        expirationDate
        availableqty
        reservedeqty
        serialRecalled
        onhandqty
        lotonhold
        isExpired
        isExpiredWithin30days
        isExpiredWithin90days
      }
    }
  }
`;
