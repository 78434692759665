/*
 * Franchises GraphQL specification
 * @module src/graphql/getFranchises
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_FRANCHISES = gql`
  query getFranchises($userId: String!) {
    getFranchises(userId: $userId) {
      id: name
      label: name
    }
  }
`;
