/**
 * Module for Event Description Cell.
 * @module src/Events/EventsList
 */
import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import './index.scss';

interface EventDescription {
  eventDescription: string;
}
interface Props {
  item?: EventDescription;
}
const EventsListEventDescriptionCell: FC<Props> = ({
  item = {
    eventDescription: '',
  },
}) => {
  const { eventDescription } = item;

  return (
    <DataTableCell title={eventDescription || ''}>
      <p className="slds-line-clamp">{eventDescription || ''}</p>
    </DataTableCell>
  );
};

/** Custom Event Description cell */
export default EventsListEventDescriptionCell;
