/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * React component for Date Picker.
 * @module src/components/shared/DatePicker
 */
import React, { FC, useCallback } from 'react';
import Datepicker from '@salesforce/design-system-react/components/date-picker';
import { format, isValid } from 'date-fns';
import Icon from '@salesforce/design-system-react/components/icon';
import { warningValidation } from '../../../util/utilityFunctions';
import { INVENTORY_REQUEST_TITLE } from '../../../util/constants';

interface Props {
  label: string;
  placeholder?: string;
  key?: string;
  handleChangeDate: (date: Date | null) => void;
  value: any;
  disabled: boolean;
  required?: boolean;
  isFilterDate?: boolean;
  userData?: any;
  modalHeading?: string;
}

const DatePicker: FC<Props> = props => {
  const {
    label,
    placeholder,
    handleChangeDate,
    value,
    disabled,
    required,
    isFilterDate,
    userData,
    modalHeading,
  } = props;

  let warningMsg = '';
  const validateDate = (data: any): boolean => {
    const letters = /^[A-Za-z]+$/;
    if (
      data.formattedDate &&
      !data.formattedDate.match(letters) &&
      !data.formattedDate.match(/^ *$/)
    ) {
      return true;
    }
    return false;
  };

  const handleChange = useCallback(
    (_event, data: any) => {
      _event.persist();

      const isValidDate = isValid(new Date(data.date));
      const isValidFormattedDate = isValid(new Date(data.formattedDate));
      const date = new Date(data.formattedDate);
      const validDate = validateDate(data);
      // eslint-disable-next-line react-hooks/exhaustive-deps

      if (data && isValidDate && validDate) {
        handleChangeDate(data.date);
      } else if (isValidFormattedDate && validDate) {
        handleChangeDate(date);
      } else {
        handleChangeDate(null);
      }
    },
    [handleChangeDate]
  );

  const dateDisabled = (input: any): boolean => {
    if (!isFilterDate) {
      const d = new Date();
      return input.date < d.setDate(d.getDate() - 1);
    }
    return false;
  };

  const parserFormatter = 'MM-dd-yyyy';
  const formatter = 'M/d/yyyy';
  const dateValue = value && format(new Date(value), formatter);
  warningMsg = warningValidation(label, value, userData);
  const showWarning =
    warningMsg &&
    warningMsg !== '' &&
    modalHeading?.toLowerCase().includes(INVENTORY_REQUEST_TITLE);

  return (
    <div className="slds-form-element slds-m-bottom_small" key={label}>
      {showWarning && (
        <div
          style={{
            width: '100%',
            padding: '10px',
            backgroundColor: 'rgba(255, 181, 0, 0.2980392156862745)',
          }}
        >
          <div style={{ display: 'flex' }}>
            <Icon
              category="utility"
              name="info"
              size="x-small"
              style={{ fill: '#000', marginRight: '12px' }}
            />
            {warningMsg}
          </div>
        </div>
      )}
      <Datepicker
        required={required}
        labels={{
          label,
        }}
        dateDisabled={dateDisabled}
        placeholder={placeholder}
        onChange={handleChange}
        formatter={(date: Date): string => {
          return date ? format(date, formatter) : '';
        }}
        parser={(dateString: string): Date | null => {
          try {
            const date = new Date(dateString);
            return new Date(format(date, parserFormatter));
          } catch {
            return new Date(format(new Date(), parserFormatter));
          }
        }}
        formattedValue={dateValue}
        disabled={disabled}
      />
    </div>
  );
};

/** React component for Date Picker. */
export default DatePicker;
