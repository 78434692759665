/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Module for Custom Rep/Covering Cell.
 * @module src/Events/EventsList
 */
/* eslint-disable no-nested-ternary */
import React, { FC, useEffect } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Button from '@salesforce/design-system-react/components/button';
import { useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import CREATE_UPDATE_CYCLE_COUNT_LINE_ITEMS from '../../../../graphql/mutations/createUpdateCycleCountLineItems';
import { getUserInfo } from '../../../../store/ducks/userInfo';

interface Quantity {
  quantity: string;
  availablequantity: string;
  productdescription: string;
  createdByDetails: any[];
  id: string;
  pendingeimtransaction: string;
  isOpen: boolean;
  needToDisplay: boolean;
  cycleCountExternalId: string;
  cycleCountId: string;
  externalId: string;
  sfid: string;
  isManual: boolean;
  createdBy: string;
}

interface Props {
  item?: Quantity;
  lineItemsData: [Quantity];
  handleDeleteItem: any;
  handleLoading: any;
  status: string;
  countType: string;
  isOps: boolean;
  disabled?: boolean;
}

const CycleCountDelete: FC<Props> = ({
  item = {
    quantity: '',
    availablequantity: '',
    productdescription: '',
    createdByDetails: [],
    id: '',
    pendingeimtransaction: '',
    isOpen: false,
    needToDisplay: false,
    cycleCountExternalId: '',
    cycleCountId: '',
    sfid: '',
    externalId: '',
    isManual: false,
    createdBy: '',
  },
  lineItemsData = [],
  handleDeleteItem = {},
  handleLoading = {},
  status = '',
  countType = '',
  isOps = false,
  disabled,
}) => {
  const { createdByDetails, isOpen, needToDisplay, isManual, createdBy } = item;
  const [createUpdateCycleCountLineItems, { data: eventUsageMutation }] = useMutation(
    CREATE_UPDATE_CYCLE_COUNT_LINE_ITEMS
  );

  const handleUpdates = async (lineItems: any[]) => {
    const mutation = {
      cycleCountExternalId: item.cycleCountExternalId,
      cycleCountId: item.cycleCountId,
      countType,
      lineItems,
    };
    createUpdateCycleCountLineItems({
      variables: mutation,
    })
      .then(() => {
        setTimeout(() => {
          handleDeleteItem();
        }, 1000);
      })
      .catch((error: any) => {
        /* eslint-disable no-console */
        console.log(error, ' - We are having issue updating the line items for Cycle Count');
      });
  };

  const handleDelete = async (product: any) => {
    handleLoading();
    const lineItemsDatas = [...lineItemsData];
    const filtered = lineItemsDatas.filter((itm: any) => itm.externalId === product.externalId);
    let listItems: any[] = [];
    if (isOps) {
      listItems = [
        {
          costVariance: product.costVariance,
          externalId: product.externalId,
          lotNumber: product.lotNumber,
          productCost: product.productCost,
          productId: product.productId,
          productNumber: product.productNumber,
          sfid: product.sfid,
          forceDelete: true,
          quantity: '0',
        },
      ];
    } else {
      listItems = filtered?.[0].createdByDetails.map((i: any) => {
        const items = {
          costVariance: i.costVariance,
          externalId: i.externalId,
          lotNumber: i.lotNumber,
          productCost: i.productCost,
          productId: i.productId,
          productNumber: i.productNumber,
          sfid: i.sfid,
          forceDelete: true,
        };
        return {
          ...items,
          quantity: '0',
        };
      });
    }
    await handleUpdates(listItems);
  };

  const userInfo = useSelector(getUserInfo);
  return needToDisplay ? (
    <DataTableCell title="Action" className="center-align">
      {isOps &&
        isOpen &&
        [...createdByDetails]?.map((part: any, index: number) => (
          <div className="delete-button-container" key={index}>
            <Button
              iconCategory="utility"
              iconName="delete"
              iconSize="large"
              iconVariant="bare"
              variant="icon"
              className="slds-text-color_default"
              disabled={
                status.toLocaleLowerCase() === 'completed' ||
                status.toLocaleLowerCase() === 'cancelled' ||
                disabled
              }
              onClick={(): void => {
                // eslint-disable-next-line no-unused-expressions
                handleDelete(part);
              }}
            />
          </div>
        ))}
      {!isOps &&
        !isOpen &&
        ((!createdByDetails[0]?.lotNumber && !item?.isManual) || item?.isManual) && (
          <div>
            <Button
              iconCategory="utility"
              iconName="delete"
              iconSize="large"
              iconVariant="bare"
              variant="icon"
              className="slds-text-color_default"
              disabled={
                (status.toLocaleLowerCase() !== 'new' &&
                  status.toLocaleLowerCase() !== 'in progress') ||
                disabled
              }
              onClick={(): void => {
                // eslint-disable-next-line no-unused-expressions
                handleDelete(item);
              }}
            />
          </div>
        )}
    </DataTableCell>
  ) : null;
};

/** Custom Rep/Covering cell */
export default CycleCountDelete;
