/*
 * Filtered Procedure Types GraphQL specification
 * @module src/graphql/getFilteredProductGroups
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Filtered Procedure Types GraphQL API query */
export const GET_FILTERED_PRODUCT_GROUPS = gql`
  query getProductGroups(
    $caseExternalId: String
    $caseId: String
    $salesRepId: String
    $tabName: String
  ) {
    getProductGroups(
      caseExternalId: $caseExternalId
      caseId: $caseId
      salesRepId: $salesRepId
      tabName: $tabName
    ) {
      id
      value
    }
  }
`;
