/*
 * Event Details GraphQL specification
 * @module src/graphql/getInventoryReturnDetails
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Event Details GraphQL API query */
export const GET_INVENTORY_RETURN_DETAILS = gql`
  query getInventoryReturnDetails($id: String) {
    getInventoryReturnDetails(id: $id) {
      orderId
      orderExternalId
      salesRepId
      assignedOPSId
      status
      type
      createdDate
      orderNumber
      fromSubInventoryCode
      toSubInventoryCode
      salesOrganization
      accountId
      accountNumber
      accountName
      salesRepName
      assignedOPSName
      visualIndicator
      shipmentDate
      submittedDate
      returnExpiryDate
      trackingNumber
      returnReason
      territoryNumber
      returnType
      shippingAddessLine1
      shippingAddessLine2
      shippingPostalcode
      shippingCity
      shippingState
      shipToId
      shippingAddressNumber
      isshippingLabelRequired
      liftGateNeeded
      dockAvailable
      shortTruckOnly
      onsiteContractName
      onsiteContractPhone
      onsiteContractEmail
      onsiteContractInstructions
      isPickupDetailsAdded
      createdDate
      updatedDate
      updatedBy
      createdBy
      salesRepNameIns
      salesRepEmailIns
      salesRepPhoneIns
      shippingInstructions
      shipDate
      isCycleCountInitiated
      comments {
        id
        externalId
        value: comments
        type
      }
      lineItems {
        id
        iscatalog
        sourceLocation
        externalId
        productId
        lotNumber
        quantity
        expirationDate
        productNumber
        status
        workflowAction
        type
        productDescription
        availableQuantity
        onhandQuantity
        lineVisualIndicator
        productOnHold
        returnReason
        trackingNumber
        svmxcTracking
        isLotNumber
        caseUsageSubInventoryCode
        sourceLocation
        lotValidation
      }
      receivedLineItems {
        id
        externalId
        productId
        lotNumber
        quantity
        expirationDate
        productNumber
        status
        workflowAction
        type
        productDescription
        availableQuantity
        onhandQuantity
        lineVisualIndicator
        productOnHold
        returnReason
        trackingNumber
        receivedQuantity
        erpOrderNumber
        receivedDate
        isLotNumber
        lotValidation
      }
      attachments {
        id
        caseId
        caseExternalId
        recordType
        attachmentType
        name
        createdBy
        createdAt
        imageurl
        user
      }
      shippingLabelAttachments {
        id
        caseId
        caseExternalId
        recordType
        attachmentType
        name
        createdBy
        createdAt
        imageurl
        user
      }
      parentEventId
      parentEventType
      parentEventStatus
      parentCaseExternalId
      parentCaseId
      parentEventSubType
    }
  }
`;
