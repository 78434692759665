/**
 * Graphql mutation to cancel event
 * @module src/graphql/cancelInventory
 */
import gql from 'graphql-tag';

/** creating event */
const CANCEL_INVENTORY = gql`
  mutation cancelEvent(
    $sfid: String
    $externalId: String
    $comments: String
    $reason: String
    $requestedFrom: String
  ) {
    cancelEvent(
      sfid: $sfid
      externalId: $externalId
      comments: $comments
      reason: $reason
      requestedFrom: $requestedFrom
    ) {
      message
    }
  }
`;

export default CANCEL_INVENTORY;
