import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import { getUSDString } from '../../../util/utilityFunctions';

//  Interface for Data table data type for all the columns
export interface Props {
  children?: FC;
  item?: AccountEventName;
}

interface AccountEventName {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  totalAmount?: any;
}
const OrderTotalAmountCell: FC<Props> = ({
  item = {
    totalAmount: '0',
  },
}) => {
  const { totalAmount } = item;
  return (
    <DataTableCell title={totalAmount || ''}>
      <p style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
        {totalAmount ? getUSDString(totalAmount.toString()) : '0'}
      </p>
    </DataTableCell>
  );
};

/** Custom Account/Event Name cell */
export default OrderTotalAmountCell;
