/*
 * Event Details GraphQL specification
 * @module src/graphql/getInventoryRequestDetails
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Event Details GraphQL API query */
export const GET_INVENTORY_FILTER_PRESETS = gql`
  query getInventoryFilterPresets {
    getInventoryFilterPresets {
      _id
      nameOfFilter
      user
      serviceLevel
      salesRep
      userDetails {
        sfId
        name
      }
      territory {
        id
        name
      }
      status
      assignedTo
      recordType
      account
      threePL
      productGroup
      cost {
        value
      }
      submittedDate {
        from
        to
      }
      needByDate {
        from
        to
      }
    }
  }
`;
