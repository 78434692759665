/*
 * Get Covering Reps GraphQL specification
 * @module src/graphql/getCoveringReps
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_COVERING_REPS = gql`
  query getCoveringReps($salesRepId: [String], $filters: CoveringRepFilters) {
    getCoveringReps(salesRepId: $salesRepId, filters: $filters) {
      id
      value
      hospital
      owned
    }
  }
`;
