/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-one-expression-per-line */
/**
 * Module for header on Surgical Detail Page
 * @module src/SurgicalDetail/SurgicalDetailHeader
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import Spinner from '@salesforce/design-system-react/components/spinner';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import Combobox from '@salesforce/design-system-react/components/combobox';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import escapeRegExp from 'lodash.escaperegexp';
import CartComponent from '../InventoryRequestDetailCatalog/cartComponent';
import debounced from '../../../../util/debounced';
import { GET_PRODUCT_LIST } from '../../../../graphql/getProductList';
import CREATE_UPDATE_USAGE from '../../../../graphql/mutations/createdUpdateUsage';
import './index.scss';
import SnackBar from '../../../Shared/SnackBar';
import { MAX_QUANTITY, NOTIFY_TYPE } from '../../../../util/constants';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import RESUBMIT_ORDER_DETAILS from '../../../../graphql/mutations/resubmitOrderDetails';
import { GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS } from '../../../../graphql/getSubInventoryWithLotDetails';
import { GET_USER_TERRITORIES } from '../../../../graphql/getUserTerritories';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import { GET_FILTERED_PRODUCT_GROUPS } from '../../../../graphql/getFilteredProductGroups';
import { getHoldIcon, calculateActualQuantitity } from '../../../../util/utilityFunctions';

// import AutocompleteSingleSelectPicker from '../../../Shared/AutocompleteMultiSelectPicker';

interface Props extends RouteComponentProps {
  isOpen?: boolean;
  handleClose: () => void;
  refetch?: () => void;
  handleAddClick?: (list: any) => void;
  setShowSearchCatalogModal?: (check: boolean) => void;
  inventoryDetail?: any;
  history: any;
  isEventInventoryFlow?: boolean;
  isEdit?: boolean;
  type?: string;
  viewOnly?: boolean;
  handleFilter: (filter: DynamicType | null) => void;
  selectedFilter?: DynamicType | null;
  quantityCount: number;
}

interface DynamicType {
  [key: string]: any;
}
interface ValueInterface {
  value: string;
}

interface Selection {
  name: string;
  __typename: string;
  id: string;
  label: string;
  value?: string;
}
interface SelectedData {
  selection: Selection[];
}
type SelectionType = Selection[];

enum DetailTypes {
  ProductGroup = 'ProductGroup',
}

const InventoryRequestAddItems: FC<Props> = ({
  isOpen,
  handleClose,
  inventoryDetail,
  isEdit = false,
  type,
  refetch,
  quantityCount,
  setShowSearchCatalogModal,
  handleAddClick,
  selectedFilter,
  handleFilter,
  // history,
  // isEventInventoryFlow,
}) => {
  const userInfo = useSelector(getUserInfo);
  const { open, notification, openSnackBar } = useSnackBar();
  const [isLoading, setLoader] = useState(false);
  const [catalogCount, setCatalogCount] = useState(quantityCount);
  const [subInvCount, setSubInvCount] = useState(0);
  const [isopen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [productList, setProductList] = useState<any[]>([]);
  const [productListUpdated, setProductListUpdated] = useState<any[]>([]);
  const [addPartsLocally, setAddPartsLocally] = useState<any[]>([]);
  const [subInventoryLotInfo, setSubInventoryLotInfo] = useState<any>([]);
  const [productListItems, { data: productItems, loading }] = useLazyQuery(GET_PRODUCT_LIST);
  const [userTerritories, setUserTerritories] = useState();
  const [
    getSubInventoryWithLotDetails,
    { data: subInventoryWithLotDetails, loading: loadingSubInventory },
  ] = useLazyQuery(GET_SUBINVENTORY_LIST_WITH_LOT_DETAILS);
  const [reSubmitInventory] = useMutation(RESUBMIT_ORDER_DETAILS);
  const [getUserTerritoriesItems, { data: userTerritoriesItems }] = useLazyQuery(
    GET_USER_TERRITORIES,
    {
      variables: {
        userId: userInfo?.id,
      },
    }
  );
  // eslint-disable-next-line prefer-const
  let [cartCount, setCartCount] = useState(0);
  const [createUpdateUsage, { data: eventUsageMutation }] = useMutation(CREATE_UPDATE_USAGE);
  const filterValues = selectedFilter?.filterValues;
  const [selectedFilters, setSelectedFilters] = useState<DynamicType | null>(filterValues || {});
  const [searchTerm, setSearchText] = useState('');
  const [selectedGrps, setSelectedGrps] = useState<any>(
    selectedFilters?.productGroup?.map((item: any) => item.id) || []
  );
  const updateProducts: Array<any> = productListUpdated;
  const getProductRecord = (product: any): any => {
    let returnVal = null;
    if (inventoryDetail && inventoryDetail.lineItems && inventoryDetail.lineItems.length) {
      returnVal = inventoryDetail.lineItems.find((rec: any) => rec.productId === product.productId);
    }
    return returnVal;
  };

  useEffect(() => {
    if (!isEdit) {
      if (productItems && productItems.getProductList && productItems.getProductList.length) {
        productItems.getProductList.forEach((list: any) => {
          const record = getProductRecord(list);
          /* eslint-disable no-param-reassign */
          const changedIndex = addPartsLocally.find(
            (index: any) => index.productId === list.productId
          );
          list.counterValue = record ? Number(record.quantity) : 0;
          if (changedIndex) {
            list.counterValue = changedIndex.counterValue;
          }
          if (record) {
            list.usageExternalId = record.usageExternalId;
            list.alreadyAdded = true;
          }
          list.changed = false;
          productListUpdated.forEach((data: any) => {
            if (data.productId === list.productId) {
              list.changed = false;
              list.counterValue = data.counterValue;
            }
          });
        });
        setProductList(productItems.getProductList);
        setCartCount(catalogCount);
      } else {
        setProductList([]);
      }
    } else {
      let counter = 0;
      const updateList = inventoryDetail.lineItems.map((record: any) => {
        counter += Number(record.quantity);
        return {
          ...record,
          changed: false,
          counterValue: Number(record.quantity),
        };
      });
      setProductList(updateList);
      setCatalogCount(counter);
    }
  }, [productItems]);
  const holdIconPath = getHoldIcon();
  const getProductLotRecord = useCallback(
    (product: any): any => {
      let returnVal = null;
      if (inventoryDetail && inventoryDetail.lineItems && inventoryDetail.lineItems.length) {
        returnVal = inventoryDetail.lineItems.find(
          (rec: any) => rec.lotNumber === product.lotNumber
        );
      }
      return returnVal;
    },
    [inventoryDetail]
  );

  useEffect(() => {
    getUserTerritoriesItems();
    if (userTerritoriesItems?.getUserTerritories) {
      const userTerritoriesItemsList = userTerritoriesItems?.getUserTerritories;
      if (userTerritoriesItemsList && userTerritoriesItemsList.length > 0) {
        setUserTerritories(userTerritoriesItemsList[0].value);
      }
    }
  }, [getUserTerritoriesItems, userTerritoriesItems]);

  useEffect(() => {
    // const output: any = ([] = [{ subInventoryName: userTerritories, lotInfo: [] }]);
    if (subInventoryWithLotDetails) {
      const searchedSubInventoryWithLot = subInventoryWithLotDetails?.getSubInventoryWithLotDetails;

      if (searchedSubInventoryWithLot.length) {
        const finalSubInvWithLotInfo: Array<any> = [];
        const productDescriptionLIst: Array<string> = [];
        searchedSubInventoryWithLot.forEach((record: any) => {
          /* eslint-disable no-param-reassign */
          const LotProduct = getProductLotRecord(record);
          record.counterValue = LotProduct ? Number(LotProduct.quantity) : 0;
          record.changed = false;
          record.maxPermissibleValue = Number(record.availableQuantity);
          if (productDescriptionLIst.indexOf(record.productDescription) >= 0) {
            const index = finalSubInvWithLotInfo.findIndex(
              (entry: any) => entry.name === record.productDescription
            );

            finalSubInvWithLotInfo[index].value.push(record);
          } else {
            finalSubInvWithLotInfo.push({
              name: record.productDescription,
              productNumber: record.productNumber,
              productOnHold: record.productOnHold,
              productOnHoldType: record.productOnHoldType,
              value: [record],
            });
            productDescriptionLIst.push(record.productDescription);
          }
        });
        setSubInventoryLotInfo(finalSubInvWithLotInfo);
      }
    }
  }, [getProductLotRecord, subInventoryWithLotDetails]);

  useEffect(() => {
    if (
      eventUsageMutation &&
      eventUsageMutation.createUpdateUsage &&
      eventUsageMutation.createUpdateUsage.message === 'success'
    ) {
      handleClose();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [eventUsageMutation]);

  const setEventPartQuantity = (searchedItem: string): void => {
    if (searchedItem.length > 2) {
      productListItems({
        variables: {
          searchText: searchedItem,
          caseId: inventoryDetail.caseId ? inventoryDetail.caseId : null,
          caseExternalId: inventoryDetail.caseExternalId ? inventoryDetail.caseExternalId : null,
          salesRepId: inventoryDetail.salesRepId ? inventoryDetail.salesRepId : null,
          filters: {
            productName: selectedGrps,
            businessUnit: [userInfo?.businessunit] || ['Sports Med'],
            userId: userInfo?.id,
          },
        },
      });
    } else {
      productListItems({
        variables: {
          searchText: '',
          caseId: inventoryDetail.caseId ? inventoryDetail.caseId : null,
          caseExternalId: inventoryDetail.caseExternalId ? inventoryDetail.caseExternalId : null,
          salesRepId: inventoryDetail.salesRepId ? inventoryDetail.salesRepId : null,
          filters: {
            productName: selectedGrps,
            businessUnit: [userInfo?.businessunit] || ['Sports Med'],
            userId: userInfo?.id,
          },
        },
      });
    }
  };

  const handleChangeOnType = useCallback(
    (_event, selectedValue: ValueInterface) => {
      const { value } = selectedValue;
      _event.persist();
      setInputValue(value);
    },
    [setInputValue]
  );
  const comboboxFilterAndLimit = (parameters: any): SelectionType => {
    const { searchedValue, options, selection } = parameters;
    const inputValueRegExp = new RegExp(escapeRegExp(searchedValue), 'ig');
    return options.filter((option: Selection) => {
      const searchTermFound = option.label ? option.label.match(inputValueRegExp) : false;
      const isSeparator = false;
      const notAlreadySelected = !selection.some((sel: Selection) => sel.id === option.id);

      return (!searchedValue || isSeparator || searchTermFound) && notAlreadySelected;
    });
  };
  const debouncedFetchSetItems = useCallback(debounced(500, setEventPartQuantity), [
    debounced,
    setEventPartQuantity,
  ]);

  // Get default Parts on load
  useEffect(() => {
    if (!isEdit) {
      productListItems({
        variables: {
          limit: 50,
          offset: 0,
          caseId: inventoryDetail.caseId ? inventoryDetail.caseId : null,
          caseExternalId: inventoryDetail.caseExternalId ? inventoryDetail.caseExternalId : null,
          salesRepId: inventoryDetail.salesRepId ? inventoryDetail.salesRepId : null,
          searchText: '',
          filters: {
            productName: selectedGrps,
            businessUnit: [userInfo?.businessunit] || ['Sports Med'],
            userId: userInfo?.id,
          },
        },
      });
      let counter = 0;
      const updateList = inventoryDetail.lineItems.map((record: any) => {
        counter += Number(record.quantity);
        return {
          ...record,
          changed: false,
          counterValue: Number(record.quantity),
        };
      });
      setCartCount(counter);
      setCatalogCount(counter);
    }
  }, [productListItems, isEdit]);

  const searchCatalog = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      setSearchText(searchedItem.value);
      const { value } = searchedItem;
      _event.persist();
      //   setInputValue(value);
      if (value && value.length >= 2) {
        debouncedFetchSetItems(value);
      }
    },
    [debouncedFetchSetItems]
  );

  const setSubInventoryQuantity = (searchedItem: string): void => {
    if (searchedItem.length > 2) {
      getSubInventoryWithLotDetails({
        variables: {
          subInventoryName: userTerritories,
          filters: {
            searchText: searchedItem,
          },
        },
      });
    } else {
      getSubInventoryWithLotDetails({
        variables: {
          subInventoryName: userTerritories,
          filters: {
            searchText: '',
          },
        },
      });
    }
  };

  const debouncedFetchSubInventoryItems = useCallback(debounced(500, setSubInventoryQuantity), [
    debounced,
    setSubInventoryQuantity,
  ]);

  // Get default Sub Inventories on load
  useEffect(() => {
    if (!isEdit) {
      getSubInventoryWithLotDetails({
        variables: {
          limit: 50,
          offset: 0,
          subInventoryName: userTerritories,
          filters: {
            searchText: '',
          },
        },
      });
    }
  }, [getSubInventoryWithLotDetails, isEdit, userTerritories]);

  const searchSubInventory = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      //   setInputValue(value);
      if (value && value.length >= 2) {
        debouncedFetchSubInventoryItems(value);
      }
    },
    [debouncedFetchSubInventoryItems]
  );
  const handleCount = (event: any, data: { value: string; number: number }, product: any): void => {
    const updatedList = [...productList];
    let partsCounter = catalogCount;
    let decremenmtFlag = false;
    updatedList.forEach((element: any, i: number) => {
      if (
        product.productId === element.productId &&
        product.usageExternalId === element.usageExternalId
      ) {
        if (product.counterValue > data.number) cartCount -= product.counterValue - data.number;
        else {
          cartCount += data.number - product.counterValue;
        }
        /* eslint-disable no-param-reassign */
        decremenmtFlag = data.number < element.counterValue;
        element.counterValue = data.number < MAX_QUANTITY ? data.number : MAX_QUANTITY;
        element.changed = true;
        partsCounter += element.changed ? 1 : 0;
        const addedIndex = addPartsLocally.findIndex((e: any) => e.productId === element.productId);
        if (addedIndex > -1) {
          addPartsLocally[addedIndex] = updatedList[i];
        } else {
          addPartsLocally.push(updatedList[i]);
        }
      }
    });
    setAddPartsLocally(addPartsLocally);
    setProductList(updatedList);
    //     if (isEdit) setCatalogCount(counter);
    // else {
    //   setCatalogCount(cartCount);
    //   setCartCount(cartCount);
    // }
    if (partsCounter < catalogCount) {
      partsCounter = partsCounter === 1 && !decremenmtFlag ? catalogCount + 1 : partsCounter;
    } else if (decremenmtFlag) {
      partsCounter -= 2;
    }
    setCatalogCount(partsCounter);
  };

  const handleCountalert = (
    event: any,
    data: { value: string; number: number },
    product: any
  ): void => {
    if (product?.productOnHold) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );
    }
    handleCount(event, data, product);
  };

  const handleSubInvCount = (
    event: any,
    data: { value: string; number: number },
    subInv: any
  ): void => {
    const subInvWithLotInfo = [...subInventoryLotInfo];
    let counter = 0;
    subInvWithLotInfo.forEach((element: any) => {
      if (element.name === subInv.productDescription) {
        element.value.forEach((data1: any) => {
          if (data1.lotNumber === subInv.lotNumber) {
            data1.changed = true;
            data1.counterValue = data.number;
          }
          counter += data1.counterValue;
        });
      }
    });
    setSubInventoryLotInfo(subInvWithLotInfo);
    setSubInvCount(counter);
  };

  // const resubmitInventory = (): void => {
  //   const payload: Array<any> = [];
  //   productList.forEach((product: any): void => {
  //     if (product.changed) {
  //       payload.push({
  //         ...product,
  //         changed: false,
  //         quantity: product.counterValue.toString(),
  //       });
  //     }
  //   });
  //   setProductList(payload);
  //   if (handleAddClick && setShowSearchCatalogModal) {
  //     handleAddClick(payload);
  //     setShowSearchCatalogModal(true);
  //   }
  // };

  const formatLineItemToReSubmit = (lineItems: any): any => {
    const finalLineItems: Array<any> = [];
    lineItems.map(
      (item: {
        productId: any;
        productNumber: any;
        quantity: any;
        actualQuantity: any;
        externalId: any;
        lotNumber: any;
        sfid: any;
        unitPrice: any;
        priceBookEntryId: any;
        salesUnitOfMeasure: any;
        deliveredDate: any;
      }): any => {
        finalLineItems.push({
          priceBookEntryId: item.priceBookEntryId,
          salesUnitOfMeasure: item.salesUnitOfMeasure,
          deliveredDate: item.deliveredDate,
          productId: item.productId,
          productNumber: item.productNumber,
          quantity: item.quantity,
          actualQuantity: item.actualQuantity || '',
          lotNumber: item.lotNumber || '',
          externalId: null,
          sfid: null,
          caseUsageId: null,
          caseUsageExternalId: null,
          unitPrice: item.unitPrice || '',
        });
      }
    );
    return finalLineItems;
  };

  const { data: filteredproductGroups, loading: loadingProductGroup } = useQuery(
    GET_FILTERED_PRODUCT_GROUPS,
    {
      variables: {
        caseId: inventoryDetail.caseId ? inventoryDetail.caseId : null,
        caseExternalId: inventoryDetail.caseExternalId ? inventoryDetail.caseExternalId : null,
        salesRepId: inventoryDetail.salesRepId ? inventoryDetail.salesRepId : null,
      },
    }
  );

  const optionsWithLabel = (value: any): object =>
    value &&
    value.map((elem: { __typename: string; name: any; number: any; value: any }) => {
      // eslint-disable-next-line no-underscore-dangle
      if (elem.__typename === DetailTypes.ProductGroup) {
        return { ...elem, label: `${elem.value}` };
      }
    });
  const productGroup = optionsWithLabel(filteredproductGroups?.getProductGroups);

  const setFilterValue = useCallback(
    (key: any, filterValuesObj: any): void => {
      // For selected filter value
      const selectedFilterValues = { ...selectedFilters, [key]: filterValuesObj };

      setSelectedFilters(selectedFilterValues);
    },
    [selectedFilters]
  );

  const handleProductList = (selectedItem: any): void => {
    const selectedOptions = selectedItem?.map((item: any) => item.id);
    setSelectedGrps(selectedOptions);
    productListItems({
      variables: {
        filters: {
          productName: selectedOptions,
          businessUnit: [userInfo?.businessunit] || ['Sports Med'],
          userId: userInfo?.id,
        },
        caseId: inventoryDetail.caseId ? inventoryDetail.caseId : null,
        caseExternalId: inventoryDetail.caseExternalId ? inventoryDetail.caseExternalId : null,
        salesRepId: inventoryDetail.salesRepId ? inventoryDetail.salesRepId : null,
        searchText: searchTerm,
      },
    });
  };

  const handleSelect = useCallback(
    (data: any, name: string) => {
      const { selection } = data;
      setFilterValue(name, data);
      handleProductList(data || []);
    },
    [handleProductList, setFilterValue]
  );
  const handleSelectItem = useCallback(
    (_event, data: SelectedData) => {
      const { selection } = data;
      setInputValue('');
      const updatedSelection: Selection[] = Object.values(
        selection.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
      );
      setOpen(false);
      handleSelect(updatedSelection, 'productGroup');
    },
    [setInputValue, handleSelect]
  );

  const onAddClick = (): void => {
    handleFilter(selectedFilters);
    setLoader(true);
    if (type === 'Failed') {
      // resubmitInventory();
      const payload: Array<any> = [];
      if (!isEdit) {
        addPartsLocally.forEach((product: any): void => {
          if (product.changed) {
            const actualQuantity = calculateActualQuantitity(product, product.counterValue);
            payload.push({
              productId: product.productId,
              quantity: product.counterValue.toString(),
              actualQuantity: actualQuantity.toString(),
              productNumber: product.productNumber,
              priceBookEntryId: product.priceBookEntryId,
              salesUnitOfMeasure: product.salesUnitOfMeasure,
              deliveredDate: null,
              ...(product.usageExternalId && { usageExternalId: product.usageExternalId }),
            });
          }
        });
      } else {
        productList.forEach((product: any): void => {
          if (product.changed) {
            const actualQuantity = calculateActualQuantitity(product, product.counterValue);
            payload.push({
              productId: product.productId,
              quantity: product.counterValue.toString(),
              actualQuantity: actualQuantity.toString(),
              productNumber: product.productNumber,
              priceBookEntryId: product.priceBookEntryId,
              salesUnitOfMeasure: product.salesUnitOfMeasure,
              deliveredDate: null,
              ...(product.usageExternalId && { usageExternalId: product.usageExternalId }),
            });
          }
        });
      }
      const mutation = {
        id: inventoryDetail.id,
        caseId: inventoryDetail.caseId,
        caseExternalId: inventoryDetail.caseExternalId,
        externalId: inventoryDetail.externalId,
        shippingId: inventoryDetail.shippingId,
        accountId: inventoryDetail.accountId,
        billingId: inventoryDetail.billingId,
        shipMethod: inventoryDetail.shipMethod,
        serviceLevel: inventoryDetail.serviceLevel,
        salesRepId: inventoryDetail.salesRepId,
        receivingRepId: inventoryDetail.receivingRepId,
        fromSubInventoryCode: inventoryDetail.fromSubInventoryCode,
        toSubInventoryCode: inventoryDetail.toSubInventoryCode,
        salesOrganization: inventoryDetail.salesOrganization,
        needByDate: inventoryDetail.needByDate,
        requestedFrom: 'InventoryRequest',
        submitType: 'Update',
        // comments: userComment,
        lineItems: formatLineItemToReSubmit(payload),
      };
      reSubmitInventory({
        variables: mutation,
      }).then((res: any) => {
        setLoader(false);
        if (res && res.data && res.data.resubmitOrderDetails?.message === NOTIFY_TYPE.SUCCESS) {
          handleClose();
        }
      });
    } else {
      const payload: Array<any> = [];
      if (!isEdit) {
        addPartsLocally.forEach((product: any): void => {
          if (product.changed) {
            const actualQuantity = calculateActualQuantitity(product, product.counterValue);
            payload.push({
              productId: product.productId,
              quantity: product.counterValue.toString(),
              productNumber: product.productNumber,
              actualQuantity: actualQuantity.toString(),
              baseorCapital: product.baseorCapital,
              workflowAction: type === 'Review' ? 'No Action' : null,
              ...(product.usageExternalId && { usageExternalId: product.usageExternalId }),
            });
          }
        });
      } else {
        productList.forEach((product: any): void => {
          if (product.changed) {
            payload.push({
              productId: product.productId,
              quantity: product.counterValue.toString(),
              productNumber: product.productNumber,
              baseorCapital: product.baseorCapital,
              ...(product.usageExternalId && { usageExternalId: product.usageExternalId }),
              ...(product.productFamily === 'SPTSIMPLANTS' && {
                actualQuantity: (product.counterValue * product.packContent).toString(),
              }),
              ...(!(product.productFamily === 'SPTSIMPLANTS') && {
                actualQuantity: product.counterValue.toString(),
              }),
            });
          }
        });
      }

      // subInventoryLotInfo.forEach((subinvenTory: any): void => {
      //   subinvenTory.value.forEach((lotInfo: any) => {
      //     if (lotInfo.changed) {
      //       payload.push({
      //         productId: lotInfo.productId,
      //         quantity: lotInfo.counterValue.toString(),
      //         productNumber: lotInfo.productNumber,
      //         ...(lotInfo.usageExternalId && { usageExternalId: lotInfo.usageExternalId }),
      //         ...(lotInfo.productFamily === 'SPTSIMPLANTS' && {
      //           actualQuantity: (lotInfo.counterValue * lotInfo.packContent).toString(),
      //         }),
      //         ...(!(lotInfo.productFamily === 'SPTSIMPLANTS') && {
      //           actualQuantity: lotInfo.counterValue.toString(),
      //         }),
      //       });
      //     }
      //   });
      // });
      const mutation = {
        caseId: inventoryDetail.caseId,
        caseExternalId: inventoryDetail.caseExternalId,
        accountId: inventoryDetail.accountId,
        branchId: inventoryDetail.branchId,
        salesRepId: inventoryDetail.salesRepId,
        shipToId: inventoryDetail.shippingId,
        serviceLevel: inventoryDetail.serviceLevel,
        needByDate: inventoryDetail.needByDate,
        requestedFrom: 'Inventory',
        deliveryContractEmail: inventoryDetail.deliveryContractEmail,
        deliveryContractInstructions: inventoryDetail.deliveryContractInstructions,
        deliveryContractName: inventoryDetail.deliveryContractName,
        deliveryContractPhone: inventoryDetail.deliveryContractPhone,
        dockAvailable: inventoryDetail.dockAvailable,
        isShippingDetails: inventoryDetail.isShippingDetails,
        liftGateNeeded: inventoryDetail.liftGateNeeded,
        shortTruckOnly: inventoryDetail.shortTruckOnly,
        usageParts: payload,
        system: 'Web',
        version: process.env.REACT_APP_VERSION || '0.0.1',
      };
      createUpdateUsage({
        variables: mutation,
      }).catch((error: any) => {
        openSnackBar(NOTIFY_TYPE.ERROR, 'Error: Adding Line Items to Inventory');
        /* eslint-disable no-console */
        console.log(error, ' - We are having issue updating the line items for Inventory Return');
      });
    }
  };

  // const dateCompare = (d1: any, d2: any): any => {
  //   const date1 = new Date(d1);
  //   const date2 = new Date(d2);

  //   if (date1 > date2) {
  //     // console.log(`${d1} is greater than ${d2}`)
  //     return false;
  //   }
  //   // console.log(`${d2} is greater than ${d1}`)
  //   return true;
  // };
  const handleDelete = (product: any): void => {
    // empty/clear quantity, it will delete ib Backend
    let counter = 0;
    const listItems = [...productList];
    listItems.forEach((i: any) => {
      if (i.productId === product.productId && i.usageExternalId === product.usageExternalId) {
        i.counterValue = 0;
        i.changed = true;
        i.deleted = true;
      }
      counter += i.counterValue;
    });
    setCatalogCount(counter);
    setAddPartsLocally(listItems || []);
  };

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      <div>
        <Modal
          isOpen={isOpen}
          dismissOnClickOutside={false}
          footer={[
            <Button
              label="Cancel"
              onClick={() => {
                handleClose();
                handleFilter(selectedFilters);
              }}
            />,
            <Button label={isEdit ? 'Update' : 'Add'} variant="brand" onClick={onAddClick} />,
          ]}
          onRequestClose={() => {
            handleClose();
            handleFilter(selectedFilters);
          }}
        >
          <div style={{ display: 'grid', padding: '0.75rem', gridTemplateColumns: '1fr 1fr' }}>
            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {isEdit ? 'Edit Parts' : 'Add Parts'}
            </div>
            <div style={{ textAlign: 'end' }}>
              <CartComponent count={catalogCount + subInvCount} color="black" textColor="white" />
            </div>
          </div>
          <div style={{ padding: '0rem 0.75rem 0rem 0.75rem' }}>
            {isLoading && <Spinner />}
            {!isEdit ? (
              <Tabs id="tabs-example-default" style={{ border: 'none' }}>
                {/* <TabsPanel label="My Sub Inventory">
                  <div>
                    <Input
                      assistiveText={{ spinner: 'Field data is loading' }}
                      iconRight={
                        <InputIcon
                          assistiveText={{
                            icon: 'Search',
                          }}
                          name="search"
                          category="utility"
                        />
                      }
                      onChange={(_event: any, searchItem: string): void =>
                        searchSubInventory(_event, searchItem)
                      }
                      hasSpinner={loadingSubInventory}
                      id="unique-id-4"
                      placeholder="Search My Sub Inventory"
                    /> 
                    <section className="slds-p-around_large-1">
                      {subInventoryLotInfo.map((subInv: any) => (
                        <div className="inventory-return-lot-info-name">
                          <div style={{ marginTop: '5px' }}>
                            {`${subInv.productNumber}-${
                              subInv.name ? subInv.name.toUpperCase() : '--'
                            }`}
                          </div>
                          {subInv.value &&
                            subInv.value.map((value: any) => (
                              <div className="inventory-return-lot-info">
                                {dateCompare(
                                  moment(value.expirationDate).format('MM/DD/YYYY'),
                                  moment().format('MM/DD/YYYY')
                                ) ? (
                                  <div style={{ color: 'red' }}>
                                    Lot # - {value.lotNumber || '--'} <br />
                                    Expiration Date:
                                    {value.expirationDate
                                      ? moment(value.expirationDate).format('MM/DD/YYYY')
                                      : '--'}
                                  </div>
                                ) : (
                                  <div>
                                    Lot # - {value.lotNumber || '--'} <br />
                                    Expiration Date:
                                    {value.expirationDate
                                      ? moment(value.expirationDate).format('MM/DD/YYYY')
                                      : '--'}
                                  </div>
                                )}
                                <Input
                                  id="counter-input-3"
                                  minValue={0}
                                  maxValue={value.maxPermissibleValue}
                                  onChange={(
                                    event: any,
                                    data: { value: string; number: number }
                                  ): void => {
                                    handleSubInvCount(event, data, value);
                                  }}
                                  value={value.counterValue}
                                  variant="counter"
                                />
                              </div>
                            ))}
                          <div />
                        </div>
                      ))}
                    </section>
                  </div>
                </TabsPanel>*/}
                <TabsPanel label="Catalog">
                  <div>
                    <div className="full-width_button-product" id="product-catalog">
                      {/* <Combobox
                        id="product"
                        events={{
                          onSelect: (event: any, data: any): void =>
                            handleSelect(data, 'productGroup'),
                          onRequestRemoveSelectedOption: (event: any, data: any): void =>
                            handleSelect(data, 'productGroup'),
                        }}
                        labels={{
                          label: `Select Product Group`,
                          placeholder: 'Select Product Group',
                          noOptionsFound: 'No data found',
                        }}
                        multiple
                        placeholder="Select Product Group"
                        options={productGroup || []}
                        // hasInputSpinner={loadingProductGroup}
                        variant="readonly"
                        selection={selectedFilters ? selectedFilters.productGroup : []}
                      /> */}
                      <Combobox
                        id="product"
                        options={comboboxFilterAndLimit({
                          searchedValue: inputValue,
                          options: productGroup || [],
                          selection: selectedFilters?.productGroup || [],
                        })}
                        events={{
                          onChange: handleChangeOnType,
                          onRequestRemoveSelectedOption: (event: any, data: any): void => {
                            handleSelect(data.selection, 'productGroup');
                          },
                          onSelect: handleSelectItem,
                        }}
                        optionsAddItem={[]}
                        labels={{
                          label: `Product Group`,
                          placeholder: 'Select Product Group',
                          noOptionsFound: 'No data found',
                        }}
                        disabled={false}
                        multiple
                        placeholder="Select Product Group"
                        menuPosition="relative"
                        value={inputValue}
                        hasInputSpinner={loadingProductGroup}
                        selection={selectedFilters ? selectedFilters.productGroup || [] : []}
                        variant="base"
                      />
                    </div>
                    {!isEdit && (
                      <div className="catalog-input">
                        <Input
                          assistiveText={{ spinner: 'Field data is loading' }}
                          iconRight={
                            <InputIcon
                              assistiveText={{
                                icon: 'Search',
                              }}
                              name="search"
                              category="utility"
                            />
                          }
                          onChange={(_event: any, searchItem: string): void =>
                            searchCatalog(_event, searchItem)
                          }
                          hasSpinner={loadingProductGroup || loading}
                          id="unique-id-4"
                          placeholder="Search Catalog"
                        />
                      </div>
                    )}
                    <section className="slds-p-around_large-1">
                      {productList &&
                        productList.map((product: any) => (
                          <div
                            style={{
                              padding: '5px',
                              borderBottom: '1px solid #545857',
                              height: '3rem',
                              display: 'grid',
                              gridTemplateColumns: '4fr 1fr',
                            }}
                          >
                            <div style={{ marginTop: '5px' }}>
                              {`${
                                product.productNumber ? product.productNumber : product.productNo
                              } ${product.productdescription}`}
                              {product.productOnHold || product.productOnHoldType === 'ITEM' ? (
                                <>
                                  <img
                                    src={holdIconPath}
                                    className="logo-image"
                                    alt="company"
                                    style={{ marginLeft: '8px', marginRight: '8px' }}
                                  />
                                  On Hold
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                            <div>
                              <Input
                                id="counter-input-3"
                                styleInput={{ width: '110px' }}
                                minValue={0}
                                maxValue={MAX_QUANTITY}
                                disabled={
                                  !!(
                                    product &&
                                    product.lineVisualIndicator &&
                                    product.lineVisualIndicator.toLowerCase() === 'approved'
                                  ) || product.alreadyAdded
                                }
                                onChange={(
                                  event: any,
                                  data: { value: string; number: number }
                                ): void => {
                                  handleCountalert(event, data, product);
                                }}
                                value={product.counterValue}
                                variant="counter"
                              />
                            </div>
                          </div>
                        ))}
                    </section>
                  </div>
                </TabsPanel>
              </Tabs>
            ) : (
              <section className="slds-p-around_large-1">
                {productList &&
                  productList.map((product: any) => {
                    return !product.deleted ? (
                      <div
                        style={{
                          padding: '5px',
                          borderBottom: '1px solid #545857',
                          height: '3rem',
                          display: 'grid',
                          overflowWrap: 'anywhere',
                          gridTemplateColumns: '4fr 1.5fr 0.5fr',
                        }}
                      >
                        <div style={{ marginTop: '5px' }}>
                          {`${product.productNumber || product.productNo || ''} ${
                            product.productdescription
                          }`}
                          {product.productOnHold || product.productOnHoldType === 'ITEM' ? (
                            <>
                              <img
                                src={holdIconPath}
                                className="logo-image"
                                alt="company"
                                style={{ marginLeft: '8px', marginRight: '8px' }}
                              />
                              On Hold
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                        <div>
                          <Input
                            id="counter-input-3"
                            styleInput={{ width: '110px' }}
                            minValue={0}
                            maxValue={MAX_QUANTITY}
                            disabled={
                              !!(
                                product &&
                                product.lineVisualIndicator &&
                                product.lineVisualIndicator.toLowerCase() === 'approved'
                              ) || product.alreadyAdded
                            }
                            onChange={(
                              event: any,
                              data: { value: string; number: number }
                            ): void => {
                              handleCountalert(event, data, product);
                            }}
                            value={product.counterValue}
                            variant="counter"
                          />
                        </div>
                        <div style={{ textAlign: 'end', paddingTop: '5px' }}>
                          <Button
                            iconCategory="utility"
                            iconName="delete"
                            iconSize="large"
                            iconVariant="bare"
                            variant="icon"
                            onClick={(): void => handleDelete(product)}
                          />
                        </div>
                      </div>
                    ) : null;
                  })}
              </section>
            )}
          </div>
        </Modal>
      </div>
    </IconSettings>
  );
};

/** Custom Header on Inventory Add Items Page **/
export default withRouter(InventoryRequestAddItems);
