/*
 * Get Translations GraphQL specification
 * @module src/graphql/getTranslations
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_TRANSLATIONS = gql`
  query getTranslations($translationKey: String!) {
    getTranslations(translationKey: $translationKey)
  }
`;

// Testing Commit
